<template>
  <div class="auth-card">
    <p class="auth-title mb-3">Password Reset</p>
    <p class="auth-link sub-t mb-3">Forgot your password? Enter your email below for reset instructions</p>
    <forgot-pass-form class="mb-3" />
    <p class="auth-link mb-0">
      <span>Back to</span>
      <span class="text-primary cursor-pointer ml-3" @click="goTo({name: 'Login'})">Log In</span>
    </p>
  </div>
</template>

<script>
import ForgotPassForm from '@/components/auth/ForgotPassForm'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    ForgotPassForm,
  },
  mixins: [GoTo]
}
</script>

<style lang="sass" scoped>
.sub-t
  font-size: 12px !important
  line-height: 1.7 !important
  color: #788288 !important
</style>