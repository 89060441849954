<template>
  <div class="no-data d-flex flex-column justify-content-center align-items-center">
    <p class="d-title mb-1 text-center">No Recent Orders</p>
    <p class="d-desc mb-3 text-center" style="max-width: 320px">We’re glad your Ordo account is all set. Currently you don’t have any “Recent Orders”. Once you link a vendor or vendors we’ll display your recent orders from your vendors in addition to your orders placed through ordo</p>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
}
</script>

<style lang="sass" scoped>

.no-data
  background-image: url('../../assets/images/order-box.png')
  background-position: 70px 0px
  background-size: 80% 100%
  background-repeat: no-repeat
  min-height: 220px
  @media (max-width: 762px)
    background-image: unset
</style>