<template>
  <div class="d-card px-3 py-3">
    <p class="d-title">Order Summary</p>
    <p
      class="d-lg-flex justify-content-between align-items-center total-number"
    >
      <span class="d-title__8 f-22-px">Total:</span>
      <span class="d-title__8" style="font-size: 22px">
        <skeleton-loader
          type="rect"
          :width="109"
          :height="33"
          :rounded="true"
          :radius="33"
          animation="wave"
          v-if="loading"
        />
        <span v-else class="text-success">${{ numberWithCommas(total) }}</span>
      </span> 
    </p>
    <p
      class="d-lg-flex justify-content-between align-items-center total-number"
    >
      <span class="d-title__2">Savings :</span>
      <span class="d-title__2 text-right text-success">
        <skeleton-loader
          class="ml-auto"
          type="rect"
          width="137px"
          height="20px"
          :rounded="true"
          :radius="20"
          animation="wave"
          v-if="fetchingData"
        />
        <!-- <span v-else>${{(checkoutData.order_details[vendorId].subtotal_amount || 0).toFixed(2)}}</span> -->
        <span v-else>${{ getTotalSaving().toFixed(2) }}</span>
      </span>
    </p>
    <table class="w-100 mb-3" v-if="$store.state.OFFICE.fetchingBudget">
      <tr>
        <td class="py-2" style="width: 70%">
          <skeleton-loader
            type="rect"
            width="100%"
            height="18px"
            :rounded="true"
            :radius="18"
            animation="wave"
          />
        </td>
        <td class="py-2" style="width: 30%">
          <skeleton-loader
            type="rect"
            width="100%"
            height="18px"
            :rounded="true"
            :radius="18"
            animation="wave"
          />
        </td>
      </tr>
      <tr>
        <td class="py-2" style="width: 70%">
          <skeleton-loader
            type="rect"
            width="100%"
            height="18px"
            :rounded="true"
            :radius="18"
            animation="wave"
          />
        </td>
        <td class="py-2" style="width: 30%">
          <skeleton-loader
            type="rect"
            width="100%"
            height="18px"
            :rounded="true"
            :radius="18"
            animation="wave"
          />
        </td>
      </tr>
    </table>
    <div class="mb-3 w-100" v-else>
      <p
        class="d-title f-13-px gilroy-sb mb-2"
        style="line-height: 1.5; color: #1c455a; max-width: 175px"
      >
        Dental supply budget after placing order
      </p>
      <p
        :class="[
          'd-title f-16-px gilroy-eb mb-4',
          remainingSpendDental < 0 ||
          remainingSpendDental < getTotalOf('dental_budget')
            ? 'text-danger__2'
            : 'text-success',
        ]"
        style="line-height: 1.5"
      >
        ${{ remainingDentalBudget }}
      </p>
      <p
        class="d-title f-13-px gilroy-sb mb-2"
        style="line-height: 1.5; color: #1c455a; max-width: 175px"
      >
        Front office supply budget after placing order
      </p>
      <p
        :class="[
          'd-title f-16-px gilroy-eb mb-0',
          remainingSpendFrontOffice < 0 ||
          remainingSpendFrontOffice < getTotalOf('office_budget')
            ? 'text-danger__2'
            : 'text-success',
        ]"
        style="line-height: 1.5"
      >
        ${{ remainingFrontOfficeBudget }}
      </p>
    </div>
    <CartPromoInSummary v-if="$route.name == 'Cart' && showPromo" />
    <div class="dash mt-3 mb-3"></div>
    <cart-promo-apply class="mb-4" @promo-done="() => {}" v-if="showPromo" />
    <b-tooltip
      :target="`tooltip-checkout-disabled`"
      triggers="hover focus"
      placement="top"
      boundary="document"
      custom-class="promotion-tooltip"
      v-if="total == 0"
    >
      <p class="d-desc mb-0">
        To proceed to checkout, please ensure that the items in your cart have
        been marked for checkout.
      </p>
    </b-tooltip>
    <div
      class="d-flex justify-content-center align-items-center flex-column gap-8-px"
      id="tooltip-checkout-disabled"
      v-if="orderApproval"
    >
      <Button
        text="+ Add an Item"
        classes="w-100"
        :submitting="submitting"
        :onclick="() => (productsModal = true)"
      />
      <Button
        text="Submit Order Approval"
        classes="w-100"
        :submitting="approvingOrder"
        :onclick="approveOrder"
      />
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-column"
      id="tooltip-checkout-disabled"
      v-else
    >
      <Button
        text="Proceed to Checkout"
        classes="w-100"
        :submitting="submitting"
        :onclick="checkoutStatus"
        v-if="!completeBtn"
        :disabled="total == 0"
      />
      <!-- 
      <Button text="Edit Credit Card" classes="w-100" :submitting="submitting" :onclick="showCreditCardPopup = true"
        v-if="editCreditCardDetails" :disabled="total == 0" /> -->

      <b-btn
        class="custom-btn success w-100 mt-2"
        @click="goTo({ name: 'Inventory' })"
        v-if="!completeBtn"
        >Continue Shopping</b-btn
      >
      <!-- <Button
        text="+ Add an Item"
        classes="w-100 mt-2"
        v-if="!completeBtn"
        :onclick="() => (productsModal = true)"
      /> -->
      <Button
        text="Complete Order"
        classes="w-100 green-button"
        :submitting="checkingOut"
        :onclick="onCheckoutClick"
        v-if="showCmpltBtn"
        :disabled="total == 0 || (hasVendorId12Product && !isCreditCardSuccess && !CD_CCvalue)"
      />
    </div>
    <modal
      :modal="cannotCheckoutModal"
      @change="toggleCannotCheckoutModal"
      size="md"
      c-classes="over-budget-modal"
    >
      <div class="d-flex justify-content-center mb-40-px">
        <logo class="logo" />
      </div>
      <p class="text-center d-desc mb-4">
        Another user is currently checking out, come back later
      </p>
      <div class="d-flex justify-content-center">
        <p
          class="text-center cursor-pointer text-primary d-title"
          @click="toggleCannotCheckoutModal(false)"
        >
          Close
        </p>
      </div>
    </modal>
    <modal
      :modal="sessionExpiredModal"
      @change="toggleSessionExpiredModal"
      size="md"
      c-classes="over-budget-modal"
      :noCloseOnBackdrop="true"
    >
      <div class="d-flex justify-content-center mb-40-px">
        <logo class="logo" />
      </div>
      <p class="text-center d-title text-danger__2">Checkout Timeout</p>
      <p class="text-center d-desc mb-4">
        You have been on the checkout page for 30 minutes with no activity. Your
        session has timed out, and we will redirect you back to the cart page.
      </p>
      <div class="d-flex justify-content-center">
        <p
          class="text-center cursor-pointer text-primary d-title"
          @click="
            toggleSessionExpiredModal(false);
            goTo({ name: 'Cart' });
          "
        >
          Back to Cart
        </p>
      </div>
    </modal>
    <modal
      :modal="overBudgetModal"
      @change="toggleOverBudgetModal"
      size="md"
      c-classes="over-budget-modal"
      :noCloseOnBackdrop="true"
    >
      <div class="d-flex justify-content-center mb-40-px">
        <logo class="logo" />
      </div>
      <p class="text-center d-title text-danger__2">
        This order requires approval.
      </p>
      <p class="text-center d-desc mb-4">
        {{ fullName }}, your order requires admin approval. We've sent an email
        notifying your office administrator that this order requires approval.
        Once an admin takes action on this order approval we'll send you an
        email with the updated order status.
      </p>
      <div class="d-flex justify-content-center">
        <p
          class="text-center cursor-pointer text-primary d-title"
          @click="
            toggleOverBudgetModal(false);
            goTo({ name: 'Dashboard' });
          "
        >
          Close Popup
        </p>
      </div>
    </modal>
    <modal
      :modal="infoModal"
      @change="toggleInfoModal"
      size="md"
      :noCloseOnBackdrop="true"
    >
      <div class="d-flex justify-content-center">
        <img src="@/assets/images/x.png" alt="" />
      </div>
      <p class="d-title text-center my-3">
        Oops.. we encounterd an error on your order
      </p>
      <p class="d-desc__2 text-center mb-3">
        While processing your order we ran into an issue. Please confirm your
        billing details and shipping info is correct
      </p>
      <div class="d-flex justify-content-center">
        <Button
          text="Back to Checkout"
          classes="mt-4"
          :onclick="
            () => {
              goTo({ name: 'Cart' });
              infoModal = false;
            }
          "
        />
      </div>
    </modal>
    <modal
      :modal="showSuccessOrder"
      @change="toggleSuccessOrderModal"
      size="md"
      cClasses="py-4 px-3"
      dialogClass="success-order-modal"
      :noCloseOnBackdrop="true"
    >
      <div class="order-success-character-div my-5">
        <img
          src="@/assets/images/character_successful_order_popup.png"
          alt=""
          class="ch-img"
        />
      </div>
      <p class="d-title text-center">Your order has been placed!</p>
      <div class="d-flex justify-content-center">
        <!-- <p class="d-desc__3 text-center" style="max-width: 350px">Congrats, you’ve just placed your order! To monitor your order go to the orders page to view the status. If you have any questions, reach out to us at hello@joinordo.com</p> -->
        <p class="d-desc__3 text-center" style="max-width: 350px">
          Your order has been placed and is currently processing. Once processed
          the order status & details will display in Ordo.
        </p>
      </div>
      <div class="d-flex justify-content-center mb-5">
        <b-btn
          class="custom-btn success px-5"
          @click="goTo({ name: 'Dashboard' })"
          >Close</b-btn
        >
      </div>
    </modal>
    <modal
      :modal="productsModal"
      @change="toggleProductsModal"
      size="lg"
      dialog-class="add-item-in-order-approval-modal"
      c-classes="px-0 pt-0 pb-0 round-modal"
    >
      <div class="modal-h px-4 py-3 position-relative">
        <p class="d-title__7 f-18-px mb-0 text-white">
          Add items in this order
        </p>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="close-icon-btn cursor-pointer text-white"
          @click="productsModal = false"
        >
          <path
            d="M17.1 2.7C17.5971 2.20294 17.5971 1.39706 17.1 0.9C16.6029 0.402944 15.7971 0.402944 15.3 0.9L9 7.2L2.7 0.9C2.20294 0.402944 1.39706 0.402944 0.9 0.9C0.402944 1.39706 0.402944 2.20294 0.9 2.7L7.2 9L0.9 15.3C0.402944 15.7971 0.402944 16.6029 0.9 17.1C1.39706 17.5971 2.20294 17.5971 2.7 17.1L9 10.8L15.3 17.1C15.7971 17.5971 16.6029 17.5971 17.1 17.1C17.5971 16.6029 17.5971 15.7971 17.1 15.3L10.8 9L17.1 2.7Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div style="min-height: 70vh">
        <OrderApprovalAddProduct
          :itemsToCheckIn="itemsToCheckIn"
          :orderId="orderId"
          @added-item-in-order="$enit('added-item-in-order')"
        />
      </div>
    </modal>
    <modal
      :modal="orderApprovedModal"
      @change="toggleOrderApprovedModal"
      size="md"
      c-classes="order-app-or-rej-modal"
      :noCloseOnBackdrop="true"
    >
      <AfterOrderApprovalModalContent
        @go="
          orderApprovedModal = false;
          goTo({ name: 'Orders' });
        "
      />
    </modal>
  </div>
</template>

<script>
import CartMixin from "@/mixins/CartMixin";
import Button from "@/components/ui/Button";
import OfficeMixin from "@/mixins/OfficeMixin";
import GoTo from "@/mixins/GoTo";
import { mapActions, mapGetters } from "vuex";
import NumberCommaMixin from "@/mixins/NumberComma";
import Modal from "@/components/modals/Modal";
import GrowLoader from "../loaders/GrowLoader.vue";
import SkeletonLoader from "skeleton-loader-vue";
import StatisticsMixin from "@/mixins/Statistics";
import Logo from "@/components/logo/Logo";
import CartPromoApply from "@/components/cart/CartPromoApply";
import CartPromoInSummary from "./CartPromoInSummary.vue";
import OrderApprovalAddProduct from "./OrderApprovalAddProduct.vue";
import AfterOrderApprovalModalContent from "@/components/dashboard/orders/AfterOrderApprovalModalContent.vue";
export default {
  props: {
    completeBtn: Boolean,
    d: {
      type: Object,
    },
    loading: Boolean,
    ckOut: Boolean,
    initTotal: Number,
    showPromo: Boolean,
    orderApproval: Boolean,
    itemsToCheckIn: Array,
    orderApprovalTotal: Number,
    orderApprovalDentalProductTotal: Number,
    orderApprovalFrontProductTotal: Number,
    orderApprovalRejectedItems: Array,
    orderId: Number,
  },
  mixins: [CartMixin, GoTo, OfficeMixin, NumberCommaMixin, StatisticsMixin],
  components: {
    Button,
    Modal,
    GrowLoader,
    SkeletonLoader,
    Logo,
    CartPromoApply,
    CartPromoInSummary,
    OrderApprovalAddProduct,
    AfterOrderApprovalModalContent,
  },
  data: () => ({
    submitting: false,
    infoModal: false,
    showSuccessOrder: false,
    overBudgetModal: false,
    overBudgetWarnigCount: 0,
    sessionExpiredModal: false,
    cannotCheckoutModal: false,
    sessionTimer: null,
    goCartTimeOut: null,
    productsModal: false,
    approvingOrder: false,
    orderApprovedModal: false,
    isCreditCardSuccess: false,
    CD_CCvalue: false,
    // fetchingData: true,
    checkoutData: {
      order_details: {}, // or [] if it should be an array
    },
  }),
  created() {
    // if(this.$route.name == 'Checkout') this.fetchingCheckout = true
    if (!this.d) {
      // if(this.$route.name != 'Checkout') this.fetchCart()
    } else {
      if (this.$route.name == "Checkout") this.checkoutData = this.d;
    }
    this.setCartValEvent();
    this.checkingOut = this.ckOut;
    // this.$BUS.$on('checkout-done-not-approved', this.onNotApproved)
    if (this.stats == null) this.fetchCurrentBudget();
    else {
      this.fetchingBudgetStats = false;
    }
    this.$BUS.$on("creditCardSuccess", (success) => {
      this.isCreditCardSuccess = success;
      this.CD_CCvalue = success;
    });
    this.$BUS.$on("order-summary:checkout", () => {
      console.log("emimtted", this.checkingOut, this.loading);
      this.onCheckoutClick();
      // On successful completion, clear saved shipping options
      localStorage.removeItem("selectedShippingOptions");
    });
    this.$BUS.$on("checkout-done", () => {
      if (this.role != 1) {
        if (this.approvalType == "ALL") this.overBudgetModal = true;
        else if (
          this.approvalType == "ALL_EXCEPT_OVERBUDGET" &&
          this.remainingSpendDental < 0
        )
          this.overBudgetModal = true;
        else this.showSuccessOrder = true;
      } else this.showSuccessOrder = true;
      this.checkingOut = false;
    });
  },
  mounted() {
    this.$BUS.$on("cd_cc_value", (success) => {
      this.CD_CCvalue = success;
    });
    if (this.$route.name == "Checkout") {
      this.sessionTimer = setTimeout(() => {
        this.sessionExpiredModal = true;
        this.goCartTimeOut = setTimeout(() => {
          this.toggleSessionExpiredModal(false);
          this.goTo({ name: "Cart" });
        }, 5000);
      }, 1000 * 60 * 30);
    }
  },
  beforeDestroy() {
    // this.$BUS.$off('checkout-done-not-approved')
    if (this.goCartTimeOut != null) clearTimeout(this.goCartTimeOut);
    this.closeSessionTimer();
    this.$BUS.$off("checkout-done");
    this.$BUS.$off("order-summary:checkout");
    this.$BUS.$off("creditCardSuccess");
  },
  watch: {
    d(v) {
      this.checkoutData = v;
    },
    ckOut(v) {
      this.checkingOut = v;
    },
    productsModal(v) {
      this.$emit("add-product-modal-close", v);
    },
  },
  computed: {
    ...mapGetters("USER", ["fullName", "role"]),
    remainingFrontOfficeBudget() {
      if (this.orderApproval) {
        return this.numberWithCommas(
          Number(
            this.remainingSpendFrontOffice - this.orderApprovalFrontProductTotal
          ).toFixed(2)
        );
      }
      let totalOfFront = this.getTotalOf("office_budget");
      return this.numberWithCommas(
        Number(this.remainingSpendFrontOffice - totalOfFront).toFixed(2)
      );
    },

    remainingDentalBudget() {
      if (this.orderApproval) {
        return this.numberWithCommas(
          Number(
            this.remainingSpendDental - this.orderApprovalDentalProductTotal
          ).toFixed(2)
        );
      }
      let totalOfDental = this.getTotalOf("dental_budget");
      return this.numberWithCommas(
        Number(this.remainingSpendDental - totalOfDental).toFixed(2)
      );
    },
    total() {
      if (this.orderApproval) {
        return this.numberWithCommas(this.orderApprovalTotal.toFixed(2));
      }


      // if (this.initTotal != null) return Number(this.initTotal).toFixed(2);

      // return (
      //   this.$route.name == "Checkout"
      //     ? this.totalOrderValue
      //     : this.totalOrderValue
      // ).toFixed(2);

      return this.totalOrderValue.toFixed(2);
    },

    showCmpltBtn() {
      return this.d && this.completeBtn;
    },
    approvalType() {
      if (this.office) {
        let settings = this.office.settings;
        if (settings) {
          if (settings.requires_approval_notification_for_all_orders) {
            return "ALL";
          } else if (
            settings.budget_threshold == 0 &&
            settings.percentage_threshold == 0
          ) {
            return "ALL_EXCEPT_OVERBUDGET";
          } else {
            return "ON_BUDGET_REQ";
          }
        }
      }
    },
    hasVendorId12Product() {
      return this.checkoutData.products.some(
        (product) => product.product.vendor.id == 12
      );
    },
  },
  methods: {
    ...mapActions("CART", [
      "CHECKOUT_CART",
      "CAN_CHECKOUT",
      "MAARK_CHECKOUT_STATUS_AS_READY",
    ]),
    ...mapGetters("CART", ["getTotalSaving"]),
    ...mapActions("ORDER", ["APPROVE_PENDING_ORDER"]),
    ...mapActions("CART", [
      "FETCH_CART",
      "CHECKOUT_CART",
      "FETCH_CHECKOUT",
      "CLEAR_SAVED_FOR_LATER",
      "CLEAR_CART",
      "VENDOR_SHIPPING_METHODS",
    ]),
    ...mapActions("PRODUCT", [
      "GET_PRODUCT_CATEGORIES_INVENTORY",
      "FETCH_ORDERS_BY_CATEGORY",
      "UPDATE_PRICE_BY_PRODUCT_IDS",
      "GET_PRICE_BY_PRODUCT_IDS",
      "GET_PRODUCT_CATEGORIES_INVENTORY_VENDOR",
    ]),
    toggleProductsModal(v) {
      if (v != this.productsModal) this.productsModal = v || false;
    },
    async markCheckoutAsReaady() {
      this.MAARK_CHECKOUT_STATUS_AS_READY({ office: this.officeId });
    },
    closeSessionTimer() {
      if (this.sessionTimer != null) {
        clearTimeout(this.sessionTimer);
      }
    },
    onNotApproved() {
      if (this.overBudgetWarnigCount > 0) return;
      if (this.office.settings.requires_approval_notification_for_all_orders) {
        this.overBudgetModal = true;
      } else if (
        !this.office.settings.requires_approval_notification_for_all_orders &&
        parseFloat(this.office.settings.percentage_threshold) == 0 &&
        parseFloat(this.office.settings.budget_threshold) == 0 &&
        this.total > this.remainingSpendDental
      ) {
        this.overBudgetModal = true;
      } else {
        this.showSuccessOrder = true;
      }
      ++this.overBudgetWarnigCount;
    },
    async onCheckoutClick() {
      if (this.checkingOut || this.loading) return;
      if (!this.needBilling()) {
        let keys = Object.keys(this.checkoutData.order_details);
        for (let key of keys) {
          if (Object.hasOwn(this.checkoutData.order_details, key)) {
            this.shippingOptionId.push(
              this.checkoutData.order_details[key].shipping_option_id
            );
          } else {
            this.shippingOptionId.push(-1);
          }
        }
        this.closeSessionTimer();
        this.checkingOut = true;
        this.$BUS.$emit("checkout");
      }
    },
    toggleCannotCheckoutModal(v) {
      if (v != this.cannotCheckoutModal)
        this.cannotCheckoutModal = v != null ? v : false;
    },
    toggleInfoModal(v) {
      if (v != this.infoModal) this.infoModal = v != null ? v : false;
    },
    toggleSessionExpiredModal(v) {
      if (v != this.sessionExpiredModal)
        this.sessionExpiredModal = v != null ? v : false;
    },
    toggleOverBudgetModal(v) {
      if (v != this.overBudgetModal)
        this.overBudgetModal = v != null ? v : false;
    },
    toggleSuccessOrderModal(v) {
      if (v != this.showSuccessOrder)
        this.showSuccessOrder = v != null ? v : false;
    },
    toggleOrderApprovedModal(v) {
      if (v != this.orderApprovedModal)
        this.orderApprovedModal = v != null ? v : false;
    },
    async complete() {
      if (this.submitting) return;
      this.$BUS.$emit("checkout");
    },
    async checkoutStatus() {
      if (this.submitting) return;
      this.submitting = true;
      try {
        let res = await this.CAN_CHECKOUT({ office: this.officeId });
        if (res.can_use_cart) this.goTo({ name: "Checkout" });
        else this.cannotCheckoutModal = true;
      } catch (e) {}
      this.submitting = false;
    },
    async approveOrder() {
      console.log("testest");
      this.approvingOrder = true;
      try {
        let payload = {
          office: this.officeId,
          orderId: this.$route.params.order_id,
          rejected_items: this.orderApprovalRejectedItems,
        };
        let res = await this.APPROVE_PENDING_ORDER(payload);
        this.orderApprovedModal = true;
      } catch (e) {
        console.log(e);
      }
      this.approvingOrder = false;
    },
    getSubSavings(vendorId) {
      let slug = "";
      if (
        this.checkoutData &&
        this.checkoutData.order_details &&
        this.checkoutData.order_details[vendorId]
      ) {
        slug = this.checkoutData.order_details[vendorId].slug;
      }
    },
    // getTotalSavings() {
    //   let totalSavings = 0;
    //   for (let order of this.checkoutData.order_details) {
    //     totalSavings = totalSavings + order.savings_amount;
    //   }
    //   return totalSavings;

    // },
    getSubTotal(vendorId) {
      let result =
        this.checkoutData.order_details[vendorId].subtotal_amount || 0;
      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
.green-button 
  background-color: green
  color: white
  border: none
  cursor: pointer 

.green-button:disabled 
  background-color: grey 
  cursor: not-allowed

.close-icon-btn
  position: absolute
  right: 1.5rem
  top: 1rem
.total-number
  background-color: #F9FAFA
  border-radius: 12px
  padding: 1rem
.over-budget-modal
  .logo
    width: 115px
    height: 32px
.order-success-close-btn
  min-height: 27px !important
  font-size: 11px
.order-success-character-div
  background-image: url('../../assets/gif/animation.gif')
  background-size: 135% 150%
  background-position: center
  display: flex
  justify-content: center
  align-items: center
  &__no-background
    @extend .order-success-character-div
    background-image: unset
    background-size: unset
    background-position: unset

  .ch-img
    height: 100%
    width: 100%
    max-width: 105px
    max-height: 156px
</style>
