import axios from '@/axios'
import Vue from 'vue'

const state = {
  inventoryItems: [],
  inventoryCategories: [],
  searchCompleted: false

}

const getters = {
  getSearchCompleted(state){
    return state.searchCompleted;
  }
}

const mutations = {}

const actions = {
  SET_SEARCH_COMPLETED: async ({rootGetters, state}, payload) => {
    state.searchCompleted = payload
  },
  SEARCH_PRODUCTS: async ({rootGetters}, payload) => {
    try {
      // console.log("Starting search product...");
      const startTime = performance.now();
      const { q, page, totalPages, vendors, product_id } = payload
      let office = payload.office_id
      payload.page_number = page;
      const companyId = rootGetters['USER/company'].id
      // let url = `companies/${companyId}/offices/${office}/search-products`
      let vendors_list;
      if (vendors) {
        vendors_list = JSON.stringify(vendors);
      }

      let url = `v3/products?search=`;
      if(q) url += `${q}&office_id=${office}`;
      if(page) {
        url += `&page_number=${page}`;
      }
      // let url = `v2/products`
      // if(q) url += `?office_pk=${office}&page=${page}&per_page=15`

      // if(product_id) url += `&selected_products=${product_id}`
      // url += `&search=${q}`
      if(payload.price_from != null) url += `&price_from=${payload.price_from}`
      if(payload.price_to) url += `&price_to=${payload.price_to}`
      if(vendors) url += `&vendors_list=${vendors_list}`
       
      const endTime= performance.now();
      const timeElapsed = endTime - startTime;
      // console.log(`Product before completed in ${timeElapsed} milliseconds.`);

      let { data } = await axios.reqApi(url).get(payload)
      const endTimeAsync = performance.now();
      const timeElapsedAsync = endTimeAsync - startTime;
      // console.log(`Product search completed in ${timeElapsedAsync} milliseconds.`);
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);  
    }
  },
  GET_PRICE_BY_PRODUCT_IDS: async ({rootGetters}, payload) => {
    try {
      let office = payload.office
      delete payload.office
      const companyId = rootGetters['USER/company'].id
      let url = `companies/${companyId}/offices/${office}/products/prices`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_PRICE_BY_PRODUCT_IDS: async ({rootGetters}, payload) => {
    try {
      let office = payload.office
      delete payload.office
      const companyId = rootGetters['USER/company'].id
      let url = `companies/${companyId}/offices/${office}/products/update_prices`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  SEARCH_PRODUCT_SUGGESTION: async ({rootGetters}, payload) => {
    try {
      // let url = `v2/products/suggest?search=${payload.q}&office_pk=${payload.office}`
      let url = `v3/products/suggest?query=${payload.q}`
      let { data } = await axios.reqApi(url).get()
      //return data.data;
      return data.data.suggestions;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  GET_PRODUCT_DESCRIPTION: async ({}, payload) => {
    try {
      let { data } = await axios.reqApi('products/detail').post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  GET_PRODUCT_CATEGORIES_INVENTORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office_id
      let url = `companies/${companyId}/offices/${office}/product-categories/inventory`
      let { data } = await axios.reqApi(url).get()
      const items = data.data
      state.inventoryCategories = items
      return items;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  GET_PRODUCT_CATEGORIES_INVENTORY_VENDOR: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office_id
      let url = `companies/${companyId}/offices/${office}/product-categories/inventory-vendor`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_ORDERS_BY_CATEGORY: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office_id
      const category = payload.category
      const vendor = payload.vendor
      const page = payload.page
      const per_page = payload.per_page
      const filters = payload.filters
      let url = `companies/${companyId}/offices/${office}/products?inventory=true&category_or_price=category`
      if(category) url += `&category=${category}`
      if(vendor) url += `&vendors=${vendor}`
      if(filters && filters.min != null) url += `&price_from=${filters.min}`
      if(filters && filters.max != null) url += `&price_to=${filters.max}`
      if(page){
        if(category) url += `&page=${page}`
        else url += `&page=${page}`
      }
      if(payload.q) url += `&q=${payload.q}`
      if(per_page != null) url += `&per_page=${per_page}`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  HIDE_PRODUCT_FROM_INVENTORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const order_id = payload.order_id
      let url = `companies/${companyId}/offices/${office}/products/${order_id}/hide`
      let { data } = await axios.reqApi(url).post({ is_inventory: false })
      let index = state.inventoryItems.findIndex(i => i.id == order_id)
      if(index > -1){
        const item = state.inventoryItems[index]
        const fromCategoryIndex = state.inventoryCategories.findIndex(cat => cat.id == item.office_product_category.id)
        const toCategoryIndex = state.inventoryCategories.findIndex(cat => cat.slug == 'hidden-products')
        if(toCategoryIndex > -1 && fromCategoryIndex > -1){
          const toCategory = state.inventoryCategories[toCategoryIndex]
          Vue.set(state.inventoryItems, index, {
            ...item,
            office_product_category: { ...toCategory }
          })
          Vue.set(state.inventoryCategories, fromCategoryIndex, {
            ...state.inventoryCategories[fromCategoryIndex],
            count: state.inventoryCategories[fromCategoryIndex].count - 1
          })
          Vue.set(state.inventoryCategories, toCategoryIndex, {
            ...state.inventoryCategories[toCategoryIndex],
            count: state.inventoryCategories[toCategoryIndex].count + 1
          })
        }
      }
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UNHIDE_PRODUCT_FROM_INVENTORY: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const order_id = payload.order_id
      let url = `companies/${companyId}/offices/${office}/products/${order_id}/unhide`
      let { data } = await axios.reqApi(url).post({ is_inventory: true })
      let index = state.inventoryItems.findIndex(i => i.id == order_id)
      if(index > -1){
        const item = state.inventoryItems[index]
        const fromCategoryIndex = state.inventoryCategories.findIndex(cat => cat.id == item.office_product_category.id)
        const toCategoryIndex = state.inventoryCategories.findIndex(cat => cat.slug == 'other')
        if(toCategoryIndex > -1 && fromCategoryIndex > -1){
          const toCategory = state.inventoryCategories[toCategoryIndex]
          Vue.set(state.inventoryItems, index, {
            ...item,
            office_product_category: { ...toCategory }
          })
          Vue.set(state.inventoryCategories, fromCategoryIndex, {
            ...state.inventoryCategories[fromCategoryIndex],
            count: state.inventoryCategories[fromCategoryIndex].count - 1
          })
          Vue.set(state.inventoryCategories, toCategoryIndex, {
            ...state.inventoryCategories[toCategoryIndex],
            count: state.inventoryCategories[toCategoryIndex].count + 1
          })
        }
      }
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_CATEGORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/products/${payload.product_id}`
      let { data } = await axios.reqApi(url).patch({ office_product_category: payload.office_product_category })
      const updatedItem = data.data
      if(updatedItem != null){
        const index = state.inventoryItems.findIndex(i => i.id === updatedItem.id)
        if(index > -1){
          const fromCategoryId = state.inventoryItems[index].office_product_category.id
          const fromCategoryIndex = state.inventoryCategories.findIndex(cat => cat.id == fromCategoryId)
          const toCategoryIndex = state.inventoryCategories.findIndex(cat => cat.id == payload.office_product_category)
          let obj = Object.assign({}, state.inventoryItems[index], { office_product_category: updatedItem.office_product_category })
          Vue.set(state.inventoryItems, index, obj)
          if(fromCategoryIndex > -1){
            Vue.set(state.inventoryCategories, fromCategoryIndex, { ...state.inventoryCategories[fromCategoryIndex], count: state.inventoryCategories[fromCategoryIndex].count - 1 })
          }
          if(toCategoryIndex > -1){
            Vue.set(state.inventoryCategories, toCategoryIndex, { ...state.inventoryCategories[toCategoryIndex], count: state.inventoryCategories[toCategoryIndex].count + 1 })
          }
        }
      }
      return updatedItem;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CREATE_CUSTOM_CATEGORY: async ({rootGetters, dispatch}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/product-categories`
      await axios.reqApi(url).post(payload)
      return await dispatch('GET_PRODUCT_CATEGORIES_INVENTORY', { office_id: office })
    } catch (e) { throw e }
  },
  DELETE_CUSTOM_CATEGORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/product-categories/${payload.category_id}`
      let { data } = await axios.reqApi(url).delete()
      const index = state.inventoryCategories.findIndex(cat => cat.id == payload.category_id)
      if(index > -1){
        const toCategory = state.inventoryCategories.find(cat => cat.slug == 'other')
        if(toCategory != null){
          state.inventoryItems
          .forEach((item, index) => {
            if(item.office_product_category.id == payload.category_id){
              Vue.set(state.inventoryItems, index, {
                ...item,
                office_product_category: { ...toCategory }
              })
            }
          })
        }
        state.inventoryCategories.splice(index, 1)
      }
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_OFFICE_PRODUCT: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/products/${payload.product_id}`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  ORDER_STATUS_UPDATE: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/order-products/${payload.product_id}`
      let { data } = await axios.reqApi(url).patch(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_BUDGET_CATEGORY: async ({rootGetters, dispatch}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const filters = payload.filters
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/order-products/${payload.product_id}`
      let { data } = await axios.reqApi(url).patch(payload)
      // dispatch('OFFICE/CURRENT_MONTH_BUDGET', { office, filters }, {root:true})
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_PRODUCT: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/products/${payload.product_id}`
      let { data } = await axios.reqApi(url).patch(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}