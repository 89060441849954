<template>
  <div class="position-relative">
    <div class="d-flex align-items-center justify-content-center" style="gap: 15px">
      <p :class="['t-text mb-0', item.status === 'rejected' || status === 'rejected' ? '' : 'text-success' ]">Approve</p>  
      <b-dropdown right class="menu-drp" menu-class="rej-res-sel" :ref="`rej-res-${item.id}`" @hide="checkRejRes(item)" offset="44">
        <template #button-content>
          <div class="d-flex align-items-center gap-10-px">
            <b-form-checkbox switch class="custom-chk__alt" :ref="`chk-res-${item.id}`" disabled @change="v => onChangeToggler(v, item)" :checked="item.status === 'rejected'"></b-form-checkbox>
            
            <p class="t-text text-right mb-0">
              <span v-if="item.status === 'rejected'">
                <span class="text-danger__2">Rejected</span>
              </span>
              <span v-else>
                <span>Rejected</span>
              </span>
              <img src="@/assets/images/svg_icon/down_caret_grey.svg" alt="" class="ml-1" v-show="isRejToggleOpen(item.id)">
            </p>
          </div>
        </template>
        <div class="cm-body">
          <div v-for="(reason, rI) in rejectReasons" :key="rI"> 
            <div class="dash" v-if="rI > 0"></div>
            <div class="my-3 cursor-pointer d-flex align-items-center" @click="setRej(reason, item)">
              <div class="ven-ck mr-2 px-2-px">
                <img src="@/assets/images/pink_check_mark.png" alt="" style="vertical-align: unset; max-width: 100%; max-height: 100%" v-if="rejected_reason == reason.text">
              </div>
              <p class="d-desc text-danger__2 mb-0">{{reason.text}}</p>
            </div>
          </div>
        </div>
      </b-dropdown>
    </div>
    <p class="mb-0 d-desc__2 ptag_information text-center" style="line-height: 1; position: absolute;" v-if="item.status === 'rejected'">Item Rejected: {{item.rejected_reason}}</p>
  </div>
</template>

<script>
import axios from "@/axios";
import { mapGetters } from 'vuex';

export default {
  props: {
    item: Object,
  },
  data: () => ({
    rejectReasons: [
      { value: 'Not Needed', text: 'Not Needed' },
      { value: 'Wrong Item', text: 'Wrong Item' },
      { value: 'Too Expensive', text: 'Too Expensive' },
      { value: 'Other' , text: 'Other' },
    ],
    rejected_reason: null,
    status: null
  }),
  watch: {
    rejected_reason(val){
      this.$emit('change', { order_product_id: this.item.id, rejected_reason: this.rejected_reason });
    },
  },
  methods: {
    async ApproveOrReject() {
      const status = this.rejected_reason ? 'rejected' : 'approved';
      const reason = this.rejected_reason || '';
      this.item.status = status;
      this.item.rejected_reason = reason;
      const res = await axios.reqApi(`companies/${this.companyId}/offices/${this.officeId}/orders/${this.item.vendor_order}/update-product-status?product_id=${this.item.id}`).post( {
        "status": status,
        "reason": this.rejected_reason
        // "status": "pendingapproval", 
        // "reason": ''
      });
      // console.log('test',this.item.status);
      // this.item.status = "rejected";
      // this.status = this.item.status;
      // this.item.rejected_reason = this.rejected_reason;
    },

    async setRej(reason, item, key = 'rejected_reason'){
      if(this.rejected_reason != reason.value) this.rejected_reason = reason.value
      else this.rejected_reason = null
      await this.$nextTick()
      this.$refs[`rej-res-${item.id}`].hide(true)

      await this.ApproveOrReject();
    },
    isRejToggleOpen(id){
      let tog = this.$refs[`rej-res-${id}`]
      if(tog) {
        return tog.visible
      }
      return false
    },
    onChangeToggler(value, item) {
        if (value) {
          this.rejected_reason = this.rejectReasons[0].value;
        } else {
          this.rejected_reason = null;
        }
        this.ApproveOrReject();
      },
    // rejectReasonBtnText(item){
    //   if(this.rejected_reason){
    //     let reason = this.rejectReasons.find(r => r.value == this.rejected_reason)
    //     if(reason) return `Item Rejected: ${reason.text}`
    //   }
    //   return null
    // },
    isRejecetedReasonPopOpen(item){
      let el = this.$refs[`rej-res-${item.id}`]
      if(el != null) return el.visible
      else return false
    },
    async checkRejRes(item){
      await this.$nextTick()
      if(this.rejected_reason == null){
        this.$refs[`chk-res-${item.id}`].$el.children[0].checked = false
        this.$forceUpdate()
      }
    },
  },

  computed: {
    ...mapGetters('USER', ['company', 'offices']),
    companyId() {
      const company = this.$store.getters['USER/company'];
      return company ? company.id : null;
    },
    officeId() {
      const offices = this.$store.getters['USER/offices'];
      return offices.length > 0 ? offices[0].id : null;
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .rej-res-sel
  min-width: 200px !important
  top: 5px !important
.ven-ck
  width: 20px
  min-width: 20px
  height: 20px
  border-radius: 4px
  border: solid 1px #ededed
  display: flex
  align-items: center
  justify-content: center
.t-text
  font-size: 12px
  font-weight: 600
  font-stretch: normal
  font-style: normal
  letter-spacing: normal
  text-align: left
  color: #707070
</style>
<style>
.ptag_information {
  padding-left: 13.6rem;
}
</style>