<template>
  <div class="no-notification">
    <p class="d-title text-center mb-2" style="font-size: 16px;">{{title}}</p>
    <p class="d-desc text-center mb-0" style="font-family: Gilroy-Medium;">{{desc}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: `No Recent Notifications`
    },
    desc: {
      type: String,
      default: `You have no current notifications, we’ll notify you when we do`
    },
  },
}
</script>

<style lang="sass" scoped>

// .no-notification
//   max-width: 200px
.no-notf-img-wrapper
  width: 100%
  max-width: 120px
  max-height: 119px
</style>