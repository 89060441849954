<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 900px;">Find the Best Deals on Dental Supplies with Our Real-Time Price Comparison Tool!</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 690px;">As a dentist, we know you have a lot on your plate. That's why our real-time price comparison tool is designed to save you time and hassle. With just a few clicks, you can access the latest prices from multiple vendors and compare them in real-time. Say goodbye to wasting time calling or emailing reps - our tool makes finding the best deals on dental supplies a breeze!</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Multiple Vendors? No Problem!</p>
        <p class="subtitle-3 mb-3">With our tool, you don't have to spend hours scrolling through different dental supply websites. We've got all the top vendors in one place, so you can easily compare prices and find the best deal. Say goodbye to the hassle of juggling multiple tabs - our tool has got you covered!</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Price_Comparison/price_comparison_1.png" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">Customized Results Just for You!</p>
        <p class="subtitle-3 mb-3">Looking for a specific product or quantity? No problem! Our tool allows you to customize your search results based on your needs, so you can find the most cost-effective solution for your dental practice. No more wasting money on unnecessary products - our tool helps you save money without sacrificing quality!</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Price_Comparison/price_comparison_2.png" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">Save Time and Money? Yes, Please!</p>
        <p class="subtitle-3 mb-3">As a dental practitioner, we know you have a lot on your plate. That's why our real-time price comparison tool is designed to save you time and hassle. With just a few clicks, you can access the latest prices from multiple vendors and compare them in real-time. Say goodbye to wasting time on phone calls and emails - our tool makes finding the best deals on dental supplies a breeze!</p>
        <p class="text-center mb-5">
          <img src="./features_images/Price_Comparison/price_comparison_3.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/nine.svg)
</style>