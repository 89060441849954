<template>
  <div class="d-card">
    <div class="d-flex justify-content-between align-items-center px-4 pt-4">
      <b-tooltip :target="'sort-by-cat-help'" triggers="hover focus" :placement="'topleft'" boundary="document" custom-class="promotion-tooltip tooltip-size-md">
        <p class="d-desc mb-0">To move a product into a different category, simply drag the drop the product image into your desired category.</p>
      </b-tooltip>
      <p class="mb-0 d-title__2 d-flex justify-content-between gap-4-px align-items-center w-100">
        <span>Sort by Category</span>
        <img src="@/assets/images/information.png" alt="" class="mb-2-px h-20-px w-20-px cursor-pointer" id="sort-by-cat-help" />
      </p>
    </div>
    <div class="d-flex align-items-center px-4 mt-3 flex-column gap-8-px">
      <b-btn class="custom-btn b1 success w-100 f-12-px lh-1" @click="categoryModal = true">+ Add Custom Category</b-btn>
      <b-btn class="custom-btn b1 primary-light w-100" @click="setSelectedCategory(null)" v-if="selectedCategory">Show All</b-btn>
    </div>
    <div class="categories-sidebar d-flex flex-lg-column px-4 pt-3">
      <div v-if="fetchingCategories"
        :class="['category-sm-card d-flex align-items-center justify-content-between px-3 mb-2']" 
      >
        <skeleton-loader type="rect" :width="'60%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" />
        <skeleton-loader type="rect" :width="'20%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" />
      </div>
      <InventorySidebarCategoryCard
        v-for="(category, cI) in computedCategories" :key="cI"
        :category="category"
        :selected-category="selectedCategory"
        :set-selected-category="setSelectedCategory"
        @fetch-cat="$emit('fetch-cat')"
        @reload="$emit('reload')"
      />
      <InventorySidebarCategoryCard
        v-if="hiddenCategory != null && viewAllCategory"
        :hidden-product-category="true"
        :category="hiddenCategory"
        :selected-category="selectedCategory"
        :set-selected-category="setSelectedCategory"
        @fetch-cat="$emit('fetch-cat')"
        @reload="$emit('reload')"
      />
      <div class="d-flex mt-2 mb-4 justify-content-end">
        <b-btn class="custom-btn primary f-12-px w-100" @click="viewAllCategory = !viewAllCategory">
          <span v-if="viewAllCategory">View Less</span>
          <span v-else>View All</span>
        </b-btn>
      </div>
    </div>
    <modal :modal="categoryModal" @change="toggleCategoryModal" size="md" dialog-class="category-adding-modal">
      <div>
        <p class="d-title text-center">Add a Custom Category</p>
        <p class="d-desc text-center">Create a custom product category in your inventory list. To add products to your custom categories, drag the product image to the specific category.</p>
        <div class="form-wrapper mx-auto mt-4">
          <validation-observer ref="catForm" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(submitCategory)">
              <div v-for="(category, cI) in formData.categories" :key="cI" class="mb-3">
                <validation-provider name="Category" rules="required" v-slot="validationContext">
                  <b-input v-model="formData.categories[cI]" class="custom-input" placeholder="Enter Category Name" :state="getValidationState(validationContext)"></b-input>
                  <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="d-flex gap-12-px justify-content-between align-items-center mb-3">
                <p class="mb-0 d-desc cursor-pointer gilroy-b text-success" @click="addAnotherCategory">+ Add Another Category</p>
                <p class="mb-0 d-desc cursor-pointer gilroy-b text-danger__2" @click="removeCategory" v-if="formData.categories.length > 1">Remove Category</p>
              </div>
              <validation-provider name="Messages" v-slot="validationContext">
                <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" class="mb-3">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
              <Button text="Create Custom Category" class="mb-4 mx-auto d-block px-md-5" :onclick="submitCategory" :submitting="submittingNewCat" />
            </b-form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InventorySidebarCategoryCard from '@/components/dashboard/inventory/SidebarCategoryCard.vue'
import FormMixin from '@/mixins/FormMixin'
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions } from 'vuex'
import SkeletonLoader from 'skeleton-loader-vue'
import Modal from '@/components/modals/Modal'
import Button from '@/components/ui/Button'

export default {
  props: {
    fetchingCategories: Boolean,
    selectedCategory: Object,
    setSelectedCategory: Function,
  },
  mixins: [ OfficeMixin, FormMixin ],
  components: { SkeletonLoader, Modal, Button, InventorySidebarCategoryCard },
  data: () => ({
    categoryModal: false,
    formData: {
      categories: [null,],
    },
    submittingNewCat: false,
    viewAllCategory: false,
  }),
  computed: {
    computedCategories(){
      if(this.viewAllCategory) return this.$store.state.PRODUCT.inventoryCategories.filter(c => c.slug !== 'hidden-products')
      return this.$store.state.PRODUCT.inventoryCategories.filter(c => c.slug !== 'hidden-products').slice(0, 5)
    },
    hiddenCategory(){
      if(this.$store.state.PRODUCT.inventoryCategories){
        return this.$store.state.PRODUCT.inventoryCategories.find(c => c.slug === 'hidden-products')
      }
      return null
    },
  },
  methods: {
    ...mapActions('PRODUCT', ['CREATE_CUSTOM_CATEGORY']),
    removeCategory(){
      if(this.formData.categories.length > 1) this.formData.categories.pop()
    },
    async submitCategory(){
      this.submittingNewCat = true
      let isValid = await this.$refs.catForm.validate()
      try{
        if(isValid){
          for(let i = 0; i< this.formData.categories.length; i ++){
            let cat = this.formData.categories[i]
            let payload = { name: cat, office: this.officeId }
            await this.CREATE_CUSTOM_CATEGORY(payload)
          }
          this.categoryModal = false
          this.formData.categories = [ null ]
          await this.$refs.catForm.reset()
        }
      }catch(e){
        const data = e.response.data
        if(data.data != null && data.data.non_field_errors != null && Array.isArray(data.data.non_field_errors)){
          let sameNameLength = data.data.non_field_errors.filter(erm => erm == 'The fields office, name must make a unique set.').length
          if(sameNameLength > 0) {
            let Messages = ''
            if(this.formData.categories.length > 1) Messages = 'Any ' + sameNameLength + ' of these categpry name has already been taken.'
            else Messages = 'This categpry name has already been taken.'
            this.$refs.catForm.setErrors({ Messages })
          }
        }
      }
      this.submittingNewCat = false
    },
    addAnotherCategory(){
      this.formData.categories.push(null)
    },
    toggleCategoryModal(v){
      if(v != this.categoryModal) this.categoryModal = v != null ? v : false
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'
@import './_category_sidebar'
</style>