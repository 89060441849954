<template>
  <div class="">
    <div class="dash mt-3 mb-3"></div>
    <div class="contents">
      <div class="d-flex flex-wrap gap-12-px align-items-end justify-content-between mb-2">
        <p class="mb-0 d-title f-12-px" v-if="totalNumberOfPromotionItemsInCart > 0">{{ totalNumberOfPromotionItemsInCart }} Promo Code<span v-if="totalNumberOfPromotionItemsInCart > 1">s</span> Found</p>
        <p class="mb-0 d-title f-12-px" v-else>No Promos Codes Found</p>
        <p class="mb-0 d-desc f-10-px cursor-pointer" style="color: #4FAAD5;" @click="priceUpdateModal = true" v-if="totalNumberOfPromotionItemsInCart > 0">View Promos</p>
      </div>
      <p class="d-desc mb-0 f-10-px" v-if="totalNumberOfPromotionItemsInCart == 0">Based on the items in your cart we weren’t able to find any Promo Codes.</p>
      <p class="d-desc mb-0 f-10-px" v-else>Based on the items in your cart we found {{ totalNumberOfPromotionItemsInCart }} promo code<span v-if="totalNumberOfPromotionItemsInCart > 1">s</span>, we will try to redeem these with the vendor</p>
    </div>
    <modal :modal="priceUpdateModal" @change="togglePriceUpdateModal" :hideCloseIcon="true" size="md" c-classes="p-0 promotion-products-modal" dialog-class="product-desc-dialog">
      <div class="d-flex flex-column gap-16-px p-4">
        <p class="d-title f-18-px d-flex gap-8-px align-items-center mb-2">
          <img src="@/assets/images/heavy-dollar-sign.png" class="h-18-px w-24-px mb-1" />
          Product promotions in your shopping cart
        </p>
        <div class="" v-for="(vendorId, vId) in vendorIdsHasPromotions" :key="vId">
          <p class="d-title f-14-px mb-2">{{ getPromotionProducts(vendorId).length }} promotion<span v-if="getPromotionProducts(vendorId).length > 1">s</span> found from {{ vendorInfo(vendorId).name }}</p>
          <ul class="pl-3">
            <li class="d-desc f-11-px" v-for="(order, orderIndex) in getPromotionProducts(vendorId)" :key="orderIndex">
              <span>{{ order.product.name }}</span>
              <span> has promotion: </span>
              <span class="">{{ order.product.promotion_description }} </span>
            </li>
          </ul>
        </div>
      </div>
    </modal>  
  </div>
</template>

<script>
import CartMixin from '@/mixins/CartMixin'
import Modal from '@/components/modals/Modal'
export default {
  mixins: [ CartMixin, ],
  components: { Modal, },
  data: () => ({
    priceUpdateModal: false,
  }),
  mounted(){},
  methods: {
    togglePriceUpdateModal(v){
      if(v != this.priceUpdateModal) this.priceUpdateModal = v
    },
  },
}
</script>

<style lang="sass" scoped>
.contents
  background-color: #F9FAFA
  border-radius: 10px
  padding: 1rem
</style>