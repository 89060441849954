<template>
  <div class="d-flex flex-column flex-lg-row gap-y-44-px justify-content-between position-relative">
    <div class="l-part">
      <p class="text-center mb-2"><img src="@/assets/images/open-dental-logo.png" alt="" /></p>
      <p class="d-desc__2 text-center mx-auto mb-0" style="max-width: 340px;">To link your OpenDental account to Ordo, please copy the code below and input it into your OpenDental account. If you need assistance, please follow the tutorial to the right.</p>
      <p class="d-desc__2 text-center mx-auto mb-4" style="max-width: 340px;">In order for Open Dental to be linked successfully, please use Open Dental version 19.4 or higher.</p>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(confirm)">
          <!-- <validation-provider name="Key" rules="required" v-slot="validationContext">
            <b-input v-model="formData.dental_key" placeholder="Input API Key" class="custom-input w-100" style="height: 40px !important;" :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
          </validation-provider>
          <p class="d-desc f-10-px mb-1 text-right mt-3">You can only select one option</p>
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <p class="mb-0 d-title__4">Pull Previous Months Adjusted Production</p>
            <b-form-checkbox v-model="formData.budget_type" value="production" :unchecked-value="null" switch class="custom-chk"></b-form-checkbox>
          </div>
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <p class="mb-0 d-title__4">Pull Months Collection</p>
            <b-form-checkbox v-model="formData.budget_type" value="collection" :unchecked-value="null" switch class="custom-chk"></b-form-checkbox>
          </div> -->
          <div class="code-div mx-auto">
            <span class="d-title l-h-1 f-12-px gilroy-sb">{{ dentalKey }}</span>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" @click="copyToClipboard">
              <path d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z" fill="#51ABD5"/>
            </svg>
          </div>
          <div class="d-flex justify-content-center mt-4 flex-column align-items-center gap-12-px">
            <Button text="Confirm Code Added" :onclick="confirm" :submitting="submitting" style="min-width: 200px" />
            <p class="d-desc__2 text-danger__2 text-center my-0" v-if="submitting">Fetching Code, this could take a few minutes.</p>
          </div>
          <p class="d-desc text-success mt-3" v-if="successMsg != null">{{ successMsg }}</p>
          <validation-provider name="Messages" v-slot="validationContext">
            <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" class="mt-3">{{ error }}</b-form-invalid-feedback>
          </validation-provider>
          <p class="d-desc__2 text-danger__2 text-center mx-auto mb-0 mt-3" v-if="showCloseWarning">Once you have added the code above to your OpenDental account, please click on "Confirmed Code Added" so that we can proceed to pull your Open Dental Data. In case you are not linking OpenDental, you may simply click on the "x" button to close the popup.</p>
        </b-form>
      </validation-observer>
    </div>
    <div class="m-part"></div>
    <div class="r-part pb-4 pb-lg-0">
      <p class="text-center f-18-px d-title">Open Dental Integration Tutorial</p>
      <div class="owl-carousel mx-auto" ref="owl-carousel">
        <img class="opendental-img" src="./images/opendental/image3.svg" alt="">
        <img class="opendental-img" src="./images/opendental/image2.svg" alt="">
        <img class="opendental-img" src="./images/opendental/image1.svg" alt="">
      </div>
      <div class="mt-2" style="min-height: 121px;">
        <div v-if="openDentalStep === 0">
          <p class="text-center f-12-px d-title">Step 1</p>
          <p class="text-center d-desc__2 mx-auto" style="max-width: 360px;">To begin, please navigate your mouse to the "Setup" tab on the top toolbar. The dropdown menu will appear. Next, click on "Advanced Setup and then click on "API" as shown above.</p>
        </div>
        <div v-else-if="openDentalStep === 1">
          <p class="text-center f-12-px d-title">Step 2</p>
          <p class="text-center d-desc__2 mx-auto" style="max-width: 350px;">Please proceed by navigating your mouse to the "Add Key" option. Once you click on it, a popup window will appear.</p>
        </div>
        <div v-else-if="openDentalStep === 2">
          <p class="text-center f-12-px d-title">Step 3</p>
          <p class="text-center d-desc__2 mx-auto" style="max-width: 415px;">Finally, please copy the code that we have provided and paste it into the text box labeled "Enter API Key". Once you have entered the code, click on "OK" and then click on the green button labeled "Confirm Code Added" underneath your unique Open Dental code provided. After completing these steps, your Ordo account will be successfully linked to Open Dental.</p>
        </div>
      </div>
    </div>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="x-icon cursor-pointer d-none" @click="close">
      <path d="M1.70631 18.2344C1.265 18.26 0.830993 18.1133 0.4958 17.8252C-0.165267 17.1602 -0.165267 16.0862 0.4958 15.4212L14.971 0.945912C15.6586 0.302532 16.7375 0.338297 17.3808 1.02586C17.9626 1.64763 17.9965 2.6033 17.4602 3.2647L2.89976 17.8252C2.56889 18.1092 2.14184 18.2556 1.70631 18.2344Z" fill="#788288"/>
      <path d="M16.1647 18.2344C15.7174 18.2325 15.2887 18.055 14.9712 17.74L0.495906 3.26468C-0.116537 2.54949 -0.0332725 1.47316 0.681919 0.860664C1.32025 0.314023 2.26165 0.314023 2.89992 0.860664L17.4604 15.3359C18.1478 15.9795 18.1833 17.0584 17.5398 17.7458C17.5142 17.7732 17.4877 17.7996 17.4604 17.8252C17.1039 18.1353 16.6347 18.2834 16.1647 18.2344Z" fill="#788288"/>
    </svg>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import FormMixin from '@/mixins/FormMixin'
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions } from 'vuex'
export default {
  components: { Button, },
  mixins: [ FormMixin, OfficeMixin ],
  props: ['officeObj'],
  data: () => ({
    submitting: false,
    formData: {
      // dental_key: null,
      budget_type: 1,
    },
    successMsg: null,
    leftAr: require('@/assets/images/left_pol_arr.png'),
    rightAr: require('@/assets/images/right_pol_arr.png'),
    openDentalStep: 0,
    closeCount: 0,
    showCloseWarning: false,
    dentalKey: null,
  }),
  mounted(){
    this.initOwl()
  },
  created(){
    this.submit()
  },
  methods: {
    ...mapActions('OFFICE', ['SET_OPENDENTAL_CONNECTION_STATUS', 'FETCH_OPENDENTAL_CONNECTION_STATUS', 'GET_AVAILABLE_DENTAL_KEY']),
    ...mapActions('ONBOARDING', ['FETCH_ONBOARDING_STATUS']),
    async copyToClipboard(){
      if(navigator.clipboard && this.dentalKey != null){
        await navigator.clipboard.writeText(this.dentalKey)
      }
    },
    initOwl(){
      let owl = this.$refs['owl-carousel']
      const carousel = $(owl).owlCarousel({
        nav: true,
        items: 1,
        autoWidth: false,
        margin: 0,
        mouseDrag: false,
        touchDrag: false,
        navText: [
          `<img class="owl-arrow" src="${this.leftAr}" alt="">`,
          `<img class="owl-arrow" src="${this.rightAr}" alt="">`
        ]
      })
      const _this = this
      carousel.on('changed.owl.carousel', function(event) {
        _this.openDentalStep = event.page.index
      })
    },
    async submit(){
      if(this.submitting) return
      this.submitting = true
      try{
        let payload = {
          ...this.formData,
          office: this.officeId
        }
        if(this.officeObj){
          payload.office = this.officeObj.id
        }
        let data = await this.FETCH_OPENDENTAL_CONNECTION_STATUS(payload)
        let keyData = await this.GET_AVAILABLE_DENTAL_KEY(payload)
        await this.FETCH_ONBOARDING_STATUS({ budget_set: true })
        this.dentalKey = keyData.key
        if(data.connected){
          let index = this.offices.findIndex(o => o.id === payload.office)
          if(index > -1 && this.offices[index].dental_api != null){
            this.dentalKey = this.offices[index].dental_api.key
          }
        }
      }catch(e){
        let errors = {
          'Messages': 'We have encountered an error while linking your OpenDental account. Our team member will reach out to you shortly to assist you further.'
        };
        if(e.message) errors.Messages = e.message
        this.$refs.form.setErrors(errors);
      }
      this.submitting = false
    },
    async confirm(){
      if(this.submitting) return
      this.submitting = true
      try{
        const payload = {
          dental_key: this.dentalKey,
          office: this.officeId,
          budget_type: 1
        }
        if(this.officeObj){
          payload.office = this.officeObj.id
        }
        let res = await this.SET_OPENDENTAL_CONNECTION_STATUS(payload)
        this.$BUS.$emit('fetch-budgets')
        this.submitting = false
        this.successMsg = 'Your OpenDental account has been linked successfully.'
        setTimeout(()=>{
          this.successMsg = null
          this.$emit('fetch-status')
        }, 1000)
      }catch(e){
        let errors = {
          'Messages': 'We have encountered an error while linking your OpenDental account. Our team member will reach out to you shortly to assist you further.'
        };
        this.$refs.form.setErrors(errors);
      }
      this.submitting = false
    },
    close(){
      if(this.closeCount > 0) {
        this.showCloseWarning = false
        this.$emit('close')
      }
      else {
        this.closeCount++
        this.showCloseWarning = true
      }
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings' 
.l-part
  max-width: 38%
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center
  .code-div
    border: 1px solid #EBEAEB
    background-color: #FAFAFA
    border-radius: 8px
    display: flex
    gap: 1rem
    justify-content: space-between
    align-items: center
    padding: 0.5rem 1rem
    width: 260px
.r-part
  max-width: 58%
  .opendental-img
    @media screen and (min-width: 992px)
      width: 556px
      height: 252px
::v-deep .owl-carousel
  @media screen and (min-width: 992px)
    max-width: 556px
  .owl-item
    width: max-content
    img
      padding: 0 2rem
  .owl-stage
    display: flex
    // align-items: flex-end
  .owl-dots
    display: none
  .owl-nav
    position: absolute
    width: 100%
    display: flex
    justify-content: space-between
    right: unset
    top: calc( 50% - 10px )
    right: 0
    z-index: 0
    .owl-arrow
      width: 14px
      height: 18px
    .owl-prev , .owl-next
      &.disabled
        .owl-arrow
          opacity: 50%
.x-icon
  position: absolute
  right: 0rem
  top: 0rem
  @media screen and (min-width: 992px)
    right: -1.5rem
    top: -1.5rem

@media screen and (min-width: 992px)
  .l-part
    min-width: 37%
  .m-part
    border-left: 1px solid #D9D9D9
    height: inherit
@media screen and (max-width: 992px)
  .l-part
    max-width: 100%
  .m-part
    display: none
  .r-part
    max-width: 100%
</style>