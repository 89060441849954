import axios from '@/axios'
import getQuery from '@/mixins/queryStringBuilder'

const state = {
  cartProducts: [],
  cartProductsByTime: [],
  fetchingCart: true,
  checkoutData: null,
  fetchingCheckoutData: null,
  totalSavings: 0,
  cc_status: false,
}

const getters = {
  cartProducts: s => s.cartProducts,
  cartProductsByTime: s => s.cartProductsByTime,
  getTotalSaving(s) {
    return s.totalSavings;
  },
  getCCStatus: s => s.cc_status,
}


const mutations = {
  SET_CART_PRODUCTS: (s, p) => s.cartProducts = p,
  SET_CART_PRODUCTS_BY_TIME: (s, p) => s.cartProductsByTime = p,
  SetTotalSaving(s, p) {
    s.totalSavings = p;
  },

  SET_CC_STATUS: (s, p) => s.cc_status = p,
}

const actions = {

  ADD_PRODUCTS_TO_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts`).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  ADD_MULTIPLE_PRODUCTS_TO_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/add-multiple-products`).post(payload.data)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_CART: async ({ rootGetters, commit, state }, payload) => {
    try {
      state.fetchingCart = true
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/carts`
      let query = getQuery(payload.filters)
      url += query
      let { data } = await axios.reqApi(url).get()
      commit('SET_CART_PRODUCTS', data.data)
      state.fetchingCart = false
      return data.data;
    } catch (e) {
      state.fetchingCart = false
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      const cart = payload.cart
      delete payload.cart
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/${cart}`).put(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  DELETE_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      const cart = payload.cart
      delete payload.cart
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/${cart}`).delete(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CHECKOUT_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/confirm-order`).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  SAVE_FOR_LATER: async ({ rootGetters, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const cart_id = payload.cart_id
      delete payload.office
      delete payload.cart_id
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/${cart_id}`).put(payload)
      let index = state.cartProducts.findIndex(c => c.id == cart_id)
      if (index > -1) {
        state.cartProducts[index].save_for_later = payload.save_for_later
      }
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CAN_CHECKOUT: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/checkout/status`).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_CHECKOUT: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      state.fetchingCheckoutData = true
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/checkout`).get()
      state.checkoutData = data.data
      state.fetchingCheckoutData = false
      return data.data;
    } catch (e) {
      state.fetchingCheckoutData = false
      return Promise.reject(e.response.data.message);
    }
  },
  CLEAR_SAVED_FOR_LATER: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/clear`).post({ remove: "save_for_later" })
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CLEAR_CART: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/carts/clear`).post({ remove: "cart" })
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  INS_CHECKOUT: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const cart = payload.cart
      delete payload.office
      delete payload.cart
      let url = `companies/${companyId}/offices/${office}/carts/${cart}`
      let { data } = await axios.reqApi(url).put(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  VENDOR_SHIPPING_METHODS: async ({ rootGetters }, payload) => {
    try {
      let url = `vendors/shipping_methods`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_CART_PRODUCT: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let office = payload.office
      let cart_id = payload.cart_id
      let url = `companies/${companyId}/offices/${office}/carts/${cart_id}/change-product`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CHANGE_VENDOR: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let office = payload.office
      let cart_id = payload.cart_id
      delete payload.cart_id
      let url = `companies/${companyId}/offices/${office}/carts/${cart_id}`
      let { data } = await axios.reqApi(url).patch(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  SET_PROMO: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let office = payload.office
      let url = `companies/${companyId}/offices/${office}/carts/set_promo`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  MAARK_CHECKOUT_STATUS_AS_READY: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let office = payload.office
      let url = `companies/${companyId}/offices/${office}/mark_checkout_status_as_ready`
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}