<template>
    <div class="onboarding-page w-100">
         <div class="klaviyo-form-RvprCz klaviyo__form"></div>
    </div>
</template>
<script>
export default {
    head: {
        script: [
            { type: 'text/javascript', src: 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UdC4cm', async: true, body: true },
        ],
        
    },
    mounted() {
        const script = document.createElement('script');
        script.src = [
            { type: 'text/javascript', src: 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UdC4cm', async: true, body: true },
        ],
        script.async = true;
        document.body.appendChild(script);
        // ... rest of the code
        console.log('Klaviyo script initialized', script);
    },
}
</script>

<style lang="scss" scoped>
.klaviyo{
    &__form{
        max-width: 30rem;
    min-width: 20rem;
    margin: 0 auto;
    background-color: rgb(223, 227, 230);
    }
    }
</style>