<template>
  <div>
    <b-input v-model="val" class="custom-input" :placeholder="placeholder" :type="type" :state="validationContext ? getValidationState(validationContext): null"></b-input>
    <b-form-invalid-feedback v-for="(error, eI) in validationContext ? validationContext.errors : []" :key="eI">{{ error }}</b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    type: String,
    validationContext: Object,
  },
  data: () => ({
    val: null
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
}
</script>

<style>

</style>