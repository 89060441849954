<template>
  <div class="lan-footer">
    <div class="l-wd-max mx-auto">
      <div class="grids">
        <div class="first">
          <LogoWhite class="logo mb-3" />
          <p class="subtitle-1">The smarter way to order and manage your dental supplies</p>
          <div class="d-flex gap-12-px flex-wrap">
            <a href="https://www.facebook.com/joinordo" target="_blank" rel="noopener noreferrer"
              style="background-color: rgba(255, 255, 255, 0.1); border-radius: 999px;"
              class="w-41-px h-41-px d-flex align-items-center justify-content-center">
              <svg enable-background="new 0 0 56.693 56.693" height="56.693px" id="Layer_1" version="1.1"
                viewBox="0 0 56.693 56.693" width="56.693px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" class="w-22-px h-22-px">
                <path
                  d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029  c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77  L40.43,21.739z"
                  fill="white" />
              </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCAgHglLZFKeQTdd0sKTmaHg" target="_blank" rel="noopener noreferrer" class="w-41-px h-41-px d-flex align-items-center justify-content-center" style="background-color: rgba(255, 255, 255, 0.1); border-radius: 999px;">
              <img src="./youtube.png" alt="" class="w-22-px h-22-px">
              <!-- <svg version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" class="w-41-px h-41-px">
                <g>
                  <polygon points="13.83,19.259 19.262,16 13.83,12.741    " fill="white" />
                  <path
                    d="M16,0C7.164,0,0,7.163,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M24.688,16.701c0,1.5-0.174,3-0.174,3     s-0.17,1.278-0.69,1.839c-0.661,0.738-1.401,0.742-1.741,0.786C19.651,22.512,16,22.518,16,22.518s-4.518-0.044-5.908-0.186     c-0.387-0.077-1.254-0.055-1.916-0.792c-0.521-0.562-0.69-1.839-0.69-1.839s-0.174-1.499-0.174-3v-1.406     c0-1.5,0.174-2.999,0.174-2.999s0.17-1.278,0.69-1.841C8.837,9.716,9.577,9.712,9.917,9.67c2.431-0.188,6.079-0.188,6.079-0.188     h0.008c0,0,3.648,0,6.079,0.188c0.339,0.042,1.08,0.046,1.741,0.784c0.521,0.563,0.69,1.841,0.69,1.841s0.174,1.5,0.174,3V16.701     z"
                    fill="white" opacity="0.1" />
                </g>
              </svg> -->
            </a>
            <a href="https://www.instagram.com/join_ordo/" target="_blank" rel="noopener noreferrer">
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_116_721)">
                  <rect opacity="0.1" x="0.47998" y="0.441895" width="40" height="40" rx="20" fill="white" />
                  <path
                    d="M20.8134 17.1816C18.8134 17.1816 17.2196 18.8066 17.2196 20.7754C17.2196 22.7754 18.8134 24.3691 20.8134 24.3691C22.7821 24.3691 24.4071 22.7754 24.4071 20.7754C24.4071 18.8066 22.7821 17.1816 20.8134 17.1816ZM20.8134 23.1191C19.5321 23.1191 18.4696 22.0879 18.4696 20.7754C18.4696 19.4941 19.5009 18.4629 20.8134 18.4629C22.0946 18.4629 23.1259 19.4941 23.1259 20.7754C23.1259 22.0879 22.0946 23.1191 20.8134 23.1191ZM25.3759 17.0566C25.3759 16.5879 25.0009 16.2129 24.5321 16.2129C24.0634 16.2129 23.6884 16.5879 23.6884 17.0566C23.6884 17.5254 24.0634 17.9004 24.5321 17.9004C25.0009 17.9004 25.3759 17.5254 25.3759 17.0566ZM27.7509 17.9004C27.6884 16.7754 27.4384 15.7754 26.6259 14.9629C25.8134 14.1504 24.8134 13.9004 23.6884 13.8379C22.5321 13.7754 19.0634 13.7754 17.9071 13.8379C16.7821 13.9004 15.8134 14.1504 14.9696 14.9629C14.1571 15.7754 13.9071 16.7754 13.8446 17.9004C13.7821 19.0566 13.7821 22.5254 13.8446 23.6816C13.9071 24.8066 14.1571 25.7754 14.9696 26.6191C15.8134 27.4316 16.7821 27.6816 17.9071 27.7441C19.0634 27.8066 22.5321 27.8066 23.6884 27.7441C24.8134 27.6816 25.8134 27.4316 26.6259 26.6191C27.4384 25.7754 27.6884 24.8066 27.7509 23.6816C27.8134 22.5254 27.8134 19.0566 27.7509 17.9004ZM26.2509 24.9004C26.0321 25.5254 25.5321 25.9941 24.9384 26.2441C24.0009 26.6191 21.8134 26.5254 20.8134 26.5254C19.7821 26.5254 17.5946 26.6191 16.6884 26.2441C16.0634 25.9941 15.5946 25.5254 15.3446 24.9004C14.9696 23.9941 15.0634 21.8066 15.0634 20.7754C15.0634 19.7754 14.9696 17.5879 15.3446 16.6504C15.5946 16.0566 16.0634 15.5879 16.6884 15.3379C17.5946 14.9629 19.7821 15.0566 20.8134 15.0566C21.8134 15.0566 24.0009 14.9629 24.9384 15.3379C25.5321 15.5566 26.0009 16.0566 26.2509 16.6504C26.6259 17.5879 26.5321 19.7754 26.5321 20.7754C26.5321 21.8066 26.6259 23.9941 26.2509 24.9004Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_116_721">
                    <rect width="40" height="40" fill="white" transform="translate(0.47998 0.441895)" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/ordo-dental-inventory-management-system" target="_blank"
              rel="noopener noreferrer">
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_116_724)">
                  <rect opacity="0.1" x="0.47998" y="0.441895" width="40" height="40" rx="20" fill="white" />
                  <path
                    d="M26.8134 13.7754H14.7821C14.2509 13.7754 13.8134 14.2441 13.8134 14.8066V26.7754C13.8134 27.3379 14.2509 27.7754 14.7821 27.7754H26.8134C27.3446 27.7754 27.8134 27.3379 27.8134 26.7754V14.8066C27.8134 14.2441 27.3446 13.7754 26.8134 13.7754ZM18.0321 25.7754H15.9696V19.1191H18.0321V25.7754ZM17.0009 18.1816C16.3134 18.1816 15.7821 17.6504 15.7821 16.9941C15.7821 16.3379 16.3134 15.7754 17.0009 15.7754C17.6571 15.7754 18.1884 16.3379 18.1884 16.9941C18.1884 17.6504 17.6571 18.1816 17.0009 18.1816ZM25.8134 25.7754H23.7196V22.5254C23.7196 21.7754 23.7196 20.7754 22.6571 20.7754C21.5634 20.7754 21.4071 21.6191 21.4071 22.4941V25.7754H19.3446V19.1191H21.3134V20.0254H21.3446C21.6259 19.4941 22.3134 18.9316 23.3134 18.9316C25.4071 18.9316 25.8134 20.3379 25.8134 22.1191V25.7754Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_116_724">
                    <rect width="40" height="40" fill="white" transform="translate(0.47998 0.441895)" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div class="flex-grow-1">
          <p class="title-1">Product</p>
          <RouterLink to="/pricing" class="subtitle-1 mb-3 !text-none d-block">Pricing</RouterLink>
          <RouterLink to="/vendors" class="subtitle-1 mb-3 !text-none d-block">Vendors</RouterLink>
          <a href="/wait-list"  class="subtitle-1 !text-none d-block mb-3 footer-wait-list">
      <button> Get Started </button></a>
          <!-- <RouterLink to="/onboarding" class="subtitle-1 !text-none d-block mb-3">Get Started</RouterLink> -->
          <a href="https://blog.joinordo.com/" class="subtitle-1 !text-none d-block mb-4 mb-lg-5">Blog</a>
          <p class="title-1">Help</p>
          <RouterLink to="https://intercom.help/joinordo/en/" class="subtitle-1 mb-3 !text-none d-block" target="_blank">
            Support Center</RouterLink>
          <!-- <a href="mailto:hello@joinordo.com" class="subtitle-1 mb-3 !text-none">Contact Us</a> -->
          <!-- <RouterLink to="https://stats.uptimerobot.com/pj7m8s9rX8" class="subtitle-1 mb-3 !text-none d-block" target="_blank">Service Status</RouterLink> -->
        </div>
        <div class="flex-grow-1">
          <p class="title-1">Features</p>
          <RouterLink to="/feature-price-comparison" class="subtitle-1 mb-3 !text-none d-block">Price Comparison
          </RouterLink>
          <RouterLink to="/feature-tracking-budget" class="subtitle-1 mb-3 !text-none d-block">Budget Tracking
          </RouterLink>
          <RouterLink to="/feature-tracking-orders-returns" class="subtitle-1 mb-3 !text-none d-block">Track Order and
            Returns</RouterLink>
          <RouterLink to="/feature-futurecast" class="subtitle-1 mb-3 !text-none d-block">Futurecast</RouterLink>
          <RouterLink to="/feature-order-approvals" class="subtitle-1 mb-3 !text-none d-block">Order Approvals
          </RouterLink>
          <RouterLink to="/feature-multivendor-ordering" class="subtitle-1 mb-3 !text-none d-block">Multi-vendor Ordering
          </RouterLink>
          <RouterLink to="/feature-imported-order-history" class="subtitle-1 mb-3 !text-none d-block">Imported Order
            History</RouterLink>
          <div class="flex-grow-1 features-d2 d-lg-none">
            <RouterLink to="/feature-customize-products" class="subtitle-1 mb-3 !text-none d-block">Customize Inventory
            </RouterLink>
            <RouterLink to="/feature-download-invoices" class="subtitle-1 mb-3 !text-none d-block">Download Invoices
            </RouterLink>
            <RouterLink to="/feature-analyze-supplies" class="subtitle-1 mb-3 !text-none d-block">Analytics</RouterLink>
            <RouterLink to="/feature-opendental-interation" class="subtitle-1 mb-3 !text-none d-block">Syncs with Open
              Dental</RouterLink>
            <RouterLink to="/feature-multioffice-functionality" class="subtitle-1 mb-3 !text-none d-block">Multi-Office
              Functionality</RouterLink>
          </div>
        </div>
        <div class="flex-grow-1 features-d2 d-none d-lg-block">
          <RouterLink to="/feature-customize-products" class="subtitle-1 mb-3 !text-none d-block">Customize Inventory
          </RouterLink>
          <RouterLink to="/feature-download-invoices" class="subtitle-1 mb-3 !text-none d-block">Download Invoices
          </RouterLink>
          <RouterLink to="/feature-analyze-supplies" class="subtitle-1 mb-3 !text-none d-block">Analytics</RouterLink>
          <RouterLink to="/feature-opendental-interation" class="subtitle-1 mb-3 !text-none d-block">Syncs with Open
            Dental</RouterLink>
          <RouterLink to="/feature-multioffice-functionality" class="subtitle-1 mb-3 !text-none d-block">Multi-Office
            Functionality</RouterLink>
        </div>
      </div>
      <div class="lower-sec d-flex flex-column flex-lg-row gap-16-px py-4">
        <p class="text-white text-center mb-0 f-13-px order-4 order-lg-1">2023 Ordo LLC, All Rights Reserved</p>
        <RouterLink to="/terms" class="text-white text-center mb-0 f-13-px !text-none ml-lg-auto order-1 order-lg-2">Terms of Service</RouterLink>
        <RouterLink to="/privacy" class="text-white text-center mb-0 f-13-px !text-none order-2 order-lg-3">Privacy Policy</RouterLink>
        <RouterLink to="/refund" class="text-white text-center mb-0 f-13-px !text-none order-2 order-lg-3">Refund Policy</RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import LogoWhite from '@/components/logo/LogoWhite.vue'
export default {
  components: { LogoWhite, },
}
</script>

<style lang="sass" scoped>
@import '../../../style/landing'

.f-link
  color: unset
  &:hover
    text-decoration: none
.lan-footer
  background-color: #274458
  padding: 5rem 4rem 0rem 4rem
  @media screen and (max-width: 992px)
    padding: 2rem 1rem
  .l-wd-max
    max-width: 1200px
    .logo
      width: 121px
      height: 33px
    .title-1
      font-family: GILROY-BOLD
      font-size: 18px
      color: #FFFFFF
    .subtitle-1
      font-size: 14px
      color: #FFFFFF
    .grids
      display: flex
      @media screen and (max-width: 992px)
        display: grid
        gap: 1rem
        grid-template-columns: repeat(2, minmax(0, 1fr))
      @media screen and (max-width: 300px)
        grid-template-columns: repeat(1, minmax(0, 1fr))
      .first
        @media screen and (min-width: 768px)
          width: 420px
          .subtitle-1
            max-width: 265px
      .features-d2
        @media screen and (min-width: 992px)
          padding-top: 43px
    .lower-sec
      border-top: 1px solid rgba(255, 255, 255, 0.2)
      margin-top: 5rem
      .footer-wait-list 
        button
        background: transparent
        border: 0
        padding: 0
        font-size: 14px
        color: #FFFFFF
</style>

