import axios from '@/axios'
import localStorageService from '@/store/localStorageService';
import Swal from 'sweetalert2';

const waitListKey = 'wait-list';

const state = {
    addedInWaitlist: false,
};

const getters = {
    getAddedInWaitlist(state) {
        const key = localStorageService.getItem(waitListKey)
        if (key != null) {
            return true;
        }
        return state.addedInWaitlist;
    },
    alreadyInWaitlist(state) {
        const key = localStorageService.getItem(waitListKey)
        
        if (key == null) {
            return false;
        }
        else {
            return true;
        }
        
    },
};

const actions = {
    ADD_TO_WAITLIST: async ({ commit }, payload) => {
        try {
            let { data } = await axios.reqApi("waitlist/create/").post(payload);
            if (data.data != null) {
                localStorageService.setItem(waitListKey, true);
                return data.data;
            } else {
                throw new Error().message = { response: { data: { data: { detail: 'Please Fill Form exactly.' } } } }
            }
        } catch (e) {
            // return Promise.reject(e.response.data.data);
            console.error(e);

            
            if (e.response && e.response.status === 400 && e.response.data) {
                // Backend returned a 400 Bad Request with error details
                const errorMessage = e.response.data.detail || 'An error occurred.';
                // Display SweetAlert for error message
                Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Wait list with this email already exists.' || 'An error occurred.',
              });
                }
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters
}