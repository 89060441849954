<template>
  <div class="product-card">
    <product-image :product="product" />
    <div class="contents px-2">
      <div class="reviews-sec mb-1">
        <b-icon font-scale="0.65" variant="warning" icon="star-fill"></b-icon>
        <b-icon font-scale="0.65" variant="warning" icon="star-fill"></b-icon>
        <b-icon font-scale="0.65" variant="warning" icon="star-fill"></b-icon>
        <b-icon font-scale="0.65" variant="warning" icon="star-half"></b-icon>
        <b-icon font-scale="0.65" icon="star" variant="light"></b-icon>
        <span class="rev-text ml-2">145 reviews</span>
      </div>
      <p class="product-title mb-1">{{product.name}}</p>
      <div class="bottom-sec d-flex justify-content-between align-items-center">
        <p class="mb-0 d-title__3">${{product.price}}</p>
        <div class="hot" v-if="hot">
          <p class="mb-0 d-desc text-white">Hot</p>
        </div>
        <p class="mb-0 d-title__4 ven-text d-flex align-items-center" v-else>
          <span class="mr-2" v-if="official">Official</span>
          <span class="mr-2" v-else>{{product.vendor.name}}</span>
          <b-icon icon="check-circle-fill" font-scale="1.4"></b-icon>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage'
export default {
  components: {
    ProductImage,
  },
  props: {
    official: Boolean,
    hot: Boolean,
    product: Object,
  },
}
</script>

<style lang="sass" scoped>

.product-card
  min-width: 211px
  height: 199px
  border-radius: 4px
  border: solid 1px rgba(28, 69, 90, 0.1)
  background-color: #ffffff
  .images-sec
    width: 100%
    height: 109px
    background-color: #ebf4f8
    img
      max-height: 109px
  .contents
    .reviews-sec
      .rev-text
        font-size: 9px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.33
        color: #7cd878
    .product-title
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.2
      color: #000000
    .bottom-sec
      .ven-text
        color: #49a2cb
      .hot
        width: 40px
        height: 16px
        background-color: #ff1dc3
        display: flex
        justify-content: center
        align-items: center
</style>