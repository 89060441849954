<template>
  <div class="manage-team">
    <div class="d-flex d-card py-3 px-3 justify-content-center align-items-center px-3 my-2" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <p class="mb-0">Fetching Data...</p>
    </div>
    <div v-else>
      <div class="mb-3 d-card py-3 px-3" v-if="connectedVendorsWithoutAmazon.length > 0">
        <div class="d-flex justify-content-between">
          <div>
            <div class="d-flex justify-content-between mb-1">
              <p class="d-title mt-2 mb-0" style="font-size: 18px;">Linked Vendors</p>
            </div>
            <p class="mb-4 d-desc__m551" style="width: 450px;">Below are your linked vendors. If you no longer use these vendors you can remove them.</p>
            <!-- <div class="d-lg-flex justify-content-lg-between align-items-lg-center mb-3">
              <p class="mb-2 d-title__2" style="font-size: 16px;">Linked Vendors</p>
            </div> -->
          </div>
          <div class="d-lg-flex justify-content-lg-end align-items-lg-center mb-3">
            <b-btn class="custom-btn success o-btn" @click="selectVendor()">Request a Vendor</b-btn>
          </div>
        </div>
        <div class="notf-surr" v-for="(vendor, mI) in connectedVendorsWithoutAmazon" :key="`mem-${mI}`">
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <div class="notf-p1 d-flex align-items-center">
              <img src="@/assets/images/vendors/edge_endo_logo_icon.png" alt="" style="height: 28px; width: 28px" class="mr-3" v-if="vendor.vendor.slug == 'edge_endo'">
              <img src="@/assets/images/vendors/implant_direct_logo_icon.png" alt="" style="height: 28px; width: 28px" class="mr-3" v-else-if="vendor.vendor.slug == 'implant_direct'">
              <img :src="vendor.vendor.logo" alt="" style="height: 28px; width: 28px" class="mr-3" v-else>
              <p class="mb-0 d-title__4">{{vendor.vendor.name}}</p>
            </div>
            <div class="ml-auto">
              <b-tooltip :target="`tooltip-vendor-pass-${mI}`" triggers="hover focus" placement="top" boundary="document" custom-class="promotion-tooltip">
                <p class="d-desc mb-0">PASSWORD</p>
              </b-tooltip>
              <div class="sm-card__auto" style="min-width: unset;">
                <p class="mb-0 d-desc__3 l-h-1 mt-1">Username : {{vendor.username}}</p>
                <img src="@/assets/images/password.png" alt="" class="h-22-px ml-1 cursor-pointer" :id="`tooltip-vendor-pass-${mI}`">
              </div>
            </div>
            <div class="d-flex align-items-center">
              <Button text="Relink" classes="sm-btn danger__2 ml-lg-2" :onclick="() => relinkVendor(vendor)" v-if="!vendor.login_success" />
              <Button text="Contact Info" classes="sm-btn ml-lg-2" :onclick="() => openContactInfoModal(vendor)" />
                <Button text="Remove" classes="sm-btn danger__2 ml-lg-2" v-if="vendor.vendor.slug !== 'crazy_dental'"  :submitting="(removingVendor && removingVendorId == vendor.id)" :onclick="() => removeVendor(vendor)" />
                
            </div>
          </div>
        </div>
      </div>
      <div class="d-card py-3 px-3" v-if="vendorsNotLinked && vendorsNotLinked.length > 0">
        <div class="d-flex justify-content-between mb-1">
          <p class="d-title mt-2 mb-0" style="font-size: 18px;">Available Vendors to Link</p>
        </div>
        <p class="mb-4 d-desc__m551" style="width: 450px;">Below are the available vendors to link. If you don't see a vendor you use, click "Request a Vendor" to tell us the vendor that you'd like added.</p>
        <div class="notf-surr" v-for="(vendor, mI) in vendorsNotLinked" :key="`mem-${mI}`">
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <div class="notf-p1 d-flex align-items-center">
              <img src="@/assets/images/vendors/edge_endo_logo_icon.png" alt="" style="height: 28px; width: 28px" class="mr-3" v-if="vendor.slug == 'edge_endo'">
              <img src="@/assets/images/vendors/implant_direct_logo_icon.png" alt="" style="height: 28px; width: 28px" class="mr-3" v-else-if="vendor.slug == 'implant_direct'">
              <img :src="vendor.logo" alt="" style="height: 28px; width: 28px" class="mr-3" v-else>
              <p class="mb-0 d-title__4">{{vendor.name}}</p>
            </div>
            <div class="d-flex align-items-center">
              <!-- <Button text="Create an Account" classes="sm-btn ml-lg-2" class="success" :onclick="() => goForCreateAcc(vendor)" /> -->
              <Button text="Link" classes="lnk-btn sm-btn ml-lg-2" :submitting="submitting" :onclick="() => selectVendor(vendor)" v-if="isVendorLinkable(vendor)" />
              <Button text="Coming Soon" classes="lnk-btn sm-btn ml-lg-2" :onclick="() => {return null}" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :modal="conModal" @change="toggleConModal" size="md" :noCloseOnBackdrop="true">
      <div v-if="selectedVendor">
        <p class="modal-header-title text-center">Link your {{selectedVendor.name}} account</p>
        <div class="d-flex justify-content-center">
          <p class="modal-header-desc text-center mb-4">Input your {{selectedVendor.name}} account details to link your account. Once you link your account, we'll add your previously ordered items to your inventory list.</p>
        </div>
      </div>
      <div v-else>
        <p class="modal-header-title text-center">Request a Vendor</p>
        <div class="d-flex justify-content-center">
          <p class="modal-header-desc text-center mb-4" style="max-width: 300px;">Don’t see one of your vendors on our platform? Enter the information below to request a vendor.</p>
        </div>
      </div>
      <div class="dash mb-4"></div>
      <div>
        <conn-acc-form :vendor="selectedVendor" :office="{id: officeId}" @close="onClose" :settingsPage="true" />
      </div>
    </modal>
    <modal :modal="conInfoModal" @change="toggleConInfoModal" size="md" dialog-class="vendor-contact-info-modal">
      <vendor-contact-info-form @close="v => toggleConInfoModal(false)" :venInfo="contactInfoForSelectedVendor" />
    </modal>
    <modal :modal="amazonConInfoModal" @change="toggleAmazonConInfoModal" size="md" dialog-class="vendor-contact-info-modal">
      <div>
        <div class="notf-p1 d-flex align-items-center justify-content-center mb-3">
          <p class="mb-0 d-title__2 f-20-px text-center" style="line-height: 1">Amazon</p>
          <img src="https://cdn.joinordo.com/vendors/amazon.jpg" alt="" style="width: 20px" class="ml-2">
        </div>
        <p class="d-desc__8 f-12-px text-center mb-3 mx-md-5" style="line-height: 1.5;">To get the contact info for Amazon, click the button below to be routed to their help page.</p>
        <div class="d-flex">
          <b-btn class="custom-btn px-4 mx-auto" @click="vendorHelpCenter('amazon')">Vendor Help Center</b-btn>
        </div>
      </div>
    </modal>
    <modal :modal="relinkModal" @change="toggleRelinkModal" size="md" :noCloseOnBackdrop="true">
      <p class="modal-header-title text-center">Relink Vendor</p>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <p class="modal-header-desc text-center mb-2">Oops! It looks like your vendor has become unlinked. Sometimes this happens when your vendor login credentials are incorrect or if they require a new or updated password.</p>
        <p class="modal-header-desc text-center mb-2">Action Required: Please visit the vendor website directly and check to see if your vendor specific username and password are correct. If they are correct, enter in those credentials below to relink the vendor. If they are incorrect, please update your username and/or password and then enter those credentials below to relink the vendor.</p>
        <p class="modal-header-desc text-center mb-4">If you are still having any issues, please reach out to us directly using the chat button on the lower right of your screen.</p>
      </div>
      <div class="dash mb-4"></div>
      <div>
        <conn-acc-form :vendor="selectedVendor" :office="{id: officeId}" @close="onClose" :settingsPage="true" :relink="true" :office-vendor-id="selectedOfficeVendorId" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/modals/Modal'
import ConnAccForm from '@/components/onboarding/ConnAccForm'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import FormMixin from '@/mixins/FormMixin'
import VendorContactInfoForm from '@/components/dashboard/settings/VendorContactInfoForm'
import available_vendors from '@/assets/json/available_vendors.json'

export default {
  components: {
    Modal,
    ConnAccForm,
    Button,
    VendorContactInfoForm
  },
  mixins: [ OfficeMixin, FormMixin, ],
  data: () => ({
    invModal: false,
    fetching: true,
    vendors: [],
    submitting: false,
    invitation: null,
    conModal: false,
    selectedVendor: null,
    upcomingVendors: [
      { name: 'Dentsply', logo: require('@/assets/images/vendors/dentsply.png') },
      { name: 'Staples', logo: require('@/assets/images/vendors/staples.png') },
    ],
    contactInfoForSelectedVendor: null,
    conInfoModal: false,
    conModal: false,
    amazonConInfoModal: false,
    updatingConVenInfo: false,
    connVenInfoBtnText: 'Save',
    connVenInfoBtnClass: 'primary-light',
    removingVendor: false,
    removingVendorId: null,
    relinkModal: false,
    selectedOfficeVendorId: null,
  }),
  created(){
    this.fetchData()
  },
  computed:{
    ...mapGetters('OFFICE', ['connected_vendors']),
    vendorsNotLinked(){
      let connIds = this.connectedVendorsWithoutAmazon.map(v => v.vendor.id)
      const vens = this.vendors.concat(this.upcomingVendors).filter(v => !connIds.includes(v.id) && v.slug != 'amazon')
      return vens.filter(a => this.isVendorLinkable(a))
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .concat(vens.filter(a => !this.isVendorLinkable(a)).sort((a, b) => a.name < b.name ? -1 : 1))
    },
    connectedVendorsWithoutAmazon(){
      return this.connected_vendors.filter(ven => ven.vendor.slug != 'amazon')
      .sort((a, b) => a.vendor.name > b.vendor.name ? -1 : 1)
      .sort((a, b) => this.isVendorLinkable(a.vendor) ? -1 : 1)
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['FETCH_VENDORS', 'CONNECT_VENDOR']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS', 'UPDATE_CONNECTED_VENDOR_INFO', 'REMOVE_VENDOR']),
    relinkVendor(vendor){
      this.selectedVendor = vendor.vendor
      this.selectedOfficeVendorId = vendor.id
      this.relinkModal = true
    },
    
    vendorHelpCenter(slug){
      if(slug == 'amazon'){
        window.open('https://www.amazon.com/', '_blank')
      }
    },
    goForCreateAcc(vendor){
      let slug = vendor.slug
      let url = null
      if(slug == 'net_32') url = `https://www.net32.com/create-account?origin=%2F`
      else if(slug == 'henry_schein') url = `https://www.henryschein.com/us-en/profiles/NewAccount.aspx`
      else if(slug == 'darby') url = `https://www.darbydental.com/Scripts/SignUp.aspx`
      else if(slug == 'ultradent') url = `https://www.ultradent.com/register#/`
      else if(slug == 'amazon') url = `https://www.amazon.com/ap/register?openid.pape.max_auth_age=0&openid.identity=http%3[…]t=signin&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0`
      else if(slug == 'patterson') url = `https://www.pattersondental.com/Account/Register?_ga=2.164583871.147424319.1644816965-645224289.1644816965`
      else if(slug == 'implant_direct') url = `https://store.implantdirect.com/customer/account/create/`
      else if(slug == 'edge_endo') url = `https://store.edgeendo.com/login.aspx?Target=account.aspx`
      else if(slug == 'benco') url = `https://identity.benco.com/AccountCheck`
      else if(slug == 'dental_city') url = `https://www.dentalcity.com/account/register`
      
      if(url){
        window.open(url, '_blank')
      }
    },
    openContactInfoModal(vendor){
      if(vendor.vendor.slug == 'amazon') {
        this.amazonConInfoModal = true
      }else {
        this.contactInfoForSelectedVendor = {
          ...vendor
        }
        this.conInfoModal = true
      }
    },
    async removeVendor(vendor){
      this.removingVendor = true
      this.removingVendorId = vendor.id
      try{
        let payload = {
          vendor: vendor.id,
          office: this.officeId
        }
        await this.REMOVE_VENDOR(payload)
        await this.fetchLinkedVendors()
      }catch(e){console.log(e)}
      this.removingVendor = false
    },
    async fetchData(){
      this.fetching = true
      await this.fetchVendors()
      this.fetching = false
    },
    async fetchLinkedVendors(){
      try{
        await this.FETCH_CONNECTED_VENDORS({office: this.officeId})
      }catch(e){}
    },
    onClose(){
      this.toggleConModal(false)
      this.toggleRelinkModal(false)
      this.fetchLinkedVendors()
    },
    selectVendor(vendor, office){
      this.selectedVendor = vendor
      this.selectedOffice = office
      this.toggleConModal(true)
    },
    toggleRelinkModal(v){
      if(v != this.relinkModal) this.relinkModal = v || false
    },
    toggleConModal(v){
      if(v != this.conModal) this.conModal = v || false
    },
    toggleConInfoModal(v){
      if(v != this.conInfoModal) this.conInfoModal = v || false
    },
    toggleAmazonConInfoModal(v){
      if(v != this.amazonConInfoModal) this.amazonConInfoModal = v || false
    },
    toggleInvModal(v){
      if(v != this.invModal) this.invModal = v != null ? v : false
      this.invitation = null
    },
    async fetchVendors(fetching = true){
      try{
        this.vendors = await this.FETCH_VENDORS()
      }catch(e){}
    },
    async updateInv(member){
      this.invitation = member
      this.invModal = true
    },
    async cancelInv(member){
      this.submitting = true
      try{
        await this.DELETE_MEMBER(member.id)
        this.fetchMembers()
      }catch(e){}
      this.submitting = false
    },
    refetch(){
      this.fetchMembers()
      this.toggleInvModal(false)
    },
    isVendorLinkable(vendor){
      if(available_vendors.includes(vendor.slug)) return true
      return false
    },
    isVendorReLinkable(vendor){
      const ven = this.connected_vendors.find(v => v.vendor.id == vendor.id)
      if(ven) return !ven.login_success
      return false
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings'
.manage-team
  .lnk-btn
    min-width: 101px
  .check-switch-box
    // min-height: 57px
    // border-radius: 4px
    // border: solid 1px #E7ECEE
    // display: flex
    // justify-content: space-between
    // align-items: center
    .sm-card
      // background-color:
      min-width: 144px
      width: min-content
      min-height: 28px
      display: flex
      align-items: center
      justify-content: center
      &__auto
        @extend .sm-card
        width: max-content !important
    .txt-rm-inv
      color: #ff1dc3
      width: max-content
      min-width: 90px
</style>