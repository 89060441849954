<template>
  <div 
    :class="[
      'category-sm-card d-flex align-items-center justify-content-between px-3 mb-2',{
      'active': selectedCategory && selectedCategory.id == category.id,
      'hover-over': droppingToCat == category.id
    }, hiddenProductCategory && 'hidden-product-category']"
    @click="onClick(category)" @drop="onDrop($event, category.id)" 
    @dragover.prevent="onDragOver(category.id)" @dragleave.prevent="onDragLeave(category.id)"
  >
    <p class="mb-0 category-name text-line-1">{{category.name}}</p>
    <SkeletonLoader type="rect" :width="'30%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" v-if="updatingCategory" />
    <div :class="['d-flex align-items-center ml-1', { 'mr-18-px' : !isCategoryDeletable }]" v-else>
      <div class="prod-num d-flex justify-content-center align-items-center">
        <span>{{category.count}} item<span v-if="category.count > 1">s</span></span>
      </div>
      <div class="del-cat cursor-pointer" @click.stop="deleteCategory(category)" v-if="isCategoryDeletable">
        <b-spinner small class="ld" v-if="deletingCat && selCatDel.id == category.id"></b-spinner>
        <img src="@/assets/images/close.png" alt="" class="x-img" style="opacity: 0.6" v-else>
      </div>
    </div>
  </div>
</template>

<script>
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions } from 'vuex'
import SkeletonLoader from 'skeleton-loader-vue'

export default {
  props: {
    category: Object,
    setSelectedCategory: Function,
    selectedCategory: Object,
    hiddenProductCategory: Boolean
  },
  mixins: [ OfficeMixin ],
  components: { SkeletonLoader },
  data: () => ({
    droppingToCat: null,
    selCatDel: null,
    deletingCat: false,
    updatingCategory: false,
  }),
  computed: {
    isCategoryDeletable(){
      return !this.category.predefined
    },
  },
  methods: {
    ...mapActions('PRODUCT', ['UPDATE_CATEGORY', 'DELETE_CUSTOM_CATEGORY']),
    async deleteCategory(category){
      if(this.deletingCat || this.updatingCategory) return
      this.selCatDel = category
      this.deletingCat = true
      try{
        let payload = { office: this.officeId, category_id: category.id }
        await this.DELETE_CUSTOM_CATEGORY(payload)
        if(this.selectedCategory && this.selectedCategory.id == category.id) this.setSelectedCategory(null)
      }catch(e){}
      this.selCatDel = null
      this.deletingCat = false
    },
    onClick(category){
      if(this.selectedCategory && this.selectedCategory.id == category.id) this.setSelectedCategory(null)
      else this.setSelectedCategory(category)
    },
    onDragOver(id){
      if(id != this.droppingToCat) this.droppingToCat = id
    },
    onDragLeave(id){
      this.droppingToCat = null
    },
    async onDrop(event, office_product_category){
      if(this.updatingCategory || this.deletingCat) return
      this.updatingCategory = true
      try{
        const product_id = Number(event.dataTransfer.getData('productId'))
        let payload = {
          office: this.officeId,
          product_id,
          office_product_category: office_product_category,
        }
        await this.UPDATE_CATEGORY(payload)
      }catch(e){
        console.log(e)
      }
      this.droppingToCat = null
      this.updatingCategory = false
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'
@import './_category_sidebar'
.hidden-product-category
  border-color: #{$red2} !important
  .category-name
    color: #{$red2} !important
  .prod-num
    span
      color: #{$red2} !important
  &.active
    background-color: #{$red2} !important
    .category-name
      color: #ffffff !important
    .prod-num
      span
        color: #fff !important
.del-cat
  border-radius: 4px
  height: 18px
  width: 18px
  display: flex
  align-items: center
  justify-content: center
  .x-img
    width: 8px
    height: 8px
  .ld
    border-color: #{$red2}
    border-right-color: transparent
    border-width: 1.5px
    width: 10px
    height: 10px
</style>