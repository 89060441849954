import axios from '@/axios'
import getQuery from '@/mixins/queryStringBuilder'

const state = {
  addManualOrder: false,
  fullStats: []
}
const getters = {
  showAddManualOrder(state){
    return state.addManualOrder;
  },
  getFullStats(state){
    return state.fullStats;
  }
}
const mutations = {
  SET_ADD_ORDER_MANUALY: (s, v) => s.addManualOrder = v,
  SET_FULL_STATE: (s,v) => s.fullStats = v
}
const actions = {
  SET_ADD_ORDER_POPUP: ({ rootGetters, commit, state }, value)=>{
    commit('SET_ADD_ORDER_MANUALY' , value);
  },
  getData(filters){
    this.onFilterChange(filters, async (filt) => {
      this.fetchingFilterData = true
      let payload = {office: this.officeId}
      if(filt) payload = { ...payload, ...filt }
      const res = await this.FETCH_FULL_STATS(payload)
      this.fullStats = res.data.data
      this.filterApplied = true
      this.fetchingFilterData = false
    })
  },
  FETCH_VENDOR_ORDERS: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const page = payload.page
      let url = `companies/${companyId}/offices/${office}/vendor-orders`
      let query = getQuery(payload.filters)
      url += query
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PENDING_STATS: async({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id;
      const office = payload.office;
      let url = `companies/${companyId}/offices/${office}/vendor-orders/pending_stats`; 
      let { data } = await axios.reqApi(url).get();
      return data.data.count;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_ORDER_PRODUCTS: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const page = payload.page
      let url = `companies/${companyId}/offices/${office}/order-products`
      let query = getQuery(payload.filters)
      url += query
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_RECENT_ORDERS: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const q = payload.q
      const vendors = payload.vendors
      let url = `companies/${companyId}/offices/${office}/products?inventory=true&q=${q}`
      if(vendors) url += `&vendors=${vendors}`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  MONTH_ORDER_COUNT_STATUS: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/stats`
      let query = getQuery(payload.filters)
      url += query
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  GET_INVOICE: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/${payload.orderId}/invoice-download`
      let res = await axios.reqApi(url).getBuffer()
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  APPROVE_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/${payload.orderId}/approve`
      let res = await axios.reqApi(url).post(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  APPROVE_PENDING_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.orderId}/approve`
      let res = await axios.reqApi(url).post(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_SINGLE_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.orderId}`
      let res = await axios.reqApi(url).get()
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/${payload.orderId}`
      delete payload.office
      let res = await axios.reqApi(url).patch(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_PENDING_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.orderId}`
      delete payload.office
      delete payload.orderId
      let res = await axios.reqApi(url).patch(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PENDING_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.orderId}`
      let res = await axios.reqApi(url).get()
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  RETURN_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/office/${office}/return-process`  
      delete payload.office
      delete payload.orderId
      let res = await axios.reqApi(url).post(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  REMOVE_ITEM_FROM_VENDOR_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/order-products/${payload.vendor_order_product_id}`
      let res = await axios.reqApi(url).delete()
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  ADD_ITEM_IN_VENDOR_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.vendor_order_id}/approvalorder-add`
      delete payload.vendor_order_id
      delete payload.office
      let res = await axios.reqApi(url).post(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_QUANTITY_OF_ITEM_IN_VENDOR_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/order-products/${payload.vendor_order_product_id}`
      delete payload.vendor_order_product_id
      delete payload.office
      let res = await axios.reqApi(url).patch(payload)
      return res
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CREATE_MANUAL_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/manual-create-order`
      delete payload.office
      return axios.reqApi(url).post(payload)
    } catch (e) { throw e }
  },
  DELETE_MANUAL_ORDER: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/orders/${payload.order_id}`
      delete payload.office
      return axios.reqApi(url).delete(payload)
    } catch (e) { throw e }
  },
  CREATE_MANUAL_ORDER_PRODUCT: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/manual-add-product`
      delete payload.office
      return axios.reqApi(url).post(payload)
    } catch (e) { throw e }
  },
  DELETE_MANUAL_ORDER_PRODUCT: async ({rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/order-products/${payload.order_product_id}`
      return axios.reqApi(url).delete()
    } catch (e) { throw e }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}