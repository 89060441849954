<template>
  <div class="notification-settings d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <p class="d-title mt-2 mb-0" style="font-size: 18px;">Notification Settings</p>
    </div>
    <p class="mb-4 d-desc__m551">Use the notification settings to manage your notifications. We send notifications through our Ordo web app, emails, and SMS, you can toggle on and off the notifications you prefer. </p>
    <p class="mb-2 d-title__2" style="font-size: 16px;">Email Notifications</p>
    <div class="check-switch-box mb-2 pl-3 pr-2">
      <p class="mb-0 d-title__4">Order Approvals</p>
      <b-form-checkbox switch class="custom-chk"></b-form-checkbox>
    </div>
    <div class="check-switch-box mb-2 pl-3 pr-2">
      <p class="mb-0 d-title__4">Order Approvals</p>
      <b-form-checkbox switch class="custom-chk"></b-form-checkbox>
    </div>
    <div class="check-switch-box mb-2 pl-3 pr-2">
      <p class="mb-0 d-title__4">Order Approvals</p>
      <b-form-checkbox switch class="custom-chk"></b-form-checkbox>
    </div>
    <p class="mt-4 mb-2 d-title__2">SMS Notification</p>
    <div class="check-switch-box pl-3 pr-2">
      <p class="mb-0 d-title__4">Order Approvals</p>
      <b-form-checkbox switch class="custom-chk"></b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'
.notification-settings
  .check-switch-box
    min-height: 57px
    border-radius: 4px
    border: solid 1px #E7ECEE
    display: flex
    justify-content: space-between
    align-items: center
  ::v-deep .custom-chk
    input
      &:focus:not(:checked) ~ .custom-control-label::before
        // border-color: #{$red2} !important
    .custom-control-label
      &::before
        // border-color: #{$red2}
        // background-color: #{$red2}
      &::after
        // background-color: #ffffff
</style>