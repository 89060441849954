<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 800px;">Get Insightful Analytics with Ordo</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Make Informed Decisions with our Comprehensive Analytics</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2 mb-5">Are you tired of guessing about your spending habits? With Ordo's analytics feature, you don't have to. Our platform provides comprehensive insights into your spending habits, allowing you to make informed decisions and stay within budget.</p>
        <p class="subtitle-3 mb-0">With Ordo's analytics, you can track your most frequently used vendors, your average spend per order, your budget vs. spend history, and much more. This feature allows you to identify areas where you can cut costs and optimize how much you spend in supplies.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Analyze_Supplies/analyze_supplies_1.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/one.svg)
</style>