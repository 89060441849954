<template>
  <div>
    <div class="recently-order-items" v-if="recentOrders.length > 0">
      <!-- <p class="d-title__8 px-2">Recently Orderd</p> -->
      <div class="d-flex flex-wrap products-wrapper">
        <div v-for="(order, oI) in recentOrders" :key="oI" class="w-100 d-card px-3 py-28-px mb-3">
            <!-- <product-card-horizontal :product="product" /> -->
          <product-card :inventory-in-search="true" :inventoryProduct="true" :supplierAsCard="true" :prod="order" @show="v=>$emit('show',v)" :addToCartBtn="false" :cart-data="cartData" />
          <!-- <div class="dash my-3" v-if="oI != recentOrders.length - 1"></div> -->
        </div>
      </div>
      <!-- <div class="dash my-4"></div> -->
    </div>
    <span v-else></span>
  </div>
</template>

<script>
import ProductCard from '@/components/shopping/ProductCardHorizontal'
export default {
  components: {
    ProductCard,
  },
  props: {
    recentOrders: Array,
    cartData: Array,
  },
}
</script>

<style lang="sass" scoped>

.products-wrapper
  // .product-card
  //   width: 24%
  //   margin-right: calc(4% / 4)
  //   margin-bottom: 15px
  //   // &:nth-child
  //   @media screen and (max-width: 992px)
  //     margin-right: 0
  //     width: 100%
</style>