<template>
  <b-modal :size="size" v-model="inviteModal" :dialog-class="dialogClass" :content-class="modalClass"
    :hide-backdrop="hideBackdrop" hide-footer hide-header centered @change="toggle"
    :no-close-on-backdrop="noCloseOnBackdrop">
    <!-- <b-icon icon="x" class="close-icon cursor-pointer" font-scale="1.7" @click="closeModal"
      v-if="!hideCloseIcon"></b-icon> -->
    <slot />
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    modal: Boolean,
    size: {
      type: String,
      default: 'md'
    },
    cClasses: {
      type: String,
      default: ''
    },
    dialogClass: {
      type: String,
      default: ''
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    hideCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inviteModal: false,
    }
  },
  created() {
    this.inviteModal = this.modal || false
  },
  watch: {
    modal(v) {
      this.inviteModal = v
    }
  },
  computed: {
    modalClass() {
      let classes = 'custom-modal'
      if (this.dark) classes += ' dark'
      return classes + ' ' + this.cClasses
    },
  },
  methods: {
    toggle(v) {
      this.inviteModal = v
      this.$emit('change', this.inviteModal)
    },
    closeModal() {
      this.toggle(false)
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .modal-dialog
  // max-width: 700px

::v-deep .custom-modal
  border-radius: 10px
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05)
  border: solid 1px rgba(14, 49, 120, 0.1)
  background-color: #ffffff
  padding: 46px
  position: relative
  .modal-body
    padding: 0
  @media screen and (max-width: 800px)
    padding: 15px
  .close-icon
    position: absolute
    top: -16px
    right: -16px
    color: #FF1DC3
    @media screen and (max-width: 800px)
      display: block
      position: absolute
      right: -10px
      top: -10px

</style>