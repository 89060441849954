<template>
  <div class="align-items-center">
    <b-dropdown right class="menu-drp mr-2" menu-class="ven-fil py-0" ref="sortPrice">
      <template #button-content>
        <div class="select-wrapper amazon d-flex align-items-center px-2 cursor-pointer">
          <p class="my-0 d-desc text-white mx-2 gilroy-b">Sort by Price</p>
          <CaretDown class="text-white" />
        </div>
      </template>
      <div class="cm-body">
        <div class="py-3 px-3">
          <div class="d-flex gap-10-px">
            <div class="fil-price p-2">
              <p class="f-10-px text-primary mb-2">Min Price</p>
              <div class="d-flex align-items-center">
                <p class="mb-0 text-success f-24-px">$</p>
                <b-input type="number" min="0" v-model="minPrice" class="custom-input back-no-img"></b-input>
              </div>
            </div>
            <div class="fil-price p-2">
              <p class="f-10-px text-primary mb-2">Max Price</p>
              <div class="d-flex align-items-center">
                <p class="mb-0 text-success f-24-px">$</p>
                <b-input type="number" min="0" v-model="maxPrice" class="custom-input back-no-img"></b-input>
              </div>
            </div>
          </div>
          <div class="d-flex mt-4 justify-content-end gap-8-px">
            <b-btn class="custom-btn danger__2 f-10-px"
              style="min-height: 40px; height: 40px !important; min-width: 100px;" @click.stop="clearPriceFilter" v-if="(
                minPrice != null && String(minPrice).trim().length > 0 && Number(minPrice) > 0 ||
                maxPrice != 0 && String(maxPrice).trim().length > 0
              )">
              <span>Clear</span>
            </b-btn>
            <b-btn class="custom-btn primary f-10-px" style="min-height: 40px; height: 40px !important; min-width: 100px;"
              @click="search(undefined, $route.query.product_id, $route.query.product_name, $route.query.q, true, true)">
              <span>Submit</span>
            </b-btn>
          </div>
        </div>
      </div>
    </b-dropdown>
    <b-dropdown right class="menu-drp" menu-class="ven-fil" ref="venDrp">
      <template #button-content>
        <div class="select-wrapper amazon d-flex align-items-center px-2 cursor-pointer">
          <p class="my-0 d-desc text-white mx-2 gilroy-b">Sort by Vendor</p>
          <CaretDown class="text-white" />
        </div>
      </template>
      <div class="cm-body">
        <div class="d-flex flex-column flex-lg-row gap-y-8-px align-items-center justify-content-between px-2">
          <p class="my-0 d-desc__2 gilroy-b" style="color: #000; line-height: 1">Sort by Vendor</p>
          <div class="d-flex gap-8-px align-items-center">
            <b-btn class="custom-btn deselect-btn" @click.capture.stop="toggleSelection">{{ allSelected ? 'Deselect All' :
              'Select All' }}</b-btn>
            <b-btn class="custom-btn deselect-btn"
              @click.capture.stop="search(undefined, $route.query.product_id, $route.query.product_name, $route.query.q, false, true)">Update</b-btn>
          </div>
        </div>
        <div class="vendors px-2">
          <div v-for="(vendor, vI) in vendorsInSearch" :key="vI">
            <div :class="['dash', { 'mt-3': vI == 0 }]"></div>
            <div class="d-flex py-3 cursor-pointer" @click.capture.stop="toggleVendorSearchFilter(vI)">
              <div class="ven-ck mr-2 px-2-px">
                <img src="@/assets/images/pink_check_mark.png" alt=""
                  style="vertical-align: unset; max-width: 100%; max-height: 100%" v-if="vendor.checked">
              </div>
              <p class="my-0 d-desc__2 gilroy-b" style="color: #000">{{ vendor.vendor.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import CaretDown from '@/components/svg/icons/solid/CaretDown.vue'
import { integer } from 'vee-validate/dist/rules'
export default {
  props: {
    allSelected: Boolean,
    canSetVenInSearch: Boolean,
    vendorsInSearch: Array,
    filterByPrice: Object,
    search: Function,
    toggleSelection: Function,
    toggleVendorSearchFilter: Function,
    maxPrice: integer,
    minPrice: integer,
  },
  components: { CaretDown, },
  data: () => ({
    // minPrice: 0,
    // maxPrice: 0,
  }),
  created() {
    this.setPriceFilterData(true)
  },
  watch: {
    filterByPrice: {
      handler() { this.setPriceFilterData() },
      deep: true,
    },
    minPrice(val) {
      if (this.filterByPrice.values[0] != this.minPrice) this.$emit('change-price-filter-min', val)
    },
    maxPrice(val) {
      console.log(val)
      if (val != 0) { if (this.filterByPrice.values[1] != this.maxPrice) this.$emit('change-price-filter-max', val) }
    },
  },
  methods: {
    setPriceFilterData(checkRoute = false) {
      if (checkRoute) {
        let min = Number(this.$route.query.price_from)
        let max = Number(this.$route.query.price_to)
        if (!isNaN(min) && min >= 0) this.minPrice = min
        if (!isNaN(max) && max > min) this.maxPrice = max
      }
      else {
        if (this.filterByPrice.values[0] != this.minPrice) this.minPrice = this.filterByPrice.values[0]
        if (this.filterByPrice.values[1] != this.maxPrice) this.maxPrice = this.filterByPrice.values[1]
      }
    },
    clearPriceFilter() {
      this.minPrice = 0
      this.maxPrice = null
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'
.fil-price
  border: 1.2px solid #EDF4F7
  border-radius: 4px
  width: 50%
  .custom-input
    border: none
    font-size: 24px
    color: #{$green}
    padding-left: 2px
::v-deep .ven-fil
  padding-left: 0 !important
  padding-right: 0 !important
  max-width: 95vw
  @media screen and (min-width: 992px)
    top: 4px !important
    left: 41px !important
    min-width: 285px !important
  @media screen and (max-width: 992px)
    max-height: calc( 100vh - 74px - 122px - 22px )
    width: 95vw
  .cm-body
    .vendors
      max-height: 290px
      overflow-y: auto
      @media screen and (max-width: 992px)
        max-height: 265px
    .deselect-btn
      min-height: 31px
      height: 31px !important
      min-width: 81px
      border-radius: 4px !important
      font-size: 10px !important
    .ven-ck
      width: 20px
      height: 20px
      border-radius: 4px
      border: solid 1px #ededed
::v-deep .select-wrapper
  width: 100%
  max-width: 119px
  background-color: #{$blue}
  &.amazon
    max-width: 170px
    height: 36px
    p
      line-height: normal
      color: #{$blue}
    .custom-chk
      label
        &::after
          background-color: #d0eff7
          border-radius: 4px
          border: solid 1px #{$blue}
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after
      background-color: #{$blue}
.custom-input
  width: 100%
  height: 36px !important
  border-radius: 12px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  @media screen and (max-width: 992px)
    width: auto
.custom-btn-div
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 8px
.custom-btn
  max-width: 124px
  width: max-content
  height: 46px !important
  border-radius: 4px !important
  font-size: 14px !important
  &.my-inv-btn
    min-height: 28px !important
    height: 28px !important
    font-size: 10px !important
    min-width: max-content
    margin-left: auto
</style>