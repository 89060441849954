<template>
  <div class="auth-layout py-5">
    <div class="con d-flex flex-column align-items-center">
      <div @click="onLogoClick">
        <auth-logo class="mb-5 logo" />
      </div>
      <router-view></router-view>
    </div>
    <img src="@/assets/images/onboarding/login_forgot_onboarding.webp" alt="" class="b-image">
  </div>
</template>

<script>
import AuthLogo from '@/components/auth/AuthLogo'
export default {
  components: { AuthLogo, },
  methods: {
    onLogoClick(){
      this.$router.push({name: 'Landing'})
    },
  },
}
</script>

<style lang="sass" scoped>
.auth-layout
  position: relative
  .con
    position: relative
    z-index: 10
  .b-image
    position: absolute
    height: 335px
    top: calc(50vh - (335px/2))
    width: 100vw
    @media screen and (max-width: 992px)
      display: none
  .logo
    width: 160px
    height: 49px
</style>