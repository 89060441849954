<template>
  <div>
    <div class="notf-surr" v-if="!officeBudgetSettings">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">Office Name</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text">{{ office.name }}</p>
            <b-btn class="custom-btn sm-btn" @click="setEditMode('name')">Edit</b-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr" v-if="!officeBudgetSettings">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">Office Address</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text" v-if="officeAddress">{{ officeAddress }}</p>
            <b-btn class="custom-btn sm-btn" @click="ofcDetailsModal = true">{{ officeAddress ? 'Edit' : 'Add' }}</b-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr" v-if="!officeBudgetSettings">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">Office Logo</p>
        </div>
        <div class="ml-auto">
          <div class="d-flex">
            <div class="pr-2 mr-4" v-if="!editMode.logo">
              <p class="mb-0 d-desc__3 c-text c-avatar" v-if="office.logo">
                <img :src="office.logo" alt="" class="w-100 h-100">
              </p>
              <p class="mb-0 d-desc__3 c-text c-avatar" style="font-size: 24px" v-else>
                <img src="@/assets/images/office_icon.png" alt="" class="w-100 h-100">
              </p>
            </div>
            <b-btn class="custom-btn sm-btn" @click="setEditMode('logo')" v-if="!editMode.logo">{{ office.logo ?
              'Edit' : 'Add' }}</b-btn>
            <validation-observer ref="logoObs" v-slot="{ handleSubmit }" v-if="editMode.logo">
              <b-form @submit.stop.prevent="handleSubmit(update)" class="d-flex">
                <validation-provider name="logo" rules="required" v-slot="validationContext">
                  <b-form-file @input="filePicked" v-model="file" class="custom-input file-picker mr-lg-2 mb-2 mb-lg-0"
                    placeholder="Upload from Computer" :state="getValidationState(validationContext)"></b-form-file>
                </validation-provider>
                <Button text="Save" classes="sm-btn ml-lg-2" :submitting="submitting" :onclick="update" />
                <b-btn :class="['custom-btn sm-btn ml-lg-2 danger__2']" @click="editMode.logo = false">Cancel</b-btn>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!fetchingBudget && officeBudget[office.id] && officeBudgetSettings">
      <div class="notf-surr">
        <div class="check-switch-box mb-2 pl-3 pr-2">
          <div class="notf-p1">
            <p class="mb-0 d-title__4">Manually Set Adjusted Production / Collections</p>
          </div>
          <div class="ml-auto">
            <div class="sm-card">
              <p class="mb-0 d-desc__3 mr-4 c-text">
                $ {{ officeBudget[office.id].basis == 1 ? officeBudget[office.id].adjusted_production :
                  officeBudget[office.id].collection || '0.00' }}
              </p>
              <!-- <b-btn class="custom-btn sm-btn" @click="onClickEditAdjustedCol">Edit</b-btn> -->
              <b-btn class="custom-btn sm-btn" @click="onClickEditAdjustedCol" >Edit</b-btn>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!fetchingBudget && officeBudget[office.id] && officeBudgetSettings">
      <div class="notf-surr" v-for="(subaccount, index) in officeBudget[office.id].subaccounts" :key="index">
        <div class="check-switch-box mb-2 pl-3 pr-2">
          <div class="notf-p1">
            <p class="mb-0 d-title__4">
              <span v-if="subaccount.slug == 'dental'">Dental Supply Budget</span>
              <span v-else-if="subaccount.slug == 'office'">Front Office Supply Budget</span>
              <span v-else-if="subaccount.slug == 'miscellaneous'">Uncategorized Budget</span>
              <span v-else><span>{{ subaccount.name }}</span> Budget</span>
            </p>
          </div>
          <div class="ml-auto">
            <div class="sm-card">
              <p class="mb-0 d-desc__3 mr-4 c-text" v-if="office.budget">
                <span v-if="calculateBudget({ slug: subaccount.slug, officeId: office.id })">
                  $ {{ calculateBudget({ slug: subaccount.slug, officeId: office.id }) }}
                </span>
              </p>
              <RemoveBudget :subaccount="subaccount" :office-id="office.id"
                v-if="!['dental', 'office', 'miscellaneous'].includes(subaccount.slug)  && shouldShowEditButton()" />
                <b-btn v-if="subaccount.slug != 'miscellaneous' && shouldShowEditButton()" class="custom-btn sm-btn" @click="onClickEditBudget(subaccount.slug)">Edit</b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr" v-if="office.practice_software == 'open_dental' && officeBudgetSettings">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">
            Open Dental Account
            <img src="@/assets/images/open-dental.png" alt="" class="ml-1">
          </p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text" v-if="office.dental_api">{{ office.dental_api.key }}</p>
            <OpendentalActions :office="office" />
          </div>
        </div>
      </div>
    </div>
    <modal :modal="editMode.name" @change="toggleNameModal" size="md">
      <div>
        <office-detail-form :single="true" @done="toggleNameModal(false); $forceUpdate()" :office="office"
          :onlyDetail="true" :showAdditionalEditButton="showAdditionalEditButton" />
      </div>
    </modal>
    <modal :modal="ofcModal" @change="toggleOfcModal" size="lg">
      <div>
        <office-budget :slug="selectedSlugForBudget" :single="true" :front-office="frontOffice"
          :adjusted_production_collection_only="adjusted_production_collection_only"
          :is-open-dental-connected="office.dental_api != null && office.dental_api.key != null"
          @done="onOfficeBudgetUpdate" :offices="[office]" />
      </div>
    </modal>
    <modal :modal="ofcDetailsModal" @change="toggleOfcDetailsModal" size="md">
      <div>
        <office-detail-form :single="true" @done="toggleOfcDetailsModal(false); $forceUpdate()" :office="office"
          :showAdditionalEditButton="showAdditionalEditButton" :onlyAddress="true" />
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FormMixin from '@/mixins/FormMixin'
import BudgetMixin from '@/mixins/Budget'
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
import Modal from '@/components/modals/Modal'
import OfficeBudget from '@/components/onboarding/OfficeBudget'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import OfficeDetailForm from '@/components/onboarding/OfficeDetailForm'
import OpendentalActions from './OpendentalActions.vue'
import RemoveBudget from '@/components/onboarding/RemoveBudget.vue'

export default {
  mixins: [FormMixin, GoTo, BudgetMixin],
  components: {
    Button,
    Modal,
    OfficeBudget,
    OfficeDetails,
    OfficeDetailForm,
    OpendentalActions,
    RemoveBudget,
  },
  props: {
    office: Object,
    officeBudgetSettings: Boolean,
    showAdditionalEditButton: { type: Boolean, default: false },
  },
  data: () => ({
    editMode: {
      name: false,
      address: false,
      logo: false,
    },
    formData: {
      name: null,
      address: null,
      logo: null,
    },
    submitting: false,
    file: null,
    ofcModal: false,
    ofcDetailsModal: false,
    imgUploaded: false,
    frontOffice: false,
    selectedSlugForBudget: null,
    adjusted_production_collection_only: false,
  }),
  computed: {
    ...mapState('OFFICE', ['fetchingBudget', 'officeBudget']),
    officeAddress() {
      if (this.office.addresses && this.office.addresses.length > 0) {
        let ad = this.office.addresses[0]
        return `${ad.address}, ${ad.city}, ${ad.state} - ${ad.zip_code}`
      }
      return null
    },
  },
  methods: {
    ...mapActions('OFFICE', ['UPDATE_OFFICE']),
    shouldShowEditButton() {
    const officeBudgetData = this.officeBudget[this.office.id];
    if (!officeBudgetData) return false;

    const adjustedProductionOrCollection = officeBudgetData.basis === 1 ? officeBudgetData.adjusted_production : officeBudgetData.collection;
    return parseFloat(adjustedProductionOrCollection) > 0;
  },
    onClickEditAdjustedCol() {
      this.adjusted_production_collection_only = true
      this.selectedSlugForBudget = null
      this.ofcModal = true
    },
    onClickEditBudget(slug) {
      this.selectedSlugForBudget = slug
      this.adjusted_production_collection_only = false
      this.ofcModal = true
    },
    toggleOfcModal(v) {
      if (v != this.ofcModal) this.ofcModal = v != null ? v : false
      this.frontOffice = this.ofcModal
    },
    toggleOfcDetailsModal(v) {
      if (v != this.ofcDetailsModal) this.ofcDetailsModal = v != null ? v : false
    },
    toggleNameModal(v) {
      if (v != this.editMode.name) this.editMode.name = v != null ? v : false
    },
    filePicked(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.formData.logo = reader.result
        this.imgUploaded = true
      }
    },
    setEditMode(key) {
      if (key == 'name') this.formData.name = this.office.name
      else if (key == 'address') this.formData.address = this.office.address
      else if (key == 'logo') this.formData.logo = this.office.logo
      this.editMode[key] = true
    },
    clearForm() {
      this.formData = {
        name: null,
        address: null,
        logo: null,
      }
    },
    async update() {
      if (this.submitting) return
      this.submitting = true
      let isValid = false
      if (this.editMode.name) isValid = await this.$refs.nameObs.validate()
      if (this.editMode.address) isValid = await this.$refs.addressObs.validate()
      if (this.editMode.logo) isValid = await this.$refs.logoObs.validate()
      try {
        if (isValid) {
          let payload = {}
          payload.name = this.formData.name || this.office.name
          if (this.editMode.address) payload.address = this.formData.address
          if (this.editMode.logo) payload.logo = this.formData.logo
          payload.company = this.office.company
          payload.id = this.office.id
          let res = await this.UPDATE_OFFICE(payload)
          this.clearForm()
          if (this.editMode.name) this.editMode.name = false
          if (this.editMode.address) this.editMode.address = false
          if (this.editMode.logo) this.editMode.logo = false
          this.$forceUpdate()
          this.$emit('change')
        }
      } catch (e) {
        let errors = {
          'Messages': e
        }
        if (this.editMode.name) this.$refs.nameObs.setErrors(errors)
        if (this.editMode.address) this.$refs.addressObs.setErrors(errors)
        if (this.editMode.logo) this.$refs.logoObs.setErrors(errors)
      }
      this.submitting = false
    },
    async onOfficeBudgetUpdate() {
      this.ofcModal = false
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings'
::v-deep .file-picker
  &.custom-file
    bottom: 1px
  label
    // display: none
    border: none
    margin-bottom: 0
    height: 26px
    font-size: 11px
  .custom-file-input
    height: inherit
.sm-btn
  min-width: 110px !important
</style>