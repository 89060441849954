<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 800px;">Access your Invoices with Ease</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Download your Invoices and Credits directly from Ordo</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">View Your Invoices and Credits in One Place</p>
        <p class="subtitle-3 mb-0">All your invoices and credits are stored in one place within your Ordo account, making it easy to view and download them at your convenience. This eliminates the need to search through old emails or paperwork to find the invoices you need. With all your invoices and credits in one location, you can easily track your expenses and credits and stay on top of your budget.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Download_Invoices/down_load_invoices_one.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/three.svg)
</style>