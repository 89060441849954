<template>
  <div class="auth-card">
    <p class="auth-title mb-3">Password Reset</p>
    <p class="auth-link mb-3">Your password has been cleared, enter a new password to complete the reset</p>
    <div class="d-flex justify-content-center align-items-center mb-3" v-if="verifying">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <span>Verifying...</span>
    </div>
    <div class="message" v-if="showMsg && !verifying && !verified">
      <p class="auth-link text-danger">Invalid Token</p>
    </div>
    <reset-pass-form class="mb-3" v-if="verified" />
    <p class="auth-link mb-0">
      <span>Back to</span>
      <span class="text-primary cursor-pointer ml-3" @click="goTo({name: 'Login'})">Log In</span>
    </p>
  </div>
</template>

<script>
import ResetPassForm from '@/components/auth/ResetPassForm'
import GoTo from '@/mixins/GoTo'
import { mapActions } from 'vuex'
export default {
  components: {
    ResetPassForm,
  },
  mixins: [GoTo],
  data: () => ({
    verifying: false,
    verified: false,
    showMsg: false,
  }),
  created(){
    if(!this.token) this.$router.push({name: 'Login'})
    else this.verify()
  },
  computed: {
    token(){
      return this.$route.query.token
    },
  },
  methods: {
    ...mapActions('USER', ['FORGOT_PASSWORD_VALIDATE_TOKEN']),
    async verify(){
      if(this.verifying) return
      this.verifying = true
      try{
        let payload = {token: this.token}
        await this.FORGOT_PASSWORD_VALIDATE_TOKEN(payload)
        this.verified  = true
      }catch(e){
        this.showMsg = true
      }
      this.verifying = false
    },
  },
}
</script>

<style>

</style>