<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <p class="d-title mt-2 mb-0" style="font-size: 18px;">Office Budget Settings</p>
    </div>
    <div class="d-flex flex-column flex-md-row gap-12-px mb-4">
      <p class="mb-0 d-desc__m551" style="width: 450px;">Your office-related data will be here. If anything related to your office details needs to be changed or updated, you can do it below.</p>
      <b-btn class="custom-btn o-btn ml-md-auto add-o-btn" @click="addBudgetModal = true">+ &nbsp; Add Budget</b-btn>
    </div>
    <div v-for="(office, oI) in filteredOffices" :key="oI">
      <div class="dash mb-4" v-if="oI > 0"></div>
      <div class="d-lg-flex justify-content-lg-between align-items-lg-center my-3" :ref="`ofc-set-${oI}`" v-b-toggle="`ofc-set-${oI}`" @click="handleAccordionToggle(oI, office.name)">
        <p class="mb-2 d-title__2" style="font-size: 16px;">{{office.name}}</p>
        <div class="d-flex align-items-center">
          <b-icon icon="chevron-down" class="ml-3 cursor-pointer" style="outline: none"></b-icon>
        </div>
      </div>
      <b-collapse :id="`ofc-set-${oI}`" accordion="office-accordion" role="tabpanel" :visible="oI == 0">
        <office :office="office" :officeBudgetSettings="true" />
      </b-collapse>
    </div>
    <modal :modal="addBudgetModal" @change="toggleAddOfficeModal" size="md" dialog-class="add-custom-budget-dialog">
      <p class="modal-header-title text-center">Create a Custom Budget</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4" style="max-width: 460px;">Creating a custom budget allows you to choose which items are allocated towards a specific budget. An example could be creating  an Implant Budget to track any implant supplies. First create the budget name. Then, select which office(s) you would like this custom budget to be displayed in. Next, you have the option to select a target spend percentage for this custom budget. If no percentage is selected, the custom budget will just track how much you've spent.</p>
      </div>
      <div class="dash mb-3"></div> 
      <div>
        <CustomBudgetForm @done="onDoneCreatingCustomBudget" />
      </div>
    </modal>
  </div>
</template>

<script>
import Office from '@/components/dashboard/settings/Office'
import { mapGetters } from 'vuex'
import Modal from '@/components/modals/Modal'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import OfficeMixin from '@/mixins/OfficeMixin'
import NumberComma from '@/mixins/NumberComma'
import CustomBudgetForm from './CustomBudgetForm.vue'

export default {
  components: {
    Office,
    Modal,
    OfficeDetails,
    CustomBudgetForm,
  },
  mixins: [OfficeMixin, NumberComma],
  data: () => ({
    addBudgetModal: false,
    selectedOfc: null,
  }),
  computed: {
    ...mapGetters('USER', ['offices']),
    filteredOffices(){
      return [this.offices[this.officeIndex]].concat(this.offices.filter(o => o.id != this.officeId))
    },
  },
  methods: {
    toggleAddOfficeModal(v){
      if(v != this.addBudgetModal) this.addBudgetModal = v != null ? v : false
    },
    onDoneCreatingCustomBudget(){
      this.addBudgetModal = false
      this.$BUS.$emit('fetch-budgets')
    },
    handleAccordionToggle(index, officeName, name) {
      this.$emit('accordion-toggle', { index, officeName });
      console.log(index, officeName)
    }
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings' 
.cr-btn
  min-width: 100px
.add-o-btn
  min-width: 119px
</style>