<template>
  <div>
    <GrowLoader v-if="fetchingOpenDentalConnectionStatus || fetchingConnectedVendors" />
    <div class="open-dental-conn-div d-flex justify-content-center align-items-center position-relative"
      v-else-if="!isOpendentalConnected">
      <div class="overlay d-flex justify-content-center align-items-md-center">
        <div class="link-opendental">
          <div class="position-relative py-46-px d-flex flex-column">
            <p class="d-title__7 f-20-px">Your Future Awaits...</p>
            <p class="d-desc f-14-px mt-2 mb-0" style="line-height: 1.7; max-width: 400px;">With Futurecast, we analyze
              your upcoming schedule so that you can easily plan and order your supplies in advance. Use the filter to set
              a time frame and see what procedures are on the schedule so that you have the right amount of inventory on
              hand at all times!</p>
            <b-btn class="custom-btn px-5 mt-4 f-14-px !min-h-45-px" style="max-width: 270px"
              @click="linkOpenDentalModal = true">
              <img src="@/assets/images/open_dental.png" alt="" class="mr-2 w-20-px h-24-px">
              Link Open Dental
            </b-btn>
          </div>
          <img src="@/assets/images/future-cast/futurecast-opendental-char.svg" alt="" class="d-none d-md-inline"
            style="max-height: 310px">
        </div>
      </div>
      <modal :modal="linkOpenDentalModal" @change="toggleLinkOpenDentalModal" size="xl">
        <LinkOpenDentalForm @close="onOpendentalConnectionSuccess" />
      </modal>
    </div>
    <div v-else>
      <!-- <CastHeader /> -->
      <admin-header class="mb-3" title="Welcome to Futurecast"
        desc="With Futurecast, we analyze your upcoming schedule so that you can easily plan and order your supplies in advance. Use the filter to set a time frame and see what procedures are on the schedule so that you have the right amount of inventory on hand at all times!"></admin-header>
      <FilterCom :future-cast="true" />
      <div class="d-flex justify-content-center align-items-center" v-if="fetchingCategory" style="min-height: 300px;">
        <GrowLoader />
      </div>
      <div v-else>
        <div class="d-flex flex-wrap gap-30-px justify-content-between mt-4" v-if="filterBy.show === 'detail'">
          <div v-for="procedureCategory in procedureCategories" :key="procedureCategory.id" class="d-card cast-table">
            <CastTable :category="procedureCategory" />
          </div>
        </div>
        <div v-else>
          <CastCategoryType v-for="t in ['inc', 'dec', 'bal']" :key="t" class="mt-4" :type="t" />
        </div>

      </div>
    </div>
    <DashboardFooter class="mt-4" v-if="!fetchingOpenDentalConnectionStatus && !fetchingConnectedVendors" />
  </div>
</template>
  
<script>
import AdminHeader from '@/views/admin_dashboard/components/admin_header.vue'
import IconSolidQuestionCircle from '@/components/svg/icons/solid/QuestionCircle.vue'
import CastTable from '@/components/future-cast/CastTable.vue'
import CastHeader from '@/components/future-cast/Header.vue'
import CastCategoryType from '@/components/future-cast/CategoryType.vue'
import CastCategoryCard from '@/components/future-cast/CategoryCard.vue'
import CastCategoryCardComingSoon from '@/components/future-cast/CategoryCardComingSoon.vue'
import GrowLoader from '@/components/loaders/GrowLoader.vue'
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import Modal from '@/components/modals/Modal'
import LinkOpenDentalForm from '@/components/dashboard/settings/LinkOpenDentalForm.vue'
import Filter from '@/mixins/Filter'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
import FilterCom from '@/components/dashboard/FilterCom'


export default {
  components: { AdminHeader, IconSolidQuestionCircle, CastTable, GrowLoader, CastHeader, CastCategoryCardComingSoon, draggable, Modal, LinkOpenDentalForm, CastCategoryType, CastCategoryCard, DashboardFooter, FilterCom, },
  mixins: [OfficeMixin, Filter],
  data: () => ({
    text: '',
    fetchingCategory: true,
    updatingOrder: false,
    unlockModal: true,
    linkOpenDentalModal: false,
    fetchingOpenDentalConnectionStatus: false,
    isOpendentalConnected: false,
    searchText: null,
  }),
  created() {
    this.fetchData()
    this.$BUS.$on('filter:filter-change-future-cast', flt => {
      this.onFilterChange(flt, (filters) => {
        // let dateRange = {
        //   'date_range': filters.date_range,
        //   'start_date': filters.selectedDate.startDate,
        //   'end_date': filters.selectedDate.endDate
        // }
        // this.fetchCategories({
        //   date_range: dateRange, start_date: filters.selectedDate.startDate,
        //   end_date: filters.selectedDate.endDate, search: filters.search
        // })
        this.fetchCategories({
          date_range: filters.date_range, search: filters.search
        })
      })
    })
  },
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change-future-cast')
  },
  computed: {
    ...mapGetters('PROCEDURE', ['procedureCategories']),
    ...mapState('PROCEDURE', ['filterBy', 'summaryCategories']),
    ...mapState('OFFICE', ['connected_vendors', 'fetchingConnectedVendors']),
    DUMMY_SUMMARY() {
      return [
        {
          "id": 59,
          "slug": "Exams",
          "order": 1,
          "is_favorite": false,
          "count": 150,
          "avg_count": 179
        },
        {
          "id": 50,
          "slug": "Cleanings",
          "order": 2,
          "is_favorite": false,
          "count": 307,
          "avg_count": 347
        },
        {
          "id": 62,
          "slug": "SRPs",
          "order": 3,
          "is_favorite": false,
          "count": 77,
          "avg_count": 111
        },
        {
          "id": 46,
          "slug": "Whitening",
          "order": 4,
          "is_favorite": false,
          "count": 1,
          "avg_count": 1
        },
        {
          "id": 49,
          "slug": "Ortho",
          "order": 5,
          "is_favorite": false,
          "count": 5,
          "avg_count": 3
        },
        {
          "id": 55,
          "slug": "Nitrous & Sedation",
          "order": 6,
          "is_favorite": false,
          "count": 2,
          "avg_count": 2
        },
        {
          "id": 51,
          "slug": "Implant Crowns",
          "order": 7,
          "is_favorite": false,
          "count": 10,
          "avg_count": 9
        },
        {
          "id": 52,
          "slug": "Bone Graft",
          "order": 8,
          "is_favorite": false,
          "count": 2,
          "avg_count": 10
        },
        {
          "id": 56,
          "slug": "Extractions",
          "order": 10,
          "is_favorite": false,
          "count": 48,
          "avg_count": 73
        },
        {
          "id": 57,
          "slug": "Mouthguards & Nightguards",
          "order": 11,
          "is_favorite": false,
          "count": 5,
          "avg_count": 8
        },
        {
          "id": 58,
          "slug": "Dentures & Partials",
          "order": 12,
          "is_favorite": false,
          "count": 6,
          "avg_count": 4
        },
        {
          "id": 60,
          "slug": "Crowns, Bridges & Veneers",
          "order": 13,
          "is_favorite": false,
          "count": 71,
          "avg_count": 79
        },
        {
          "id": 61,
          "slug": "Implant Placement",
          "order": 14,
          "is_favorite": false,
          "count": 4,
          "avg_count": 7
        },
        {
          "id": 63,
          "slug": "Fillings",
          "order": 16,
          "is_favorite": false,
          "count": 190,
          "avg_count": 242
        },
        {
          "id": 53,
          "slug": "Root Canals",
          "order": 18,
          "is_favorite": false,
          "count": 21,
          "avg_count": 27
        }
      ]
    },
  },
  watch: {
    // [`filterBy.show`](){
    //   this.fetchCategories()
    // },
    fetchingConnectedVendors(v) {
      if (!v) this.fetchData()
    },
  },
  methods: {
    ...mapActions('PROCEDURE', ['FETCH_PROCEDURE_CATEGORY', 'FETCH_PROCEDURE_SUMMARY_CATEGORY', 'UPDATE_SUMMARY_CATEGORY']),
    ...mapActions('OFFICE', ['FETCH_OPENDENTAL_CONNECTION_STATUS']),
    onOpendentalConnectionSuccess() {
      this.linkOpenDentalModal = false
      this.fetchData()
    },
    async fetchData() {
      if (!this.fetchingConnectedVendors) {
        // let openDentalVendor = this.connected_vendors.find(v => v.vendor.slug == '')
        await this.fetchOpenDentalConnectionStatus()
        this.fetchCategories()
      }
    },
    toggleUnlockModal(v) {
      if (v != this.unlockModal) this.unlockModal = v || false
    },
    toggleLinkOpenDentalModal(v) {
      if (v != this.linkOpenDentalModal) this.linkOpenDentalModal = v != null ? v : false
    },
    async search() { },
    async fetchOpenDentalConnectionStatus() {
      this.fetchingOpenDentalConnectionStatus = true
      try {
        let data = await this.FETCH_OPENDENTAL_CONNECTION_STATUS({ office: this.officeId })
        this.isOpendentalConnected = data.connected
      } catch (e) { console.log(e) }
      this.fetchingOpenDentalConnectionStatus = false
    },
    async fetchCategories(filters = { date_range: this.filterBy.dateRange }) {
      this.fetchingCategory = true
      try {
        if (this.filterBy.show == 'detail') {
          await this.FETCH_PROCEDURE_CATEGORY({ office: this.officeId, filters })
        } else {
          await this.FETCH_PROCEDURE_SUMMARY_CATEGORY({ office: this.officeId, filters })
        }
      } catch (e) { console.log(e) }
      this.fetchingCategory = false
    },
    async onChangeByDrag(e) {
      if (e.moved) {
        this.updatingOrder = true
        try {
          let payload = {
            office: this.officeId,
            id: e.moved.element.id,
            category_order: e.moved.newIndex + 1,
            category_prev_order: e.moved.oldIndex + 1
          }
          await this.UPDATE_SUMMARY_CATEGORY(payload)
        } catch (e) { console.log(e) }
        this.updatingOrder = false
      }
    },
  },
}
</script>
  
<style lang="sass" scoped>
  
  .search-wrp-form
    width: 100%
    max-width: 355px
  .wrp  
    background-color: #FAFAFA
    border: 1px solid #F0F0F0
    border-radius: 8px
  
  .cast-table
    width: calc( 50% - 15px )
    min-height: 487px
  
  .close-icon-btn
    position: absolute
    right: 0
    top: 0
    
  
  .open-dental-conn-div
    min-height: calc(100vh - 62px - 24px - 12px)
    background: linear-gradient(120.66deg, #C73CEA -9.54%, #50AAD5 52.67%, #5EBC3B 123.91%)
    border-radius: 10px
    // position: relative
    // @media screen and (min-width: 768px)
    // .dummy-summary-categories
    //   height: max-content
    //   width: 100%
    // .overlay
    //   height: 100%
    //   width: 100%
    //   background-color: rgba(0, 0, 0, 0.5)
    //   border-radius: 12px
    .link-opendental
      background-color: #fff
      border-radius: 10px
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05)
      border: solid 1px rgba(14, 49, 120, 0.1)
      background-color: #ffffff
      padding: 0 46px
      max-width: 745px
      position: relative
      z-index: 1
      display: flex
      gap: 2rem
  </style>