import axios from '@/axios'
import moment from 'moment'
import getQuery from '@/mixins/queryStringBuilder'

const state = {
  summaryCategories: [],
  categories: [],
  filterBy: {
    show: 'all',
    dateRange: 'nextWeek',
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD')
  },
}

const getters = {
  procedureCategories: s => s.categories,
}

const mutations = {
  SET_PROCEDURE_CATEGORIES: (s, v) => s.categories = v,
}

const actions = {
  FETCH_PROCEDURE_REPORT: async ({rootGetters, dispatch}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/procedures/report` + getQuery(payload.filters)
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PROCEDURE_REPORT_SUMMARY: async ({rootGetters, dispatch}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/procedures/summary_detail` + getQuery(payload.filters)
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PROCEDURE_SUMMARY_CATEGORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/procedures/summary_category` + getQuery(payload.filters || {})
      let { data } = await axios.reqApi(url).get()
      let categories = data.data
      let cats = []
      for(let key in categories){
        cats.push({
          slug: key,
          ...categories[key],
        })
      }
      state.summaryCategories = cats
      return cats;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PROCEDURE_CATEGORY: async ({rootGetters, commit}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/procedures/category` + getQuery(payload.filters || {})
      let { data } = await axios.reqApi(url).get()
      let categories = data.data.map(d => ({
        id: d[0],
        name: d[1],
        count: d[2]
      }))
      commit('SET_PROCEDURE_CATEGORIES', categories)
      return categories;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_INV_PRODUCTS_FOR_SUMMARY_CATEGORY: async ({rootGetters, commit}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/procedure-links/linked-products` + getQuery(payload.filters || {})
      let { data } = await axios.reqApi(url).get()
      return data
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_SUMMARY_CATEGORY: async ({rootGetters, state}, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const id = payload.id
      delete payload.office
      delete payload.id
      let url = `companies/${companyId}/offices/${office}/procedure-links/${id}`
      let { data } = await axios.reqApi(url).put(payload)
      let index = state.summaryCategories.findIndex(c => c.id == id)
      if(index > -1){
        state.summaryCategories[index] = {
          ...state.summaryCategories[index],
          ...payload
        }
      }
      return data
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}