<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Edge Endo</p>
        <!-- <p class="subtitle-1 mx-auto mb-4 text-white" style="max-width: 590px;">Easy integration, reliable delivery, great documentation: integrate with the email API that developers love and start sending in minutes.</p> -->
        <!-- <div class="d-flex flex-wrap justify-content-center align-items-center gap-16-px mb-5">
          <RouterLink to="/onboarding">
            <Button class="px-4 !min-h-45-px f-14-px" text="Start 14 Day Free Trial" />
          </RouterLink>
        </div> -->
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="subtitle-3 mb-5">Edge Endo is a dental supply company that specializes in providing high-quality endodontic products to dental professionals. The company offers a range of endodontic instruments, files, and accessories that are designed to simplify endodontic procedures and improve patient outcomes. Edge Endo is committed to advancing endodontic care through research and development, and offers innovative solutions such as nickel titanium rotary files and bioactive sealers to improve the efficiency and effectiveness of endodontic treatments.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import CheckCircleIcon from './CheckCircleIcon.vue'
export default {
  components: { Button, CheckCircleIcon }
}
</script>

<style lang="sass" scoped>
@import ./_vendors
</style>