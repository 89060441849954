<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Link your Open Dental account with Ordo to get automated features</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Ordo syncs directly with your Open Dental account so you’ll have access to powerful tools like futurecast and automatic budget updates</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2 mb-3">Utilize all of Ordo features by linking your Open Dental account</p>
        <p class="subtitle-3 mb-3">Integrating Ordo into your open dental software is a seamless and efficient process that allows you to take your ordering skills to the next level. By linking your Open Dental account to Ordo, you can easily access all the features and benefits Ordo has to offer, including automatic budget updates and futurecast.</p>
        <p class="subtitle-3 mb-3">By letting Ordo automatically update your budget, you'll no longer have to manually pull reports and update your budget monthly, saving you and your team time. Additionally, Ordo's futurecast feature allows you to project the supplies you'll need based on your future schedule, enabling you to make informed decisions about what you need to order and when you need to order.</p>
        <p class="subtitle-3 mb-5">Integrating Open Dental with Ordo will allow you to run a more efficient and effective dental practice!</p>
        
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/seven.svg)
</style>