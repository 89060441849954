<template>
  <div>
    <search-head-cover class="mb-3" title="Welcome to Futurecast"
      desc="With Futurecast, we analyze your upcoming schedule so that you can easily plan and order your supplies in advance. Use the filter to set a time frame and see what procedures are on the schedule so that you have the right amount of inventory on hand at all times!"></search-head-cover>
    <FilterCom :future-cast="true" />
  </div>
</template>

<script>
import IconSolidIncrease from '@/components/svg/icons/solid/Increase.vue'
import IconSolidDecrease from '@/components/svg/icons/solid/Decrease.vue'
import IconSolidBalance from '@/components/svg/icons/solid/Balance.vue'
import SearchHeadCover from '@/components/SearchHeadCover'
import FilterCom from '@/components/dashboard/FilterCom'
import { mapState } from 'vuex'
import Filter from '@/mixins/Filter'

export default {
  components: { IconSolidIncrease, IconSolidDecrease, IconSolidBalance, SearchHeadCover, FilterCom },
  mixins: [Filter],
  data: () => ({
    dateRanges: [
      { value: 'thisMonth', text: 'Current Month', },
      { value: 'thisQuarter', text: 'Current Quarter', },
      { value: 'lastQuarter', text: 'Last Quarter', },
      { value: 'lastMonth', text: 'Last Month', },
      { value: 'last2Months', text: 'Last 2 Months', },
      { value: 'last3Months', text: 'Last 3 Months', },
      { value: 'thisYear', text: 'Current Year', },
      { value: 'last12Months', text: 'Last 12 Months', },
      { value: 'lastYear', text: 'Last Year', },
    ]
  }),
  created() {
    this.$BUS.$on('filter:filter-change-future-cast', flt => {
      this.filterBy.dateRange = flt.date_range

      this.onFilterChange(flt, (filters) => {
        this.filterBy.from = filters.selectedDate.startDate
        this.filterBy.to = filters.selectedDate.endDate
      })
    })
  },
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change-future-cast')
  },
  computed: {
    ...mapState('PROCEDURE', ['filterBy']),
    selectedDateRange() {
      if (this.filterBy.dateRange != null) {
        return this.dateRanges.find(r => r.value === this.filterBy.dateRange)
      }
      return { value: null, text: 'Upcoming Work' }
    },
  },
}
</script>

<style lang="sass" scoped>

.text-c1
  color: #284458
.drp-option
  cursor: pointer
  &.active
    background-color: #EDF4F7
</style>