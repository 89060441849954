<template>
  <validation-observer ref="ofcDetailObserver" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(next)">
      <div :class="['office-details', { 'w-100 no-shadow': single }]">
        <p class="c-title" v-if="!settingsPage">Sub-Company Details</p>
        <div :class="[{ 'px-3': settingsPage }]">
          <validation-provider name="Office Name" rules="required" v-slot="validationContext" v-if="willShow('detail')">
            <p class="form-label mb-1">Company Name</p>
            <b-input v-model="formData.name" class="custom-input" placeholder="Enter Company Name" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <div class="d-flex align-items-center mt-3" v-if="showPrevAddressOption">
            <b-checkbox class="custom-chk" v-model="setPrevAddress" @change="onSetPrevAddress"></b-checkbox>
            <span class="form-label" style="color: #a9aaaa;">Apply same address of previous office</span>
          </div>
          <!-- <validation-provider name="address" rules="required" v-slot="validationContext"
            v-if="willShow('address') && !setPrevAddress">
            <p class="form-label mb-1 mt-3">Address</p>
            <b-input v-model="formData.addresses[0].address" class="custom-input" placeholder="Enter address"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider> -->
         
          <!-- <validation-provider name="phone_number" rules="required" v-slot="validationContext" v-if="willShow(null)"
            :custom-messages="{ 'required': 'Required' }">
            <p class="form-label mb-1 mt-3">Phone</p>
            <b-input v-model="formData.phone_number" class="custom-input" placeholder="Enter Phone Number" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <validation-provider name="practice_software" rules="required" v-slot="validationContext" v-if="willShow(null)">
            <p class="form-label mb-1 mt-3">Practice Software</p>
            <b-select :options="practiceSoftwares" v-model="formData.practice_software" class="custom-input"
              placeholder="Practice Software" :state="getValidationState(validationContext)"></b-select>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <validation-provider name="website" rules="" v-slot="validationContext" v-if="willShow(null)">
            <p class="form-label mb-1 mt-3">Website (Optional)</p>
            <b-input v-model="formData.website" class="custom-input" placeholder="Enter Website" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider> -->
        </div>
      </div>
      <div class="d-flex flex-column align-items-center mt-4">
        <!-- <Button v-if="!single || willShow('show-btn')" :text="office ? 'Update' : 'Add another company'"
          class="o-btn mb-3" :onclick="() => createSubCompany()" :submitting="submittingAnotherOffice" /> -->
        <Button v-if="!office" text="Save and Continue" class="o-btn" :submitting="submittingSaveAndContinue"
          :onclick="preCreateSubCompany" />
      </div>
      <p class="text-success text-center f-12-px gilroy-b cursor-pointer mt-3 mb-0" v-if="(showSuccessMsg)">{{ msg }}</p>
    </b-form>
  </validation-observer>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
// import { SlideYUpTransition } from "vue2-transitions"
import { mapActions } from 'vuex'
import FormMixin from '@/mixins/FormMixin'
import US_STATES from '@/assets/json/usa_states.json'
import US_CITIES from '@/assets/json/usa_cities_with_states.json'
export default {
  mixins: [FormMixin],
  components: {
    Input,
    Button,
    // SlideYUpTransition,
  },
  props: {
    prevOffices: Array,
    single: Boolean,
    office: Object,
    onlyAddress: Boolean,
    onlyDetail: Boolean,
    settingsPage: Boolean,
    showPrevAddressOption: { type: Boolean, default: false },
    initOfficeAddresss: Object,
  },
  data: () => ({
    offices: [],
    currentState: null,
    formData: {
      name: null,
      company: null,
      addresses: [
        {
          address: null,
          address_type: 'address',
          city: null,
          state: null,
          zip_code: null,
        }
      ],
      website: null,
      phone_number: null,
      practice_software: 'open_dental',
    },
    submittingAnotherCompany: false,
    submittingSaveAndContinue: false,
    errors: [],
    showSuccessMsg: false,
    msg: null,
    setPrevAddress: false,
    practiceSoftwares: [
      { value: 'open_dental', text: 'OpenDental' },
      { value: 'dentirx', text: 'Dentirx' },
      { value: 'eaglesoft', text: 'EagleSoft' },
      { value: 'curve', text: 'Curve' },
      { value: 'carestack', text: 'Carestack' },
      { value: 'macpractice', text: 'MacPractice' },
      { value: 'other', text: 'Other' },
    ]
  }),
  created() {

    if (this.office) this.setData()
  },
  watch: {
    office: {
      handler(v) {
        // this.setData()
      }, deep: true
    }
  },
  computed: {

    cities() {
      return US_CITIES;
    },
    states() { return US_STATES; },
  },
  methods: {
    ...mapActions('ONBOARDING', ['ONBOARDING_CREATE_OFFICES']),
    ...mapActions('SUBCOMPANY', ['CREATE_SUB_COMPANY', 'GET_ALL_SUB_COMPANY']),
    ...mapActions('USER', ['GET_ACCOUNT']),
    onSetPrevAddress() {
      if (this.initOfficeAddresss) {
        this.formData.addresses[0].address = this.initOfficeAddresss.address
        this.formData.addresses[0].city = this.initOfficeAddresss.city
        this.formData.addresses[0].state = this.initOfficeAddresss.state
        this.formData.addresses[0].zip_code = this.initOfficeAddresss.zip_code
      }
    },
    getStateCode(stateName) {
      for (const state of this.states) {
        if (state.text === stateName || state.value === stateName) {
          return state.value;
        }
      }
      return "Unknown"; // Return "Unknown" for unsupported states
    },
    willShow(name) {
      if (name == 'show-btn') return true
      if (this.onlyAddress && 'address' == name) return true
      if (this.onlyDetail && 'detail' == name) return true
      if (!this.onlyDetail && !this.onlyAddress) return true
    },
    setData() {
      if (this.office) {
        let obj = {}
        if (this.office.id) obj.id = this.office.id
        for (let key in this.formData) {
          obj[key] = this.office[key]
        }
        this.formData = obj
      }
    },
    formatErrors(er) {
      let err = []
      Object.keys(er).forEach(key => {
        err = err.concat(er[key])
      })
      return err
    },
    
    async createSubCompany() {
      if (this.submittingAnotherCompany) return;
      this.submittingAnotherCompany = true;
      let isValid = true;
      isValid = await this.$refs.ofcDetailObserver.validate();

      try {
        if (isValid) {
          let payload;
          payload = {
            name: this.formData.name,
          }
          await this.CREATE_SUB_COMPANY(payload)
          this.msg = 'Sub Company Created Successfully'
          this.showSuccessMsg = true
          this.$emit('getAllSubCompanies');
        }
      }
      catch (e) {
        console.log(e)
      }
      this.submittingAnotherOffice = false
      this.submittingSaveAndContinue = false

    },
    
    async preCreateSubCompany() {
      if (this.submittingSaveAndContinue) return;
      let save = true
      this.submittingSaveAndContinue = true;

      let isValid = await this.$refs.ofcDetailObserver.validate();

      if (isValid) {
        for (let key in this.formData) {
          if (key != 'id' && this.formData[key] != null && !Array.isArray(this.formData[key]) && this.formData[key].trim().length > 0) save = false
        }
        if (!save) {
          let isvalid = await this.validateForm()
          if (isvalid) {
            await this.createSubCompany(true, true)
          }
        }
        else if (!this.single && this.prevOffices.length > 0) this.$emit('next')
        else this.createSubCompany(true, true)
      }
      else {
        this.submittingSaveAndContinue = false;
      }
    },
    clearFormData() {
      this.formData = {
        name: null,
        addresses: [
          {
            address: null,
            address_type: 'address',
            city: null,
            state: null,
            zip_code: null,
          }
        ],
        website: null,
        phone_number: null,
        practice_software: 'open_dental',
      }
      this.$refs.ofcDetailObserver.reset()
    },
  
    async validateForm() {
      let isValid = false;
      for (let key in this.formData) {
        if (key !== 'id' && this.formData[key] != null && String(this.formData[key]).trim().length > 0) {
          isValid = true;
          break;
        }
      }

      return isValid;
    },
    async saveOffice(clear = true) {
      let isValid = await this.validateForm()
      if (!isValid) return
      if (this.selectedOfficeIndex >= 0) this.offices[this.selectedOfficeIndex] = { ...this.formData }
      else this.offices.push({ ...this.formData })
      if (clear) {
        this.$forceUpdate()
        this.clearFormData()
        this.$refs.ofcDetailObserver.reset()
        this.formData = { ...this.prevData }
        this.prev = null
        this.selectedOfficeIndex = -1
      }
    },
    editOfficeDetails(index) {
      this.selectedOfficeIndex = index
      this.prevData = { ...this.formData }
      this.formData = { ...this.offices[index] }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.office-details
  width: 100%
  max-width: 507px
  padding: 32px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  &.no-shadow
    box-shadow: unset
  @media screen and (max-width: 992px)
    max-width: 100%
  ::v-deep .custom-input
    &:disabled
      background-color: #ffffff
</style>