<template>
  <div v-if="!mobile" :class="['d-flex align-items-center mb-3 mb-lg-0', {'active': notfDrp}]">
    <b-dropdown right class="menu-drp w-100" ref="notfDrp" @toggle="v => notfDrp = v">
      <template #button-content>
        <div class="d-flex align-items-center">
          <div class="d-icon">
            <img :src="cartImage" alt="" style="width: 20px; height: 20px;">
            <span class="num-wrapper d-flex justify-content-center align-items-center" v-if="totalQuantity > 0">
              <span class="num-notf d-desc text-white" style="font-size: 10px;line-height: 1;margin-top:2px">{{totalQuantity}}</span>
            </span>
          </div>
          <!-- <p class="d-title__3 mb-0">Shopping Cart</p> -->
        </div>
      </template>
      <cart-contents @close="close" />
    </b-dropdown>
  </div>
  <div class="" v-else>
    <b-dropdown right class="menu-drp w-100" ref="drp" >
      <template #button-content>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-icon">
            <img :src="cartImage" alt="" class="w-100 h-100">
            <span class="num-wrapper d-flex justify-content-center align-items-center" v-if="totalQuantity > 0">
              <span class="num-notf d-desc text-white" style="font-size: 10px;line-height: 1;margin-top:2px">{{totalQuantity}}</span>
            </span>
          </div>
          <p class="d-title__3 mb-0 ml-1 d-none d-lg-block">Shopping Cart</p>
        </div>
      </template>
      <cart-contents @close="close" />
    </b-dropdown>
  </div>
</template>

<script>
import NotificationMenu from '@/components/dashboard/NotificationMenu'
import GoTo from '@/mixins/GoTo'
import UserCard from '@/components/dashboard/UserCard'
import CartContents from '@/components/cart/CartContents'
import { mapGetters } from 'vuex'
export default {
  props: {
    mobile: Boolean,
  },
  components: {
    NotificationMenu,
    UserCard,
    CartContents,
  },
  mixins: [GoTo],
  data: () => ({
    notfDrp: false,
    num: 0
  }),
  beforeDestroy(){
    this.$BUS.$off('items-in-cart')
    this.$BUS.$off('open-cart-tab')
  },
  created(){
    this.$BUS.$on('items-in-cart', v => this.num = v)
  },
  mounted(){
    this.$BUS.$on('open-cart-tab', () => {
      if(this.$refs.notfDrp) this.$refs.notfDrp.show()
    })
  },
  computed: {
    ...mapGetters('CART', ['cartProducts']),
    cartImage(){
      if(this.num == 0) return require('@/assets/images/shopping_cart.png')
      return require('@/assets/images/shopping_cart.png')
    },
    totalQuantity(){
      if(this.cartProducts.length == 0) return 0
      let cartitems = this.cartProducts.filter(c => !c.save_for_later).map(c => c.quantity)
      if(cartitems.length == 0) return 0
      return cartitems.reduce((a,b) => a + b)
    }
  },
  methods: {
    close(){
      this.$refs.notfDrp.hide(true)
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../style/variables'

::v-deep .menu-drp 
  .dropdown-menu 
    width: 520px
    &.show
      left: 80px !important
      top: 16px !important
      @media screen and (max-width: 992px)
        left: 3px !important
        width: calc(100vw - 20px)
        top: 10px !important
  .d-icon
    position: relative
    .num-wrapper
      position: absolute
      top: -8.3px
      right: -7.65px
      background-color: #{$red2}
      height: 19px
      width: 19px
      border-radius: 100%
.d-icon
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 8px
.d-title__3
  @media screen and (max-width: 768px)
    font-size: 10px
  @media screen and (max-width: 350px)
    font-size: 8px
  @media screen and (max-width: 300px)
    display: none
</style>