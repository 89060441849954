<template>
  <div class="welcome-to-ordo py-4 py-lg-5 px-4">
    <div class="wrapper">
      <p class="d-title__8 mb-0 text-white">You have $3,400</p>
      <p class="d-title__8 text-white">remaining in August</p>
      <p class="d-desc__2 text-white" style="max-width: 375px">Hey! Currently we haven’t generated any monthly reports.you’ll recieve your first report on budgeting and spend. </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.welcome-to-ordo
  border-radius: 12px
  box-shadow: 0 3px 25px 0 rgba(235, 235, 235, 0.16)
  background-image: linear-gradient(to bottom, #8bde88, #60c95c)
  // min-height: 200px
  .wrapper
    @media screen and (min-width: 800px)
      max-width: 406px
</style>