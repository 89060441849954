<template>
  <div class="dashboard-page">
    <div class="">
      <div class="d-p-1 order-2 order-lg-1">
        <div class="d-flex flex-wrap justify-content-between align-items-center px-3 py-2 mb-3"
          style="background-color: #FF1DC3; border-radius: 12px;" v-if="showBanner">
          <p class="mb-0">
            <span class="text-white d-title">Finish Onboarding</span> &nbsp;
            <span class="text-white d-title__2">
              <span>{{ ['vendors_linked', 'budget_set', 'team_invited'].map(k => status[k]).filter(i => !i).length
              }}</span>
              of 3 steps remaining
            </span>
          </p>
          <Button text="Complete Onboarding" class="white" style="color: #FF1DC3;"
            :onclick="() => completeOnboardModal = true" />
        </div>
        <search-head-cover class="mb-3" :title="`Welcome to Ordo, ${profile.first_name}!`" redirect="CreateAnOrder"
          :searchField="true" :initText="$route.query.q"
          :desc="`Hey there! My name is Gordo and I'm here to take care of all your ordering needs. If you ever need any help click on the ''Help Center'' button to the left for more information about how things work around these parts`"></search-head-cover>
        <div class="d-card" v-if="loadingBudget">
          <grow-loader />
        </div>
        <div class="d-flex gap-16-px align-items-center w-100" v-else>
          <img src="@/assets/images/left_pol_arr.png" alt="" @click="scroll('left')"
            class="cursor-pointer w-14-px h-18-px hidden"
            :class="[scrollLeft > 0 && cardHolder && cardHolder.getClientRects()[0] && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"
            v-if="cardHolder">
          <div class="d-flex gap-16-px align-items-stretch w-100" style="overflow-x: auto;" ref="cardHolder"
            @scroll="onScroll">
            <pie-chart class="d-card py-3 pie-chart"
              v-for="subaccount in this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts"
              :subaccount="subaccount" :key="subaccount.key" />
          </div>
          <img src="@/assets/images/right_pol_arr.png" alt="" @click="scroll('right')"
            class="cursor-pointer w-14-px h-18-px hidden"
            :class="[!scrollEnded && cardHolder && cardHolder.getClientRects()[0] && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"
            v-if="cardHolder">
        </div>
        <recent-orders class="d-card mt-3" />
      </div>
    </div>
    <modal :modal="startPop" @change="toggleStartPop" size="md" dialog-class="start-pop-modal">
      <div class="d-flex">
        <div>
          <p class="d-title">Welcome aboard</p>
          <p class="d-desc" style="max-width: 340px">Now that you've successfully completed onboarding, you are ready to
            start using Ordo. We are currently pulling your order history from your linked vendors. This typically takes
            5-10 minutes. We'll send you an email when the process is complete. Happy ordering!</p>
          <Button text="Start using Ordo!" class="px-md-4 mt-lg-4" :onclick="() => toggleStartPop(false)" />
        </div>
        <img src="@/assets/images/onboarding_popup.png" alt="" class="start-pop-ch">
      </div>  
    </modal>
    <DashboardFooter class="mt-3" />
    <modal :modal="completeOnboardModal" @change="toggleCompleteOnboardModal" size="lg"
      dialog-class="dashboard-onboarding">
      <p class="d-title text-center">Complete Onboarding</p>
      <Onboarding @close="onCloseOnboard" @step-change="onStepChange" />
    </modal>
  </div>
</template>

<script>
import RecentOrders from '@/components/dashboard/RecentOrders'
import PieChart from '@/components/dashboard/PieChart'
import { mapGetters, mapActions, mapState } from 'vuex'
import SearchHeadCover from '@/components/SearchHeadCover'
import Modal from '@/components/modals/Modal'
import Button from '@/components/ui/Button'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
import Onboarding from '@/views/OnboardingDashboard.vue'
import GrowLoader from '@/components/loaders/GrowLoader'
import OfficeMmixin from '@/mixins/OfficeMixin'

export default {
  head: {
    script: [
      { type: 'text/javascript', src: '/js/fbq_dashboard.js', async: true, body: true },
      { type: 'text/javascript', src: 'https://www.googletagmanager.com/gtag/js?id=G-4PXG8JVSJH', async: true, head: true },
      { type: 'text/javascript', src: '/js/gm_dashboard.js', async: true, body: true },
    ],
  },
  components: {
    RecentOrders,
    PieChart,
    SearchHeadCover,
    Modal,
    Button,
    DashboardFooter,
    Onboarding,
    GrowLoader,
  },
  mixins: [OfficeMmixin],
  data: () => ({
    noUpcOrder: true,
    startPop: false,
    completeOnboardModal: false,
    on_boarding_step: 5,
    scrollLeft: 0,
    scrollEnded: false,
    cardHolder: null,
    showOnboardingBanner: false,
    fetchingOnboardingStatus: true,
  }),
  created() {
    this.fetchOnboardingStatus()
  },
  mounted() {
    this.cardHolder = this.$refs.cardHolder
    this.completeOnboardModal = this.$route.query.enableOnBoarding == null ? false : true;
    if(this.$route.query.fetchGreetings == true){
      this.fetchGreetings()
    }
  },
  computed: {
    ...mapGetters('USER', ['role', 'profile']),
    ...mapGetters('OFFICE', ['connected_vendors']),
    ...mapState('OFFICE', ['fetchingConnectedVendors', 'fetchingBudget', 'fetchingTeamMebers']),
    ...mapState('ONBOARDING', ['status']),
    
    loadingBudget() {
      return this.$store.state.OFFICE.fetchingBudget || this.$store.state.OFFICE.updatingBudget
    },
    subaccounts() {
      return JSON.parse(JSON.stringify(this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts))
    },
    showBanner() {
      return !this.fetchingOnboardingStatus && this.status && (!this.status.vendors_linked || !this.status.budget_set || !this.status.team_invited)
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['FETCH_ONBOARDING_STATUS']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS', 'FETCH_OFFICE_BUDGETS']),
    ...mapActions('GREETINGS', ['FETCH_GREETINGS_BY_ROLE']),
    
    async fetchGreetings() {
      if (this.role != null) {
        let payload = {
          role: this.role
        }
        await this.FETCH_GREETINGS_BY_ROLE(payload)
       
      }
    },

    onShowStartPop() {
      this.startPop = true
    },
    toggleStartPop(v) {
      if (v != this.startPop) this.startPop = v != null ? v : false
    },
    toggleCompleteOnboardModal(v) {
      if (v != this.completeOnboardModal) this.completeOnboardModal = v != null ? v : false
      if (!v) {
        this.FETCH_CONNECTED_VENDORS({ office: this.officeId })
      }
    },
    async fetchOnboardingStatus() {
      await this.FETCH_ONBOARDING_STATUS()
      this.fetchingOnboardingStatus = false
      setTimeout(() => {
        if (this.$route.query.start_pop != null && this.showBanner) {
          this.completeOnboardModal = true
        }
      }, 500)
    },
    onCloseOnboard() {
      this.completeOnboardModal = false
      this.on_boarding_step = 6
    },
    onScroll() {
      let el = this.$refs.cardHolder
      if (el) {
        this.scrollLeft = el.scrollLeft
        if (el.getClientRects()[0].width + this.scrollLeft > el.scrollWidth - 10) {
          this.scrollEnded = true
        } else {
          this.scrollEnded = false
        }
      }
    },
    scroll(direction) {
      let el = this.$refs.cardHolder
      if (el) {
        if (direction == 'right') {
          el.scrollTo(el.scrollLeft + 300, 0)
        }
        if (direction == 'left') {
          el.scrollTo(el.scrollLeft - 300, 0)
        }
      }
    },
    onStepChange() {
      this.on_boarding_step++
    },
  },
}
</script>

<style lang="sass" scoped>
.pie-chart
  min-width: 80%
  max-width: 80%
  @media (min-width: 992px)
    min-width: 29%
    max-width: 29%

.charts
  @media (max-width: 992px)
    overflow-x: auto
    flex-wrap: nowrap
.start-pop-ch
  width: 184px
  height: 249px
  @media (min-width: 992px)
    margin-right: -50px
    margin-left: 50px
  @media (max-width: 992px)
    display: none
</style>