<template>
  <div>
    <vue-range-slider class="px-0" v-model="sliderValue" :min="min" :max="max" :height="3" :dot-width="25" :dot-height="25" tooltip="hover" />
  </div>
</template>

<script>
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'
export default {
  components: {
    VueRangeSlider,
  },
  props: {
    value: Object | Number,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1000000000 },
  },
  data: () => ({
    sliderValue: [100, 3000],
  }),
  created(){
    this.setValue()
  },
  watch: {
    sliderValue: {
      handler(v){ this.$emit('input', v) }, deep: true
    },
    value: {
      handler(v){ this.setValue() }, deep: true
    },
  },
  methods: {
    // setValue(){
    //   if(this.sliderValue != this.value){
    //     this.sliderValue = this.value
    //   }
    // },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .vue-range-slider
  &.slider-component
    .slider
      .slider-dot
        border: 1px solid #50AAD5
      .slider-process
        background-color: #50AAD5 !important
</style>