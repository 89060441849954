<template>
  <div class="drp-contents">
    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center mt-2">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/shopping_cart.png" alt="" style="width: 30px; height: 30px;">
        <p class="d-title__4 mx-2 mb-0" style="font-family: Gilroy-Bold; font-size: 14px; padding-left: 10px;">Shopping
          Cart</p>
      </div>
      <div class="mt-2 mt-lg-0 d-flex" v-if="$route.name !== 'Checkout'">
        <b-btn class="custom-btn" @click="$emit('close')">Continue Shopping</b-btn>
        <b-btn class="custom-btn ml-2" @click="() => { goTo({ name: 'Cart' }); $emit('close') }">Checkout</b-btn>
      </div>
    </div>
    <div class="dash my-3"></div>
    <div v-if="fetchingCart" class="px-2">
      <product-card :skeleton="true" />
    </div>
    <div class="d-flex flex-column align-items-center py-5" v-if="$route.name === 'Checkout' && !fetchingCart">
      <p class="d-title__2 text-center mx-auto" style="max-width: 374px;">You're currently on the checkout page. To make
        a change to your order, please go back to the shopping cart.</p>
      <b-btn class="custom-btn danger__2 px-4" @click="goTo({ name: 'Cart' })">Back to Shopping Cart</b-btn>
    </div>
    <div v-else-if="onlyItemsInCart.length > 0" class="cart-items-wrapper">
      <div v-for="(cartItem, index) in onlyItemsInCart" :key="cartItem.id" class="px-2">
        <div class="dash my-3" v-if="index > 0 || fetchingCart"></div>
        <product-card :product="cartItem.product" :productQuantity="cartItem.quantity" :order="cartItem" />
      </div>
      <p class="mb-0 d-desc text-center cursor-pointer" @click="goTo({ name: 'Cart' })"
        style="font-size: 14px; padding-bottom: 10px;">See all</p>
    </div>
    <div v-else-if="!fetchingCart" class="py-3">
      <no-item-in-cart />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/cart/ProductCard'
import GoTo from '@/mixins/GoTo'
import { mapState } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import CartMixin from '@/mixins/CartMixin'
import NoItemInCart from '@/components/dashboard/NoItemInCart'
import BUS from '@/bus/BUS'
export default {
  components: {
    ProductCard,
    NoItemInCart
  },
  mixins: [GoTo, OfficeMixin, CartMixin,],
  data: () => ({
    fetching: true,
  }),
  beforeDestroy() {
    this.$BUS.$off('fetch-cart')
  },
  created() {
    this.fetchCart(true, { by: 'time' })
    this.$BUS.$on('fetch-cart', async (cartByTime) => {
      if (cartByTime) await this.fetchCart(true, { by: 'time' })
      else await this.fetchCart(true)
    })
  },
  computed: {
    ...mapState('CART', ['fetchingCart'])
  },
}
</script>

<style lang="sass" scoped>
.cart-items-wrapper
  max-height: 80vh
  overflow-y: auto
</style>