<template>
  <div class="d-flex align-items-center">
    <Button class="danger__2 sm-btn" v-if="!fetchingOpenDentalConnectionStatus && isOpendentalConnected" :submitting="unlinkingOpendental" :onclick="unlinkOpendental">
      <span>{{ unlinkButtonText }}</span>
    </Button>
    <Button class="sm-btn" :onclick="() => linkOpenDentalModal = true" v-else-if="!fetchingOpenDentalConnectionStatus">
      <span>{{ linkButtonText }}</span>
    </Button>
    <modal :modal="linkOpenDentalModal" @change="toggleLinkOpenDentalModal" size="xl" :noCloseOnBackdrop="true">
      <LinkOpenDentalForm @success="onSuccess" :officeObj="office" @close="linkOpenDentalModal = false" @fetch-status="fetchOpenDentalConnectionStatus" />
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/modals/Modal'
import Button from '@/components/ui/Button'
import LinkOpenDentalForm from './LinkOpenDentalForm.vue'

export default {
  components: {
    Modal,
    Button,
    LinkOpenDentalForm,
  },
  props: {
    office: Object,
    linkButtonText: { type: String, default: 'Link' },
    unlinkButtonText: { type: String, default: 'Unlink' },
  },
  data: () => ({
    linkOpenDentalModal: false,
    fetchingOpenDentalConnectionStatus: false,
    isOpendentalConnected: false,
    unlinkingOpendental: false,
  }),
  created(){
    this.fetchOpenDentalConnectionStatus()
  },
  methods: {
    ...mapActions('OFFICE', ['FETCH_OPENDENTAL_CONNECTION_STATUS', 'SET_OPENDENTAL_CONNECTION_STATUS', 'UNLINK_OPENDENTAL']),
    onSuccess(){
      this.fetchOpenDentalConnectionStatus()
      this.linkOpenDentalModal = false
      this.$BUS.$emit('fetch-current-budget')
    },
    async unlinkOpendental(){
      if(this.unlinkingOpendental) return
      this.unlinkingOpendental = true
      try{
        let payload = {
          office: this.office.id
        }
        await this.UNLINK_OPENDENTAL(payload)
        this.$emit('conn-update')
        this.isOpendentalConnected = false
      }catch(e){}
      this.unlinkingOpendental = false
    },
    async fetchOpenDentalConnectionStatus(){
      this.fetchingOpenDentalConnectionStatus = true
      try{  
        let data = await this.FETCH_OPENDENTAL_CONNECTION_STATUS({ office: this.office.id })
        this.isOpendentalConnected = data.connected
        this.$emit('conn-update')
      }catch(e){console.log(e)}
      this.fetchingOpenDentalConnectionStatus = false
    },
    toggleLinkOpenDentalModal(v){
      if(v != this.linkOpenDentalModal) this.linkOpenDentalModal = v != null ? v : false
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings' 
.cr-btn
  min-width: 100px
.sm-btn
  width: max-content
  min-width: 106px !important
</style>