<template>
  <div>
    <!-- <dash-head class="mb-4" /> -->
    <grow-loader v-if="fetchingCart" />
    <div v-else class="cart-page">
      <SearchHeadCover
        class="d-lg-none"
        title="Your Cart Details"
        :desc="`View your cart below. Update your quantity, change the vendor, or save an item for later. If you don't want to purchase certain items right now, deselect the ''Send to Checkout'' button and the item will stay in your cart until next time.`"
      />
      <div class="d-flex flex-column flex-lg-row mt-3">
        <div class="l-part order-2 order-lg-1">
          <SearchHeadCover
            class="d-none d-lg-block"
            title="Your Cart Details"
            :desc="`View your cart below. Update your quantity, change the vendor, or save an item for later. If you don't want to purchase certain items right now, deselect the ''Send to Checkout'' button and the item will stay in your cart until next time.`"
          />
          <div v-if="cartProducts.length != savedOrders.length">
            <div
              class="d-card px-3 py-3 mt-3"
              v-for="(vendorId, vId) in vendorIdsHasProducts"
              :key="vId"
            >
              <div
                class="d-lg-flex justify-content-between align-items-center gap-x-47-px gap-y-16-px"
              >
                <div
                  class="d-lg-flex justify-content-between align-items-center gap-12-px"
                >
                  <p class="mb-0">
                    <span class="d-title__11">{{
                      vendorInfo(vendorId).name
                    }}</span>
                    &nbsp;
                    <span class="d-desc__2 f-12-px"
                      >({{
                        vendorOrdersNotSavedForLater(vendorId).length
                      }}
                      item<span
                        v-if="vendorOrdersNotSavedForLater(vendorId).length > 1"
                        >s</span
                      >)</span
                    >
                  </p>
                  <p
                    class="mb-0 d-title"
                    v-if="getDefaultShippingOption(vendorId)"
                  >
                    {{ getDefaultShippingOption(vendorId).name }}
                    <b-tooltip
                      :target="`tooltip-target-${vendorId}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      custom-class="promotion-tooltip tooltip-size-md"
                    >
                      <p class="d-desc mb-0">{{ getShippingNote(vendorId) }}</p>
                    </b-tooltip>
                    <img
                      src="@/assets/images/information.png"
                      alt=""
                      class="ml-2 mb-1 h-20-px w-20-px"
                      :id="`tooltip-target-${vendorId}`"
                      v-if="getShippingNote(vendorId)"
                    />
                  </p>
                </div>

                <!-- <p class="mb-0 d-title ml-auto" v-if="true">
                  Estimated Shipping :
                  <span
                    class="text-success"
                    v-if="
                      Number(
                        getShippingCost(
                          vendorInfo(vendorId).slug,
                          getTotal(vendorId),
                          vId
                        )
                      ) == 0
                    "
                    >Free</span
                  >
                  <span class="text-success" v-else
                    >${{
                      Number(
                        getShippingCost(
                          vendorInfo(vendorId).slug,
                          getTotal(vendorId),
                          vId
                        )
                      ).toFixed(2)
                    }}</span
                  >
                </p>
                -->
                <p class="mb-0 d-title ml-auto" v-if="true">
  Estimated Shipping :
  <span
    class="text-success tooltip-hover"
    v-if="vendorInfo(vendorId).slug === 'patterson'"
  >
  <b-icon class="h5 calculated-icon m-0" icon="info-circle" > </b-icon>
  <span class="tooltip-info">Calculated by Vendor</span>


  </span>
  <span class="text-success" v-else>
    <span v-if="Number(getShippingCost(vendorInfo(vendorId).slug, getTotal(vendorId), vId)) === 0">
      Free
    </span>
    <span v-else>
      ${{ Number(getShippingCost(vendorInfo(vendorId).slug, getTotal(vendorId), vId)).toFixed(2) }}
    </span>
  </span>
</p>




                <p class="mb-0 d-title">
                
                  Total :
                  <span class="text-success"
                    >${{
                      Number(
                        Number(getTotal(vendorId)) +
                          Number(
                            getShippingCost(
                              vendorInfo(vendorId).slug,
                              Number(getTotal(vendorId)), 0
                            )
                          )
                      ).toFixed(2)
                    }}</span
                  >
                </p>
                
              </div>
              <div class="dash my-3"></div>
              <div class="table-section" style="position: unset">
                <table class="w-100 cart-show-table">
                  <thead>
                    <th>Description</th>
                    <th class="text-center">Unit Price</th>
                    <!-- <th class="text-center">Shipping</th> -->
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Add to Checkout</th>
                  </thead>
                  <tbody>
                    <cart-product-tr
                      :ref="`cart-product-tr-${order.id}`"
                      :id="`cart-product-tr-${order.id}`"
                      v-for="(order, vOId) in vendorOrdersNotSavedForLater(
                        vendorId
                      )"
                      :key="vOId"
                      :order="order"
                      @saved="fetchCartCustomised"
                      @deleted="fetchCartCustomised"
                      @change-vendor="fetchCartCustomised"
                      :shippingCost="
                        getShippingCost(
                          vendorInfo(vendorId).slug,
                          vendorOrdersSubTotal(vendorId),
                          vId
                        )
                      "
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="d-card px-3 py-3 mt-3" v-if="savedOrders.length > 0">
            <div class="d-lg-flex justify-content-between align-items-center">
              <!-- <p class="mb-0 d-title">Saved for Later</p> -->
              <p class="mb-0">
                <span class="d-title__11">Saved for Later</span> &nbsp;
                <span class="d-desc__2 f-12-px"
                  >({{ savedOrders.length }} item<span
                    v-if="savedOrders.length > 1"
                    >s</span
                  >)</span
                >
              </p>
              <p
                class="mb-0 d-title text-danger cursor-pointer"
                @click="clearSaveForLaterList"
              >
                <b-spinner
                  style="width: 0.8rem; height: 0.8rem"
                  class="mr-2 text-danger__2"
                  v-if="clearingSaveForLaterList"
                />
                Clear all Items
              </p>
            </div>
            <div class="dash my-3"></div>
            <div class="table-section" style="position: unset">
              <table class="w-100 cart-show-table">
                <thead>
                  <th>Description</th>
                  <th class="text-center">Unit Price</th>
                  <th class="text-center">Quantity</th>
                </thead>
                <tbody>
                  <cart-product-tr
                    v-for="(order, vOId) in savedOrders"
                    :key="vOId"
                    :order="order"
                    @change-vendor="fetchCartCustomised"
                    @saved="fetchCart"
                    @deleted="fetchCart"
                    :ins-checkout="false"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="pl-lg-3 r-part order-1 order-lg-2">
          <order-summary-card :showPromo="false" />
        </div>
      </div>
      <DashboardFooter class="mt-3" />

      <modal
        :modal="priceUpdateModal"
        @change="togglePriceUpdateModal"
        :hideCloseIcon="true"
        size="md"
        c-classes="p-0 price-update-alert-modal"
        dialog-class="product-desc-dialog"
      >
        <div class="d-flex flex-column gap-16-px p-4">
          <p class="d-title f-18-px d-flex gap-8-px align-items-center mb-2">
            <img
              src="@/assets/images/caution.png"
              class="h-18-px w-18-px mb-1"
            />
            Important messages about items in your cart:
          </p>
          <div
            class=""
            v-for="(vendorId, vId) in vendorIdsHasUpdatedPrices"
            :key="vId"
          >
            <p class="d-title f-14-px mb-2">
              {{ getUpdatedPriceProducts(vendorId).length }} item<span
                v-if="getUpdatedPriceProducts(vendorId).length > 1"
                >s</span
              >
              from {{ vendorInfo(vendorId).name }} price has changed
            </p>
            <p class="d-desc f-12-px mb-0">
              Items on your Shopping Cart will always reflect the most recent
              price displayed on their product detail pages
            </p>
            <ul class="pl-3">
              <li
                class="d-desc f-11-px"
                v-for="(order, orderIndex) in getUpdatedPriceProducts(vendorId)"
                :key="orderIndex"
              >
                <span>{{ order.product.name }}</span>
                <span> has changed from </span>
                <span class="text-success gilroy-b"
                  >${{ Number(order.unit_price).toFixed(2) }}
                </span>
                <span> to </span>
                <span class="text-success gilroy-b"
                  >${{ Number(order.updated_unit_price).toFixed(2) }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import DashHead from "@/components/dashboard/DashHead";
import GoTo from "@/mixins/GoTo";
import GrowLoader from "@/components/loaders/GrowLoader";
import CartProductTr from "@/components/cart/CartProductTr";
import CartMixin from "@/mixins/CartMixin";
import CartCover from "@/components/cart/CartCover";
import OrderSummaryCard from "@/components/cart/OrderSummaryCard";
import RemainingBudgetCard from "@/components/cart/RemainingBudgetCard";
import PromoAnalyzer from "@/components/cart/PromoAnalyzer";
import CartPromoApply from "@/components/cart/CartPromoApply";
import SearchHeadCover from "@/components/SearchHeadCover";
import { mapState } from "vuex";
import DashboardFooter from "@/components/dashboard/footer/DashboardFooter.vue";
import Modal from "@/components/modals/Modal";
import { mapActions, mapMutations } from "vuex";
import axios from "@/axios";

export default {
  components: {
    DashHead,
    GrowLoader,
    CartProductTr,
    CartCover,
    OrderSummaryCard,
    RemainingBudgetCard,
    SearchHeadCover,
    PromoAnalyzer,
    CartPromoApply,
    DashboardFooter,
    Modal,
  },
  mixins: [GoTo, CartMixin],
  data: () => ({
    priceUpdateModal: false,
    isPriceUpdateModalShown: false,
    // editCreditCardDetails: false,
  }),
  created() {
    if (this.vendorIdsHasUpdatedPrices.length > 0) this.priceUpdateModal = true;
    for (let vId of this.vendorIdsHasProducts) {
    }
    console.log('I am from anotehr chhhhhhh')
    this.getTotalSavings()
    // this.fetchCreditCardDetails();
  },

  mounted() {
    // this.fetchCreditCardDetails();
    // this.fetchCartCustomised()
    // this.$BUS.$on('fetch-cart', async (cartByTime) => {
    //   this.fetchCart(true, undefined, false)
    // })
  },
  beforeDestroy() {
    // this.$BUS.$off('cart-item-deleted')
    // this.$BUS.$off('fetch-cart')
  },
  computed: {
    ...mapState("CART", ["fetchingCart"]),
  },

  // years() {
  //   const currentYear = new Date().getFullYear();
  //   return Array.from({ length: 10 }, (_, i) => currentYear + i);
  // },

  watch: {
    fetchingCart(v) {
      if (!v) {
        this.updatePrices();
      }
      if (!v && !this.isPriceUpdateModalShown) {
        this.isPriceUpdateModalShown = false;
        if (this.vendorIdsHasUpdatedPrices.length > 0)
          this.priceUpdateModal = true;
      }
    },
  },
  methods: {
    ...mapActions("PRODUCT", ["UPDATE_PRICE_BY_PRODUCT_IDS"]),
    ...mapActions("CART", ["FETCH_CHECKOUT"]),
    ...mapMutations("CART", ["SetTotalSaving", "SET_CC_STATUS"]),
    async getTotalSavings() {
      let totalSavings = 0;
      let checkoutData = await this.FETCH_CHECKOUT({
          office: this.officeId,
        });
      for (let order in checkoutData.order_details) {
        totalSavings =
          totalSavings + checkoutData.order_details[order].savings_amount;
      }
      this.SetTotalSaving(totalSavings);
      return totalSavings;
    },

    async updatePrices() {
      let productIds = this.getProductIds();
      let updatedRecord = await this.UPDATE_PRICE_BY_PRODUCT_IDS({
        office: this.officeId,
        products: productIds,
      });
      console.log(updatedRecord);

      for (let item of this.cartProducts) {
        let prod = item.product;
        let updatedProd = updatedRecord[prod.id];
        if (updatedProd) {
          if (updatedProd.price != item.unit_price) {
            item.updated_unit_price = updatedProd.price;
          }
        }
      }
      if (this.$store.state.CART.cartProducts.length > 0) {
        for (let product of this.$store.state.CART.cartProducts) {
          if (product.product.vendor.slug == "crazy_dental") {
            this.showCreditCardPopup = true;
            // this.editCreditCardDetails = true;
            this.$forceUpdate();
          }
        }
      }
    },
    getProductIds() {
      let ids = [];
      for (let product of this.cartProducts) {
        ids.push(product.product.id);
      }
      return ids;
    },

    getTotal(vendorId) {
      return parseFloat(this.vendorOrdersSubTotal(vendorId)).toFixed(2);
    },

    getTotalAmount(vendorId) {
      let sub_total = Number(
        this.checkoutData.order_details[vendorId].total_amount
      );
      let shippingCost = Number(this.getShippingCost(vendorId, sub_total, vId));
      let total = (sub_total + shippingCost).toFixed(2);
      return total;
    },
    togglePriceUpdateModal(v) {
      if (v != this.priceUpdateModal) this.priceUpdateModal = v;
    },
    events() {
      this.$BUS.$on("cart-updated", () => {
        console.log("UPDATED");
        this.onQuantityUpdate();
      });
      this.$BUS.$on("cart-item-deleted", (orderId) => {
        let index = this.cart.findIndex((c) => c.id == orderId);
        if (index > -1) {
          this.cart.splice(index, 1);
        }
      });
    },
    async fetchCartCustomised() {
      await this.fetchCart(false, { by: "time" }, false);
      this.getPrices();
    },
    getShippingNote(vendorId) {
      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.id === vendorId
        );
        if (vendorInfo) {
          let slug = vendorInfo.vendor.slug;
          if (slug === "safco")
            return `All orders placed before 4:30pm central time Monday-Thursday (3:30pm on Friday) ship the same day. For customers within our next day delivery area, there's no faster service available in the industry.`;
          if (slug === "edge_endo")
            return `All orders placed by 5PM MST Monday–Thursday, and by 4PM MST on Friday, will be shipped SAME DAY.`;
          if (slug === "henry_schein")
            return `Future order prices may vary on date of processing. Final order total may differ than that displayed due to variations in product availability, discounts, taxes, shipping, hazardous materials, small order charges, and/or handling charges. This sale is subject to Henry Schien Terms and Conditions.`;
          if (slug === "darby")
            return `Products sometimes ship from an alternate warehouse. This may result in longer shipping times.`;
          if (slug === "patterson")
            return `Prices are subject to change. Actual invoice cost may differ from displayed amount due to taxes, shipping, and other adjustments. By selecting place order, you acknowledge you have read and agree to the Terms And Conditions and the Privacy Statement, which terms shall govern your purchase.`;
          if (slug === "ultradent")
            return `Orders placed after 5:00 PM EST will be processed the next business day.`;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
.cart-page
  .l-part
    width: -webkit-fill-available
    @media screen and (min-width: 992px)
      // height: 90vh
      // overflow-y: auto
    @media screen and (max-width: 992px)
      width: 100%
  .r-part
    width: 100%
    max-width: calc( 267px + 1rem)
    position: relative
    .d-card
      @media screen and (min-width: 992px)
        position: sticky
        top: 80px
    @media screen and (max-width: 992px)
      max-width: 100%
  .checkout-card
    ::v-deep .c-chk
      label
        font-family: GILROY-Bold
        font-size: 14px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: 1.71
        text-align: left
        color: #000
    .c-l-part
      border-right: solid 1px #EBF4F7
      width: 100%
    .c-r-part
      width: 100%
      .checkout-pricing-table
        tr
          td
            padding-top: 8px
            padding-bottom: 8px
        .total-row
          td
            border-top: solid 1px #EBF4F7
      .checkout-pay-to-btn-wrapper
        border: solid 1px #EBF4F7
        border-radius: 4px
</style>
<style>
.tooltip-hover{
  position:relative;
  transition: 0.4s;
}
.tooltip-info{
  position: absolute;
    top: -24px;
    font-size: 12px;
    background: black;
    color: white;
    padding: 2px;
    border-radius: 3px;
    width: 140px;
    left: 5px;
    transition: 0.4s;
    display: none;
}
.tooltip-hover:hover .tooltip-info{
  display: block !important;
  transition: 0.4s;
}
.form fieldset {
  border: none;
  padding: 0;
  padding: 5px 0;
  position: relative;
  clear: both;
  margin: 0px 5px;
    margin-left: 0px;
}

.form fieldset.fieldset-expiration {
  float: left;
  width: 60%;
}

.form fieldset.fieldset-expiration .select {
  width: 84px;
  margin-right: 12px;
  float: left;
}

.form fieldset.fieldset-ccv {
  clear: none;
  float: right;
  width: 86px;
}

.form fieldset label {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Inconsolata;
  font-family: "GILROY-MEDIUM";
}

.form fieldset input,
.form fieldset .select {
  width: 100%;
  height: 38px;
  color: #333333;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  font-family: "GILROY-MEDIUM";
}

.form fieldset input.input-cart-number,
.form fieldset .select.input-cart-number {
  width: 82px;
  display: inline-block;
  margin-right: 8px;
}

.form fieldset input.input-cart-number:last-child,
.form fieldset .select.input-cart-number:last-child {
  margin-right: 0;
}

.form fieldset .select {
  position: relative;
}

.form fieldset .select::after {
  content: "";
  border-top: 8px solid #222;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  z-index: 2;
  top: 14px;
  right: 10px;
  pointer-events: none;
}

.form fieldset .select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  padding: 0;
  border: none;
  width: 100%;
  outline: none !important;
  top: 6px;
  left: 6px;
  background: none;
}

.form fieldset .select select :-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form button {
  margin-top: 30px !important;
  margin: 0px 3px;
}

.form button .fa {
  margin-right: 6px;
}

/*--------------------
Checkout
--------------------*/
.checkout {
  margin: 120px auto 0;
  position: relative;
  /* width: 460px; */
  background: white;
  border-radius: 15px;
  padding: 160px 45px 30px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}

/*--------------------
Credit Card
--------------------*/
.credit-card-box {
  perspective: 1000;
  width: 400px;
  height: 280px;
  position: absolute;
  top: -112px;
  left: 50%;
  transform: translateX(-50%);
}

.credit-card-box:hover .flip,
.credit-card-box.hover .flip {
  transform: rotateY(180deg);
}

.credit-card-box .front,
.credit-card-box .back {
  width: 400px;
  height: 250px;
  border-radius: 15px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: linear-gradient(135deg, #bd6772, #53223f);
  position: absolute;
  color: #fff;
  font-family: Inconsolata;
  top: 0;
  left: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}

.credit-card-box .front::before,
.credit-card-box .back::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("http://cdn.flaticon.com/svg/44/44386.svg") no-repeat center;
  background-size: cover;
  opacity: 0.05;
}

.credit-card-box .flip {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.credit-card-box .logo {
  position: absolute;
  top: 9px;
  right: 20px;
  width: 60px;
}

.credit-card-box .logo svg {
  width: 100%;
  height: auto;
  fill: #fff;
}

.credit-card-box .front {
  z-index: 2;
  transform: rotateY(0deg);
}

.credit-card-box .back {
  transform: rotateY(180deg);
}

.credit-card-box .back .logo {
  top: 185px;
}

.credit-card-box .chip {
  position: absolute;
  width: 60px;
  height: 45px;
  top: 20px;
  left: 20px;
  background: linear-gradient(135deg, #ddccf0 0%, #d1e9f5 44%, #f8ece7 100%);
  border-radius: 8px;
}

.credit-card-box .chip::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 4px solid #cbcb10;
  width: 80%;
  height: 70%;
  border-radius: 5px;
}

select {
  word-wrap: normal;
  color: #333333;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}

.credit-card-box .chip::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 3px solid #cbcb10;
  width: 57%;
  height: 47%;
  border-radius: 5px;
}

.credit-card-box .strip {
  background: linear-gradient(135deg, #404040, #1a1a1a);
  position: absolute;
  width: 100%;
  height: 50px;
  top: 30px;
  left: 0;
}

.credit-card-box .number {
  position: absolute;
  margin: 0 auto;
  top: 103px;
  left: 19px;
  font-size: 20px;
  font-family: "GILROY-MEDIUM";
}

.credit-card-box label {
  font-size: 10px;
  letter-spacing: 1px;
  text-shadow: none;
  text-transform: uppercase;
  font-weight: normal;
  opacity: 0.5;
  display: block;
  margin-bottom: 3px;
}

.credit-card-box .card-holder,
.credit-card-box .card-expiration-date {
  position: absolute;
  margin: 0 auto;
  top: 180px;
  font-family: "GILROY-MEDIUM";
  left: 19px;
  font-size: 22px;
  text-transform: capitalize;
  max-width: 200px;
  word-break: break-all;
  font-size: 14px;
  line-height: 1.1;
}

.credit-card-box .card-expiration-date {
  text-align: right;
  left: auto;
  right: 20px;
}

.credit-card-box .ccv {
  height: 36px;
  background: #fff;
  width: 91%;
  border-radius: 5px;
  top: 110px;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  color: #000;
  text-align: right;
  padding: 10px;
}

.credit-card-box .ccv label {
  margin: -25px 0 14px;
  color: #fff;
  font-family: "GILROY-MEDIUM";
}

.the-most {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 50vw;
  max-width: 200px;
  padding: 10px;
}

.the-most img {
  max-width: 100%;
}

.card-logo {
  position: absolute;
  right: 20px;
  top: 25px;
}

.address-card {
  margin-top: 83px;
}

.address-card span {
  background: #29bf12;
  height: 30px;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  padding: 6px;
  color: white;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-card span i {
  margin-right: 3px;
  font-size: 11px;
}

.address-card textarea {
  outline: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  font-family: "GILROY-MEDIUM";
  resize: none;
  margin-top: 10px;
}

.card-fieldset {
  width: 100% !important;
  gap: 10px;
}

.card-fieldset fieldset {
  width: 100%;
}
.calculated-icon {
  height: 0.9em;
  width: 0.9em;
}
</style>