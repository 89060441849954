import { mapActions, mapGetters, mapState } from "vuex";
import OfficeMixin from "@/mixins/OfficeMixin";
import GoTo from "@/mixins/GoTo";
// Saved working directory and index state WIP on develop: 0c260d3 Merge branch 'hotfix/scrum-82-and-scrum-257' into develop
export default {
  mixins: [OfficeMixin, GoTo],
  data: () => ({
    cart: [],
    fetching: false,
    fetchingCheckout: false,
    checkingOut: false,
    checkoutData: null,
    clearingSaveForLaterList: false,
    clearingCart: false,
    fetchingVendorShippingMethods: false,
    vendorShippingMethods: {},
    shipping_options: {},
    shippingOptionId: [],
    totalSavings: 0,
    cc_details: false,
    // showCreditCardPopup: false,
    // editCreditCardDetails: false,
  }),
  beforeDestroy() {
    this.$BUS.$off("ins-chk-upd");
    this.$BUS.$off("cart-updated");
    this.$BUS.$off("cart-data");
  },
  mounted() {
    this.events();
  },
  watch: {
    fetching(v) {
      this.$BUS.$emit("cart-fetching", v);
    },
    cart(v) {
      this.$BUS.$emit("cart", v);
    },
  },

  computed: {
    ...mapGetters("USER", ["role"]),
    ...mapState("CART", ["cartProducts"]),
    ...mapGetters("OFFICE", ["connected_vendors"]),
    vendorIds() {
      let vendors = this.cartProducts.map((o) => o.product.vendor.id);
      return vendors.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    },
    vendorIdsHasProducts() {
      let vendors = this.cartProducts.map((o) => o.product.vendor.id);
      return vendors
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((vid) => this.vendorOrdersNotSavedForLater(vid).length > 0);
    },
    vendorIdsHasProductsAndInCheckout() {
      let vendors = this.cartProducts.map((o) => o.product.vendor.id);
      return vendors
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter(
          (vid) =>
            this.vendorOrdersNotSavedForLater(vid).length > 0 &&
            this.vendorOrdersNotSavedForLaterAndInCheckout(vid).length > 0
        );
    },
    vendorIdsHasUpdatedPrices() {
      return this.vendorIdsHasProducts.filter(
        (vendorId) => this.getUpdatedPriceProducts(vendorId).length > 0
      );
    },
    vendorIdsHasPromotions() {
      return this.vendorIdsHasProducts.filter(
        (vendorId) => this.getPromotionProducts(vendorId).length > 0
      );
    },
    totalNumberOfPromotionItemsInCart() {
      const items = this.vendorIdsHasProducts.map(
        (vendorId) => this.getPromotionProducts(vendorId).length
      );
      if (items.length == 0) return 0;
      return items.reduce((a, b) => a + b, 0);
    },
    vendorNames() {
      let name = this.vendorIdsHasProducts
        .map((vId) => this.vendorInfo(vId).name)
        .reduce((a, b) => a + ", " + b, "");
      if (name.length > 0) return name.substr(1);
      return name;
    },
    subTotalOfItemsInCart() {
      return this.vendorIdsHasProductsAndInCheckout.map((vId) => {
        let subtotal = Number(this.vendorOrdersSubTotal(vId));
        let shipping = this.getDefaultShippingOption(vId);
        if (shipping) subtotal += Number(shipping.price);
        return subtotal;
      });
    },

    totalOrderValue() {
      let amount = 0.0;
      for (let productId of this.vendorIdsHasProducts) {
        let slug = this.vendorInfo(productId).slug;
        let price = Number(this.vendorOrdersSubTotal(productId));
        let shippingCost = Number(this.getShippingCost(slug, price, 0));
        price = price + Number(shippingCost);
        amount = amount + price;
      }
      return amount;
    },

    // totalOrderValue() {
    //   let amount = 0.0;
    //   for (let productId of this.vendorIdsHasProducts) {
    //     let slug = this.vendorInfo(productId).slug;
    //     let price = Number(this.vendorOrdersSubTotal(productId));
    //     let shippingCost = Number(this.getShippingCost(slug, price)); // Get shipping cost as a number
    //     let totalWithShipping = price + shippingCost; // Add shipping cost to the price
    //     amount += totalWithShipping; // Accumulate total amount
    //   }
    //   return amount; // Return the total amount rounded to 2 decimal places
    // },

    totalOrderValueCheckout() {
      let amount = 0.0;
      if (this.checkoutData && this.checkoutData.order_details) {
        for (let key in this.checkoutData.order_details) {
          if (!isNaN(this.checkoutData.order_details[key].total_amount))
            amount += parseFloat(
              this.checkoutData.order_details[key].total_amount
            );
        }
      }
      return amount;
    },
    savedOrders() {
      return this.cartProducts.filter((o) => o.save_for_later);
    },
    onlyItemsInCart() {
      return this.cartProducts.filter((o) => !o.save_for_later);
    },
    totalQuantity() {
      if (this.cartProducts.length == 0) return 0;
      return this.cartProducts.map((c) => c.quantity).reduce((a, b) => a + b);
    },
  },
  methods: {
    ...mapActions("CART", [
      "FETCH_CART",
      "CHECKOUT_CART",
      "FETCH_CHECKOUT",
      "CLEAR_SAVED_FOR_LATER",
      "CLEAR_CART",
      "VENDOR_SHIPPING_METHODS",
    ]),
    ...mapActions("PRODUCT", ["GET_PRICE_BY_PRODUCT_IDS"]),

    getShippingCost(vendorSlug, totalOfVendor, index) {
      let instant_checkout = false;
      // let instant_checkout = true;
      try {
        if (index != "undefined") {
          if (
            Object.hasOwn(
              this.$store.state.CART.cartProducts[index],
              "instant_checkout"
            )
          ) {
            let products = this.$store.state.CART.cartProducts;
            for (let product of products) {
              if (product.product.vendor.slug == vendorSlug) {
                if (product.instant_checkout) {
                  instant_checkout = true;
                }
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      if (!instant_checkout) {
        return 0;
      }
      
      let price = 0;
      if (vendorSlug == "dental_city") {
        if (totalOfVendor > 200) price = 0;
        else price = 12.99;
      } else if (vendorSlug == "safco") {
        if (totalOfVendor > 250) price = 0;
        else price = 14.95;
      } else if (vendorSlug == "ultradent") {
        if (totalOfVendor > 285) price = 0;
        else price = 14.95;
      } else if (vendorSlug == "darby") {
        if (totalOfVendor > 150) price = 9.95;
        else price = 12.95;
      } else if (vendorSlug == "benco") {
        if (totalOfVendor < 225) price = 11.95;
        else price = 0;
      } else if (vendorSlug == "dcdental") {
        price = 8.95;
      }
      // else if (vendorSlug == "patterson") {
      //   price = 0;
      // }
      else if (vendorSlug == "crazy_dental") {
        price = 8.95;
      } else if (vendorSlug == "henry_schein" || vendorSlug == "henry") {
        price = 9.49;
      }
      return Number(price);
      // return Number(price).toFixed(2);
    },
    

    shippingCost(vendorSlug, totalOfVendor) {
      let price = 0;
      if (vendorSlug == "dental_city") {
        if (totalOfVendor > 200) price = 0;
        else price = 12.99;
      } else if (vendorSlug == "safco") {
        if (totalOfVendor > 250) price = 0;
        else price = 14.95;
      } else if (vendorSlug == "ultradent") {
        if (totalOfVendor > 285) price = 0;
        else price = 14.95;
      } else if (vendorSlug == "benco") {
        if (totalOfVendor < 225) price = 11.95;
        else price = 0;
      } else if (vendorSlug == "dcdental") {
        price = 8.95;
      } else if (vendorSlug == "crazy_dental") {
        price = 8.95;
      } else if (vendorSlug == "henry_schein" || vendorSlug == "henry") {
        price = 9.49;
      } else if (vendorSlug == "darby") {
        if (totalOfVendor > 150) price = 9.95;
        else price = 12.95;
      }
      return Number(price);
    },

    needBilling() {
      let need_billing_for = null;
      for (let key in this.checkoutData.order_details) {
        if (
          ["dcdental", "top_glove"].includes(key) &&
          this.checkoutData.order_details[key].credit_info == null
        ) {
          need_billing_for = key;
          break;
        }
      }
      if (need_billing_for) {
        this.$BUS.$emit(
          "checkout:show-billing-" + need_billing_for,
          need_billing_for
        );
        return true;
      }
      return false;
    },
    getTotalOf(type) {
      let itemVendors = [];
      if (type === "dental_budget") {
        itemVendors = this.cartProducts
          .filter(
            (o) =>
              o.instant_checkout === true &&
              o.save_for_later === false &&
              o.budget_spend_type === "dental"
          )
          .map((o) => o.product.vendor.id);
      } else if (type === "office_budget") {
        itemVendors = this.cartProducts
          .filter(
            (o) =>
              o.instant_checkout === true &&
              o.save_for_later === false &&
              o.budget_spend_type === "office"
          )
          .map((o) => o.product.vendor.id);
      } else if (type === "miscellaneous_budget") {
        itemVendors = this.cartProducts
          .filter(
            (o) =>
              o.instant_checkout === true &&
              o.save_for_later === false &&
              o.budget_spend_type === "miscellaneous"
          )
          .map((o) => o.product.vendor.id);
      }
      const vendorIds = itemVendors.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      return vendorIds
        .map((vId) => {
          let subtotal = Number(this.vendorOrdersSubTotal(vId));
          let shipping = this.getDefaultShippingOption(vId);
          if (shipping) subtotal += Number(shipping.price);
          return subtotal;
        })
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    getDefaultShippingOption(vendorId) {
      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.id === vendorId
        );
        if (vendorInfo && vendorInfo.default_shipping_option) {
          let totalOfVendor = this.vendorOrdersSubTotal(vendorId);
          let price = vendorInfo.default_shipping_option.price;
          if (vendorInfo.vendor.slug == "dental_city") {
            price = 12.99;
            if (totalOfVendor > 249.99) price = 0;
          } else if (vendorInfo.vendor.slug == "safco") {
            price = 14.95;
            if (totalOfVendor > 250) price = 0;
          } else if (vendorInfo.vendor.slug == "ultradent") {
            price = 14.95;
            if (totalOfVendor > 285) price = 0;
          } else if (vendorInfo.vendor.slug == "darby") {
            price = 12.95;
            if (totalOfVendor > 150) price = 9.95;
          }
          return {
            ...vendorInfo.default_shipping_option,
            price,
          };
        }
      }
    },
    getUpdatedPriceProducts(vendorId) {
      return this.vendorOrdersNotSavedForLater(vendorId).filter(
        (order) =>
          !order.save_for_later &&
          order.updated_unit_price &&
          order.updated_unit_price != order.unit_price
      );
    },
    getPromotionProducts(vendorId) {
      return this.vendorOrdersNotSavedForLater(vendorId).filter(
        (order) =>
          order.product.promotion_description != null &&
          order.product.promotion_description.length > 0
      );
    },
    events(cartData = true) {
      this.$BUS.$on("ins-chk-upd", (order) => {
        let oI = this.cartProducts.findIndex((o) => o.id == order.id);
        if (oI >= 0) {
          this.cartProducts[oI].instant_checkout = order.instant_checkout;
          this.$BUS.$emit("total-order-value-in-cart", this.totalOrderValue);
        }
      });
      this.$BUS.$on("cart-updated", this.onQuantityUpdate);
      if (cartData)
        this.$BUS.$on(
          "cart-data",
          (v) => (this.$store.state.CART.cartProducts = v)
        );

      if (this.$store.state.CART.cartProducts.length > 0) {
        for (let product of this.$store.state.CART.cartProducts) {
          // if (product.product.vendor.slug == 'crazy_dental'){
          //   debugger
          //   this.showCreditCardPopup = true;
          //   this.editCreditCardDetails = true;
          //   this.$forceUpdate()
          // }
        }
      }
    },
    onQuantityUpdate(order) {
      let index = this.cartProducts.findIndex((c) => c.id == order.id);
      if (index >= 0) this.cartProducts[index] = order;
      this.$forceUpdate();
    },
    vendorOrdersNotSavedForLaterAndInCheckout(vId) {
      return this.cartProducts.filter(
        (o) =>
          o.product.vendor.id == vId && !o.save_for_later && o.instant_checkout
      );
    },
    vendorOrdersNotSavedForLater(vId) {
      return this.cartProducts.filter(
        (o) => o.product.vendor.id == vId && !o.save_for_later
      );
    },
    vendorOrders(vId) {
      return this.cartProducts.filter((o) => o.product.vendor.id == vId);
    },
    vendorOrdersSubTotal(vId) {
      let orders = this.vendorOrdersNotSavedForLater(vId).filter(
        (o) => o.instant_checkout
      );
      return parseFloat(
        orders
          .map((o) => {
            let unit_price = o.updated_unit_price || o.unit_price;
            return (
              (unit_price -
                (o.promotion && o.promotion.type == 1
                  ? o.promotion.reduction_price
                  : 0)) *
              o.quantity
            );
          })
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      );
    },
    vendorInfo(vId) {
      return this.vendorOrders(vId)[0].product.vendor;
    },

    taxByVendor(vId) {
      return 0;
    },
    shippingByVendor(vId) {
      return 0;
    },
    totalByVendor(vId) {
      return parseFloat(
        (
          this.vendorOrdersSubTotal(vId) +
          this.taxByVendor(vId) +
          this.shippingByVendor(vId)
        ).toFixed(2)
      );
    },
    orderedProductsForCheckoutByVendor(vId) {
      return this.checkoutData.products.filter(
        (o) => o.product.vendor.id == vId
      );
    },
    async fetchCart(
      emitCartData = true,
      filters = { by: "time" },
      fetching = true
    ) {
      this.fetching = fetching;
      try {
        await this.FETCH_CART({ office: this.officeId, filters });
        await this.getPrices();
        this.$BUS.$emit("items-in-cart", this.totalQuantity);
        this.$BUS.$emit("total-order-value-in-cart", this.totalOrderValue);
        // this.$BUS.$emit('items-in-cart', this.cartProducts.length - this.savedOrders.length)

        if (emitCartData) this.$BUS.$emit("cart-data", this.cartProducts);
      } catch (e) {
        console.log(e);
      }
      this.fetching = false;
    },
    async getPrices() {
      let null_price_ids = [];
      for (let i = 0; i < this.cartProducts.length; i++) {
        let pr = this.cartProducts[i];
        if (pr.sibling_products) {
          for (
            let j = 0;
            j < this.cartProducts[i].sibling_products.length;
            j++
          ) {
            let p = this.cartProducts[i].sibling_products[j];
            if (this.cartProducts[i].sibling_products[j].product_price != null)
              this.cartProducts[i].sibling_products[j].unit_price =
                this.cartProducts[i].sibling_products[j].product_price;
            if (p.unit_price == null && p.vendor != null) {
              this.cartProducts[i].sibling_products[j].fetching_price = true;
              null_price_ids.push(p.id);
              let component = this.$refs[`cart-product-tr-${pr.id}`];
              if (Array.isArray(component)) component = component[0];
              if (component) component.$forceUpdate();
            }
          }
        }
      }

      if (null_price_ids.length > 0) {
        let pRes = await this.GET_PRICE_BY_PRODUCT_IDS({
          office: this.officeId,
          products: null_price_ids,
        });
        let ids = Object.keys(pRes).map((i) => parseInt(i));
        for (let i = 0; i < this.cartProducts.length; i++) {
          let pr = this.cartProducts[i];
          if (pr.sibling_products) {
            for (let j = 0; j < pr.sibling_products.length; j++) {
              this.cartProducts[i].sibling_products[j].fetching_price = false;
              let p = pr.sibling_products[j];
              if (ids.includes(p.id)) {
                this.cartProducts[i].sibling_products[j].unit_price =
                  pRes[p.id].price;
                if (pRes[p.id].product_vendor_status)
                  this.cartProducts[i].sibling_products[
                    j
                  ].product_vendor_status = pRes[p.id].product_vendor_status;
                await this.$nextTick();
                let component = this.$refs[`cart-product-tr-${pr.id}`];
                if (Array.isArray(component)) component = component[0];
                if (component) component.$forceUpdate();
              }
            }
          }
        }
      }
    },
    async fetchCheckoutData() {
      this.fetchingCheckout = true;
      try {
        this.checkoutData = await this.FETCH_CHECKOUT({
          office: this.officeId,
        });
        await this.$nextTick();
        this.$BUS.$emit(
          "total-order-value-in-cart",
          this.totalOrderValueCheckout
        );
        this.$BUS.$emit("checkout-data", this.checkoutData);
      } catch (e) {
        this.$BUS.$emit("checkout-error");
        console.log(e);
      }
      this.fetchingCheckout = false;
    },
    async checkout() {
      if (this.checkingOut) return;
      this.checkingOut = true;
      try {
        let shipping_option = {};
        if (Object.keys(this.shipping_options).length > 0) {
          shipping_option = this.shipping_options;
        }
        let payload = {
          office: this.officeId,
          shipping_options: shipping_option,
        };
        for (let key in this.checkoutData.order_details) {
          payload.shipping_options[key] =
            this.checkoutData.order_details[key].shipping_option_id;
          if (this.checkoutData.order_details[key].credit_info != null) {
            payload[key] = {
              credit_info: this.checkoutData.order_details[key].credit_info,
            };
          }
        }
        let res = await this.CHECKOUT_CART(payload);
        this.$BUS.$emit("fetch-current-budget");
        setTimeout(() => {
          this.$BUS.$emit("checkout-done");
        }, 1500);
        this.$BUS.$emit("fetch-notification");
        await this.fetchCart();

        // DISABLED
        // if(res.is_approved || this.role == 1) this.$BUS.$emit('checkout-done')
        // else this.$BUS.$emit('checkout-done-not-approved')
      } catch (e) {
        console.log(e);
      }
      this.checkingOut = false;
    },
    async clearSaveForLaterList() {
      if (this.clearingSaveForLaterList) return;
      this.clearingSaveForLaterList = true;
      try {
        await this.CLEAR_SAVED_FOR_LATER({ office: this.officeId });
        this.fetchCart(true, { by: "time" }, true);
        this.$emit("cleared");
      } catch (e) {}
      this.clearingSaveForLaterList = false;
    },
    async clearCart() {
      if (this.clearingCart) return;
      this.clearingCart = true;
      try {
        await this.CLEAR_CART({ office: this.officeId });
        this.fetchCart();
        this.$emit("cleared");
      } catch (e) {}
      this.clearingCart = false;
    },
    async fetchVendorShippingMethods(vens) {
      if (this.fetchingVendorShippingMethods) return;
      this.fetchingVendorShippingMethods = true;
      try {
        let payload = {
          vendors: vens,
        };
        this.vendorShippingMethods = await this.VENDOR_SHIPPING_METHODS(
          payload
        );
        console.log(res);
      } catch (e) {}
      this.fetchingVendorShippingMethods = false;
    },
  },
};
