<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Connect All Of Your Offices with Multi-Office Functionality</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Effortlessly Manage Supplies Across Multiple Locations with Ordo</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Office-Specific Permissions</p>
        <p class="subtitle-3 mb-3">Our multi-office functionality also allows you to set office-specific permissions. This means that you can control who has access to certain features and information for each location, ensuring that each office's inventory is managed effectively. This feature provides you with enhanced control over your supply chain and helps you avoid any potential discrepancies.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Multi_Office_Function/multi-office-function-1.webp" alt="" class="" style="max-width: 300px">
        </p>
        <p class="title-2">Easy Navigation</p>
        <p class="subtitle-3 mb-3">Navigating between multiple offices is made easy with our platform. Our intuitive interface allows you to toggle between offices quickly and effortlessly, providing you with a seamless experience. This feature saves you time and effort and allows you to focus on providing excellent care to your patients across all your locations.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Multi_Office_Function/multi-office-function-2.webp" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/five.svg)
</style>