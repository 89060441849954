<template>
  <div class="outer px-3 owl-carousel" ref="owl-carousel">
    <div class="d-flex flex-column flex-lg-row gap-y-45-px gap-x-60-px mx-auto align-items-center contents">
      <div class="client">
        <div class="client-avatar mx-auto mb-2" :style="{ backgroundImage: `url(${require('./landing_images/testimonial/dixie.jpg')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }"></div>
        <p class="client-name mb-0 text-center">Dixie</p>
        <p class="client-title mb-0 text-center">Columbine Creek Dentistry</p>
      </div>
      <div class="comment mb-0 text-center text-lg-left">“Ordo has been a game-changer for me! It's saved me tons of time by streamlining my ordering process and providing real-time tracking of my inventory and budget. It is super easy to use and makes ordering fun!”</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    this.initOwl()
  },
  methods: {
    initOwl(){
      let owl = this.$refs['owl-carousel']
      $(owl).owlCarousel({
        nav: false,
        items: 1,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1500,
        autoplayHoverPause:true,
        loop: true,
        margin: 0,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.outer
  margin-top: 8rem
  background-image: url(./landing_images/testamonial_gradient.svg)
  background-repeat: no-repeat
  background-size: cover
  padding: 4rem 0
  .contents
    max-width: 755px
  .client
    .client-avatar
      width: 76px
      height: 76px
      border: 3px solid #FFFFFF
      border-radius: 100%
    .client-name
      font-family: GILROY-SEMIBOLD
      font-size: 16px
      color: #FFFFFF
    .client-title
      font-size: 14px
      color: #FFFFFF
      min-width: max-content
    .client-company
      font-family: GILROY-BOLD
      font-size: 32px
      color: #FFFFFF
  .comment
    font-size: 18px
    color: #FFFFFF
    // max-width: 462px
::v-deep .owl-dots , ::v-deep .owl-nav
    display: none

@media screen and (max-width: 992px)
  .outer
    margin-top: 6rem
</style>