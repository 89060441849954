<template>
  <div class="inv-member-form">
    <div v-for="(member, mI) in formData" :key="mI" class="mb-3">
      <div class="dash my-4" v-if="mI > 0"></div>
      <b-row>
        <b-col cols="12" class="pt-3 pt-lg-0">
          <p class="form-label mb-1">Team Member Email Address</p>
          <b-input v-model="formData[mI].email" class="custom-input" placeholder="Enter team member email"></b-input>
        </b-col>
        <b-col cols="12" lg="6" class="pt-3 pt-lg-0 mt-3">
          <p class="form-label mb-1">Role</p>
          <b-form-select v-model="formData[mI].role" :options="formData[mI].role !== 3 ? superRoleOptions : roleOptions" class="custom-input" placeholder="User Role"></b-form-select>
        </b-col>
        <b-col cols="12" lg="6" class="pt-3 pt-lg-0 mt-3">
          <p class="form-label mb-1">Assigned Office</p>
          <multiselect v-model="formData[mI].offices" :options="officeOptions" :multiple="invitation == null" class="custom-input" placeholder="Office"></multiselect>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-end mt-4" v-if="!invitation">
      <p class="mb-0 form-label cursor-pointer text-primary" @click="addMemberObj">+ Add Team Members</p>
    </div>
    <div class="dash my-4"></div>
    <div class="d-flex align-items-center justify-content-center">
      <Button :text="invitation ? 'Save' : inviteSent ? 'Invite Sent' : 'Send Invite'" :class="[{'danger__2' : inviteSent}]" :classes="'o-btn'" :submitting="submitting" :onclick="inv" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    Button,
    Multiselect: Treeselect,
  },
  props: {
    invitation: Object,
  },
  
  mixins: [OfficeMixin],
  data: () => ({
    formData: [],
    superRoleOptions: [
      { text: "Admin", value: 1 },
      // { text: "Manager", value: 3 },
      { text: "User", value: 2 },
    ],
    roleOptions: [
      { text: "Admin", value: 1 },
      { text: "Manager", value: 3 },
      { text: "User", value: 2 },
    ],
    fD: {
      offices: null,
      email: null,
      role: 1,
    },
    submitting: false,
    inviteSent: false,
  }),
  created(){
    if(this.invitation) this.setFormData()
    else this.addMemberObj()
  },
  computed: {
    officeOptions(){
      return this.offices.map(o => ({ id: o.id, label: o.name }))
    },
  },
  methods: {
    ...mapActions('OFFICE', ['INVITE_MWMBER', 'UPDATE_MEMBER_ROLE']),
    ...mapActions('ONBOARDING', ['INVITE_TEAM_MEMBER']),
    setFormData(){
      this.formData.push({
        offices: [this.invitation.office.id],
        email: this.invitation.email,
        role: this.invitation.role,
      })
    },
    async inv(){
      if(this.submitting) return 
      this.submitting = true
      try{
        if(this.invitation){
          let payload = {
            member: this.invitation.id,
            role: this.formData[0].role
          }
          await this.UPDATE_MEMBER_ROLE(payload)
        }
        else{
          await Promise.all(this.formData.map(async (formData) => {
            return new Promise(async (resolve, reject) => {
              let payload = {
                members: [formData]
              }
              await this.INVITE_TEAM_MEMBER(payload)
              resolve(true)
            })
          }))
        }
        this.inviteSent = true
        setTimeout(() => {
          this.inviteSent = false
          this.$emit('updated')
        }, 1000)
      }catch(e){}
      this.submitting = false
    },
    addMemberObj(){
      this.formData.push({...this.fD})
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../style/onboarding/onboarding'
::v-deep .vue-treeselect
  .vue-treeselect__control
    border: none
  .vue-treeselect__placeholder, .vue-treeselect__single-value
    font-family: GILROY-MEDIUM
    font-size: 12px
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: normal
    color: #707070
    display: flex
    align-items: center
  .vue-treeselect-helper-hide
    display: none !important
.inv-member-form
  @media screen and (max-width: 992px)
    max-height: 50vh
    overflow-y: auto
  .ass-ofc
    background: #fff url('../../../assets/images/svg_icon/arrow.svg') right 0.75rem center/35px 20px no-repeat
</style>