<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <p class="d-title mt-2 mb-0" style="font-size: 18px;">User Profile</p>
    </div>
    <p class="mb-4 d-desc__m551" style="width: 450px;">Your account-related data will be here. If anything related to your
      user account details needs to be changed or updated, you can do it below.</p>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <p class="mb-2 d-title__2" style="font-size: 16px;">User Information</p>
    </div>
    <div class="notf-surr">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">User Name</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text" v-if="!editMode.name">{{ fullName }}</p>
            <b-btn class="custom-btn sm-btn" @click="setEditMode('name')" v-if="!editMode.name">Edit</b-btn>
            <validation-observer ref="nameObs" v-slot="{ handleSubmit }" v-if="editMode.name">
              <b-form @submit.stop.prevent="handleSubmit(update)" class="d-flex">
                <validation-provider name="First Name" rules="required" v-slot="validationContext">
                  <b-input v-model="formData.first_name" class="custom-input mr-lg-2 mb-2 mb-lg-0"
                    placeholder="First Name" type="text" :state="getValidationState(validationContext)"></b-input>
                </validation-provider>
                <validation-provider name="Last Name" rules="required" v-slot="validationContext">
                  <b-input v-model="formData.last_name" class="custom-input" placeholder="Last Name" type="text"
                    :state="getValidationState(validationContext)"></b-input>
                </validation-provider>
                <Button text="Save" classes="sm-btn ml-lg-2" :submitting="submittingUserName" @click.native="update(1)" />
                <Button text="Cancel" classes="danger__2 sm-btn ml-lg-2" :onclick="() => {
                  submittingUserName = false
                  editMode.name = false
                }" />
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">User Email</p>
        </div>
        <div class="notf-p1" v-if="emailAlreadyExist">
          <p class="mb-0 d-title__4 text-danger">Email Already Exist</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text" v-if="!editMode.email">{{ profile.email }}</p>
            <b-btn class="custom-btn sm-btn" @click="setEditMode('email')" v-if="!editMode.email">Edit</b-btn>
            <validation-observer ref="emailObs" v-slot="{ handleSubmit }" v-if="editMode.email">
              <b-form @submit.stop.prevent="handleSubmit(update)" class="d-flex">
                <validation-provider name="Email" rules="required|email" v-slot="validationContext">
                  <b-input v-model="formData.email" class="custom-input mr-lg-2 mb-2 mb-lg-0" placeholder="Email"
                    type="text" :state="getValidationState(validationContext)"></b-input>
                </validation-provider>
                <Button text="Save" classes="sm-btn ml-lg-2" :submitting="submittingEmail" @click.native="update(2)" />
                <Button text="Cancel" classes="danger__2 sm-btn ml-lg-2" :onclick="() => {
                  submittingEmail = false;
                  editMode.email = false
                }" />
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">User Avatar or Image</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p class="mb-0 d-desc__3 mr-4 c-text c-avatar" v-if="!editMode.avatar">
              <img :src="profile.avatar" alt="" class="w-100 h-100" v-if="profile.avatar">
              <img src="@/assets/images/user_profile.png" :alt="fullName" style="width: 30px; height: 30px;" v-else>
            </p>
            <b-btn class="custom-btn sm-btn" @click="setEditMode('avatar')" v-if="!editMode.avatar">Edit</b-btn>
            <validation-observer ref="avatarObs" v-slot="{ handleSubmit }" v-if="editMode.avatar">
              <b-form @submit.stop.prevent="handleSubmit(update)" class="d-flex">
                <validation-provider name="Avatar" rules="required" v-slot="validationContext">
                  <b-form-file @input="filePicked" v-model="file" class="custom-input mr-lg-2 mb-2 mb-lg-0"
                    placeholder="Avatar" :state="getValidationState(validationContext)"
                    @change="filePicked"></b-form-file>
                </validation-provider>
                <Button text="Save" classes="sm-btn ml-lg-2" :submitting="submittingAvatar" @click.native="update(3)" />
                <Button text="Cancel" classes="danger__2 sm-btn ml-lg-2" :onclick="() => {
                  submittingAvatar = false;
                  editMode.avatar = false
                }" />
              </b-form>
            </validation-observer>
            <!-- <b-btn class="custom-btn sm-btn ml-4 disabled">Save</b-btn> -->
          </div>
        </div>
      </div>
    </div>
    <div class="notf-surr">
      <div class="check-switch-box mb-2 pl-3 pr-2">
        <div class="notf-p1">
          <p class="mb-0 d-title__4">User Role</p>
        </div>
        <div class="ml-auto">
          <div class="sm-card">
            <p :class="['mb-0 d-desc__3 c-text', role != 1 && 'mr-4']" v-if="!editMode.role">{{ roleOfUser }}</p>
            <!-- <b-btn class="custom-btn sm-btn" @click="setEditMode('role')" v-if="(!editMode.role && role != 1)">Edit</b-btn>
            <validation-observer ref="roleObs" v-slot="{ handleSubmit }" v-if="editMode.role">
              <b-form @submit.stop.prevent="handleSubmit(update)" class="d-flex">
                <validation-provider name="Role" rules="required" v-slot="validationContext">
                  <b-form-select v-model="formData.role" :options="roleOptions" class="custom-input" placeholder="User Role" type="text" :state="getValidationState(validationContext)"></b-form-select>
                </validation-provider>
                <Button text="Save" classes="sm-btn ml-lg-2" :submitting="submitting" :onclick="update" />
                <Button text="Cancel" classes="danger__2 sm-btn ml-lg-2" :onclick="() => editMode.role = false" />
              </b-form>
            </validation-observer> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@/mixins/FormMixin'
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  mixins: [FormMixin, GoTo],
  components: {
    Button,
  },
  data: () => ({
    editMode: {
      name: false,
      email: false,
      role: false,
      avatar: false,
    },
    formData: {
      first_name: null,
      last_name: null,
      email: null,
      avatar: null,
      role: 1,
    },
    submittingUserName: false,
    emailAlreadyExist: false,
    submittingEmail: false,
    submittingAvatar: false,
    isEmailUpdated: false,
    deleting: false,
    file: null,
  }),
  computed: {
    ...mapGetters('USER', ['profile', 'fullName', 'role']),
    roleOfUser() {
      return (this.roleOptions.find(r => r.value == this.profile.role)).text
    },
    roleOptions() {
      return [
        { text: 'Admin', value: 1 },
        { text: 'Manager', value: 3 },
        { text: 'User', value: 2 },
      ]
    },
  },
  methods: {
    ...mapActions('USER', ['UPDATE_ACCOUNT', 'DELETE_ACCOUNT']),
    ...mapActions(['CLEAR']),
    filePicked(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.formData.avatar = reader.result
      }
    },
    setEditMode(key) {
      if (key == 'name') {
        this.formData.first_name = this.profile.first_name
        this.formData.last_name = this.profile.last_name
      }
      else if (key == 'role') this.formData.role = this.profile.role
      else if (key == 'avatar') this.formData.avatar = this.profile.avatar
      else if (key == 'email') {
        this.formData.email = this.profile.email;
        this.isEmailUpdated = true;
      }
      this.editMode[key] = true
    },
    async deleteAcc() {
      if (this.deleting) return
      this.deleting = true
      try {
        await this.DELETE_ACCOUNT()
        this.CLEAR()
        this.goTo({ name: 'Login' })
      } catch (e) { }
      this.deleting = false
    },
    async update(submittingSlug) {
      if (this.submittingUserName || this.submittingAvatar || this.submittingEmail) return
      this.emailAlreadyExist = false;
      if (submittingSlug == 1) {
        this.submittingUserName = true;
      }
      else if (submittingSlug == 2) {
        this.submittingEmail = true;
      }
      else {
        this.submittingAvatar = true;
      }
      let isValid = false
      if (this.editMode.name) isValid = await this.$refs.nameObs.validate()
      if (this.editMode.role) isValid = await this.$refs.roleObs.validate()
      if (this.editMode.avatar) isValid = await this.$refs.avatarObs.validate()
      if (this.editMode.email ) isValid = await this.$refs.emailObs.validate()
      try {
        if (isValid) {
          let payload = {}
          if (this.editMode.name) {
            payload.first_name = this.formData.first_name
            payload.last_name = this.formData.last_name
          }
          if (this.editMode.role) payload.role = this.formData.role
          if (this.editMode.avatar && this.profile.avatar != this.formData.avatar) payload.avatar = this.formData.avatar
          if (this.editMode.email && this.profile.email != this.formData.email) payload.email = this.formData.email
          let res = await this.UPDATE_ACCOUNT(payload)
          this.clearForm()
          if (this.editMode.name) this.editMode.name = false
          if (this.editMode.role) this.editMode.role = false
          if (this.editMode.avatar) this.editMode.avatar = false
          if (this.editMode.email) this.editMode.email = false
        }
      } catch (e) {
        if (e === 'Email existed') {
          this.clearForm()
          this.emailAlreadyExist = true;
          if (this.editMode.name) this.editMode.name = false
          if (this.editMode.role) this.editMode.role = false
          if (this.editMode.avatar) this.editMode.avatar = false
          if (this.editMode.email) this.editMode.email = false
        }
        let errors = {
          'Messages': e
        }
        if (this.editMode.name) this.$refs.nameObs.setErrors(errors)
        if (this.editMode.role) this.$refs.roleObs.setErrors(errors)
        if (this.editMode.avatar) this.$refs.avatarObs.setErrors(errors)
        if (this.editMode.email) this.$refs.emailObs.setErrors(errors)
      }
      this.submittingUserName = false
      this.submittingEmail = false
      this.submittingAvatar = false
    },
    clearForm() {
      this.formData = {
        first_name: null,
        last_name: null,
        avatar: null,
        role: 1
      }
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings'
::v-deep .custom-file
  input
    display: none
  label
    padding-top: 0
    padding-bottom: 0
    height: inherit
    display: flex
    align-items: center
</style>
