import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import localStorageService from '@/store/localStorageService';

let greetKey = 'onceGreets';

Vue.use(Vuex)

import ONBOARDING from './onboarding'
import USER from './user'
import OFFICE from './office'
import ORDER from './order'
import PRODUCT from './product'
import CART from './cart'
import FILTER from './filter'
import NOTIFICATION from './notification'
import PROCEDURE from './procedure'
import GREETINGS from './greetings'
import WAITLIST from './waitlist'
import SUBCOMPANY from './subCompany'
import ADMIN_VIEW from './adminView'
import ADMIN_ORDER from './adminOrder'

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['USER', 'ONBOARDING', 'NOTIFICATION']
  })],
  modules: {
    USER,
    ONBOARDING,
    OFFICE,
    ORDER,
    PRODUCT,
    CART,
    FILTER,
    NOTIFICATION,
    PROCEDURE,
    GREETINGS,
    WAITLIST,
    SUBCOMPANY,
    ADMIN_VIEW,
    ADMIN_ORDER
  },
  state: {
    // Define your state here
    reOrderData: null,
  },
  mutations: {
    // Define your mutations here
    setReOrderData(state, data) {
      state.reOrderData = data;
    },
  },
  actions: {
    CLEAR({ rootState }) {
      let localOnceGreets = localStorageService.getItem(greetKey) || []
      const lastVisitedRoute = localStorageService.getItem('lastVisitedRoute');
      window.localStorage.clear()
      localStorageService.setItem(greetKey, localOnceGreets);
      localStorageService.setItem('lastVisitedRoute', lastVisitedRoute);
    },
  },
})
