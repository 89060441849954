<template>
  <div class="d-card py-4 py-md-5">
    <div class="d-flex justify-content-center align-items-center">
      <div>
        <p class="d-title">{{title}}</p>
        <p class="d-desc mb-0" style="max-width: 340px">{{desc}}</p>
      </div>
      <div class="d-flex align-items-center ml-md-4">
        <!-- <div class="loader-div">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div> -->
        <div class="ven-logo-div-wrp ml-md-5">
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/amazon.png" alt="" class="w-100 mt-1">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/benco.png" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/ebay.png" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/dentsply_sirona.png" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/purelife.png" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/crazy_dental.png" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/Darby.svg" alt="" class="w-100">
          </div>
          <div class="ven-logo-div">
            <img src="@/components/landing/vendor-images/safco.png" alt="" class="w-100">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'
.loader-div
  .lds-ring
    display: inline-block
    position: relative
    width: 80px
    height: 80px
  .lds-ring div
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border: 2px solid #{$blue}
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: #{$blue} transparent transparent transparent
  .lds-ring div:nth-child(1)
    animation-delay: -0.45s
  .lds-ring div:nth-child(2)
    animation-delay: -0.3s
  .lds-ring div:nth-child(3)
    animation-delay: -0.15s
  @keyframes lds-ring
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(360deg)
.ven-logo-div-wrp
  display: grid
  column-gap: 20px
  row-gap: 20px
  grid-template-columns: repeat(4, 1fr)
  max-width: calc((70px * 4) + (20px * 3))
  .ven-logo-div
    width: 70px
    height: 70px
    flex-grow: 0
    border-radius: 4.7px
    box-shadow: 0 2.4px 21px 0 rgba(0, 0, 0, 0.07)
    border: solid 1px #ebeaeb
    background-color: #fff
    display: flex
    align-items: center
    justify-content: center
    padding: 10px
</style>