<template>
  <div class="order-shopping">
    <!-- <d-head title="Orders" class="mb-4" :cart="true" /> -->
    <div class="d-card py-3 px-3">
      <shopping-search />
    </div>
  </div>
</template>

<script>
import CaretDownFill from '@/components/svg/CaretDownFill'
import ProductCard from '@/components/shopping/ProductCard'
import DHead from '@/components/dashboard/DHead'
import FilterSidebar from '@/components/shopping/FilterSidebar'
import ProductCardHorizontal from '@/components/shopping/ProductCardHorizontal'
import ShoppingSearch from '@/components/shopping/ShoppingSearch'
export default {
  components: {
    CaretDownFill,
    ProductCard,
    DHead,
    FilterSidebar,
    ProductCardHorizontal,
    ShoppingSearch,
  },
  data: () => ({
    filterBy: {
      sortBy: 'cheapset'
    },
    sortOptions: ['cheapset'],
    categories: ['Discounted Products', 'My Favorites'],
  }),
}
</script>

<style lang="sass" scoped>

@import '../../style/shopping'
.order-shopping
  .filter-sidebar
    @media screen and (min-width: 992px)
      border-right: solid 1px #EBF4F7
      width: 242px
  .products-search-results
    @media screen and (min-width: 992px)
      width: calc( 100% - 242px )
</style>