import { render, staticRenderFns } from "./Lan10.vue?vue&type=template&id=1abf992c&scoped=true"
import script from "./Lan10.vue?vue&type=script&lang=js"
export * from "./Lan10.vue?vue&type=script&lang=js"
import style0 from "./Lan10.vue?vue&type=style&index=0&id=1abf992c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1abf992c",
  null
  
)

export default component.exports