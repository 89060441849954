<template>
  <div class="position-relative outer">
    <div class="bg">
      <img src="./Section2.svg" alt="">
      <img src="./Section2Shape.svg" alt="">
    </div>
    <div class="inner d-flex flex-column flex-lg-row gap-20-px justify-content-center mx-auto align-items-center">
      <div class="contents">
        <p class="title-1">Hey there, I’m <span class="gradient-text">Gordo!</span></p>
        <p class="subtitle-1 mb-4">As a certified master in dental supply and inventory management, I am your personal guide to help you navigate Ordo. With my guidance and wisdom, you can learn the ways of ordering and managing your inventory to run a smooth and efficient dental office.</p>
        <Button class="success px-4 f-14-px" text="Review Our Features" :onclick="() => $router.push('/features')" />
      </div>
      <img src="./lan_i1.webp" alt="" class="character mt-auto">
    </div>
  </div>
</template>


<script>
import Button from '../ui/Button.vue'
export default {
  components: { Button, },
}
</script>

<style lang="sass" scoped>
.bg
  position: absolute
  top: 0
  width: 100%
  height: 100%
  img
    display: block
    width: 100%
    &:first-child
      height: 100%
      object-fit: cover
    &:last-child
      position: absolute
      bottom: 0
.outer
  padding-top: 7rem
.inner
  position: relative
  border-radius: 12px
  background-color: #FFFFFF
  padding: 0rem 3rem 0 3rem
  max-width: 1057px
  z-index: 1
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
  .contents
    padding: 4rem 0
  .title-1
    font-family: GILROY-BOLD
    font-size: 30px
    color: #274458
  .subtitle-1
    font-size: 16px
    color: #788288
    max-width: 392px
    line-height: 1.7
  .character
    max-width: 330px
    max-height: 330px
  .gradient-text
    background: linear-gradient(96.04deg, #6DDE43 19.46%, #50AAD5 77.98%)
    background-clip: text
    -webkit-text-fill-color: transparent

@media screen and (max-width: 992px)
  .inner
    margin-left: 1rem !important
    margin-right: 1rem !important
    padding: 2rem 2rem 0 2rem
  .title-1
    font-size: 24px
    text-align: center
  .subtitle-1
    text-align: center
  .contents
    display: flex
    flex-flow: column
    align-items: center
  .character
    max-width: 160px
    max-height: 160px
  .bg
    height: 500px
    img
      &:last-child
        display: none

</style>