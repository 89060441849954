<template>
  <div class="previous-orders-invoices">
    <div class="d-flex flex-column flex-lg-row justify-content-between mb-3 gap-12-px">
      <div class="d-lg-flex align-items-center">
        <div class="title-part mb-3 mb-lg-0" v-if="showTitle">
          <p class="mb-0 d-title">{{ title }}</p>
        </div>
        <div class="d-flex align-items-center gap-16-px" style="overflow-x: auto;">
          <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == null }]" v-if="statusFilter"
            @click="setStatus(null)">Order by Quantity</p>
          <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == 'total_price' }]" v-if="statusFilter"
            @click="setStatus('total_price')">Order by Total Price</p>
          <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == 'unit_price' }]" v-if="statusFilter"
            @click="setStatus('unit_price')">Order by Unit price</p>
          <!-- <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == 'category' }]" v-if="statusFilter"
            @click="setStatus('category')">order By Category</p> -->
          <!-- <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == 'amount' }]" v-if="statusFilter"
            @click="setStatus('amount')">Vendor By Amount Spent</p> -->
        </div>
      </div>

    </div>
    <div class="table-section">
      <b-table :items="this.$store.getters['ADMIN_VIEW/getVendorOrders']" :fields="fields" :thead-class="thClass" table-class="c-table" @row-clicked="onRowClick"
        show-empty :busy="fetching" ref="orderTable">
        <template #cell(name)="row">
          <div class="inner flex-column gap-8-px" v-if="row.item.showForm" style="height: auto;">
            <b-input v-model="row.item.vendor_name"
              :class="[vendorNameValidationError && currentItemid == row.item.id ? 'text-danger__2 invalid' : 'custom-input table-input']"
              :placeholder="vendorNameValidationError && currentItemid == row.item.id ? vendorNameValidationError : 'Vendor Name'"
              type="text" style="height: 28px !important"></b-input>
            <b-input v-model="row.item.vendor_order_id"
              :class="[orderIdValidationError && currentItemid == row.item.id ? 'text-danger__2 invalid' : 'custom-input table-input']"
              :placeholder="orderIdValidationError && currentItemid == row.item.id ? orderIdValidationError : 'Order Id'"
              type="text" style="height: 28px !important"></b-input>
          </div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <b-tooltip :target="`tooltip-vendor-${row.item.id}`" triggers="hover focus" placement="top"
              boundary="document" custom-class="promotion-tooltip" v-if="row.item.status != 'pendingapproval'">
              <p class="d-desc mb-0">{{ row.item.vendor.name }}</p>
            </b-tooltip>
            <div class="supplier-name d-flex align-items-center" v-if="row.item.status != 'pendingapproval'"
              :id="`tooltip-vendor-${row.item.id}`">
              <div class="avatar__super-mini mr-2">
                <img src="@/assets/images/vendors/implant_direct_logo_icon.png" alt="" class="w-100 h-100"
                  v-if="row.item.vendor.slug == 'implant_direct'">
                <img src="@/assets/images/vendors/edge_endo_logo_icon.png" alt="" class="w-100 h-100"
                  v-else-if="row.item.vendor.slug == 'edge_endo'" />
                <img :src="row.item.vendor.logo" alt="" class="w-100 h-100" v-else-if="row.item.vendor.logo" />
                <span v-else>{{ row.item.vendor.name[0] }}</span>
              </div>
              <span class="d-title__3 ml-0">{{ row.item.vendor.name }}</span>
            </div>
            <div v-else class="d-flex align-items-center gap-4-px">
              <b-tooltip :target="`tooltip-vendor-${vendor_order.id}`" triggers="hover focus" placement="top"
                boundary="document" custom-class="promotion-tooltip" v-for="vendor_order in row.item.vendor_orders"
                :key="vendor_order.id">
                <p class="d-desc mb-0">{{ vendor_order.vendor.name }}</p>
              </b-tooltip>
              <div class="supplier-name d-flex align-items-center" v-for="(vendor_order, i) in row.item.vendor_orders"
                :id="`tooltip-vendor-${vendor_order.id}`" :key="i">
                <div class="avatar__super-mini mr-2">
                  <img src="@/assets/images/vendors/implant_direct_logo_icon.png" alt="" class="w-100 h-100"
                    v-if="vendor_order.vendor.slug == 'implant_direct'">
                  <img src="@/assets/images/vendors/edge_endo_logo_icon.png" alt="" class="w-100 h-100"
                    v-else-if="vendor_order.vendor.slug == 'edge_endo'">
                  <img :src="vendor_order.vendor.logo" alt="" class="w-100 h-100" v-else-if="vendor_order.vendor.logo" />
                  <span v-else>{{ vendor_order.vendor.name[0] }}</span>
                </div>
                <span class="d-title__3 ml-0" v-if="row.item.vendor_orders.length <= 1">{{ vendor_order.vendor.name
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #cell(id)="row">
          <div class="inner" v-if="row.item.showForm" style="height: 90px;"></div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>{{ row.item.id }}</div>
        </template>
        <template #cell(nickname)="row">
          <div class="inner" v-if="row.item.showForm" style="height: 90px;">
            <b-input v-model="row.item.nickname"
              :class="[nickNameValidationError && currentItemid == row.item.id ? 'text-danger__2 invalid' : 'custom-input table-input']"
              :placeholder="nickNameValidationError && currentItemid == row.item.id ? nickNameValidationError : 'Nickname'"
              type="text" style="height: 28px !important"></b-input>
          </div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <div v-if="row.item.nickname" class="bx__1 d-flex align-items-center justify-content-between w-100"
              style="border-color: transparent;">
              <b-tooltip :target="`tooltip-nickname-${row.item.id}`" triggers="hover focus" placement="top"
                boundary="document" custom-class="promotion-tooltip">
                <p class="d-desc mb-0">{{ row.item.nickname }}</p>
              </b-tooltip>
              <p class="mb-0 nickname cursor-pointer" :id="`tooltip-nickname-${row.item.id}`">{{ row.item.nickname }}</p>
              <p class="ml-2 mb-0 text-primary cursor-pointer" @click.stop="editNickName($event, row.item)">Edit</p>
            </div>
            <div class="w-100" v-else>
              <p class="mb-0 text-primary cursor-pointer" @click.stop="onAddNicknameClick">+Add</p>
              <div class="bx__1 pr-2" style="display: none;">
                <b-input class="custom-input no-border-inp table-input"></b-input>
                <b-spinner style="width: 0.5rem; height: 0.38rem; border-width: 1px;" class="mr-1 text-primary"
                  v-if="submittingNickName && submittingNickNameFor && submittingNickNameFor.id == row.item.id"></b-spinner>
                <p class="mb-0 l-h-1 text-primary cursor-pointer f-10-px" @click.stop="submitNickName($event, row.item)">
                  Save</p>
              </div>
            </div>
          </div>
        </template>
        <template #cell(order_date)="row">
          <div class="inner" v-if="row.item.showForm" style="height: 90px;">
            <b-form-datepicker v-model="row.item.order_date" class="custom-dt-picker"
              :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" locale="en-US"
              label-no-date-selected="-"></b-form-datepicker>
          </div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>
            {{ formatDateTime(row.item.order_date) }}</div>
        </template>
        <template #cell(total_items)="row">
          <div class="inner justify-content-center" v-if="row.item.showForm" style="height: 90px;">
            <!-- <b-input v-model="row.item.total_items"
                :class="[quantityValidationError && currentItemid == row.item.id ? 'text-danger__2 invalid' : 'custom-input table-input']"
                :placeholder="quantityValidationError && currentItemid == row.item.id ? quantityValidationError : 'Quantity'"
                type="number" min="1" style="height: 28px !important; max-width: 100px;"></b-input> -->
          </div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <p class="mb-0">{{ row.item.total_items }}</p>
          </div>
        </template>
        <template #cell(total_amount)="row">
          <div class="inner" v-if="row.item.showForm" style="height: 90px;">
            <!-- <b-input v-model="row.item.total_amount"
                :class="[amountValidationError && currentItemid == row.item.id ? 'text-danger__2 invalid' : 'custom-input table-input']"
                :placeholder="amountValidationError && currentItemid == row.item.id ? amountValidationError : 'Amount'"
                type="number" min="0" style="height: 28px !important"></b-input> -->
          </div>
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <span class="pl-2-px">${{ row.item.total_amount }}</span>
          </div>
        </template>
        <template #cell(status_display_text)="row">
          <div class="inner" v-if="row.item.showForm" style="height: 90px;">
            <b-select v-model="row.item.order_status" :options="orderStatusList"
              class="custom-input custom-select table-input" placeholder="Status"
              style="height: 28px !important"></b-select>
          </div>
          <div :class="['inner d-flex align-items-center', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <span :class="['st-text']" style="width: 120px">
              <span style="min-width: 50px; display: inline-block;">{{ row.item.status_display_text }}</span>
              <img src="@/assets/images/caution.png" alt="" class="w-14-px h-14-px mb-1 ml-1"
                v-if="row.item.products.find(p => p.status == 'rejected')">
            </span>
          </div>
        </template>
        <template #cell(action)="row">
          <div class="inner gap-8-px" v-if="row.item.showForm || (row.item.vendor && row.item.vendor.is_manual)"
            :style="{ height: row.item.showForm ? '90px' : '55px' }">
            <div class="view-btn px-3 bg-primary !min-h-28-px" @click.stop="addManualOrderProductRow(row.item)"
              v-if="!row.item.showForm">Add Item</div>
            <!-- <p class="text-right d-desc text-danger__2 mt-1 mb-0" v-if="nickNameValidationErrorMessage">{{ nickNameValidationErrorMessage
              }}</p> -->
            <Button text="Save" classes="!min-h-28-px" style="min-width: max-content"
              :onclick="() => saveManualOrder(row.item)" :submitting="submittingManualOrderId == row.item.id"
              v-if="row.item.showForm" />
            <Button text="Delete" classes="!min-h-28-px danger__2" style="min-width: max-content"
              :submitting="deletingManualOrderId == row.item.id" :onclick="() => deleteManualOrder(row.item)" />
          </div>
          <div :class="['inner d-flex flex-column', { 'hover-color-changeable': hoverColorChange }]" v-else>
            <div>
              <div class="view-btn bg-primary ml-lg-2 w-125-px"
                v-if="!invoiceBtn && row.item.status == 'pendingapproval' && role == 1"
                @click.stop="goTo({ name: 'OrderApproval', params: { order_id: row.item.id } })">
                Approve or Reject
              </div>
              <Button :text="`${downloadingInvoice && selectedOrder.id == row.item.id ? 'Downloading' : 'Download'}`"
                classes="!min-h-28-px" :onclick="() => downloadInvoice(row.item)"
                :submitting="downloadingInvoice && selectedOrder.id == row.item.id" v-else-if="invoiceBtn" />
              <Button :text="`Re-order all Items`" classes="!min-h-28-px w-125-px" style="min-width: max-content"
                :onclick="() => reOrder(row)" :submitting="selectedItem && selectedItem.id == row.item.id"
                v-else-if="row.detailsShowing && row.item.products.length > 1" />
              <div class="view-btn px-3 bg-primary ml-lg-2 w-125-px" v-else
                @click.stop="openContactInfoModal(row.item.vendor)">Contact Vendor</div>
            </div>
          </div>
        </template>
        <template #row-details="{ item }">
          <!-- <div class="inner" v-if="item.showForm"></div> -->
          <div class="order-details" style="padding-top: 0 !important;">
            <div class="w-100 pr-2 pt-2" v-if="selectedItem && selectedItem.id == item.id && msgs.length > 0">
              <p class="text-right d-desc text-danger__2 my-2" v-for="(msg, mI) in msgs" :key="mI">{{ msg }}</p>
            </div>
            <order-items ref="childComponent" :invoice-btn="invoiceBtn" :order="item" :orders="item.products"
              :lastFilters="lastFilters" :reject-reason-btn="item.status == 'pendingapproval'"
              :rejectReasons="rejectReasons" :prevReasons="orderItems[item.id]" :cartData="cartData"
              @order-item-change="v => onOrderItemChange(v, item)" @status-updated="onStatusUpdated"
              @delete="onDeleteProduct" @set-validation-errors="status => setValidationErrors(status)"
              @added="fetchOrders(true, 1, lastFilters)" />
            <div v-if="item.status == 'pendingapproval' && role == 1">
              <div class="dash"></div>
              <div class="d-flex justify-content-end py-3 px-3 pr-4">
                <Button style="min-width: 74px" :text="`Submit`" classes="!min-h-28-px w-125-px mr-19-px"
                  :onclick="() => approveOrder(item)" :submitting="approvingOrder && selectedOrder.id == item.id" />
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="no-orders">
            <p class="d-desc text-center mx-auto my-4" style="max-width: 300px" v-if="q && q.trim().length > 0">No orders
              found for "{{ q }}".</p>
            <div v-else-if="status">
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-if="status == 'total_price'">There are no
                open orders during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else-if="status == 'unit_price'">There
                are no closed orders during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px"
                v-else-if="status == 'pendingapproval'">No orders are pending approval during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else-if="status == 'backordered'">No
                orders are backordered during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else>There are no orders during this
                period</p>
            </div>
            <p class="d-title text-center mx-auto my-4" style="max-width: 300px" v-else>There are no orders during this
              period</p>
          </div>
        </template>
        <template #table-busy>
          <div style="height: 250px">
            <grow-loader />
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination class="custom-pagination" v-if="!onlyTable && items.length > 0 && !fetching"
      v-model="tableSettings.currentPage" :total-rows="tableSettings.rows" :per-page="tableSettings.perPage"
      align="center" hide-ellipsis hide-goto-end-buttons />
    <!-- <div v-if="approvalModalDiv">
        <modal :modal="approvalModal" @change="toggleApprovalModal" size="lg" c-classes="px-0 py-4 order-approval-md"
          dialog-class="order-approval-modal">
          <div class="px-3">
            <p class="d-title mb-0" v-if="items.length > 0">Order Approval Required</p>
            <order-approval th-class="d-none" :hide-header="true" :hover-color-change="false"
              :ids="$route.query.order_approval_reject" />
          </div>
        </modal>
      </div> -->
    <!-- <modal :modal="orderApprovedModal" @change="toggleOrderApprovedModal" size="md" c-classes="order-app-or-rej-modal">
      <AfterOrderApprovalModalContent @go="orderApprovedModal = false; approvalModal = false;" />
    </modal> -->
    <!-- <modal :modal="orderRejectionModal" @change="toggleOrderRejectionModal" size="md" c-classes="order-app-or-rej-modal">
      <div v-if="selectedOrder">
        <validation-observer ref="formObs" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(rejectOrder)">
            <div class="d-flex justify-content-center mb-40-px">
              <logo class="logo" />
            </div>
            <p class="text-center d-title text-danger__2">Reject the {{ selectedOrder.vendor.name }} order placed on
              {{ formatDateTime(selectedOrder.order_date) }}</p>
            <p class="text-center d-desc mb-4">If you have a specific reason as to why you are rejecting the order you can
              leave your comments below, or just cliick “Reject Order” to process the rejection without comments</p>
            <validation-provider name="Reason" rules="required" v-slot="validationContext">
              <b-textarea class="custom-input-textarea" placeholder="Input order rejection notes..." :rows="5" no-resize
                v-model="rejected_reason" :state="getValidationState(validationContext)"></b-textarea>
              <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
              }}</b-form-invalid-feedback>
            </validation-provider>
            <div class="d-flex flex-column align-items-center justify-content-center mt-4">
              <Button text="Reject Order" style="min-width: 150px" classes="custom-btn danger__2" :onclick="rejectOrder"
                :submitting="rejectingOrder" />
              <p class="text-center cursor-pointer text-primary d-title f-12-px mt-3"
                @click="v => { orderRejectionModal = false; }">Close</p>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </modal> -->
    <!-- <modal :modal="conInfoModal" @change="toggleConInfoModal" size="md" dialog-class="vendor-contact-info-modal">
      <vendor-contact-info-form @close="v => toggleConInfoModal(false)" :venInfo="contactInfoForSelectedVendor" />
    </modal>
    <modal :modal="approvalModal" @change="toggleApprovalModal" size="lg" dialog-class="pending-order-notf-modal"
      v-if="numberOfPendingApprovalOrders > 0" cClasses="pb-0">
      <PendingApprovalPopupContent :numberOfPendingApprovalOrders="numberOfPendingApprovalOrders"
        @close="approvalModal = false" />
    </modal> -->
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import PlusMinus from '@/mixins/PlusMinus'
import ChildComponent from '@/components/dashboard/orders/OrderItems'
import Button from '@/components/ui/Button'
import moment from 'moment'
import Modal from '@/components/modals/Modal'
import Logo from '@/components/logo/Logo'
import FormMixin from '@/mixins/FormMixin'
import GrowLoader from '@/components/loaders/GrowLoader'
import BUS from '@/bus/BUS'
import VendorContactInfoForm from '@/components/dashboard/settings/VendorContactInfoForm'
import PendingApprovalPopupContent from '@/components/dashboard/orders/PendingApprovalPopupContent.vue'
import GoTo from '@/mixins/GoTo.vue'
import AfterOrderApprovalModalContent from '@/components/dashboard/orders/AfterOrderApprovalModalContent.vue'
import { v4 as uuid } from 'uuid'
export default {
  name: 'AllOrders',
  mixins: [OfficeMixin, FormMixin, PlusMinus, GoTo],
  components: {
    //   OrderApproval,
    OrderItems: ChildComponent,
    Button,
    Modal,
    Logo,
    GrowLoader,
    VendorContactInfoForm,
    PendingApprovalPopupContent,
    AfterOrderApprovalModalContent,
  },
  props: {
    hideHeader: {
      type: Boolean,
    },
    statusFilter: {
      type: Boolean,
      default: true,
    },
    dateFilters: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "All Orders",
    },
    onlyTable: {
      type: Boolean,
      default: false,
    },
    'invoice-btn': Boolean,
    'hover-color-change': { type: Boolean, default: true },
    approvalModalDiv: { type: Boolean },
    thClass: { type: String, default: '' },
    ids: { type: Array, },
    recent: { type: Boolean, },
    showTitle: { type: Boolean, },
    hideSearchField: { type: Boolean, },
  },
  data: () => ({
    items: [],
    fetching: true,
    tableSettings: {
      rows: 100,
      perPage: 5,
      currentPage: 1
    },
    downloadingInvoice: false,
    validationErrors: false,
    approvingOrder: false,
    rejectingOrder: false,
    status: null,
    pendingCount: 0,
    approvalModal: false,
    rejectReasons: [
      { value: 'noneed', text: 'Not Needed' },
      { value: 'wrong', text: 'Wrong Item' },
      { value: 'expensive', text: 'Too Expensive' },
      { value: 'other', text: 'Other' },
    ],
    orderApprovedModal: false,
    orderRejectionModal: false,
    currentItemid: null,
    anyValidationError: false,
    nickNameValidationError: null,
    vendorNameValidationError: null,
    orderIdValidationError: null,
    quantityValidationError: null,
    amountValidationError: null,
    selectedOrder: null,
    orderItems: {},
    rejected_reason: null,
    selectedItem: null,
    reOrdering: false,
    msgs: [],
    cartData: [],
    q: null,
    lastFilters: {},
    submittingNickNameFor: null,
    submittingNickName: false,
    contactInfoForSelectedVendor: null,
    conInfoModal: false,
    orderStatusList: [
      { value: 'open', text: 'Open' },
      { value: 'closed', text: 'Closed' },
      { value: 'pendingapproval', text: 'Pending Approval' },
    ],
    submittingManualOrderId: null,
    deletingManualOrderId: null,
  }),
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change')
    this.$BUS.$off('cart-data')
  },
  created() {
    this.fetchMembers();
    this.fetchPendingStats();
    if (this.recent) this.fetchOrders(true, undefined, {
      per_page: 5
    })
    else {
      const obj = {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
      }
      if (this.$route.query.pendingapproval != null) {
        this.setStatus(null)
        obj.start_date = null
        obj.end_date = null
      }
      this.lastFilters = obj
      this.fetchOrders(true, undefined, this.lastFilters)
    }
    if (!this.recent) this.$BUS.$on('filter:filter-change', this.onFilterChange)
    if (this.$route.query.order_approval_reject) {
      this.approvalModal = true
    }
    this.$BUS.$on('cart-data', v => this.cartData = v)
  },
  watch: {
    'tableSettings.currentPage'(v) {
      this.fetchOrders(true, v, this.lastFilters)
    },
    status(v) {
      if (this.tableSettings.currentPage != 1) this.tableSettings.currentPage = 1
      else this.fetchOrders(true, undefined, this.lastFilters)
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    ...mapGetters('OFFICE', ['connected_vendors']),
    numberOfPendingApprovalOrders() {
      return this.pendingCount;
    },
    fields() {
      if (this.invoiceBtn) {
        return [
          { key: 'order_date', label: 'Date', sortable: false, tdClass: 'w-25', },
          { key: 'name', label: 'Vendor', sortable: false, tdClass: 'w-25', },
          { key: 'total_amount', label: 'Total', sortable: false, tdClass: 'w-25' },
          { key: 'action', label: 'Action', sortable: false, tdClass: 'w-25', },
        ]
      }
      return [
        { key: 'order_date', label: 'Order Date', sortable: false, tdClass: 'order-date' },
        { key: 'nickname', label: 'Order Nickname', sortable: false, tdClass: 'order-nickname', },
        { key: 'name', label: 'Vendor', sortable: false, tdClass: 'vendor-name' },
        { key: 'total_items', label: 'Quantity', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total_amount', label: 'Order Total', sortable: false, tdClass: 'order-total-td' },
        { key: 'status_display_text', label: 'Status', sortable: false },
        { key: 'action', label: 'Order Action', sortable: false, thClass: 'text-center' },
      ]
    },
  },
  methods: {
    ...mapActions('ADMIN_VIEW', ['FETCH_VENDOR_ORDERS', 'SET_SORTING_SLUG']),
    ...mapActions('ADMIN_ORDER', ['GET_INVOICE', 'FETCH_PENDING_STATS', 'APPROVE_ORDER', 'UPDATE_ORDER', 'UPDATE_PENDING_ORDER', 'APPROVE_PENDING_ORDER', 'CREATE_MANUAL_ORDER', 'DELETE_MANUAL_ORDER']),
    ...mapActions('CART', ['ADD_MULTIPLE_PRODUCTS_TO_CART']),
    ...mapActions('OFFICE', ['TEAM_MEMBERS', 'DELETE_MEMBER']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS']),

    async fetchMembers() {
      try {
        this.members = await this.TEAM_MEMBERS({ office: this.officeId })
      } catch (e) {
        console.log(e)
      }
    },
    
    setSortingSlug(slug) {
      this.SET_SORTING_SLUG(slug);
    },

    resetFilter() {
      this.q = null
      this.search()
    },
    setValidationErrors(status) {
      this.validationErrors = status;
    },
    onAddNicknameClick(ev) {
      let parent = ev.target.parentElement
      parent.children[0].style.display = 'none'
      parent.children[1].style.display = 'flex'
    },
    openContactInfoModal(vendor) {
      if (vendor.slug == 'amazon') return
      let ven = this.connected_vendors.find(v => v.vendor.slug === vendor.slug)
      if (ven) {
        this.contactInfoForSelectedVendor = {
          ...ven
        }
        this.conInfoModal = true
      }
    },
    toggleApprovalModal(v) {
      if (v != this.approvalModal) this.approvalModal = v || false
    },
    toggleConInfoModal(v) {
      if (v != this.conInfoModal) this.conInfoModal = v || false
    },
    getContactInfo(slug) {
      if (slug == 'net_32') return { email: 'support@net32.com', phone_number: '(919) 205-0448' }
      else if (slug == 'henry_schein') return { email: 'custserv@henryschein.com', phone_number: '1-800-372-4346, Extension 2' }
      else if (slug == 'darby') return { email: 'dawn.sharma@darby.com', phone_number: '(516) 688-6800 Ext. (4213)' }
      else if (slug == 'ultradent') return { email: 'onlineordersupport@ultradent.com', phone_number: '888.230.1420' }
      else if (slug == 'patterson') return { email: 'ptc.support@pattersoncompanies.com', phone_number: '1.866.449.7282' }
      else if (slug == 'implant_direct') return { email: 'css@implantdirect.com', phone_number: '1-888-649-6425' }
      else if (slug == 'edge_endo') return { email: 'edgehr@edgeendo.com', phone_number: '855-985-3636' }
      else if (slug == 'benco') return { email: 'N/A', phone_number: '1-800-462-3626' }
      else if (slug == 'dental_city') return { email: 'help@DentalCity.com', phone_number: '1-800-353-9595' }

      return null
    },
    getContactPhone(slug) {
      let info = this.getContactInfo(slug)
      if (info) return info.phone_number
    },
    getContactEmail(slug) {
      let info = this.getContactInfo(slug)
      if (info) return info.email
    },
    async editNickName(ev, item) {
      let index = this.items.findIndex(i => i.id == item.id)
      if (index > -1) {
        let nickname = this.items[index].nickname
        this.items[index].nickname = null
        let inner = ev.target.parentElement.parentElement
        await this.$nextTick()
        inner.children[0].children[0].style.display = 'none'
        inner.children[0].children[1].style.display = 'flex'
        setTimeout(() => {
          inner.children[0].children[1].children[0].value = nickname
        }, 100)
      }
    },
    async submitNickName(ev, item) {
      this.submittingNickNameFor = item
      this.submittingNickName = true
      try {
        let parent = ev.target.parentElement.parentElement
        let nickname = ev.target.parentElement.children[0].value
        if (nickname.trim().length == 0) nickname = null
        let payload = {
          office: this.officeId,
          orderId: item.id,
          nickname,
        }
        let res
        if (item.status == 'pendingapproval') {
          res = await this.UPDATE_PENDING_ORDER(payload)
        }
        else {
          res = await this.UPDATE_ORDER(payload)
        }
        let index = this.items.findIndex(i => i.id == item.id)
        if (index > -1) {
          this.items[index].nickname = nickname
          await this.$nextTick()
          setTimeout(() => {
            parent.children[0].style.display = 'block'
            if (item.status == 'pendingapproval') {
              if (res.data.data.vendor_orders[0].nickname == null) parent.children[1].style.display = 'none'
              else parent.children[1].style.display = 'block'
            } else {
              if (res.data.data.nickname == null) parent.children[1].style.display = 'none'
              else parent.children[1].style.display = 'block'
            }
          }, 100)
        }
      } catch (e) {
        console.log(e)
      }
      this.submittingNickName = false
      this.submittingNickNameFor = null
    },
    cartItem(productId, full) {
      if (!this.cartData) return null
      let cartProd = this.cartData.find(c => c.product.id == productId)
      if (cartProd) return full ? cartProd : cartProd.quantity
      return null
    },
    onStatusUpdated(item, order) {
      let orderIndex = this.items.findIndex(o => o.id == order.id)
      if (orderIndex > -1) {
        let itemIndex = this.items[orderIndex].products.findIndex(p => p.id == item.id)
        if (itemIndex > -1) {
          this.items[orderIndex].products[itemIndex].status = item.status
          this.items[orderIndex].products[itemIndex].status_display_text = item.status_display_text
          const receivedItems = this.items[orderIndex].products.filter(p => p.status == 'received')
          const cancelledItems = this.items[orderIndex].products.filter(p => p.status == 'cancelled')
          const rejectedItems = this.items[orderIndex].products.filter(p => p.status == 'rejected')
          const itemLength = this.items[orderIndex].products.length
          if (receivedItems.length == itemLength || cancelledItems.length == itemLength || receivedItems.length == itemLength) {
            this.items[orderIndex].status = 'closed'
            this.items[orderIndex].status_display_text = 'Closed'
          }
          else if (item.status == 'processing') {
            this.items[orderIndex].status = 'open'
            this.items[orderIndex].status_display_text = 'Open'
          }
        }
      }
    },
    async reOrder(row) {
      if (this.reOrdering) return
      this.selectedItem = row.item
      this.reOrdering = true
      try {
        let payload = {
          data: [],
          office: this.officeId
        }
        for (let i = 0; i < row.item.products.length; i++) {
          let prod = row.item.products[i]

          let orderObj = this.cartItem(prod.product.id, true)
          let quantity = 1
          if (orderObj) {
            quantity += orderObj.quantity
            await this.update({
              office: this.officeId,
              cart: orderObj.id,
              quantity,
            })
          } else {
            payload.data.push({
              product: prod.product.id,
              office: this.officeId,
              quantity
            })
          }

        }
        if (payload.data.length > 0) {
          let res = await this.ADD_MULTIPLE_PRODUCTS_TO_CART(payload)
        }
        this.$BUS.$emit('fetch-cart')
        BUS.$emit('open-cart-tab')
        this.selectedItem = null
      } catch (e) {
        console.log(e)
        if (Array.isArray(e)) this.msgs = e
        else this.msgs = [e]
        setTimeout(() => {
          this.selectedItem = null
        }, 2000)
      }
      this.reOrdering = false
    },
    async search() {
      this.fetchOrders(false, undefined, this.lastFilters)
    },
    onOrderItemChange(v, item) {
      this.orderItems[item.id] = v
    },
    toggleApprovalModal(v) {
      if (v != this.approvalModal) this.approvalModal = v != null ? v : false
    },
    toggleOrderApprovedModal(v) {
      if (v != this.orderApprovedModal) this.orderApprovedModal = v != null ? v : false
    },
    toggleOrderRejectionModal(v) {
      if (v != this.orderRejectionModal) this.orderRejectionModal = v != null ? v : false
      this.rejected_reason = null
    },
    setStatus(v) {
      if (this.status != v) {
        this.status = v;
        this.setSortingSlug(v);
      }
      else {
        this.status = null
      }
      if (this.lastFilters.status != v && v != null) this.lastFilters.status = v
      else delete this.lastFilters.status
    },
    vendorsPerOrder(item) {
      let vendors = ((item.vendor_orders.map(vo => vo.products.map(p => p.product.vendor))).flat()).filter(f => f)
      let ids = (vendors.map(v => v.id)).filter((value, index, self) => self.indexOf(value) === index)
      return ids.map(id => vendors.find(v => v.id == id))
    },
    onRowClick(row) {
      if (row.status == "pendingapproval" || row.showForm) return
      if (this.invoiceBtn == true) return
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.items.forEach(item => {
          this.$set(item, '_showDetails', false)
        })
        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    async onFilterChange(filters) {
      let obj = {}
      if (filters.budget_type) obj.budget_type = filters.budget_type
      // if(filters.date_range) obj.date_range = filters.date_range
      if (filters.selectedDate.startDate && filters.selectedDate.endDate) {
        let sta = moment(filters.selectedDate.startDate).format('YYYY-MM-DD')
        let end = moment(filters.selectedDate.endDate).format('YYYY-MM-DD')
        obj.start_date = sta
        obj.end_date = end
      }
      if (
        (filters.selectedDate.startDate && filters.selectedDate.endDate)
        || (!filters.selectedDate.startDate && !filters.selectedDate.endDate)
      ) {
        this.lastFilters = obj
        this.fetchOrders(true, undefined, obj)
      }
    },
    async fetchPendingStats() {
      this.pendingCount = await this.FETCH_PENDING_STATS({ office: this.officeId });
    },
    async fetchOrders(fetching = true, page = undefined, filters = {}) {
      this.fetching = fetching
      try {
        let payload = {
          office: this.officeId,
          filters
        }
        payload.filters.per_page = 10
        if (page) payload.filters.page = page
        if (this.status) payload.filters.status = this.status
        if (this.ids) payload.filters.ids = this.ids
        if (this.q && this.q.trim().length > 0) payload.filters.q = this.q
        else delete payload.filters.q
        let res = await this.FETCH_VENDOR_ORDERS(payload)
        
        this.tableSettings.rows = res.total
        this.tableSettings.perPage = res.per_page
        this.tableSettings.currentPage = res.current_page
        this.items = res.data.map(order => {
          if (order.status == 'pendingapproval') {
            let products = []
            order.vendor_orders.forEach(vendor_order => {
              products = products.concat(vendor_order.products)
            })
            return {
              ...order,
              status_display_text: 'Pending Approval',
              nickname: order.vendor_orders[0].nickname,
              products
            }
          }
          else return order
        })
        this.$emit('items', this.items)
        if (this.status == null && res.data.length == 0 && Object.keys(payload.filters).length == 0) this.$emit('show-empty')
        if (this.numberOfPendingApprovalOrders > 0 && this.role == 1 && this.$route.name == 'Dashboard') this.approvalModal = true
      } catch (e) { }
      this.fetching = false
    },
    async downloadInvoice(order) {
      this.selectedOrder = order
      this.downloadingInvoice = true
      try {
        let res = await this.GET_INVOICE({ office: this.officeId, orderId: order.id })
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', 'invoice.zip')
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.log(e)
      }
      this.downloadingInvoice = false
      this.selectedOrder = null
    },
    async showRejectOrderModal(order) {
      this.selectedOrder = order
      this.orderRejectionModal = true
    },
    async approveOrder(order) {
      this.selectedOrder = order
      this.approvingOrder = true
      try {
        let payload = {
          office: this.officeId,
          orderId: order.id,
          is_approved: true,
        }
        if (this.orderItems[order.id]) {
          payload.rejected_items = this.orderItems[order.id].filter(i => i.rejected_reason != null)
        } else {
          payload.rejected_items = []
        }
        let res
        if (order.status == 'pendingapproval') {
          delete payload.is_approved
          res = await this.APPROVE_PENDING_ORDER(payload)
        }
        else {
          res = await this.APPROVE_ORDER(payload)
        }
        this.fetchOrders()
        // this.$BUS.$emit('filter:fetch-stats')
        this.orderApprovedModal = true
      } catch (e) {
        console.log(e)
      }
      this.approvingOrder = false
      this.selectedOrder = null
    },
    async rejectOrder(order) {
      this.rejectingOrder = true
      try {
        let payload = {
          office: this.officeId,
          orderId: this.selectedOrder.id,
          is_approved: false,
          rejected_reason: this.rejected_reason
        }
        let res = await this.APPROVE_ORDER(payload)
        this.fetchOrders()
        this.toggleOrderRejectionModal(false)
      } catch (e) {
        console.log(e)
      }
      this.rejectingOrder = false
      this.selectedOrder = null
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d, 'YYYY-MM-DD').format("MM/DD/YY");
    },
    addManualOrderRow() {
      this.validationErrors = false;
      const uid = uuid()
      this.items = [{
        id: uid,
        vendor_order_id: null,
        nickname: null,
        order_date: moment().format("YYYY-MM-DD"),
        total_items: 0,
        total_amount: 0,
        order_status: "closed",
        vendor_name: null,
        products: [],
        showForm: true,
      }].concat(this.items)
    },
    addManualOrderProductRow(item) {
      if (!this.validationErrors) {
        const uid = uuid()
        const index = this.items.findIndex(i => i.id == item.id)
        if (index > -1) {
          this.items[index].products = [{
            id: uid,
            order_id: item.order,
            quantity: null,
            unit_price: null,
            status: "processing",
            product_name: null,
            showForm: true,
            budget_spend_type: 'dental',
          }].concat(this.items[index].products)
          this.$set(item, '_showDetails', true)
        }
      }
      this.validationErrors = true;
    },
    addManualOrderProductTile(item) {
      const uid = uuid()
      const index = this.items.findIndex(i => i.id == item.id)
      if (index > -1) {
        this.items[index].products = [{
          id: uid,
          order_id: item.order,
          quantity: null,
          unit_price: null,
          status: "processing",
          product_name: null,
          showForm: true,
          budget_spend_type: 'dental',
        }].concat(this.items[index].products)
        this.$set(item, '_showDetails', true)
      }
    },
    async saveManualOrder(item) {
      this.vendorNameValidationError = null
      this.nickNameValidationError = null
      this.amountValidationError = null
      this.quantityValidationError = null
      this.orderIdValidationError = null
      this.anyValidationError = false

      if (item.vendor_name == null) {
        this.vendorNameValidationError = 'Vendor Name Required'
        this.anyValidationError = true
        this.currentItemid = item.id
      }
      if (item.nickname == null) {
        this.nickNameValidationError = 'Nickname Required'
        this.anyValidationError = true
        this.currentItemid = item.id
      }
      if (item.vendor_order_id == null) {
        this.orderIdValidationError = 'Order ID Required'
        this.anyValidationError = true
        this.currentItemid = item.id
      }
      if (this.anyValidationError == false) {
        this.submittingManualOrderId = item.id
        const payload = {
          office: this.officeId,
          vendor_order_id: item.vendor_order_id,
          nickname: item.nickname,
          order_date: item.order_date,
          total_items: item.total_items,
          total_amount: item.total_amount,
          order_status: item.order_status,
          vendor_name: item.vendor_name,
        }
        const { data } = await this.CREATE_MANUAL_ORDER(payload)

        await this.fetchOrders(true, 1, this.lastFilters)
        this.addManualOrderProductRow(this.items[0])
        this.submittingManualOrderId = null
      }
    },
    async updateOrders() {
      const { data } = await this.CREATE_MANUAL_ORDER(payload)
      await this.fetchOrders()
    },
    async deleteManualOrder(item) {
      this.deletingManualOrderId = item.id
      if (typeof item.id == 'string') {
        const index = this.items.findIndex(i => i.id == item.id)
        if (index > -1) this.items.splice(index, 1)
        return
      }
      this.validationErrors = false;
      const payload = {
        office: this.officeId,
        order_id: item.order,
      }
      const { data } = await this.DELETE_MANUAL_ORDER(payload)
      await this.fetchOrders(true, 1, this.lastFilters)
      this.deletingManualOrderId = null
    },
    onDeleteProduct(orderId, productId) {
      this.validationErrors = false;
      const oIndex = this.items.findIndex(i => i.id == orderId)
      if (oIndex > -1) {
        const pIndex = this.items[oIndex].products.findIndex(i => i.id == productId)
        if (pIndex > -1) {
          this.items[oIndex].products.splice(pIndex, 1)
        }
      }
    },
  },
}
</script>
  
<style lang="sass" scoped>
  .no-orders
    background-image: url(../../../assets/images/no_order_img.png)
    background-size: 80% 100%
    background-position-x: 40%
    background-repeat: no-repeat
    min-height: 250px
    @media (min-width: 992px)
      padding-top: 80px
    @media (max-width: 992px)
      min-height: unset
      background-image: unset
      background-size: unset
  .bx-wrapper
    height: 40px
    border-radius: 4px
    border: solid 1px #e9e9e9
    .custom-input
      height: 100% !important
  .bx
    height: 28px
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 4px
    border: solid 1px #eaeaeb
    // background-color: #fafafb
    .custom-input
      height: 100% !important
    &__1
      @extend .bx
      min-width: 120px
      width: 100%
  .search-wrapper
    border-radius: 12px
    border: solid 1px #ebeaeb
    background-color: #fafafa
    padding: 4px
    height: 38px
    @media (min-width: 992px)
      max-width: 280px
    @media (min-width: 1200px)
      max-width: unset
      min-width: 280px
    .custom-input
      &::placeholder
        font-size: 10px
  ::v-deep .rej-res-sel
    min-width: 200px !important
    top: 5px !important
  ::v-deep .bud-cat-sel
    min-width: 200px !important
  // ::v-deep .custom-modal
  //   &.order-approval-md
  .view-btn
    min-height: 29px
    min-width: 54px
    border-radius: 4px
    color: #fff
    display: flex
    align-items: center
    justify-content: center
    font-size: 12px
    font-family: GILROY-BOLD
    font-stretch: normal
    font-style: normal
    line-height: normal
  .previous-orders-invoices
    ::v-deep .b-table-has-details
      box-shadow: 0 0 50px 0 rgba(235, 235, 235, 0.93)
    .order-details
      border: solid 1px #E5EAEC
      box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
    .f-text
      opacity: 0.2
      font-size: 14px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.71
      color: #000000
      &.ac
        opacity: 1
        font-family: GILROY-BOLD
        color: #1c455a
    .title-part
      .below-title
        width: 95px
        height: 3px
        border-radius: 8px
        background-color: #49a2cb
    ::v-deep .c-table
      @media screen and (max-width: 992px)
        width: max-content
        min-width: 100%
      thead
        tr
          th
            background-color: #ebf4f8
            border-top: none
            border-bottom: none
            font-family: GILROY-BOLD
            font-size: 12px
            font-weight: 600
            font-stretch: normal
            font-style: normal
            color: #1c455a
            padding-top: 10px
            padding-bottom: 10px
            &:first-child
              border-top-left-radius: 4px
              border-bottom-left-radius: 4px
            &:last-child 
              border-top-right-radius: 4px
              border-bottom-right-radius: 4px
      tbody
        tr
          td
            border-top: none
            border-bottom: none
            padding: 12px 0 0 0
            font-size: 12px
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: 2
            color: #000000
            .inner
              cursor: pointer
              padding: 12px
              border-top: solid 1px #E7ECEE
              border-bottom: solid 1px #E7ECEE
              height: 55px
              display: flex
              align-items: center
            &:first-child
              .inner
                border-left: solid 1px #E7ECEE
                border-top-left-radius: 4px
                border-bottom-left-radius: 4px
            &:last-child
              @media screen and (min-width: 992px)
                width: 220px
              .inner
                border-right: solid 1px #E7ECEE
                border-top-right-radius: 4px
                border-bottom-right-radius: 4px
                justify-content: center
            &.qty-td
              @media screen and (min-width: 992px)
                width: 193px
              p
                min-width: 27px
                height: 25px
                width: min-content
                flex-grow: 0
                // border-radius: 4px
                // border: solid 1px #e6eaeb
                display: flex
                justify-content: center
                margin: 0 auto
            &.order-date
              @media screen and (min-width: 992px)
                width: 125px
            &.order-nickname
              @media screen and (min-width: 992px)
                width: 180px
                .nickname
                  max-width: 110px
                  text-overflow: ellipsis
                  overflow: hidden
                  white-space: nowrap
  
            &.vendor-name
              @media screen and (min-width: 992px)
                min-width: 150px
            &.order-total-td
              @media screen and (min-width: 992px)
                width: 150px
          &:hover
            .hover-color-changeable
              background-image: linear-gradient(to bottom, #49a2cb -84%, #ffffff 111%)
              opacity: 0.75
              border-top: solid 1px transparent
              border-bottom: solid 1px #ffffff
          &.b-table-has-details
            td
              .inner
                border-bottom: none
              &:first-child
                .inner
                  border-bottom-left-radius: 0px
              &:last-child
                .inner
                  border-bottom-right-radius: 0px
          &.b-table-details
            td
              padding-top: 0
              .order-details
                border-top: none
                padding-top: 5px !important
  </style>