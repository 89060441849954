import axios from '@/axios'
import { mapGetters } from 'vuex'
import getQuery from '@/mixins/queryStringBuilder'

const state = {
    showOfficePicker: false,
    totalOffices: null,
    selectedOffices: [],
    sortingSlug: null,
    vendor_orders: [],
    top_vendor_spent: []
}

const getters = {
    showOfficePicker(state) {
        return state.showOfficePicker
    },
    getTotalOffices(state) {
        return state.totalOffices;
    },
    getSelectedOffices(state) {
        return state.selectedOffices;
    },
    getSortingSlug(state) {
        return state.sortingSlug;
    },
    getVendorOrders(state) {
        return state.vendor_orders;
    }
    ,
    getTopVendorSpent(state) {
        return state.top_vendor_spent;
    }
}

const mutations = {
    setTopVendors(state, value) {
        state.top_vendor_spent = value;
    },
    setVendorOrders(state, value) {
        state.vendor_orders = value;
    },
    setSortingSlug(state, value) {
        state.sortingSlug = value;
    },
    toggleShowOfficePicker(state) {
        state.showOfficePicker = !state.showOfficePicker;
    },
    setTotalOffices(state, officeLength) {
        state.totalOffices = officeLength;
    },
    setSelectedOffices(state, { value, check }) {
        if (check) {
            if (!state.selectedOffices.includes(value)) {
                state.selectedOffices.push(value);
            }
        }
        else {
            if (state.selectedOffices.includes(value)) {
                var index = state.selectedOffices.indexOf(value);
                if (index > -1) {
                    state.selectedOffices.splice(index, 1);
                }
            }
        }
    }
}
const actions = {
    ...mapGetters('ADMIN_VIEW', ['getSelectedOffices',]),
    ...mapGetters('ADMIN_ORDER', ['getSortingSlug',]),


    TOGGLE_SHOW_OFFICE_PICKER: (state) => {
        state.showOfficePicker = !state.showOfficePicker;
    },
    SET_SELECTED_OFFICES: ({ rootGetters, commit, state }, { value, check }) => {
        commit('setSelectedOffices', { value, check });
    },
    SET_SORTING_SLUG: ({ rootGetters, commit }, slug) => {
        commit('setSortingSlug', slug)
    },

    FETCH_COMPANY_TREE: async ({ rootGetters, commit, state }, payload) => {
        try {
            const companyId = rootGetters['USER/company'].id
            let url = 'admin-view/get_offices_tree'
            url += `?&company_id=${companyId}`
            let { data } = await axios.reqApi(url).get();
            return data
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    },
    FETCH_TOP_VENDORS: async ({ rootGetters, commit }, payload) => {
        try {
            
            let query = null
            const office = payload.office
            const page = payload.page
            let url = 'admin-view/top_vendor_spent'
            if (payload.hasOwnProperty('selectedDate')) { query = getQuery(payload.selectedDate) }
            else {
                query = getQuery(payload)
            }
            url += query
            if (!url.includes('?')) {
                url += '?'
            }
            url += `&office_pk=${office}`
            let sortingSlug = rootGetters['ADMIN_VIEW/getSortingSlug'];
            url += `&sort=${sortingSlug}`
            let selectedOffices = rootGetters['ADMIN_VIEW/getSelectedOffices'];
            if (selectedOffices.length == 0) {
                selectedOffices = `${office}`
            }
            else {
                selectedOffices = selectedOffices.join(',')
            }
            url += `&offices=${selectedOffices}`
            let { data } = await axios.reqApi(url).get()
            commit('setTopVendors', data.data);

            return data.data;
        } catch (e) {
            return Promise.reject(e.response.data.message);
        }
    },

    FETCH_VENDOR_ORDERS: async ({ rootGetters, commit }, payload) => {
        try {
            const office = payload.office
            const page = payload.page
            let url = 'admin-view/vendors_orders'
            let query = getQuery(payload.filters)
            url += query
            if (!url.includes('?')) {
                url += '?'
            }
            url += `&office_pk=${office}`
            let sortingSlug = rootGetters['ADMIN_VIEW/getSortingSlug'];
            url += `&sort=${sortingSlug}`
            let selectedOffices = rootGetters['ADMIN_VIEW/getSelectedOffices'];
            if (selectedOffices.length == 0) {
                selectedOffices = `${office}`
            }
            else {
                selectedOffices = selectedOffices.join(',')
            }
            url += `&offices=${selectedOffices}`
            console.log(url)
            let { data } = await axios.reqApi(url).get()
            commit('setVendorOrders', data.data.results);

            return data.data;
        } catch (e) {
            return Promise.reject(e.response.data.message);
        }
    },
    FETCH_DASHBOARD_ANALYTICS: async ({ rootGetters, commit, state }, payload) => {
        try {
            let url = 'admin-view/dashboard_analytics'
            let { data } = await axios.reqApi(url).get();
            commit('setTotalOffices', data.data.offices.length);
            return data
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}