const PREFIX = 'myOrdo_';

export default {
  setItem(key, value) {
    localStorage.setItem(PREFIX + key, JSON.stringify(value));
  },

  getItem(key) {
    const value = localStorage.getItem(PREFIX + key);
    return (value != 'undefined' && value != null) ? JSON.parse(value) : null;
  },

  removeItem(key) {
    localStorage.removeItem(PREFIX + key);
  },
}; 