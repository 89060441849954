import axios from '@/axios'

const state = {
  data: null,
  on_boarding_step: 5,
  status: null,
}

const getters = {
  onboarding: s => s.data,
  next_on_boarding_step: s => {
    if(s.on_boarding_step < 3) return 3
    if(s.on_boarding_step < 4) return 4
    if(s.on_boarding_step < 5) return 5
    return 5
  },
}

const mutations = {
  SET_ONBOARDING_DATA: (s, d) => s.data = d,
  UPDATE_ONBOARDING_DATA: (s, d) => s.data = Object.assign({}, s.data, d),
}

const actions = {
  ONBOARDING_SIGNUP: async ({dispatch, commit}, payload) => {
    try {
      let { data } = await axios.reqApi("auth/signup").post(payload);
      if(payload.token){
        return await dispatch('USER/LOGIN', {username: payload.email, password: payload.password}, {root: true})
      }else{
        commit('USER/SET_TOKEN', data.data.token, { root: true })
        let d = data.data
        delete d.token
        commit('SET_ONBOARDING_DATA', d)
        return d;
      }
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  ONBOARDING_CREATE_OFFICES: async ({commit, state, rootGetters}, payload) => {
    try {
      const single = payload.single
      if(single != null) delete payload.single
      const companyId = !single ? state.data.company.id : rootGetters['USER/company'].id
      let { data } = await axios.reqApi("companies/" + companyId).put(payload)
      if(!single) commit('UPDATE_ONBOARDING_DATA', {offices: data.data.offices})
      return data.data.offices;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },

  CREATE_SUBSCRIPTION: async ({state, rootGetters}, payloadStripe) => {
    try {
      let jsonPayloadStripe = JSON.stringify(payloadStripe);
      let url = `subscription/create-subscription`;
      let { data } = await axios.reqApi(url).post(jsonPayloadStripe)

      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },

  FETXH_COMPANY_WITH_OFFICES: async ({commit, state, rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : state.data.company.id
      let { data } = await axios.reqApi("companies/" + companyId).put(payload)
      commit('UPDATE_ONBOARDING_DATA', {offices: data.data.offices})
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_VENDORS: async ({rootGetters, state}, payload) => {
    try {
      let { data } = await axios.reqApi("vendors").get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CONNECT_VENDOR: async ({rootGetters, state}, payload) => {
    try {
      const companyId = state.data && state.data.company ? state.data.company.id : rootGetters['USER/company'].id
      const officeId = payload.office
      delete payload.office
      payload['company'] = companyId;
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${officeId}/vendors`).post(payload)
      return data.data;
    } catch (e) {
      console.log(e)
      return Promise.reject(e.response.data.message);
    }
  },
  RELINK_VENDOR: async ({rootGetters, state}, payload) => {
    try {
      const companyId = state.data && state.data.company ? state.data.company.id : rootGetters['USER/company'].id
      const officeId = payload.office
      const officeVendorId = payload.officeVendorId
      delete payload.officeVendorId
      delete payload.office
      payload['company'] = companyId;
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${officeId}/vendors/${officeVendorId}/relink_office_vendor`).post(payload)
      return data.data;
    } catch (e) {
      console.log(e)
      return Promise.reject(e.response.data.message);
    }
  },
  INVITE_TEAM_MEMBER: async ({commit, state, rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : state.data.company.id
      let { data } = await axios.reqApi(`companies/${companyId}/members/bulk`).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  OFFICE_BUDGET: async ({dispatch, state, rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : state.data.company.id
      const office = payload.office
      delete payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/budgets`).post(payload)
      if(!rootGetters['USER/company']) dispatch('FETXH_COMPANY_WITH_OFFICES')
      else await dispatch('USER/GET_ACCOUNT', {}, {root: true})
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_OFFICE_BUDGET: async ({dispatch, state, rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : state.data.company.id
      const office = payload.office
      delete payload.office
      const budgetId = payload.id
      delete payload.id
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}/budgets/${budgetId}`).put(payload)
      if(!rootGetters['USER/company']) dispatch('FETXH_COMPANY_WITH_OFFICES')
      else await dispatch('USER/GET_ACCOUNT', {}, {root: true})
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CHECK_COUPON: async ({dispatch, state, rootGetters}, payload) => {
    try {
      return axios.reqApi(`check-coupon?code=${payload.coupon}`).get()
    } catch (e) {
      throw e
    }
  },
  FETCH_ONBOARDING_STATUS: async ({dispatch, state, rootGetters}, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : state.data.company.id
      let res
      if(payload) res = axios.reqApi(`companies/${companyId}/onboarding_status`).patch(payload)
      else res = axios.reqApi(`companies/${companyId}/onboarding_status`).get()
      state.status = (await res).data.data
    } catch (e) {
      throw e
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}