<template>
  <div class="d-flex flex-column flex-lg-row">
    <div>
      <div>
        <div :class="['mb-3', { 'billing-info': !single }]">
          <div
            :ref="`bill`"
            class="bill-head cursor-pointer d-flex justify-content-between align-items-center"
            v-b-toggle="`bill`"
          >
            <p class="c-title mb-0">Please Enter Card Details</p>
            <b-icon icon="chevron-down"></b-icon>
          </div>
          <b-collapse
            :id="`bill`"
            accordion="my-accordion"
            role="tabpanel"
            visible
          >
            <validation-observer :ref="`billObs`" v-slot="{ handleSubmit }">
              <b-form
                @submit.stop.prevent="handleSubmit(() => next1)"
                class="mt-3"
              >
                <div class="dash mb-3 mt-2"></div>
                <p class="form-label mb-1">Credit Card Number</p>
                <validation-provider
                  name="Card Number"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-input
                    v-model="formData.cc_number"
                    v-cardformat:formatCardNumber
                    class="custom-input"
                    placeholder="Enter Card Number"
                    :state="getValidationState(validationContext)"
                  ></b-input>
                  <b-form-invalid-feedback
                    v-for="(error, eI) in validationContext.errors"
                    :key="eI"
                    >{{ error }}</b-form-invalid-feedback
                  >
                </validation-provider>
                <b-row>
                  <b-col cols="6">
                    <validation-provider
                      name="Expiry"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <p class="form-label mb-1 mt-3">Expiration Date</p>
                      <b-input
                        class="custom-input"
                        placeholder="MM/YY"
                        maxlength="7"
                        v-cardformat:formatCardExpiry
                        @keydown="(e) => e.target.setAttribute('maxlength', 7)"
                        v-model="formData.cc_expiry"
                        :state="getValidationState(validationContext)"
                      ></b-input>
                      <b-form-invalid-feedback
                        v-for="(error, eI) in validationContext.errors"
                        :key="eI"
                        >{{ error }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      name="CVV"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <p class="form-label mb-1 mt-3">CVV</p>
                      <b-input
                        v-model="formData.cc_code"
                        v-cardformat:formatCardCVC
                        class="custom-input"
                        placeholder="Enter CVV"
                        :state="getValidationState(validationContext)"
                      ></b-input>
                      <b-form-invalid-feedback
                        v-for="(error, eI) in validationContext.errors"
                        :key="eI"
                        >{{ error }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="dash mt-4"></div>
                <validation-provider
                  name="address"
                  rules=""
                  v-slot="validationContext"
                >
                  <p class="form-label mb-1 mt-3">Billing Address</p>
                  <b-input
                    v-model="formData.address"
                    class="custom-input"
                    placeholder="Enter your card address"
                    :state="getValidationState(validationContext)"
                  ></b-input>
                  <b-form-invalid-feedback
                    v-for="(error, eI) in validationContext.errors"
                    :key="eI"
                    >{{ error }}</b-form-invalid-feedback
                  >
                </validation-provider>
                <b-row>
                  <b-col cols="4">
                    <validation-provider
                      name="state"
                      rules=""
                      v-slot="validationContext"
                    >
                      <p class="form-label mb-1 mt-3">State</p>
                      <b-form-select
                        v-model="formData.state"
                        :options="states"
                        class="custom-input"
                        placeholder="State"
                        :state="getValidationState(validationContext)"
                      >
                        <template #first>
                          <b-form-select-option :value="null"
                            >Select state</b-form-select-option
                          >
                        </template></b-form-select
                      >
                      <b-form-invalid-feedback
                        v-for="(error, eI) in validationContext.errors"
                        :key="eI"
                        >{{ error }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-col>
                  <b-col cols="4">
                    <validation-provider
                      name="city"
                      rules="required"
                      v-slot="validationContext"
                      :custom-messages="{ required: 'Required' }"
                    >
                      <p class="form-label mb-1 mt-3">City</p>
                      <b-select
                        v-model="formData.city"
                        class="custom-input"
                        placeholder="Enter City"
                        :state="getValidationState(validationContext)"
                      >
                        <template #first>
                          <b-form-select-option :value="null"
                            >Select city</b-form-select-option
                          >
                        </template>
                        <template v-if="formData.state === null">
                          <b-form-select-option :value="null"
                            >Select a state first</b-form-select-option
                          >
                        </template>
                        <template v-else>
                          <template v-for="(stateData, state) in cities">
                            <template
                              v-if="getStateCode(state) == formData.state"
                            >
                              <b-form-select-option
                                :value="city"
                                v-for="city in stateData.cities"
                                :key="city"
                              >
                                {{ city }}
                              </b-form-select-option>
                            </template>
                          </template>
                        </template>
                      </b-select>
                      <b-form-invalid-feedback
                        v-for="(error, eI) in validationContext.errors"
                        :key="eI"
                        :style="{
                          display: validationContext.invalid ? 'block' : 'none',
                        }"
                        >{{ error }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-col>

                  <b-col cols="4">
                    <validation-provider
                      name="zip"
                      rules="min:5"
                      v-slot="validationContext"
                      :custom-messages="{
                        required:
                          'Zip Code field must be at least 5 characters',
                        min: 'Zip Code field must be at least 5 characters',
                      }"
                    >
                      <p class="form-label mb-1 mt-3">Zip Code</p>
                      <b-input
                        v-model="formData.zip"
                        class="custom-input"
                        placeholder="Enter Zip"
                        v-cardformat:restrictNumeric
                        :state="getValidationState(validationContext)"
                      ></b-input>
                      <b-form-invalid-feedback
                        v-for="(error, eI) in validationContext.errors"
                        :key="eI"
                        >{{ error }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="dash mt-4"></div>

                <div class="d-flex justify-content-center mt-4">
                  <b-btn class="custom-btn o-btn" @click="next1">
                    <b-spinner
                      small
                      variant="light"
                      class="mr-2"
                      v-if="submitting"
                    ></b-spinner>
                    <span>Next</span>
                  </b-btn>
                </div>
              </b-form>
            </validation-observer>
          </b-collapse>
        </div>
      </div>
    </div>
    <div v-if="!single" class="ml-lg-3 mt-3 mt-lg-0">
      <div
        class="billing-info__2"
        style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)"
      >
        <p class="mb-2 d-flex align-items-center">
          <span class="c-title">Order Total : </span>
          <span class="c-title title-amount ml-3">${{ total() }}</span>
          <span class="form-label ml-2"> /per month</span>
          <span class="form-label ml-2">
            ( {{ offices.length }} office<span v-if="offices.length > 1"
              >s</span
            >
            )</span
          >
        </p>
        <p class="c-title__2 text-success mb-1" v-if="firstCouponData()">
          for {{ firstCouponData().duration_in_months }} mmonth<span
            v-if="firstCouponData().duration_in_months > 1"
            >s</span
          >
          starting {{ moment().format("MM/DD/YY") }}
        </p>

        <p class="form-label mb-0" v-if="firstCouponData()">
          $99/mo starting on {{ freeTrialDate() }}.
        </p>

        <p class="form-label mb-0">
          30 Day money back guarantee. If within 30 days Ordo dosen't make you
          happier than a toothbrush on Halloween, we'll refund you faster than
          you can say "open wide"!
        </p>
      </div>
      <div
        class="billing-info__2 mt-3 pb-4"
        style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)"
      >
        <p class="mb-3 c-title title-2">Ordo Subscription</p>
        <p class="mb-2 d-flex align-items-center">
          <span class="c-title title-amount">$99</span>
          <span class="form-label ml-2"> /per month, per office</span>
        </p>
        <div class="dash my-3"></div>
        <div class="li-wrapper">
          <div class="lis px-4 py-4">
            <ul class="ul">
              <li class="mb-2">
                Link your dental supply vendors directly into Ordo
              </li>
              <li class="mb-2">Real-time vendor price comparison</li>
              <li class="mb-2">Organize your previously purchased items</li>
              <li class="mb-2">
                Simple budgeting features to track and manage budgets
              </li>
              <li class="mb-2">Set order approvals</li>
              <li class="mb-2">Track and manage your orders</li>
              <li class="mb-2">Process returns</li>
              <li>And many more features!</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/ui/Input";
import Button from "@/components/ui/Button";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import Swal from "sweetalert2";
import GoTo from "@/mixins/GoTo";
import US_STATES from "@/assets/json/usa_states.json";
import US_CITIES from "@/assets/json/usa_cities_with_states.json";
import moment from "moment";
export default {
  props: {
    offices: {
      type: Array,
      default: [],
    },
    single: Boolean,
  },
  mixins: [FormMixin, GoTo],
  components: {
    Input,
    Button,
  },
  data() {
    return {
      formData: [],
      currentState: null,
      fD: {
        card: null,
        expiration: null,
        security: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        coupon: null,
        coupon_data: null,
        isValidCoupon: false,
      },
      submitting: false,
      errors: [],
      sameAddForAll: false,
      submittingCoupon: false,
    };
  },
  created() {
    this.formData = this.offices.map((ofc) => ({
      id: ofc.id,
      cc_number: ofc.cc_number || null,
      cc_expiry: ofc.cc_expiry || null,
      cc_code: ofc.cc_code || null,
      address: ofc.address || null,
      city: ofc.city || null,
      state: ofc.state || null,
      zip: ofc.zip || null,
    }));
  },

  computed: {
    cities() {
      return US_CITIES;
    },
    states() {
      return US_STATES;
    },
    moment() {
      return moment;
    },
  },
  watch: {
    formData: {
      handler(v) {
        this.onSaveForAllChange();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("ONBOARDING", [
      "ONBOARDING_CREATE_OFFICES",
      "CHECK_COUPON",
      "CREATE_SUBSCRIPTION",
    ]),
    ...mapActions("OFFICE", ["UPDATE_OFFICE_BILLING"]),
    ...mapActions("USER", ["GET_ACCOUNT"]),
    freeTrialDate() {
      if (this.firstCouponData() && this.firstCouponData().duration_in_months) {
        return moment()
          .add(this.firstCouponData().duration_in_months, "month")
          .format("MM/DD/YYYY");
      }
      return moment().add(14, "days").format("MM/DD/YYYY");
    },
    getStateCode(stateName) {
      for (const state of this.states) {
        if (state.text === stateName || state.value === stateName) {
          return state.value;
        }
      }
      return "Unknown"; // Return "Unknown" for unsupported states
    },
    total() {
      return this.formData
        .map((ofc) => {
          if (ofc.coupon_data != null) {
            if (ofc.coupon_data.amount_off) {
              return 99 - ofc.coupon_data.amount_off / 100;
            }
            return 99 - (99 * ofc.coupon_data.percent_off) / 100;
          }
          return 99;
        })
        .reduce((a, b) => a + b, 0);
    },
    firstCouponData() {
      const of = this.formData.find((ofc) => ofc.coupon_data != null);
      if (of) return of.coupon_data;
      else null;
    },
    async submitCoupon(index) {
      this.$refs[`billObs-${index}`][0].reset();
      if (
        this.formData[index].coupon != null &&
        this.formData[index].coupon.trim().length > 0
      ) {
        this.submittingCoupon = true;
        try {
          const { data } = await this.CHECK_COUPON({
            coupon: this.formData[index].coupon,
          });
          this.formData[index].isValidCoupon = true;
          this.formData[index].coupon_data = JSON.parse(
            JSON.stringify(data.data)
          );
          this.formData[index].coupon_data_api = true;
          this.$forceUpdate();
        } catch (e) {
          this.formData[index].isValidCoupon = false;
          this.$refs[`billObs-${index}`][0].setErrors({
            Messages: e.response.data.data.error,
          });
        }
        this.submittingCoupon = false;
      }
    },
    onSaveForAllChange() {
      if (this.sameAddForAll) {
        for (let i = 1; i < this.formData.length; i++) {
          this.formData[i].cc_number = this.formData[0].cc_number;
          this.formData[i].cc_expiry = this.formData[0].cc_expiry;
          this.formData[i].cc_code = this.formData[0].cc_code;
          this.formData[i].address = this.formData[0].address;
          this.formData[i].city = this.formData[0].city;
          this.formData[i].state = this.formData[0].state;
          this.formData[i].zip = this.formData[0].zip;
          this.formData[i].coupon = this.formData[0].coupon;
          this.formData[i].coupon_data = JSON.parse(
            JSON.stringify(this.formData[0].coupon_data)
          );
        }
      } else {
        for (let i = 1; i < this.formData.length; i++) {
          if (!this.formData[i].coupon_data_api) {
            this.formData[i].coupon = null;
            this.formData[i].coupon_data = null;
          }
        }
      }
      this.$forceUpdate();
    },
    async next1() {
      if (this.errors.length > 0) this.errors = {};
      if (this.submitting) return;
      let isValid = await this.$refs["billObs"].validate();
      if (isValid) {
        this.submitting = true;
        try {
          let payload = {
            on_boarding_step: 2,
          };
          payload = {
            on_boarding_step: 2,
            offices: this.formData.map((formData, i) => {
              const obj = {
                id: formData.id,
                cc_number: this.formData.cc_number
                  ? this.formData.cc_number.replaceAll(" ", "")
                  : this.formData.cc_number,
                cc_expiry: this.formData.cc_expiry
                  ? this.formData.cc_expiry.replaceAll(" ", "")
                  : this.formData.cc_expiry,
                cc_code: this.formData.cc_code
                  ? this.formData.cc_code.replaceAll(" ", "")
                  : this.formData.cc_code,
                address: this.formData.address
                  ? this.formData.address
                  : this.formData.address,
              };
              if (formData.coupon && formData.coupon.trim().length > 0)
                obj["coupon"] = formData.coupon;
              return obj;
            }),
          };

          let payloadStripe = {
            company_id: this.offices[0].company,
            quantity: this.offices.length,
            cc_number: this.formData.cc_number
              ? this.formData.cc_number.replaceAll(" ", "")
              : this.formData.cc_number,
            cc_expiry: this.formData.cc_expiry
              ? this.formData.cc_expiry.replaceAll(" ", "")
              : this.formData.cc_expiry,
            cc_code: this.formData.cc_code
              ? parseInt(this.formData.cc_code.replaceAll(" ", ""))
              : this.formData.cc_code,
          };

          let res = await this.CREATE_SUBSCRIPTION(payloadStripe);
          await this.ONBOARDING_CREATE_OFFICES(payload);
          await this.GET_ACCOUNT();
          this.$router
            .push({
              name: "Dashboard",
              query: { start_pop: "show", enableOnBoarding: true },
            })
            .catch(() => {});
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid Card Details!",
            confirmButtonText: "Try Again",
            confirmButtonColor: "#e83e8c",
          });
          this.$refs["billObs"].setErrors({ Messages: e.message });
        }
        this.submitting = false;
      }
    },

    async next(index) {
      if (this.submittingCoupon) return;
      if (this.errors.length > index) this.errors[index] = {};
      if (this.submitting) return;
      let isValid = await this.$refs[`billObs-${index}`][0].validate();
      if (isValid) {
        this.submitting = true;
        try {
          if (!this.single) {
            let payload = {
              on_boarding_step: 2,
              offices: this.formData.map((formData, i) => {
                const obj = {
                  id: formData.id,
                  cc_number: formData.cc_number
                    ? formData.cc_number.replaceAll(" ", "")
                    : formData.cc_number,
                  cc_expiry: formData.cc_expiry
                    ? formData.cc_expiry.replaceAll(" ", "")
                    : formData.cc_expiry,
                  cc_code: formData.cc_code
                    ? formData.cc_code.replaceAll(" ", "")
                    : formData.cc_code,
                  address: formData.address
                    ? formData.address
                    : formData.address,
                };
                if (formData.coupon && formData.coupon.trim().length > 0)
                  obj["coupon"] = formData.coupon;
                return obj;
              }),
            };

            if (index == this.offices.length - 1 || this.sameAddForAll) {
              await this.ONBOARDING_CREATE_OFFICES(payload);

              await this.GET_ACCOUNT();
              this.$router
                .push({
                  name: "Dashboard",
                  query: { start_pop: "show", enableOnBoarding: true },
                })
                .catch(() => {});
            } else {
              await this.$nextTick();
              this.$refs[`bill-${index}`][0].click();
              this.$refs[`bill-${index + 1}`][0].click();
            }
          } else {
            let payload = {
              office: this.offices[0].id,
              cc_number: this.formData[0].cc_number.replaceAll(" ", ""),
              cc_expiry: this.formData[0].cc_expiry.replaceAll(" ", ""),
              cc_code: this.formData[0].cc_code.replaceAll(" ", ""),
              address: this.formData[0].address,
              coupon: this.formData[0].coupon,
            };
            await this.UPDATE_OFFICE_BILLING(payload);
            await this.GET_ACCOUNT();
            this.$emit("done");
          }
        } catch (e) {
          this.$refs[`billObs-${index}`][0].setErrors({ Messages: e.message });
        }
        this.submitting = false;
      }
    },
    setErrors(e, index) {
      if (e.message != null) {
        let errors = {
          Messages: e.message,
        };
        this.$refs[`billObs-${index}`][0].setErrors(errors);
      } else {
        e = e.data.offices;
        let obj = {
          cc_number: "Card Number",
          cc_expiry: "Expiry",
          cc_code: "CVV",
        };
        let errors = {};
        Object.keys(e[0]).map((key, j) => {
          errors[obj[key]] = e[0][key];
        });
        this.$refs[`billObs-${index}`][0].setErrors(errors);
        let d = this.$refs[`bill-${index}`][0];
        if (d && [...d.classList].includes("collapsed")) d.click();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.billing-info
  width: 450px
  border-radius: 8px
  background-color: #ffffff
  padding: 15px 32px
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  &__2
    @extend .billing-info
    width: 385px
  @media screen and (max-width: 992px)
    width: 100%
  .title-amount
    color: #29bf12
    font-size: 30px
  .title-2
    font-size: 20px
  .li-wrapper
    border-radius: 8px
    border-style: solid
    border-width: 3px
    border-image-source: linear-gradient(to bottom, #ea3cbe 0%, #50aad5 52%, #5ebc3b 100%)
    border-image-slice: 1
    background-image: linear-gradient(to bottom, #eef4f7, #eef4f7), linear-gradient(to bottom, #ea3cbe 0%, #50aad5 52%, #5ebc3b 100%)
    background-origin: border-box
    background-clip: content-box, border-box
    // background-color: #edf4f7
    .ul
      padding: 0
      padding-left: 13px
      margin-bottom: 0
      li
        font-family: GILROY-SEMIBOLD
        font-size: 12px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        text-align: left
        color: #707070
        &::marker
          font-size: 14px
</style>
