<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <div>
        <p class="d-title mt-2 mb-0" style="font-size: 18px;">Billing Details</p>
        <p class="mb-0 d-desc__m551" style="width: 400px;">Edit and manage your account below.</p>
      </div>
      <div class="">
      <p class="mb-0 d-title__2 mt-2" style="font-size: 15px;">Monthly Subscription Total : <span class="text-success">${{ this.subscriptionDetails.total_amount }}</span>
      </p>
      <p class="mb-0 d-title__2" style="font-size: 15px;">Subscription End Date : <span class="text-success">{{ this.subscriptionDetails.current_period_end }}</span>
      </p>
    </div>
    </div>

    <!-- for credit card settings and moved from card settings file to here as per client request 12-07-23  -->
    <!-- <div class="manage-team mb-3">
      <div class="d-flex justify-content-between mb-1">
        <p class="d-title mt-2 mb-0" style="font-size: 18px;">Update Credit Card</p>
      </div>
      <div class="d-flex mb-4 justify-content-between">
        <p class="mb-0 d-desc__m551" style="width: 400px;">Update your Credit Card Information</p>
        <p class="mb-0 d-title__2" style="font-size: 16px;"> 
          <a @click="(updateCardModal = true)" class="custom-btn btn danger__2 ml-2"> Update </a>
        </p>
      </div>
    </div> -->
    <div class="manage-team mb-3">
      <div class="d-flex justify-content-between mb-3">
        <p class="d-title mt-2 mb-0" style="font-size: 18px;">Manage Your Subsrciption </p>
      </div>
      <div class="d-flex mb-4 justify-content-between">
        <p class="mb-0 d-desc fs-14 text-capitalize"> {{ company.name }}</p>
        <p class="mb-0 d-desc fs-14">Total Offices: <span class="font-weight-bold">{{ this.subscriptionDetails.quantity }}</span></p>
        <div class="sm-card d-flex align-items-center">
          <p class="mb-0 d-desc__3 gilroy-b c-text fs-12"><span class="text-success ml-2">/Office : $99.00</span></p>
          <b-btn class="custom-btn danger__2 sm-btn ml-4" style="width: max-content" v-if="company.is_subscription_cancel === true" @click="toggleAlreadyCanceledModal(true)">
            Cancel Subscription
          </b-btn>
          <b-btn class="custom-btn danger__2 sm-btn ml-4" style="width: max-content" v-else @click="toggleCancelModal(true)">
            Cancel Subscription
          </b-btn>
          <b-btn class="custom-btn danger__2 sm-btn ml-3" style="width: max-content" @click="toggleRenewModal(true)">
            Renew Subscription
          </b-btn>
        </div>
      </div>
      
    </div>

    <!-- <div v-for="(ofc, oI) in filteredOffices" :key="oI">
      <div class="dash mb-4" v-if="oI > 0"></div>
      <div class="d-flex justify-content-between align-items-center my-3" v-b-toggle="`ofc-set-${oI}`"
        :ref="`ofc-set-${oI}`" @click="handleAccordionToggle(oI, ofc.name)">
        <p class="mb-2 d-title__2" style="font-size: 16px;">{{ ofc.name }}</p>
        <b-icon icon="chevron-down" class="ml-3"></b-icon>
      </div>
      <b-collapse :id="`ofc-set-${oI}`" accordion="my-accordion" role="tabpanel">
        <div class="notf-surr">
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <div class="notf-p1">
              <p class="mb-0 d-title__4">Ordo Subscription Plan</p>
            </div>
            <div class="ml-auto">
              <div class="sm-card">
                <p class="mb-0 d-desc__3 gilroy-b c-text"><span class="text-success ml-2">$99.00</span></p>
                <b-btn class="custom-btn danger__2 sm-btn ml-4" style="width: max-content" @click="toggleCancelModal(true, ofc.id)">
                  Cancel Subscription
                </b-btn>
                <b-btn class="custom-btn danger__2 sm-btn ml-3" style="width: max-content" @click="toggleRenewModal(true, ofc.id)">
                  Renew Subscription
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div> -->
    <modal :modal="billModal" @change="togglebillModal" size="md">
      <div>
        <billing-info :offices="[selectedOfc]" :single="true" @done="togglebillModal" />
      </div>
    </modal>
    <modal :modal="cancelModal" @change="toggleCancelModal" size="md">
      <p class="modal-header-title text-center">Cancel Ordo Subscription</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-3">Are you sure you want to Cancel your Ordo Subscription for all the offices?</p>
      </div>
      <div class="dash mb-4"></div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-lg custom-btn danger__2 mr-3" @click="(cancelModal = false)">No</button> 
        <button class="btn btn-lg custom-btn danger__2" @click="cancelSubscription">Yes</button>
      </div>
    </modal>
    <modal :modal="alreadyCanceledModal" @change="toggleAlreadyCanceledModal" size="md">
      <p class="modal-header-title text-center">Cancel Ordo Subscription</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-3">You have already canceled the Ordo Subscription </p>
      </div>
      <div class="dash mb-4"></div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-lg custom-btn danger__2 mr-3" @click="(alreadyCanceledModal = false)">OK</button> 
      </div>
    </modal>
    <modal :modal="renewModal" @change="toggleRenewModal" size="md">
      <p class="modal-header-title text-center">Renew Ordo Subscription</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-3">Are you sure you want to renew your Ordo subscription for all the offices?</p>
      </div>
      <div class="dash mb-4"></div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-lg custom-btn danger__2 mr-3" @click="(renewModal = false)">No</button> 
        <button class="btn btn-lg custom-btn danger__2" @click="renewSubscription">Yes</button>
      </div>
    </modal>
    <modal :modal="updateCardModal" @change="toggleUpdateCardModal" size="md">
      <p class="modal-header-title text-center">Update Credit Card</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4">Please email <a href="mailto:hello@joinordo.com"
            class="text-danger__2">hello@joinordo.com</a> to update your Credit Card Information</p>
      </div>
      <div class="dash mb-4"></div>
      <div>
        <p class="c-title__2 mb-1 mt-2 cursor-pointer text-center" @click="(updateCardModal = false)">Close</p>
      </div>
    </modal>
  </div>
</template>

<script>
import OfficeMixin from '@/mixins/OfficeMixin'
import BillingInfo from '@/components/onboarding/BillingInfo'
import Modal from '@/components/modals/Modal'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex';
export default {
  mixins: [OfficeMixin],
  components: {
    BillingInfo,
    Modal,
  },
  data: () => ({
    billModal: false,
    selectedOfc: null,
    cancelModal: false,
    renewModal: false,
    alreadyCanceledModal: false,
    updateCardModal: false,
    officeId: null,
    subscriptionDetails: {}
  }),
  async beforeMount() {
    let res = await this.SUBSCRIPTION_DETAILS();
    this.subscriptionDetails = res;
  },
  computed: {
    ...mapGetters('USER', ['company']),
    cardEnd() {
      if (this.office.cc_number && this.office.cc_number.length >= 4) return this.office.cc_number.substr(this.office.cc_number.length - 4)
      return this.office.cc_number
    },
    filteredOffices() {
      const selectedOffice = this.offices[this.officeIndex];
      return [selectedOffice].concat(
        this.offices.filter(o => o.id !== this.officeId && o.id !== selectedOffice.id)
      );
    },
  },
  methods: {
    ...mapActions('OFFICE', ['CANCEL_SUBSCRIPTION', 'RENEW_SUBSCRIPTION', 'SUBSCRIPTION_DETAILS']),

    togglebillModal(v) {
      if (v != this.billModal) this.billModal = v != null ? v : false
    },
    openBillModal(office) {
      this.selectedOfc = office
      this.billModal = true
    },
    toggleCancelModal(v) {
      // this.officeId = office;
      if (v != this.cancelModal) this.cancelModal = v || false
    },
    toggleAlreadyCanceledModal(v) {
      if (v != this.alreadyCanceledModal) this.alreadyCanceledModal = v || false
    },
    toggleRenewModal(v) {
      // this.officeId = office;
      if (v != this.renewModal) this.renewModal = v || false
    },
    toggleUpdateCardModal(v) {
      if (v != this.updateCardModal) this.updateCardModal = v || false
    },
    handleAccordionToggle(index, officeName, name) {
      this.$emit('accordion-toggle', { index, officeName });
      console.log(index, officeName)
    },
    async cancelSubscription() { 
      // let office = this.officeId;
      let res = await this.CANCEL_SUBSCRIPTION();
      console.log('Response API: ', res)
      this.cancelModal = false;
    },
    async renewSubscription() {
      // let office = this.officeId;
      let res = await this.RENEW_SUBSCRIPTION();
      console.log('Response Renew API: ', res)
      this.renewModal = false;
    }
  },
}
</script>

<style lang="sass" scoped>
.custom-btn.danger__2
    display: flex
    align-items: center
    min-height: auto

.c-title__2
    font-size: 14px !important

.fs-14
    font-size:14px !important

.fs-12
  font-size:12px !important

.modal-header-desc
    font-size: 14px !important

@import '../../../style/settings'
@import '../../../style/onboarding/onboarding'
</style>