<template>
  <logo class="auth-logo cursor-pointer" />
</template>

<script>
import Logo from '@/components/logo/Logo'
export default {
  components: {
    Logo
  },
}
</script>