<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 1000px;">Control you Budget with Order Approvals</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Order Approvals allow you to make sure your team is staying within your budget</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Set Approvals the way you want</p>
        <p class="subtitle-3 mb-3">Ordo allows you to select between requiring an approval every time an order is placed or only when an order goes above your budget. This way you can decide how often you want to review an order.</p>
        <p class="text-center mb-3">
          <img src="./features_images/Order_Approvals/image_one.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4 mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/eight.svg)
</style>