<template>
  <div class="d-card px-3 py-3 w-100" style="min-width: max-content;">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <p class="d-title mb-0" style="font-size: 16px; font-family: Gilroy-Bold;">
        <span v-if="subaccount.slug == 'dental'">Dental Supply Activity</span>
        <span v-else-if="subaccount.slug == 'office'">Front Office Supply Activity</span>
        <span v-else-if="subaccount.slug == 'miscellaneous'">Uncategorized Activity</span>
        <span v-else>{{ subaccount.slug }} Activity</span>
      </p>
    </div>
    <grow-loader v-if="fetchingFilterData" />
    <div class="wrapper w-100 py-3" v-else>
      <div class="activity py-2 py-lg-0" v-if="showTotalBudget">
        <p :class="['d-title__8 mb-0 text-center', 'text-success']" style="font-size: 22px; font-family: Gilroy-Bold;">${{totalBudget}}</p>
        <p class="d-desc mb-0 text-center" style="font-family: Gilroy-Semibold;">Total Budget</p>
      </div>
      <div class="h-rect my-2 my-lg-0" v-if="showTotalBudget"></div>
      <div class="activity py-2 py-lg-0" v-if="showSpent">
        <p :class="['d-title__8 mb-0 text-center', 'text-success']" style="font-size: 22px; font-family: Gilroy-Bold;">${{totalSpend}}</p>
        <p class="d-desc mb-0 text-center" style="font-family: Gilroy-Semibold;">Total Spend</p>
      </div>
      <div class="activity py-2 py-lg-0" v-if="showAmountSpentProp">
        <p :class="['d-title__8 mb-0 text-center', 'text-success']" style="font-size: 28px; font-family: Gilroy-Bold;">${{totalSpend}}</p>
        <p class="d-desc mb-0 text-center f-14-px" style="font-family: Gilroy-Semibold;">Amount Spent</p>
      </div>
      <div class="h-rect my-2 my-lg-0" v-if="showTotalRemainBudget"></div>
      <div class="activity py-2 py-lg-0" v-if="showTotalRemainBudget">
        <p :class="['d-title__8 mb-0 text-center', overBudget ? 'over-budget-txt' : 'text-success']" style="font-size: 22px; font-family: Gilroy-Bold;">${{remainingBudget}}</p>
        <p class="d-desc mb-0 text-center" style="font-family: Gilroy-Semibold;">
          {{overBudget ? 'Over Budget' : 'Remaining Budget'}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GrowLoader from '@/components/loaders/GrowLoader.vue'
import moment from 'moment'
import StatisticsMixin from '@/mixins/Statistics'
import MonthMixin from '@/mixins/Month'
import NumberComma from '@/mixins/NumberComma'
import Filter from '@/mixins/Filter'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { GrowLoader },
  mixins: [StatisticsMixin, MonthMixin, NumberComma, Filter],
  props: {
    officeActivity: Boolean,
    extTitle: {
      type: String,
      default: 'Finance Activity'
    },
    showTotalBudget: { type: Boolean, default: true },
    showTotalRemainBudget: { type: Boolean, default: true },
    showSpent: { type: Boolean, default: true },
    showAmountSpentProp: { type: Boolean, default: false },
    type: { type: String, },

    monthBudgetData: Object,
    fetchingFilterData: Boolean,
    filterApplied: Boolean,
    slug: String,
    subaccount: Object,
  },
  data: () => ({
  }),
  created(){
    this.period = this.currentMonth
    this.$BUS.$on('filter:filter-change', filters => this.onFilterChange(filters, async (filt) => {
      this.setPeriod(filters)
    }))
  },
  beforeDestroy(){
    this.$BUS.$off('filter:filter-change')
  },
  computed: {
    totalBudget(){
      return this.numberWithCommas((this.subaccount.total_amount || 0.00).toFixed(2))
    },
    totalSpend(){
      return this.numberWithCommas((this.subaccount.total_spend || 0.00).toFixed(2))
    },
    remainingBudget(){
      return this.numberWithCommas(((this.totalBudget - this.totalSpend) || 0.00).toFixed(2))
    },
    overBudget(){
      if(this.currencyToNumber(this.remainingBudget) < 0) return true
      return false
    },
  },
  methods: {
    
  },
}
</script>

<style lang="sass" scoped>

.wrapper
  border-radius: 8px
  background-color: #f9fafa
  display: flex
  justify-content: space-around
  align-items: center
  @media screen and (max-width: 1430px)
    display: block
  .h-rect
    width: 0.5px
    opacity: 0.2
    height: 120px
    border-radius: 8px
    background-color: #687C8A
    @media screen and (max-width: 1430px)
      width: 100%
      height: 2px
  .activity
    height: 120px
    display: flex
    flex-flow: column
    justify-content: center
    min-width: 130px
    width: calc( 100% / 3 )
    @media screen and (max-width: 992px)
      width: unset
      height: auto
      min-width: unset
    .amount
      font-size: 34px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      text-align: left
      color: #1c455a
.over-budget-txt
  color: #FF38C9
</style>