<template>
  <div class="landing-cover">
    <div class="all">
      <div class="cov-image">
        <div class="cov-overlay"></div>
        <img src="@/assets/images/landing/header_image.webp" alt="">
        <img src="@/assets/images/svg_icon/play_icon.svg" alt="" class="play-pause-btn" ref="playPauseBtn" @click="toggleVideoPlay">
      </div>
      <div class="cov-video" v-show="playVideo">
        <video ref="video" :src="`${publicPath}videos/ORDO.mp4`" v-click-outside="onOutsideVideoClick" controls></video>
      </div>
      <div class="upper pb-4">
        <img src="@/assets/images/landing/landing_page_header.webp" alt="" class="h-img">
        <landing-header />
        <div class="l-wd-max mx-auto s1 px-3 px-lg-0">
          <p class="lan-c-title mb-0">The smarter way to order and manage your dental supplies</p>
        </div>
      </div>
      <div class="lower pt-4 px-3 px-lg-0">
        <div class="l-wd-max mx-auto">
          <p class="mb-5 l-desc sub-t">With Ordo, you now have a one-stop-shop where you can order and compare real-time pricing from all your vendors, manage your orders, and track your budget.</p>
          <div class="d-flex flex-column flex-lg-row low-wd-max">
            <b-btn class="custom-btn l-btn" @click="$router.push({name: 'OnBoarding'})">Get Started</b-btn>
            <b-btn class="custom-btn success__outline ml-lg-3 mt-3 mt-lg-0 l-btn out-b d-flex align-items-center justify-content-center" @click="$router.replace({name: 'Landing', hash: '#features'})">Learn More</b-btn>
            <!-- <p class="d-flex mb-0 align-items-center f-18-px ml-lg-5 mt-3 mt-lg-0">
              <span class="l-desc gilroy-sb">Learn More</span>
              <b-icon icon="chevron-down" class="ml-3"></b-icon>
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingHeader from '@/components/landing/header/LandingHeader'
export default {
  components: { LandingHeader, },
  data: () => ({
    playVideo: false,
    publicPath: process.env.BASE_URL,
  }),
  mounted(){
    window.onresize = () => {
      if(window.innerWidth < 1000){
        if(this.playVideo) this.toggleVideoPlay()
      }
    }
  },
  methods: {
    toggleVideoPlay(){
      this.playVideo = !this.playVideo
      if(this.playVideo) this.$refs.video.play()
      else {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 0
      }
    },
    onOutsideVideoClick(e){
      if(e.target !== this.$refs.playPauseBtn && window.innerWidth > 1000 && this.playVideo) this.toggleVideoPlay()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/landing'
.landing-cover
  .upper
    position: relative
    overflow-x: hidden
    .h-img
      position: absolute
      z-index: 1
      min-width: 1440px
      width: 100vw
      top: 0
      height: 100%
    .s1
      p
        position: relative
        z-index: 2
    @media screen and (max-width: 992px)
      // display: none
      .h-img
        height: 100%
  .all
    position: relative
    z-index: 2
    overflow-x: hidden
    .cov-video
      position: fixed
      z-index: 5
      left: 0
      top: 0
      backdrop-filter: blur(4px)
      background-color: rgba(0,0,0,0.3)
      width: 100vw
      height: 100vh
      display: flex
      align-items: center
      justify-content: center
      video
        max-width: 800px
        max-height: 500px
        box-shadow: 0px 33.1868px 82.5983px rgba(0, 0, 0, 0.06), 0px 16.8008px 36.0077px rgba(0, 0, 0, 0.0405), 0px 6.63736px 13.4222px rgba(0, 0, 0, 0.03), 0px 1.45192px 4.77521px rgba(0, 0, 0, 0.0195)
        border-radius: 5px
    .cov-image
      width: 800px
      height: 500px
      position: absolute
      top: 150px
      right: -165px
      left: calc(50% + 610px - 530px)
      z-index: 3
      .cov-overlay
        height: 491px
        width: 791px
        position: absolute
        border-radius: 10px
        top: 0
        left: 4.5px
        background: rgba(0,0,0,0.3)
        z-index: 2
      img , video
        width: inherit
        height: inherit
      .play-pause-btn
        width: 100px
        height: 100px
        position: absolute
        left: calc( 50% - 10px )
        top: calc( 50% - 50px )
        z-index: 3
      @media screen and (max-width: 992px)
        display: none
    .upper
      // background-image: url('../../assets/images/landing/header_landing.png')
      // background-size: 100% 100%
      // .lan-c-title
      //   font-family: GILROY-BOLD
      //   max-width: 546px
      //   font-size: 64px
      //   font-weight: 800
      //   font-stretch: normal
      //   font-style: normal
      //   line-height: 1.1
      //   letter-spacing: -0.64px
      //   color: #fff
      //   text-shadow: 3px 6px 14px rgba(0, 0, 0, 0.15)
        // @media screen and (max-width: 992px)
          // font-size: 32px
          // color: #264458
      .s1
        padding-top: 80px
        position: relative
        @media screen and (max-width: 992px)
          padding-top: calc( 74px * 2 )
    .lower
      .sub-t
        font-size: 20px
        line-height: 1.7
        font-family: GILROY-MEDIUM
        max-width: 500px
        color: #788288
      .low-wd-max
        max-width: 502px
        .l-btn
          width: 100%
          max-width: 251px
          min-height: 55px
          font-size: 18px
          &.out-b
            border-width: 2px
</style>