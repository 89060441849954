<template>
  <b-dropdown right class="menu-drp not-blur" menu-class="bud-cat-sel" offset="44">
    <template #button-content>
      <b-btn class="custom-btn danger__2__outline d-flex align-items-center justify-content-between" style="width: 147px;">
        <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;" v-if="val == 'dental'">Dental Supply Budget</span>
        <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;" v-else-if="val == 'office'">Front Office Supply Budget</span>
        <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;" v-else-if="val == 'miscellaneous'">Uncategorized</span>
        <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;" v-else>Budget Type</span>
        <!-- <span :class="['text-danger__2']">{{itemReason(row.item, 'budget_category')}}</span> -->
        <img src="@/assets/images/svg_icon/down_caret_pink.svg" alt="" class="ml-2">
      </b-btn>
    </template>
    <div class="cm-body">
      <div v-for="(budCat, rI) in  budgetCategories" :key="rI">
        <div class="dash" v-if="rI > 0"></div>
        <div class="my-3 cursor-pointer d-flex align-items-center" @click="val = budCat.value">
          <div class="ven-ck mr-2 px-2-px">
            <img src="@/assets/images/pink_check_mark.png" alt="" style="vertical-align: unset; max-width: 100%; max-height: 100%" v-if="val == budCat.value">
          </div>
          <p class="d-desc text-danger__2 mb-0">{{ budCat.text }}</p>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import OfficeMixin from '@/mixins/OfficeMixin'
export default {
  props: ['value'],
  emits: ['input'],
  mixins: [ OfficeMixin ],
  computed: {
    budgetCategories() {
      if(this.$store.state.OFFICE.officeBudget[this.officeId]){
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if(subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if(subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if(subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        })
      }
      return []
    },
    val: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.ven-ck
  width: 20px
  min-width: 20px
  height: 20px
  border-radius: 4px
  border: solid 1px #ededed
  display: flex
  align-items: center
  justify-content: center
</style>