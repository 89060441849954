<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Customize Your Inventory for Maximum Efficiency</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Make Your Inventory Work for You</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2 mb-5">Are you tired of searching through endless lists of generic product names and images? Ordo solves this by allowing you to create nicknames for items you've previously ordered. Now you can quickly locate your favorite bur without having to look through fifty pages of burs in a catalog.</p>
        <p class="subtitle-3 mb-4">In addition to creating nicknames, you can upload custom photos of your supplies. By doing this, you'll always have an accurate visual representation of what you're ordering, instead of relying on generic product images. This can be especially helpful when ordering items with subtle variations in shape or size.</p>
        <p class="subtitle-3 mb-4">Ordo's customizable inventory feature also allows you to organize your inventory in a way that makes sense for your business. You can create custom categories and subcategories, making it easier to find what you need, when you need it. Whether you want to group items by department, vendor, or any other criteria, you can do so with ease.</p>
        <p class="subtitle-3 mb-0">By customizing your inventory in Ordo, you can streamline your ordering process, save time, and reduce errors. Plus, with everything organized in a way that makes sense to you, you'll always have a clear understanding of what you have on hand and what you need to order. Say goodbye to generic inventory lists and hello to a more efficient way of ordering. Try Ordo today!</p>
        <p class="text-center mb-5">
          <img src="./features_images/Customize_Products/customize_products_1.webp" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/two.svg)
</style>