<template>
  <div class="create-an-order">
    <search-head-cover class="mb-4" title="Quickly order your favorite products" :btn1="true" desc="You're going to love the way our dental supplies are organized. All of your favorite items will be right at hand, so you can quickly find what you want and get busy ordering!">
      <template #btns>
        <div class="d-none d-lg-flex" style="max-width: 375px">
          <b-btn class="custom-btn danger__2 w-50">Tutorial</b-btn>
          <b-btn class="custom-btn danger__2 ml-3 w-50">Add a vendor</b-btn>
        </div>
      </template>
    </search-head-cover>
    <div class="">
      <div class="" v-if="showSearch">
        <shopping-search :text="searchText" />
      </div>
      <div v-else>
        <inventory-list :head="false" :paadx="false" />
      </div>
    </div>
  </div>
</template>   

<script>
import ShoppingSearch from '@/components/shopping/ShoppingSearch'
import InventoryList from '@/views/app/InventoryList'
import SearchHeadCover from '@/components/SearchHeadCover'

export default {
  components: {
    ShoppingSearch,
    InventoryList,
    SearchHeadCover,
  },
  data: () => ({
    searchText: '',
    showSearch: false,
  }),
  beforeDestroy(){
    this.$BUS.$off('search')
  },
  mounted(){
    this.$BUS.$on('search', (payload) => {
      this.showSearch = true
      this.searchText = payload.q
    })
    if(this.$route.query.q){
      this.searchText = this.$route.query.q
      this.$BUS.$emit('search', {
        q: this.$route.query.q
      })
    }
  },
  methods: {
    changePagination(){
      console.log('pagination changed')
    },
    setSearch(v){
      this.searchText = v
    },
  },
}
</script>

<style lang="sass" scoped>

::v-deep .products-carousel
  .owl-item
    width: max-content !important
    min-width: 276px
    .product-card
      width: 256px
</style>