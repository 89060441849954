<template >
  <div class="d-lg-flex align-items-center">
    <div class="d-lg-flex justify-content-between w-100 ">
      <div class="bx__2" :class="[futureCast && 'future-cast']">
        <div class="select-wrapper h-100">
          <b-select class="custom-input custom-select" v-model="filterBy.date_range" :options="months"
            @change="onDateRangeChange" style="padding-right: 0;">
            <template #first>
              <option :value="null" disabled>Select Preset Range</option>
            </template>
          </b-select>
        </div>
      </div>
      <div class="bx__3 d-flex align-items-center" :class="[futureCast && 'future-cast']" v-if="!futureCast">
        <div class="w-100 h-100 d-flex align-items-center cursor-pointer" @click.prevent="openDateRangePicker">
          <p class="mb-0 d-desc ml-0-px text-line-1" style="line-height: 1">
            <span v-if="filterBy.selectedDate.startDate && filterBy.selectedDate.endDate">{{
              formatDateTime(filterBy.selectedDate.startDate) }} - {{ formatDateTime(filterBy.selectedDate.endDate)
  }}</span>
            <span v-else>Select Date Range</span>
          </p>
        </div>
        <div class="select-wrapper h-100">
          <date-range-picker class="custom-date-picker" ref="DateRangePicker" minDate="2019-12" :maxDate="maxDate"
            :timePicker="false" v-model="filterBy.selectedDate" @update="onDatePickerChange">
            <!-- <template v-slot:input style="min-width: 350px;">
              <span class="gilroy-b">Sort by Date</span>
            </template> -->
            <template v-slot:input>
              <span class="gilroy-b" >
                Sort by Date
              </span>
            </template>
          </date-range-picker>
        </div>
      </div>

      <div style="margin-left: 10px; margin-right: 10px;" class="bx__3 d-flex align-items-center"
        :class="[futureCast && 'future-cast']" v-if="futureCast">
        <div class="w-100 h-100 d-flex align-items-center cursor-pointer" @click.prevent="openDateRangePicker">
          <p class="mb-0 d-desc ml-0-px text-line-1" style="line-height: 1">
            <span v-if="filterBy.selectedDate.startDate && filterBy.selectedDate.endDate">{{
              formatDateTime(filterBy.selectedDate.startDate) }} - {{ formatDateTime(filterBy.selectedDate.endDate)
  }}</span>
            <span v-else>Select Date Range</span>
          </p>
        </div>
        <div class="select-wrapper h-100">
          <date-range-picker class="custom-date-picker" ref="DateRangePicker" :minDate="minDateFuture"
            :maxDate="maxDateFuture" :timePicker="false" v-model="filterBy.selectedDate" @update="onDatePickerChange">
            <template v-slot:input>
              <span class="gilroy-b">
                Sort by Date
              </span>
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="bx__4" :class="[futureCast && 'future-cast px-0']">
        <div class="select-wrapper h-100 d-flex align-items-center" v-if="futureCast">
          <b-input class="custom-input" placeholder="Search by Procedure Code or Name" v-model="filterBy.search"
            @keyup.enter="emitFilter"></b-input>
          <img src="@/assets/images/magnifying-glass-tilted.png" alt="" style="height: 20px; width: 20px"
            class="mx-2 cursor-pointer" @click="emitFilter">
        </div>
        <div class="select-wrapper h-100" v-else>
          <b-select class="custom-input custom-select" v-model="filterBy.budget_type" :options="budgetTypes"
            v-if="!this.$store.state.OFFICE.fetchingBudget"></b-select>
        </div>
      </div>
      <div class="bx__5 d-flex justify-content-center py-0">
        <b-btn class="custom-btn" @click="clear">Reset Filters</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import OfficeMixin from '@/mixins/OfficeMixin'
export default {
  components: { DateRangePicker, },
  mixins: [OfficeMixin],
  props: {
    futureCast: Boolean,
  },
  data: () => ({
    filterBy: {
      office: null,
      date_range: 'thisMonth',
      budget_type: null,
      selectedDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
      },
      search: null,
    },
  }),
  created() {
    this.filterBy.office = this.officeId
    if (this.futureCast) {
      this.filterBy.date_range = 'nextWeek'
    }
  },
  computed: {
    months() {
      if (this.futureCast) {
        return [
          { text: 'Current Week', value: 'thisWeek' },
          { text: 'Next Week (next Monday to Sunday)', value: 'nextWeek' },
          { text: 'Next 2 Weeks (includes next week and the following week)', value: 'next2Weeks' },
          { text: 'Next 3 Weeks (includes next week and the following 2 weeks)', value: 'next3Weeks' },
          { text: 'Next 4 Weeks (includes next week and the following 3 weeks)', value: 'next4Weeks' },
        ]
      }
      return [
        { text: 'Current Month', value: 'thisMonth' },
        { text: 'Current Year', value: 'thisYear' },
        { text: 'Current Quarter', value: 'thisQuarter' },
        { text: 'Previous Quarter', value: 'lastQuarter' },
        { text: 'Previous Month', value: 'lastMonth' },
        { text: 'Previous 2 Months', value: 'last2Months' },
        { text: 'Previous 3 Months', value: 'last3Months' },
        { text: 'Previous 12 Months', value: 'last12Months' },
        { text: 'Last Year', value: 'lastYear' },
        { text: 'Custom Date Range', value: 'customDate' },
      ]
    },
    budgetTypes() {
      if (this.$store.state.OFFICE.officeBudget[this.officeId] != null) {
        return [{ value: null, text: 'All Budgets' }].concat(this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if (subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if (subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if (subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        }))
      }
      return []
    },
    filteredOfcName() {
      if (this.filterBy.office) return (this.offices.find(o => o.id == this.filterBy.office)).name
      return '-'
    },
    maxDate() {
      return moment().endOf('month').format('YYYY-MM-DD')
    },
    minDateFuture() {
      return moment().format('YYYY-MM-DD')
    },
    maxDateFuture() {
      return moment().endOf('year').format('YYYY-MM-DD')
    },
  },
  methods: {
    ...mapMutations('FILTER', ['SET_FILTERS']),
    onDatePickerChange() {
      // if (this.futureCast) return
      if (this.filterBy.selectedDate != null && this.filterBy.selectedDate.startDate != null && this.filterBy.selectedDate.endDate != null) {
        let st = moment(this.filterBy.selectedDate.startDate).format('DDMMYYYY')
        let en = moment(this.filterBy.selectedDate.endDate).format('DDMMYYYY')
        let thisMonthSt = moment().startOf('month').format('DDMMYYYY')
        let thisMonthEn = moment().endOf('month').format('DDMMYYYY')
        let thisYearSt = moment().startOf('year').format('DDMMYYYY')
        let thisYearEn = moment().endOf('year').format('DDMMYYYY')
        let thisQuarterSt = moment().startOf('quarter').format('DDMMYYYY')
        let thisQuarterEn = moment().endOf('quarter').format('DDMMYYYY')
        let lastQuarterSt = moment().startOf('quarter').subtract(1, 'quarter').format('DDMMYYYY')
        let lastQuarterEn = moment().endOf('quarter').subtract(1, 'quarter').format('DDMMYYYY')
        let lastMonthSt = moment().subtract(1, 'month').startOf('month').format('DDMMYYYY')
        let lastMonthEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last2MonthsSt = moment().subtract(2, 'month').startOf('month').format('DDMMYYYY')
        let last2MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last3MonthsSt = moment().subtract(3, 'month').startOf('month').format('DDMMYYYY')
        let last3MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last12MonthsSt = moment().subtract(12, 'month').startOf('month').format('DDMMYYYY')
        let last12MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let lastYearSt = moment().subtract(1, 'year').startOf('year').format('DDMMYYYY')
        let lastYearEn = moment().subtract(1, 'year').endOf('year').format('DDMMYYYY')
        let thisWeekSt = moment().startOf('week').format('DDMMYYYY')
        let thisWeekEn = moment().endOf('week').format('DDMMYYYY')
        let nextWeekSt = moment().add(1, 'week').startOf('week').format('DDMMYYYY')
        let nextWeekEn = moment().add(1, 'week').endOf('week').format('DDMMYYYY')
        let nextMonthSt = moment().add(1, 'month').startOf('week').format('DDMMYYYY')
        let nextMonthEn = moment().add(1, 'month').endOf('week').format('DDMMYYYY')
        if (st == thisMonthSt && en == thisMonthEn)
          this.filterBy.date_range = 'thisMonth'
        else if (st == thisYearSt && en == thisYearEn)
          this.filterBy.date_range = 'thisYear'
        else if (st == thisQuarterSt && en == thisQuarterEn)
          this.filterBy.date_range = 'thisQuarter'
        else if (st == lastQuarterSt && en == lastQuarterEn)
          this.filterBy.date_range = 'lastQuarter'
        else if (st == lastMonthSt && en == lastMonthEn)
          this.filterBy.date_range = 'lastMonth'
        else if (st == last2MonthsSt && en == last2MonthsEn)
          this.filterBy.date_range = 'last2Months'
        else if (st == last3MonthsSt && en == last3MonthsEn)
          this.filterBy.date_range = 'last3Months'
        else if (st == last12MonthsSt && en == last12MonthsEn)
          this.filterBy.date_range = 'last12Months'
        else if (st == lastYearSt && en == lastYearEn)
          this.filterBy.date_range = 'lastYear'
        else if (st == thisWeekSt && en == thisWeekEn)
          this.filterBy.date_range = 'thisWeek'
        else if (st == nextWeekSt && en == nextWeekEn)
          this.filterBy.date_range = 'nextWeek'
        else if (st == nextMonthSt && en == nextMonthEn)
          this.filterBy.date_range = 'nextMonth'
        else
          this.filterBy.date_range = 'customDate'
        if (this.filterBy.date_range == 'customDate') {
          let dateRange = 'customDate'
          en = moment(en, 'DDMMYYYY')
          st = moment(st,'DDMMYYYY')

          const totalDays = en.diff(st, 'days');

          // const timeDifference = Math.abs(en - st);
          // let totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); 
          // totalDays = moment(totalDays).format('DDMMYYYY')
          if (st < thisWeekSt) {
            if (totalDays <= 7) {
              dateRange = 'thisWeek';
            }
            else if (totalDays <= 14) {
              dateRange = 'next2Weeks';
            }
            else if (totalDays <= 21) {
              dateRange = 'next3Weeks';
            }
            else if (totalDays <= 28) {
              dateRange = 'next4Weeks';
            }
          } else {
            if (totalDays <= 7) {
              dateRange = 'nextWeek';
            }
            else if (totalDays <= 14) {
              dateRange = 'next2Weeks';
            }
            else if (totalDays <= 21) {
              dateRange = 'next3Weeks';
            }
            else if (totalDays <= 28) {
              dateRange = 'next4Weeks';
            }
          }
          this.filterBy.date_range = dateRange;
        }
        this.emitFilter()
      }
    },
    onDateRangeChange() {
      if (this.futureCast) return this.emitFilter()
      if (this.filterBy.date_range == 'thisMonth') {
        this.filterBy.selectedDate.startDate = moment().startOf('month')
        this.filterBy.selectedDate.endDate = moment().endOf('month')
      }
      else if (this.filterBy.date_range == 'thisYear') {
        this.filterBy.selectedDate.startDate = moment().startOf('year')
        this.filterBy.selectedDate.endDate = moment().endOf('year')
      }
      else if (this.filterBy.date_range == 'thisQuarter') {
        this.filterBy.selectedDate.startDate = moment().startOf('quarter')
        this.filterBy.selectedDate.endDate = moment().endOf('quarter')
      }
      else if (this.filterBy.date_range == 'lastQuarter') {
        this.filterBy.selectedDate.startDate = moment().startOf('quarter').subtract(1, 'quarter')
        this.filterBy.selectedDate.endDate = moment().endOf('quarter').subtract(1, 'quarter')
      }
      else if (this.filterBy.date_range == 'lastMonth') {
        this.filterBy.selectedDate.startDate = moment().subtract(1, 'month').startOf('month')
        this.filterBy.selectedDate.endDate = moment().subtract(1, 'month').endOf('month')
      }
      else if (this.filterBy.date_range == 'last2Months') {
        this.filterBy.selectedDate.startDate = moment().subtract(2, 'month').startOf('month')
        this.filterBy.selectedDate.endDate = moment().subtract(1, 'month').endOf('month')
      }
      else if (this.filterBy.date_range == 'last3Months') {
        this.filterBy.selectedDate.startDate = moment().subtract(3, 'month').startOf('month')
        this.filterBy.selectedDate.endDate = moment().subtract(1, 'month').endOf('month')
      }
      else if (this.filterBy.date_range == 'last12Months') {
        this.filterBy.selectedDate.startDate = moment().subtract(12, 'month').startOf('month')
        this.filterBy.selectedDate.endDate = moment().subtract(1, 'month').endOf('month')
      }
      else if (this.filterBy.date_range == 'lastYear') {
        this.filterBy.selectedDate.startDate = moment().subtract(1, 'year').startOf('year')
        this.filterBy.selectedDate.endDate = moment().subtract(1, 'year').endOf('year')
      }
      else if (this.filterBy.date_range == 'thisWeek') {
        this.filterBy.selectedDate.startDate = moment().startOf('week')
        this.filterBy.selectedDate.endDate = moment().endOf('week')
      }
      else if (this.filterBy.date_range == 'nextWeek') {
        this.filterBy.selectedDate.startDate = moment().add(1, 'week').startOf('week')
        this.filterBy.selectedDate.endDate = moment().add(1, 'week').endOf('week')
      }
      else if (this.filterBy.date_range == 'nextMonth') {
        this.filterBy.selectedDate.startDate = moment().add(1, 'month').startOf('month')
        this.filterBy.selectedDate.endDate = moment().add(1, 'month').endOf('month')
      }
      else if (this.filterBy.date_range == 'customDate') {
        setTimeout(() => {
          this.$refs.DateRangePicker.togglePicker()
        }, 100)
      }
      else {
        this.filterBy.selectedDate.startDate = null
        this.filterBy.selectedDate.endDate = null
      }
      this.emitFilter()
    },
    async emitFilter() {
      await this.$nextTick()
      if (this.futureCast == true) {
        let payload = {
          ...this.filterBy,
          futureCast: true,
        }
        this.$BUS.$emit('filter:filter-change-future-cast', payload)
      }
      else {
        this.$BUS.$emit('filter:filter-change', this.filterBy)
        this.SET_FILTERS(this.filterBy)
      }
    },
    openDateRangePicker() {
      setTimeout(() => {
        this.$refs.DateRangePicker.togglePicker()
      }, 100)
    },
    clear() {
      let prev = Object.assign({}, this.filterBy)
      this.filterBy = {
        office: null,
        date_range: this.futureCast ? 'nextWeek' : 'thisMonth',
        budget_type: null,
        selectedDate: {
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month')
        },
        search: null,
      }
      if (
        prev.date_range != 'thisMonth'
        || (
          prev.selectedDate.startDate != moment().startOf('month')
          || prev.selectedDate.endDate != moment().endOf('month')
        )
      ) {
        this.emitFilter()
      }
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d).format("MM/DD/YY");
    },
  },
}
</script>

<style lang="sass" scoped>

.select-wrapper
  width: 100%
  background-color: transparent
  .custom-select
    background: #fff url('../../assets/images/svg_icon/arrow.svg') right 0rem center/35px 20px no-repeat
    font-size: 12px !important
    font-family: GILROY-Medium
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1
    color: #788288
    text-overflow: ellipsis
    padding-right: 50px
    padding-left: 0px
    @media screen and (max-width: 992px)
      background: #fff url('../../assets/images/svg_icon/arrow.svg') right 0rem center/35px 20px no-repeat
.bx
  border-radius: 8px
  // border: solid 1px #ebeaeb
  border: solid 1px #FAFAFA
  background-color: #FAFAFA
  height: 38px
  padding: 0.3rem 0.5rem
  display: flex
  justify-content: space-between
  align-items: center
  box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  .custom-input
    background-color: transparent
    height: 100% !important
    border: none
  &__1
    @extend .bx
    width: calc((100% - 150px) / 3 - 10px)
    &.future-cast
      @media screen and (min-width: 992px)
        width: calc((100% - 150px) / 2 - 10px)
    .select-wrapper
      // width: 89px
      .custom-select
        font-size: 10px
        padding-right: 0.75rem
    @media screen and (max-width: 992px)
      margin-bottom: 10px
      width: 100%
  &__2
    @extend .bx
    width: calc((100% - 150px) / 3 - 10px)
    &.future-cast
      @media screen and (min-width: 992px)
        width: calc((100% - 150px) / 2 - 10px)
    .select-wrapper
      // width: 107px
      .custom-select
        font-size: 10px
        padding-right: 0.75rem
    @media screen and (max-width: 992px)
      margin-bottom: 10px
      width: 100%
  &__3
    @extend .bx
    width: calc((100% - 150px) / 3 - 10px)
    &.future-cast
      @media screen and (min-width: 992px)
        width: calc((100% - 150px) / 2 - 10px)
    ::v-deep .select-wrapper
      width: 100%
      max-width: 79px
      background-color: #13ADD9
      .reportrange-text
        font-size: 10px !important
        color: #ffffff !important
        font-family: GILROY-BOLD
    @media screen and (max-width: 992px)
      margin-bottom: 10px
      width: 100%
  &__4
    @extend .bx
    width: calc((100% - 150px) / 3 - 10px)
    &.future-cast
      @media screen and (min-width: 992px)
        width: calc((100% - 150px) / 2 - 10px)
    .select-wrapper
      // width: 90px
      .custom-select
        font-size: 10px
        padding-right: 0.75rem
    @media screen and (max-width: 992px)
      margin-bottom: 10px
      width: 100%
  &__5
    @extend .bx
    width: min-content
    background-color: unset
    border: none
    width: 150px
    .custom-btn
      min-height: 36px
      width: 100%
    @media screen and (max-width: 992px)
      padding: 0 !important
      width: 100%
      .custom-btn
        width: 100% !important
::v-deep .custom-date-picker
  min-width: 90%
  height: 100%
  .reportrange-text
    border: none !important
    height: 100%
    background: transparent
    font-family: GILROY-MEDIUM !important
    font-size: 12px !important
    font-weight: 500 !important
    font-stretch: normal !important
    font-style: normal !important
    line-height: normal !important
    color: #707070 !important
    display: flex
    align-items: center
    &:hover
      background-color: transparent !important
      border: none !important
.search-box
  display: flex
  align-items: center
  img
    width: 15px
    height: 15px
    margin-bottom: 2px
</style>