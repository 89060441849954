<template>
  <div class="notf w-100 px-2 py-3">
    <div class="px-md-5" v-if="unreadNotifications.length == 0">
      <no-notification class="mx-auto" />
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="@/assets/images/notification_center_alt.png" style="width: 30px; height: 30px;" alt="">
          <p class="d-title__4 mx-2 mb-0" style="font-family: Gilroy-Bold; font-size: 14px; padding-left: 10px;">Notifications</p>
        </div>
        <Button class="danger__2" spinner-width="0.7rem" spinner-height="0.7rem" text="Mark all as Read" :onclick="markAllAsRead" :submitting="markingAllAsRead" />
      </div>
      <div class="dash my-3"></div>
      <div v-for="(n, nI) in unreadNotifications" :key="n.id">
        <div class="dash my-3" v-if="nI > 0"></div>
        <notification-card-detail :n="n" />
      </div>
    </div>
  </div>
</template>

<script>
import NoNotification from '@/components/dashboard/NoNotification'
import NotificationCardDetail from '@/components/dashboard/NotificationCardDetail'
import Button from '@/components/ui/Button'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
export default {
  components: {
    NoNotification, Button, NotificationCardDetail,
  },
  mixins: [ OfficeMixin, ],
  data: () => ({
    fetchingNotification: true,
    markingAllAsRead: false,
  }),
  created(){
    this.fetchNotification()
    this.$BUS.$on('fetch-notification', this.fetchNotification)
  },
  beforeDestroy(){
    this.$BUS.$off('fetch-notification')
  },
  computed: {
    ...mapGetters('NOTIFICATION', ['unreadNotifications']),
  },
  methods: {
    ...mapActions('NOTIFICATION', ['GET_NOTIFICATION', 'MARK_ALL_NOTIFICATIONS_AS_READ']),
    ...mapMutations('NOTIFICATION', ['TOGGLE_ALL_NOTIFICATION_READ']),
    async fetchNotification(){
      this.fetchingNotification = true
      try{
        await this.GET_NOTIFICATION({office_id: this.off})
      }catch(e){
        console.log(e)
      }
      this.fetchingNotification = false
    },
    async markAllAsRead(){
      // this.TOGGLE_ALL_NOTIFICATION_READ()
      this.markingAllAsRead = true
      try{
        await this.MARK_ALL_NOTIFICATIONS_AS_READ()
      }catch(e){
        console.log(e)
      }
      this.markingAllAsRead = false
    },
    notfDetails(n){
      window.open(n.notification.metadata.link, '_blank')
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../style/ui/dropdown'
.mark-btn
  font-size: 8px !important
  min-height: 30px !important
</style>