<template>
  <div class="bar-chart">
    <div class="d-flex px-3 justify-content-between mb-0">
      <p class="d-title mb-0">
        <span v-if="subaccount.slug == 'dental'">Over Budget</span>
        <span v-else-if="subaccount.slug == 'office'">Under Budget</span>
        <span v-else-if="subaccount.slug == 'miscellaneous'">Under Budget Percentage</span>
        <span v-else>{{ modifiedName }} Budget</span>
      </p>
    </div>
    <grow-loader v-if="$store.state.OFFICE.fetchingBudget || fetchingFilterData" />
    <div v-else-if="false" class="d-flex flex-column h-100 align-items-center justify-content-center py-3 px-2">
      <img src="@/assets/images/money-bag.png" alt="" style="width: 40px; height: 40px" class="mb-4">
      <p class="d-title text-center mb-3">
        No&nbsp;
        <span v-if="subaccount.slug == 'dental'">Dental Supply Budget</span>
        <span v-else-if="subaccount.slug == 'office'">Front Office Supply Budget</span>
        <span v-else-if="subaccount.slug == 'miscellaneous'">Uncategorized Budget</span>
        <span v-else>{{ subaccount.name }} Budget</span>
        &nbsp;Set
      </p>
      <p class="d-desc text-center mb-4" style="max-width: 300px;">Unfortunately you weren’t able to setup your dental
        supply budget during onboarding. Click the button below to add a dental supply budget</p>
      <Button class="w-75" text="Add Budget" :onclick="() => goTo({ name: 'Settings', hash: '#office_budget' })" />
    </div>
    <div v-else>
      <div class="chart-wrapper max-w-250-px mx-auto">
        <apexchart :options="chartOptions" :series="series"></apexchart>
        <div class="chart-inn-label">
          <p :class="['mb-0 rem-amount', totalBudget <= 0 ? 'text-primary' : overBudget ? 'over' : 'text-success']"
            style="font-size: 1em">
            <!-- <span v-if="currentSpendPercentage == 0">${{ numberWithCommas((totalSpend || 0).toFixed(2)) }}</span>
            <span v-else>{{ currentSpendPercentage }}{{ overBudget ? '' : '%' }}</span> -->
            <span >87%</span>
          </p>
          <p class="mb-0 rem-txt">
            <!-- {{ overBudget ? 'Over Budget' : 'Spent' }} -->
            <!-- spent -->
            Over Budget
          </p>
        </div>
      </div>
      <!-- <b-row class="mx-0 px-3 gap-y-10-px">
        <b-col cols="12" md="6" class="px-0">
          <p class="mb-0 d-desc">Total Offices</p>
          <div class="d-flex align-items-center">
            <div class="color__2 mr-2"></div>
            <p class="mb-0 d-title" v-if="Number(totalBudget || 0) > 0">${{ numberWithCommas(Number(totalBudget ||
              0).toFixed(2)) }}</p>
            <p class="mb-0 d-title" v-else>30</p>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="px-0">
          <p class="mb-0 d-desc">Over Budget Offices</p>
          <div class="d-flex align-items-center">
            <p class="mb-0 d-title">
              <span v-if="targetSpendPercentage > 0">{{ targetSpendPercentage }}%</span>
              <span v-else>87%</span>
            </p>
          </div>
        </b-col>
      </b-row> -->
    </div>
  </div>
</template>
  
<script>
import VueApexCharts from 'vue-apexcharts'
import GrowLoader from '@/components/loaders/GrowLoader'
import StatisticsMixin from '@/mixins/Statistics'
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  props: {
    subaccount: Object,
    fetchingFilterData: Boolean,
    calculateFullStats: Boolean,
  },
  components: {
    apexchart: VueApexCharts,
    GrowLoader,
    Button,
  },
  mixins: [StatisticsMixin, GoTo,],
  data: () => ({
    filterBy: {
      dt: 'month'
    },
    dtOptions: [
      { text: 'Month', value: 'month' },
    ],
  }),
  created() { },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'donut',
          height: 300,
          width: '100%'
        },
        labels: ['Total Expense', 'Total Budget',],
        stroke: {
          width: 0
        },
        fill: {
          colors: [
            this.totalBudget <= 0 ? '#13ADD9' :
              this.overBudget ? '#FF38C9' : '#29BF12',
            this.totalBudget <= 0 ? '#13ADD9' : '#C4E2C0',
          ]
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            startAngle: 360,
            endAngle: 0,
            size: 200,
            donut: {
              size: '70%',
              labels: {
                show: false,
              },
            }
          }
        },
      }
    },
    series() {
      if (this.overBudget) {
        return [100, 0]
      }
      return [this.getWidthPercent, (100 - this.getWidthPercent),]
    },
    getWidthPercent() {
      if (this.totalBudget == 0) return 0
      return (parseFloat(this.totalSpend) * 100) / parseFloat(this.totalBudget)
    },
    remaininigAmount() {
      return (this.totalBudget - this.totalSpend).toFixed(2)
    },
    overBudget() {
      return this.subaccount.amount < this.subaccount.spend
    },
    totalBudget() {
      return this.subaccount.amount
    },
    totalSpend() {
      return this.subaccount.spend;
    },
    currentSpendPercentage() {
      if (this.totalBudget == 0) return '0'
      let percent = ((this.totalSpend / this.totalBudget) * 100).toFixed(0)
      if
        (percent > 100) {
        return '$' + (this.totalSpend - this.totalBudget).toFixed(2);
      }
      else {
        return percent;
      }
    },
    targetSpendPercentage() {
      return (this.totalBudget * 100 / this.subaccount.budget_amount).toFixed(1);
    },

    modifiedName() {
      const name = this.subaccount.name.toLowerCase();
      if (name.endsWith('budget')) {
        return this.subaccount.name.slice(0, -7);
      } else {
        return this.subaccount.name;
      }
    },
  },
}
</script>
  
<style lang="sass" scoped>
  
  $cl1: #29BF12
  $cl2: #C4E2C0
  .chart-wrapper
    position: relative
    .chart-inn-label
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      flex-flow: column
      justify-content: center
      .rem-amount
        font-size: 20px
        font-family: GILROY-BOLD
        font-weight: 600
        color: #264458
        text-align: center
        &.over
          color: #FF38C9
      .rem-txt
        font-size: 12px
        color: #264458
        text-align: center
  .color
    width: 18px
    height: 18px
    border-radius: 4px
    background-color: $cl1
    &.over
      background-color: #FF38C9
    &__2
      @extend .color
      background-color: $cl2
  .text-gr
    color: $cl1
  .limit
    width: 100%
    height: 5px
    border-radius: 5px
    border: solid 1px #edf4f7
    position: relative
    background-color: #edf4f7
    max-width: 91px
    .spent
      height: inherit
      background-color: $cl1
      border-radius: 5px
      min-width: 10px
      &__2
        @extend .spent
        background-color: $cl2
  ::v-deep .apexcharts-datalabels-group
    transform: translate(0, 20px)
  </style>