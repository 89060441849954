<template>
  <div class="settings-page">
    <div class="d-flex flex-column flex-lg-row page-contents">
      <div class="menu-sec mb-3 mb-lg-0">
        <div class="menus-wrp">
          <div class="menus mb-4">
            <div :class="['menu cursor-pointer pl-2', {'active': menu.value == selectedMenu}]" v-for="(menu, mI) in menus" :key="mI" @click="selectedMenu = menu.value">
              <p class="mb-0 menu-text">{{menu.text}}</p>
            </div>
          </div>
          <div class="menus">
            <div :class="['menu cursor-pointer pl-2', {'active': menu.value == selectedMenu}]" v-for="(menu, mI) in menus2" :key="mI" @click="selectedMenu = menu.value">
              <p class="mb-0 menu-text">{{menu.text}}</p>
            
            </div>
          </div>
        </div>
      </div>
      <div class="oth-con">
        <office v-if="selectedMenu == 'Ofcs'" />
        <office-settings v-if="selectedMenu == 'Ofc'" />
        <manage-team v-if="selectedMenu == 'MTm'" />
        <terms v-if="selectedMenu == 'terms'" />
        <privacy v-if="selectedMenu == 'privacy'" />
        <OfficeBudgetSettings v-if="selectedMenu == 'office_budget'" />
      </div>
    </div>
    <DashboardFooter class="mt-3" />
  </div>
</template>

<script>
import DHead from '@/components/dashboard/DHead'
import DSearch from '@/components/dashboard/DSearch'
import Office from '@/components/dashboard/Office'
import OfficeSettings from '@/components/dashboard/settings/OfficeSettings'
import OfficeBudgetSettings from '@/components/dashboard/settings/OfficeBudgetSettings'
import ManageTeam from '@/components/dashboard/settings/ManageTeam'
import Terms from '@/components/dashboard/settings/Terms'
import Privacy from '@/components/dashboard/settings/Privacy'
import DashHead from '@/components/dashboard/DashHead'
import { mapGetters } from 'vuex'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
export default {
  components: {
    DHead,
    DSearch,
    ManageTeam,
    OfficeSettings,
    Office,
    Terms,
    Privacy,
    DashboardFooter,
    OfficeBudgetSettings,
  },
  data: () => ({
    menus2: [
      { text: 'Terms of Use', value: 'terms' },
      { text: 'Privacy Policy', value: 'privacy' },
    ],
    selectedMenu: 'Ofc',
    usrs: [ 'Usr', 'Ntfc', 'Lnk' ]
  }),
  created(){
    this.checkHash()
  },
  watch: {
    $route(){
      this.checkHash()
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    menus(){
      let mns = [
      { text: 'Offices', value: 'Ofcs' },
        { text: 'Office Settings', value: 'Ofc' },
        { text: 'Office Budget Settings', value: 'office_budget' },
        { text: 'Manage Team', value: 'MTm' },
      ]
      return this.role == 1 && 3 ? mns : mns.filter(m => this.usrs.includes(m.value))
    },
  },
  methods: {
    checkHash(){
      if(this.$route.hash) {
        if((this.role == 1) || (this.role == 2 && this.Ofc.includes(this.$route.hash.substring(1)))) this.selectedMenu = this.$route.hash.substr(1)
      }
      else this.selectedMenu = 'Ofcs'
    },
  },
}
</script>

<style lang="sass" scoped>
.settings-page
  .page-contents
    min-height: calc( 100vh - 62px - 66px - 4rem )
  .menu-sec
    position: relative
    @media screen and (min-width: 992px)
      width: 276px
    @media screen and (max-width: 992px)
      max-width: 100%
    .menus-wrp
      @media screen and (min-width: 992px)
        position: sticky
        top: 80px
      .menus
        border-radius: 12px
        box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
        background-color: #ffffff
        .menu
          height: 55px
          display: flex
          align-items: center
          border-top: solid 1px #E7ECEE
          border-left: solid 9px transparent
          &:first-child
            border-top: none
            &.active
              border-top-left-radius: 12px
          &:last-child
            &.active
              border-bottom-left-radius: 12px
          .menu-text
            font-size: 12px
            font-family: Gilroy-SEMIBOLD
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: 2
            color: #1c455a
          &.active
            border-left: solid 9px #13add9
            .menu-text
              color: #13add9
  .oth-con
    width: calc( 100% - 276px - 20px )
    margin-left: auto
    @media screen and (max-width: 992px)
      width: 100%
</style>