<template>
  <div class="orders-page">
    <admin-header class="mb-3" title="Track It, Receive It, Complete It" redirect="CreateAnOrder" :searchField="true"
      desc="Here you can manage all of your orders. Click on an order below to track it from start to finish. You can even process a return, and easily re-order your items.">
      <template #btns>
        <div class="d-none d-lg-flex" style="max-width: 375px">
          <b-btn class="custom-btn danger__2 w-50">Tutorial</b-btn>
          <b-btn class="custom-btn danger__2 ml-3 w-50">Check promotions</b-btn>
        </div>
      </template>
    </admin-header>
    <filter-com />
    <div class="mt-3 mb-4">
      <order-activity />
    </div>
    <order-sync class="mt-4" v-if="showSpin" title="Syncing Order Data"
      desc="Now that you’ve linked your vendors we are pulling all your products ordered and creating your inventory list." />
    <order-no-data v-else-if="showEmpty" class="mt-4 d-card py-5" />
    <div v-else class="all-orders">
      <all-orders :hover-color-change="false" class="oders-table-card d-card px-3 py-3" :approvalModalDiv="true"
        @show-empty="onShowEmpty" />
    </div>
    <DashboardFooter class="mt-3" />
  </div>
</template>
  
<script>
import AllOrders from '@/components/dashboard/orders/AllOrders'
import DHead from '@/components/dashboard/DHead'
import DashHead from '@/components/dashboard/DashHead'
import AdminHeader from '@/views/admin_dashboard/components/admin_header.vue'
import OrderActivity from '@/components/dashboard/OrderActivity'
import TopSuppliersBySpendOrders from '@/components/dashboard/TopSuppliersBySpendOrders'
import OfficeMixin from '@/mixins/OfficeMixin'
import moment from 'moment'
import FilterCom from '@/components/dashboard/FilterCom'
import OrderNoData from '@/components/dashboard/OrderNoData'
import OrderSync from '@/components/dashboard/vendor-fetching/OrderSync'
import { mapActions } from 'vuex'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
export default {
  components: {
    AllOrders,
    DHead,
    DashHead,
    AdminHeader,
    OrderActivity,
    TopSuppliersBySpendOrders,
    FilterCom,
    OrderNoData,
    OrderSync,
    DashboardFooter,
  },
  mixins: [OfficeMixin],
  data: () => ({
    showEmpty: false,
    timer: null,
    showSpin: false,
  }),
  created() {
    this.fetchOrders()
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer)
  },
  computed: {
    currentMonth() {
      return moment().format('MMMM')
    },
  },
  methods: {
    ...mapActions('ORDER', ['FETCH_VENDOR_ORDERS']),

    async fetchOrders() {
      let payload = {
        office: this.officeId,
      }
      let res = await this.FETCH_VENDOR_ORDERS(payload)
      if (res.total == 0) {
        this.showSpin = true
        this.timer = setTimeout(() => {
          this.fetchOrders()
        }, 30 * 1000)
      } else {
        if (this.timer) clearTimeout(this.timer)
      }
    },
    onShowEmpty() {
      this.showEmpty = true
    },
  },
}
</script>
  
<style lang="sass" scoped>
  .all-orders
    .oders-table-card
      @media (min-width: 992px)
        min-height: 522px
  </style>