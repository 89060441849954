<template>
  <div>
    <validation-observer ref="ofcDetailObserver" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(next)">
      <div :class="['office-details', { 'w-100 no-shadow': single }]">
        <p class="c-title" v-if="!settingsPage">Office Details</p>
        <div :class="[{ 'px-2': settingsPage }]">
          <validation-provider name="Office Name" rules="required" v-slot="validationContext" v-if="willShow('detail')">
            <p class="form-label mb-1">Office Name</p>
            <b-input v-model="formData.name" class="custom-input" placeholder="Enter Office Name" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <!-- <div class="d-flex align-items-center mt-3" v-if="showPrevAddressOption">
            <b-checkbox class="custom-chk" v-model="setPrevAddress" @change="onSetPrevAddress"></b-checkbox>
            <span class="form-label" style="color: #a9aaaa;">Apply same address of previous office</span>
          </div> -->
          <validation-provider name="address" rules="required" v-slot="validationContext"
            v-if="willShow('address') && !setPrevAddress">
            <p class="form-label mb-1 mt-3">Address</p>
            <b-input v-model="formData.addresses[0].address" class="custom-input" placeholder="Enter address"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <b-row v-if="willShow('address') && !setPrevAddress">
            <b-col cols="4">
              <validation-provider name="state" rules="required" v-slot="validationContext"
                :custom-messages="{ 'required': 'Required' }">
                <p class="form-label mb-1 mt-3">State</p>
                <b-select v-model="formData.addresses[0].state" :options="states" class="custom-input" placeholder="State"
                  :state="getValidationState(validationContext)" @change="handleStateChange" >
                  <template #first>
                    <b-form-select-option :value="null">Select state</b-form-select-option>
                  </template></b-select>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI"
                  :style="{ display: validationContext.invalid ? 'block' : 'none' }">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider name="city" rules="required" v-slot="validationContext"
                :custom-messages="{ 'required': 'Required' }">
                <p class="form-label mb-1 mt-3">City</p>
                <b-select v-model="formData.addresses[0].city" class="custom-input" placeholder="Enter City"
                  :state="getValidationState(validationContext)">
                  <template #first>
                    <b-form-select-option :value="null">Select city</b-form-select-option>
                  </template>
                  <template v-if="formData.addresses[0].state === null">
                    <!-- Display a message or placeholder option when state is not selected -->
                    <b-form-select-option :value="null">Select a state first</b-form-select-option>
                  </template>
                  <template v-else>
                    <template v-for="(stateData, state) in cities">
                      <template v-if="getStateCode(state) == formData.addresses[0].state">
                        <b-form-select-option :value="city" v-for="city in stateData.cities" :key="city">
                          {{ city }}
                        </b-form-select-option>
                      </template>
                    </template>
                  </template>
                </b-select>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI"
                  :style="{ display: validationContext.invalid ? 'block' : 'none' }">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider name="zip" rules="required|min:5" v-slot="validationContext"
                :custom-messages="{ 'required': 'Zip Code field must be at least 5 characters', 'min': 'Zip Code field must be at least 5 characters' }">
                <p class="form-label mb-1 mt-3">Zip Code</p>
                <b-input v-model="formData.addresses[0].zip_code" class="custom-input" placeholder="Enter Zip"
                  v-cardformat:restrictNumeric :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <validation-provider name="phone_number" rules="required" v-slot="validationContext" v-if="willShow(null)"
            :custom-messages="{ 'required': 'Required' }">
            <p class="form-label mb-1 mt-3">Phone</p>
            <b-input v-model="formData.phone_number" class="custom-input" placeholder="Enter Phone Number" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <validation-provider name="practice_software" rules="required" v-slot="validationContext" v-if="willShow(null)">
            <p class="form-label mb-1 mt-3">Practice Software</p>
            <b-select :options="practiceSoftwares" v-model="formData.practice_software" class="custom-input"
              placeholder="Practice Software" :state="getValidationState(validationContext)"></b-select>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
          <validation-provider name="website" rules="" v-slot="validationContext" v-if="willShow(null)">
            <p class="form-label mb-1 mt-3">Website (Optional)</p>
            <b-input v-model="formData.website" class="custom-input" placeholder="Enter Website" type="text"
              :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
            }}</b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center mt-3">
        <Button v-if="showAdditionalEditButton" :text="office ? 'Update' : 'Add another office'" class="o-btn mb-3"
          :onclick="() => next()" :submitting="submittingAnotherOffice" />
        <Button v-if="!single && willShow('show-btn')" :text="office ? 'Update' : 'Add another office'" class="o-btn mb-3"
          :onclick="() => next()" :submitting="submittingAnotherOffice" />
        <Button v-if="!office" text="Save and Continue" class="o-btn" :submitting="submittingSaveAndContinue"
          :onclick="goNext" />
      </div>
      <p class="text-success text-center f-12-px gilroy-b cursor-pointer mt-3 mb-0" v-if="(showSuccessMsg)">{{ msg }}</p>
    </b-form>
  </validation-observer>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
// import { SlideYUpTransition } from "vue2-transitions"
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@/mixins/FormMixin'
import US_STATES from '@/assets/json/usa_states.json'
import US_CITIES from '@/assets/json/usa_cities_with_states.json'
export default {
  mixins: [FormMixin],
  components: {
    Input,
    Button,
    // SlideYUpTransition,
  },
  props: {
    prevOffices: Array,
    single: Boolean,
    office: Object,
    onlyAddress: Boolean,
    onlyDetail: Boolean,
    settingsPage: Boolean,
    showPrevAddressOption: { type: Boolean, default: false },
    initOfficeAddresss: Object,
    showAdditionalEditButton: { type: Boolean, default: false },


  },
  data: () => ({
    offices: [],
    currentState: null,
    formData: {
      name: null,
      addresses: [
        {
          address: null,
          address_type: 'address',
          city: null,
          state: null,
          zip_code: null,
        }
      ],
      website: null,
      phone_number: null,
      practice_software: 'open_dental',
    },
    submittingAnotherOffice: false,
    submittingSaveAndContinue: false,
    errors: [],
    showSuccessMsg: false,
    msg: null,
    setPrevAddress: false,
    practiceSoftwares: [
      { value: 'open_dental', text: 'OpenDental' },
      { value: 'dentirx', text: 'Dentirx' },
      { value: 'eaglesoft', text: 'EagleSoft' },
      { value: 'curve', text: 'Curve' },
      { value: 'carestack', text: 'Carestack' },
      { value: 'macpractice', text: 'MacPractice' },
      { value: 'other', text: 'Other' },
    ]
  }),
  created() {
    // if(this.prevOffices) this.offices = this.prevOffices.map(ofc => {
    //   let obj = {}
    //   if(ofc.id) obj.id = ofc.id
    //   for(let key in this.formData){
    //     obj[key] = ofc[key]
    //   }
    //   return obj
    // })
    if (this.office) this.setData()
  },
  watch: {
    office: {
      handler(v) {
        // this.setData()
      }, deep: true
    }
  },
  computed: {
    ...mapGetters('USER', ['company']),
    companyId() {
      const company = this.$store.getters['USER/company'];
      return company ? company.id : null;
    },

    cities() {
      return US_CITIES;
    },
    states() { return US_STATES; },
  },
  methods: {
    ...mapActions('ONBOARDING', ['ONBOARDING_CREATE_OFFICES', 'CREATE_SUBSCRIPTION']),
    ...mapActions('USER', ['GET_ACCOUNT']),
    ...mapActions('OFFICE', ['UPDATE_OFFICE']),
    onSetPrevAddress() {
      if (this.initOfficeAddresss) {
        this.formData.addresses[0].address = this.initOfficeAddresss.address
        this.formData.addresses[0].city = this.initOfficeAddresss.city
        this.formData.addresses[0].state = this.initOfficeAddresss.state
        this.formData.addresses[0].zip_code = this.initOfficeAddresss.zip_code
      }
    },
    handleStateChange(newValue) {
      this.formData.addresses[0].city = null;
    },
    getStateCode(stateName) {
      for (const state of this.states) {
        if (state.text === stateName || state.value === stateName) {
          return state.value;
        }
      }
      return "Unknown"; // Return "Unknown" for unsupported states
    },
    willShow(name) {
      if (name == 'show-btn') return true
      if (this.onlyAddress && 'address' == name) return true
      if (this.onlyDetail && 'detail' == name) return true
      if (!this.onlyDetail && !this.onlyAddress) return true
    },
    setData() {
      if (this.office) {
        let obj = {}
        if (this.office.id) obj.id = this.office.id
        for (let key in this.formData) {
          obj[key] = this.office[key]
        }
        this.formData = obj
      }
    },
    formatErrors(er) {
      let err = []
      Object.keys(er).forEach(key => {
        err = err.concat(er[key])
      })
      return err
    },
    async next(emitNext = false, goNext = false) {
      if (goNext) {
        this.submittingSaveAndContinue = true;
      } else {
        if (this.submittingAnotherOffice) return;
        this.submittingAnotherOffice = true;
      }

      let isValid = true
      if (this.offices.length == 0 || this.single) isValid = await this.$refs.ofcDetailObserver.validate();
      try {
        if (isValid) {
          if (this.office) {
            let payload
            if (this.onlyDetail) {
              payload = {
                name: this.formData.name,
                id: this.formData.id,
              }
            } else {
              payload = {
                ...this.formData
              }
            }

            const res = await this.UPDATE_OFFICE(payload)
            if (this.$route.name == 'OnBoarding') {
              const index = this.$store.state.ONBOARDING.data.offices.findIndex(o => o.id == res.data.id)
              if (index > -1) {
                this.$store.state.ONBOARDING.data.offices[index] = res.data
              }
            }
          }
          else {
            let payload = {
              "on_boarding_step": 1,
              offices: [{
                ...this.formData,
                website: this.formData.website != null && this.formData.website.trim().length == 0 ? null : this.formData.website
              }],
              single: this.single
            }
            payload.offices = payload.offices.filter(o => o.name != null && o.name.trim().length > 0)
            
            let payloadStripe = {
              company_id: this.companyId,
              quantity: 1,
            }
            if (this.settingsPage) {
              console.log('payloadStripe office:', payloadStripe);
              let res = await this.CREATE_SUBSCRIPTION(payloadStripe);
              await this.ONBOARDING_CREATE_OFFICES(payload);
            } else {
                await this.ONBOARDING_CREATE_OFFICES(payload)
            }
          }
          let tim = 0
          if (this.$route.name === 'Settings') {
            if (this.office) {
              this.msg = 'Updated Successfully'
            } else {
              this.msg = 'Added Successfully'
            }
            this.showSuccessMsg = true
            tim = 1000
          }
          if (!this.office) {
            this.clearFormData()
          }
          setTimeout(async () => {
            if (this.single) {
              this.$emit('done')
              await this.GET_ACCOUNT()
            } else {
              this.$emit('updates')
            }
          }, tim)
          if (emitNext) this.$emit('next')
        }
      } catch (e) {
        if (e.data && e.data.offices) {
          this.errors = e.data.offices
          let errors = {};
          e.data.offices.forEach(err => {
            Object.keys(err).forEach(key => {
              if (key == 'addresses') {
                Object.keys(err.addresses[0]).forEach(k => {
                  errors[k] = err.addresses[0][k]
                })
              }
              else errors[key] = err[key];
            });
          })
          this.$refs.ofcDetailObserver.setErrors(errors);
        } else {
          let errors = {};
          Object.keys(e.data).forEach(key => {
            errors[key] = e.data[key];
          });
          this.$refs.ofcDetailObserver.setErrors(errors);
        }
      }
      this.submittingAnotherOffice = false
      this.submittingSaveAndContinue = false
    },
    clearFormData() {
      this.formData = {
        name: null,
        addresses: [
          {
            address: null,
            address_type: 'address',
            city: null,
            state: null,
            zip_code: null,
          }
        ],
        website: null,
        phone_number: null,
        practice_software: 'open_dental',
      }
      this.$refs.ofcDetailObserver.reset()
    },
    async goNext() {
      if (this.submittingSaveAndContinue) return;
      let save = true
      this.submittingSaveAndContinue = true;

      let isValid = await this.$refs.ofcDetailObserver.validate();

      if (isValid) {
        for (let key in this.formData) {
          if (key != 'id' && this.formData[key] != null && !Array.isArray(this.formData[key]) && this.formData[key].trim().length > 0) save = false
        }
        if (!save) {
          let isvalid = await this.validateForm()
          if (isvalid) {
            await this.next(true, true)
          }
        }
        else if (!this.single && this.prevOffices.length > 0) this.$emit('next')
        else this.next(true, true)
      }
      else {
        this.submittingSaveAndContinue = false;
      }
    },
    // async validateForm(){
    //   let isValid = false
    //   for(let key in this.formData){
    //     if(key != 'id' && this.formData[key] != null && String(this.formData[key]).trim().length > 0) isValid = true
    //   }
    //   if(!isValid){
    //     return await this.$refs.ofcDetailObserver.validate()
    //   }
    //   return isValid
    // },
    async validateForm() {
      // Add your custom validation logic here
      let isValid = false;
      for (let key in this.formData) {
        if (key !== 'id' && this.formData[key] != null && String(this.formData[key]).trim().length > 0) {
          isValid = true;
          break;
        }
      }

      return isValid;
    },
    async saveOffice(clear = true) {
      let isValid = await this.validateForm()
      if (!isValid) return
      if (this.selectedOfficeIndex >= 0) this.offices[this.selectedOfficeIndex] = { ...this.formData }
      else this.offices.push({ ...this.formData })
      if (clear) {
        this.$forceUpdate()
        this.clearFormData()
        this.$refs.ofcDetailObserver.reset()
        this.formData = { ...this.prevData }
        this.prev = null
        this.selectedOfficeIndex = -1
      }
    },
    editOfficeDetails(index) {
      this.selectedOfficeIndex = index
      this.prevData = { ...this.formData }
      this.formData = { ...this.offices[index] }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.office-details
  width: 100%
  max-width: 507px
  padding: 28px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  &.no-shadow
    box-shadow: unset
  @media screen and (max-width: 992px)
    max-width: 100%
  ::v-deep .custom-input
    &:disabled
      background-color: #ffffff
</style>