<template>
  <div :class="[
    'pro-card-wrapper',
    { 'inventory-product-wrapper': inventory },
    isProdOnPromo ? 'manufacturer-promo-product' : 'py-28-px',
    isProdOnPromo ? (inventory ? 'inventory-promo' : 'py-28-px') : '',
    noBorder && 'no-border',
  ]">
    <b-tooltip :target="`tooltip-target-${prod.id}`" triggers="hover focus" placement="top" boundary="window"
      custom-class="promotion-tooltip" v-if="isProdOnPromo && !skeleton">
      <p class="d-desc mb-0">{{ prod.promotion_description }}</p>
    </b-tooltip>
    <div class="product-card custom-flex-wrap d-md-flex align-items-center num-wrp-holder px-3"
      :class="{ 'inventory-product': inventory }">
      <!-- <div class="promotion-holder" v-if="isProdOnPromo && !skeleton" :id="`tooltip-target-${prod.id}`">
        <p class="mb-0 gilroy-b f-12-px text-white text-center">Manufacturer Promo</p>
      </div> -->
      <div class="d-flex flex-column flex-md-row w-100 align-items-center gap-y-12-px">
        <product-image :skeleton="skeleton" v-if="skeleton" :inventoryProduct="inventoryProduct" />
        <div v-else draggable @dragstart="startDrag" class="num-wrp-holder product-image-holder">
          <product-image :product="product" :inventoryProduct="inventoryProduct" class="mx-auto" />
          <span class="num-wrapper__2 d-flex justify-content-center align-items-center"
            v-if="inventory && cartItem(product.id, undefined, true)">
            <span class="num-notf d-desc text-white" style="font-size: 12px; line-height: 1; margin-top: 2px">{{
    cartItem(product.id, undefined, true) }}</span>
          </span>
          <div class="d-flex flex-column gap-10-px mt-2" v-if="inventoryBtns">
            <b-form-file v-model="upPhotoFile" @input="onChangeUpPhoto" ref="photoUp" class="d-none"
              accept="image/*"></b-form-file>
            <b-btn class="custom-btn rmv-btn primary order-md-1" @click="selectPhoto">
              <b-spinner style="width: 0.8rem; height: 0.8rem" class="mr-2" v-if="uploadingPhoto" />
              <span v-if="uploadingPhoto">Uploading</span>
              <span v-else>Upload Custom Photo</span>
            </b-btn>
            <b-btn class="custom-btn rmv-btn danger__2 order-md-1" @click="removeInventoryProduct">
              <b-spinner style="width: 0.8rem; height: 0.8rem" class="mr-2" v-if="removing" />
              <span v-if="prod &&
    prod.office_product_category &&
    prod.office_product_category.slug === 'hidden-products'
    ">Unhide from Inventory</span>
              <span v-else-if="prod &&
    prod.office_product_category &&
    prod.office_product_category.slug !== 'hidden-products'
    ">Hide from Inventory</span>
            </b-btn>
          </div>
        </div>
        <div v-if="skeleton" class="ml-3 w-100 d-flex flex-column flex-lg-row gap-16-px justify-content-lg-between">
          <div class="flex-grow-1">
            <skeleton-loader type="rect" :width="'90%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-0" />
            <skeleton-loader type="rect" :width="'70%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-2" />
            <skeleton-loader type="rect" :width="'80%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-2" />
            <skeleton-loader type="rect" :width="'60%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-2" />
          </div>
          <div class="flex-grow-1 d-flex flex-column align-items-end">
            <skeleton-loader type="rect" :width="'70%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-0" />
            <skeleton-loader type="rect" :width="'50%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-2" />
            <skeleton-loader type="rect" :width="'30%'" :height="'16px'" :radius="16" :rounded="true" animation="wave"
              class="mt-2" />
          </div>
        </div>
        <div class="contents pr-lg-2 pl-lg-3 d-flex flex-column justify-content-center" v-else>
          <p class="product-title mb-2">{{ prod.name }}</p>
          <div class="mb-3 nickname" v-if="nickName || inventory">
            <div v-if="nickName != null && !nicknameEditMode"
              class="br-4-px pr-2 align-items-center input-wrp d-flex flex-column flex-lg-row gap-x-15-px gap-y-4-px">
              <button class="custom-btn nickname-btn" style="min-width: 160px">
                {{ this.nickName }}
              </button>
              <p class="mb-0 text-primary cursor-pointer f-10-px" v-if="inventory" @click="editNickName">
                Edit
              </p>
              <p class="mb-0 text-primary cursor-pointer f-10-px w-max" v-else @click="goTo({ name: 'Inventory' })">
                Edit Nickname on Inventory List
              </p>
            </div>
            <button class="custom-btn nickname-btn w-min" style="min-width: 160px" @click="addNickName"
              v-else-if="inventory && nickName == null && !nicknameEditMode">
              + Add Item Nickname
            </button>
            <div class="row" style="margin-left: 4px" v-else-if="inventory">
              <div class="br-4-px pr-2 align-items-center input-wrp" style="display: flex; border: solid 1px #eaeaeb">
                <b-input v-model="nickName" class="custom-input no-border-inp table-input"
                  placeholder="Add Item Nickname"></b-input>
                <!-- <b-spinner style="width: 0.5rem; height: 0.38rem; border-width: 1px;" class="mr-1 text-primary"
                  v-if="submittingNickName"></b-spinner> -->
                <!-- <p class="mb-0 l-h-1 text-primary cursor-pointer f-10-px" @click="saveNickName">Save</p> -->
              </div>
              <button :submitting="submittingNickName" class="custom-btn nickname-btn w-min" style="margin-left: 8px"
                @click="saveNickName(prod.id)">
                Save
              </button>
            </div>
          </div>
          <p class="d-desc prod-desc mb-0" v-if="prod.manufacturer_number != null || prod.product_id != null">
            Manufacturer
            <span class="text-primary">#{{ getManufacturerId(prod) }}</span>
          </p>
          <p class="l-h-1 mb-2" :title="prod.description || prod.name">
            <span class="d-desc prod-desc">{{ productDescription }} </span>
            <span v-if="showViewFullDesc && !viewFullDesc">
              ...
              <span class="mb-2 text-primary d-desc cursor-pointer" @click="toggleproductDescriptionModal">
                {{ viewFullDesc ? "View Less" : "View Full Description" }}
              </span>
            </span>
          </p>
          <!-- <p class="mb-2 text-primary d-desc cursor-pointer" v-if="showViewFullDesc" @click="toggleproductDescriptionModal">
            {{viewFullDesc ? 'View less' : 'View full description'}}
          </p> -->
          <div class="d-sm-flex align-items-center mb-3 mb-sm-0">
            <p class="mb-0 d-desc text-center text-lg-left" v-if="prod.last_order_date">
              Last Ordered on&nbsp;<span class="d-title__13">{{
    formatDateTime(prod.last_order_date)
  }}</span>&nbsp;
              <span v-if="getVendor(prod)">
                <span class="mb-0 d-desc">from
                  <span class="d-title__13">{{
    getVendor(prod).name
  }}</span></span>
                <span class="mb-0 d-desc avatar__4 square ml-1 d-inline-flex">
                  <img :src="getVendor(prod).logo" alt="" v-if="getVendor(prod).logo" />
                  <span class="text-black" v-else>{{
    getVendor(prod).name[0]
  }}</span>
                </span>
                <span class="mb-0 d-desc">&nbsp; for &nbsp;</span>
                <span class="mb-0 d-title__13 text-success">${{
      prod.last_order_price
        ? parseFloat(prod.last_order_price).toFixed(2)
        : "0.00"
    }}</span>
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Search Products part start -->
      <div v-if="!skeleton" :class="[
    'd-md-flex custom-flex-width r-prt w-100',
    inventoryBtns
      ? 'justify-content-between pl-lg-5'
      : 'justify-content-end',
  ]">
        <div class="d-flex flex-column align-items-center justify-content-center mb-3 mb-md-0" v-if="inventoryBtns">
          <p class="mb-2 d-desc f-10-px gilroy-sb" style="color: #000">
            Quantity
          </p>
          <div class="plus-minus">
            <div class="minus" @click="decreaseQuantity()">-</div>
            <div class="num">{{ commonQuantity }}</div>
            <div class="plus" @click="increaseQuantity()">+</div>
          </div>
        </div>
        <div v-if="supplierAsCard" :class="[
    'cards-sec custom-flex-wrap pl-md-3',
    comparisons.length > 3
      ? 'owl-carousel mr-lg-3 pl-3'
      : 'd-flex align-items-end mr-lg-4',
  ]" ref="owl-carousel">
          <!-- <div v-for="(p, pI) in comparisons" :key="p.fetching_price ? `comp-fetching-price-1-${p.id}` : `comp-1-${p.id}`" :class="[{'pr-com-card': pI > 0 && comparisons.length < 3}]"> -->
          <div v-for="(p, pI) in comparisons" :key="`comp-1-${pI}`"
            :class="[{ 'pr-com-card cards-sec': pI > 0 && comparisons.length < 3 }]">
            <!-- <b-tooltip :target="`tooltip-target-${p.id}`" triggers="hover focus" placement="top" boundary="document"
              custom-class="promotion-tooltip"
              v-if="!isAnyOneFetchingPrice && (hasPromotion(p) || (p.product_vendor_status === 'Discontinued'))">
              <p class="d-desc mb-0" v-if="p.promotion_description">{{ p.promotion_description }} <span
                  v-if="p.special_price">, ${{ p.special_price }}</span> </p>
              <p class="d-desc mb-0" v-else-if="p.product_vendor_status === 'Discontinued'">Discontinued items cannot be
                added to cart</p>
              <p class="d-desc mb-0" v-else>Promotion : Reduced price to ${{ getPrice(p) }} original price
                ${{ getBasePrice(p) }}</p>
            </b-tooltip> -->

            <div :class="[
    'supplier-card__3 num-wrp-holder h-auto',
    { promoted__3: hasPromotion(p) },
  ]" :id="p.fetching_price
    ? 'tooltip-target-no'
    : `tooltip-target-${p.id}`
    ">
              <!-- <div class="promotion-gr__2" v-if="hasPromotion(p)">
                <p class="f-10-px" style="line-height: unset;">
                  <span v-if="getVendor(p, false) && getVendor(p, false).slug === 'net_32'">Price Reduction</span>
                  <span v-else-if="p.special_price != null">PROMO</span>
                  <span v-else>On Promotion</span>
                </p>
              </div> -->
              <div class="promotion-gr__2" v-if="p.vendor.slug == 'crazy_dental'">
                <p class="f-10-px" style="line-height: unset">
                  <span>Exclusive Pricing</span>
                </p>
              </div>
              <div :class="[
    'd-flex flex-column align-items-center justify-content-center position-relative py-3',
    hasPromotion(p) ? '' : 'h-100',
  ]">
                <!-- <p class="mb-0 supp-price text-danger__2" :style="{ visibility: p.special_price != null ? 'visible' : 'hidden' }" v-if="!p.fetching_price && hasPromotion(p)">${{(parseFloat(p.special_price) || 0).toFixed(2)}}</p> -->
                <!-- <p class="mb-0 supp-price text-danger__2" v-if="!p.fetching_price && hasSpecialPrice(p)">${{(getPrice(p)).toFixed(2)}}</p> -->
                <skeleton-loader type="rect" :width="'60%'" :height="'16px'" :radius="16" :rounded="true"
                  animation="wave" v-if="p.fetching_price" />
                <p :class="[
    'mb-0 supp-price text-line-1 mx-2',
    {
      'text-danger__2':
        p.product_vendor_status === 'Discontinued',
    },
  ]" :title="p.product_vendor_status" v-else-if="getPrice(p) == 0 && p.product_vendor_status">
                  <span v-if="p.product_vendor_status == 'Error' ||
    p.product_vendor_status == null
    ">Unavailable</span>
                  <span v-else>{{ p.product_vendor_status }}</span>
                </p>
                <p :class="[
    'mb-0 supp-price text-line-1 mx-2',
    {
      'text-danger__2':
        p.product_vendor_status === 'Discontinued',
    },
  ]" :title="p.product_vendor_status" v-else-if="getStatus(p)">
                  <span v-if="p.product_vendor_status == 'Error' ||
    p.product_vendor_status == null
    ">Unavailable</span>
                  <span v-else>{{ getStatus(p) }}</span>
                </p>
                <p :class="[
    'mb-0 supp-price',
    { 'cut-off': hasSpecialPrice(p) },
  ]" v-else-if="getPrice(p) != 0">
                  <span v-if="hasSpecialPrice(p) && getBasePrice(p) != 0">${{ getBasePrice(p).toFixed(2) }}</span>
                  <span v-else>${{ getPrice(p).toFixed(2) }}</span>
                  <!-- <span class="cut-off-span"></span> -->
                </p>
                <!-- <p class="mb-0 supp-price text-danger__2" v-else>$_.__</p> -->
                <p class="mb-0 supp-price text-danger__2" v-else>
                  Discontinued
                </p>
                <!-- <b-btn v-else class="custom-btn success supp-add-card-btn" @click="fetchPrice(p)">
                  <b-spinner style="width: 0.4rem;height:0.4rem;font-size:5px;" class="mr-2" v-if="fetchingPrice && currentProduct == p" />
                  Fetch Price</b-btn> -->
                <div class="supp-info d-flex align-items-center mt-3 mb-2" v-if="getVendor(p, false)">
                  <p class="mb-0 supp-name">{{ getVendor(p, false).name }}</p>
                  <div class="avatar__4 square ml-1">
                    <img :src="getVendor(p, false).logo" alt="" class="w-100 h-100" v-if="getVendor(p, false).logo" />
                    <span v-else style="color: #000">{{
    getVendor(p, false).name[0]
  }}</span>
                  </div>
                </div>
                <div class="supp-info mb-3">
                  <!-- <p  v-if="hasPromotion(p)">message</p>  -->
                  <p class="mb-0 supp-name f-10-px text-line-1 mx-2" style="line-height: 1" :title="getProductId(p)">
                    Product # : {{ getProductId(p) }}
                  </p>
                </div>
                <div class="plus-minus__2 mb-2">
                  <div class="minus" @click="decreaseQuantity(p)">-</div>
                  <div class="num">
                    {{ getQuantityFromProductsData(p) || commonQuantity }}
                  </div>
                  <div class="plus" @click="increaseQuantity(p)">+</div>
                </div>
                <b-btn class="custom-btn success supp-add-card-btn" v-if="!checkIsVendorConnected(p.vendor.slug)"
                  @click="goTo({ name: 'Settings', hash: '#Lnk' })">Link Vendor</b-btn>
                <b-btn class="custom-btn danger__2 supp-add-card-btn" v-else-if="getVendor(p, false) && !getVendor(p, false).login_success
    " @click="relinkVendor(p.vendor)">Relink</b-btn>
                <b-btn class="custom-btn primary supp-add-card-btn" @click="addProdToCart(p)" v-else>
                  <b-spinner style="width: 0.4rem; height: 0.4rem; font-size: 5px" class="mr-2"
                    v-if="submitting && p.id == currentSubmittingProductId" />
                  <span v-if="orderApproval">+ Add to Order</span>
                  <span v-else>+ Add to Cart</span>
                </b-btn>
              </div>
              <span class="num-wrapper__2 d-flex justify-content-center align-items-center"
                v-if="cartItem(p.id, undefined, true)">
                <span class="num-notf d-desc text-white" style="font-size: 12px; line-height: 1; margin-top: 2px">{{
    cartItem(p.id, undefined, true) }}</span>
              </span>
            </div>
          </div>
          <div :class="[
    { 'ml-3': comparisons.length > 0 && comparisons.length < 2 },
  ]">
            <div class="supplier-card__3 d-flex flex-column align-items-center justify-content-center px-2 py-3"
              style="min-height: 171px">
              <div class="supp-info d-flex align-items-center my-auto px-1">
                <p class="mb-0 text-center d-desc f-10-px" style="line-height: normal">
                  Add a vendor to compare more pricing
                </p>
              </div>
              <b-btn class="custom-btn success supp-add-card-btn" @click="goTo({ name: 'Settings', hash: '#Lnk' })">Link
                a Vendor</b-btn>
            </div>
          </div>
        </div>
        <div v-else-if="inventoryBtns" :key="prod.id" class="inv-acc-btn-div cards-sec d-flex flex-column pt-lg-5 mt-lg-2">
          <b-btn :disabled="comparisons.length <= 0" style="min-width: 135px" class="custom-btn success"
            @click="toggleOtherVendorPricing">
            {{ showOtherVendorPricing ? "Hide Pricing" : "View Pricing" }}
          </b-btn>
          <b-btn style="min-width: 135px" class="custom-btn danger__2 mt-2" v-if="getVendor(prod, false, true) &&
    !getVendor(prod, false, true).login_success
    " @click="relinkVendor(prod.vendor)">Relink</b-btn>
          <!-- <b-btn style="min-width: 135px" class="custom-btn primary mt-2"
            @click="addProdToCart(prod.product, commonQuantity)" v-else>
            <b-spinner style="
                width: 0.5rem;
                height: 0.5rem;
                font-size: 5px;
                margin-bottom: 1px;
              " class="mr-2" v-if="submitting" />
            + Add to Cart
          </b-btn> -->
          <div class="row">
            <p class="mb-0 mt-lg-4 pt-lg-3 mt-2 d-desc ml-md-auto text-center" style="color: black">
              Category :
            </p>
            <p class="ml-1 mb-0 mt-lg-4 pt-lg-3 mt-2 d-desc inv-cat-text text-primary text-center"
              v-if="prod.office_product_category">
              {{ prod.office_product_category.name }}
            </p>
          </div>
        </div>
      </div>
       <!-- Search Products part end -->
    </div>
    
    <div v-if="showOtherVendorPricing">
      <div class="dash my-3"></div>
      <b-row>
        <b-col cols="12" md="5">
          <p class="d-title mb-2">Compare Pricing</p>
          <p class="d-desc__2">
            To view more pricing comparisons click the button below to link more
            vendors
          </p>
          <b-btn class="custom-btn outline-primary ov-link-more-btn"
            @click="goTo({ name: 'Settings', hash: '#Lnk' })">Link more vendors</b-btn>
        </b-col>
        <b-col cols="12" md="7" class="mt-3 mt-md-0">
          <div class="cards-sec__2 ml-auto pr-3 ">
            <div :class="[
    comparisons.length > 3
      ? 'owl-carousel pl-md-3'
      : 'd-md-flex justify-content-md-end align-items-md-end',
  ]" ref="owl-carousel">
              <div class="d-flex" v-for="(p, pI) in comparisons" :key="p.id">
                <!-- <b-tooltip :target="`tooltip-target-${p.id}`" triggers="hover focus" placement="top" boundary="window" custom-class="promotion-tooltip" v-if="hasPromotion(p)">
                  <p class="d-desc mb-0" v-if="p.promotion_description">{{p.promotion_description}}</p>
                  <p class="d-desc mb-0" v-else>Promotion : Reduced price to ${{getPrice(p)}} original price ${{getBasePrice(p) || getPrice(p)}}</p>
                </b-tooltip> -->
                <div class="supplier-card mt-10-px num-wrp-holder h-auto" :class="[
    { 'ml-3': pI > 0 && comparisons.length < 3 },
    { promoted__2: hasPromotion(p) },
  ]" :id="`tooltip-target-${p.id}`">
                  <!-- <div class="promotion-gr" v-if="hasPromotion(p)">
                    <p>On Promotion</p>
                  </div> -->
                  <div class="promotion-gr__2" v-if="p.vendor.slug == 'crazy_dental'">
                    <p class="f-10-px" style="line-height: unset">
                      <span>Exclusive Pricing</span>
                    </p>
                  </div>
                  <div :class="[
    'd-flex flex-column align-items-center position-relative py-11-px',
    hasPromotion(p) ? 'pt-11-px' : 'h-100',
  ]">
                    <skeleton-loader type="rect" :width="'60%'" :height="'16px'" :radius="16" :rounded="true"
                      animation="wave" v-if="p.fetching_price" />
                    <p :class="[
    'mb-0 supp-price text-line-1 mx-2',
    {
      'text-danger__2':
        p.product_vendor_status === 'Discontinued',
    },
  ]" :title="p.product_vendor_status" v-else-if="getPrice(p) == 0 && p.product_vendor_status">
                      <span v-if="p.product_vendor_status == 'Error' ||
    p.product_vendor_status == null
    ">Unavailable</span>
                      <span v-else>{{ p.product_vendor_status }}</span>
                    </p>
                    <p :class="[
    'mb-0 supp-price text-line-1 mx-2',
    {
      'text-danger__2':
        p.product_vendor_status === 'Discontinued',
    },
  ]" :title="p.product_vendor_status" v-else-if="getStatus(p)">
                      <span v-if="p.product_vendor_status == 'Error' ||
    p.product_vendor_status == null
    ">Unavailable</span>
                      <span v-else>{{ getStatus(p) }}</span>
                    </p>
                    <p :class="[
    'mb-0 supp-price',
    { 'cut-off': hasSpecialPrice(p) },
  ]" v-else-if="getPrice(p) != 0">
                      <span v-if="hasSpecialPrice(p) && getBasePrice(p) != 0">${{ getBasePrice(p).toFixed(2) }}</span>
                      <!-- <span v-else>${{ getPrice(p).toFixed(2) }}</span> -->
                      <span class="mb-0 supp-price" v-if="getPrice(p) != 0">
                        ${{ getPrice(p).toFixed(2) }}
                      </span>
                    </p>

                    <div class="supp-info d-flex align-items-center mt-3 mb-2" v-if="getVendor(p, false)">
                      <p class="mb-0 supp-name">
                        {{ getVendor(p, false).name }}
                      </p>
                      <div class="avatar__4 square ml-1">
                        <img :src="getVendor(p, false).logo" alt="" class="w-100 h-100"
                          v-if="getVendor(p, false).logo" />
                        <span v-else style="color: #000">{{
    getVendor(p, false).name[0]
  }}</span>
                      </div>
                    </div>
                    <div class="supp-info mb-3">
                      <p :title="p.product_id" class="mb-0 supp-name f-10-px mx-2" style="
                          line-height: 1;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        ">
                        Product # : {{ getProductId(p) }}
                      </p>
                    </div>
                    <b-btn class="custom-btn success supp-add-card-btn" v-if="checkIsVendorConnected(p.vendor)"
                      @click="goTo({ name: 'Settings', hash: '#Lnk' })">Link Vendor</b-btn>
                    <b-btn class="custom-btn danger__2 supp-add-card-btn" v-else-if="getVendor(p, false) &&
    !getVendor(p, false).login_success
    " @click="relinkVendor(p.vendor)">Relink</b-btn>
                    <b-btn class="custom-btn supp-add-card-btn primary" @click="addProdToCart(p, commonQuantity)"
                      v-else>
                      <b-spinner style="width: 0.4rem; height: 0.4rem; font-size: 5px" class="mr-2" v-if="submitting" />
                      + Add to Cart
                    </b-btn>
                  </div>
                  <span class="num-wrapper__2 d-rtx justify-content-center align-items-center"
                    v-if="cartItem(p.id, undefined, true)">
                    <span class="num-notf d-desc text-white" style="font-size: 12px; line-height: 1; margin-top: 2px">{{
    cartItem(p.id, undefined, true) }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="w-100">
      <p class="text-right d-desc text-danger__2 my-2" v-for="(msg, mI) in msgs" :key="mI">
        {{ msg }}
      </p>
    </div>
    <div v-if="!skeleton">
      <modal :modal="productDescriptionModal" @change="toggleproductDescriptionModal" :hideBackdrop="true" size="md"
        c-classes="product-desc-modal" dialog-class="product-desc-dialog">
        <p class="mb-2 d-desc prod-desc mt-3 text-light">{{ prod.description || prod.name }}</p>
      </modal>
    </div>
    <modal :modal="conModal" @change="toggleConModal" size="md" :noCloseOnBackdrop="true">
      <p class="modal-header-title text-center">Relink Vendor</p>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <p class="modal-header-desc text-center mb-2">
          Oops! It looks like your vendor has become unlinked. Sometimes this
          happens when your vendor login credentials are incorrect or if they
          require a new or updated password.
        </p>
        <p class="modal-header-desc text-center mb-2">
          Action Required: Please visit the vendor website directly and check to
          see if your vendor specific username and password are correct. If they
          are correct, enter in those credentials below to relink the vendor. If
          they are incorrect, please update your username and/or password and
          then enter those credentials below to relink the vendor.
        </p>
        <p class="modal-header-desc text-center mb-4">
          If you are still having any issues, please reach out to us directly
          using the chat button on the lower right of your screen.
        </p>
      </div>
      <div class="dash mb-4"></div>
      <div>
        <conn-acc-form :vendor="selectedVendor" :office="{ id: officeId }" @close="onClose" :settingsPage="true"
          :relink="true" :office-vendor-id="selectedOfficeVendorId" />
      </div>
    </modal>
  </div>
</template>
<script>
import PlusMinusMixin from "@/mixins/PlusMinus";
import StatisticsMixin from "@/mixins/Statistics";
import Button from "@/components/ui/Button";
import OfficeMixin from "@/mixins/OfficeMixin";
import ProductImage from "@/components/ProductImage";
import CartBus from "@/bus/CartBus";
import { mapActions, mapGetters } from "vuex";
import SkeletonLoader from "skeleton-loader-vue";
import BUS from "@/bus/BUS";
import moment from "moment";
import GoTo from "@/mixins/GoTo";
import Modal from "@/components/modals/Modal";
import ConnAccForm from "@/components/onboarding/ConnAccForm";
import PromoSvg from "./Promo";
import PromoExtSvg from "./PromoExt";
export default {
  props: {
    products: Array,
    addToCartBtn: {
      type: Boolean,
      default: true,
    },
    removeBtnInventory: Boolean,
    orderId: Number,
    productPrice: Number,
    prod: Object,
    skeleton: Boolean,
    inventory: Boolean,
    inventoryBtns: Boolean,
    supplierAsCard: Boolean,
    searchSkelton: Boolean,
    inventoryProduct: Boolean,
    inventoryInSearch: Boolean,
    orderApproval: Boolean,
    itemsToCheckIn: Array,
    orderId: Number,
    noBorder: Boolean,
  },
  components: {
    Button,
    ProductImage,
    SkeletonLoader,
    Modal,
    ConnAccForm,
    PromoSvg,
    PromoExtSvg,
  },
  mixins: [PlusMinusMixin, OfficeMixin, GoTo, StatisticsMixin],
  data: () => ({
    currentSubmittingProductId: null,
    fetchingPrice: false,
    currentProduct: null,
    productsData: [],
    msgs: [],
    removing: false,
    uploadingPhoto: false,
    viewFullDesc: false,
    commonQuantity: 1,
    showOtherVendorPricing: false,
    productDescriptionModal: false,
    leftAr: require("@/assets/images/left_pol_arr.png"),
    rightAr: require("@/assets/images/right_pol_arr.png"),
    nickName: null,
    submittingNickName: false,
    nicknameEditMode: false,
    upPhotoFile: null,
    conModal: false,
    selectedVendor: null,
    selectedOfficeVendorId: null,
  }),
  created() {
    if (!this.skeleton) {
      this.productsData = this.comparisons.map((p) => ({
        quantity: 1,
        price: this.getBasePrice(p),
        id: p.id || p.product_id,
      }));
      if (this.product) this.nickName = this.product.nickname;
    }
  },
  mounted() {
    this.setNickName();
    this.setupOwlCar();
  },
  watch: {
    prod: {
      handler(v) {
        this.setupOwlCar();
        this.$forceUpdate();
        this.setNickName();
      },
      deep: true,
    },
  },

  // Search Duplicate Old Code //
  // computed: {
  //   ...mapGetters("CART", ["cartProducts", "budgetType"]),
  //   ...mapGetters("OFFICE", ["connected_vendors"]),
  //   isAnyOneFetchingPrice() {
  //     return this.comparisons.find((c) => c.fetching_price) != null;
  //   },
  //   isProdOnPromo() {
  //     if (this.prod != null) {
  //       return (
  //         this.prod.promotion_description != null &&
  //         this.prod.promotion_description.length > 0
  //       );
  //     }
  //     return false;
  //   },
  //   product() {
  //     if (
  //       this.prod &&
  //       this.prod.vendor == null &&
  //       this.prod.children &&
  //       Array.isArray(this.prod.children) &&
  //       this.prod.children.length > 0
  //     ) {
  //       let p = null;
  //       p = this.prod.children[0];
  //       if (this.prod.image_url && this.prod.image_url !== "") {
  //         p.images = [{ image: this.prod.image_url }];
  //       } else {
  //         let vens = ["henry_schein", "darby", "benco", "patterson"];
  //         for (let i = 0; i < vens.length; i++) {
  //           let ven = vens[i];
  //           let childProduct = this.prod.children.find(
  //             (c) => c.vendor && c.vendor.slug == ven
  //           );
  //           if (childProduct) {
  //             p.images = childProduct.images;
  //             break;
  //           }
  //         }
  //         if (p.images == null) {
  //           p.images = this.prod.children[0].images;
  //         }
  //         return p;
  //       }
  //     }
  //     return this.prod;
  //   },
  //   comparisons() {
  //     if (!this.prod) return [];
  //     let arr = [];
  //     if (
  //       this.prod.vendor == null &&
  //       this.prod.children &&
  //       Array.isArray(this.prod.children) &&
  //       this.prod.children.length > 0
  //     ) {
  //       arr = this.prod.children;
  //     } else arr = [this.prod].concat(this.children || []);
  //     let nulls = arr.filter((a) => this.getPrice(a) == 0);
  //     let notNull = arr.filter((a) => this.product_vendor_status == null);
  //     return notNull
  //       .sort((a, b) => this.getPrice(a) - this.getPrice(b))
  //       .concat(
  //         nulls.sort((a, b) =>
  //           this.getPrice(a) == 0 && a.product_vendor_status ? 1 : -1
  //         )
  //       );
  //     return notNull;
  //   },
  //   total() {
  //     if (this.supplierAsCard) {
  //       return this.comparisons
  //         .map((d, i) => d.price * this.productsData[i].quantity)
  //         .reduce((a, b) => a + b, 0)
  //         .toFixed(2);
  //     }
  //     return this.productsData
  //       .map((d, i) => d.price * d.quantity)
  //       .reduce((a, b) => a + b, 0)
  //       .toFixed(2);
  //   },
  //   showViewFullDesc() {
  //     if (this.prod.description) {
  //       return this.prod.description.length > 100;
  //     }
  //     return false;
  //   },
  //   productDescription() {
  //     let desc = this.prod.description || this.prod.name;
  //     if (desc) {
  //       return !this.viewFullDesc ? desc.substr(0, 100) : desc;
  //     }
  //     return null;
  //   },
  // },

  // Search Duplicate New Code // 
  computed: {
  ...mapGetters("CART", ["cartProducts", "budgetType"]),
  ...mapGetters("OFFICE", ["connected_vendors"]),
  isAnyOneFetchingPrice() {
    return this.comparisons.find((c) => c.fetching_price) != null;
  },
  isProdOnPromo() {
    if (this.prod != null) {
      return (
        this.prod.promotion_description != null &&
        this.prod.promotion_description.length > 0
      );
    }
    return false;
  },
  product() {
    if (
      this.prod &&
      this.prod.vendor == null &&
      this.prod.children &&
      Array.isArray(this.prod.children) &&
      this.prod.children.length > 0
    ) {
      let p = null;
      p = this.prod.children[0];
      if (this.prod.image_url && this.prod.image_url !== "") {
        p.images = [{ image: this.prod.image_url }];
      } else {
        let vens = ["henry_schein", "darby", "benco", "patterson"];
        for (let i = 0; i < vens.length; i++) {
          let ven = vens[i];
          let childProduct = this.prod.children.find(
            (c) => c.vendor && c.vendor.slug == ven
          );
          if (childProduct) {
            p.images = childProduct.images;
            break;
          }
        }
        if (p.images == null) {
          p.images = this.prod.children[0].images;
        }
        return p;
      }
    }
    return this.prod;
  },
  comparisons() {
    if (!this.prod) return [];
    let arr = [];
    if (
      this.prod.vendor == null &&
      this.prod.children &&
      Array.isArray(this.prod.children) &&
      this.prod.children.length > 0
    ) {
      arr = this.prod.children;
    } else arr = [this.prod].concat(this.children || []);
    
    // Filter out duplicate products for Darby and Crazy Dental vendors
    let filteredProducts = [];
    let darbySeen = false;
    let crazyDentalSeen = false;
    arr.forEach((product) => {
      if (product.vendor && product.vendor.slug === "darby") {
        if (!darbySeen) {
          filteredProducts.push(product);
          darbySeen = true;
        }
      } else if (product.vendor && product.vendor.slug === "crazy_dental") {
        if (!crazyDentalSeen) {
          filteredProducts.push(product);
          crazyDentalSeen = true;
        }
      } else {
        filteredProducts.push(product);
      }
    });

    // Separate discontinued, unavailable, or out of stock products
    let discontinued = filteredProducts.filter(
      (product) =>
        product.product_vendor_status === "Discontinued" ||
        product.product_vendor_status === "Out of Stock" ||
        product.product_vendor_status === "Unavailable"
    );
    let remainingProducts = filteredProducts.filter(
      (product) =>
        product.product_vendor_status !== "Discontinued" &&
        product.product_vendor_status !== "Out of Stock" &&
        product.product_vendor_status !== "Unavailable"
    );

    // Sort remaining products by price in ascending order
    remainingProducts.sort((a, b) => this.getPrice(a) - this.getPrice(b));

    // Concatenate the arrays with discontinued products at the end
    let sortedProducts = remainingProducts.concat(discontinued);

    return sortedProducts;
  },
  total() {
    if (this.supplierAsCard) {
      return this.comparisons
        .map((d, i) => d.price * this.productsData[i].quantity)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    }
    return this.productsData
      .map((d, i) => d.price * d.quantity)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  },
  showViewFullDesc() {
    if (this.prod.description) {
      return this.prod.description.length > 100;
    }
    return false;
  },
  productDescription() {
    let desc = this.prod.description || this.prod.name;
    if (desc) {
      return !this.viewFullDesc ? desc.substr(0, 100) : desc;
    }
    return null;
  },
},




  methods: {
    ...mapActions("CART", ["ADD_MULTIPLE_PRODUCTS_TO_CART", "SAVE_FOR_LATER"]),
    ...mapActions("PRODUCT", [
      "HIDE_PRODUCT_FROM_INVENTORY",
      "UNHIDE_PRODUCT_FROM_INVENTORY",
      "UPDATE_PRODUCT",
      "GET_PRICE_BY_PRODUCT_IDS",
    ]),
    ...mapActions("OFFICE", ["FETCH_CONNECTED_VENDORS"]),
    ...mapActions("ORDER", ["ADD_ITEM_IN_VENDOR_ORDER"]),
    getStatus(product) {
      let status = null;
      if (product.product_vendor_status == "Discontinued") {
        if (product.vendor.slug == "crazy_dental") {
          status = "Out of Stock"; // Shows "Out of Stock" for 'crazy_dental' products that are Discontinued
        } else {
          status = "Discontinued"; // Shows "Discontinued" for products from other vendors
        }
      }
      return status;
    },
    toggleConModal(v) {
      if (v != this.conModal) this.conModal = v || false;
    },
    async fetchLinkedVendors() {
      try {
        await this.FETCH_CONNECTED_VENDORS({ office: this.officeId });
      } catch (e) { }
    },
    getVendorSlug(p) {
      console.log(p);
    },
    onClose() {
      this.toggleConModal(false);
      this.fetchLinkedVendors();
    },
    relinkVendor(vendor) {
      this.selectedVendor = vendor;
      let connected_vendor = this.connected_vendors.find(
        (v) => v.vendor.id === vendor.id
      );
      if (connected_vendor) {
        this.selectedOfficeVendorId = connected_vendor.id;
      }
      this.conModal = true;
    },
    getProductId(p) {
      let vens = ["midwest_dental", "pearson"];
      if (p.vendor && vens.includes(p.vendor.slug)) return p.sku;
      return p.product_id;
    },
    getManufacturerId(prod) {
      let id = String(prod.manufacturer_number || prod.product_id);
      if (id.includes("#")) return id.replace("#", "");
      return id;
    },
    onChangeUpPhoto() {
      let reader = new FileReader();
      reader.readAsDataURL(this.upPhotoFile);
      reader.onload = async () => {
        this.uploadingPhoto = true;
        try {
          let payload = {
            office: this.officeId,
            image_url: reader.result,
            product_id: this.prod.id,
          };
          let res = await this.UPDATE_PRODUCT(payload);
          this.$emit("product-image-update", res);
        } catch (e) {
          console.log(e);
        }
        this.uploadingPhoto = false;
      };
    },
    selectPhoto() {
      let el = this.$refs.photoUp;
      el.$refs.input.click();
    },
    setNickName() {
      if (this.prod && this.prod.product) {
        if (
          this.prod.product.nickname != null &&
          this.prod.product.nickname.trim().length === 0
        )
          this.nickName = null;
        else this.nickName = this.prod.product.nickname;
      }
    },
    editNickName() {
      this.nicknameEditMode = true;
    },
    addNickName(evt) {
      this.nicknameEditMode = true;
    },
    async saveNickName(id) {
      this.submittingNickName = true;
      this.$emit("update-nickname", id, this.nickName);
      try {
        if (this.nickName != null && this.nickName.trim().length === 0)
          this.nickName = null;
        let payload = {
          office: this.officeId,
          product_id: this.prod.id,
          nickname: this.nickName == null ? "" : this.nickName,
        };
        await this.UPDATE_PRODUCT(payload);
        this.nicknameEditMode = false;
      } catch (e) {
        console.log(e);
      }
      this.submittingNickName = false;
    },
    hasPromotion(p) {
      return (
        p.promotion_description != null && p.promotion_description.length > 0
      );
    },
    hasSpecialPrice(p) {
      return (
        p.special_price != null &&
        this.hasPromotion(p) &&
        p.special_price > this.getBasePrice(p)
      );
    },
    getBasePrice(p) {
      return Number(p.unit_price || p.product_price || p.price || 0);
    },
    getPrice(p) {
      if (this.hasPromotion(p) && this.hasSpecialPrice(p))
        return Number(p.special_price);
      return this.getBasePrice(p);
    },
    checkIsVendorConnected(slug) {
      return this.connected_vendors.find((v) => v.vendor.slug == slug) != null;
    },
    getVendor(p, checkLastOrderVendor = true, takeProduct = false) {
      if (p.last_order_vendor && checkLastOrderVendor) {
        return this.connected_vendors
          .map((cv) => cv.vendor)
          .find((v) => v.id == p.last_order_vendor);
      } else {
        let vendor = p.vendor;
        if (p.product && takeProduct) {
          vendor = p.product.vendor;
        }
        if (vendor) {
          if (typeof vendor === "object") {
            let connected_vendor = this.connected_vendors.find(
              (v) => v.vendor.slug === vendor.slug
            );
            if (connected_vendor != null)
              return {
                login_success:
                  connected_vendor.login_success || this.office.id === 227,
                officeVendorId: connected_vendor.id,
                ...vendor,
              };
            return vendor;
          } else {
            return {
              name: vendor,
              slug: p.slug,
              logo: require("@/assets/images/landing/amazon.png"),
            };
          }
        }
      }
    },
    toggleproductDescriptionModal(v) {
      if (v != this.productDescriptionModal)
        this.productDescriptionModal =
          v === true || v === false ? v : !this.productDescriptionModal;
    },
    async toggleOtherVendorPricing() {
      this.showOtherVendorPricing = !this.showOtherVendorPricing;
      await this.$nextTick();
      this.setupOwlCar();
    },
    setupOwlCar() {
      if (this.comparisons.length > 3) {
        this.initOwl();
      }
    },
    initOwl() {
      let owl = this.$refs["owl-carousel"];
      $(owl).owlCarousel({
        nav: true,
        items: 3,
        autoWidth: true,
        margin: 16,
        mouseDrag: false,
        touchDrag: false,
        navText: [
          `<img class="owl-arrow" src="${this.leftAr}" alt="">`,
          `<img class="owl-arrow" src="${this.rightAr}" alt="">`,
        ],
      });
    },
    checkInItems(productId, full) {
      let cartItems = this.itemsToCheckIn || [];
      let cartProd = cartItems.find((c) => c.product.id == productId);
      if (cartProd) return full ? cartProd : cartProd.quantity;
      return null;
    },
    cartItem(productId, full, excludeSaveForLater = false) {
      if (this.orderApproval) {
        return this.checkInItems(productId, full);
      }
      let cartItems = this.cartProducts;
      if (excludeSaveForLater)
        cartItems = this.cartProducts.filter((p) => !p.save_for_later);
      let cartProd = cartItems.find((c) => c.product.id == productId);
      if (cartProd) return full ? cartProd : cartProd.quantity;
      return null;
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d, "YYYY-MM-DD").format("MM/DD/YY");
    },
    startDrag(evt) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("productId", this.prod.id);
    },
    show() {
      this.$emit("show", this.product);
    },
    fetchPrice(product) {
      if (this.fetchingPrice) return;
      this.fetchingPrice = true;
      this.currentProduct = product;
      this.GET_PRICE_BY_PRODUCT_IDS({
        office: this.officeId,
        products: [product.id],
      }).then((pRes) => {
        product.price = pRes[product.id].price;
        product.product_price = pRes[product.id].price;
        this.fetchingPrice = false;
        this.currentProduct = null;
        this.$forceUpdate();
      });
    },
    decreaseQuantity(prod) {
      if (prod == undefined || prod == null) {
        if (this.commonQuantity > 1) --this.commonQuantity;
      } else {
        const id = prod.id || prod.product_id;
        let index = this.productsData.findIndex((p) => p.id == id);
        if (index > -1) {
          let q = this.getQuantityFromProductsData(prod);
          if (q > 0) --this.productsData[index].quantity;
        } else {
          //TODO: only for quick fix
          if (this.commonQuantity > 1) {
            this.commonQuantity--;
          }
        }
      }
    },
    increaseQuantity(prod) {
      if (prod == undefined || prod == null) {
        ++this.commonQuantity;
      } else {
        const id = prod.id || prod.product_id;
        let index = this.productsData.findIndex((p) => p.id == id);
        if (index > -1) {
          ++this.productsData[index].quantity;
        } else {
          //TODO: only for quick fix
          this.commonQuantity++;
        }
      }
    },
    getQuantityFromProductsData(prod) {
      const id = prod.id || prod.product_id;
      let data = this.productsData.find((p) => p.id == id);
      if (data) return data.quantity;
      return null;
    },
    async addProdToCart(prod, qnt) {
      if (this.getPrice(prod) != 0 && !this.getStatus(prod)) {
        if (prod.product != undefined) prod = prod.product;
        if (prod) {
          const id = prod.id || prod.product_id;
          this.currentSubmittingProductId = id;
          let quantity = 0;
          if (qnt != null || qnt != undefined) quantity = qnt;
          else quantity = this.productsData.find((p) => p.id == id).quantity;
          if (this.submitting || this.skeleton || quantity <= 0) return;
          this.submitting = true;
          try {
            let orderObj = this.cartItem(id, true);
            if (this.orderApproval) {
              const payload = {
                product: id,
                office: this.officeId,
                quantity,
                vendor_order_id: this.orderId,
                budget_type: this.searchTo,
              };
              await this.ADD_ITEM_IN_VENDOR_ORDER(payload);
              this.$emit("added-item-in-order");
            } else if (orderObj) {
              quantity += orderObj.quantity;
              if (orderObj.save_for_later) {
                let payload = {
                  office: this.officeId,
                  cart_id: orderObj.id,
                  save_for_later: !orderObj.save_for_later,
                  budget_type: this.searchTo,
                };
                await this.SAVE_FOR_LATER(payload);
              }
              await this.update({
                office: this.officeId,
                cart: orderObj.id,
                budget_type: this.searchTo,
                quantity,
              });
            } else {
              let payload = {
                office: this.officeId,
                data: [
                  {
                    product: id,
                    office: this.officeId,
                    quantity,
                    unit_price: this.getPrice(prod),
                    budget_type: this.searchTo,
                  },
                ],
              };
              let res = await this.ADD_MULTIPLE_PRODUCTS_TO_CART(payload);
            }
            if (!this.orderApproval) {
              this.$BUS.$emit("fetch-cart", true);
              BUS.$emit("open-cart-tab");
            }
          } catch (e) {
            if (Array.isArray(e)) this.msgs = e;
            else this.msgs = [e];
          }
          this.submitting = false;
        } else {
          this.msgs = ["Out of Stock"];
        }
      } else {
        this.msgs = [
          `You can't add out-of-stock or discontinued items to the shopping cart`,
        ];
      }
    },
    async removeInventoryProduct() {
      if (this.removing) return;
      this.removing = true;
      try {
        let payload = {
          office: this.officeId,
          order_id: this.prod.id,
        };
        if (
          this.prod &&
          this.prod.office_product_category &&
          this.prod.office_product_category.slug === "hidden-products"
        ) {
          await this.UNHIDE_PRODUCT_FROM_INVENTORY(payload);
        } else {
          await this.HIDE_PRODUCT_FROM_INVENTORY(payload);
        }
      } catch (e) {
        console.log(e);
      }
      this.removing = false;
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../../style/variables'
@import '../../style/settings'
.gap-10
  gap: 10px

.product-image-holder
  @media screen and (max-width: 992px)
    width: 100%
.nickname
  .custom-btn
    min-height: 30px
    font-size: 10px
  .input-wrp
    input
      height: 28px !important
      &::placeholder
        font-size: 10px
.inv-cat-text
  @media screen and (min-width: 992px)
    max-width: 135px
    text-align: center
.pr-com-card
  margin-left: 1rem
::v-deep .owl-carousel
  // .owl-item
  //   display: none
  //   &.active
  //     display: block
  .owl-stage
    display: flex
    align-items: flex-end
  .owl-dots
    display: none
  .owl-nav
    position: absolute
    width: calc( 100% + 25px )
    display: flex
    justify-content: space-between
    right: unset
    top: calc( 50% - 10px )
    right: -15px
    z-index: -1
    .owl-arrow
      width: 14px
      height: 18px
    .owl-prev , .owl-next
      &.disabled
        .owl-arrow
          opacity: 50%
.ov-link-more-btn
  min-height: 29px
  font-size: 10px
.inv-acc-btn-div
  .custom-btn
    min-height: 34px
    min-width: 113px
    font-size: 10px
.cards-sec
  max-width: 705px
  width: -moz-min-content
  width: min-content
  gap: 10px
  // overflow-x: auto
  // width: min-content
  &__2
    @extend .cards-sec
    max-width: 630px
  @media screen and (min-width: 992px)
    // margin-right: 25px
  @media screen and (max-width: 992px)
    max-width: 100%
.manufacturer-promo-product
  margin-top: 1rem
.pro-card-wrapper
  &.inventory-product-wrapper
    border-top: 1px solid #EBF4F7
    &.no-border
      border-top: none
.product-card
  width: 100%
  position: relative
  .promotion-holder
    position: absolute
    top: -70px
    background-image: url(./Promo.svg)
    width: 96px
    height: 91px
    background-size: 100% 100%
    padding: 10px 17px 10px 3px
    line-height: 1.2
    display: flex
    justify-content: center
    align-items: center
    .inventory-promo &
      top: -100px
  .price-comparison-wrapper
    height: 100%
    max-height: 164px
    min-height: 164px
    overflow-y: auto
    border-left: solid 1px #EBF4F7
    max-width: 320px
    .prices-comparison
      min-height: 164px
      .ven-name
        font-size: 10px
        @media screen and (min-width: 992px)
          width: 100px
  .ct-btn
    min-width: 69px
    min-height: 28px
    font-size: 9px
  ::v-deep .images-sec
    min-width: 140px
    width: 140px
    height: 140px
    background-color: #ebf4f8
    img
      max-height: 140px
    // @media screen and (max-width: 992px)
    //   min-width: 100%
    //   min-height: 100%
    &.inventory-product
      img
        max-height: calc(140px - 28px - 4px)
  .contents
    width: 100%
    max-width: 500px
    @media screen and (max-width: 992px)
      max-width: 100%
    .prod-desc
      max-width: 250px
      width: 100%
    .reviews-sec
      .rev-text
        font-size: 14px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.71
        color: #7cd878
    .product-title
      font-family: GILROY-BOLD
      font-size: 16px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 1.4
      color: #1c455a
      max-width: 420px
      width: 100%
      @media screen and (max-width: 992px)
        width: 100%
        max-width: 100%
      &__2
        @extend .product-title
        font-size: 20px
        font-family: Gilroy-EXTRABOLD
        line-height: 1.2
        color: #8BDE88
    .prod-desc
      max-width: 420px
      @media screen and (max-width: 992px)
        max-width: 100%
    .bottom-sec
      .ven-text
        line-height: 1
        color: #49a2cb
    .nickname-btn
      @media screen and (max-width: 992px)
        width: 100%
  &.inventory-product
    .price-comparison-wrapper
      min-height: 133px
      max-height: 133px
      .prices-comparison
        min-height: 133px
    ::v-deep .images-sec
      // min-width: 85px
      // width: 85px
      // height: 85px
      img
        // max-height: 85px
        max-width: calc( 100% )
        max-height: calc( 100% )
    .contents
      max-width: 450px
      .product-title
        font-size: 12px
      .d-desc
        font-size: 10px
    .r-prt
      width: 100%
      justify-content: space-between
      @media screen and (min-width: 992px)
        padding-right: 20px
        max-width: 42%
.rmv-btn
  min-height: 34px !important
  @media screen and (min-width: 992px)
    min-height: 21px !important
    font-size: 10px !important
    padding-left: 0
    padding-right: 0
    min-width: 120px
.add-ven-text
  max-width: 189px
.add-ven-btn
  min-width: 77px
  max-height: 30px !important
  min-height: 30px !important
  font-size: 8px !important
.num-wrapper__2.futureCastItem
  left: 0
  display: flex
  .product-desc-modal
  
</style>
<style>
@media (max-width: 1399.98px) { 
  .cards-sec[data-v-0ef754ac], .cards-sec__2[data-v-0ef754ac] {
    max-width: 525px;
}
}
</style>
