<template>
  <div class="product-card d-flex">
    <product-image :skeleton="skeleton" v-if="skeleton" style="height: 120px" />
    <product-image :product="product" :showLg="false&&hasPromotion" :inv-text="product.vendor && product.vendor.slug === 'net_32' ? 'Price Reduction' : 'PROMO'" :no-image-short="true" style="height: inherit" v-else />
    <div class="contents px-2 ml-15-px flex-grow-1">
      <div v-if="skeleton">
        <skeleton-loader type="rect" :width="'90%'" :height="'16px'" :radius="16" :rounded="true" animation="wave" class="mt-0" />
        <skeleton-loader type="rect" :width="'70%'" :height="'16px'" :radius="16" :rounded="true" animation="wave" class="mt-2" />
        <skeleton-loader type="rect" :width="'70%'" :height="'16px'" :radius="16" :rounded="true" animation="wave" class="mt-2" />
        <skeleton-loader type="rect" :width="'70%'" :height="'16px'" :radius="16" :rounded="true" animation="wave" class="mt-2" />
      </div>
      <div v-else>
        <p class="product-title mb-1">{{product.name}}</p>
        <div class="d-flex flex-wrap gap-x-16-px align-items-center mb-1">
          <b-tooltip :target="`tooltip-price-change-cart-${order.id}`" triggers="hover focus" placement="top" boundary="document" custom-class="promotion-tooltip"  v-if="order.save_for_later && order.updated_unit_price && order.updated_unit_price != order.unit_price">
            <p class="d-desc mb-0">Price changed from ${{ order.unit_price }} to ${{ order.updated_unit_price }}</p>
          </b-tooltip>
          <p class="t-text mb-0 pl-2-px" :id="`tooltip-price-change-cart-${order.id}`">
            <span class="d-title__3 ml-0 min-w-48-px" style="color: #29BF12">${{ Number( order.unit_price).toFixed(2) }}</span>
            <img src="@/assets/images/caution.png" alt="" class="w-14-px h-14-px mb-1 ml-2" v-if="order.save_for_later && order.updated_unit_price && order.updated_unit_price != order.unit_price" />
          </p>
          <div class="plus-minus">
            <div class="minus" @click="quantity == 1 ? deleteProduct() : decreaseQuantity(true, false)">-</div>
            <div class="num">
              <b-spinner style="width:0.8rem;height:0.8rem" variant="primary" v-if="updatingQuantity" />
              <span v-else>{{quantity}}</span>
            </div>
            <div class="plus" @click="increaseQuantity(true, false)">+</div>
          </div>
        </div>
        <div class="bottom-sec d-flex justify-content-between" v-if="product.vendor">
          <div class="mb-0 d-title__4 d-flex align-items-center">
            <span style="color: #788288">Vendor: </span> &nbsp; <span class="ven-text mr-2">{{product.vendor.name}}</span>
            <div class="avatar__super-mini mb-1">
              <img :src="product.vendor.logo" alt="" class="w-100 h-100" v-if="product.vendor.logo">
              <span v-else>{{product.vendor.name[0]}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trash ml-auto cursor-pointer" v-if="!skeleton">
      <b-spinner small variant="primary" class="mr-2" v-if="(deletingProduct)"></b-spinner>
      <img src="@/assets/images/trash.png" alt="" style="width: 20px;height: 20px" @click.stop="deleteProduct" v-else>
    </div>
  </div>
</template>

<script>
import PlusMinusMixin from '@/mixins/PlusMinus'
import ProductImage from '../ProductImage.vue'
import SkeletonLoader from 'skeleton-loader-vue'
export default {
  components: { ProductImage, SkeletonLoader, },
  props: {
    product: Object,
    productQuantity: Number,
    order: Object,
    skeleton: Boolean,
  },
  mixins: [PlusMinusMixin],
  data(){
    return {
      deletingProduct: false
    }
  },
  created(){
    this.quantity = this.productQuantity
  },
  watch: {
    productQuantity(v){
      this.quantity = v
    },
  },
  computed: {
    hasPromotion(){
      return this.product.promotion_description != null && this.product.promotion_description.length > 0
    },
  },
  methods: {
    async deleteProduct(){
      this.deletingProduct = true
      this.quantity = 0
      await this.deleteCart({
        vendor: this.product.vendor.id,
        office: this.officeId,
        cart: this.order.id
      })
      this.deletingProduct = false
    },
  },
}
</script>

<style lang="sass" scoped>
.product-card
  // min-width: 211px
  // height: 199px
  // border-radius: 4px
  // border: solid 1px rgba(28, 69, 90, 0.1)
  background-color: #ffffff
  ::v-deep .images-sec
    max-width: 132px
    min-width: 66px
    width: 100%
    height: 66px
    max-height: 120px
    height: 100%
    background-color: #ebf4f8
    img
      max-height: 130px
    &.show-lg
      img
        max-height: calc(132px - 44px)
  .contents
    max-width: 255px
    .reviews-sec
      .rev-text
        font-size: 9px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1
        color: #7cd878
    .product-title
      font-family: GILROY-BOLD
      font-size: 14px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.6
      color: #1c455a
    .bottom-sec
      .ven-text
        line-height: 1
        color: #788288
</style>
