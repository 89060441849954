<template>
  <div :class="[display, justify, align, padding]">
    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
    <p class="ml-3 mb-0 mt-1 d-desc__2">{{text}}</p>
  </div>
</template>

<script>
export default {
  props: {
    display: { type: String, default: 'd-flex' },
    justify: { type: String, default: 'justify-content-center' },
    align: { type: String, default: 'align-items-center' },
    padding: { type: String, default: 'py-4' },
    text: { type: String, default: 'Loading' },
  },
}
</script>

<style lang="sass" scoped>

</style>