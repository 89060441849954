<template>
  <div class="categories-sidebar-wrapper">
    <InventoryCategories
      :categories="categories"
      :fetching-categories="fetchingCategories"
      :selected-category="selectedCategory"
      :set-selected-category="setSelectedCategory"
      @fetch-cat="$emit('fetch-cat')"
      @reload="$emit('reload')"
    />
    <div class="d-card mt-4">
      <div class="d-flex justify-content-between align-items-center px-4 pt-4">
        <p class="mb-0 d-title__2">Filter by Vendor</p>
      </div>
      <div class="d-flex align-items-center mt-3 px-3">
        <b-btn class="custom-btn b1 primary-light w-100" v-if="selectedVendor" @click="setSelectedVendor(null)">Show All</b-btn>
      </div>
      <div class="categories-sidebar d-flex flex-lg-column px-4 pt-3">
        <div class="linked__vendor">
        <div v-if="fetchingInventoryVendors"
          :class="['category-sm-card d-flex align-items-center justify-content-between px-3 mb-2']" 
        >
          <skeleton-loader type="rect" :width="'60%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" />
          <skeleton-loader type="rect" :width="'20%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" />
        </div>
      
      </div>
        <div 
          :class="[
            'category-sm-card d-flex align-items-center justify-content-between px-3 mb-2',{
            'active': selectedVendor && selectedVendor.id == vendor.id
          }]" 
          v-for="(vendor, cI) in computedVendors" :key="cI"
          @click="onClickVendor(vendor)"
        >
          <p class="mb-0 category-name text-line-1">{{vendor.name}}</p>
          <div :class="['d-flex align-items-center ml-1', { 'mr-18-px' : vendor.predefined }]">
            <div class="prod-num d-flex justify-content-center align-items-center">
              <span>{{vendor.count}} items</span>
            </div>
          </div>
        </div>
        <div  :class="[
            '',{
            'active': selectedVendor && selectedVendor.id == vendor.id
          }]" v-if="inventoryVendors.length<1">
          <p class="text-danger__2 f-14px ">
            No Vendor is linked yet!.. 
          </p>
        </div>
        <div class="d-flex mt-2 mb-4 justify-content-end">
          <b-btn class="custom-btn primary f-12-px w-100" @click="viewAllVendor = !viewAllVendor" v-if="inventoryVendors.length>0">
            <span v-if="viewAllVendor">View Less</span>
            <span v-else>View All</span>
          </b-btn>
          <div class="custom-btn primary f-12-px w-100" v-else>
            <router-link to="/settings#Lnk" class="text-white ">  <b-btn class="custom-btn primary f-12-px w-100" >Link a Vendor </b-btn></router-link> 
          </div>
        </div>
      </div>
    </div>
    <div class="d-card mt-4">
      <div class="d-flex justify-content-between align-items-center px-4 pt-4">
        <p class="mb-0 d-title__2">Filter by Price</p>
      </div>
      <div class="py-3 px-4">
        <div class="d-flex gap-10-px">
          <div class="fil-price p-2">
            <p class="f-10-px text-primary mb-2">Min Price</p>
            <div class="d-flex align-items-center">
              <p class="mb-0 text-success f-24-px">$</p>
              <b-input type="number" min="0" v-model="filterByPrice.values[0]" class="custom-input back-no-img"></b-input>
            </div>
          </div>
          <div class="fil-price p-2">
            <p class="f-10-px text-primary mb-2">Max Price</p>
            <div class="d-flex align-items-center">
              <p class="mb-0 text-success f-24-px">$</p>
              <b-input type="number" min="0" v-model="filterByPrice.values[1]" class="custom-input back-no-img"></b-input>
            </div>
          </div>
        </div>
        <!-- <RangeSlider :min="0" :max="2000" class="mt-3" /> -->
        <div class="d-flex mt-4 justify-content-end">
          <b-btn class="custom-btn primary f-12-px w-100" @click="doFilterByPrice">
            <span>Submit</span>
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfficeMixin from '@/mixins/OfficeMixin'
import SkeletonLoader from 'skeleton-loader-vue'
import Button from '@/components/ui/Button'
import RangeSlider from '@/components/ui/RangeSlider.vue'
import InventoryCategories from '@/components/dashboard/inventory/Categories.vue'

export default {
  props: {
    categories: Array,
    inventoryVendors: Array,
    fetchingCategories: Boolean,
    fetchingInventoryVendors: Boolean,
    selectedCategory: Object,
    selectedVendor: Object,
    setSelectedCategory: Function,
    setSelectedVendor: Function,
    onClickVendor: Function,
  },
  mixins: [ OfficeMixin ],
  components: { SkeletonLoader, Button, RangeSlider, InventoryCategories, },
  data: () => ({
    viewAllVendor: false,
    filterByPrice: {
      values: [ 0, null ],
    },
  }),
  computed: {
    computedVendors(){

      if(this.viewAllVendor) return this.inventoryVendors
      return this.inventoryVendors.slice(0, 5)
   
    },
  },
  methods: {
    doFilterByPrice(){
      this.$emit('filter-by-price', this.filterByPrice)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'
@import './inventory/_category_sidebar'
.f-14px
  font-size: 14px
  
</style>