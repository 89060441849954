import axios from '@/axios'
import localStorageService from '@/store/localStorageService';

let greetKey = 'onceGreets';
let hardCloseKey = 'hardClose';

const state = {
  allGreetings:[],
  onceGreets: [],
  showGreetings:false,
  hardClosed:false,
}

const mutations = {
  clearOnceGreet(state) {
    state.onceGreets = [];
  },
  addOnceGreet(state, greetingId) {
    state.onceGreets.push(greetingId);
    localStorageService.setItem(greetKey, state.onceGreets);
  },
  setAllGreetings(state, greetings){
    state.allGreetings = greetings;
    state.showGreetings = greetings.length > 0;
  },
  setHardClosed(state, value){
    localStorageService.setItem(hardCloseKey, value);
    state.hardClosed = value;
  }
};

const actions = {
  FETCH_GREETINGS_BY_ROLE: async ({ rootGetters, commit, state }, payload) => {
    let hardClose = localStorageService.getItem(hardCloseKey);
    commit('setHardClosed', hardClose);
    try {
      let url = 'greetings/all/'
      let { data } = await axios.reqApi(url + payload.role).get()
      let filteredGreets = filterGreetsOnOnce(data.data, commit);
      addToOnceGreet(data.data, commit);
      commit('setAllGreetings', filteredGreets);
      return filteredGreets;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CLOSE_CAROUSEL({ commit }, value) {
    commit('setHardClosed', value);
  },
};

const getters = {
  getAllGreetings(state){
    return state.allGreetings;
  },
  getShowGreetings(state){
    return state.showGreetings;
  },
  getHardClosed(state){
    return state.hardClosed;
  }
};

function filterGreetsOnOnce(greetings, commit) {
  var length = greetings.length;
  let localOnceGreets = localStorageService.getItem(greetKey) || []
  let filteredGreetings = []
  for (let i = 0; i < length; i++) {
    let greeting = greetings[i]
    if (!localOnceGreets.includes(greeting.id)) {
      filteredGreetings.push(greeting)
    }
  }
  return filteredGreetings

}

function addToOnceGreet(greetings, commit) {
  var length = greetings.length;
  for (let i = 0; i < length; i++) {
    let greeting = greetings[i]
    if (greeting.once) {
      if (state.onceGreets.length == 0 || !state.onceGreets.includes(greeting.id)) {
        commit('addOnceGreet', greeting.id);
      }
    }

  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}  