<template>
  <!-- <div class="product-card">
    <product-image :product="product" />
    <div class="contents px-2 pb-3">
      <div class="d-flex justify-content-between my-3">
        <p class="product-title mb-1">{{product.name}}</p>
      </div>
      <div class="d-flex justify-content-between mt-3 align-items-center">
        <div>
          <div class="d-flex align-items-center">
            <span class="product-desc mr-1">Supplier : {{product.vendor.name}}</span>
            <div class="avatar__super-mini mb-1">
              <img :src="product.vendor.logo" alt="" class="w-120 h-120" v-if="product.vendor.logo">
              <span v-else>{{product.vendor.name[0]}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dash my-3"></div>
      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <div class="btn-rect cursor-pointer px-2 py-2 mr-2" @click="addToCart">
          <b-spinner small variant="primary" v-if="submitting"></b-spinner>
          <span v-else>+ Add to Cart</span>
        </div>
        <div class="btn-rect__red cursor-pointer px-2 py-2 mt-2 mt-lg-0" @click="removeInventoryProduct">
          <b-spinner small class="text-danger__2" v-if="removing"></b-spinner>
          <span v-else>Remove Item</span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="product-card d-lg-flex mt-2 mb-3">
    <product-image :product="product" />
    <div class="contents px-2">
      <p class="product-title mt-2 mb-2">{{product.name}}</p>
      <p class="mb-1 d-desc">{{product.description}}</p>
      <div class="d-flex flex-wrap my-2 align-items-center">
        <p class="mb-0 d-desc">
          Vendor(s) : <span>{{product.vendor.name}}</span>
        </p>
        <div class="d-flex ml-2">
          <div class="avatar__mini">
            <img :src="product.vendor.logo" alt="" class="w-100 h-100" v-if="product.vendor.logo">
            <span v-else>{{product.vendor.name[0]}}</span>
          </div>
        </div>
      </div>
      <p class="mb-2">
        <span class="product-title__2">${{product.price}}</span>
        <span class="d-desc mb-3 ml-1">As low as</span>
      </p>
    </div>
    <div class="pl-lg-2 d-lg-flex flex-column justify-content-center align-items-center w-fill">
      <div class="btn-rect cursor-pointer px-2 py-2" @click="addToCart">
        <b-spinner small variant="primary" v-if="submitting"></b-spinner>
        <span v-else>+ Add to Cart</span>
      </div>
      <div class="btn-rect__red cursor-pointer px-2 py-2 mt-2" @click="removeInventoryProduct">
        <b-spinner small class="text-danger__2" v-if="removing"></b-spinner>
        <span v-else>Remove Item</span>
      </div>
    </div>
  </div>
</template>

<script>
import PlusMinusMixin from '@/mixins/PlusMinus'
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import ProductImage from '@/components/ProductImage'
export default {
  components: {
    ProductImage,
  },
  props: {
    orderId: Number,
    product: Object,
  },
  mixins: [PlusMinusMixin, OfficeMixin],
  data: () => ({
    removing: false,
  }),
  methods: {
    ...mapActions('PRODUCT', ['HIDE_PRODUCT_FROM_INVENTORY', 'UNHIDE_PRODUCT_FROM_INVENTORY']),
    async removeInventoryProduct(){
      if(this.removing) return
      this.removing = true
      try{
        let payload = {
          office: this.officeId,
          order_id: this.orderId
        }
        await this.HIDE_PRODUCT_FROM_INVENTORY(payload)
        this.$emit('removed')
      }catch(e){
        console.log(e)
      }
      this.removing = false
    },
  },
}
</script>

<style lang="sass" scoped>

.product-card
  width: 100%
  .images-sec
    min-width: 172px
    width: 172px
    height: 162px
    background-color: #ebf4f8
    img
      max-height: 162px
    @media screen and (max-width: 992px)
      min-width: 100%
      min-height: 100%
  .contents
    width: 100%
    .prod-desc
      max-width: 250px
      width: 100%
    .reviews-sec
      .rev-text
        font-size: 14px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.71
        color: #7cd878
    .product-title
      font-family: GILROY-BOLD
      font-size: 16px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 1.4
      color: #1c455a
      max-width: 350px
      width: 100%
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      @media screen and (max-width: 992px)
        width: 100%
      &__2
        @extend .product-title
        font-size: 20px
        font-family: Gilroy-EXTRABOLD
        line-height: 1.2
        color: #8BDE88
    .d-desc
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
    .bottom-sec
      .ven-text
        line-height: 1
        color: #49a2cb
  .btn-rect, .btn-rect__red
    height: 35px
    width: 100%
    max-width: 150px
    @media screen and (max-width: 992px)
      max-width: 100%
</style>