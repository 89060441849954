<template>
  <div class="no-data d-flex flex-column justify-content-center align-items-center">
    <p class="d-title mb-1 text-center">No Order History</p>
    <p class="d-desc mb-3 text-center" style="max-width: 450px">Currently you have no order histroy. If you link a vendor we’ll display all your previous orders from these vendors in addition to orderd placed through Ordo. You’ll also see your order approvals and all the current status of your orders</p>
    <Button style="min-width: 200px" text="Connect a Vendor" :onclick="() => goTo({name: 'Settings', hash: '#Lnk'})" />
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
}
</script>

<style lang="sass" scoped>

.no-data
  background-image: url('../../assets/images/order-box.png')
  background-position: 70px 0px
  background-size: 90% 100%
  background-repeat: no-repeat
  min-height: 220px
  @media (max-width: 762px)
    background-image: unset
</style>