<template>
  <div class="bar-chart">
    <div class="d-md-flex px-3 justify-content-between">
      <p class="d-title mb-0">Budget vs Amount Spent</p>
      <div class="d-md-flex align-items-center">
        <div class="d-flex align-items-center">
          <div class="budget-color-div"></div>
          <p class="d-title__3 mb-0">Budget</p>
        </div>
        <div class="d-flex align-items-center ml-md-3">
          <div class="spent-color-div"></div>
          <p class="d-title__3 mb-0">Amount Spent</p>
        </div>
        <b-form-select v-model="filterBy.dt" :options="dtOptions" class="dt-options ml-md-3 mt-2 mt-md-0" v-if="!this.$store.state.OFFICE.fetchingBudget"></b-form-select>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center px-3 my-2" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
       <p class="mb-0">Fetching Data...</p>
    </div>
    <div v-else>
      <apexchart height="300px" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import moment from 'moment'
export default {
  mixins: [OfficeMixin],
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    filterBy: {
      dt: 'dental'
    },
    fetching: true,
    spendings: [],
  }),
  computed: {
    chartOptions(){
      return {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false,
          },
          type: "bar"
        },
        xaxis: {
          categories: this.spendings.map(s => moment(s.month, 'YYYY-MM-DD').format('MMM YYYY')) //['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yaxis: {
          labels: {
            formatter: (value) => `$${value}`
          },
        },
        fill: {
          colors: [function({ value, dataPointIndex, seriesIndex, w }) {
            if(seriesIndex == 0) return '#29bf12'
            return '#13ADD9'
          }]
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            show: true
          },
          y: {
            formatter: value => `$${value.toFixed(2)}`
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5
          },
        },
      }
    },
    series(){
      return [{
        name: 'Budget',
        data: this.budgets,
      },{
        name: 'Amount Spent',
        data: this.spends
      }]
    },
    dtOptions() {
      if(this.$store.state.OFFICE.officeBudget[this.officeId] != null){
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if(subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if(subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if(subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        })
      }
      return []
    },
    budgets(){
      return this.spendings.map(spending => {
        const subaccount = spending.subaccounts.find(acc => acc.slug == this.filterBy.dt)
        if(subaccount){
          let amount = Number(spending.adjusted_production || 0)
          if(spending.basis == 2) amount = Number(spending.collection || 0)
          return Number((amount * Number(subaccount.percentage || 0)) / 100)
        }
        return 0
      })
    },
    spends(){
      return this.spendings.map(spending => {
        const subaccount = spending.subaccounts.find(acc => acc.slug == this.filterBy.dt)
        if(subaccount){
          return Number(subaccount.spend || 0)
        }
        return 0
      })
    },
  },
  created(){
    this.fetchSpending()
  },
  methods: {
    ...mapActions('OFFICE', ['FETCH_BUDGET_VS_SPEND']),
    async fetchSpending(){
      try{
        this.spendings = await this.FETCH_BUDGET_VS_SPEND({
          office: this.officeId,
          start_month: '2021-01',
          end_month: '2021-12',
        })
        if(this.spendings.length == 0) return this.$emit('no-data')
      }catch(e){}
      this.fetching = false
    },
  },
}
</script>

<style lang="sass" scoped>
.budget-color-div
  width: 10px
  height: 10px
  background-color: #29bf12
  border-radius: 4px
.spent-color-div
  width: 10px
  height: 10px
  background-color: #13ADD9
  border-radius: 4px
.dt-options
  background: #fff url('../../assets/images/svg_icon/arrow.svg') right 0.75rem center/35px 20px no-repeat !important
  min-width: 227px
  height: 36px
</style>