<template>
  <div class="onboarding-layout">
    <div class="con py-5 d-flex flex-column align-items-center">
      <RouterLink to="/">
        <logo class="logo mb-5" />
      </RouterLink>
      <router-view></router-view>
    </div>
    <img src="@/assets/images/onboarding/login_forgot_onboarding.webp" alt="" class="b-image">
  </div>
</template>

<script>
import Logo from '@/components/logo/Logo'
export default {
  components: {
    Logo,
  },
  mounted(){
    fbq('track', 'AddToCart');
  },
}
</script>

<style lang="sass" scoped>
.onboarding-layout
  position: relative
  .con
    position: relative
    z-index: 10
  .b-image
    position: absolute
    height: 335px
    top: 250px
    width: 100vw
    @media screen and (max-width: 992px)
      display: none
.logo
  width: 160px
  height: 49px
</style>