<template>
  <div class="page-outer">
    <div class="page-contents mx-auto">
      <div class="vendors">
        <RouterLink class="!text-none" v-for="feature in features" :key="feature.title" :to="feature.to">
          <FeatureCard :feature="feature" />
        </RouterLink>
      </div>
    </div>
    <div class="mx-auto mt-5 pt-5" style="max-width: 900px;">
      <lan-9 style="padding: 0 !important;" />
    </div>
  </div>
</template>

<script>
import FeatureCard from './FeatureCard.vue'
import Lan9 from '@/components/landing/Lan9'
export default {
  components: { FeatureCard, Lan9, },
  data: () => ({
    features: [
      { backgroundImage: require('@/views/features/cover_images/six.svg'), image: require('@/components/landing/feature-images/price_comparison.png'), type: 'price_comparison', title: 'Price Comparison', to: '/feature-price-comparison', },
      { backgroundImage: require('@/views/features/cover_images/eight.svg'), image: require('@/components/landing/feature-images/tracking_budgets.png'), type: 'tracking_budgets', title: 'Budget Tracking', to: '/feature-tracking-budget', },
      { backgroundImage: require('@/views/features/cover_images/one.svg'), image: require('@/components/landing/feature-images/tracking_orders_returns.png'), type: 'tracking_orders_returns', title: 'Track Orders and Returns', to: '/feature-tracking-orders-returns', },
      { backgroundImage: require('@/views/features/cover_images/one.svg'), image: require('@/assets/images/future_cast.png'), type: 'future_cast', title: 'Futurecast', to: '/feature-futurecast', },
      { backgroundImage: require('@/views/features/cover_images/eight.svg'), image: require('@/components/landing/feature-images/order_approvals.png'), type: 'order_approvals', title: 'Order Approvals', to: '/feature-order-approvals', },
      { backgroundImage: require('@/views/features/cover_images/six.svg'), image: require('@/components/landing/feature-images/multi-vendor_orders.png'), type: 'multi-vendor_orders', title: 'Multi-vendor Ordering', to: '/feature-multivendor-ordering', },
      { backgroundImage: require('@/views/features/cover_images/four.svg'), image: require('@/components/landing/feature-images/imported_order_history.png'), type: 'imported_order_history', title: 'Imported Order History', to: '/feature-imported-order-history', },
      { backgroundImage: require('@/views/features/cover_images/two.svg'), image: require('@/components/landing/feature-images/customize_products.png'), type: 'customize_products', title: 'Customize Inventory', to: '/feature-customize-products', },
      { backgroundImage: require('@/views/features/cover_images/three.svg'), image: require('@/components/landing/feature-images/download_invoice.png'), type: 'download_invoice', title: 'Download Invoices', to: '/feature-download-invoices', },
      { backgroundImage: require('@/views/features/cover_images/one.svg'), image: require('@/components/landing/feature-images/analyze_supplies.png'), type: 'analyze_supplies', title: 'Analytics', to: '/feature-analyze-supplies', },
      { backgroundImage: require('@/views/features/cover_images/eight.svg'), image: require('@/components/landing/feature-images/open_dental.png'), type: 'open_dental', title: 'Syncs with Open Dental', to: '/feature-opendental-interation', },
      { backgroundImage: require('@/views/features/cover_images/five.svg'), image: require('@/components/landing/feature-images/multi-office_function.png'), type: 'multi-office_function', title: 'Multi-Office Functionality', to: '/feature-multioffice-functionality', },
    ],
  }),
}
</script>

<style lang="sass" scoped>
.page-outer
  padding: 5rem 1rem
.page-contents
  max-width: 900px
  .title-1
    font-family: GILROY-BOLD
    font-size: 24px
    color: #274458
  .subtitle-1
    font-family: GILROY-MEDIUM
    font-size: 16px
    color: #274458
  .cd-1
    font-family: GILROY-BOLD
    font-size: 16px
    color: #274458
    display: flex
    justify-content: center
    align-items: center
    background: #FFFFFF
    border-radius: 6px
    padding: 0.5rem 1rem
  .vendors
    display: grid
    grid-template-columns: repeat(3, minmax(0, 1fr))
    row-gap: 3rem
    column-gap: 1rem
    @media screen and (max-width: 992px)
      grid-template-columns: repeat(2, minmax(0, 1fr))
    @media screen and (max-width: 768px)
      grid-template-columns: repeat(1, minmax(0, 1fr))
</style>