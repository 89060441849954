import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
export default {
  mixins: [OfficeMixin],
  created(){
    this.setEvents()
  },
  data: () => ({
    searching: false,
    searchingMore: false,
    q: null,
    searchResult: [],
    fetchedSearchResult: [],
    searchPage: 1,
    inc: true,
    recentOrders: [],
    lastIndex: 0,
  }),
  computed: {
    filteredProducts(){
      return this.searchResult
    },
  },
  watch: {
    searching(v){
      this.$BUS.$emit('searching', v)
    },
    $route(v){
      this.searchResult = []
      this.fetchedSearchResult = []
      this.searchPage = 1
      this.inc = true
      this.q = v.query.q
      this.search()
    },
  },
  beforeDestroy(){
    this.$BUS.$off('search')
  },
  methods: {
    ...mapActions('PRODUCT', ['SEARCH_PRODUCTS', 'FETCH_OFFICE_PRODUCT', 'GET_PRICE_BY_PRODUCT_IDS', 'SET_SEARCH_COMPLETED']),
    ...mapActions('ORDER', ['FETCH_RECENT_ORDERS']),
    setEvents(){
      this.$BUS.$on('search', this.emitter)
    },
    emitter(v){
      this.inc = true
      this.q = v.q
      this.searchPage = 1
      this.searchResult = []
      this.search(v.filters)
    },
    async searchMore(){
      if(this.inc){
        this.searchingMore = true
        ++this.searchPage
        await this.search()
        this.searchingMore = false
      }
    },
    async search(filters = {}){
      this.searching = true
      try{
        let payload = {
          q: this.q,
          page: this.searchPage,
          office_id: this.officeId
        }
        let product_id = this.$route.query.product_id || filters.product_id
        let vendors = this.$route.query.vendors || filters.vendors
        let price_from = this.$route.query.price_from || filters.price_from
        let price_to = this.$route.query.price_to || filters.price_to

        if(product_id) payload.product_id = product_id
        if(vendors && vendors.trim().length > 0) payload.vendors = vendors.split(',')
        if(price_from != null) payload.price_from = price_from
        if(price_to) payload.price_to = price_to
        let res = await this.SEARCH_PRODUCTS(payload)
        // if(res.next_page == null) this.inc = false
        if(res.page_number  >= res.total_pages) {
          this.inc = false
        } else {
          res.page_number += 1;
        }
        let null_price_ids = []
        res.data = res.data.filter(p => (p._source.children && p._source.children.length > 0) || p._source.product_id != null || p._source.is_inventory)
        res.data.forEach(pr => {
          // if(pr.price == null) pr.price = pr.product_price
          // if(pr.price == null && pr.vendor != null && !pr.searched_data) {
          //   pr.fetching_price = true
          //   null_price_ids.push(pr.id)
          // }
          if(pr._source.children){
            pr._source.children.forEach(p => {
              if(p.price == null) p.price = p.product_price
              if(p.price == null && p.vendor != null) {
                p.fetching_price = true
                null_price_ids.push(p.id)
              }
            })
          }
        })

        if(null_price_ids.length > 0){
          this.GET_PRICE_BY_PRODUCT_IDS({
            office: this.officeId,
            products: null_price_ids
          }).then(pRes => {
            let ids = Object.keys(pRes).map(i => parseInt(i))
            for(let i = 0; i < this.searchResult.length; i++){
              let pr = this.searchResult[i]
              this.searchResult[i].fetching_price = false
              if(ids.includes(pr.id)){
                this.searchResult[i].price = pRes[pr.id].price
                if(pRes[pr.id].product_vendor_status) this.searchResult[i].product_vendor_status = pRes[pr.id].product_vendor_status
              }
              if(pr.children){
                for(let j = 0; j< pr.children.length; j++){
                  this.searchResult[i].children[j].fetching_price = false
                  let p = pr.children[j]
                  if(ids.includes(p.id)){
                    this.searchResult[i].children[j].price = pRes[p.id].price
                    if(pRes[p.id].product_vendor_status) this.searchResult[i].children[j].product_vendor_status = pRes[p.id].product_vendor_status
                  }
                }
              }
            }
            this.$forceUpdate()
          })
        }
        this.searchResult = this.searchResult.concat(res.data)
        if (this.searchResult.length > 0){
          this.SET_SEARCH_COMPLETED(true)
        }
        // let vendorsInSearch = res.data.vendor_slugs
        // this.$BUS.$emit('vendors-in-search', vendorsInSearch)
        // this.$forceUpdate()
      }catch(e){
        console.log(e)
      }
      this.searching = false
    },
  },
}