import { mapActions, mapGetters } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import NumberMixin from '@/mixins/NumberComma'
export default {
  data: () => ({
    fetchingOrderStats: true,
    fetchingBudgetStats: true,
    totalOrderValueInCart: 0,
    searchTo: 'dental',
  }),
  mixins: [ OfficeMixin, NumberMixin ],
  beforeDestroy(){
    this.$BUS.$off('total-order-value-in-cart')
  },
  created(){},
  computed: {
    ...mapGetters('OFFICE', ['stats']),
    remainingSpend(){
      if(this.searchTo == 'dental') return this.remainingSpendDental
      return this.remainingSpendFrontOffice
    },
    remainingSpendDental(){
      return this.getRemainingSpendOf('dental')
    },
    remainingSpendFrontOffice(){
      return this.getRemainingSpendOf('office')
    },
    budget(){
      return this.$store.state.OFFICE.officeBudget[this.officeId]
    },
    count(){
      // if(this.$store.state.OFFICE.officeBudget[this.officeId]) return this.$store.state.OFFICE.officeBudget[this.officeId].order
      return null
    },
  },
  methods: {
    searchToOptions(){
      if(this.$store.state.OFFICE.officeBudget[this.officeId]){
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if(subaccount.slug == 'dental') return { value: subaccount.slug, text: 'Dental Supply Budget' }
          else if(subaccount.slug == 'office') return { value: subaccount.slug, text: 'Front Office Supply Budget' }
          else if(subaccount.slug == 'miscellaneous') return { value: subaccount.slug, text: 'Uncategorized Budget' }
          else return { value: subaccount.slug, text: subaccount.name + ' Budget' }
        })
      }
    },
    setCartValEvent(){
      this.$BUS.$on('total-order-value-in-cart', v => {
        this.totalOrderValueInCart = v
        this.$forceUpdate()
      })
    },
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET']),
    async fetchCurrentBudget(filters){
      this.fetchingBudgetStats = true
      try{
        let payload = {office: this.officeId}
        if(filters) payload.filters = filters
        let res = await this.CURRENT_MONTH_BUDGET(payload)
        // this.budget = res.budget
      }catch(e){console.log(e)}
      this.fetchingBudgetStats = false
    },
    calculateBudget({ slug, officeId }){
      if(this.$store.state.OFFICE.officeBudget[officeId] == null) return 0
      const index = this.$store.state.OFFICE.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
      if(index > -1 && this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage){
        if(this.$store.state.OFFICE.officeBudget[officeId].basis == 1 && this.$store.state.OFFICE.officeBudget[officeId].adjusted_production) return ((NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].adjusted_production) * NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage))/100).toFixed(2)
        else if(this.$store.state.OFFICE.officeBudget[officeId].basis == 2 && this.$store.state.OFFICE.officeBudget[officeId].collection) return ((NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].collection) * NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage))/100).toFixed(2)
      }
    },
    getSpendOf(slug){
      if(this.$store.state.OFFICE.officeBudget[this.officeId] == null) return 0
      const index = this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.findIndex(a => a.slug == slug)
      if(index > -1){
        const spend = Number(this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts[index].spend || 0)
        return spend
      }
      return 0
    },
    getRemainingSpendOf(slug){
      if(this.$store.state.OFFICE.officeBudget[this.officeId] == null) return 0
      const index = this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.findIndex(a => a.slug == slug)
      if(index > -1){
        const budget = Number(this.calculateBudget({ slug, officeId: this.officeId }) || 0)
        const spend = Number(this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts[index].spend || 0)
        return budget - spend
      }
      return 0
    },
    getTotalBudget(slug){
      return Number(this.calculateBudget({ slug, officeId: this.officeId }) || 0)
    }

  },
}