<template>
  <div>
    <div class="d-card d-none d-lg-flex flex-column flex-lg-row justify-content-between py-2" v-if="!showMobMenu">
      <div class="d-1 pl-3">
        <dash-head-search />
      </div>
      <div class="d-2 pl-3">
        <remaining-budget-card :head="true" class="pl-lg-3" />
      </div>
      <div class="d-3 ps-3 d-flex justify-content-end gap-20px">
        <notification-card />
        <shopping-cart-card />
        <user-card />
      </div>
    </div>
    <div class="d-lg-none" v-if="showMobMenu">
      <div class="d-flex align-items-center justify-content-around d-card py-3 px-4 mb-2">
        <shopping-cart-card :mobile="true" />
        <notification-card />
        <user-card :mobile="true" />
      </div>
      <div class="d-card px-3 py-3">
        <dash-head-search class="dhsearch" />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationMenu from '@/components/dashboard/NotificationMenu'
import GoTo from '@/mixins/GoTo'
import UserCard from '@/components/dashboard/UserCard'
import NotificationCard from '@/components/dashboard/NotificationCard'
import ShoppingCartCard from '@/components/dashboard/ShoppingCartCard'
import CartContents from '@/components/cart/CartContents.vue'
import DashHeadSearch from '@/components/dashboard/DashHeadSearch'
import RemainingBudgetCard from '@/components/cart/RemainingBudgetCard'
import StatisticsMixin from '@/mixins/Statistics'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    NotificationMenu,
    UserCard,
    NotificationCard,
    ShoppingCartCard,
    CartContents,
    DashHeadSearch,
    RemainingBudgetCard,
  },
  mixins: [GoTo, StatisticsMixin],
  data: () => ({
    notfDrp: false,
    showMobMenu: false,
  }),
  created(){
    this.chkMob()
    window.onresize = this.chkMob
    if(this.stats == null) this.fetchOfficeBudgets()
    this.$BUS.$on('fetch-current-budget', this.fetchCurrentBudget)
    this.$BUS.$on('fetch-budgets', this.fetchOfficeBudgets)
  },
  computed: {
    ...mapGetters('USER', ['offices']),
  },
  beforeDestroy(){
    this.$BUS.$off('fetch-current-budget')
    this.$BUS.$off('fetch-budgets')
  },
  methods: {
    ...mapActions('OFFICE', ['FETCH_OFFICE_BUDGETS']),
    chkMob(){
      if(window.innerWidth <= 992){
        this.showMobMenu = true
      }else{
        this.showMobMenu = false
      }
    },
    async fetchOfficeBudgets(){
      this.$store.state.OFFICE.fetchingBudget = true
      await Promise.all(
        this.offices.map(office => new Promise((resolve, reject) => {
          this.FETCH_OFFICE_BUDGETS({ office: office.id }).then(res => resolve(res)).catch(err => reject(err))
        }))
      )
      this.$store.state.OFFICE.fetchingBudget = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'
.d-card
  // height: 77px
  // box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  &.dhsearch
    @media screen and (max-width: 992px)
      height: auto
.rm-card
  width: 211px
.d-1
  width: 51%
  // @media screen and (min-width: 992px)
  //   border-right: solid 1px #EBF4F7
.d-2
  width: 28%
  ::v-deep .head-card
    height: 46px
    .select-wrapper
      height: 36px
  // @media screen and (min-width: 992px)
  //   border-right: solid 1px #EBF4F7
.d-3
  width: 20%

.d-title__3
  @media screen and (max-width: 768px)
    font-size: 10px
  @media screen and (max-width: 350px)
    font-size: 8px
  @media screen and (max-width: 300px)
    display: none
.gap-20px
  gap: 20px
</style>