<template>
  <div class="d-head d-lg-flex align-items-center w-100">
    <div :class="['d-flex align-items-center h-100 w-100']">
      <div class="mb-2 mb-lg-0 d-prt d-flex align-items-center justify-content-between px-2">
        <span class="c-title">{{title}}</span>
        <b-btn class="custom-btn n-btn">View Team</b-btn>
      </div>
      <cart v-if="cart" />
      <b-btn :class="['custom-btn c-btn mr-3 mb-2 mb-lg-0', {'ml-auto': !cart}]" v-if="manageOrderBtn">Manage My Orders</b-btn>
    </div>
    <d-search :class="['search-box', {'ml-auto': !manageOrderBtn && !cart}]" v-if="searchBox" />
  </div>
</template>

<script>
import DSearch from '@/components/dashboard/DSearch'
import Cart from '@/components/Cart'
export default {
  props: {
    title: String,
    searchBox: {
      type: Boolean,
      default: true,
    },
    manageOrderBtn: {
      type: Boolean,
      default: false,
    },
    cart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DSearch,
    Cart,
  },
}
</script>

<style lang="sass" scoped>

.d-head
  .d-prt
    width: 100%
    height: 43px
    border-radius: 8px
    box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
    background-color: #ffffff
    .n-btn
      padding: 0
      height: 31px
      min-height: unset
      font-size: 12px
      width: 122px
  .c-title
    font-family: GILROY-SEMIBOLD
    font-size: 20px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    line-height: 1.2
    color: #000000
    @media screen and (max-width: 992px)
      font-size: 18px
  .search-box
    max-width: 432px
    width: 100%
    height: 43px
  .c-btn
    min-width: 189px
    height: 43px

</style>