<template>
  <div>
    <Button class="custom-btn sm-btn danger__2 mr-3" :onclick="() => onClickRemomveBudget(subaccount)" text="Remove" />
    <modal :modal="rmBudgetModal" @change="toggleRmBudgetModal" size="md">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <p class="modal-header-desc text-center mb-2">Deleting this budget will allocate any previous items in this budget to the Uncategorized Budget category and will no longer be able to see this budget from in your Dashboard. Do you wish to continue?</p>
      </div>
      <div class="dash mt-3 mb-3"></div>
      <div class="d-flex gap-16-px">
        <Button class="custom-btn danger__2 w-100 max-w-200-px mx-auto mt-3" :onclick="removeBudget" text="Yes" :submitting="removingBudget" />
        <Button class="custom-btn w-100 max-w-200-px mx-auto mt-3" :onclick="() => rmBudgetModal = false" text="No" />
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormMixin from '@/mixins/FormMixin.vue'
import Modal from '@/components/modals/Modal'
import Button from '@/components/ui/Button'
export default {
  props: {
    subaccount: Object,
    officeId: Number,
  },
  mixins: [ FormMixin ],
  components: {
    Modal, Button,
  },
  data: () => ({
    rmBudgetFormData: {
      substitute_slug: null,
    },
    submitting: false,
    removingBudget: false,
    rmBudgetModal: false,
  }),
  computed: {
    ...mapState('OFFICE', ['fetchingBudget', 'officeBudget']),
    budgets(){
      return this.officeBudget[this.officeId].subaccounts.filter(s => s.slug != this.subaccount.slug).map(s => ({ value: s.slug, text: s.name }))
    },
  },
  methods: {
    ...mapActions('OFFICE',['REMOVE_CUSTOM_BUDGET']),
    toggleRmBudgetModal(v){
      if(v != this.rmBudgetModal) this.rmBudgetModal = v != null ? v : false
    },
    async removeBudget(){
      if(this.removingBudget) return
      this.removingBudget = true
      try{
        const payload = {
          substitute_slug: "miscellaneous",
          subaccountId: this.subaccount.id,
          office: this.officeId
        }
        await this.REMOVE_CUSTOM_BUDGET(payload)
        this.$BUS.$emit('fetch-budgets')
        this.rmBudgetModal = false
      }catch(e){}
      this.removingBudget = false
    },
    onClickRemomveBudget(subaccount){
      this.rmBudgetModal = true
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
@import '../../style/settings'
</style>