<template>
  <div class="login-page d-flex flex-column align-items-center">
    <!-- <auth-logo class="mb-5" /> -->
    <reset-pass-card />
  </div>
</template>

<script>
import ResetPassCard from '@/components/auth/ResetPassCard'
import AuthLogo from '@/components/auth/AuthLogo'
export default {
  components: {
    ResetPassCard,
    AuthLogo,
  },
}
</script>

<style lang="sass" scoped>
.logo
  width: 50px
</style>