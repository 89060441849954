<template>
  <div :class="[{ 'd-card px-3 py-3': !head }, { 'd-flex align-items-center head-card': head }]">
    <div class="d-flex justify-content-between align-items-center" v-if="!head">
      <p class="mb-0 d-title">Remaining Spend</p>
      <b-dropdown right class="menu-drp ml-auto acc" ref="drp">
        <template #button-content>
          <div class="d-flex align-content-center">
          </div>
        </template>
        <div class="px-2">
          <p class="d-title__6">See Details</p>
        </div>
      </b-dropdown>
    </div>
    <div>
      <p :class="{ 'my-4': !head, 'mb-0 l-h-normal': head }">
        <skeleton-loader type="rect" :width="head ? '80px' : '109px'" :height="head ? '20px' : '28px'" :rounded="true"
          :radius="head ? 20 : 28" animation="wave"
          v-if="$store.state.OFFICE.fetchingBudget || $store.state.OFFICE.updatingBudget" class="d-inline-block" />
        <!-- <span
          :class="['d-title__8', { 'text-success': ((getRemainingSpendOf(searchTo) || 0) > 0), 'over-budget-txt': ((getRemainingSpendOf(searchTo) || 0) < 0), 'l-h-normal mr-2': head, }]"
          :style="{ 'font-size': head ? '16px' : '24px', }" v-else>${{ numberWithCommas((getRemainingSpendOf(searchTo) ||
            0).toFixed(2)) }}</span> -->
            <span
  :class="['d-title__8', { 'text-success': ((getRemainingSpendOf(searchTo) || 0) > 0), 'over-budget-txt': ((getRemainingSpendOf(searchTo) || 0) < 0), 'l-h-normal mr-2': head, }]"
  :style="{ 'font-size': head ? '16px' : '24px', }"
  v-else>
  {{ 
    (getRemainingSpendOf(searchTo) || 0) < 0 ? 
    '-' + '$' + numberWithCommas(Math.abs((getRemainingSpendOf(searchTo) || 0)).toFixed(2)) : 
    '$' + numberWithCommas((getRemainingSpendOf(searchTo) || 0).toFixed(2))
  }}
</span>
        <span class="d-title__9 ml-2" style="font-size: 10px; color: #788288; font-family: Gilroy-Medium;"
          v-if="!head">Remains for {{ currentMonth }}</span>
      </p>
      <p class="d-title__9 mb-0 l-h-normal" style="font-size: 8px; color: #788288; font-family: Gilroy-Medium;"
        v-if="head">
        {{ (getRemainingSpendOf(searchTo) || 0) < 0 ? 'Over Budget' : 'Budget Remaining' }} </p>
          <div class="d-flex justify-content-center align-items-center flex-column" v-if="!head">
            <b-btn class="custom-btn w-100" @click="goTo({ name: 'Orders' })">View Previous Orders / Activity</b-btn>
          </div>
    </div>
    <div class="select-wrapper ml-auto"
      v-if="head && !$store.state.OFFICE.fetchingBudget && !$store.state.OFFICE.updatingBudget">
      <b-select class="custom-input custom-select gilroy-b" :options="searchToOptions()" v-model="searchTo"></b-select>
    </div>
  </div>
</template>

<script>
import StatisticsMixin from '@/mixins/Statistics'
import GrowLoader from '@/components/loaders/GrowLoader'
import MonthMixin from '@/mixins/Month'
import GoTo from '@/mixins/GoTo'
import SkeletonLoader from 'skeleton-loader-vue'
export default {
  components: { GrowLoader, SkeletonLoader, },
  mixins: [StatisticsMixin, MonthMixin, GoTo],
  props: {
    head: Boolean,
  },
  created() {
    this.setCartValEvent()
  },
  computed: {
    overBudget() {
      if (this.remainingSpend < 0) return true
      return false
    },
  },
}
</script>

<style lang="sass" scoped>
.head-card
  border: solid 1px #ebeaeb
  background-color: #fafafa
  border-radius: 12px
  padding: 4px
.select-wrapper
  background-color: #13ADD9
  border-radius: 6px
  ::v-deep .custom-select
    max-width: 213px
    // min-width: max-content
    width: 100%
    height: 36px !important
    background-color: transparent
    border-color: transparent
    color: #FFFFFF
    text-overflow: ellipsis
    background: #13ADD9 url('../../assets/images/svg_icon/down_caret_white.svg') right 0.75rem center/15px 20px no-repeat
    @media screen and (max-width: 992px)
      width: auto
      max-width: 100px
.over-budget-txt
  color: #FF38C9
</style>