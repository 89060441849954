<template>
  <div class="onboarding-page w-100">
    <div class="d-flex justify-content-center align-items-center" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <p class="mb-0">Fetching Data...</p>
    </div>
    <div class="d-flex flex-column align-items-center" v-else>
      <onboarding-steps :currentStep="step" :completed="completed" @change="setStep" :fullCompletedSteps="[]" />
      <div class="active-step mt-4 w-100 d-flex justify-content-center">
        <account-setup-one @next="v=>setStep(2, true)" v-if="step == 1" />
        <office-details @next="v=>{setStep(3, true);}" :offices="offices" v-else-if="step == 2" />
        <billing-info v-else-if="step == 3" :offices="offices" />
      </div>
    </div>
    <div class="character-wrapper">
      <div class="character">
        <div class="msg-box" v-if="m">
          <div class="msg">
            <p class="m-title">{{m.title}}</p>
            <p class="m-desc mb-0">
              {{msgDescription}}
              <!-- <span v-if="showViewFullDesc && !viewFullDesc">...</span> -->
            </p>
            <!-- <p class="m-desc" v-if="showViewFullDesc">
              <span class="cursor-pointer text-primary" @click="viewFullDesc = !viewFullDesc">{{viewFullDesc ? 'View less' : 'View full message'}}</span>
            </p> -->
          </div>
          <!-- <msg-arrow /> -->
        </div>
        <div :class="['img-part', { 'd-flex justify-content-end': step != 1 && step != 6 }, step]">
          <!-- <component :is="image" /> -->
          <img v-if="step == 1" src="@/assets/images/onboarding/onboarding_one.png" alt="" class="w-100">
          <img v-else-if="step == 6" src="@/assets/images/onboarding/final_onboarding.png" alt="" class="w-100">
          <img v-else src="@/assets/images/onboarding/main_onboarding.png" alt="" class="mx-auto" style="height: 490px; width: 200px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingSteps from '@/components/onboarding/OnboardingSteps'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import BillingInfo from '@/components/onboarding/BillingInfo'
import AccountSetupOne from '@/components/onboarding/AccountSetupOne'
import AccountSetupTwo from '@/components/onboarding/AccountSetupTwo'
import InviteTeam from '@/components/onboarding/InviteTeam'
import ConnectAccount from '@/components/onboarding/ConnectAccount'
import OfficeBudget from '@/components/onboarding/OfficeBudget'
import { mapGetters, mapActions } from 'vuex'
import MsgArrow from '@/components/svg/onboarding/MsgArrow'
export default {
  head: {
    script: [
      { type: 'text/javascript', src: '/js/fbq_onboarding.js', async: true, body: true },
    ],
  },
  components: {
    OnboardingSteps,
    OfficeDetails,
    BillingInfo,
    AccountSetupOne,
    AccountSetupTwo,
    InviteTeam,
    ConnectAccount,
    OfficeBudget,
    MsgArrow,
  },
  data: () => ({
    step: 1,
    fetching: false,
    completed: [],
    members: [],
    // viewFullDesc: true,
  }),
  created(){
    this.checkCurrentOffice()
  },
  computed: {
    ...mapGetters('ONBOARDING', ['onboarding']),
    msgDescription(){
      let desc = this.m.desc// || (this.m.desc + '...')
      return desc
      if(desc){
        return !this.viewFullDesc ? desc.substr(0, 145) : desc
      }
      return null
    },
    showViewFullDesc(){
      if(this.m && this.m.desc){
        return this.m.desc.length > 250
      }
      return false
    },
    offices(){
      return this.onboarding && this.onboarding.offices ? this.onboarding.offices: []
    },
    image(){
      return 'One'
    },
    m(){
      switch(this.step){
        case 1:
          return { title: 'Hello and welcome to Ordo!', desc: 'Go ahead and enter your first and last name and create a password to get access to your office’s Ordo account.' }
        case 2:
          return { title: `Let's get your office details`, desc: 'Enter the office details for your dental practice. If you have additional offices, click “add another office” at the bottom of the page. Any office that has orders directly shipped to that location, as well as its own budget is considered a separate office.' }
        case 3:
          return { title: 'Time to subscribe!', desc: `We believe in clear and transparent pricing here at Ordo. You'll get access to all of our features for just $99/month per office.` }
      }
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['FETXH_COMPANY_WITH_OFFICES']),
    ...mapActions('OFFICE', ['TEAM_MEMBERS']),
    async checkCurrentOffice(){
      if(this.onboarding){
        this.fetching = true
        try{
          let res = await this.FETXH_COMPANY_WITH_OFFICES()
          if(res.data.on_boarding_step + 1 <= 3) this.step = res.data.on_boarding_step + 2
          for(let i = 1; i <= res.data.on_boarding_step + 2; i++){ this.completed.push(i) }
        }catch(e){}
        this.fetching = false
      }
    },
    async fetchMembers(){
      let members = await this.TEAM_MEMBERS({ company: this.onboarding.company.id })
      members.pop()
      this.members = members
    },
    setStep(val, completed){
      this.step = val
      if(completed && !this.completed.includes(val)) this.completed.push(val)
    },
    // setOffices(offices){
    //   this.offices = offices
    // },
  },
}
</script>

<style lang="sass" scoped>
.onboarding-page
  position: relative
  .character-wrapper
    display: none
    @media screen and (min-width: 1350px)
      display: block
    position: fixed
    bottom: 0
    // right: 40px
    left: 0
    .character
      position: relative
      .msg-box
        width: 320px
        min-height: 166px
        // border-radius: 15px
        // background-color: #fff
        position: absolute
        background-image: url(../assets/images/onboarding/Frame2.png)
        background-size: 100% 100%
        left: 10px
        bottom: 350px
        padding: 45px 30px 100px 45px
        .msg
          .m-title
            font-size: 17px
            font-family: GILROY-BOLD
            font-stretch: normal
            font-style: normal
            line-height: 1.2
            letter-spacing: normal
            text-align: left
            color: #000
          .m-desc
            font-size: 14px
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: 1.5
            letter-spacing: normal
            text-align: left
            color: #788288
        svg
          position: absolute
          left: 200px
          bottom: -34px
      .img-part
        width: 294px
        height: 390px
        position: relative
        bottom: -10px
        svg
          width: inherit
          height: inherit
</style>