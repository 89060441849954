<template>
  <div class="terms d-card py-4 px-4">
    <p class="d-title">Privacy Policy</p>
    <p class="d-title__13">Last Updated – April 30th, 2021</p>
    
    <p class="d-title">Overview</p>
    <p class="d-desc">This Privacy Statement informs you about the type of data that is collected and received through our Platform and website accessible at https://www.joinordo.com (“Ordo”), and about how we use, share, retain, and protect such data. </p>
    <p class="d-desc">We use your data to provide and improve our Services. By using Ordo, you agree to the collection and use of information in accordance with this Privacy Policy. </p>
    
    <p class="d-title">1. What kinds of information do we collect?</p>
    <p class="d-desc">As your authorized agent we collect different kinds of information about your business, company, clinic, group, or other entity you represent, as well as information about you, our user. </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Information you provide:</span> We collect the content and other information you provide when you use our Services, including when you sign up for an account. This can include text, as well as contextual information such as a date and time actions were taken. It also includes encrypted and hashed versions of account information from third parties that are provided by you and used by Ordo in the course of offering our Services. To make sure Ordo services are provided to the right user, we also collect account information including email address, user information, office location. </li>
      <li class="d-desc"><span class="d-title__13">Things you do:</span> As you use our Services, we collect information about the actions you take. This may include information about how you use our Services, such as when you search for or view products, or when you add products to your cart. This information may include text of queries, product IDs, or supplier IDs, as well as contextual information, such as when an action was taken, or how many times an action was taken. </li>
      <li class="d-desc"><span class="d-title__13">Purchase history:</span> As your authorized agent, Ordo retrieves on your behalf and stores information about your purchases with third-party websites that you’ve authorized Ordo to access. </li>
      <li class="d-desc"><span class="d-title__13">Device information:</span> We collect information from or about the computers, tablets, phones, or other devices where you access our Services. This may include attributes such as the operating system, hardware version, device settings, and device identifiers. It may also include device locations, including specific geographic locations, such as through GPS, Bluetooth, or WiFi signals, as well as connection information such as the name of your mobile operator or ISP, IP address, browser type, language, or time zone. </li>
    </ul>
    
    <p class="d-title">2. How do we use this information? </p>
    <p class="d-desc">We use all of the information we have to help us provide and support our Services. Here’s how: </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Provide our Services:</span> Using the information you provide, we are able to deliver our Services with personalized content that is intended to help your business. This includes information we retrieve as your authorized agent from third-party services that are  organized and displayed for you on Ordo, such as product pricing and availability information. The information you provide also allows us to send information back to third-party services, such as when you add an item to a third-party eCommerce cart. Additionally, information about how you use and interact with our services helps us personalize content to maximize the relevancy of our Services to you. For instance, your recently viewed or recently added to cart items are used in lists and recommendations to improve your experience with our Services. Other information collected during your use of the Services may be used, in aggregate with data from other users, to provide features of the Services, such as a display of popular or trending products. </li>
      <li class="d-desc"><span class="d-title__13">Improve our Services:</span> Information largely about how you use our Services is anonymized and used by the Ordo, from time to time, to improve our Services. For instance, how frequently features or user interface elements are used help us determine the merit of those features or interface elements, and helps us improve our Services for all users. We may test features in development, or issue voluntary surveys from time to time, the results of which will also be used to evaluate and improve our Services. Information about device information and geographic location are used primarily in aggregated form to improve future development of our services, or to troubleshoot bugs experienced by our users. </li>
      <li class="d-desc"><span class="d-title__13">Support the community:</span> Information you provide, especially contributions to Ordo discussions, are stored and displayed publicly for other users, with the intention of helping your peers improve their businesses. Aggregated and anonymized information about how our users collectively use our Services may be published or circulated to provide the community with insights on peer buying habits. </li>
      <li class="d-desc"><span class="d-title__13">Communicate with you:</span> We use your information to communicate with you about our Services, and let you know about our policies and terms. We also use your information to respond to you when you contact us. We may in the future use your information to send you marketing communications when you are opted-in to receive such communications in your communications preferences, such as manufacturer discounts, specials, and exclusive offers relevant to your business. </li>
      <li class="d-desc"><span class="d-title__13">Show and measure ads and services:</span> Ordo provides manufacturers, suppliers, and service providers the ability to display ads that are viewable in the course of using the Services. If and when Ordo begins to offer advertising or marketing services, we plan to use the information we have to improve our advertising and measurement systems so we can show you relevant ads on our Services and measure the effectiveness and reach of ads. </li>
      <li class="d-desc"><span class="d-title__13">Promote safety and security:</span> We use the information we have to help verify accounts and activity, and to promote safety and security on our Services, such as by investigating suspicious activity, unauthorized use of our Services, or violations of our terms or policies. We work hard to protect your account information and the privacy of your connected accounts using teams of engineers, automated systems, and advanced technology such as encryption and machine learning. </li>
    </ul>
    <p class="d-desc">Like most web services, Ordo uses cookies and similar technologies to provide and support our Services and each of the uses outlined and described in this section of our policy. </p>
    
    <p class="d-title">3. How do we share this information? </p>
    <p class="d-desc">We may share personal information in the following situations: </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Third-party supplier integrations:</span> When you’ve authorized us to connect your third-party supplier accounts to Ordo, select information is shared with those services, including items added to or removed from carts, or quantities desired changed in carts. All information shared with or received from these third parties is used exclusively to provide our Services. When you authorize Ordo to share your information with third party suppliers, your information will be shared in the same manner as if you were using such third party suppliers services directly. We do not reveal your use of Ordo to third party suppliers without your consent. </li>
      <li class="d-desc"><span class="d-title__13">Sharing within Ordo:</span> We share information we have about you within Ordo to provide and improve our Services. Some information, such as login information to third-party services, is not viewable or accessible by Ordo since it is transmitted to us in code and stored in an encrypted and hashed format. </li>
      <li class="d-desc"><span class="d-title__13">New authorized user of account:</span> If due to personnel changes your account needs to be transferred to a new authorized representative at your businesses, some account information may be shared with the new representative at the discretion of Ordo to preserve the integrity of the account. </li>
      <li class="d-desc"><span class="d-title__13">New owner:</span> If the ownership or control of all or part of our Services or their assets changes, we may transfer your information to the new owner. </li>
    </ul>
    <p class="d-title__13">Possible Future Sharing With Third-Party Partners and Customers </p>
    <p class="d-desc">Ordo does not share any information with any third-party partners aside from the systems of those third-party suppliers whose accounts you have authorized us to connect to Ordo, as mentioned in the previous section. </p>
    <p class="d-desc">However, in the future Ordo may work with third party companies to help us provide and improve our Services or who use advertising or related products. Here are the types of third parties we may, at some point in the future, share information with about you: </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Advertising, Measurement and Analytics Services (Non-Personally Identifiable Information Only):</span> We want our advertising to be as relevant and interesting as the other information you find on our Services. With this in mind, we would use all of the information we have about you to show you relevant ads. We will not share information that personally identifies you (personally identifiable information is information like name or email address that can by itself be used to contact you or identifies who you are) with advertising, measurement or analytics partners unless you give us permission. We may provide these partners with information about the reach and effectiveness of their advertising without providing information that personally identifies you, or if we have aggregated the information so that it does not personally identify you. For example, we may tell an advertiser how its ads performed, or how many people viewed their ads, or provide non-personally identifying demographic information to these partners to help them understand their audience or customers, but only after the advertiser has agreed to abide by the advertiser guidelines that govern all advertising on Ordo. </li>
      <li class="d-desc"><span class="d-title__13">Vendors, service providers and other partners (Non-Personally Identifiable Information Only):</span> In the future, we may transfer information to vendors, service providers, and other partners who globally support our business, such as providing technical infrastructure services, analyzing how our Services are used, measuring the effectiveness of ads and services, providing customer service, facilitating payments, or conducting academic research and surveys. These partners must adhere to strict confidentiality obligations in a way that is consistent with this Privacy Policy and the agreements we enter into with them. </li>
    </ul>
    
    <p class="d-title">4. How do we protect personal data? </p>
    <p class="d-desc">We maintain appropriate technical and organizational security safeguards designed to protect your personal data against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use. However, due the inherent open nature of the Internet, we cannot guarantee that communications between you and Ordo or the personal information stored is absolutely secure. We will notify you of any data breach that is likely to have unfavorable consequences for your privacy in accordance with applicable law. </p>
    
    <p class="d-title">5. How can you manage or delete your data? </p>
    <p class="d-desc">You can manage the information when you use Ordo Platform through your My Account Settings. You’ll be able to see what you’ve mentioned and can modify the same, You’ll also be able to unlink third-party supplier accounts you’ve connected to Ordo. </p>
    <p class="d-desc">We store data for as long as it is necessary to provide products and services to you and others, including those described above. Information associated with your account will be kept until your account is deleted, unless we no longer need the data to provide products and services. </p>
    <p class="d-desc">You can delete your account by contacting us by email at hello@joinordo.com. When you delete your account, we may delete all the past data or any activity. If you do not want to delete your account, but want to temporarily stop using Ordo, you may stop using Ordo at any time, and your account information will be preserved. </p>
    
    <p class="d-title">6. How do we respond to legal requests? </p>
    <p class="d-desc">Government agencies can ask Ordo to disclose user information in response to a legal request. We carefully review each request to make sure it satisfies applicable laws. If a request asks for too much information, we try to narrow it down. </p>
    <p class="d-desc">This may include responding to legal requests from jurisdictions outside of the United States where that response is required by law in that jurisdiction. </p>
    <p class="d-desc">We may also access, preserve and share information when it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations. </p>
    
    <p class="d-title">7. Links to Other Websites </p>
    <p class="d-desc">Our Service may contain links to other websites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. </p>
    <p class="d-desc">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services. </p>
    
    <p class="d-title">8. Updates to this Privacy Policy </p>
    <p class="d-desc">We may update this Privacy Statement from time to time. We will notify you of any significant changes to this Privacy Statement on the website or through other appropriate communication channels. All changes shall be effective from the date of publication, unless otherwise provided in the notification. </p>
    <p class="d-desc">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>
    
    <p class="d-title">9. Contact Us</p>
    <p class="d-desc">If you have any questions about this Privacy Policy, You can contact us: </p>
    <p class="d-desc">· By email: hello@joinordo.com </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>


</style>