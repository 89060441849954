<template>
  <div class="d-md-flex">
    <div>
      <div class="invite-team">
        <validation-observer ref="invObs" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(next)">
            <p class="c-title">Invite Your Team</p>
            <div v-for="(member, mI) in formData" :key="mI" class="mb-3">
              <div class="dash my-4" v-if="mI > 0"></div>
              <b-row>
                <b-col cols="8">
                  <!-- <validation-provider name="Name" rules="required" v-slot="validationContext"> -->
                    <p class="form-label mb-1">Name</p>
                    <!-- <b-input v-model="formData[mI].email" class="custom-input" placeholder="Enter Team Member Name" :state="getValidationState(validationContext)"></b-input> -->
                    <b-input class="custom-input" placeholder="Enter Team Member Name"></b-input>
                    <!-- <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback> -->
                  <!-- </validation-provider> --> 
                </b-col>
                <b-col cols="4">
                  <p class="form-label mb-1">Role</p>
                  <b-form-select v-model="formData[mI].role" :options="formData[mI].role !== 3 ? superRoleOptions : roleOptions" class="custom-input" placeholder="User Role"></b-form-select>
                </b-col>
                <b-col cols="6" class="mt-md-3">
                  <validation-provider name="Email" rules="email" v-slot="validationContext">
                    <p class="form-label mb-1">Email Address</p>
                    <b-input v-model="formData[mI].email" class="custom-input" placeholder="Enter team member email" :state="getValidationState(validationContext)"></b-input>
                    <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mt-md-3">
                  <p class="form-label mb-1">Assigned Office</p>
                  <multiselect v-model="formData[mI].offices" :options="officeOptions" :multiple="true" class="custom-input" placeholder="Office"></multiselect>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <p class="mb-0 form-label cursor-pointer gilroy-b" style="color: #FF1DC3" @click="addMemberObj">+ Add Team Members</p>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center mt-4">
              <Button text="Save and Continue" class="o-btn" :submitting="submitting" :onclick="next" />
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div class="mt-3">
        <p class="text-danger__2 text-center f-12-px gilroy-b cursor-pointer" @click="skipStep">
          <b-spinner style="width: 12px; height: 12px;" class="mr-2" v-if="skippingStep"></b-spinner>
          Skip Step
        </p>
      </div>
    </div>
    <div class="ml-md-4" v-if="prevl">
      <div class="invite-team__2 mb-3">
        <p class="mb-2 iv2-title">User Privileges</p>
        <ul>
          <li>Link vendors</li>
          <li>Search for items</li>
          <li>Price compare</li>
          <li>Place orders</li>
          <li>Process returns</li>
          <li>See budget</li>
          <li>Organize Inventory Categories</li>
          <li>Get Notifications</li>
        </ul>
      </div>
      <div class="invite-team__2">
        <p class="mb-2 iv2-title">Admin Privileges:</p>
        <p class="mb-0 iv2-desc__2">User Privileges plus:</p>
        <ul>
          <li>Set/adjust budgets</li>
          <li>Create custom budgets</li>
          <li>Approve/deny orders</li>
          <li>Create custom inventory categories</li>
          <li>Invite team members</li>
          <li>Get monthly reports</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'
import GoTo from '@/mixins/GoTo'
import FormMixin from '@/mixins/FormMixin'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  props: {
    offices: {
      type: Array,
      default: []
    },
    members: {
      type: Array,
      default: []
    },
    prevl: Boolean,
    skippingStep: Boolean,
  },
  components: {
    Input,
    Button,
    Multiselect: Treeselect,
  },
  mixins: [FormMixin, GoTo],
  data: () => ({
    formData: [],
    superRoleOptions: [
      { text: "Admin", value: 1 },
      { text: "Manager", value: 3 },
      { text: "User", value: 2 },
    ],
    roleOptions: [
      { text: "Admin", value: 1 },
      { text: "Manager", value: 3 },
      { text: "User", value: 2 },
    ],
    fD: {
      offices: null,
      email: null,
      role: 1
    },
    submitting: false,
  }),
  created(){
    this.members.forEach(member => {
      let index = this.formData.findIndex(f => f.email == member.email)
      if(index >= 0){
        this.formData[index].offices.push(member.office)
      }else{
        this.formData.push({
          ...member,
          offices: [member.office],
        })
      }
    })
    this.addMemberObj()
  },
  computed:{
    officeOptions(){
      return this.offices.map(o => ({ id: o.id, label: o.name }))
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['INVITE_TEAM_MEMBER', 'ONBOARDING_CREATE_OFFICES', 'FETCH_ONBOARDING_STATUS']),
   
    skipStep() {
      console.log('skipping step');
      this.skippingStep = true; // Show spinner while processing
      this.$emit('skip-step'); 
      this.skippingStep = false;// Emit an event to the parent component
    },
   
    async goNext(){
      let save = true
      this.formData.forEach(f => {
        for(let key in f){
          if(f.email != null && f.email.trim().length > 0) save = false
        }
      })
      if(!save) {
        await this.next()
        this.$emit('next')
      }else this.$emit('next')
    },
    async next(){
      if(this.submitting) return
      this.submitting = true
      let isValid = await this.$refs.invObs.validate()
      if(isValid){
        try{
          let payload = {
            members: this.formData.filter(f => 
            f.email != null && f.email.trim().length > 0
            && f.id == undefined
            )
          }
          if(this.$route.name == 'OnBoarding' || this.$route.name == 'Dashboard') payload.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
          if(payload.members.length == 0) {
            await this.ONBOARDING_CREATE_OFFICES(payload)
            await this.FETCH_ONBOARDING_STATUS({ team_invited: true })
          }else{
            await this.INVITE_TEAM_MEMBER(payload)
            await this.FETCH_ONBOARDING_STATUS({ team_invited: true })
            this.$emit('invited')
            this.$forceUpdate()
          }
          this.$store.state.ONBOARDING.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
          this.$emit('next')
        }catch(e){}
      }
      this.submitting = false
    },
   
    async addMemberObj(){
      if(
        this.formData.length == 0 
        || (this.formData[this.formData.length - 1].email != null 
          && this.formData[this.formData.length - 1].email.trim().length > 0
        )
      ) {
        let obj = {...this.fD}
        if(this.offices.length == 1){
          obj.offices = [this.offices[0].id]
        }
        this.formData.push(obj)
      }
      else await this.$refs.invObs.validate()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
@import '../../style/variables'
::v-deep .vue-treeselect
  .vue-treeselect__control
    border: none
  .vue-treeselect__placeholder, .vue-treeselect__single-value
    font-family: GILROY-MEDIUM
    font-size: 12px
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: normal
    color: #707070
    display: flex
    align-items: center
  .vue-treeselect-helper-hide
    display: none !important
.invite-team
  width: 600px
  padding: 32px
  border-radius: 8px
  background-color: #ffffff
  height: min-content
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  &__2
    @extend .invite-team
    width: 220px
    .iv2-title
      font-family: GILROY-BOLD
      font-size: 14px
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      text-align: left
      color: #5fbc3b
    .iv2-desc
      font-size: 12px
      font-weight: 500
      font-stretch: normal
      font-style: normal
      line-height: 2
      letter-spacing: normal
      text-align: left
      color: #707070
      &__2
        @extend .iv2-desc
        font-weight: 600
        // color: #e941bf
    ul
      padding: 0
      padding-left: 13px
      margin-bottom: 0
      li
        padding-left: 3px
        font-size: 12px
        font-weight: 500 
        font-stretch: normal
        font-style: normal
        line-height: 2
        letter-spacing: normal
        text-align: left
        color: #707070
        &::marker
          font-size: 14px
  @media screen and (max-width: 992px)
    width: 100%
</style>