<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 900px;">Track your Order and Returns</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Stay Organized and Efficient by being able to track your orders and returns from start to finish</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Track your Orders in Real-Time</p>
        <p class="subtitle-3 mb-3">With Ordo, you will know where your orders are at any given time. You can track them from when they are processed, to when they are shipped, and when they are expected to be delivered. Finally, you can mark your items as received once they’ve arrived. Remove the second section titled Mark Items received</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Tracking_Orders/tracking_orders_1.png" alt="" style="max-width: 100%">
        </p>
        <p class="title-2">Return Items with Ease</p>
        <p class="subtitle-3 mb-3">Ordo simplifies the return process by allowing you to initiate any return directly from Ordo. We notify the vendor for you via email and they will send you the information needed to process the return.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Tracking_Orders/tracking_orders_2.png" alt="" style="max-width: 200px">
        </p>
        <p class="title-2">Hassle-Free Returns - Simplify Your Returns Process</p>
        <p class="subtitle-3 mb-3">Our hassle-free returns feature allows you to initiate returns with ease. With a few clicks, you can notify the vendor which items you need to return instead of having to wait on hold for a representive or your dental rep to pick up the items.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Tracking_Orders/tracking_orders_3.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/eleven.svg)
</style>