<template>
    <div class="card overflow-hidden" @click="redirectToGreetings">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h3 class="mb-2 fw-semibold">{{ number }}</h3>
                    <p class="text-muted fs-13 mb-0">{{ title }}</p>
                </div>
                <div>
                    <div class=" mb-0">
                        <b-icon :icon=icon animation="throb" font-scale="3" :style="{ 'color': iconColor }"></b-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { BIcon, BIconArrowUp, BIconStarFill, BIconAwardFill, BIconArrowDown } from 'bootstrap-vue'

export default {
    data: {

    },
    components: {
        BIconStarFill,
        BIcon,
        BIconArrowUp,
        BIconArrowDown,
        BIconAwardFill

    },
    props: {
        title: String,
        number: Number,
        iconColor: String,
        icon: String
    },
    methods: {
        redirectToGreetings() {
        },
    },
};
</script>

<style scoped>
.card {
    width: 340px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: #13ADD9;
    color: white;
    padding: 15px;
    font-size: 13px;
    text-align: center;
}

.card-body {
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}

.circle-background {
    background-color: #13ADD9;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.rounded-background {
    background-color: #13ADD9;
    border-radius: 16px;
    width: 130px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.number {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin: 0;
}

.center-text {
    text-align: center;
}

.title,
.subTitle {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

  