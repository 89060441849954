<template>
  <div class="">
    <div class="d-flex gap-20-px justify-content-between align-items-center flex-wrap mb-3">
      <p class="mb-0 gilroy-b d-title" v-if="!hideCategoryTitle">{{category ? category.id : ''}}</p>
      <!-- <div class="d-flex gap-10-px align-items-center ml-auto" v-if="filterBy.dateRange != 'thisMonth'">
        <p class="f-12-px mb-0">Current Month View</p>
        <b-form-checkbox switch class="custom-chk" v-model="currentMonthView"></b-form-checkbox>
      </div> -->
    </div>
    <div class="table-section">
      <div class="table-1 mb-4">
        <b-table :fields="fields" :items="items" :busy="fetchingData" class="mb-0">
          <template v-slot:cell(Category)="{ item }">
            <div class="inner">
              <div :title="item.Category">{{item.Category}}</div>
            </div>
          </template>
          <template v-slot:cell(Description)="{ item }">
            <div class="inner">
              <b-tooltip :target="`tooltip-target-item-desc-${item.Code}`" triggers="hover focus" placement="top" boundary="document" custom-class="promotion-tooltip">
                <p class="d-desc mb-0">{{ item.Description }}</p>
              </b-tooltip>
              <div :id="`tooltip-target-item-desc-${item.Code}`">{{item.Description}}</div>
            </div>
          </template>
          <template v-slot:cell(Code)="{ item }">
            <div class="inner">
              <div :title="item.Code">{{item.Code}}</div>
            </div>
          </template>
          <template v-slot:cell(complete)="{ item }">
            <div class="inner">
              <div :title="item.complete" class="text-center w-100">{{item.complete || 0}}</div>
            </div>
          </template>
          <template v-slot:cell(schedule)="{ item }">
            <div class="inner">
              <div :title="item.schedule" class="text-center w-100">{{item.schedule || 0}}</div>
            </div>
          </template>
          <template v-slot:cell(total)="{ item }">
            <div class="inner">
              <div :title="item.total" class="text-center w-100">{{item.total || 0}}</div>
            </div>
          </template>
          <template v-slot:cell(avg_count)="{ item }">
            <div class="inner">
              <div :title="item.avg_count" class="text-center w-100">{{item.avg_count || 0}}</div>
            </div>
          </template>
          <template v-slot:cell(trend)="{ item }">
            <div class="inner text-center">
              <svg data-v-754af902="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="caret down fill" xmlns="http://www.w3.org/2000/svg" fill="#29bf12" class="bi-caret-down-fill mx-auto b-icon bi text-primary" style="font-size: 1.2rem; rotate: 180deg;" v-if="isUp('total', item)">
                <g data-v-754af902="">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                </g>
              </svg>
              <svg data-v-754af902="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="caret down fill" xmlns="http://www.w3.org/2000/svg" fill="#FF1DC3" class="bi-caret-down-fill mx-auto b-icon bi text-primary" style="font-size: 1.2rem" v-else-if="isDown('total', item)">
                <g data-v-754af902="">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                </g>
              </svg>
            </div>
          </template>
          <template #table-busy>
            <div>
              <GrowLoader />
            </div>
          </template>
        </b-table>
      </div>
      <!-- <div class="d-flex justify-content-center align-items-center gap-4-px" v-if="res.length > 5">
        <b-spinner small class="text-primary h-10-px w-10-px" style="border-width: 2px;" v-if="loadingMore"></b-spinner>
        <p class="text-center text-primary mb-0 f-10-px cursor-pointer l-h-1" @click="viewAll = !viewAll">
          <span>View {{ viewAll ? 'Less' : 'All' }}</span>
        </p>
      </div> -->
      <b-table :fields="fields" :items="totalRowTableItems" v-if="!fetchingData" class="mb-0 mt-4 total-row-table">
        <template v-slot:cell(Code)>
          <div class="last-row">
            <div class="last-row-inner"></div>
          </div>
        </template>
        <template v-slot:cell(Description)>
          <div class="last-row">
            <div class="last-row-inner">
              <p class="mb-0 total-row-table-text text-right">Total</p>
            </div>
          </div>
        </template>
        <template v-slot:cell(complete)>
          <div class="last-row">
            <div class="last-row-inner">
              <p class="mb-0 total-row-table-text text-center w-100">{{ getTotalOf('complete') }}</p>
            </div>
          </div>
        </template>
        <template v-slot:cell(schedule)>
          <div class="last-row">
            <div class="last-row-inner">
              <p class="mb-0 total-row-table-text text-center w-100">{{ getTotalOf('schedule') }}</p>
            </div>
          </div>
        </template>
        <template v-slot:cell(total)>
          <div class="last-row">
            <div class="last-row-inner">
              <p class="mb-0 total-row-table-text text-center w-100">{{ getTotalOf('total') }}</p>
            </div>
          </div>
        </template>
        <template v-slot:cell(avg_count)>
          <div class="last-row">
            <div class="last-row-inner">
              <p class="mb-0 total-row-table-text text-center w-100">{{ getTotalOf('avg_count') }}</p>
              <!-- <p v-if="filterBy.dateRange !== 'thisWeek'" class="mb-0 total-row-table-text text-center w-100">{{ getTotalOf('avg_count') }}</p> -->
 
            </div>
          </div>
        </template>
        <template v-slot:cell(trend)>
          <div class="last-row">
            <div class="last-row-inner pr-3">
              <svg data-v-754af902="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="caret down fill" xmlns="http://www.w3.org/2000/svg" fill="#29bf12" class="bi-caret-down-fill mx-auto b-icon bi text-primary" style="font-size: 1.2rem; rotate: 180deg;" v-if="isTotalUpFromAvg">
                <g data-v-754af902="">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                </g>
              </svg>
              <svg data-v-754af902="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="caret down fill" xmlns="http://www.w3.org/2000/svg" fill="#FF1DC3" class="bi-caret-down-fill mx-auto b-icon bi text-primary" style="font-size: 1.2rem" v-else-if="isTotalDownFromAvg">
                <g data-v-754af902="">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                </g>
              </svg>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import PolyGonIcon from '@/components/svg/icons/PolyGonIcon.vue'
import { mapActions, mapState } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import GrowLoader from '@/components/loaders/GrowLoader'
import moment from 'moment'

export default {
  components: { PolyGonIcon, GrowLoader, },
  mixins: [ OfficeMixin ],
  props: {
    category: Object,
    hideCategoryTitle: Boolean,
    summaryCategory: Boolean,
  },
  data: () => ({
    fetchingData: true,
    loadingMore: false, 
    isCurrentWeek: false,
    filter: {
      type: 'month',
      date_range: 'nextWeek',
      // limit: 5,
      category: null,
    },
    res: [],
    viewAll: false,
    currentMonthView: false,
  }),
  created(){
    if(this.category){
      this.filter.category = this.category.id
    }
    this.filter.date_range = this.filterBy.dateRange
    this.filter.from = this.filterBy.from
    this.filter.to = this.filterBy.to
    if(this.filterBy.dateRange == 'thisMonth'){
      this.currentMonthView = true
    }
    this.fetchData()
  },
  watch: {
    currentMonthView(v){
      if(v){
        this.filter.type = 'week'
        this.filter.date_range = 'thisMonth'
        this.fetchData()
      }else {
        this.filter.type = 'month'
        this.filter.date_range = this.filterBy.dateRange
        this.filter.from = this.filterBy.from
        this.filter.to = this.filterBy.to
        this.fetchData()
      }
    },
  },
  computed: {
    ...mapState('PROCEDURE', ['filterBy']),
    fields(){
      return [
        // { key: 'Category', label: 'Category', tdClass: 'text-left' },
        { key: 'Code', label: 'Code' },
        { key: 'Description', label: 'Description', tdClass: 'td-description' },
        { key: 'complete', label: 'Completed', tdClass: 'min-w-110-px max-w-110-px', thClass: 'text-center' },
        { key: 'schedule', label: 'Scheduled', tdClass: 'min-w-110-px max-w-110-px', thClass: 'text-center' },
        { key: 'total', label: 'Total', tdClass: 'min-w-90-px max-w-90-px', thClass: 'text-center' },
        { key: 'avg_count', label: 'Average', tdClass: 'min-w-90-px max-w-90-px', thClass: 'text-center' },
        { key: 'trend', label: 'Trend', tdClass: 'min-w-90-px max-w-90-px', thClass: 'text-center' },
      ]
    },
    items(){
      return this.res
    },
    isTotalUpFromAvg(){
      return this.getTotalOf('total') > this.getTotalOf('avg_count')
    },
    isTotalDownFromAvg(){
      return this.getTotalOf('total') < this.getTotalOf('avg_count')
    },
    totalRowTableItems(){
      return [
        {
          Code: null,
          Description: null,
          complete: null,
          schedule: null,
          total: null,
          avg_count: null,
          trend: null,
        }
      ]
    },
  },
  methods: {
    ...mapActions('PROCEDURE', ['FETCH_PROCEDURE_REPORT', 'FETCH_PROCEDURE_REPORT_SUMMARY']),
    getTotalOf(key){
      return this.res.map(r => r[key]).reduce((a, b) => a + b, 0)
    },
    isUp(key, item){
      if(key === 'total'){
        if(item['total'] > item['avg_count']) return true
      }
      return false
    },
    isDown(key, item){
      if(key === 'total'){
        if(item['total'] < item['avg_count']) return true
      }
      return false
    },
    async fetchData(loading = true){
      this.fetchingData = loading
      try{
        let payload = {
          office: this.officeId,
          filters: this.filter,
        }
        if(this.summaryCategory){
          payload.filters.summary_category = this.category.slug
          delete payload.filters.category
          // delete payload.filters.date_range
          delete payload.filters.type
          let res = await this.FETCH_PROCEDURE_REPORT_SUMMARY(payload)
          let arr = []
          for(let key in res){
            arr.push(res[key])
          }
          this.res = arr
        }else {
          this.res = await this.FETCH_PROCEDURE_REPORT(payload)
        }
      }catch(e){console.log(e)}
      this.fetchingData = false
    },
    async toggleViewAll(){
      if(this.loadingMore) return
      if(this.filter.limit === 5){
        this.filter.limit = 20
      }else {
        this.filter.limit = 5
      }
      this.loadingMore = true
      await this.fetchData(false)
      this.loadingMore = false
    },
  },
}
</script>

<style lang="sass" scoped>

$color-1: #2C495C
.table-1
  max-height: 373px
  overflow-y: auto
::v-deep .total-row-table
  thead
    display: none
.table-title
  color: $color-1

::v-deep .table-section
  table
    th
      background-color: #ebf4f8
      border-top: none
      border-bottom: none
      font-family: GILROY-BOLD
      font-size: 12px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      color: #1c455a
      padding-top: 10px
      padding-bottom: 10px
      &:first-child
        border-top-left-radius: 4px
        border-bottom-left-radius: 4px
      &:last-child 
        border-top-right-radius: 4px
        border-bottom-right-radius: 4px
    td
      border-top: none
      border-bottom: none
      padding: 12px 0 0 0
      font-size: 12px
      font-weight: 500
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #000000
      .inner
        cursor: pointer
        padding: 12px
        border-top: solid 1px #E7ECEE
        border-bottom: solid 1px #E7ECEE
        height: 55px
        display: flex
        align-items: center
      &:first-child
        min-width: 85px
        max-width: 85px
        .inner
          border-left: 1px solid #E7ECEE
          border-top-left-radius: 4px
          border-bottom-left-radius: 4px
      &:last-child
        .inner
          border-right: 1px solid #E7ECEE
          border-top-right-radius: 4px
          border-bottom-right-radius: 4px
      &.td-description
        min-width: 280px
        max-width: 280px
        @media screen and (max-width: 768px)
          min-width: 128px
          max-width: 128px
        .inner > div
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
    tbody
      tr
        &:first-child
          td
            padding-top: 0.75rem
::v-deep .total-row-table
  tbody
    tr
      &:first-child
        td
          padding-top: 0 !important
      td
        &:first-child
          .last-row-inner
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
        &:last-child
          .last-row-inner
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
        .last-row
          .last-row-inner
            display: flex
            justify-content: flex-end
            align-items: center
            padding: 0.5rem calc( 0.75rem + 2px )
            height: 38px
            background-color: #EDF4F7
            font-family: GILROY-BOLD
            font-size: 12px
            font-weight: 600
            font-stretch: normal
            font-style: normal
            color: #1c455a
            line-height: 1
            
</style>