 <template>
  <div class="notification-settings d-card py-3 px-3">
    <p class="d-title mt-2 mb-1" style="font-size: 18px;">Order Approval Settings</p>
    <p class="mb-4 d-desc__m551">Set your order approval settings for each admin. We send notifications through Ordo as well as emails. Toggle the approvals on or off based on your office needs</p>
    <div class="d-flex flex-column flex-lg-row flex-wrap justify-content-between align-items-center mb-2">
      <p class="mb-0 d-title__2" style="font-size: 16px;">
        <span v-if="role == 1">Admin</span>
        <span v-else>User</span>
        <span> : </span>
        <span>{{fullName}}</span>
      </p>
      <p class="d-desc mb-0 d-flex gap-12-px align-items-center" :class="[ !enable_order_approval && 'pr-2-px' ]">
        <span v-if="enable_order_approval">Order approvals are currently enabled. Toggle the switch to the left to disable them.</span>
        <span v-else>Order approvals are currently disabled. Toggle this switch to enable them.</span>
        <b-form-checkbox v-model="enable_order_approval" switch class="custom-chk mr-2" @change="onChange"></b-form-checkbox>
      </p>
    </div>
    <div class="check-switch-box mb-2 pl-3 pr-2" :class="[ !enable_order_approval && 'disabled' ]">
      <p class="mb-0 d-title__4">All orders require approval</p>
      <b-form-checkbox :value="'ALL'" :unchecked-value="null" v-model="approvalType" switch class="custom-chk" @change="onChange" :disabled="!enable_order_approval"></b-form-checkbox>
    </div>
    <div class="check-switch-box mb-2 pl-3 pr-2" :class="[ !enable_order_approval && 'disabled' ]">
      <p class="mb-0 d-title__4">Only orders over budget require approval</p>
      <b-form-checkbox :value="'ALL_EXCEPT_OVERBUDGET'" :unchecked-value="null" v-model="approvalType" switch class="custom-chk" @change="onChange" :disabled="!enable_order_approval"></b-form-checkbox>
    </div>
    <!-- <div class="check-switch-box mb-2 pl-3 pr-2">
      <div class="d-md-flex align-items-center">
        <p class="mb-0 d-title__4">Only orders within</p>
        <div class="d-flex align-items-center">
          <div :class="['inp-wrp d-flex align-items-center mx-2', { 'inactive': approvalType == 'ON_BUDGET_REQ' && (percentage_threshold != null && percentage_threshold.trim().length > 0) }]">
            <p class="mb-0 d-title__4">$</p>
            <b-input v-model="budget_threshold" :disabled="approvalType != 'ON_BUDGET_REQ' || (percentage_threshold != null && percentage_threshold.trim().length > 0)" class="custom-input no-border-inp bg-transparent p-0 pl-1"></b-input>
          </div>
          <p class="mb-0 d-title__4">or</p>
          <div :class="['inp-wrp d-flex align-items-center mx-2', { 'inactive': approvalType == 'ON_BUDGET_REQ' && (budget_threshold != null && budget_threshold.trim().length > 0) }]">
            <b-input v-model="percentage_threshold" :disabled="approvalType != 'ON_BUDGET_REQ' || (budget_threshold != null && budget_threshold.trim().length > 0)" class="custom-input no-border-inp bg-transparent p-0 pl-1"></b-input>
            <p class="mb-0 d-title__4 mr-2">%</p>
          </div>
        </div>
        <p class="mb-0 d-title__4">of budget require approval</p>
        <Button :submitting="submittingRange" class="ml-2" text="Submit" style="min-height: 32px" :onclick="onChange" v-if="approvalType == 'ON_BUDGET_REQ'" />
      </div>
      <b-form-checkbox :value="'ON_BUDGET_REQ'" :unchecked-value="null" v-model="approvalType" switch class="custom-chk" @change="onChange"></b-form-checkbox>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import Button from '@/components/ui/Button'
export default {
  components: { Button },
  mixins: [ OfficeMixin ],
  data: () => ({
    approvalType: null,
    budget_threshold: null,
    percentage_threshold: null,
    submitting: false,
    submittingRange: false,
    enable_order_approval: true,
  }),
  created(){
    this.setData()
  },
  computed: {
    ...mapGetters('USER', ['fullName', 'role']),
  },
  watch: {},
  methods: {
    ...mapActions('OFFICE', ['UPDATE_OFFICE_SETTINGS']),
    onDollarPerChange(update_type){
      const budget = parseFloat(this.office.budget.dental_budget)
      if(update_type == 'update_dollar'){
        let p = this.per || 0
        let d = ''
        if(this.per.trim().length == 0) {
          p = ''
        }else if(!isNaN(p)){
          d = ((budget * p) / 100).toFixed(2)
        }
        this.dollar = `${d}`
        this.per = `${p}`
      }else if (update_type == 'update_per'){
        let d = this.dollar || 0
        let p = ''
        if(this.dollar.trim().length == 0) {
          d = ''
        }else if(!isNaN(d)){
          p = ((100 * d) / budget).toFixed(2)
        }
        this.dollar = `${d}`
        this.per = `${p}`
      }
    },
    async onChange(value){
      if(this.submitting || value == 'ON_BUDGET_REQ') return
      this.submitting = true
      let requires_approval_notification_for_all_orders = false
      
      if(value == null || this.approvalType == 'ALL_EXCEPT_OVERBUDGET'){
        this.approvalType = 'ALL_EXCEPT_OVERBUDGET'
        this.budget_threshold = null
        this.percentage_threshold = null
      }else if(this.approvalType == 'ALL'){
        requires_approval_notification_for_all_orders = true
        this.budget_threshold = null
        this.percentage_threshold = null
      }else {
        requires_approval_notification_for_all_orders = false
        if(
          (this.budget_threshold != null && this.budget_threshold.trim().length > 0) ||
          (this.percentage_threshold != null && this.percentage_threshold.trim().length > 0)
        ){
          this.submittingRange = true
        }
      }
      let budget_threshold = this.budget_threshold || '0.0'
      if(budget_threshold != null && budget_threshold.trim().length == 0) budget_threshold = '0.0'
      let percentage_threshold = this.percentage_threshold || '0.0'
      if(percentage_threshold != null && percentage_threshold.trim().length == 0) percentage_threshold = '0.0'
      budget_threshold = parseFloat(budget_threshold).toFixed(1)
      percentage_threshold = parseFloat(percentage_threshold).toFixed(1)
      try{
        let payload = {
          office: this.officeId,
          requires_approval_notification_for_all_orders,
          budget_threshold,
          percentage_threshold,
          enable_order_approval: this.enable_order_approval,
        }
        await this.UPDATE_OFFICE_SETTINGS(payload)
      }catch(e){
        console.log(e)
      }
      this.submitting = false
      this.submittingRange = false
    },
    setData(){
      if(this.office){
        let settings = this.office.settings
        if(settings){
          this.enable_order_approval = settings.enable_order_approval
          if(settings.requires_approval_notification_for_all_orders){
            this.approvalType = 'ALL'
          }else if (settings.budget_threshold == 0 && settings.percentage_threshold == 0) {
            this.approvalType = 'ALL_EXCEPT_OVERBUDGET'
          }else {
            this.approvalType = 'ON_BUDGET_REQ'
            if(settings.budget_threshold == 0) this.budget_threshold = null
            else this.budget_threshold = parseFloat(settings.budget_threshold).toFixed(2)
            if(settings.percentage_threshold == 0) this.percentage_threshold = null
            else this.percentage_threshold = parseFloat(settings.percentage_threshold).toFixed(2)
          }
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'

::v-deep .check-switch-box
  &.disabled
    opacity: 0.5
    .custom-chk input:checked ~ label::before
      background-color: #e9ecef !important
      border-color: #adb5bd !important
    .custom-chk input:checked ~ label::after
      background-color: #adb5bd !important

.notification-settings
  .inp-wrp
    max-width: 60px
    max-height: 30px
    border-radius: 6px
    border: solid 1px #e8ecee
    padding-left: 6px
    &.inactive
      background-color: #e8ecee
  // .check-switch-box
  //   min-height: 57px
  //   border-radius: 4px
  //   border: solid 1px #E7ECEE
  //   display: flex
  //   justify-content: space-between
  //   align-items: center
  // ::v-deep .custom-chk
  //   input
  //     &:focus:not(:checked) ~ .custom-control-label::before
  //       // border-color: #{$red2} !important
  //   .custom-control-label
  //     &::before
  //       // border-color: #{$red2}
  //       // background-color: #{$red2}
  //     &::after
  //       // background-color: #ffffff
</style>