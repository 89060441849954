<template>
  <div class="acc-setup-one">
    <p class="c-title">
      <span v-if="isInvitation">Set up your account</span>
      <span v-else>Owner Account Setup</span>
    </p>
    <validation-observer ref="accSetupObserver" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(next)">
        <validation-provider name="Company Name" rules="required" v-slot="validationContext">
          <p class="form-label mb-1">Company Name</p>
          <b-input v-model="formData.company_name" :disabled="isInvitation" class="custom-input" placeholder="Enter Company Name" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="First Name" rules="required" v-slot="validationContext">
          <p class="form-label mb-1 mt-3">First Name</p>
          <b-input v-model="formData.first_name" class="custom-input" placeholder="Enter First Name" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="Last Name" rules="required" v-slot="validationContext">
          <p class="form-label mb-1 mt-3">Last Name</p>
          <b-input v-model="formData.last_name" class="custom-input" placeholder="Enter Last Name" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="Email" rules="required|email" v-slot="validationContext">
          <p class="form-label mb-1 mt-3">Email Address</p>
          <b-input v-model="formData.email" :disabled="isInvitation" class="custom-input" placeholder="Enter Email Address" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="Password" rules="required" v-slot="validationContext">
          <p class="form-label mb-1 mt-3">Password</p>
          <b-input-group :class="['password-input-group', { 'is-invalid' : getValidationState(validationContext) == false } ]">
            <b-input v-model="formData.password" :type="showPass ? 'text' : 'password'" placeholder="Enter Password" class="custom-input password-field" :state="getValidationState(validationContext)"></b-input>
            <b-input-group-append class="d-flex align-items-center pr-2">
              <b-icon class="cursor-pointer" icon="eye-slash-fill" v-if="!showPass" @click="toggleShowPass"></b-icon>
              <b-icon class="cursor-pointer" icon="eye-fill" v-else @click="toggleShowPass"></b-icon>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" >{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <!-- <validation-provider name="User Role" rules="required" v-slot="validationContext">
          <p class="form-label mb-1 mt-3">User Role</p>
          <b-form-select disabled v-model="formData.role" :options="roleOptions" class="custom-input" placeholder="User Role" type="text" :state="getValidationState(validationContext)"></b-form-select>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider> -->
        <validation-provider name="Messages" v-slot="validationContext">
          <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <!-- <p class="form-label mb-1 mt-3">Re-enter password</p>
        <validation-provider name="cPassword" rules="required" v-slot="validationContext">
          <b-input-group class="password-input-group">
            <b-input v-model="formData.cpassword" :type="cshowPass ? 'text' : 'password'" placeholder="Re-enter your password" class="custom-input password-field" :state="getValidationState(validationContext)"></b-input>
            <b-input-group-append class="d-flex align-items-center pr-2">
              <b-icon class="cursor-pointer" icon="eye-slash-fill" v-if="!cshowPass" @click="togglecShowPass"></b-icon>
              <b-icon class="cursor-pointer" icon="eye-fill" v-else @click="togglecShowPass"></b-icon>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" >{{ error }}</b-form-invalid-feedback>
        </validation-provider> -->
        <div class="d-flex justify-content-center mt-4">
          <Button text="Save and Continue" class="o-btn" :submitting="submitting" :onclick="next" />
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import FormMixin from '@/mixins/FormMixin'
import { mapActions } from 'vuex'
export default {
  mixins: [FormMixin],
  components: {
    Input,
    Button,
  },
  data: () => ({
    roleOptions: [
      { text: 'Admin', value: 1 },
      // { text: 'User', value: 2 }
    ],
    formData: {
      company_name: null,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      role: 1
    },
    showPass: false,
    cshowPass: false,
    submitting: false,
  }),
  created(){
    if(this.isInvitation) this.setInvData()
  },
  computed: {
    invEmail(){
      return this.$route.query.email
    },
    invRole(){
      return this.$route.query.role
    },
    invCompanyName(){
      return this.$route.query.company
    },
    invToken(){
      return this.$route.query.token
    },
    isInvitation(){
      if(this.invEmail && this.invRole && this.invCompanyName && this.invToken) return true
      return false
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['ONBOARDING_SIGNUP']),
    setInvData(){
      this.formData.email = this.invEmail
      this.formData.company_name = this.invCompanyName
      this.formData.role = this.invRole
    },
    async next(){
      if(this.submitting) return
      this.submitting = true
      let isValid = await this.$refs.accSetupObserver.validate();
      try{
        if(isValid){
          let payload = {...this.formData}
          if(this.isInvitation){
            payload.token = this.invToken
            delete payload.role
          }
          let res = await this.ONBOARDING_SIGNUP(payload)
          if(!this.isInvitation) this.$emit('next')
          else setTimeout(() => {
            this.$router.push({name: 'Dashboard'})
          }, 100)
        }
      }catch(e){
        console.log(e)
        let errors = {
          'Messages': e
        }
        this.$refs.accSetupObserver.setErrors(errors)
      }
      this.submitting = false
    },
    toggleShowPass() {
      this.showPass = !this.showPass;
    },
    togglecShowPass() {
      this.cshowPass = !this.cshowPass;
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.acc-setup-one
  width: 507px
  padding: 32px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  @media screen and (max-width: 992px)
    width: 100%
</style>