<template>
  <div class="outer px-3">
    <div class="contents mx-auto">
      <p class="title-1 text-center mx-auto mb-5">Explore our Features</p>
      <div class="grids">
        <RouterLink class="grid d-flex gap-16-px align-items-center !text-none" v-for="feature in features" :class="[ feature.type ]" :to="feature.to" :key="feature.to">
          <div class="av" :style="{ backgroundImage: `url(${feature.image})` }"></div>
          <p class="title-2 mb-0">{{ feature.title }}</p>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 data: () => ({
  features: [
    { image: require('./feature-images/price_comparison.png'), type: 'price_comparison', title: 'Price Comparison', to: '/feature-price-comparison', },
    { image: require('./feature-images/tracking_budgets.png'), type: 'tracking_budgets', title: 'Budget Tracking', to: '/feature-tracking-budget', },
    { image: require('./feature-images/tracking_orders_returns.png'), type: 'tracking_orders_returns', title: 'Track Orders and Returns', to: '/feature-tracking-orders-returns', },
    { image: require('@/assets/images/future_cast.png'), type: 'future_cast', title: 'Futurecast', to: '/feature-futurecast', },
    { image: require('./feature-images/order_approvals.png'), type: 'order_approvals', title: 'Order Approvals', to: '/feature-order-approvals', },
    { image: require('./feature-images/multi-vendor_orders.png'), type: 'multi-vendor_orders', title: 'Multi-vendor Ordering', to: '/feature-multivendor-ordering', },
    { image: require('./feature-images/imported_order_history.png'), type: 'imported_order_history', title: 'Imported Order History', to: '/feature-imported-order-history', },
    { image: require('./feature-images/customize_products.png'), type: 'customize_products', title: 'Customize Inventory', to: '/feature-customize-products', },
    { image: require('./feature-images/download_invoice.png'), type: 'download_invoice', title: 'Download Invoices', to: '/feature-download-invoices', },
    { image: require('./feature-images/analyze_supplies.png'), type: 'analyze_supplies', title: 'Analytics', to: '/feature-analyze-supplies', },
    { image: require('./feature-images/open_dental.png'), type: 'open_dental', title: 'Syncs with Open Dental', to: '/feature-opendental-interation', },
    { image: require('./feature-images/multi-office_function.png'), type: 'multi-office_function', title: 'Multi-Office Functionality', to: '/feature-multioffice-functionality', },
  ]
 }),
}
</script>

<style lang="sass" scoped>
.outer
  padding: 6rem 0
  .contents
    max-width: 1056px
  .title-1
    font-family: GILROY-BOLD
    font-size: 32px
    max-width: 535px
    color: #274458
  .title-2
    font-family: GILROY-BOLD
    font-size: 16px
    color: #274458
  .grids
    display: grid
    row-gap: 1.5rem
    column-gap: 2rem
    grid-template-columns: repeat(3, minmax(0, 1fr))
    .grid
      border-radius: 12px
      background-color: #FFFFFF
      padding: 1.2rem 1.5rem
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
      .av
        width: 32px
        height: 32px
        border-radius: 4px
        background-size: cover
        background-repeat: no-repeat
      // &.price_comparison
      // &.tracking_budgets
      // &.tracking_orders_returns
      // &.future_cast
      &.order_approvals
        .av
          width: 26px
          height: 26px
      // &.multi-vendor_orders
      // &.imported_order_history
      &.customize_products
        .av
          width: 26px
          height: 26px
      // &.download_invoice
      &.analyze_supplies
        .av
          width: 26px
          height: 26px
      &.open_dental
        .av
          background-size: contain
      // &.multi-office_function
@media screen and (max-width: 992px)
  .outer
    padding: 2rem 0
    .grids
      grid-template-columns: repeat(1, minmax(0, 1fr))
</style>