<template>
  <div class="px-3 header-wrp">
    <div class="landing-header mx-auto d-flex align-items-center gap-x-44-px py-3">
      <RouterLink to="/"><logo class="w-130-px h-40-px" /></RouterLink>
      <HeaderMenus class="flex-grow-1 d-none d-xl-flex" />
      <b-icon icon="x" font-scale="1.5" :class="['cursor-pointer d-xl-none ml-auto gilroy-m']" v-if="menu" @click="toggleMobileMenu"></b-icon>
      <b-icon icon="list" font-scale="1.5" :class="['cursor-pointer d-xl-none ml-auto gilroy-m']" v-else @click="toggleMobileMenu"></b-icon>
      <div class="nv-menu d-xl-none px-4" v-if="menu">
        <HeaderMenus class="d-flex flex-column" />
      </div>
    </div>
  </div>
</template>

<script>
import GoTo from '@/mixins/GoTo'
import Logo from '@/components/logo/Logo'
import HeaderMenus from './HeaderMenus.vue'
import LogoWhite from '@/components/logo/LogoWhite'
export default {
  mixins: [GoTo],
  components: { Logo, LogoWhite, HeaderMenus, },
  data: () => ({
    menu: false,
    scrollY: 0
  }),
  mounted(){
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed(){
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    alt(){
      let u = document.querySelector('.upper')
      if(u) u = u.clientHeight
      else u = 300
      return this.scrollY < u && !this.menu
    },
  },
  watch: {
    $route(){
      this.menu = false
    },
  },
  methods: {
    onLinkClick(sec){
      if(this.$route.name === 'Landing'){
        document.querySelector(`#${sec}`).scrollIntoView({
          behavior: 'smooth'
        })
      }else {
        this.$router.push({name: 'Landing', hash: `#${sec}`})
      }
    },
    onScroll(){
      this.scrollY = window.scrollY
      let u = document.querySelector('.upper')
      if(u) u = u.clientHeight
      else u = 300
      if(window.scrollY > u || this.menu){
        this.$refs.landingHeader.classList.add('mb-wh-head')
      }else {
        this.$refs.landingHeader.classList.remove('mb-wh-head')
      }
    },
    toggleMobileMenu(){
      this.menu = !this.menu
    },
    gotoFeatures(){
      this.$router.replace({name: 'Landiing', hash: '#features'})
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../style/landing'

.header-wrp
  position: sticky
  top: 0
  z-index: 10
  background-color: #FFFFFF
  border-bottom: solid 1px #E7ECEE
.landing-header
  // height: 74px
  max-width: 1200px
  position: relative
  z-index: 3
  // @media screen and (max-width: 992px)
  //   position: fixed
  //   width: 100%
  //   z-index: 3
  //   &.mb-wh-head
  //     border-bottom: 1px solid #E8ECEE
  //     background-color: #ffffff

  .nv-menu
    height: 100%
    width: 100%
    @media screen and (max-width: 992px)
      padding: 1.5rem 0
      position: absolute
      top: 72px
      width: 100vw
      height: max-content
      max-height: 80vh
      overflow-y: auto
      z-index: 3
      background-color: #ffffff
      left: -1rem
      .nav-i
        color: #EA3CBE !important
        text-align: center
  @media screen and (max-width: 992px)
    width: 100%
    position: relative
  .s3
    padding-left: 100px
    @media screen and (max-width: 992px)
      margin-top: 30px
      padding-left: 0
      display: flex
      flex-flow: column
      align-items: center
      .l-btn
        min-width: 60vw
  .l-btn
    height: 44px
    font-size: 14px !important
    &:nth-child(1)
      width: 120px
    &:nth-child(2)
      width: 150px
.logo-img
  width: 120px
  height: 38px
.nav-i
  font-family: GILROY-BOLD
  font-size: 16px
  &:hover
    text-decoration: none
</style>