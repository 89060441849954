<template>
  <div class="page-outer">
    <div class="page-contents mx-auto">
      <Lan9 />
      <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div>
          <p class="title-1">Still have questions?</p>
          <p class="subtitle-1">Please put your information in the designated fields, and a member of the Ordo team will reach out to you shortly to address any remaining questions you may have. Thank you for your interest in Ordo!</p>
        </div>
        <div class="form-div">
          <p class="title-2">Contact Us</p>
          <validation-observer ref="loginFormObs" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(submit)">
              <div class="d-flex gap-12-px">
                <validation-provider name="First Name" rules="required" v-slot="validationContext">
                  <b-input v-model="formData.first_name" class="custom-input" placeholder="First Name" type="text" :state="getValidationState(validationContext)"></b-input>
                  <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
                </validation-provider>
                <validation-provider name="Last Name" rules="required" v-slot="validationContext">
                  <b-input v-model="formData.last_name" class="custom-input" placeholder="Last Name" type="text" :state="getValidationState(validationContext)"></b-input>
                  <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <validation-provider name="Office Name" rules="required" v-slot="validationContext">
                <b-input v-model="formData.officeName" class="custom-input mt-3" placeholder="Office Name" type="text" :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
              <validation-provider name="Email Address" rules="required" v-slot="validationContext">
                <b-input v-model="formData.email" class="custom-input mt-3" placeholder="Email Address" type="email" :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
              <!-- <b-checkbox class="auth-chk custom-chk mt-2">Stay signed in for a week</b-checkbox> -->
              <validation-provider name="Messages" v-slot="validationContext">
                <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
              <div class="mt-4 d-flex justify-content-between align-items-center gap-12-px">
                <Button text="Submit" class="success px-4" :onclick="submit" :submitting="submitting" />
                <p class="subtitle-1 text-success mb-0" v-if="successMsg">{{ successMsg }}</p>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  mixins: [FormMixin],
  components: { Button, Input, Lan9, },
  data: () => ({
    formData: {
      email: null,
      first_name: null,
      last_name: null,
      officeName: null,
    },
    submitting: false,
    successMsg: null,
  }),
  methods: {
    async submit(){
      if(this.submitting) return
      this.submitting = true
      this.successMsg = 'Request submitted'
      setTimeout(() => {
        this.successMsg = null
        this.submitting = false
      }, 2000)
    },
  },
}
</script>

<style lang="sass" scoped>
.page-contents
  max-width: 842px
  padding: 6rem 0
  border-top: 2px solid #D9D9D9
.title-1
  font-family: GILROY-BOLD
  font-size: 24px
  color: #274458
.title-2
  font-family: GILROY-BOLD
  font-size: 20px
  color: #274458
.subtitle-1
  font-family: GILROY-MEDIUM
  font-size: 16px
  color: #788288
  line-height: 1.6
  max-width: 395px
.faq
  position: relative
  background-color: #FFFFFF
  padding: 1rem
  border-radius: 8px
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
  .icon
    position: absolute
    right: 1rem
    top: 1.3rem
  &:has(.collapse.show)
    .icon
      transform: rotate(90deg)
.form-div
  background: #FFFFFF
  box-shadow: 8.73234px -5.85067px 61.4144px rgba(0, 0, 0, 0.07)
  border-radius: 5.84899px
  padding: 2rem
  @media screen and (min-width: 992px)
    width: 391px
</style>