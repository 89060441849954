<template>
  <div class="landing-page">
    <!-- <landing-cover /> -->
    <lan-1 />
    <lan-2 />
    <lan-3 />
    <lan-4 />
    <lan-5 />
    <lan-6 />
    <lan-10 />
    <lan-7 />
    <lan-8 />
    <div class="mx-auto" style="max-width: 900px;">
      <lan-9 />
    </div>
  </div>
</template>

<script>
import LandingCover from '@/components/landing/LandingCover'
import Lan1 from '@/components/landing/Lan1'
import Lan2 from '@/components/landing/Lan2'
import Lan3 from '@/components/landing/Lan3'
import Lan4 from '@/components/landing/Lan4'
import Lan5 from '@/components/landing/Lan5'
import Lan6 from '@/components/landing/Lan6'
import Lan7 from '@/components/landing/Lan7'
import Lan8 from '@/components/landing/Lan8'
import Lan9 from '@/components/landing/Lan9'
import Lan10 from '@/components/landing/Lan10'
export default {
  components: { LandingCover, Lan1, Lan2, Lan3, Lan4, Lan5, Lan6, Lan7, Lan8, Lan9, Lan10 },
  mounted(){
    this.chkSectionHash()
  },
  watch: {
    $route(){
      this.chkSectionHash()
    },
  },
  methods: {
    chkSectionHash(){
      if(this.$route.hash && this.$route.hash.length > 1){
        document.querySelector(`#${this.$route.hash.substring(1)}`).scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.landing-page
  // background-color: #F8F9FC
</style>