<template>
  <div>
    <div class="d-flex justify-content-center mb-40-px">
      <logo class="logo" />
    </div>
    <p class="text-center d-title text-danger__2">Order approval processed</p>
    <p class="text-center d-desc mb-4">We've processed your order approval, users of your office will receive an email with the updated status of this order</p>
    <div class="d-flex justify-content-center">
      <p class="text-center cursor-pointer text-primary d-title" @click="$emit('go')">View Order Status</p>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logo/Logo'
export default {
  components: { Logo, },
}
</script>

<style lang="sass" scoped>

.logo
  width: 115px
  height: 32px
</style>