<template>
  <div class="d-flex justify-content-center align-items-center" v-if="$store.state.OFFICE.fetchingBudget">
    <b-spinner small variant="primary" class="mr-2"></b-spinner>
    <p class="mb-0">Fetching Data...</p>
  </div>
  <div v-else>
    <div v-for="(ofc, oI) in offices" :key="oI"
      :style="[$route.name == 'Onboarding' && { 'box-shadow': '0 0 5px 0 rgba(0, 0, 0, 0.1)' }]">
      <div :class="['mb-3', { 'ofc-budget': !single }]" v-if="!$store.state.OFFICE.fetchingBudget">
        <div class="ofc-bud-head cursor-pointer d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center w-100 pr-3 justify-content-between" v-b-toggle="`ofc-bud-${oI}`"
            :ref="`ofc-bud-${oI}`">
            <p class="c-title mb-0">{{ ofc.name }}</p>
          </div>
          <p class="c-title mb-0" v-if="isCompletedBudget(ofc)"><span class="text-success">Completed</span></p>
          <div class="align-items-center gap-16-px d-flex" v-else>
            <OpendentalActions :office="ofc" link-button-text="Link Open Dental" unlink-button-text="Unlink Open Dental"
              v-if="ofc.practice_software == 'open_dental'" @conn-update="$emit('opendental-conn-update')" />
            <div class="set-div" v-b-toggle="`ofc-bud-${oI}`" v-else>Set Manually</div>
          </div>
        </div>
        <b-form @submit.stop.prevent="handleSubmit(() => next(oI))" v-if="officeBudget[ofc.id] != null">
          <b-collapse :id="`ofc-bud-${oI}`" accordion="my-accordion" role="tabpanel" :visible="oI == 0">
            <div class="d-flex align-items-center mt-4 mb-2"
              v-if="slug == null && !adjusted_production_collection_only">
              <div class="sl-num">
                <p class="d-desc mb-0 text-white">1</p>
              </div>
              <p class="mb-0 d-title__2 text-danger__2 ml-2">Step One</p>
            </div>
            <b-row class="mx-0 pb-3" :class="[!(slug == null && !adjusted_production_collection_only) && 'mt-3']"
              v-if="slug == null">
              <b-col cols="12" lg="6" class="mb-3 mb-lg-0 pr-lg-0 pl-0">
                <p class="form-label mb-1">Previous Months Adjusted Production</p>
                <validation-provider name="Dental Adjusted Production"
                  :rules="officeBudget[ofc.id].basis == 1 ? 'required' : ''" v-slot="validationContext">
                  <b-input v-model="officeBudget[ofc.id].adjusted_production"
                    :disabled="officeBudget[ofc.id].basis == 2"
                    :class="['custom-input', officeBudget[ofc.id].basis == 1 && 'active-adj-col', { 'back-no-img': officeBudget[ofc.id].basis == 2 }]"
                    placeholder="Enter Adjusted Production" type="text" :state="getValidationState(validationContext)"
                    @keyup="onKeyUpBudget($event, ofc.id, 'adjusted_production')"></b-input>

                  <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
                    }}</b-form-invalid-feedback>
                </validation-provider>
                <div v-if="valueIsNegative" style="color:red; font-size:80%; width:100%">
                  <p>Budget must be positive</p>

                </div>
              </b-col>
              <b-col cols="12" lg="1"
                class="d-flex align-items-center justify-content-center mb-2 mb-lg-0 pl-lg-0 pr-lg-0">
                <b-form-checkbox v-model="officeBudget[ofc.id].basis" :value="2" :unchecked-value="1" switch
                  class="custom-chk budget-chk ml-lg-2 mt-lg-4"></b-form-checkbox>
              </b-col>
              <b-col cols="12" lg="5" class="mb-3 mb-lg-0 pl-lg-0 pr-0">
                <p class="form-label mb-1">Previous Months Collections</p>
                <validation-provider name="Dental Collection" :rules="officeBudget[ofc.id].basis == 2 ? 'required' : ''"
                  v-slot="validationContext">
                  <b-input v-model="officeBudget[ofc.id].collection" :disabled="officeBudget[ofc.id].basis == 1"
                    :class="['custom-input', officeBudget[ofc.id].basis == 2 && 'active-adj-col', { 'back-no-img': officeBudget[ofc.id].basis == 1 }]"
                    placeholder="Enter Collections" type="text" :state="getValidationState(validationContext)"
                    @keyup="onKeyUpBudget($event, ofc.id, 'collection')"></b-input>
                  <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
                    }}</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <p class="form-label mb-0"
              v-if="isOpenDentalConnected && slug == null && adjusted_production_collection_only">Note: Your adjusted
              production/collections are automatically being pulled by Open Dental. No need to edit.</p>
            <validation-observer :ref="`budgetObs-${oI}`" v-slot="{ handleSubmit }"
              v-if="!adjusted_production_collection_only">
              <div class="bordered pt-3 mb-3 mt-3" v-for="(subaccount, subAccountIndex) in getSubaccounts(ofc)">
                <div class="d-flex flex-wrap gap-16-px align-items-center justify-content-between px-3 pb-3">
                  <OfficeBudgetTitle class="flex-grow-1" :officeId="offices[oI].id" :subaccount="subaccount"
                    @updated="onUpdateSubaccountTitle" v-b-toggle="`ofc-bud-dental-${subaccount.slug}`"
                    :ref="`ofc-bud-dental-${subaccount.slug}`" />
                  <p class="c-title mb-0 ml-2" v-b-toggle="`ofc-bud-dental-${subaccount.slug}`"
                    v-if="calculateBudget({ slug: subaccount.slug, officeId: ofc.id })"><span class="text-success">${{
    formatToCurrencyNumber(calculateBudget({
      slug: subaccount.slug, officeId:
        ofc.id
    })) }}/mo</span></p>
                  <span class="form-label__2 ml-2 f-10-px cursor-pointer"
                    v-b-toggle="`ofc-bud-dental-${subaccount.slug}`" v-else>Click to Expand</span>
                </div>
                <b-collapse :id="`ofc-bud-dental-${subaccount.slug}`" accordion="my-accordion-dental" role="tabpanel"
                  class="pb-2" :visible="slug == subaccount.slug || subAccountIndex == 0">
                  <div class="dash mb-3"></div>
                  <div class="d-flex align-items-center mb-2 ml-3" v-if="slug == null">
                    <div class="sl-num">
                      <p class="d-desc mb-0 text-white">2</p>
                    </div>
                    <p class="mb-0 d-title__2 text-danger__2 ml-2">Step Two</p>
                  </div>
                  <b-row class="mx-0">
                    <b-col cols="12" lg="6" class="mb-3 mb-lg-0 pr-lg-2">
                      <p class="form-label mb-1">Target Spend Percentage</p>
                      <validation-provider name="Dental Percentage" v-slot="validationContext">
                        <div class="position-relative">
                          <b-input
                            @keyup="onKeyUpPercentageDental(subaccount,$event, ofc.id, subaccount.slug)"
                            v-model="subaccount.percentage" class="custom-input" step="0.25"
                            placeholder="Enter Target Spend Percentage" type="text"
                            :state="getValidationState(validationContext)" style="background-image: unset"></b-input>
                          <div class="arrows d-flex flex-column">
                            <b-icon class="cursor-pointer" icon="caret-up-fill" font-scale="0.8"
                              @click="onKeyUpPercentageDental(subaccount, undefined, ofc.id, subaccount.slug, '0.1')"></b-icon>
                            <b-icon class="cursor-pointer" icon="caret-down-fill" font-scale="0.8"
                              @click="onKeyUpPercentageDental(subaccount, undefined, ofc.id, subaccount.slug, '0.1', true)"></b-icon>
                          </div>
                        </div>
                        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" lg="1"
                      class="d-flex align-items-center justify-content-center mb-2 mb-lg-0 pl-lg-2 pr-lg-2">
                      <p class="form-label mb-1 mt-lg-4">=</p>
                    </b-col>
                    <b-col cols="12" lg="5" class="mb-3 mb-lg-0 pl-lg-2 pr-lg-2">
                      <p class="form-label mb-1">Monthly Office Budget</p>
                      <p class="slider-val mb-0 mt-11-px">${{ (formatToCurrencyNumber(calculateBudget({
    slug:
      subaccount.slug, officeId: ofc.id
  }) || 0.00)) }}</p>
                    </b-col>
                  </b-row>
                  <div class="dash mb-2 mt-4" v-if="subaccount.slug == 'office' || subaccount.slug == 'dental'"></div>
                  <p class="form-label text-center my-0" v-if="subaccount.slug == 'office'">The industry standard target
                    spend percentage for a dental office is 1-2%</p>
                  <p class="form-label text-center my-0" v-else-if="subaccount.slug == 'dental'">The industry standard
                    target spend percentage for a dental office is 4-7%</p>
                </b-collapse>
              </div>
            </validation-observer>
          </b-collapse>
          <div class="d-flex justify-content-center my-4">
            <b-btn class="custom-btn o-btn" @click="next(oI)" :disabled="valueIsNegative">
              <b-spinner small variant="light" class="mr-2" v-if="submitting"></b-spinner>
              <span v-if="oI == offices.length - 1">Save and Continue</span>
              <span v-else>Next</span>
            </b-btn>
          </div>
          <p class="text-success text-center f-12-px gilroy-b cursor-pointer" v-if="showSuccessMsg">{{ msg }}</p>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { mapActions, mapState, mapMutations } from 'vuex'
import FormMixin from '@/mixins/FormMixin'
import NumberComma from '@/mixins/NumberComma'
import OpendentalActions from '@/components/dashboard/settings/OpendentalActions.vue'
import OfficeBudgetTitle from './OfficeBudgetTitle.vue'
export default {
  props: {
    offices: {
      type: Array,
      default: []
    },
    single: Boolean,
    frontOffice: Boolean,
    slug: String,
    adjusted_production_collection_only: { type: Boolean, default: false },
    isOpenDentalConnected: Boolean,
  },
  components: {
    Input,
    Button,
    VueSlider,
    OpendentalActions,
    OfficeBudgetTitle,
  },
  mixins: [FormMixin, NumberComma],
  data: () => ({
    max: 5000,
    min: 100,
    budgets: [],
    submitting: false,
    budgetType: 'month',
    prData: [
      { image: require('@/assets/images/practice_system/LogoBigO_Software_500x180.svg'), selected: null },
      { image: require('@/assets/images/practice_system/Dentrix-logo.svg'), selected: null },
      { image: require('@/assets/images/practice_system/eaglesoft-logo.svg'), selected: null },
    ],
    showSuccessMsg: false,
    msg: null,
    officeBudget: {},
    valueIsNegative: false,
  }),
  created() {
    if (this.$route.name == 'OnBoarding') this.fetchOfficeBudgets()
    this.setData()
  },
  watch: {
    offices: {
      handler(v) {
        this.setFormData()
      }, deep: true,
    },
    fetchingBudget(v) {
      if (!v) setTimeout(() => {
        this.setData()
      }, 500)
    },
  },
  computed: {
    ...mapState('OFFICE', ['fetchingBudget']),
  },
  methods: {
    ...mapActions('ONBOARDING', ['ONBOARDING_CREATE_OFFICES', 'OFFICE_BUDGET', 'UPDATE_OFFICE_BUDGET', 'FETCH_ONBOARDING_STATUS']),
    ...mapActions('OFFICE', ['CREATE_CUSTOM_BUDGET']),
    isCompletedBudget(office) {
      if (this.$store.state.OFFICE.officeBudget[office.id]) {
        const amount = Number(this.$store.state.OFFICE.officeBudget[office.id].adjusted_production ||
          this.$store.state.OFFICE.officeBudget[office.id].collection || 0)
        if (amount == 0) return false
        const dentalSlug = this.$store.state.OFFICE.officeBudget[office.id].subaccounts.find(s => s.slug == 'dental')
        if (Number(dentalSlug.percentage || 0) != 0) return true
      }
      return false
    },
    onClickSetManually(oI, slug) {
      if (this.$refs[`ofc-bud-${oI}`] && this.$refs[`ofc-bud-${oI}`].length > 0) this.$refs[`ofc-bud-${oI}`][0].click()
      setTimeout(() => {
        if (this.$refs[`ofc-bud-dental-${slug}`] && this.$refs[`ofc-bud-dental-${slug}`].length > 0) this.$refs[`ofc-bud-dental-${slug}`][0].click()
      }, 300)
    },
    setData() {
      this.officeBudget = Object.assign({}, JSON.parse(JSON.stringify(this.$store.state.OFFICE.officeBudget)))
      for (let ofKey in this.officeBudget) {
        this.officeBudget[ofKey].adjusted_production = `$${this.officeBudget[ofKey].adjusted_production}`
        this.officeBudget[ofKey].collection = `$${this.officeBudget[ofKey].collection}`
        this.officeBudget[ofKey].subaccounts.forEach((subaccount, index) => {
          this.officeBudget[ofKey].subaccounts[index].percentage = `${this.officeBudget[ofKey].subaccounts[index].percentage}%`
        })
      }
    },
    setFormData() {
      if (this.offices) {
        this.budgets = this.offices.map(ofc => {
          let obj = {
            id: ofc.id,
            dental_budget_type: 'production',
            office_budget_type: 'production',
            dental_budget: null,
            office_budget: null,
            budgetId: null,

            ofc_adj: null,
            ofc_col: null,
            ofc_per: null,

            den_adj: null,
            den_col: null,
            den_per: null,
          }
          let dental_total_budget = 0
          let office_total_budget = 0
          if (ofc.budget) {
            obj.dental_budget_type = ofc.budget.dental_budget_type
            obj.office_budget_type = ofc.budget.office_budget_type
            obj.dental_budget = ofc.budget.dental_budget
            obj.office_budget = ofc.budget.office_budget
            obj.budgetId = ofc.budget.id

            dental_total_budget = parseFloat(ofc.budget.dental_total_budget)
            let adjusted_production = parseFloat(ofc.budget.adjusted_production)
            let collection = parseFloat(ofc.budget.collection)
            if (!isNaN(adjusted_production) && adjusted_production > 0) obj.den_adj = '$' + this.formatToCurrencyNumber(adjusted_production)
            else if (ofc.budget.dental_budget_type == 'production') obj.den_adj = '$' + this.formatToCurrencyNumber(dental_total_budget)
            if (!isNaN(collection) && collection > 0) obj.den_col = '$' + this.formatToCurrencyNumber(collection)
            else if (ofc.budget.dental_budget_type == 'collection') obj.den_col = '$' + this.formatToCurrencyNumber(dental_total_budget)
            obj.den_per = ofc.budget.dental_percentage + '%'

            office_total_budget = parseFloat(ofc.budget.office_total_budget)
            if (!isNaN(adjusted_production) && adjusted_production > 0) obj.ofc_adj = '$' + this.formatToCurrencyNumber(adjusted_production)
            else if (ofc.budget.office_budget_type == 'production') obj.ofc_adj = '$' + this.formatToCurrencyNumber(office_total_budget)
            if (!isNaN(collection) && collection > 0) obj.ofc_col = '$' + this.formatToCurrencyNumber(collection)
            else if (ofc.budget.office_budget_type == 'collection') obj.ofc_col = '$' + this.formatToCurrencyNumber(office_total_budget)

            // if(ofc.budget.office_budget_type == 'production') obj.ofc_adj = '$' + this.formatToCurrencyNumber(office_total_budget)
            // else if(ofc.budget.office_budget_type == 'collection') obj.ofc_col = '$' + this.formatToCurrencyNumber(office_total_budget)
            obj.ofc_per = ofc.budget.office_percentage + '%'
          }
          return obj
        })
      }
    },
    onKeyUpPercentageDental(account, event, officeId, slug, incBy, dec) {
      if (incBy != null || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
        account.percentage = parseFloat(account.percentage).toFixed(2)
        const index = this.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
        if (index > -1) {
          let percentage = this.officeBudget[officeId].subaccounts[index].percentage || '0'
          if (percentage.split('.')[1].toString().replace('%', '').toString().length > 2) {
            return
          }
          if (percentage && percentage.trim().length == 0) {
            this.officeBudget[officeId].subaccounts[index].percentage = null
          }
          if (percentage.includes('%')) percentage = percentage.replaceAll('%', '');
          if (percentage > 100 || percentage < 0) {
            this.officeBudget[officeId].subaccounts[index].percentage = percentage.slice(0, -1) + '%'
          }
          else if (percentage) {
            if (incBy) {
              if (dec && parseFloat(percentage) > 0) percentage = (this.currencyToNumber(percentage) - parseFloat(incBy)).toFixed(2)
              else percentage = (this.currencyToNumber(percentage) + parseFloat(incBy)).toFixed(2)
            }
            this.officeBudget[officeId].subaccounts[index].percentage = this.numberWithCommas(percentage)
            if (this.officeBudget[officeId].subaccounts[index].percentage && !this.officeBudget[officeId].subaccounts[index].percentage.includes('%')) this.officeBudget[officeId].subaccounts[index].percentage += '%'
          }
        }
      }
    },
    onKeyUpPercentageOffice(event, index, incBy, dec) {
      if (incBy != null || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
        let percentage = this.budgets[index].ofc_per || '0'
        if (percentage && percentage.trim().length == 0)
          this.budgets[index].ofc_per = null
        else if (percentage) {
          if (percentage.includes('%')) percentage = percentage.replaceAll('%', '')
          if (incBy) {
            if (dec && parseFloat(percentage) > 0) percentage = (this.currencyToNumber(percentage) - parseFloat(incBy)).toFixed(2)
            else percentage = (this.currencyToNumber(percentage) + parseFloat(incBy)).toFixed(2)
          }
          this.budgets[index].ofc_per = this.numberWithCommas(percentage)
          if (this.budgets[index].ofc_per && !this.budgets[index].ofc_per.includes('%')) this.budgets[index].ofc_per += '%'
        }
      }
    },
    onKeyUpBudget(event, officeId, key) {
      this.valueIsNegative = false;
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
        let v = event.target.value
        if (v < 0) {
          this.valueIsNegative = true;
        }
        if (v != null && v.trim().length > 0) {

          v = v.replaceAll('$', '')
          v = v.replaceAll(',', '')
          v = Number(v)
          let x = '$' + this.numberWithCommas(v)
          this.officeBudget[officeId][key] = x
        } else {
          this.officeBudget[officeId][key] = null
        }
      }
    },
    makeEmpty(index, key, own) {
      let v = this.budgets[index][key]
      let v2 = this.budgets[index][own]
      if (v2 && v2.trim().length > 0) {
        this.budgets[index][key] = null
        if (key.includes('den')) this.budgets[index][`ofc_${key.split('_')[1]}`] = null
        let t = this.formatToCurrencyNumber(v2)
        if (t) {
          if (t.trim().length == 0) {
            this.budgets[index][own] = null
            if (key.includes('den')) this.budgets[index][`ofc_${own.split('_')[1]}`] = null
          }
          else {
            let x = '$' + this.formatToCurrencyNumber(t)
            this.budgets[index][own] = x
            if (key.includes('den')) this.budgets[index][`ofc_${own.split('_')[1]}`] = x
          }
        }
      }
    },
    calculateOfficeBudget(index) {
      let budget = this.budgets[index]
      let b = null
      if (budget.office_budget_type == 'production' && budget.ofc_adj && budget.ofc_per) b = ((this.currencyToNumber(budget.ofc_adj) * this.currencyToNumber(budget.ofc_per)) / 100).toFixed(2)
      else if (budget.office_budget_type == 'collection' && budget.ofc_col && budget.ofc_per) b = ((this.currencyToNumber(budget.ofc_col) * this.currencyToNumber(budget.ofc_per)) / 100).toFixed(2)
      else if (budget.office_budget) b = budget.office_budget
      return b
    },
    calculateDentalBudget(slug, officeId) {
      const index = this.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
      if (index > -1 && this.officeBudget[officeId].subaccounts[index].percentage) {
        let b = null
        if (this.officeBudget[officeId].basis == 1 && this.officeBudget[officeId].adjusted_production) b = ((this.currencyToNumber(this.officeBudget[officeId].adjusted_production) * this.currencyToNumber(this.officeBudget[officeId].subaccounts[index].percentage)) / 100).toFixed(2)
        else if (this.officeBudget[officeId].basis == 2 && this.officeBudget[officeId].collection) b = ((this.currencyToNumber(this.officeBudget[officeId].collection) * this.currencyToNumber(this.officeBudget[officeId].subaccounts[index].percentage)) / 100).toFixed(2)
        return b
      }
    },
    calculateBudget({ slug, officeId }) {
      const index = this.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
      if (index > -1 && this.officeBudget[officeId].subaccounts[index].percentage) {
        if (this.officeBudget[officeId].basis == 1 && this.officeBudget[officeId].adjusted_production) return ((this.currencyToNumber(this.officeBudget[officeId].adjusted_production) * this.currencyToNumber(this.officeBudget[officeId].subaccounts[index].percentage)) / 100).toFixed(2)
        else if (this.officeBudget[officeId].basis == 2 && this.officeBudget[officeId].collection) return ((this.currencyToNumber(this.officeBudget[officeId].collection) * this.currencyToNumber(this.officeBudget[officeId].subaccounts[index].percentage)) / 100).toFixed(2)
      }
    },
    async next(index) {
      if (this.submitting) return
      this.submitting = true
      let isValid = this.adjusted_production_collection_only ? true : await this.$refs[`budgetObs-${index}`][0].validate()
      if (isValid) {
        try {
          let officeId = this.offices[index].id
          let payload = {
            ...this.officeBudget[officeId],
            adjusted_production: this.currencyToNumber(this.officeBudget[officeId].adjusted_production),
            collection: this.currencyToNumber(this.officeBudget[officeId].collection),
            basis: this.officeBudget[officeId].basis == null ? 1 : this.officeBudget[officeId].basis,
            subaccounts: this.officeBudget[officeId].subaccounts.map(subaccount => {
              const obj = {
                ...subaccount,
                percentage: this.currencyToNumber(subaccount.percentage)
              }
              delete obj.vendors
              return obj
            }),

            office: officeId,
          }
          if (this.$route.name == 'OnBoarding' || this.$route.name == 'Dashboard') payload.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
          await this.CREATE_CUSTOM_BUDGET(payload)
          await this.FETCH_ONBOARDING_STATUS({ budget_set: true })
          this.$store.state.ONBOARDING.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
          if (index < this.offices.length - 1) {
            this.$refs[`ofc-bud-${index}`][0].click()
            this.$refs[`ofc-bud-${index + 1}`][0].click()
          }
          else this.$emit('next')
          if (this.$route.name === 'Settings') {
            this.msg = 'Updated Successfully'
            this.showSuccessMsg = true
            setTimeout(() => {
              this.$emit('done')
              this.fetchOfficeBudgets()
            }, 1000)
          } else this.fetchOfficeBudgets()
        } catch (e) {
          console.log(e)
        }
      }
      this.submitting = false
    },
    async fetchOfficeBudgets() {
      this.$store.state.OFFICE.fetchingBudget = true
      await Promise.all(
        this.offices.map(office => new Promise((resolve, reject) => {
          this.$store.dispatch('OFFICE/FETCH_OFFICE_BUDGETS', { office: office.id }).then(res => resolve(res)).catch(err => reject(err))
        }))
      )
      this.setData()
      this.$store.state.OFFICE.fetchingBudget = false
    },
    onUpdateSubaccountTitle(officeId, slug, title) {
      const index = this.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
      if (index > -1) this.officeBudget[officeId].subaccounts[index].name = title
      this.$forceUpdate()
    },
    getSubaccounts(office) {
      if (this.slug) {
        return [this.officeBudget[office.id].subaccounts.find(s => s.slug == this.slug)]
      }
      if (this.$route.name == 'Dashboard') return this.officeBudget[office.id].subaccounts.filter(s => ['dental', 'office'].includes(s.slug))
      return this.officeBudget[office.id].subaccounts
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
@import '../../style/variables'
.active-adj-col
  &:not(.is-invalid)
    border-color: #{$green} !important
    border-width: 2px
.set-div
  width: max-content
  padding: 0 0.75rem
  height: 28px
  border-radius: 4px
  background-color: #{$blue}
  display: flex
  align-items: center
  justify-content: center
  font-family: GILROY-BOLD
  font-size: 12px
  font-stretch: normal
  font-style: normal
  line-height: 1.67
  letter-spacing: normal
  text-align: center
  color: #fff

.sl-num
  background-color: #{$red2}
  width: 18px
  height: 18px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  p
    line-height: 1
    margin-top: 2px
    font-size: 12px
.d-title__2
  font-family: GILROY-Bold
  font-size: 12px
  line-height: 1
  font-weight: 600
  font-stretch: normal
  font-style: normal
  color: #1c455a
.arrows
  position: absolute
  right: 10px
  bottom: 7px
.ofc-budget
  width: 557px
  padding: 15px 32px
  border-radius: 8px
  background-color: #ffffff
  @media screen and (max-width: 992px)
    width: 100%
  .slider-val
    font-family: GILROY-SEMIBOLD
    font-size: 18px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    line-height: 1.33
    color: #29BF12
  .slider
    ::v-deep .vue-slider-dot-handle, ::v-deep .slider-process
      background-color: #49a2cb !important
  .t-btn
    font-size: 10px
    min-height: 35px
    border-radius: 8px
  .l-btn
    font-size: 8px
    min-height: 24px
    max-height: 24px
    border-radius: 8px
  .pr-box
    .img-sec
      height: 39px
      width: 69px

</style>