export default filters => {
  let c = 0
  let str = ''
  for(let key in filters){
    if(filters[key] != null){
      if(c == 0) str += '?'
      else str += '&'
      str += `${key}=${encodeURIComponent(filters[key])}`
      c++
    }
  }
  return str
}