<template>
  <div class="no-data d-flex flex-column justify-content-center align-items-center">
    <p class="d-title mb-1 text-center">My Inventory</p>
    <p class="d-desc mb-3 text-center" style="max-width: 450px">Once you link a vendor or order an item it will be saved to your inventory. Items in your inventory will display here. As a default we put them in categories, but you can move them in and out of the categories you prefer.</p>
    <Button style="min-width: 200px" text="Connect a Vendor" :onclick="() => goTo({name: 'Settings', hash: '#Lnk'})" />
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
}
</script>

<style lang="sass" scoped>

.no-data
  background-image: url('../../assets/images/inventory-no-data-box.png')
  background-position: 70px 100%
  background-size: 85% 90%
  background-repeat: no-repeat
  min-height: 220px
  @media (max-width: 762px)
    background-image: unset
</style>