<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 1100px;">Manage Your Finances with Ease Using Our Budget Tracking and Management Feature</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Get Real-Time Tracking and Detailed Analytics to Make Informed Decisions</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Stay on Top of your expenses to Track your Expenses in Real-Time</p>
        <p class="subtitle-3 mb-3">With our budget tracking and management feature, you can monitor your expenses in real-time. This means you can see how much you're spending on supplies, equipment, and other expenses as they happen. No more guesswork or waiting for a monthly statement - our tool gives you up-to-date information so you can make informed decisions about your spending.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Tracking_Budget/tracking_budget_1.png" alt="" class="" style="max-width: 400px">
        </p>
        <p class="title-2">Keep Your Expenses Organized to Separate your Front and Back Office Supplies</p>
        <p class="subtitle-3 mb-3">This feature allows you to decide if you want to allocate an item to the front or back office budget. By allowing you to separate these budgets, you can more accurately understand your budgets. We even allow you to put large one-time purchases into a miscellaneous category so it doesn’t affect either budget.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Tracking_Budget/tracking_budget_2.png" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">Analytics on your Spending Habits</p>
        <p class="subtitle-3 mb-3">With Ordo, you’ll get detailed analytics on your spending habits. You can filter by date or time period and see how much you’ve spent compared to previous months. This information helps you make more informed decisions about your finances and can help you identify opportunities to cut costs and improve your bottom line.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Tracking_Budget/tracking_budget_3.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/ten.svg)
</style>