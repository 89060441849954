import moment from 'moment'
export default {
  data: () => ({
    period: null,
  }),
  methods: {
    setPeriod(filters){
      this.showBudget = true
      this.showRemainBudget = true
      this.showTotalSpent = true
      this.showAmountSpent = false
      if(filters.selectedDate != null && filters.selectedDate.startDate != null && filters.selectedDate.endDate != null){
        let st = moment(filters.selectedDate.startDate).format('DDMMYYYY')
        let en = moment(filters.selectedDate.endDate).format('DDMMYYYY')
        let thisMonthSt = moment().startOf('month').format('DDMMYYYY')
        let thisMonthEn = moment().endOf('month').format('DDMMYYYY')
        let thisYearSt = moment().startOf('year').format('DDMMYYYY')
        let thisYearEn = moment().endOf('year').format('DDMMYYYY')
        let thisQuarterSt = moment().startOf('quarter').format('DDMMYYYY')
        let thisQuarterEn = moment().endOf('quarter').format('DDMMYYYY')
        let lastQuarterSt = moment().startOf('quarter').subtract(1, 'quarter').format('DDMMYYYY')
        let lastQuarterEn = moment().endOf('quarter').subtract(1, 'quarter').format('DDMMYYYY')
        let lastMonthSt = moment().subtract(1, 'month').startOf('month').format('DDMMYYYY')
        let lastMonthEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last2MonthsSt = moment().subtract(2, 'month').startOf('month').format('DDMMYYYY')
        let last2MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last3MonthsSt = moment().subtract(3, 'month').startOf('month').format('DDMMYYYY')
        let last3MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let last12MonthsSt = moment().subtract(12, 'month').startOf('month').format('DDMMYYYY')
        let last12MonthsEn = moment().subtract(1, 'month').endOf('month').format('DDMMYYYY')
        let lastYearSt = moment().subtract(1, 'year').startOf('year').format('DDMMYYYY')
        let lastYearEn = moment().subtract(1, 'year').endOf('year').format('DDMMYYYY')
        let thisWeekSt = moment().startOf('week').format('DDMMYYYY')
        let thisWeekEn = moment().endOf('week').format('DDMMYYYY')
        let nextWeekSt = moment().add(1, 'week').startOf('week').format('DDMMYYYY')
        let nextWeekEn = moment().add(1, 'week').endOf('week').format('DDMMYYYY')
        let nextMonthSt = moment().add(1, 'month').startOf('month').format('DDMMYYYY')
        let nextMonthEn = moment().add(1, 'month').endOf('month').format('DDMMYYYY')
        if(st == thisMonthSt && en == thisMonthEn)
          this.period = this.currentMonth
        else if(st == thisYearSt && en == thisYearEn)
          this.period = moment().format('YYYY')
        
        else if(st == thisQuarterSt && en == thisQuarterEn)
          this.period = `${moment(thisQuarterSt, 'DDMMYYYY').format('MMM Do')} - ${moment(thisQuarterEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == lastQuarterSt && en == lastQuarterEn)
          this.period = `${moment(lastQuarterSt, 'DDMMYYYY').format('MMM Do')} - ${moment(lastQuarterEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == lastMonthSt && en == lastMonthEn)
          this.period = moment(lastMonthSt, 'DDMMYYYY').format('MMMM')
        else if(st == last2MonthsSt && en == last2MonthsEn)
          this.period = `${moment(last2MonthsSt, 'DDMMYYYY').format('MMM Do')} - ${moment(last2MonthsEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == last3MonthsSt && en == last3MonthsEn)
          this.period = `${moment(last3MonthsSt, 'DDMMYYYY').format('MMM Do')} - ${moment(last3MonthsEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == last12MonthsSt && en == last12MonthsEn)
          this.period = `${moment(last12MonthsSt, 'DDMMYYYY').format('MMM Do')} - ${moment(last12MonthsEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == lastYearSt && en == lastYearEn)
          this.period = moment(lastYearSt, 'DDMMYYYY').format('YYYY')
        else if(st == thisWeekSt && en == thisWeekEn)
          this.period = `${moment(thisWeekSt, 'DDMMYYYY').format('MMM Do')} - ${moment(thisWeekEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == nextWeekSt && en == nextWeekEn)
          this.period = `${moment(nextWeekSt, 'DDMMYYYY').format('MMM Do')} - ${moment(nextWeekEn, 'DDMMYYYY').format('MMM Do')}`
        else if(st == nextMonthSt && en == nextMonthEn)
          this.period = `${moment(nextMonthSt, 'DDMMYYYY').format('MMM Do')} - ${moment(nextMonthEn, 'DDMMYYYY').format('MMM Do')}`
        else{
          this.showBudget = false
          this.showRemainBudget = false
          this.showTotalSpent = false
          this.showAmountSpent = true
          this.period = `${moment(st, 'DDMMYYYY').format('MMM Do')} - ${moment(en, 'DDMMYYYY').format('MMM Do')}`
        }
      }
      if(this.period == null) this.period = this.currentMonth
    },
    onFilterChange(filters, func){
      if(filters.futureCast){
        if(func) func(filters)
        return
      }
      let obj = {}
      if(filters.budget_type) obj.budget_type = filters.budget_type
      // if(filters.date_range) obj.date_range = filters.date_range
      if(filters.selectedDate.startDate && filters.selectedDate.endDate){
        let sta = moment(filters.selectedDate.startDate).format('YYYY-MM-DD')
        let end = moment(filters.selectedDate.endDate).format('YYYY-MM-DD')
        obj.start_date = sta
        obj.end_date = end
      }
      if(filters.type) obj.type = filters.type
      if(filters.search) obj.search = filters.search
      if(
        (filters.selectedDate.startDate && filters.selectedDate.endDate)
        || (!filters.selectedDate.startDate && !filters.selectedDate.endDate)
      ) {
        if(func) func(obj)
      }
    },
    dateRangeToPeriod(startDate, endDate) {
      let dateRange = 'nextWeek';
      startDate = moment(filters.selectedDate.startDate).format('DDMMYYYY')
      endDate = moment(filters.selectedDate.endDate).format('DDMMYYYY')
      const timeDifference = endDate - startDate
      let totalDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
      totalDays = moment(totalDays).format('DDMMYYYY')
      const today = moment().format('DDMMYYYY')
      let startDateDifference = startDate - today;
      startDateDifference = Math.floor(startDateDifference / (24 * 60 * 60 * 1000))

      if (startDateDifference > 6) {
        if (totalDays < 7) {
          dateRange = 'nextWeek';
        }
        else if (totalDays < 14) {
          dateRange = 'next2Weeks';
        }
        else if (totalDays < 21) {
          dateRange = 'next3Weeks';
        }
        else if (totalDays < 28) {
          dateRange = 'next4Weeks';
        }

      } else {
        if (totalDays < 7) {
          dateRange = 'thisWeek';
        }
        else if (totalDays < 14) {
          dateRange = 'next2Weeks';
        }
        else if (totalDays < 21) {
          dateRange = 'next3Weeks';
        }
        else if (totalDays < 28) {
          dateRange = 'next4Weeks';
        }
      }
      return dateRange;
    }
  },
}