import OfficeMixin from "./OfficeMixin"
import { mapActions } from 'vuex'
export default {
  mixins: [OfficeMixin],
  data: () => ({
    quantity: 1,
    updatingQuantity: false,
    vendorCredentials: [
      {
        slug: 'net_32',
        username: ''
      },
    ],
    submitting: false,
    deleting: false,
  }),
  computed: {
    unitPrice(){
      return this.product.price
    },
    price(){
      let p = this.unitPrice
      return parseFloat((this.quantity * p).toFixed(2))
    },
  },
  methods: {
    ...mapActions('CART', ['UPDATE_CART', 'DELETE_CART', 'ADD_PRODUCTS_TO_CART']),
    increaseQuantity(update = false, cartByTime = true){
      this.quantity += 1
      if(update){
        this.update(undefined, cartByTime)
      }
    },
    decreaseQuantity(update = false, cartByTime = true){
      if(this.quantity > 1) this.quantity -= 1
      if(update){
        this.update(undefined, cartByTime)
      }
    },
    async update(preD, cartByTime = true){
      if(this.updatingQuantity) return
      this.updatingQuantity = true
      try{
        let payload = preD || {
          office: this.officeId,
          cart: this.order.id,
          quantity: this.quantity,
        }
        let res = await this.UPDATE_CART(payload)
        let index = this.$store.state.CART.cartProducts.findIndex(c => c.id == res.id)
        if(index > -1) this.$store.state.CART.cartProducts[index].quantity = res.quantity
        let indexT = this.$store.state.CART.cartProductsByTime.findIndex(c => c.id == res.id)
        if(indexT > -1) this.$store.state.CART.cartProductsByTime[index].quantity = res.quantity
        // this.$BUS.$emit('cart-updated',res)
        // this.$BUS.$emit('fetch-cart', cartByTime)
      }catch(e){console.log(e)}
      this.updatingQuantity = false
    },
    async deleteCart(payload){
      if(this.deleting) return
      this.deleting = true
      try{
        const orderId = payload.cart
        let res = await this.DELETE_CART(payload)
        // this.$BUS.$emit('cart-item-deleted', orderId)
        // this.$BUS.$emit('fetch-cart')
        this.$store.state.CART.cartProducts = this.$store.state.CART.cartProducts.filter(c => c.id != orderId)
        this.$store.state.CART.cartProductsByTime = this.$store.state.CART.cartProductsByTime.filter(c => c.id != orderId)
      }catch(e){}
      this.deleting = false
    },
    async addToCart(){
      if(this.submitting) return
      this.submitting = true
      try{
        let pObj = Object.assign({}, this.product, {vendor: parseInt(this.product.vendor.id)}, {price: this.unitPrice.toString()})
        delete pObj.vendor_id
        let payload = {
          product: pObj,
          quantity: this.quantity,
          office: this.officeId
        }
        await this.ADD_PRODUCTS_TO_CART(payload)
        this.$BUS.$emit('fetch-cart')
        this.$BUS.$emit('open-cart-tab')
      }catch(e){}
      this.submitting = false
    },
  },
}