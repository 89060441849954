<template>
  <div class="notification-settings d-card py-3 px-3">
    <p class="d-title mt-2 mb-0" style="font-size: 18px;">Notification Center</p>
    <p class="mb-3 d-desc__m551">Stay up to date on all your Ordo notifcations</p>
    <div class="filters d-flex mb-3">
      <div class="bx">
        <div class="select-wrapper h-100">
          <b-select class="custom-input custom-select" v-model="filterBy.date_range" :options="months">
            <template #first>
              <option :value="null" disabled>Select Preset Range</option>
            </template>
          </b-select>
        </div>
      </div>
      <p class="d-desc mb-0 l-h-1 mx-3 pt-12-px">or</p>
      <div class="bx__3 d-flex align-items-center">
        <div class="select-wrapper h-100">
          <date-range-picker class="custom-date-picker" ref="DateRangePicker" minDate="2019-12" maxDate="2023-12" :timePicker="false" v-model="filterBy.selectedDate">
            <template v-slot:input style="min-width: 350px;">
              <p class="mb-0 d-desc text-line-1" style="line-height: 1">
                <span v-if="filterBy.selectedDate.startDate && filterBy.selectedDate.endDate">{{ formatDateTime(filterBy.selectedDate.startDate) }} - {{ formatDateTime(filterBy.selectedDate.endDate) }}</span>
                <span v-else>Select Date Range</span>
              </p>
            </template>
          </date-range-picker>
        </div>
      </div>
      <Button text="Update" class="success px-5 ml-4" />
    </div>
    <div class="notification-card px-3 py-5 mb-4" v-if="notifications.length == 0">
      <no-notification title="No Notifications" desc="Currently you don’t have any notifications. Once you do they will display here." />
    </div>
    <div v-else>
      <div v-for="(n) in notifications" :key="n.id" class="notification-card px-3 py-3 mb-3">
        <notification-card-detail :n="n" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import NoNotification from '@/components/dashboard/NoNotification'
import Button from '@/components/ui/Button'
import NotificationCardDetail from '@/components/dashboard/NotificationCardDetail'
export default {
  components: { DateRangePicker, NoNotification, NotificationCardDetail, Button, },
  data: () => ({
    filterBy: {
      date_range: 'thisMonth',
      selectedDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
      },
    },
  }),
  computed: {
    ...mapGetters('NOTIFICATION', ['notifications']),
    months(){
      return [
        { text: 'Current Month : ' + moment().format('MMMM'), value: 'thisMonth'},
        { text: 'Current Year', value: 'thisYear'},
        { text: 'Current Quarter', value: 'thisQuarter'},
        { text: 'Previous Quarter', value: 'lastQuarter'},
        { text: 'Previous Month', value: 'lastMonth'},
        { text: 'Previous 2 Months', value: 'last2Months'},
        { text: 'Previous 3 Months', value: 'last3Months'},
        { text: 'Previous 12 Months', value: 'last12Months'},
        { text: 'Last Year', value: 'lastYear'},
        { text: 'Custom Date Range', value: 'customDate'},
      ]
    },
  },
  methods: {
    openDateRangePicker(){
      setTimeout(() => {
        this.$refs.DateRangePicker.togglePicker()
      }, 100)
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d).format("MM/DD/YY");
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/variables'
.select-wrapper
  width: 100%
  background-color: transparent
  .custom-select
    background: unset
    font-size: 12px !important
    font-family: GILROY-Medium
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1
    color: #788288
    text-overflow: ellipsis
    padding-right: 0px
    padding-left: 0px
    max-width: max-content
.bx
  height: 38px
  display: flex
  justify-content: space-between
  align-items: center
  border-radius: 4px
  border: solid 1px #eaeaeb
  background-color: #fafafb
  .select-wrapper
    padding: 0.3rem 1rem
    background-color: unset
    .custom-input
      background-color: transparent
      height: 100% !important
      border: none
  &__3
    @extend .bx
    ::v-deep .select-wrapper
      width: 100%
      display: flex
      align-items: center
      .reportrange-text
        font-size: 10px !important
        color: #13add9 !important
        border: none !important
        background-color: unset !important
        padding: 0 !important
        height: 100% !important
.notification-card
  border-radius: 6px
  border: solid 1px #e7edee
.notification-settings
  .check-switch-box
    min-height: 57px
    border-radius: 4px
    border: solid 1px #E7ECEE
    display: flex
    justify-content: space-between
    align-items: center
  ::v-deep .custom-chk
    input
      &:focus:not(:checked) ~ .custom-control-label::before
        // border-color: #{$red2} !important
    .custom-control-label
      &::before
        // border-color: #{$red2}
        // background-color: #{$red2}
      &::after
        // background-color: #ffffff
</style>