<template>
  <div>
    <div class="ven-card-image d-flex align-items-center justify-content-center mb-3">
      <img :src="vendor.image" alt="">
      <div class="coming-soon" v-if="vendor && vendor.comingSoon">
        <p class="mb-0">COMING SOON</p>
      </div>
    </div>
    <div class="d-flex gap-8-px justify-content-center align-items-center">
      <!-- <p class="mb-0 title-1">{{ vendor.title }}</p> -->
      <RouterLink :to="vendor.to" class="mb-0 title-2__link d-block">View Details <RightArrow class="mb-2-px w-16-px h-16-px" /></RouterLink>
    </div>
  </div>
</template>

<script>
import RightArrow from '@/components/svg/icons/solid/RightArrow.vue'
export default {
  props: {
    vendor: Object,
  },
  components: { RightArrow, },
}
</script>

<style lang="sass" scoped>
.ven-card-image
  border-radius: 12px
  background-color: #FFFFFF
  padding: 2rem
  height: 150px
  position: relative
  box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  img
    max-width: 100%
    max-height: 100%
  .coming-soon
    position: absolute
    right: 0
    bottom: 0
    background-color: rgba(80, 169, 213, 0.26)
    padding: 0.3rem 1rem
    border-radius: 4px 0px 12px
    p
      font-family: GILROY-BOLD
      font-size: 10px
      color: #50A9D5
      line-height: 1
      margin-top: 2px
.title-1
  font-family: GILROY-BOLD
  font-size: 18px
  color: #274458
.title-2
  font-family: GILROY-BOLD
  font-size: 16px
  color: #274458
  &__link
    @extend .title-2
    font-family: GILROY-SEMIBOLD
    color: #50A9D5
    font-size: 16px
    &:hover
      text-decoration: none
.subtitle-1
  font-family: GILROY-MEDIUM
  font-size: 14px
  color: #788288
</style>