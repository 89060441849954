<template>
  <div class="app-layout d-lg-flex container-fluid">
    <app-header :toggleSidebar="toggleSidebar" class="d-flex d-lg-none" />
    <div class="wrapper">
      <!-- <div class="sidebar-back"></div> -->
      <div class="super-admin-layout"></div>
      <div class="users-layout"></div>
      <div v-if="isSuperAdmin">
        <admin-sidebar v-if="sidebar" @toggle-sidebar="toggleSidebar" />
      </div>
      <div v-else>
        <sidebar v-if="sidebar" @toggle`-sidebar="toggleSidebar" />
      </div>
    </div>
    <div class="right_side" v-if="isSuperAdmin">
      <!-- Search Bar -->
      <div class="search-bar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 50 50"
        >
          <path
            d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"
          ></path>
        </svg>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search"
          @input="handleSearch"
        />
      </div>

        <!-- Table Start -->
        <div class="table-responsive display_none_mobile">
          <table
            class="table table-striped super-admin-table"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-show-export="true"
            data-click-to-select="true"
            data-toolbar="#toolbar"
          >
            <thead>
            <tr class="table-row">
              <th data-sortable="true" scope="col">ID</th>
              <th data-sortable="true" scope="col">Office</th>
              <th data-sortable="true" scope="col">Email</th>
              <th data-sortable="true" scope="col">Name</th>
              <th scope="col">Created Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr-bg-white" v-for="user in userData" :key="user.id">
              <td scope="row">{{ user.id }}</td>
              <td>{{ user.office }}</td>
              <td>{{ user.email }}</td>
              <td>
                <!-- <img src="../assets/images/user_profile.png" alt="" /> -->
                {{ user.name }}
              </td>
              <td>{{ user.createdDate }}</td>
              <td class="">
                <button class="btn custom-btn primary btn-secondary" @click="userLogin(user.id)">
                  Login
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      
      <!-- Pagination Start-->
      <di  v class="pagination">
        <div>
          <p class="p-first">{{ startIndex + 1 }}-{{ endIndex + 1 }} of {{ totalResults }}</p>
        </div>
        <div class="select-pagination">
          <!-- <div>
            <p>
              Rows per page:
              <select class="form-select" aria-label="Default select example">
                <option v-for="page in totalPages" :key="page" :value="page" >{{ page }}</option>
              </select>
            </p>
          </div> -->
          <div class="overflow-auto d-flex align-items-center border --border-radius-5">
            <button class="btn btn-link p-2" @click="firstPage" :disabled="currentPage === 1"> {{ '<<' }} </button>
            <button class="btn btn-link p-2" @click="prevPage" :disabled="currentPage === 1"> {{ '<' }} </button>
            <div class="text-primary font-weight-bold p-2">{{ currentPage }}</div>
            <button class="btn btn-link p-2" @click="nextPage" :disabled="currentPage === totalPages">{{ '>' }}</button>
            <button class="btn btn-link p-2" @click="lastPage" :disabled="currentPage === totalPages"> {{ '>>' }} </button>
          </div>
        </div>
      </di>
      <!-- Pagination End-->
      <!-- Table End -->


      
    </div>
    <div class="rt-view-wrapper" v-else>
      <grow-loader v-if="verifyingToken" />
      <div v-else style="position: relative">
        <dash-head
          class="d-h mb-4"
          style="position: sticky; top: 0; z-index: 999"
        />
        <offices-picker
          class="offices-picker d-h mb-4"
          style="
            position: sticky;
            top: 0;
            z-index: 999;
            background-color: #ebf4f7;
          "
        />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/Sidebar";
import AdminSidebar from "@/components/sidebar/admin_sidebar";
import AppHeader from "@/components/header/AppHeader";
import DashHead from "@/components/dashboard/DashHead";
import OfficesPicker from "@/views/admin_dashboard/components/offices_picker";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex'
import GrowLoader from "@/components/loaders/GrowLoader.vue";

export default {
  components: {
    Sidebar,
    AdminSidebar,
    AppHeader,
    DashHead,
    GrowLoader,
    OfficesPicker,
  },
  data: () => ({
    sidebar: false,
    verifyingToken: false,
    isSuperAdmin: false,
    userData: null, // Add userData property to store UsersRes
    currentPage: 1,
    totalPages: null,
    perPage: null,
    totalResults: null,
    globalToken: null,
  }),
  created() {
    this.chkWinWidth();
    this.verifyToken();
    window.addEventListener("resize", this.chkWinWidth);
  },
  watch: {
    $route(v) {
      this.verifyToken();
    },
  },

  computed: {
    ...mapGetters('USER', ['token']),
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      return Math.min(this.startIndex + this.perPage - 1, this.totalResults - 1);
    },
  },

  methods: {
    ...mapActions("USER", ["VERIFY_TOKEN", "FETCH_USERS", "LOGIN_IMPERSONATE"]),
    ...mapActions(["CLEAR"]),
    ...mapActions('OFFICE', ['TEAM_MEMBERS']),
    ...mapMutations('USER', ['UPDATE_PROFILE']),
    isAdminView() {
      const path = location.pathname;
      return (
        path.includes("admin-dashboard") ||
        path.includes("procedure-analytics") ||
        path.includes("orders-analytics") ||
        path.includes("financial-analytics") ||
        path.includes("admin-orders")
      );
    },

    async userLogin(userId) {
      const aToken = this.token;
      let payload = {
        aToken,
        userId
      }
      let res = await this.LOGIN_IMPERSONATE(payload);

      // redirect to dashboard of selected user
      if (res.profile && res.profile.company && res.profile.company.on_boarding_step > 1) {
        this.isSuperAdmin = false;
        let payload = {
          company: res.profile.company.id,
          office: res.profile.company.offices[0]?.id,
        }
        let members = await this.TEAM_MEMBERS(payload)
        let mem = members.find(m => m.email == this.profileEmail)
        if (mem) {
          this.UPDATE_PROFILE({
            role: mem.role
          })
        }
      }   
      else {
        alert('User OnBoarding Not Found');
      }
    },

    logout() {
      this.CLEAR();
      // Store the current route in localStorage
      // localStorage.setItem("lastVisitedRoute", this.$route.fullPath);

      // Redirect to the login page
      window.location = window.location.origin + "/login";
      // this.goTo({name: 'Login'})
    },
    async verifyToken() {
      this.verifyingToken = true;
      try {
        if (this.$route.name == "CreateAnOrder") this.VERIFY_TOKEN();
        else {
          let res = await this.VERIFY_TOKEN();
          this.isSuperAdmin = res.data.profile.is_superuser;
          let aToken = res.data.token;
          if(this.isSuperAdmin === true) {
            this.userDataMap(aToken);
          }
        } 
        // No need to handle else case here
      } catch (e) {
        // Handle verification error
        console.error("Verification Error:", e);
        // Redirect to the default route (e.g., dashboard) on error
        this.logout();
      }
      this.verifyingToken = false;
    },

    async userDataMap(aToken) {
      
        this.globalToken = aToken;
        let payload = {
          aToken,
          currentPage: this.currentPage
        }
        let UsersRes = await this.FETCH_USERS(payload);
        this.totalResults = UsersRes.total;
        this.totalPages = Math.round(this.totalResults/10);
        this.currentPage = UsersRes.current_page;
        this.perPage = UsersRes.per_page;
        this.userData = UsersRes.data.map(user => ({
          id: user.id,
          office: user.company ? user.company.offices[0]?.name : 'NA',
          email: user.email,
          name: user.first_name + ' ' + user.last_name,
          createdDate: this.formatDate(user.date_joined),
          pageNumber: user.current_page,
          pageSize: user.per_page
        }));
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.userDataMap(this.globalToken);
      }
    },
    firstPage() {
      if (this.currentPage > 1) {
        this.currentPage = 1;
        this.userDataMap(this.globalToken);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.userDataMap(this.globalToken);
      }
    },
    lastPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage = this.totalPages;
        this.userDataMap(this.globalToken);
      }
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero indexed, so add 1
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },

    chkWinWidth() {
      if (window.innerWidth >= 992) this.sidebar = true;
      else this.sidebar = false;
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>
<!-- <script>
  export default {
    data() {
      return {
        perPage: 3,
        currentPage: 1,
        items: [
          { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
          { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
          { id: 3, first_name: 'Barney', last_name: 'Rubble' },
          { id: 4, first_name: 'Betty', last_name: 'Rubble' },
          { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
          { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
          { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
          { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
          { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' }
        ]
      }
    },
    computed: {
      rows() {
        return this.items.length
      }
    }
  }
</script> -->

<style>
.sidebar-back[data-v-60ba8c99] {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 256;
  padding: 60px 20px;
}
.--border-radius-5 {
  border-radius: 5px;
}
.b-con[data-v-60ba8c99] {
  width: 215px;
  padding: 1.5rem 1rem;
}
.sidebar .menus .menu[data-v-60ba8c99] {
  height: 44px;
  max-width: 215px;
  display: flex;
  align-items: center;
  padding: 0 0px;
}
.sidebar .menus .menu.active[data-v-60ba8c99] {
  width: 215px;
  padding: 0 10px;
}
.logo_img img {
  width: 26px;
  margin-left: 7px;
  margin-top: 5px;
}
.logo_img {
  background: white;
  height: 39px;
  width: 60px;
  border-radius: 20px;
}
.Signbtn {
  color: white;
  border: none;
  padding: 10px, 20px, 10px, 20px !important;
  background-color: #ed6666;
  border-radius: 7px;
  width: 200px;
  position: fixed;
  bottom: 25px;
  padding: 6px;
  font-size: 15px;
}
.right_side {
  border-radius: 8px;
  margin: 40px 0px 0px 25px;
  background-color: white;
  width: 100%;
  height: 890px;
  justify-content: center;
}
.search-bar {
  display: flex;
  justify-content: start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.search-bar svg {
  position: absolute;
  top: 45px;
  left: 63px;
  fill: #75797e;
}
.search-bar input {
  margin-left: 3rem;
  padding: 10px 45px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  width: 40%;
}
.search-bar input:focus {
  outline: none;
}
.table-row {
  background-color: #ebf4f8;
}
button {
  color: white;
  padding: 4px 30px;
  background-color: #13add9;
  border-radius: px;
  font-size: 16px;
  border: none;
}
.super-admin-table th {
  font-weight: 400 ;
  padding: 12px ;
  padding-left: 4rem ;
  border-top: 0px ;
  vertical-align: middle ;
  color: #495057;
  font-size: 14px ;
}
.super-admin-table td {
  font-weight: 400 ;
  padding: 12px ;
  padding-left: 4rem ;
  border-top: 0px ;
  vertical-align: middle ;
  color: #495057;
  font-size: 14px ;
}
.table_img img {
  width: 40px;
  margin: 0px 20px 0px 0px;
  border-radius: 25px;
}
.tr-bg {
  background-color: #f8f8f8 !important;
}
.tr-bg-white {
  background-color: white !important;
}
.pagination {
  background-color: #f8f8f8;
  padding: 8px 72px 0px 60px !important;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.form-select {
  font-size: 14px !important;
  width: 42px;
  color: #333333;
  padding: 2px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
.select-pagination {
  display: flex;
}
.select-pagination p {
  margin-top: 15px;
}

.table-row th {
  font-size: 16px !important;
  font-weight: 600 !important;
}


/* // TO Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1399.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 1199.98px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
 
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  
  .search-bar svg {
    top: 45px;
    left: 23px;
  }
  .right_side {
    border-radius: 8px;
    margin: 20px 0px 0px 0px;
  }
  .super-admin-table th,
   td {
    padding: 3px !important;
    padding-left: 7px !important;
    font-size: 13px !important;
  }
  button {
    padding: 4px 20px;
    border-radius: 4px;
    font-size: 12px;
  }
  .pagination {
    flex-wrap: wrap;
    display: flex;
    background-color: #f8f8f8;
    padding: 0px 0px 0px 0px !important;
    align-items: center;
  }
  .select-pagination p {
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 13px;
  }
  .pagination .p-first {
    margin-left: 13px;
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 13px;
  }
  .select-pagination {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  form-select {
    height: 25px;
    font-size: 13px !important;
    width: 42px;
    padding: 2px;
  }

  .table_img {
    display: flex;
    align-items: center;
  }
  .table_img img {
    margin: 6px 8px;
  }
}
</style>

<style lang="sass" scoped>
.offices-picker
  background-color: transparent !important
  position: absolute !important
  .card
    background-color: transparent !important


.app-layout
  margin: 0 auto
  position: relative
  @media screen and (max-width: 992px)
    position: relative
  .wrapper
    .sidebar-back
      position: fixed
      height: 100vh
      width: 100px
      left: 0
      top: 0
      background-color: #ffffff
      @media screen and (max-width: 479px)
        display: none
    @media screen and (max-width: 479px)
      .sidebar
        max-width: 92%
        padding: 0
        box-shadow: 0px 2px 8px #9e9e9e
  .rt-view-wrapper
    padding: 32px 24px
    padding-top: 10px
    width: calc( 100vw - 220px )
    height: 100vh
    overflow-x: auto
    position: relative
    @media screen and (min-width: 992px)
      padding-right: 10px
    @media screen and (max-width: 992px)
      width: 100vw
      max-width: 100%
      overflow: initial
      padding: 1rem 0
      height: auto
</style>
