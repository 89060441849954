import axios from '@/axios'

const state = {
  notifications: [],
}

const getters = {
  notifications: s => s.notifications,
  unreadNotifications: s => s.notifications.filter(n => !n.is_read),
}

const mutations = {
  SET_NOTIFICATIONS: (s, p) => {
    let prev = s.notifications
    let curr = []
    p.forEach(n => {
      let index = prev.findIndex(pn => pn.id == n.id)
      if(index > -1) curr.push(prev[index])
      else curr.push(n)
    })
    s.notifications = curr
  },
  UPDATE_NOTIFICATION: (s, p) => {
    let i = s.notifications.findIndex(n => n.id == p.id)
    if(i > -1) s.notifications[i] = p
  },
  TOGGLE_NOTIFICATION_READ: (s, p) => {
    let index = s.notifications.findIndex(n => n.id == p)
    if(index > -1) s.notifications[index].is_read = !s.notifications[index].is_read
  },
  TOGGLE_ALL_NOTIFICATION_READ: (s, p) => {
    for(let i = 0; i< s.notifications.length; i++){
      s.notifications[i].is_read = !s.notifications[i].is_read
    }
  },
}

const actions = {
  GET_NOTIFICATION: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("notifications").get(payload);
      commit('SET_NOTIFICATIONS', data.data)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
  MARK_NOTIFICATION: async ({dispatch}, payload) => {
    try {
      const notification_id = payload.notification_id
      let url = `notifications/${notification_id}`
      delete payload.notification_id
      let { data } = await axios.reqApi(url).patch(payload);
      // commit('UPDATE_NOTIFICATION', data.data)
      await dispatch('GET_NOTIFICATION')
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
  MARK_ALL_NOTIFICATIONS_AS_READ: async ({getters, dispatch}) => {
    try {
      let url = `notifications/read`
      let payload = {
        notifications: getters.notifications.map(n => n.id),
        mark_all: true,
      }
      let { data } = await axios.reqApi(url).post(payload);
      await dispatch('GET_NOTIFICATION')
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}