<template>
  <div class="terms d-card py-4 px-4">
    <p class="d-title">Terms of Use </p>
    <p class="d-title__13">Last Updated – April 30th, 2021 </p>
    <p class="d-title__13">Ordo Inc (also “Ordo”) provides a software platform for the procurement of dental and office products.</p>
    <p class="d-title__13">This statement of Terms of Use governs our relationship with dental practices and others who interact with Ordo.</p>
    <p class="d-title__13">Please read these Ordo Terms of Use (“Terms”) carefully before You purchase or use the Ordo Platform.</p>
    
    <p class="d-title">1. Definitions</p>
    <p class="d-desc">In these Terms, the expressions below shall have the meaning assigned to them in this clause, unless the context requires otherwise: </p>
    <p class="d-desc"><span class="d-title__13">You</span> refers to the individual accessing or using the Service, or Ordo, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. </p>
    <p class="d-desc"><span class="d-title__13">Ordo</span> refers to Ordo the company, platform and associated websites </p>
    <p class="d-desc"><span class="d-title__13">Account</span> refers to a unique account created for You to access our Service or parts of our Service. </p>
    <p class="d-desc"><span class="d-title__13">Goods</span> refer to the items offered for sale on the Service. </p>
    <p class="d-desc"><span class="d-title__13">Orders</span> mean a request by You to purchase Goods from Us. </p>
    <p class="d-desc"><span class="d-title__13">Service</span> refers to the Ordo Platform. </p>
    <p class="d-desc"><span class="d-title__13">Subscriptions</span> refer to the services or access to the Service offered on a subscription basis by the Ordo to You. </p>
    <p class="d-desc"><span class="d-title__13">Supplier</span> refers to the vendor from which you will place an order of goods. </p>
    
    <p class="d-title">2. Acknowledgment</p>
    <p class="d-desc">These are the Terms and Conditions governing the use of this Service and the agreement that operates between you and Ordo. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service. </p>
    <p class="d-desc">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service. </p>
    <p class="d-desc">By accessing or using the Service you agree to be bound by these Terms and Conditions. If you disagree with any part of these Terms and Conditions then you may not access the Service. </p>
    
    <p class="d-title">3. Privacy</p>
    <p class="d-desc">Your privacy is very important to us. We designed our <span class="d-title__13">Privacy Policy</span> to make important disclosures about how we collect and use your content and information. We encourage you to read it to help you make informed decisions about using Ordo. </p>
    <p class="d-desc">By using or accessing the Ordo Services, you agree to these conditions, as updated from time to time in accordance with our update policies in this document. Please read them carefully. </p>
    
    <p class="d-title">4. Copyright</p>
    <p class="d-desc">All content included in or made available through any Ordo Services, such as text, graphics, logos, button icons, images, audio clips, digital downloads, and data compilations is the property of Ordo and protected by United States and international copyright laws. </p>
    <p class="d-desc">The compilation of all content included in or made available through any Ordo Service is the exclusive property of Ordo, and is protected by U.S. and international copyright laws. </p>
    <p class="d-desc">Some content displayed by Ordo, including company names and logos of manufacturers and suppliers, as well as product names, descriptions, documentation, and images, may be owned by their originators rather than Ordo. </p>
    <p class="d-desc">As an authorized agent of our users, Ordo accesses and uses these content items for the sole purpose of providing the Services to our users, but claims no copyright to such materials owned and copyrighted by their originators. </p>
    <p class="d-desc">Companies that upload or enter content into Ordo give Ordo a permanent and unrestricted license to such content for all as long as the Ordo Services remain operational. </p>
    
    <p class="d-title">5. Communications</p>
    <p class="d-desc">When you use our Services, or send us an email or visit our website, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by posting news and notices on our website and by sending you emails. You agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing. </p>
    
    <p class="d-title">6. Subscriptions</p>
      <p class="d-title__2">Subscription Period </p>
      <p class="d-desc">The Service or some parts of the Service are available only with a paid Subscription unless you are in the software free trial period. The free trial period is 14 days (subject to change). You will be billed in advance on a recurring and periodic basis (either monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.</p>
      <p class="d-desc">At the end of each period, your subscription will automatically renew under the exact same conditions unless you cancel it or Ordo cancels it. </p>
      <p class="d-title__2">Subscription Cancellations</p>
      <p class="d-desc">You may cancel your Subscription renewal at any time. You will not receive a refund for the fees you already paid for your current subscription period and you will be able to access the Service until the end of your current subscription period. </p>
      <p class="d-desc">Except when required by law, paid subscription fees are non-refundable. </p>
      <p class="d-title__2">Billing</p>
      <p class="d-desc">You shall provide Ordo with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. </p>
      <p class="d-desc">Should automatic billing fail to occur for any reason, ORdo will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice. </p>
      <p class="d-title__2">Fee Changes</p>
      <p class="d-desc">Ordo, in its sole discretion and at any time, may modify the subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period. </p>
      <p class="d-desc">Ordo will provide you with reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your subscription before such change becomes effective. </p>
      <p class="d-desc">Your continued use of the service after the subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount. </p>
      
    <p class="d-title">7. User Accounts</p>
    <p class="d-desc">When you create an account Ordo, you must provide Ordo information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on Ordo Service. </p>
    <p class="d-desc">You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. </p>
    <p class="d-desc">You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of Your account. </p>
    <p class="d-desc">You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene. </p>
    
    <p class="d-title">8. Risk of Loss, Returns, Refunds and Title</p>
    <p class="d-desc">Information about products, including names, descriptions, images, and documentation, is gathered from various sources, including but not limited to websites and documentation of suppliers and product manufacturers. Ordo may attempt to curate such information, to the best of our ability, in an attempt to be as accurate as possible. However, Ordo does not warrant that product descriptions or other content of any Ordo Service is accurate, complete, reliable, current, or error-free. </p>
    <p class="d-desc">If you are unsure about the accuracy of a product’s information, we suggest cross-referencing the product information with authorized sellers of the product, or directly with the product’s manufacturer, to ensure accuracy. If a product discovered on or ordered through Ordo is not as described, your sole remedy is to return the product to the seller whence it came, pursuant to the terms of that seller’s return policy. </p>
    <p class="d-desc">Ordo assumes no responsibility for products ordered using information obtained on Ordo, and will not interact with sellers on your behalf to initiate or negotiate a return of any product. </p>
    
    <p class="d-title">9. Account Information From Third-Party Sites</p>
    <p class="d-desc">When you use the Ordo Services, you may direct Ordo to retrieve your own information maintained online by third-parties with which you have customer relationships, maintain accounts, or engage in purchase transactions to supply your organization (“Account Information”). Ordo does not review the Account Information for accuracy, legality or non-infringement. Ordo is not responsible for the Account Information or products and services offered by or on third-party sites. </p>
    <p class="d-desc">Ordo cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data, personalization settings or other service interruptions. Ordo cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any user data, communications or personalization settings. </p>
    
    <p class="d-title">10. Rights You Grant to Ordo as Your Authorized Agent </p>
    <p class="d-desc">By submitting information, data, passwords, usernames, other login information, materials and other content to Ordo through the Services, you are licensing that content to Ordo for the purpose of providing the Services. Ordo may use and store the content in accordance with this Agreement and our Privacy Policy. You represent that you are entitled to submit it to Ordo for use for this purpose, without any obligation by Ordo to pay any fees or be subject to any restrictions or limitations. </p>
    <p class="d-desc">When you sign up for a Ordo account, you designate Ordo as your authorized agent with permission to use your login information to third-party services, such as your suppliers, and represent that you are authorized to do so by your clinic or company. Ordo systems utilize this login information to access these third-party services’ websites on your behalf to obtain and analyze pricing, availability, order history, invoices, cart, and other information. </p>
    <p class="d-desc">By using the Services, you expressly authorize Ordo to access your Account Information maintained by identified third parties, on your behalf as your agent, and you expressly authorize such third parties to disclose your information to us. When you use the Ordo Services, you will be directly connected to the website for the third party you have identified. Ordo will submit information including usernames and passwords that you provide to log into the Site. You hereby authorize and permit Ordo to use and store information submitted by you to accomplish the foregoing and to configure the Services so that it is compatible with the third party sites for which you submit your information. </p>
    <p class="d-desc">Actions you expressly authorize Ordo to take on your behalf for all suppliers whose information you’ve submitted to Ordo include, but are not limited to, the following: </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Product Retrieval</span> - Ordo will locate a given product on a supplier’s website and view that product’s page or listing in order to serve you appropriate content. </li>
      <li class="d-desc"><span class="d-title__13">Availability Retrieval</span> - Ordo will obtain availability information, including stock or backorder status, quantities in stock, warehouse information, drop-ship information, and other availability information when available. </li>
      <li class="d-desc"><span class="d-title__13">Pricing Retrieval</span> - Ordo will obtain your own clinic’s pricing information for a product, including quantity discounts, promotions, and special instructions related to that product’s purchasing or shipment. </li>
      <li class="d-desc"><span class="d-title__13">Cart Modification</span> - Ordo will manage your cart on the supplier’s website by adding or removing products to/from your cart, or changing quantities in your cart. </li>
      <li class="d-desc"><span class="d-title__13">Order History Retrieval</span> - Ordo will retrieve and store invoices and other information on past orders from your suppliers to provide our order history and analytics features. </li>
      <li class="d-desc"><span class="d-title__13">Order Placement</span> - Ordo will place orders on your behalf with select third-party supplier websites </li>
    </ul>
    <p class="d-desc">Ordo takes the above actions only after you have signed up for a Ordo account as an authorized representative of your clinic or company. Ordo will not, except for purposes of providing the Services to you, take actions on your suppliers’ websites without explicit permission. </p>
    <p class="d-desc">For purposes of this Agreement and solely to provide the Account Information to you as part of the Services, you grant Ordo a limited power of attorney, and appoint Ordo as your attorney-in-fact and agent, to access third party sites, retrieve and use your information with the full power and authority to do and perform each thing necessary in connection with such activities, as you could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN Ordo IS ACCESSING AND RETRIEVING ACCOUNT INFORMATION FROM THIRD PARTY SITES, Ordo IS ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT OPERATES THE THIRD PARTY SITE. You understand and agree that the Services are not sponsored or endorsed by any third parties accessible through the Services. Ordo is not responsible for any payment processing errors or fees or other Services-related issues, including those issues that may arise from inaccurate account information.  </p>
    
    <p class="d-title">11. Pricing and Availability From Suppliers With Whom You Have a Prior Relationship</p>
    <p class="d-desc">For suppliers where Ordo acts as your Authorized Agent, any prices viewable to you on ORdo are the prices offered to you by the seller of that product. These prices are retrieved, on your behalf, in real time or via supplier-provided catalog, and represent the pricing specifically for your practice  just as you would see on that seller’s eCommerce website. </p>
    <p class="d-desc">Ordo makes no warranties to the accuracy of pricing or availability information. Despite our best efforts, a small number of the items in our catalog may be mispriced, or stock may be mislabeled, due to technical error of either the seller or Ordo. If the error resides with the seller, please contact the seller and/or refer to the seller’s policies for remedies. If the price of the item on Ordo does not match the price of the item on the seller’s eCommerce website, please contact us at hello@joinordo.com so that we may look into it. </p>
    <p class="d-desc">Pricing and availability information at any given supplier may be unavailable, from time to time, due to an error or downtime on a supplier’s website, or changes to a supplier’s website that makes Ordo interfacing with their website temporarily unavailable. Ordo makes no warranties of uptime or that any connected supplier will have pricing and availability retrievable at any given time. </p>
    
    <p class="d-title">12. AS IS" and "AS AVAILABLE" Disclaimer</p>
    <p class="d-desc">Ordo Service is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Ordo, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. </p>
    <p class="d-desc">Without limitation to the foregoing, Ordo provides no warranty or undertaking, and makes no representation of any kind that the Service will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected. </p>
    <p class="d-desc">Without limiting the foregoing, Ordo does not make any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of Ordo are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. </p>
    <p class="d-desc">Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. </p>
    
    <p class="d-title">13. Limitation of Liability</p>
    <p class="d-desc">Notwithstanding any damages that you might incur, the entire liability of Ordo and any of its suppliers under any provision of this Terms and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you through the Service or 100 USD if you haven't purchased anything through the Service. </p>
    <p class="d-desc">To the maximum extent permitted by applicable law, in no event shall Ordo or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if Ordo or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose. </p>
    <p class="d-desc">Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law. </p>
    
    <p class="d-title">14. Governing Laws and Disputes Resolution</p>
    <p class="d-desc">This agreement is governed by the laws of the United States and the laws of the state of Delaware, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and Ordo. </p>
    <p class="d-desc">If you have any concern or dispute about the Service, you agree to first try to resolve the dispute informally by contacting Ordo. </p>
    <p class="d-desc">Our goal is to provide you with great service, so we’ll try our best to resolve any disagreements that you have with us. If we can’t, then you and we both agree to resolve disputes related to your use of the Services or these Terms (each, a “Claim”) in binding arbitration instead of court, except that a Claim may be brought in small claims court if it qualifies for it. You and we also agree that either party may bring suit in court to enjoin the infringement or other misuse of intellectual property rights. </p>
    
    <p class="d-title">15. Site Policies, Modification, and Severability</p>
    <p class="d-title__2">Severability</p>
    <p class="d-desc">Please review our other policies, such as our Privacy Policy posted on this site. These policies also govern your use of Ordo Services. We reserve the right to make changes to our site, Privacy Policy, and Terms of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. </p>
    <p class="d-title__2">Notice and Procedure for Making Claims of Copyright Infringement </p>
    <p class="d-desc">If you believe that your work has been copied in a way that constitutes copyright infringement, please submit a report in writing to the above address, and please provide us with this information. </p>
    <ul>
      <li class="d-desc">A physical signature of the person authorized to act on behalf of the owner of the copyright interest </li>
      <li class="d-desc">A description of the copyrighted work that you claim has been infringed upon </li>
      <li class="d-desc">A description of where the material that you claim is infringing is located on the site </li>
      <li class="d-desc">Your address, telephone number, and e-mail address </li>
      <li class="d-desc">A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law </li>
      <li class="d-desc">A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf. </li>
    </ul>
    <p class="d-title__2">Additional Ordo Software Terms </p>
    <ul>
      <li class="d-desc"><span class="d-title__13">Use of the Ordo Software</span> - You may use Ordo Software solely for purposes of enabling you to use and enjoy the Ordo Services as provided by Ordo, and as permitted by the Terms of Use, these Software Terms and any Service Terms, including those of third party suppliers. You may not incorporate any portion of the Ordo Software into your own programs or compile any portion of it in combination with your own programs, transfer it for use with another service, or sell, rent, lease, lend, loan, distribute or sub-license the Ordo Software or otherwise assign any rights to the Ordo Software in whole or in part. You may not use the Ordo Software for any illegal purpose. We may cease providing any Ordo Software and we may terminate your right to use any Ordo Software at any time. Your rights to use the Ordo Software will automatically terminate without notice from us if you fail to comply with any of these Software Terms or the Terms of Use. Additional third party terms contained within or distributed with certain Ordo Software that are specifically identified in related documentation may apply to that Ordo Software (or software incorporated with the Ordo Software) and will govern the use of such software in the event of a conflict with these Terms of Use. All software used in any Ordo Service is the property of Ordo or its software suppliers and protected by United States and international copyright laws. </li>
      <li class="d-desc"><span class="d-title__13">Use of Third Party Services</span> - When you use the Ordo Software, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third party services may be subject to the separate policies, terms of use, and fees of these third parties. </li>
      <li class="d-desc"><span class="d-title__13">No Reverse Engineering</span> - You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, extract source code, or otherwise tamper with, the Ordo Software, whether in whole or in part, or create any derivative works from or of the Ordo Software. </li>
      <li class="d-desc"><span class="d-title__13">Update</span> - In order to keep the Ordo Software up-to-date, we may offer automatic or manual updates at any time and without notice to you. </li>
      <li class="d-desc"><span class="d-title__13">Export Regulations; Government End Users</span> - You must comply with all export and re-export restrictions and regulations of the Department of Commerce and other United States agencies and authorities that may apply to the Ordo Software. If you are a U.S. Government end user, we are licensing the Ordo Software to you as a "Commercial Item" as that term is defined in the U.S. Code of Federal Regulations (see 48 C.F.R. § 2.101), and the rights we grant you to the Ordo Software are the same as the rights we grant to all others under these Terms of Use. </li>
    </ul>
    <p class="d-title__2">Additional Conditions of to Ensure Safety</p>
    <p class="d-desc">We do our absolute best to keep Ordo safe. By using the Ordo Services, you agree to the following to help ensure the safety of our Services and the Ordo community: </p>
    <ul>
      <li class="d-desc">You will not post unauthorized commercial communications (such as spam) on Ordo. </li>
      <li class="d-desc">You will not collect users' content or information, or otherwise access Ordo, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission. </li>
      <li class="d-desc">You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on Ordo. </li>
      <li class="d-desc">You will not upload viruses or other malicious code. </li>
      <li class="d-desc">You will not solicit login information or access an account belonging to someone else. </li>
      <li class="d-desc">You will not bully, intimidate, or harass any user. </li>
      <li class="d-desc">You will not post content that: is hate speech, threatening, or pornographic; incites violence; or contains nudity or graphic or gratuitous violence. </li>
      <li class="d-desc">You will not attempt to sell any product that is illegal in any U.S. jurisdiction, or that you are not authorized to sell. </li>
      <li class="d-desc">You will not use Ordo to do anything unlawful, misleading, malicious, or discriminatory. </li>
      <li class="d-desc">You will not do anything that could disable, overburden, or impair the proper working or appearance of Ordo, such as a denial of service attack or interference with page rendering or other Ordo functionality. </li>
      <li class="d-desc">You will not facilitate or encourage any violations of this Statement or our policies. </li>
    </ul>
    <p class="d-title__2">Additional Conditions for Registration and Account Security </p>
    <p class="d-desc">Ordo users provide their real names, contact information, and clinic information, and we need your help to keep it that way. Here are some commitments you make to us relating to registering and maintaining the security of your account: </p>
    <ul>
      <li class="d-desc">You will not provide any false personal or clinic information on Ordo, or create an account for anyone other than yourself without permission. </li>
      <li class="d-desc">You will not create more than one account for your clinic. </li>
      <li class="d-desc">You will not misrepresent yourself for your clinic, by impersonating another person or clinic, on any part of Ordo. </li>
      <li class="d-desc">If we disable your account, you will not create another one without our permission. </li>
      <li class="d-desc">You will not use your Ordo account for your commercial gain, except for the purposes for which Ordo was specifically created to help clinics. </li>
      <li class="d-desc">You will keep your contact information accurate and up-to-date. </li>
      <li class="d-desc">You will not share your password, let anyone else access your account outside of your clinic, or do anything else that might jeopardize the security of your account. </li>
      <li class="d-desc">You will not transfer your account to anyone without first getting our written permission. </li>
    </ul>
    <p class="d-title__2">Additional Miscellaneous Conditions of Use </p>
    <ul>
      <li class="d-desc">This Statement makes up the entire agreement between the parties regarding Ordo, and supersedes any prior agreements. </li>
      <li class="d-desc">If any portion of this Statement is found to be unenforceable, the remaining portion will remain in full force and effect. </li>
      <li class="d-desc">If we fail to enforce any of this Statement, it will not be considered a waiver. </li>
      <li class="d-desc">Any amendment to or waiver of this Statement must be made in writing and signed by us. </li>
      <li class="d-desc">You will not transfer any of your rights or obligations under this Statement to anyone else without our consent. </li>
      <li class="d-desc">All of our rights and obligations under this Statement are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise </li>
      <li class="d-desc">Nothing in this Statement shall prevent us from complying with the law. </li>
      <li class="d-desc">This Statement does not confer any third party beneficiary rights. </li>
      <li class="d-desc">We reserve all rights not expressly granted to you. </li>
      <li class="d-desc">You will comply with all applicable laws when using or accessing Ordo. </li>
    </ul>
    
    <p class="d-title">16. Changes to These Terms and Conditions</p>
    <p class="d-desc">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. </p>
    <p class="d-desc">By continuing to access or use our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service. </p>
    
    <p class="d-title">17. Contact Us</p>
    <p class="d-desc">We welcome feedback, comments and suggestions for improvements to the Product/Services (“Feedback”) by emailing us at hello@joinordo.com. </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

</style>
