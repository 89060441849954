var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-page"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row page-contents"},[_c('div',{staticClass:"menu-sec mb-3 mb-lg-0"},[_c('div',{staticClass:"menus-wrp"},[_c('div',{staticClass:"menus mb-4"},_vm._l((_vm.menus),function(menu,mI){return _c('div',{key:mI,class:[
            'menu cursor-pointer pl-2',
            {
              active: menu.value == _vm.selectedMenu,
              'appended-settings': menu.appendedSettings,
            },
          ],on:{"click":function($event){return _vm.handleMenuClick(menu.value)}}},[_c('p',{staticClass:"mb-0 menu-text"},[_vm._v(_vm._s(menu.text))])])}),0),_c('div',{staticClass:"menus"},_vm._l((_vm.menus2),function(menu,mI){return _c('div',{key:mI,class:[
            'menu cursor-pointer pl-2',
            { active: menu.value == _vm.selectedMenu },
          ],on:{"click":function($event){return _vm.handleMenuClick(menu.value)}}},[_c('p',{staticClass:"mb-0 menu-text"},[_vm._v(_vm._s(menu.text))])])}),0)])]),_c('div',{staticClass:"oth-con"},[(_vm.selectedMenu === 'Ntfc')?_c('notification-center'):_vm._e(),(_vm.selectedMenu === 'Ofc')?_c('office-settings',{on:{"accordion-toggle":_vm.handleAccordionToggle}}):_vm._e(),(_vm.selectedMenu == 'Comp')?_c('sub-company-settings'):_vm._e(),(_vm.selectedMenu === 'Usr')?_c('user-settings'):_vm._e(),(_vm.selectedMenu === 'Bill')?_c('billing-settings',{on:{"accordion-toggle":_vm.handleAccordionToggle}}):_vm._e(),(_vm.selectedMenu === 'MTm')?_c('manage-team'):_vm._e(),(_vm.selectedMenu === 'Lnk')?_c('link-vendor-setting'):_vm._e(),(_vm.selectedMenu === 'OrderApproval')?_c('order-approval-settings'):_vm._e(),(_vm.selectedMenu === 'office_budget')?_c('OfficeBudgetSettings',{on:{"accordion-toggle":_vm.handleAccordionToggle}}):_vm._e(),(_vm.selectedMenu === 'terms')?_c('terms'):_vm._e(),(_vm.selectedMenu === 'privacy')?_c('privacy'):_vm._e()],1)]),_c('DashboardFooter',{staticClass:"mt-3"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }