<template>
  <div :class="['images-sec', {'inventory-product': inventoryProduct}]" v-if="skeleton">
    <skeleton-loader type="rect" :width="'100%'" :height="'100%'" :radius="8" :rounded="true" animation="wave" />
  </div>
  <div :class="['images-sec', { 'show-lg': inventoryProduct || showLg }, {'inventory-product': inventoryProduct}, {'image-short':size === 'sm'}]" v-else>
    <!-- <p class="mb-0 f-8-px">{{hasImage }} - {{imageIsLoaded}}</p> -->
    <div :class="['l-g', { 'inv': invText == 'My Inventory' }]" v-if="inventoryProduct || showLg">
      <p>{{invText}}</p>
    </div>
    <img :src="productImage" :alt="product.name" v-if="hasImage && imageIsLoaded">
    <div v-else class="d-flex flex-column justify-content-center align-items-center px-3 py-3 no-image-msg-container">
      <p class="msg-title__sm d-title__10 text-center my-0" v-if="noImageShort">No Image</p>
      <div v-else>
        <!-- <p class="msg-title d-title__10 text-center">Product Image Missing</p> -->
        <p class="msg-desc d-desc__6 text-center mb-0">Missing Product Image</p>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from 'skeleton-loader-vue'
export default {
  props: {
    product: Object,
    'no-image-short': Boolean,
    skeleton: Boolean,
    inventoryProduct: Boolean,
    invText: {type: String, default: 'My Inventory'},
    showLg: Boolean,
    size: String,
  },
  components: { SkeletonLoader, },
  data: () => ({
    imageIsLoaded: true
  }),
  watch: {
    product:{
      handler(){
        this.chkImageUrl()
      }, deep: true
    },
  },
  created(){
    this.chkImageUrl()
  },
  computed: {
    hasImage(){
      if(!this.product) return false
      if(this.product.image != null) return true
      return this.product && this.product.images && Array.isArray(this.product.images) && this.product.images.length > 0
    },
    productImage(){
      if(this.product.image != null) return this.product.image
      else return this.product.images[0].image
    },
  },
  methods: {
    chkImageUrl(){
      if(!this.hasImage){
        this.imageIsLoaded = false
        return
      }else {
        this.imageIsLoaded = true
      }
      if(!this.skeleton){
        try{
          let img = new Image()
          img.src = this.productImage
          img.onerror = () => {
            this.imageIsLoaded = false
            this.$forceUpdate()
          }
        }catch(e){
          console.log(e, this.product)
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.images-sec
  width: 100%
  height: 179px
  background-color: #ebf4f8
  border: solid 2px #ebf4f8
  border-radius: 8px
  // padding: 1.5px
  img
    max-height: 178px
    // border: solid 1.5px #F3F4F4
    width: 100%
    height: 100%
    border-radius: 6px
    // max-width: calc( 100% - 1px )
    // max-height: calc( 100% - 1px )
  &.inventory-product
    border-top-left-radius: 6px
    border-top-right-radius: 6px
  &.show-lg
    .l-g
      // background: linear-gradient(87.22deg, #E93CBE -5.51%, #50AAD5 30.61%, #5EBC3B 94.67%)
      background-image: url(./sidebar/Office_grad.svg)
      background-size: cover
      height: 28px
      border-top-left-radius: 6px
      border-top-right-radius: 6px
      border-bottom: solid 0.5px #ebf4f8
      display: flex
      justify-content: center
      align-items: center
      // &.inv
      //   background: #13ADD9
      p
        font-family: GILROY-BOLD
        font-size: 10px
        font-stretch: normal
        font-style: normal
        line-height: 1.2
        letter-spacing: normal
        text-align: center
        color: #fff
        margin: 0
    img
      max-height: calc(132px - 41px)
      border-top-left-radius: 0
      border-top-right-radius: 0
    .no-image-msg-container
      height: calc( 100% - 28px )
  .no-image-msg-container
    height: 100%
    .msg-title
      font-family: Gilroy-Bold
      color: #1c455a
      font-size: 14px
      margin-top: 15px
      &__sm
        @extend .msg-title
        font-size: 10px
        line-height: normal
    .msg-desc
      opacity: 0.8
      font-family: Gilroy-Semibold
      font-size: 12px
  &.image-short
    background-color: transparent
    border-radius: 2px
    img
      border-radius: 2px
    &.show-lg
      .l-g
        border-top-left-radius: 2px
        border-top-right-radius: 2px
        height: 16px
        p
          font-size: 7px
          line-height: 1
      img
        border-bottom-left-radius: 2px
        border-bottom-right-radius: 2px
        border-top-left-radius: 0px
        border-top-right-radius: 0px
        max-height: calc(62px - 20px)
      .no-image-msg-container
        height: calc( 100% - 20px )
</style>