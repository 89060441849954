import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
export default {
  mixins: [OfficeMixin],
  data: () => ({
    fetchingDescription: false,
    prodDetail: null,
  }),
  methods: {
    ...mapActions('PRODUCT', ['GET_PRODUCT_DESCRIPTION']),
    async getProductDetails(obj){
      this.fetchingDescription = true
      try{
        let payload = {
          office_id: this.officeId,
          vendor: obj.vendor,
          product_id: obj.product_id,
          product_url: obj.product_url
        }
        this.prodDetail = await this.GET_PRODUCT_DESCRIPTION(payload)
      }catch(e){
        console.log(e)
      }
      this.fetchingDescription = false
    },
  },
}