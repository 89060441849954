<template>
  <div class="d-card px-3 py-3 d-flex flex-column">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <p class="d-title mb-0" style="font-size: 14px">{{title}}</p>
      <div class="d-flex align-items-center">
        <!-- <b-form-select v-model="selectedMonth" :options="months" class="dt-options" @change="v => {fetchOrderCount(selectedMonth); fetchCurrentBudget(selectedMonth)}"></b-form-select> -->
        <p class="d-title__4 mb-0 ml-2 cursor-pointer" style="line-height: 1.5" @click="viewAll = !viewAll" v-if="filteredVendors.length > 2">View {{viewAll ? 'Less' : 'All'}}</p>
      </div>
    </div>
    <grow-loader v-if="$store.state.OFFICE.fetchingBudgetStats" />
    <div class="wrapper px-4 py-3 h-100" v-else>
      <div v-if="filteredVendors.length == 0" class="h-100 d-flex align-items-center justify-content-center">
        <p class="d-desc mb-0">No Vendor Data yet for {{currentMonth}}</p>
      </div>
      <div v-for="(vendor, vI) in filteredVendors" :key="vI">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="avatar__2x">
              <img :src="vendor.logo" alt="" class="w-100 h-100" v-if="vendor.logo">
              <span v-else style="font-size: 18px; font-family: Gilroy-Bold;">N</span>
            </div>
            <p class="mb-0 d-title__4 ml-3" style="font-size: 12px; font-family: Gilroy-Bold; line-height: normal">{{vendor.name}}</p>
          </div>
          <div style="width: 40%" class="d-flex align-items-center">
            <p class="d-title__3 mb-0">${{numberWithCommas(vendor.total_amount)}}</p>
            <!-- <div class="limit">
              <div class="spent" :style="{width: getWidthPercent(vendor)+'%'}"></div>
            </div> -->
          </div>
        </div>
        <div class="h-rect my-3" v-if="vI != filteredVendors.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GrowLoader from '@/components/loaders/GrowLoader.vue'
import moment from 'moment'
import StatisticsMixin from '@/mixins/Statistics'
import MonthMixin from '@/mixins/Month'
import NumberComma from '@/mixins/NumberComma'
export default {
  components: { GrowLoader },
  props: {
    title: String
  },
  mixins: [StatisticsMixin, MonthMixin, NumberComma],
  data: () => ({
    viewAll: false,
  }),
  created(){
    // this.fetchOrderCount()
    // this.fetchCurrentBudget()
  },
  computed: {
    vendorsIds(){
      return (this.count.vendors.map(v => v.id)).filter((value, index, self) => self.indexOf(value) === index)
    },
    filteredVendors(){
      if(!this.count) return []
      return this.viewAll ? this.count.vendors : this.count.vendors.slice(0,2)
    },
  },
  methods: {
    vInfo(id){
      return this.count.vendors.find(v=>v.id == id)
    },
    getWidthPercent(vendor){
      return (vendor.total_amount * 100) / this.budget.total_budget
    },
    totalAmount(vId){
      return parseFloat((((this.count.vendors.filter(v=>v.id==vId)).map(v=>v.total_amount)).reduce((a,b)=>a+b,0)).toFixed(2))
    },
  },
}
</script>

<style lang="sass" scoped>

.d-card
  @media screen and (min-width: 992px)
    height: 100%
.wrapper
  border-radius: 8px
  background-color: #f9fafa
  .h-rect
    width: 100%
    height: 1px
    opacity: 0.2
    border-radius: 8px
    background-color: #687C8A
  .limit
    width: 100%
    height: 15px
    border-radius: 10px
    border: solid 1px #e6eaec
    position: relative
    background-color: #fff
    .spent
      height: inherit
      background-color: #60a1c7
      border-radius: 10px
      min-width: 15px

</style>