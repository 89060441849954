<template>
  <div class="sidebar d-flex flex-column pb-2">
    <div class="sidebar-back">
      <div class="m-3 mt-4" @click="goTo({ name: 'Dashboard' })">
        <logo class="logo mb-5 cursor-pointer" />
      </div>
      <!-- <office-picker /> -->
      <div class="ofc-menu w-100" style="cursor: !important">
        <template>
          <div
            @click="toggleOfficePicker"
            class="d-flex align-items-center justify-content-between b-con"
          >
            <div class="logo_img">
              <img src="../logo/newlogo.png" alt="" />
            </div>
            <div class="logo_text">Gordo Family Dentistry</div>

            <!-- <div class="d-flex av-name">
            <div class="av" :class="[!office.logo && 'no-image']">
              <img v-if="office.logo" :src="office.logo" alt="" class="w-100 h-100">
              <img src="./office_default.png" alt="" v-else>
            </div>
            <p class="d-title__3 my-auto o-name text-line-2 text-white"
              style="color: #1C455A;line-height: 1.5;font-size: 14px">{{ office.name }}</p>
          </div> -->
          </div>
        </template>
      </div>
      <p class="menu-text mt-4 pl-2">Menu</p>
      <div class="menus mb-2">
        <div
          :class="[
            'menu cursor-pointer mb-2',
            { active: $route.name == menu.name },
          ]"
          v-for="(menu, mI) in menus"
          :key="mI"
          @click="onClicked(menu)"
        >
          <img :src="menu.icon" alt="" class="icon mr-3" />
          <span class="m-text">{{ menu.text }}</span>
        </div>
        <!-- <div class="menu logout-sec pl-10-px mb-3 cursor-pointer" @click="logout">
        <img src="@/assets/images/left-arrow.png" alt="" class="mr-3 icon">
        <span style="font-family: Gilroy-Bold; color: #1C455A;">Log Out</span>
      </div> -->
      </div>
      <!-- <p class="menu-text mt-3">Shortcuts</p> -->
      <div class="menus mb-4">
        <div
          :class="['menu cursor-pointer']"
          v-for="(menu, mI) in menus2"
          :key="mI"
          @click="onClicked(menu)"
        >
          <span
            v-if="menu.text == 'Tutorial Videos'"
            class="mr-3 f-20-px min-w-20-px min-h-30-px max-w-20-px max-h-20-px"
            >📹</span
          >
          <img
            :src="$route.name == menu.name ? menu.alt : menu.icon"
            alt=""
            class="icon mr-3"
            v-else
          />
          <span class="m-text">{{ menu.text }}</span>
        </div>
      </div>
      <button class="Signbtn ml-3" @click="logout">Sign out</button>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/logo/Logo";
import GoTo from "@/mixins/GoTo";
import Office from "@/components/dashboard/Office.vue";
import OfficePicker from "@/components/sidebar/office_picker";
import localStorageService from '@/store/localStorageService';

import { mapActions, mapGetters, mapMutations } from "vuex";
import OfficeMixin from "@/mixins/OfficeMixin";
import Office_picker from "./office_picker.vue";
export default {
  components: {
    Logo,
    Office,
    OfficePicker,
  },
  mixins: [GoTo, Office_picker, OfficeMixin],
  data: () => ({
    // menus2: [
    //   { text: 'Link a Vendor', name: 'Settings', hash: '#Lnk', icon: require('@/assets/images/link_vendor.png'), alt: require('@/assets/images/office-building.webp') },
    //   { text: 'Invite Team Member', name: 'Settings', hash: '#MTm', icon: require('@/assets/images/invite_team.png'), alt: require('@/assets/images/user.webp') },
    // ],
  }),
  computed: {
    ...mapGetters("USER", ["role"]),
    menus() {
      return [
        {
          text: "Users",
          name: "AdminDashboard",
          icon: require("@/assets/images/home.png"),
          alt: require("@/assets/images/home.png"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["CLEAR"]),
    ...mapMutations("ADMIN_VIEW", ["toggleShowOfficePicker"]),

    // logout() {
    //   this.CLEAR();
    //   window.location = window.location.origin + "/login";
    //   // this.goTo({name: 'Login'})
    // },
    onClicked(menu) {
      if (window.innerWidth < 1100) this.$emit("toggle-sidebar");
      if (menu.link) {
        window.open(menu.link, "_blank");
      } else if (menu.openEmail) {
        window.location.href = `mailto:${menu.openEmail}?`;
      } else this.goTo(menu);
    },
    toggleOfficePicker() {
      this.toggleShowOfficePicker();
    },
    logout() {
      this.CLEAR();
      if (this.role == 2) {
        localStorageService.setItem('lastVisitedRoute', this.$route.fullPath);
      }
      window.location = window.location.origin + '/login';
    },
  },
};
</script>
<style>
.logo_text {
  margin-left: 15px;
  line-height: 20px;
  font-size: 18px;
  color: white;
  /* font-family: GILROY-BOLD; */
}
</style>

<style lang="sass" scoped>
.sidebar-parent
  z-index: 1
.sidebar-back
  position: fixed
  height: 100vh
  width: 235px
  left: 0
  top: 0
  background-color: #ffffff
  @media screen and (max-width: 479px)
    display: none

.sidebar
  max-width: 220px
  min-width: 220px
  width: 100%
  height: 100vh
  overflow-y: auto
  background-color: #ffffff
  padding: 33px 0px 0px 0px
  @media screen and (max-width: 992px)
    position: fixed
    border-radius: 0
    height: calc( 100vh - 62px )
    z-index: 9999
    max-width: 100%
  .logo
    width: 150px
    height: 39px
    min-height: 50px
    @media screen and (max-width: 992px)
      display: none
  .menu-text
    opacity: 0.7
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.71
    color: #1c455a
    @media screen and (max-width: 479px)
      padding-left: 10px
  .menus
    .menu
      height: 44px
      max-width: 230px
      display: flex
      align-items: center
      padding: 0 10px
      cursor: pointer
      @media screen and (max-width: 992px)
        max-width: 100%
      .icon
        width: 20px
        height: 20px
      .m-text
        font-family: GILROY-BOLD
        font-size: 14px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: 1.71
        color: #1c455a
      &.active
        border-radius: 6px 0 0 6px
        background-color: #13ADD9
        @media screen and (max-width: 992px)
          border-radius: 0
        .icon , .m-text
          color: white
  .logout-sec
    font-family: GILROY-SEMIBOLD
    font-size: 14px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    line-height: 1.71
    color: #ff1dc3
  .sidebar-footer
    .cpr
      opacity: 0.8
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #1c455a
    .sf-title
      opacity: 0.8
      font-size: 18px
      font-family: GILROY-BOLD
      font-stretch: normal
      font-style: normal
      line-height: 1.11
      color: #1c455a
      .us-card
  .us-sec
    width: calc( 100% - 0.5rem )
.b-con
  background-image: url(./Office_grad.svg)
  background-size: cover
  padding: 1.5rem 1rem
  border-radius: 8px 0px 0px 8px
  @media screen and (max-width: 992px)
    border-radius: 0
  .av-name
    max-width: 95%
.av
  min-width: 41px
  min-height: 41px
  max-width: 41px
  max-height: 41px
  border-radius: 100%
  border: 2px solid white
  img
    // min-height: calc( 100% - 4px )
    // min-width: calc( 100% - 4px )
    object-fit: cover
    border-radius: inherit
    height: 100%
    width: 100%
  &.no-image
    // background-color: #FFFFFF
    display: flex
    align-items: center
    justify-content: center
    border-color: transparent
    border-radius: 0
    span
      font-family: GILROY-BOLD
      font-size: 26px
      color: white
      margin-top: 3px
</style>
