<template>
  <b-btn :class="['custom-btn', classes || '']" @click="onclick">
    <b-spinner :style="{ width: spinnerWidth, height: spinnerHeight }" :class="['mr-2', spinnerClass]" v-if="submitting"></b-spinner>
    <slot>{{text}}</slot>
  </b-btn>
</template>

<script>
export default {
  props: {
    text: String,
    onclick: {
      type: Function,
      default: () => {}
    },
    classes: String,
    submitting: Boolean,
    spinnerClass: {
      type: String,
      default: 'text-light'
    },
    spinnerWidth: {
      type: String,
      default: '1rem'
    },
    spinnerHeight: {
      type: String,
      default: '1rem'
    },
  },
}
</script>

<style>

</style>