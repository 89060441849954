// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import store from '@/store'

// import AuthLayout from '@/layouts/AuthLayout'
// import OnboardingLayout from '@/layouts/OnboardingLayout'
// import AppLayout from '@/layouts/AppLayout'
// import LandingLayout from '@/layouts/LandingLayout'

// import Login from '@/views/auth/Login'
// import ForgotPass from '@/views/auth/ForgotPass'
// import ResetPass from '@/views/auth/ResetPass'
// import Onboarding from '@/views/Onboarding'
// import Waitlist from '@/views/Waitlist'
// import Dashboard from '@/views/app/Dashboard'
// import AdminDashboard from '@/views/admin_dashboard/admin_dashboard.vue'
// import Settings from '@/views/app/Settings'
// import OfficeSettings from '@/views/app/OfficeSettings'
// import Finance from '@/views/app/Finance'
// import Orders from '@/views/app/Orders'
// import OrderShopping from '@/views/app/OrderShopping'
// import OrderShoppingSearch from '@/views/app/OrderShoppingSearch'
// import CreateAnOrder from '@/views/app/CreateAnOrder'
// import CartPage from '@/views/app/CartPage'
// import OrderApproval from '@/views/app/OrderApproval' 
// import Checkout from '@/views/app/Checkout'
// import FutureCast from '@/views/app/FutureCast'
// import AcceptInvite from '@/views/auth/AcceptInvite'
// import Inventory from '@/views/app/Inventory'
// import Landing from '@/views/Landing'
// import Terms from '@/views/Terms'
// import Refund from '@/views/Refund'
// import Privacy from '@/views/Privacy'
// import Vendors from '@/views/Vendors'
// // import Videos from '@/views/Videos'
// import Pricing from '@/views/Pricing'
// import Features from '@/views/Features'
// import FeatureAnalyzeSupplies from '@/views/features/AnalyzeSupplies'
// import FeatureCustomizeProducts from '@/views/features/CustomizeProducts'
// import FeatureDownloadInvoices from '@/views/features/DownloadInvoices'
// import FeatureFuturecast from '@/views/features/Futurecast'
// import FeatureImportedOrderHistory from '@/views/features/ImportedOrderHistory'
// import FeatureMultiofficeFunctionality from '@/views/features/MultiofficeFunctionality'
// import FeatureMultivendorOrdering from '@/views/features/MultivendorOrdering'
// import FeatureOpendentalInteration from '@/views/features/OpendentalInteration'
// import FeatureOrderApprovals from '@/views/features/OrderApprovals'
// import FeaturePriceComparison from '@/views/features/PriceComparison'
// import FeatureTrackingBudget from '@/views/features/TrackingBudget'
// import FeatureTrackingOrdersReturns from '@/views/features/TrackingOrdersReturns'
// import ProductDemo from '@/views/ProductDemo'
// import GetStarted from '@/views/GetStarted'
// import VendorAmazon from '@/views/vendors/Amazon'
// import VendorBenco from '@/views/vendors/Benco'
// import VendorBergmanDental from '@/views/vendors/BergmanDental'
// import VendorBlueSkyBio from '@/views/vendors/BlueSkyBio'
// import VendorBioHorizons from '@/views/vendors/BioHorizons'
// import VendorCrazyDental from '@/views/vendors/CrazyDental'
// import VendorDarby from '@/views/vendors/Darby'
// import VendorDcDental from '@/views/vendors/DcDental'
// import VendorDentalCity from '@/views/vendors/DentalCity'
// import VendorDentsplySirona from '@/views/vendors/DentsplySirona'
// import VendorEbay from '@/views/vendors/Ebay'
// import VendorEdgeEndo from '@/views/vendors/EdgeEndo'
// import VendorHenrySchien from '@/views/vendors/HenrySchien'
// import VendorImplantDirect from '@/views/vendors/ImplantDirect'
// import VendorMidwestDental from '@/views/vendors/MidwestDental'
// import VendorNet32 from '@/views/vendors/Net32'
// import VendorOfficeDepot from '@/views/vendors/OfficeDepot'
// import VendorOrthoArch from '@/views/vendors/OrthoArch'
// import VendorPatterson from '@/views/vendors/Patterson'
// import VendorPearson from '@/views/vendors/Pearson'
// import VendorPracticon from '@/views/vendors/Practicon'
// import VendorPurelife from '@/views/vendors/Purelife'
// import VendorSafco from '@/views/vendors/Safco'
// import VendorSalvin from '@/views/vendors/Salvin'
// import VendorSkyDental from '@/views/vendors/SkyDental'
// import VendorStaples from '@/views/vendors/Staples'
// import VendorTopQualityGloves from '@/views/vendors/TopQualityGloves'
// import VendorUltradent from '@/views/vendors/Ultradent'
// import ProcedureAnalytics from '@/views/admin_dashboard/layouts/procedure_analytics'
// import OrdersAnalytics from '@/views/admin_dashboard/layouts/orders_analytics'
// import FinancialAnalytics from '@/views/admin_dashboard/layouts/financial_analytics'
// import AdminOrders from '@/views/admin_dashboard/layouts/admin_orders'

// // VUE_APP_API_ENDPOINT

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     component: LandingLayout,
//     children: [
//       { path: '', name: 'Landing', component: Landing, },
//       { path: 'terms', name: 'Terms', component: Terms, },
//       { path: 'privacy', name: 'Privacy', component: Privacy, },
//       { path: 'refund', name: 'Refund', component: Refund, },
//       // { path: 'videos', name: 'Videos', component: Videos, },
//       { path: 'pricing', name: 'Pricing', component: Pricing, },

//       { path: 'features', name: 'Features', component: Features, },
//       { path: 'feature-analyze-supplies', name: 'FeatureAnalyzeSupplies', component: FeatureAnalyzeSupplies, },
//       { path: 'feature-customize-products', name: 'FeatureCustomizeProducts', component: FeatureCustomizeProducts, },
//       { path: 'feature-download-invoices', name: 'FeatureDownloadInvoices', component: FeatureDownloadInvoices, },
//       { path: 'feature-futurecast', name: 'FeatureFuturecast', component: FeatureFuturecast, },
//       { path: 'feature-imported-order-history', name: 'FeatureImportedOrderHistory', component: FeatureImportedOrderHistory, },
//       { path: 'feature-multioffice-functionality', name: 'FeatureMultiofficeFunctionality', component: FeatureMultiofficeFunctionality, },
//       { path: 'feature-multivendor-ordering', name: 'FeatureMultivendorOrdering', component: FeatureMultivendorOrdering, },
//       { path: 'feature-opendental-interation', name: 'FeatureOpendentalInteration', component: FeatureOpendentalInteration, },
//       { path: 'feature-order-approvals', name: 'FeatureOrderApprovals', component: FeatureOrderApprovals, },
//       { path: 'feature-price-comparison', name: 'FeaturePriceComparison', component: FeaturePriceComparison, },
//       { path: 'feature-tracking-budget', name: 'FeatureTrackingBudget', component: FeatureTrackingBudget, },
//       { path: 'feature-tracking-orders-returns', name: 'FeatureTrackingOrdersReturns', component: FeatureTrackingOrdersReturns, },

//       { path: 'product-demo', name: 'ProductDemo', component: ProductDemo, },
//       { path: 'get-started', name: 'GetStarted', component: GetStarted, },

//       { path: 'vendors', name: 'Vendors', component: Vendors, },
//       { path: 'vendor-amazon', name: 'VendorAmazon', component: VendorAmazon, },
//       { path: 'vendor-benco', name: 'VendorBenco', component: VendorBenco, },
//       { path: 'vendor-bergman-dental', name: 'VendorBergmanDental', component: VendorBergmanDental, },
//       { path: 'vendor-blue-sky-bio', name: 'VendorBlueSkyBio', component: VendorBlueSkyBio, },
//       { path: 'vendor-bio-horizons', name: 'VendorBioHorizons', component: VendorBioHorizons, },
//       { path: 'vendor-crazy-dental', name: 'VendorCrazyDental', component: VendorCrazyDental, },
//       { path: 'vendor-darby', name: 'VendorDarby', component: VendorDarby, },
//       { path: 'vendor-dc-dental', name: 'VendorDcDental', component: VendorDcDental, },
//       { path: 'vendor-dental-city', name: 'VendorDentalCity', component: VendorDentalCity, },
//       { path: 'vendor-dentsply-sirona', name: 'VendorDentsplySirona', component: VendorDentsplySirona, },
//       { path: 'vendor-ebay', name: 'VendorEbay', component: VendorEbay, },
//       { path: 'vendor-edge-endo', name: 'VendorEdgeEndo', component: VendorEdgeEndo, },
//       { path: 'vendor-henry-schien', name: 'VendorHenrySchien', component: VendorHenrySchien, },
//       { path: 'vendor-implant-direct', name: 'VendorImplantDirect', component: VendorImplantDirect, },
//       { path: 'vendor-midwest-dental', name: 'VendorMidwestDental', component: VendorMidwestDental, },
//       { path: 'vendor-net32', name: 'VendorNet32', component: VendorNet32, },
//       { path: 'vendor-office-depot', name: 'VendorOfficeDepot', component: VendorOfficeDepot, },
//       { path: 'vendor-ortho-arch', name: 'VendorOrthoArch', component: VendorOrthoArch, },
//       { path: 'vendor-patterson', name: 'VendorPatterson', component: VendorPatterson, },
//       { path: 'vendor-pearson', name: 'VendorPearson', component: VendorPearson, },
//       { path: 'vendor-practicon', name: 'VendorPracticon', component: VendorPracticon, },
//       { path: 'vendor-purelife', name: 'VendorPurelife', component: VendorPurelife, },
//       { path: 'vendor-safco', name: 'VendorSafco', component: VendorSafco, },
//       { path: 'vendor-salvin', name: 'VendorSalvin', component: VendorSalvin, },
//       { path: 'vendor-sky-dental', name: 'VendorSkyDental', component: VendorSkyDental, },
//       { path: 'vendor-staples', name: 'VendorStaples', component: VendorStaples, },
//       { path: 'vendor-top-quality-gloves', name: 'VendorTopQualityGloves', component: VendorTopQualityGloves, },
//       { path: 'vendor-ultradent', name: 'VendorUltradent', component: VendorUltradent, },
//     ],
//   },
//   {
//     path: '/',
//     component: AuthLayout,
//     children: [
//       {
//         path: 'login',
//         name: 'Login',
//         component: Login
//       },
      
//       {
//         path: 'forgot-password',
//         name: 'ForgotPassword',
//         component: ForgotPass
//       },
//       {
//         path: 'reset-password',
//         name: 'ResetPassword',
//         component: ResetPass
//       },
//       {
//         path: 'accept-invite',
//         name: 'AcceptInvite',
//         component: AcceptInvite
//       },
//     ]
//   },
//   {
//     path: '/Onboarding ',
//     component: OnboardingLayout,
//     children: [
//       // { path: 'onboarding', name: 'OnBoarding', component: OnBoarding }
//       { path: 'onboarding', name: 'OnBoarding', component: Onboarding }
//     ],
//   },
//   {
//     path: '/wait-list',
//     component: OnboardingLayout,
//     children: [
//       { path: 'wait-list', name: 'Waitlist', component: Waitlist }
//     ],
//   },
//   {
//     path: '/:office?',
//     component: AppLayout,
//     beforeEnter: (to, from, next) => {
//       if (store.getters['USER/token'] && !store.getters['USER/company']) return next({ name: 'OnBoarding' })
//       else if (!store.getters['USER/company']) return next({ name: 'Login' })
//       let totalOffices = store.getters['USER/company'].offices.length
//       if (
//         to.params.office == undefined
//         || isNaN(parseInt(to.params.office))
//         || parseInt(to.params.office) >= totalOffices
//       ) next({
//         params: {
//           office: 0
//         },
//         name: to.name,
//         query: to.query,
//       })
//       next()
//     },
//     children: [
//       { path: 'dashboard', name: 'Dashboard', component: Dashboard },
//       { path: 'admin-dashboard', name: 'AdminDashboard', component: AdminDashboard },
//       { path: 'settings', name: 'Settings', component: Settings },
//       { path: 'office-settings', name: 'OfficeSettings', component: OfficeSettings },
//       { path: 'finance', name: 'Finance', component: Finance },
//       { path: 'inventory', name: 'Inventory', component: Inventory },
//       { path: 'orders', name: 'Orders', component: Orders },
//       { path: 'order-shopping', name: 'OrderShopping', component: OrderShopping },
//       { path: 'order-shopping-search', name: 'OrderShoppingSearch', component: OrderShoppingSearch },
//       { path: 'create-an-order', name: 'CreateAnOrder', component: CreateAnOrder },
//       { path: 'cart', name: 'Cart', component: CartPage },
//       { path: 'order-approval/:order_id', name: 'OrderApproval', component: OrderApproval },
//       { path: 'checkout', name: 'Checkout', component: Checkout },
//       { path: 'future-cast', name: 'FutureCast', component: FutureCast },
//       { path: 'procedure-analytics', name: 'ProcedureAnalytics', component: ProcedureAnalytics },
//       { path: 'orders-analytics', name: 'OrdersAnalytics', component: OrdersAnalytics },
//       { path: 'financial-analytics', name: 'FinancialAnalytics', component: FinancialAnalytics },
//       { path: 'admin-orders', name: 'AdminOrders', component: AdminOrders }, 
      
//     ],
//   },
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       document.getElementById('app').scrollTop = 0
//       return { x: 0, y: 0 }
//     }
//   },
// })

// let withoutAuthRoutes = [
//   "Landing",
//   'wait-list',
//   "Login",
//   "ForgotPassword",
//   "ResetPassword",
//   "AcceptInvite",
// ]

// let ignorePaths = [
//   // '/',
//   '/onboarding',
//   '/terms',
//   '/privacy',
//   '/refund',
//   '/vendors',
//   // '/videos',
//   '/pricing',
//   '/features',
//   '/feature-analyze-supplies',
//   '/feature-customize-products',
//   '/feature-download-invoices',
//   '/feature-futurecast',
//   '/feature-imported-order-history',
//   '/feature-multioffice-functionality',
//   '/feature-multivendor-ordering',
//   '/feature-opendental-interation',
//   '/feature-order-approvals',
//   '/feature-price-comparison',
//   '/feature-tracking-budget',
//   '/feature-tracking-orders-returns',
//   '/vendor-patterson',
//   '/product-demo',
//   '/get-started',
//   '/vendor-amazon',
//   '/vendor-benco',
//   '/vendor-bergman-dental',
//   '/vendor-blue-sky-bio',
//   '/vendor-bio-horizons',
//   '/vendor-crazy-dental',
//   '/vendor-darby',
//   '/vendor-dc-dental',
//   '/vendor-dental-city',
//   '/vendor-dentsply-sirona',
//   '/vendor-ebay',
//   '/vendor-edge-endo',
//   '/vendor-henry-schien',
//   '/vendor-implant-direct',
//   '/vendor-midwest-dental',
//   '/vendor-net32',
//   '/vendor-office-depot',
//   '/vendor-ortho-arch',
//   '/vendor-patterson',
//   '/vendor-pearson',
//   '/vendor-practicon',
//   '/vendor-purelife',
//   '/vendor-safco',
//   '/vendor-salvin',
//   '/vendor-sky-dental',
//   '/vendor-staples',
//   '/vendor-top-quality-gloves',
//   '/vendor-ultradent',
// ]

// // function to redirect all pages to wait list hide it for now as per client request 
// // router.beforeEach((to, from, next) => {
// //   // if(to.name == 'Landing') {
// //   //   next()
// //   //   return
// //   // }

// //   if (to.path == '/wait-list') {
// //     next()
// //     return
// //   }
// //   if (ignorePaths.find(p => to.path.includes(p)) != null) {
// //     next()
// //     return
// //   }
// //   if (withoutAuthRoutes.indexOf(to.name) == -1 && !(store.getters["USER/token"])) {
// //     next('/wait-list')
// //     // next('/login')
// //   } else if (withoutAuthRoutes.indexOf(to.name) >= 0 && (store.getters["USER/token"])) {
// //     next('/0/dashboard')
// //   }
// //   else {
// //     next()
// //   }
// // });

// export default router

// *********************** added this staging file testing will remove after client sign up new account ************************

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AuthLayout from '@/layouts/AuthLayout'
import OnboardingLayout from '@/layouts/OnboardingLayout'
import AppLayout from '@/layouts/AppLayout'
import LandingLayout from '@/layouts/LandingLayout'

import Login from '@/views/auth/Login'
import ForgotPass from '@/views/auth/ForgotPass'
import ResetPass from '@/views/auth/ResetPass'
import Onboarding from '@/views/Onboarding'
import Waitlist from '@/views/Waitlist'
import Dashboard from '@/views/app/Dashboard'
import AdminDashboard from '@/views/admin_dashboard/admin_dashboard.vue'
import Settings from '@/views/app/Settings'
import OfficeSettings from '@/views/app/OfficeSettings'
import Finance from '@/views/app/Finance'
import Orders from '@/views/app/Orders'
import OrderShopping from '@/views/app/OrderShopping'
import OrderShoppingSearch from '@/views/app/OrderShoppingSearch'
import CreateAnOrder from '@/views/app/CreateAnOrder'
import CartPage from '@/views/app/CartPage'
import OrderApproval from '@/views/app/OrderApproval' 
import Checkout from '@/views/app/Checkout'
import FutureCast from '@/views/app/FutureCast'
import AcceptInvite from '@/views/auth/AcceptInvite'
import Inventory from '@/views/app/Inventory'
import Landing from '@/views/Landing'
import Terms from '@/views/Terms'
import Refund from '@/views/Refund'
import Privacy from '@/views/Privacy'
import Vendors from '@/views/Vendors'
// import Videos from '@/views/Videos'
import Pricing from '@/views/Pricing'
import Features from '@/views/Features'
import FeatureAnalyzeSupplies from '@/views/features/AnalyzeSupplies'
import FeatureCustomizeProducts from '@/views/features/CustomizeProducts'
import FeatureDownloadInvoices from '@/views/features/DownloadInvoices'
import FeatureFuturecast from '@/views/features/Futurecast'
import FeatureImportedOrderHistory from '@/views/features/ImportedOrderHistory'
import FeatureMultiofficeFunctionality from '@/views/features/MultiofficeFunctionality'
import FeatureMultivendorOrdering from '@/views/features/MultivendorOrdering'
import FeatureOpendentalInteration from '@/views/features/OpendentalInteration'
import FeatureOrderApprovals from '@/views/features/OrderApprovals'
import FeaturePriceComparison from '@/views/features/PriceComparison'
import FeatureTrackingBudget from '@/views/features/TrackingBudget'
import FeatureTrackingOrdersReturns from '@/views/features/TrackingOrdersReturns'
import ProductDemo from '@/views/ProductDemo'
import GetStarted from '@/views/GetStarted'
import VendorAmazon from '@/views/vendors/Amazon'
import VendorBenco from '@/views/vendors/Benco'
import VendorBergmanDental from '@/views/vendors/BergmanDental'
import VendorBlueSkyBio from '@/views/vendors/BlueSkyBio'
import VendorBioHorizons from '@/views/vendors/BioHorizons'
import VendorCrazyDental from '@/views/vendors/CrazyDental'
import VendorDarby from '@/views/vendors/Darby'
import VendorDcDental from '@/views/vendors/DcDental'
import VendorDentalCity from '@/views/vendors/DentalCity'
import VendorDentsplySirona from '@/views/vendors/DentsplySirona'
import VendorEbay from '@/views/vendors/Ebay'
import VendorEdgeEndo from '@/views/vendors/EdgeEndo'
import VendorHenrySchien from '@/views/vendors/HenrySchien'
import VendorImplantDirect from '@/views/vendors/ImplantDirect'
import VendorMidwestDental from '@/views/vendors/MidwestDental'
import VendorNet32 from '@/views/vendors/Net32'
import VendorOfficeDepot from '@/views/vendors/OfficeDepot'
import VendorOrthoArch from '@/views/vendors/OrthoArch'
import VendorPatterson from '@/views/vendors/Patterson'
import VendorPearson from '@/views/vendors/Pearson'
import VendorPracticon from '@/views/vendors/Practicon'
import VendorPurelife from '@/views/vendors/Purelife'
import VendorSafco from '@/views/vendors/Safco'
import VendorSalvin from '@/views/vendors/Salvin'
import VendorSkyDental from '@/views/vendors/SkyDental'
import VendorStaples from '@/views/vendors/Staples'
import VendorTopQualityGloves from '@/views/vendors/TopQualityGloves'
import VendorUltradent from '@/views/vendors/Ultradent'
import ProcedureAnalytics from '@/views/admin_dashboard/layouts/procedure_analytics'
import OrdersAnalytics from '@/views/admin_dashboard/layouts/orders_analytics'
import FinancialAnalytics from '@/views/admin_dashboard/layouts/financial_analytics'
import AdminOrders from '@/views/admin_dashboard/layouts/admin_orders'

// VUE_APP_API_ENDPOINT

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter() {
      window.location.href = 'https://www.joinordo.io/';
    },
    component: LandingLayout,
    children: [
      { path: '', name: 'Landing', component: Landing, },
      { path: 'terms', name: 'Terms', component: Terms, },
      { path: 'privacy', name: 'Privacy', component: Privacy, },
      { path: 'refund', name: 'Refund', component: Refund, },
      // { path: 'videos', name: 'Videos', component: Videos, },
      { path: 'pricing', name: 'Pricing', component: Pricing, },

      { path: 'features', name: 'Features', component: Features, },
      { path: 'feature-analyze-supplies', name: 'FeatureAnalyzeSupplies', component: FeatureAnalyzeSupplies, },
      { path: 'feature-customize-products', name: 'FeatureCustomizeProducts', component: FeatureCustomizeProducts, },
      { path: 'feature-download-invoices', name: 'FeatureDownloadInvoices', component: FeatureDownloadInvoices, },
      { path: 'feature-futurecast', name: 'FeatureFuturecast', component: FeatureFuturecast, },
      { path: 'feature-imported-order-history', name: 'FeatureImportedOrderHistory', component: FeatureImportedOrderHistory, },
      { path: 'feature-multioffice-functionality', name: 'FeatureMultiofficeFunctionality', component: FeatureMultiofficeFunctionality, },
      { path: 'feature-multivendor-ordering', name: 'FeatureMultivendorOrdering', component: FeatureMultivendorOrdering, },
      { path: 'feature-opendental-interation', name: 'FeatureOpendentalInteration', component: FeatureOpendentalInteration, },
      { path: 'feature-order-approvals', name: 'FeatureOrderApprovals', component: FeatureOrderApprovals, },
      { path: 'feature-price-comparison', name: 'FeaturePriceComparison', component: FeaturePriceComparison, },
      { path: 'feature-tracking-budget', name: 'FeatureTrackingBudget', component: FeatureTrackingBudget, },
      { path: 'feature-tracking-orders-returns', name: 'FeatureTrackingOrdersReturns', component: FeatureTrackingOrdersReturns, },

      { path: 'product-demo', name: 'ProductDemo', component: ProductDemo, },
      { path: 'get-started', name: 'GetStarted', component: GetStarted, },

      { path: 'vendors', name: 'Vendors', component: Vendors, },
      { path: 'vendor-amazon', name: 'VendorAmazon', component: VendorAmazon, },
      { path: 'vendor-benco', name: 'VendorBenco', component: VendorBenco, },
      { path: 'vendor-bergman-dental', name: 'VendorBergmanDental', component: VendorBergmanDental, },
      { path: 'vendor-blue-sky-bio', name: 'VendorBlueSkyBio', component: VendorBlueSkyBio, },
      { path: 'vendor-bio-horizons', name: 'VendorBioHorizons', component: VendorBioHorizons, },
      { path: 'vendor-crazy-dental', name: 'VendorCrazyDental', component: VendorCrazyDental, },
      { path: 'vendor-darby', name: 'VendorDarby', component: VendorDarby, },
      { path: 'vendor-dc-dental', name: 'VendorDcDental', component: VendorDcDental, },
      { path: 'vendor-dental-city', name: 'VendorDentalCity', component: VendorDentalCity, },
      { path: 'vendor-dentsply-sirona', name: 'VendorDentsplySirona', component: VendorDentsplySirona, },
      { path: 'vendor-ebay', name: 'VendorEbay', component: VendorEbay, },
      { path: 'vendor-edge-endo', name: 'VendorEdgeEndo', component: VendorEdgeEndo, },
      { path: 'vendor-henry-schien', name: 'VendorHenrySchien', component: VendorHenrySchien, },
      { path: 'vendor-implant-direct', name: 'VendorImplantDirect', component: VendorImplantDirect, },
      { path: 'vendor-midwest-dental', name: 'VendorMidwestDental', component: VendorMidwestDental, },
      { path: 'vendor-net32', name: 'VendorNet32', component: VendorNet32, },
      { path: 'vendor-office-depot', name: 'VendorOfficeDepot', component: VendorOfficeDepot, },
      { path: 'vendor-ortho-arch', name: 'VendorOrthoArch', component: VendorOrthoArch, },
      { path: 'vendor-patterson', name: 'VendorPatterson', component: VendorPatterson, },
      { path: 'vendor-pearson', name: 'VendorPearson', component: VendorPearson, },
      { path: 'vendor-practicon', name: 'VendorPracticon', component: VendorPracticon, },
      { path: 'vendor-purelife', name: 'VendorPurelife', component: VendorPurelife, },
      { path: 'vendor-safco', name: 'VendorSafco', component: VendorSafco, },
      { path: 'vendor-salvin', name: 'VendorSalvin', component: VendorSalvin, },
      { path: 'vendor-sky-dental', name: 'VendorSkyDental', component: VendorSkyDental, },
      { path: 'vendor-staples', name: 'VendorStaples', component: VendorStaples, },
      { path: 'vendor-top-quality-gloves', name: 'VendorTopQualityGloves', component: VendorTopQualityGloves, },
      { path: 'vendor-ultradent', name: 'VendorUltradent', component: VendorUltradent, },
    ],
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPass
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPass
      },
      {
        path: 'accept-invite',
        name: 'AcceptInvite',
        component: AcceptInvite
      },
    ]
  },
  {
    path: '/',
    component: OnboardingLayout,
    children: [
      { path: 'onboarding', name: 'OnBoarding', component: Onboarding }
      // { path: 'onboarding', name: 'OnBoarding', component: Onboarding }
    ],
  },
  {
    path: '/',
    component: OnboardingLayout,
    children: [
      { path: 'wait-list', name: 'Waitlist', component: Waitlist }
    ],
  },
  {
    path: '/:office?',
    component: AppLayout,
    beforeEnter: (to, from, next) => {
      if (store.getters['USER/token'] && !store.getters['USER/company']) return next({ name: 'OnBoarding' })
      else if (!store.getters['USER/company']) return next({ name: 'Login' })
      let totalOffices = store.getters['USER/company'].offices.length
      if (
        to.params.office == undefined
        || isNaN(parseInt(to.params.office))
        || parseInt(to.params.office) >= totalOffices
      ) next({
        params: {
          office: 0
        },
        name: to.name,
        query: to.query,
      })
      next()
    },
    children: [
      { path: 'dashboard', name: 'Dashboard', component: Dashboard },
      { path: 'admin-dashboard', name: 'AdminDashboard', component: AdminDashboard },
      { path: 'settings', name: 'Settings', component: Settings },
      { path: 'office-settings', name: 'OfficeSettings', component: OfficeSettings },
      { path: 'finance', name: 'Finance', component: Finance },
      { path: 'inventory', name: 'Inventory', component: Inventory },
      { path: 'orders', name: 'Orders', component: Orders },
      { path: 'order-shopping', name: 'OrderShopping', component: OrderShopping },
      { path: 'order-shopping-search', name: 'OrderShoppingSearch', component: OrderShoppingSearch },
      { path: 'create-an-order', name: 'CreateAnOrder', component: CreateAnOrder },
      { path: 'cart', name: 'Cart', component: CartPage },
      { path: 'order-approval/:order_id', name: 'OrderApproval', component: OrderApproval },
      { path: 'checkout', name: 'Checkout', component: Checkout },
      { path: 'future-cast', name: 'FutureCast', component: FutureCast },
      { path: 'procedure-analytics', name: 'ProcedureAnalytics', component: ProcedureAnalytics },
      { path: 'orders-analytics', name: 'OrdersAnalytics', component: OrdersAnalytics },
      { path: 'financial-analytics', name: 'FinancialAnalytics', component: FinancialAnalytics },
      { path: 'admin-orders', name: 'AdminOrders', component: AdminOrders }, 
      
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      document.getElementById('app').scrollTop = 0
      return { x: 0, y: 0 }
    }
  },
})

let withoutAuthRoutes = [
  "Landing",
  'wait-list',
  "Login",
  "ForgotPassword",
  "ResetPassword",
  "AcceptInvite",
]

let ignorePaths = [
  // '/',
  '/onboarding',
  '/terms',
  '/privacy',
  '/refund',
  '/vendors',
  // '/videos',
  '/pricing',
  '/features',
  '/feature-analyze-supplies',
  '/feature-customize-products',
  '/feature-download-invoices',
  '/feature-futurecast',
  '/feature-imported-order-history',
  '/feature-multioffice-functionality',
  '/feature-multivendor-ordering',
  '/feature-opendental-interation',
  '/feature-order-approvals',
  '/feature-price-comparison',
  '/feature-tracking-budget',
  '/feature-tracking-orders-returns',
  '/vendor-patterson',
  '/product-demo',
  '/get-started',
  '/vendor-amazon',
  '/vendor-benco',
  '/vendor-bergman-dental',
  '/vendor-blue-sky-bio',
  '/vendor-bio-horizons',
  '/vendor-crazy-dental',
  '/vendor-darby',
  '/vendor-dc-dental',
  '/vendor-dental-city',
  '/vendor-dentsply-sirona',
  '/vendor-ebay',
  '/vendor-edge-endo',
  '/vendor-henry-schien',
  '/vendor-implant-direct',
  '/vendor-midwest-dental',
  '/vendor-net32',
  '/vendor-office-depot',
  '/vendor-ortho-arch',
  '/vendor-patterson',
  '/vendor-pearson',
  '/vendor-practicon',
  '/vendor-purelife',
  '/vendor-safco',
  '/vendor-salvin',
  '/vendor-sky-dental',
  '/vendor-staples',
  '/vendor-top-quality-gloves',
  '/vendor-ultradent',
]

// function to redirect all pages to wait list hide it for now as per cleint request 
// router.beforeEach((to, from, next) => {
//   // if(to.name == 'Landing') {
//   //   next()
//   //   return
//   // }

//   if (to.path == '/wait-list') {
//     next()
//     return
//   }
//   if (ignorePaths.find(p => to.path.includes(p)) != null) {
//     next()
//     return
//   }
//   if (withoutAuthRoutes.indexOf(to.name) == -1 && !(store.getters["USER/token"])) {
//     // next('/wait-list')
//     next('/login')
//   } else if (withoutAuthRoutes.indexOf(to.name) >= 0 && (store.getters["USER/token"])) {
//     next('/0/dashboard')
//   }
//   else {
//     next()
//   }
// });

export default router
