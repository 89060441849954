<template>
  <svg width="47" height="39" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46.5344 0.369968L7.64408 1.44522L0 38.7627L46.5344 0.369968Z" fill="white"/>
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>