<template>
  <div>
    <div class="wrapper d-flex flex-column flex-md-row gap-32-px align-items-md-center">
      <div>
        <p class="d-title f-24-px mb-2">Pending Order(s) to Approve!</p>
        <p class="d-desc__2 mb-4" style="max-width: 285px">You have {{ numberOfPendingApprovalOrders }} order<span v-if="numberOfPendingApprovalOrders > 1">s</span> pending approval, please take a look at your recent orders</p>
        <Button class="success px-4" text="View Pending Approval(s)" :onclick="() => goTo({ name: 'Orders', query: { pendingapproval: 'show' } })" />
        <p class="d-desc__2 gilroy-b text-danger__2 mt-3 mb-0 cursor-pointer" style="max-width: 190px" @click="$emit('close')">Close</p>
      </div>
      <img src="./images/pending_approval_popup.png" alt="">
    </div>
  </div>
</template>


<script>
import Button from '@/components/ui/Button.vue'
import GoTo from '@/mixins/GoTo.vue'
export default {
  components: { Button, },
  mixins: [ GoTo ],
  props: {
    numberOfPendingApprovalOrders: Number,
  },
}
</script>

<style lang="sass" scoped>

.close__btn
  position: absolute
  right: 0
  top: 0
  margin: 0 
  cursor: pointer
  height: auto
  line-height: 1
  font-weight: 800
.modal-body 
  position: initial
 
.wrapper
  padding-bottom: 46px
  position: relative

  img
    max-width: 300px
    @media screen and (min-width: 768px)
      position: relative
      bottom: -64px
      right: -30px
   
</style>