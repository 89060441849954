<template>
  <div class="no-result d-card px-4 py-5 pb-md-0">
    <p class="d-title">No available results</p>
    <p class="d-desc">Your linked vendors didn’t have any results that match your search parameters. Link more vendors to expand possible results.</p>
    <img src="@/assets/images/no-search-result.png" alt="" class="ch">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

.no-result
  max-width: 626px
  height: 100%
  min-height: 332px
  position: relative
  @media (max-width: 768px)
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    .d-title
      text-align: center
    .d-desc
      text-align: center
  .ch
    width: 238px
    height: 270px
    position: absolute
    right: 54px
    bottom: 0
    @media (max-width: 768px)
      display: none
  .d-title
    @media (min-width: 768px)
      margin-top: 50px
  .d-desc
    max-width: 300px
</style>