<template>
  <div class="upcoming-orders d-card py-3">
    <div class="d-flex px-3 justify-content-between">
      <p class="d-title mb-0">Budgeting Insights</p>
      <b-form-select v-model="filterBy.dt" :options="dtOptions" class="dt-options"></b-form-select>
    </div>
    <div class="d-flex justify-content-between mt-3 px-3 orders-carousel" id="budspd">
      <div :class="['upc-order pb-3 pt-2 position-relative two']" v-for="i in 3" :key="i">
        <div :class="['indicator two']"></div>
        <div class="head-sec mx-3 mb-3">
          <div class="t-head d-flex justify-content-between mb-3">
            <p class="mb-0 h-title mt-2">Total Budget This Month</p>
            <!-- <b-dropdown right class="menu-drp ml-md-3" ref="drp" >
              <template #button-content>
                <div class="d-flex align-content-center">
                  <b-icon icon="three-dots"></b-icon>
                </div>
              </template>
              sadjhbsad
            </b-dropdown> -->
          </div>
          <div class="time-head d-flex justify-content-between align-items-center my-4">
            <div class="start-time-sec">
              <p class="t-title mb-0 text-primary">$ 45340</p>
            </div>
          </div>
        </div>
        <p class="h-desc mb-3 mx-2">Lorem Ipsum is simply dummy text of the print text dummy</p>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
  components: {
    Carousel
  },
  data: () => ({
    filterBy: {
      dt: 'month'
    },
    dtOptions: [
      { text: 'Month', value: 'month' },
    ],
  }),
  mounted(){
    // $(document).ready(function(){
    //   $("#budspd").owlCarousel({
    //     autoWidth: true,
    //     // nav: true,
    //     // navText: ["<i class='fa fa-angle-left mr-3'></i>", "<i class='fa fa-angle-right'></i>"]
    //   });
    // });
  },
}
</script>

<style lang="sass" scoped>

.upcoming-orders
  ::v-deep .orders-carousel
    .owl-item
      width: max-content !important
      min-width: calc((100% / 4))
  .upc-order
    width: 32%
    border-radius: 12px
    &.one
      border: solid 1px #F5FAFD
      background-color: #F5FAFD
    &.two
      border: solid 1px #E5E5E5
      background-color: #ffffff
    .indicator
      width: 4px
      height: 38px
      border-radius: 12px
      position: absolute
      &.one
        background-color: #49a2cb
      &.two
        background-color: #7cd878
      &.three
        background-color: #1c455a
    .h-title
      font-family: GILROY-SEMIBOLD
      font-size: 14px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #1c455a
      &__2
        @extend .h-title
        font-size: 12px
        line-height: 2.4
        color: #1c455a
    .t-title
      font-family: GILROY-SEMIBOLD
      font-size: 38px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 0.75
    .h-desc
      width : 220px
      font-size: 10px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #1c455a

</style>