<template>
  <div class="appbar">
    <logo class="logo cursor-pointer" @click="goTo({name: 'Landing'})" />
    <b-icon icon="list" class="cursor-pointer gilroy-m" font-scale="1.5" @click="toggleSidebar"></b-icon>
  </div>
</template>

<script>
import Logo from '@/components/logo/Logo'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Logo,
  },
  mixins: [GoTo],
  props: {
    toggleSidebar: Function
  },
}
</script>

<style lang="sass" scoped>
.appbar
  height: 74px
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 1rem
  box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  background-color: #ffffff
  position: sticky
  top: 0
  z-index: 1
  @media screen and (max-width: 992px)
    margin-top: 0.5rem
    padding: 0.3rem 0.5rem
    border-radius: 6px
    height: auto
  .logo
    height: 36px
    width: 100px
</style>