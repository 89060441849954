import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import * as Sentry from '@sentry/browser';


// Sentry.init({
//   dsn: "https://9ab38e45992b3c9d09399489451b1ccf@o4504651385733120.ingest.sentry.io/4506356135886848",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [process.env.VUE_APP_API_ENDPOINT],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoringpindex
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate"
import en from "vee-validate/dist/locale/en.json"
import * as rules from "vee-validate/dist/rules"
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize("en", en)
Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

import './style/style.sass'

import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import infiniteScroll from 'vue-infinite-scroll'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

import VueMarqueeSlider from 'vue-marquee-slider'
Vue.use(VueMarqueeSlider)

import BUS from '@/bus/BUS'
Vue.prototype.$BUS = BUS

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueHead from 'vue-head'
Vue.use(VueHead)

new Vue({
  directives: {infiniteScroll},
  router,
  store,
  render: h => h(App)
}).$mount('#app')
