<template>
  <div class="auth-card">
    <!-- <p class="auth-title mb-3">Password Reset</p> -->
    <!-- <p class="auth-link mb-3">Verifying Invitation</p> -->
    
    <div class="d-flex justify-content-center align-items-center mb-3" v-if="submitting">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <span>Verifying Invitation...</span>
    </div>

    <div class="message" v-if="showMessage">
      <p class="auth-link text-danger">{{msg}}</p>
    </div>
    
    <p class="auth-link mb-0">
      <span>Back to</span>
      <span class="text-primary cursor-pointer ml-3" @click="goTo({name: 'Login'})">Log In</span>
    </p>
  </div>
</template>

<script>
import GoTo from '@/mixins/GoTo'
import { mapActions } from 'vuex'
export default {
  mixins: [GoTo],
  data: () => ({
    submitting: false,
    showMessage: false,
    msg: null,
  }),
  created(){
    if(!this.token) this.$router.push({name: 'Login'})
    else this.verify()
  },
  computed: {
    token(){
      return this.$route.query.token
    },
  },
  methods: {
    ...mapActions('USER', ['ACCEPT_INVITE']),
    async verify(){
      this.submitting = true
      try{
        let payload = { token: this.token }
        let res = await this.ACCEPT_INVITE(payload)
        if(res.data.redirect == 'signup'){
          delete res.data.redirect
          this.$router.push({
            name: 'OnBoarding',
            query: res.data
          })
        }else if(res.data.redirect == 'login'){
          this.$router.push({name: 'Login'})
        }
      }catch(e){
        this.showMessage = true
        this.msg = e
      }
      this.submitting = false
    },
  },
}
</script>

<style>

</style>