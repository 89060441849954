<template>
  <validation-observer ref="conAccObs" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(connectAcc)">
      <validation-provider name="vendor_name" rules="required" v-slot="validationContext" v-if="!vendor">
        <p class="form-label mb-1">Vendor Name</p>
        <b-input v-model="formData.vendor_name" class="custom-input" placeholder="Enter Vendor Name" type="text"
          :state="getValidationState(validationContext)"></b-input>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
        }}</b-form-invalid-feedback>
      </validation-provider>
      <!-- <validation-provider name="vendor_url" rules="required" v-slot="validationContext" v-if="!vendor">
        <p class="form-label mb-1 mt-3">Vendor URL</p>
        <b-input v-model="formData.vendor_url" class="custom-input" placeholder="Vendor URL" type="text" :state="getValidationState(validationContext)"></b-input>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
      </validation-provider> -->
      <validation-provider name="Username" rules="required" v-slot="validationContext" v-if="vendor">
        <p :class="['form-label mb-1', { 'mt-3': !vendor }]">User ID or Email Address</p>
        <b-input v-model="formData.username" class="custom-input" placeholder="Type Here" type="text"
          :state="getValidationState(validationContext)"></b-input>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
        }}</b-form-invalid-feedback>
      </validation-provider>
      <validation-provider name="Password" rules="required" v-slot="validationContext" v-if="vendor">
        <p class="form-label mb-1 mt-3">Password</p>
        <b-input v-model="formData.password" class="custom-input" placeholder="Type" type="password"
          :state="getValidationState(validationContext)"></b-input>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
        }}</b-form-invalid-feedback>
      </validation-provider>
      <validation-provider name="Messages" v-slot="validationContext">
        <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
        }}</b-form-invalid-feedback>
      </validation-provider>
      <div class="header-container">
        <p class="form-label mb-1 mt-3">Offices</p>
        <div class="custom-input form-control select-offices" @click="toggleCollapse()">
          <p class="mb-0">Select Offices to Link</p>
          <b-icon style="margin-left: 10px" v-if="offices.length > 0" icon="caret-down"></b-icon>
        </div>
      </div>

      <b-collapse :visible="visibleCollapse" :id="`outer-${offices[0].id}`" class="mt-2">
        <ul>
          <li v-for="(officeData, index) in offices" :key="index">
            <input type="checkbox" :id="`office-${officeData.id}`" @change="checkOffice(officeData.id)" />
            <label style="margin-left: 10px" :for="`office-${officeData}`">{{
              officeData.name
            }}
            </label>
          </li>
        </ul>
      </b-collapse>

      <div class="dash my-4" v-if="settingsPage"></div>
      <div class="d-flex flex-column align-items-center justify-content-center mt-4">
        <Button :text="btnText" :class="['o-btn', btnClass]" :submitting="submitting" :onclick="connectAcc" />
        <p class="c-title__2 mb-1 mt-2 cursor-pointer" @click="onClickClose">Close</p>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/ui/Button'
export default {
  props: {
    vendor: Object,
    office: Object,
    settingsPage: Boolean,
    relink: Boolean,
    officeVendorId: Number,
  },
  components: {
    Button,
  },
  mixins: [FormMixin],
  data: () => ({
    formData: {
      username: null,
      password: null,
    },
    submitting: false,
    showSuccessMsg: false,
    btnText: null,
    btnClass: '',
    checkedOffices: [],
    visibleCollapse: false
  }),
  created() {
    this.setBtnText()
  },

  computed: {
    ...mapGetters('USER', ['company', 'offices']),
    filteredOffices() {
      return [this.offices[this.officeIndex]].concat(this.offices.filter(o => o.id != this.officeId))
    },
  },

  methods: {
    ...mapActions('ONBOARDING', ['CONNECT_VENDOR', 'RELINK_VENDOR', 'FETCH_ONBOARDING_STATUS']),
    ...mapActions('OFFICE', ['REQUEST_VENDOR']),
    toggleCollapse() {
      this.visibleCollapse = !this.visibleCollapse;

    },
    checkOffice(value) {
      const index = this.checkedOffices.indexOf(value);
      if (index !== -1) {
        this.checkedOffices.splice(index, 1);
      }
      else {
        this.checkedOffices.push(value);
      }


      console.log(this.checkedOffices)
    }
    ,
    setBtnText(txt) {
      let t = txt
      if (this.vendor) {
        if (this.showSuccessMsg) {
          t = 'Vendor Successfully Linked'
        } else if (this.relink) {
          t = 'Relink Vendor'
        } else {
          t = 'Link Account'
        }
      } else {
        t = 'Submit Vendor Request'
      }
      this.btnText = t
    },
    setData() {
      if (!this.vendor) {
        this.formData.vendor_name = null
        this.formData.vendor_url = null
      }
    },
    async connectAcc() {
      if (this.submitting || this.showSuccessMsg) return
      this.submitting = true
      let isValid = await this.$refs.conAccObs.validate();
      try {
        if (isValid) {
          let payload = {
            office: this.office.id,
            ...this.formData,
            offices: this.checkedOffices
          }
          if (!this.vendor) {
            delete payload.username
            delete payload.password
            delete payload.office
            let res = await this.REQUEST_VENDOR(payload)
            this.btnText = 'Vendor Requested'
            this.btnClass = 'success'
            setTimeout(() => this.$emit('close'), 1000)
          } else {
            this.btnText = 'Vendor is connecting. Please wait..'
            payload.vendor = this.vendor.id
            let res
            if (this.relink) {
              payload.officeVendorId = this.officeVendorId
              res = await this.RELINK_VENDOR(payload)
            } else {
              if (this.$route.name == 'OnBoarding' || this.$route.name == 'Dashboard') payload.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
              res = await this.CONNECT_VENDOR(payload)
              await this.FETCH_ONBOARDING_STATUS({ vendors_linked: true })
              this.$store.state.ONBOARDING.on_boarding_step = this.$store.getters['ONBOARDING/next_on_boarding_step']
            }
            // this.formData.username = null
            // this.formData.password = null
            this.showSuccessMsg = true
            this.btnClass = 'success'
            setTimeout(() => {
              this.setBtnText()
              this.$emit('close', res)
            }, 1000)
          }
        }
      } catch (e) {
        this.setBtnText()
        let errors = {
          'Messages': e
        };
        // this.setBtnText(e)
        this.$refs.conAccObs.setErrors(errors);
      }
      this.submitting = false
    },
    onClickClose() {
      if (this.submitting) return
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'

.select-offices
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer


</style>
