<template>
  <div class="px-3 outer-wrapper">
    <div class="outer mx-auto">
      <p class="title-1">Ready to Join Ordo?</p>
      <p class="subtitle-1 mx-auto mb-4">Join the dental ordering revolution. Ordo is the platform that's changing the game for dental practices. Sign up today.</p>
      <div class="d-flex flex-wrap gap-20-px justify-content-center align-items-center">
        <!-- <RouterLink to="/onboarding"> -->
        <!-- <RouterLink to="/wait-list">
          <Button class="success !min-h-45-px f-14-px px-5" text="Start 14 Day Free Trial" />
        </RouterLink> -->
        <a href="/wait-list" >
       <button class="btn nv-btn success f-14-px px-5 btn-secondary custom-btn !min-h-45-px"> Start 14 Day Free Trial </button></a>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
export default {
  components: { Button, },
}
</script>

<style lang="sass" scoped>
.outer-wrapper
  padding-bottom: 6rem
.outer
  width: 100%
  max-width: 1056px
  border-radius: 16px
  background-image: url(./lan_i2.svg)
  background-size: cover
  background-repeat: no-repeat
  padding: 5rem 1rem
  .title-1
    font-family: GILROY-BOLD
    font-size: 32px
    color: #FFFFFF
    text-align: center
  .subtitle-1
    font-size: 18px
    color: #FFFFFF
    text-align: center
    max-width: 604px
</style>