import moment from 'moment'
import BUS from '@/bus/BUS'
export default {
  data() {
    return {
      selectedMonth: null,
    }
  },
  created(){
    this.setCurrMonth()
  },
  watch: {
    selectedMonth(v){
      this.$BUS.$emit('stats-month-change', v)
    },
  },
  computed: {
    currentYear(){
      return moment().format('YYYY')
    },
    currentMonth(){
      return moment().format('MMMM')
    },
    months(){
      let yr = this.currentYear
      return [
        { text: 'January', value: `${yr}-01` },
        { text: 'February', value: `${yr}-02` },
        { text: 'March', value: `${yr}-03` },
        { text: 'April', value: `${yr}-04` },
        { text: 'May', value: `${yr}-05` },
        { text: 'June', value: `${yr}-06` },
        { text: 'July', value: `${yr}-07` },
        { text: 'August', value: `${yr}-08` },
        { text: 'September', value: `${yr}-09` },
        { text: 'October', value: `${yr}-10` },
        { text: 'November', value: `${yr}-11` },
        { text: 'December', value: `${yr}-12` },
      ]
    },
  },
  methods: {
    setCurrMonth(){
      this.selectedMonth = moment().format('YYYY-MM')
    },
  },
}