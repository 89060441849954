<template>
  <div class="order-shopping">
    <!-- <d-head title="Orders" class="mb-4" :cart="true" /> -->
    <div class="d-card py-3 px-3">
      <div class="products-by-categories">
        <div class="products-by-category pb-5 mb-5" v-for="(cat, cI) in categories" :key="cI">
          <div class="category-header d-flex flex-column flex-lg-row justify-content-between">
            <div class="cat-info d-flex align-items-center order-2 order-lg-1">
              <div class="cat-image mr-2"></div>
              <div class="cat-content">
                <p class="mb-0 cat-title">{{cat}}</p>
                <p class="mb-0 cat-desc text-success">{{cat}}</p>
              </div>
            </div>
            <div class="filters d-lg-flex align-items-center order-1 order-lg-2 mb-3 mb-lg-0" v-if="cI == 0">
              <div class="search-filter-b mb-2 mb-lg-0 mr-lg-2 d-flex align-items-center justify-content-between">
                <p class="mb-0 d-desc__2 mr-2">Search Filters</p>
                <caret-down-fill />
              </div>
              <div class="sort-by d-flex align-items-center">
                <p class="mb-0 d-desc__2 srt-text">Short by</p>
                <b-select v-model="filterBy.sortBy" :options="sortOptions" class="s-drp"></b-select>
              </div>
            </div>
          </div>
          <div class="products-wrapper pb-3 mt-3">
            <product-card v-for="p in 20" :key="p" class="mr-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaretDownFill from '@/components/svg/CaretDownFill'
import ProductCard from '@/components/shopping/ProductCard'
import DHead from '@/components/dashboard/DHead'
export default {
  components: {
    CaretDownFill,
    ProductCard,
    DHead,
  },
  data: () => ({
    filterBy: {
      sortBy: 'cheapset'
    },
    sortOptions: ['cheapset'],
    categories: ['Discounted Products', 'My Favorites'],
  }),
}
</script>

<style lang="sass" scoped>

@import '../../style/shopping'
.order-shopping
  .products-by-categories
    .products-by-category
      border-bottom: solid 1px rgba(28, 69, 90, 0.1)
      &:last-child
        border-bottom: none
        margin-bottom: 0 !important
        padding-bottom: 0 !important
      .category-header
        .cat-info
          .cat-image
            width: 31px
            height: 31px
            background-color: #1c455a
      .products-wrapper
        display: flex
        width: 100%
        overflow-x: auto
</style>