<template>
  <div class="no-notification">
    <p class="d-title text-center mb-2" style="font-size: 16px;">Your cart is empty</p>
    <p class="d-desc text-center mb-4" style="font-family: Gilroy-Medium;">Your shopping cart is empty, start searching for </br> items to add to your cart.</p>
    <div class="no-notf-img-wrapper mx-auto">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

.no-notf-img-wrapper
  width: 100%
  max-width: 120px
  max-height: 119px
</style>