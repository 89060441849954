<template>
  <div :class="[fullFlex ? 'd-flex' : 'd-inline-flex']">
    <div :class="['d-search', inputClasses]" :style="{backgroundColor: inputColor}">
      <b-input class="s-input" :placeholder="placeholder" v-model="searchT" :style="{backgroundColor: inputColor}"></b-input>
    </div>
    <!-- <div class="cursor-pointer d-search ml-2" @click="emitSearch()">
      <b-icon class="s-icon" icon="search"></b-icon>
    </div> -->
    <b-btn class="custom-btn ml-3 search-btn" @click="emitSearch()">
      <b-spinner small variant="light" v-if="searching"></b-spinner>
      <span v-else>Search</span>
      <!-- <b-icon class="s-icon" icon="search" v-else></b-icon> -->
    </b-btn>
  </div>
</template>

<script>
import ProductSearchBus from '@/bus/ProductSearchBus'
export default {
  props: {
    setSearch: Function,
    redirect: String,
    initText: String,
    fullFlex: Boolean,
    inputClasses: String,
    inputColor: {
      type: String,
      default: '#fff'
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
  },
  data: () => ({
    searchT: '',
    searching: false,
  }),
  watch: {
    searchT(v){
      if(this.setSearch) this.setSearch(v)
    },
  },
  beforeDestroy(){
    this.$BUS.$off('search-filter')
    this.$BUS.$off('searching')
  },
  created(){
    if(this.initText) this.searchT = this.initText
    this.$BUS.$on('search-filter', this.emitSearch)
    this.$BUS.$on('searching', v => {
      this.searching = v
    })
  },
  methods: {
    emitSearch(filters){
      if(this.redirect) this.$router.push({
        name: this.redirect,
        query: {...this.$route.query,q : this.searchT},
        params: this.$route.params
      })
      else if(this.searchT.length > 3) this.$BUS.$emit('search', {
        q: this.searchT,
        filters: filters || null
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'
.d-search
  background-color: #ffffff
  display: flex
  align-items: center
  padding: 0 16px
  border-radius: 12px
  // box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  border: solid 1px #EBF4F7
  .s-input
    font-family: Gilroy-MEDIUM
    color: #788288
    font-size: 14px
    outline: none
    box-shadow: none
    border: none
    border-radius: 0
    line-height: 2.5
    &:focus
      outline: none
      box-shadow: none
    &::placeholder
      font-family: Gilroy-MEDIUM
      font-size: 14px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      color: #788288
  &.greenplaceholder
    .s-input
      &::placeholder
        color: #{$green} !important
.search-btn
  font-size: 14px
  width: 120px
  border-radius: 8px !important
  min-height: 45px
  background-color: #E959F7
  border-color: #E959F7
</style>