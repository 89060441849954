<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
}
</script>

<style>

</style>