<template>
  <div>
    <!-- <p class="d-title f-12-px">Manually Apply Promo Code</p>
    <div class="d-flex align-items-center gap-12-px w-100 mb-3" v-for="promo in promosApplied" :key="promo">
      <b-input :value="promo" disabled class="custom-input flex-grow-1" placeholder="Input Promo Code"></b-input>
    </div>
    <div class="d-flex align-items-center my-3 my-lg-0 gap-12-px w-100">
      <b-input v-model="promocode" class="custom-input flex-grow-1" placeholder="Input Promo Code"></b-input>
      <Button :text="promoSuccess ? 'Applied' : 'Apply'" :class="[ promoSuccess && 'success']" :onclick="submit" :submitting="submitting" />
    </div>
    <p class="d-desc text-danger__2 ml-lg-3 my-3 my-lg-0" v-if="promoError">{{promoError}}</p> -->
    <!-- <p class="d-desc text-success ml-lg-3 my-3 my-lg-0" v-else-if="promoSuccess">{{promoSuccess}}</p> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import Button from '@/components/ui/Button'
export default {
  components: { Button },
  mixins: [ OfficeMixin ],
  props: {
    vendorId: Number,
  },
  data: () => ({
    promoSuccess: false,
    promoError: null,
    submitting: false,
    promocode: null,
    promosApplied: [],
  }),
  methods: {
    ...mapActions('CART', ['SET_PROMO']),
    async submit(){
      if(this.submitting || this.promocode == null || this.promocode.trim().length == 0) return
      this.promoSuccess = null
      this.promoError = null
      this.submitting = true
      try{
        let payload = {
          office: this.officeId,
          vendor_id: this.vendorId,
          promo_code: this.promocode
        }
        // await this.SET_PROMO(payload)
        this.promoSuccess = true
        setTimeout(() => {
          this.promoSuccess = false
          this.$emit('promo-done')
          this.promosApplied.push(this.promocode)
        }, 1000)
      }catch(e){
        this.promoError = e
      }
      this.submitting = false
    },
  },
}
</script>
