<template>
  <div class="inventory-list">
    <category-wise-products :padx="padx" />
    <DashboardFooter class="mt-4" />
  </div>
</template>

<script>
import CategoryWiseProducts from '@/components/dashboard/CategoryWiseProducts'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
export default {
  props: {
    padx: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CategoryWiseProducts,
    DashboardFooter,
  },
}
</script>

<style lang="sass" scoped>

.inventory-list
  ::v-deep .products-carousel
    .owl-item
      width: max-content !important
      min-width: 235px
      .product-card
        max-width: 215px
  .va-btn
    width: 144px
    height: 36px
    font-size: 12px
  .products-wrapper
    .product-card
      width: 24%
      margin-right: calc(4% / 4)
      margin-bottom: 15px
      // &:nth-child
      @media screen and (max-width: 992px)
        margin-right: 0
        width: 100%
  .s-input
    // border-top-right-radius: 0
    // border-bottom-right-radius: 0
    // border-right: 0
    width: 100%
    @media screen and (min-width: 992px)
      width: 250px
  .s-btn
    min-width: max-content
    // background-color: #E959F7
    // border-color: #E959F7
    // border-top-left-radius: 0
    // border-bottom-left-radius: 0
</style>