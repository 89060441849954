<template>
  <div class="align-items-center justify-content-between gap-y-16-px">
    <div class="d-flex flex-column flex-xl-row gap-y-24-px gap-x-44-px pt-xl-2">
      <RouterLink to="/features" class="h-link text-center" :class="[$route.name === 'Features' && 'active']">Features
      </RouterLink>
      <RouterLink to="/vendors" class="h-link text-center" :class="[$route.name === 'Vendors' && 'active']">Vendors
      </RouterLink>
      <RouterLink to="/pricing" class="h-link text-center" :class="[$route.name === 'Pricing' && 'active']">Pricing
      </RouterLink>
      <a href="https://blog.joinordo.com/" class="h-link text-center">Blog</a>
      <!-- <RouterLink to="/videos" class="h-link text-center" :class="[ $route.name === 'Videos' && 'active' ]">Resources</RouterLink> -->
    </div>
    <div class="d-flex flex-column flex-xl-row gap-16-px nv-btn-wrapper">
      <RouterLink to="/login">
        <Button class="nv-btn min-w-unset login" text="Log In" />
      </RouterLink>
      <RouterLink to="/product-demo">
        <Button class="nv-btn success__outline px-4" style="border-width: 2px" text="Product Demo" />
      </RouterLink>
      <!-- <RouterLink to="/signup">
        <Button class="nv-btn success px-4" text="Sign Up" />
      </RouterLink> -->
      <!-- <a href="/Onboarding" >
       <button class="btn nv-btn success px-4 btn-secondary custom-btn"> Sign Up </button></a> -->
      <a href="/wait-list">
        <button class="btn nv-btn success px-4 btn-secondary custom-btn"> Join our Waitlist </button></a>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Features from './Features.vue'
export default {
  components: { Button, Features, },
  watch: {
    $route() {
      let el = this.$refs.featuresDrp
      if (el) {
        el.hide()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../style/variables'
.h-link
  color: #788288
  text-decoration: none !important
  line-height: 1
  font-size: 16px
  font-family: GILROY-SEMIBOLD
  &:hover , &.active
    color: #{$red2}
.nv-btn-wrapper
  .nv-btn
    min-height: 45px
    font-size: 14px
    &.login
      padding-left: 2rem
      padding-right: 2rem
  @media screen and (max-width: 992px)
    width: 100%
    .nv-btn
      width: 100%
  @media screen and (min-width: 992px)
    .nv-btn
      min-width: 160px
      &.min-w-unset
        min-width: unset
::v-deep .feature-menu
  max-height: 90vh
  overflow-y: auto
  top: 27px !important
  @media screen and (max-width:992px)
    top: 0 !important
    max-height: 300px
  @media screen and (min-width:350px) and (max-width:992px)
    left: unset !important
    right: calc( 100% - (74px / 2) - (280px / 2) ) !important
</style>