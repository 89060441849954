<template>
  <div class="d-card px-3 py-3">
    <div class="d-flex flex-column flex-lg-row align-items-center gap-30-px mobile-gap-0 position-relative">
      <Logo class="logo" />
      <RouterLink to="/privacy" target="_blank" class="mb-0 d-block t-link f-14-px d-desc ml-lg-auto">Privacy Policy</RouterLink>
      <RouterLink to="/terms" target="_blank" class="mb-0 d-block t-link f-14-px d-desc">Terms of Use</RouterLink>
      <p class="mb-0 d-block t-link f-14-px d-desc">Ordo LLC. 2023 All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logo/Logo.vue'
export default {
  components: { Logo, },
}
</script>

<style lang="sass" scoped>

.logo
  width: 120px
  height: 34px
  @media screen and (min-width: 992px)
    position: relative
    top: -2px
.t-link
  text-decoration: none !important
@media screen and (max-width: 479px)
  .mobile-gap-0
    gap: 0 !important
</style>