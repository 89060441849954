<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    goTo(item){
      let params = { ...this.$route.params }
      if(item.params) {
        params = { ...params, ...item.params }
      }
      if (item.href) {
        window.location = item.href;
      } else if (item.name) {
        let obj = { ...item, name: item.name, params };
        this.$router.push(obj);
      } else if (item.path) {
        let obj = { path: item.path };
        this.$router.push(obj);
      }
    },
  },
}
</script>

<style>

</style>