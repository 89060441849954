<template>
    <b-collapse :visible="$store.state.ADMIN_VIEW.showOfficePicker" id="offices_picker" class="mt-2"
        style="width: auto !important;, box-shadow:none, box-border:0px, background-color: #ebf4f7">
        <b-card style="background-color: #ebf4f7, box-shadow:none, box-border:0px">
            <div style="flex-direction: column ;">
                <div class="card-box" style="flex-direction: column ;">
                    <ul>
                        <li v-for="companyData in companies" :key="companyData.company.id">
                            <input type="checkbox" :id="`company-${companyData.company.id}`" v-model="companyData.checked"
                                @change="checkAllSubItems(companyData)" style="margin-right: 10px;" />
                            <label :for="`company-${companyData.company.id}`">{{ companyData.company.name }}</label>
                            <ul>
                                <li v-for="subcompanyData, i in companyData.subcompanies"
                                    :key="subcompanyData.subcompany.id">
                                    <input type="checkbox" :id="`subcompany-${subcompanyData.subcompany.id}`"
                                        v-model="subcompanyData.checked" @change="checkAllOffices(subcompanyData)"
                                        class="custom-checkbox" />
                                    <label :for="`subcompany-${subcompanyData.subcompany.id}`" style="margin-left: 10px;">
                                        Region {{ i + 1 }}
                                    </label>

                                    <b-icon style="float:right; margin-left: 10px " v-if="subcompanyData.offices.length > 0"
                                        @click="toggleOfficesCollapse(subcompanyData)" icon="caret-down"></b-icon>

                                    <b-collapse :visible="subcompanyData.showOffices"
                                        :id="`inner-${subcompanyData.offices[0].office.id}`" class="mt-2">
                                        <ul>
                                            <li v-for="officeData in subcompanyData.offices" :key="officeData.office.id">
                                                <input type="checkbox" :id="`office-${officeData.office.id}`"
                                                    v-model="officeData.checked" @change="checkOffice(officeData)" />

                                                <label style="margin-left: 10px" :for="`office-${officeData.office.id}`">{{
                                                    officeData.office.name
                                                }}
                                                </label>
                                            </li>
                                        </ul>
                                    </b-collapse>
                                </li>

                            </ul>
                        </li>
                    </ul>
                    <b-button @click="toggleAndFetchVendorOrders" variant="primary">GO</b-button>
                </div>
            </div>
        </b-card>
    </b-collapse>
</template>
  

<script>
import { mapActions, mapMutations } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'

export default {
    mixins: [OfficeMixin],

    data() {
        return {
            companies: null,
            isChecklist: [],
            checkAll: false,
        };
    },
    computed: {

    },
    methods: {
        ...mapActions('ADMIN_VIEW', ['FETCH_COMPANY_TREE', 'SET_SELECTED_OFFICES', 'FETCH_VENDOR_ORDERS']),
        ...mapActions('ADMIN_ORDER', ['SET_SORTING_SLUG', 'GET_INVOICE', , 'FETCH_PENDING_STATS', 'APPROVE_ORDER', 'UPDATE_ORDER', 'UPDATE_PENDING_ORDER', 'APPROVE_PENDING_ORDER', 'CREATE_MANUAL_ORDER', 'DELETE_MANUAL_ORDER']),
        ...mapMutations('ADMIN_VIEW', ['toggleShowOfficePicker']),

        setSelectedOffice(id, check) {
            this.SET_SELECTED_OFFICES({ value: id, check });
        },

        async toggleAndFetchVendorOrders() {
            this.toggleShowOfficePicker();
            await this.fetchVendorOrder();
        },

        async fetchVendorOrder() {
            let payload = {
                office: this.officeId,
            }
            let res = await this.FETCH_VENDOR_ORDERS(payload)
            if (res.total == 0) {
                this.showSpin = true
                this.timer = setTimeout(() => {
                    this.fetchVendorOrder()
                }, 30 * 1000)
            } else {
                if (this.timer) clearTimeout(this.timer)
            }
        },

        checkAllSubItems(companyData) {
            const isChecked = companyData.checked;
            companyData.subcompanies.forEach((subcompanyData) => {
                subcompanyData.checked = isChecked;
                subcompanyData.offices.forEach((officeData) => {
                    this.setSelectedOffice(officeData.office.id, isChecked);
                    officeData.checked = isChecked;
                });
            });
        },
        checkAllOffices(subcompanyData) {
            const isChecked = subcompanyData.checked;
            subcompanyData.offices.forEach((officeData) => {
                this.setSelectedOffice(officeData.office.id, isChecked);
                officeData.checked = isChecked;
            });
        },
        checkOffice(officeData) {
            const isChecked = officeData.checked;
            this.setSelectedOffice(officeData.office.id, isChecked);
        },

        toggleOfficesCollapse(subcompanyData) {
            this.$set(subcompanyData, 'showOffices', !subcompanyData.showOffices);
        },

        async fetchCompanyTree() {
            this.companies = await this.FETCH_COMPANY_TREE();
            this.companies = this.companies.data
            console.log(this.companies)
        }
    },
    created() {
        this.fetchCompanyTree();
    },
}
</script>

<style lang="scss" scoped>
.animated-div {
    background-color: green;
    overflow: hidden;
    transition: width 2s ease-out;
}

.custom-checkbox[type="checkbox"]:checked {
    background-color: #11b026;
}

.slide-enter-active {
    .slide-leave-active {
        transition: width 2s;
    }
}

.slide-enter {
    .slide-leave-to {
        width: 100%;
    }
}

.card {
    background-color: transparent;
    border: 0 !important;
    box-shadow: none;
    margin-top: 92px;

    .card-box {
        width: auto;
        display: inline-flex;
        padding: 1rem 2rem;
        background-color: #fff;
        border-radius: 12px;
        min-width: 280px;

        >ul {
            padding: 0 !important;
        }

        ul {
            padding-left: 1rem;
        }
    }
}
</style>
  