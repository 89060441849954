import axios from '@/axios'
import getQuery from '@/mixins/queryStringBuilder'

const state = {
  connected_vendors: [],
  stats: null,
  teamMembers: [],
  fetchingBudgetStats: true,
  fetchingConnectedVendors: true,
  fetchingTeamMebers: true,
  orderFormData: {
    items: []
  },
  officeBudget: {},
  fetchingBudget: false, 
  updatingBudget: false,
  openDentalConStat: {},
  showLocations: null,
}

const getters = {
  connected_vendors: s => s.connected_vendors,
  stats: s => s.stats, 
  teamMembers: s => s.teamMembers,
  showLocations: s => s.showLocations,
}

const mutations = {
  SET_CONNECTED_VENDORS: (s, v) => s.connected_vendors = v,
  SET_STATS: (s, v) => s.stats = v,
  SET_TEAM_MEMBERS: (s, v) => s.teamMembers = v,
  UPDATE_CONNECTED_VENDOR: (s, v) => {
    let index = s.connected_vendors.findIndex(ven => ven.id == v.id)
    if (index > -1) {
      s.connected_vendors[index] = v
    }
  },
  SET_SHOW_LOCATIONS: (s, v) => s.showLocations = v
}

const actions = {
  // SET_SHOW_LOCATIONS: (s, v) => s.showLocations = v,

  UPDATE_OFFICE: async ({ rootGetters, commit }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.id
      delete payload.id
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}`).put(payload)
      commit('USER/UPDATE_OFFICE', data.data, { root: true })
      return data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  LIST_OFFICE_VENDORS: async ({ }, payload) => {
    try {
      let { data } = await axios.reqApi(`offices/${payload}/spending?by=vendor`).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  OFFICE_SPENDING_BY_MONTH: async ({ }, payload) => {
    try {
      let { data } = await axios.reqApi(`offices/${payload}/spending?by=month`).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_PRODUCTS: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${payload}/order-products`).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  INVITE_MWMBER: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let { data } = await axios.reqApi(`companies/${companyId}/members`).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  TEAM_MEMBERS: async ({ rootGetters, commit, state }, payload) => {
    try {
      state.fetchingTeamMebers = true
      let companyId = null
      if (payload != null && payload.company != undefined) {
        companyId = payload.company
      } else {
        companyId = rootGetters['USER/company'].id
      }
      let url = `companies/${companyId}/members`
      if (payload && payload.office != null) {
        url += `?office_id=${payload.office}`
      }
      let { data } = await axios.reqApi(url).get()
      commit('SET_TEAM_MEMBERS', data.data)
      state.fetchingTeamMebers = false
      return data.data;
    } catch (e) {
      state.fetchingTeamMebers = false
      return Promise.reject(e.response.data.message);
    }
  },
  DELETE_MEMBER: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let { data } = await axios.reqApi(`companies/${companyId}/members/${payload}`).delete()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  REMOVE_OFFICE: async ({ rootGetters }, payload) => {
    try {
      const companyId = payload.company || rootGetters['USER/company'].id
      console.log(companyId, payload.company)
      if (payload.company) delete payload.company
      const office = payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}`).delete()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_MEMBER_ROLE: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const member = payload.member
      delete payload.member
      let { data } = await axios.reqApi(`companies/${companyId}/members/${member}`).put(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_OFFICE_BILLING: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let { data } = await axios.reqApi(`companies/${companyId}/offices/${office}`).put(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  CURRENT_MONTH_BUDGET: async ({ rootGetters, commit, state }, payload) => {
    try {
      const saveData = payload.saveData
      delete payload.saveData
      if (saveData !== false) state.fetchingBudgetStats = true
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/vendor-orders/stats`
      let query = getQuery(payload.filters)
      url += query
      // if(payload.month) url += `?month=${payload.month}`
      let { data } = await axios.reqApi(url).get()
      if (saveData !== false) commit('SET_STATS', data.data)
      state.fetchingBudgetStats = false
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_CONNECTED_VENDORS: async ({ rootGetters, commit, state }, payload) => {
    try {
      state.fetchingConnectedVendors = payload.loading || true
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const save = payload.save || true
      let url = `companies/${companyId}/offices/${office}/vendors`
      let { data } = await axios.reqApi(url).get()
      if (save) state.connected_vendors = JSON.parse(JSON.stringify(data.data))
      if (state.fetchingConnectedVendors == true) state.fetchingConnectedVendors = false
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_BUDGET_VS_SPEND: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/budgets/charts?start_month=${payload.start_month}&end_month=${payload.end_month}`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  REQUEST_VENDOR: async ({ rootGetters }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      let url = `companies/${companyId}/vendor-requests`
      payload['company'] = companyId;
      let { data } = await axios.reqApi(url).post(payload)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  UPDATE_CONNECTED_VENDOR_INFO: async ({ rootGetters, commit }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const vendorId = payload.id
      delete payload.office
      delete payload.id
      let url = `companies/${companyId}/offices/${office}/vendors/${vendorId}`
      payload['company'] = companyId;
      let { data } = await axios.reqApi(url).patch(payload)
      commit('UPDATE_CONNECTED_VENDOR', data.data)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  UPDATE_OFFICE_SETTINGS: async ({ rootGetters, commit }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/settings`
      let { data } = await axios.reqApi(url).post(payload)
      commit('USER/UPDATE_OFFICE_SETTINGS', {
        ...data.data,
        officeId: office
      }, { root: true })
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  REMOVE_VENDOR: async ({ rootGetters, commit }, payload) => {
    try {
      const companyId = rootGetters['USER/company'].id
      const office = payload.office
      const vendor = payload.vendor
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/vendors/${vendor}`
      let { data } = await axios.reqApi(url).delete()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  FETCH_OPENDENTAL_CONNECTION_STATUS: async ({ rootGetters, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/open_dental_connect_status`
      let { data } = await axios.reqApi(url).get()
      state.openDentalConStat[office] = data.data.connected
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  GET_AVAILABLE_DENTAL_KEY: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/available_dental_key`
      let { data } = await axios.reqApi(url).get()
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  SET_OPENDENTAL_CONNECTION_STATUS: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/dental_api`
      let { data } = await axios.reqApi(url).post(payload)
      if (!rootGetters['USER/company']) dispatch('ONBOARDING/FETXH_COMPANY_WITH_OFFICES', {}, { root: true })
      else await dispatch('USER/GET_ACCOUNT', {}, { root: true })
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  UNLINK_OPENDENTAL: async ({ rootGetters, dispatch }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/unlink_open_dental`
      let { data } = await axios.reqApi(url).post()
      state.openDentalConStat[office] = false
      if (!rootGetters['USER/company']) dispatch('ONBOARDING/FETXH_COMPANY_WITH_OFFICES', {}, { root: true })
      else await dispatch('USER/GET_ACCOUNT', {}, { root: true })
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  },
  FETCH_OFFICE_BUDGETS: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      let url = `companies/${companyId}/offices/${office}/budgets/stats`
      const { data } = await axios.reqApi(url).get()
      if (data.data) {
        if (data.data.office == null) data.data.office = payload.office
        if (data.data.basis == null) data.data.basis = 1
        if (data.data.subaccounts.length == 0) {
          data.data.subaccounts = [
            { slug: "dental", percentage: null, spend: 0, name: 'Dental Supply Budget' },
            { slug: "office", percentage: null, spend: 0, name: 'Front Office Supply Budget' }
          ]
        }
        state.officeBudget[payload.office] = data.data
      } else {
        state.officeBudget[payload.office] = {
          subaccounts: [
            { slug: "dental", percentage: null, spend: 0, name: 'Dental Supply Budget' },
            { slug: "office", percentage: null, spend: 0, name: 'Front Office Supply Budget' }
          ],
          month: currentMonth,
          basis: 1,
          adjusted_production: 0,
          collection: 0,
          office: payload.office
        }
      }
      return data.data
    } catch (e) {
      throw e
    }
  },
  CREATE_CUSTOM_BUDGET: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      state.updatingBudget = true
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      const budgetId = payload.id
      delete payload.office
      delete payload.budgetId
      if (budgetId != null) {
        let url = `companies/${companyId}/offices/${office}/budgets/${budgetId}`
        const { data } = await axios.reqApi(url).patch(payload)
        state.officeBudget[office] = data.data
        state.updatingBudget = false
        return data.data
      } else {
        payload.basis = payload.basis || 1
        let url = `companies/${companyId}/offices/${office}/budgets`
        const { data } = await axios.reqApi(url).post(payload)
        state.officeBudget[office] = data.data
        state.updatingBudget = false
        return data.data
      }
    } catch (e) {
      state.updatingBudget = false
      throw e
    }
  },
  FETCH_FULL_STATS: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      delete payload.office
      let url = `companies/${companyId}/offices/${office}/budgets/full_stats`
      url += getQuery(payload)
      return axios.reqApi(url).get()
    } catch (e) {
      throw e
    }
  },
  RESEND_INVITE: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const member_id = payload.member_id
      delete payload.member_id
      let url = `companies/${companyId}/members/${member_id}/reinvite_member`
      return axios.reqApi(url).post(payload)
    } catch (e) {
      throw e
    }
  },
  REMOVE_CUSTOM_BUDGET: async ({ rootGetters, dispatch, state }, payload) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id
      const office = payload.office
      const subaccountId = payload.subaccountId
      delete payload.office
      delete payload.subaccountId
      let url = `companies/${companyId}/offices/${office}/subaccounts/${subaccountId}/remove`
      await axios.reqApi(url).post(payload)
      state.officeBudget[office].subaccounts.splice(state.officeBudget[office].subaccounts.findIndex(s => s.id == subaccountId), 1)
      return
    } catch (e) {
      throw e
    }
  },
  CANCEL_SUBSCRIPTION:  async ({ rootGetters }) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id;
      // const office = officeId
      // let url = `companies/${companyId}/offices/${office}/cancel-subscription`;
      let url = `subscription/cancel-subscription?company_id=${companyId}`;
      console.log('URL: ', url);
      let response = await axios.reqApi(url).post();
      return response;
    } catch (e) {
      throw e
    }
  },
  RENEW_SUBSCRIPTION:  async ({ rootGetters }) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id;
      // const office = officeId
      // let url = `companies/${companyId}/offices/${office}/renew-subscription`;
      let url = `subscription/renew-subscription?company_id=${companyId}`;
      console.log('URL: ', url);
      let response = await axios.reqApi(url).post();
      return response;
    } catch (e) {
      throw e
    }
  },
  SUBSCRIPTION_DETAILS:  async ({ rootGetters }) => {
    try {
      const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id;
      // const office = officeId
      // let url = `companies/${companyId}/offices/${office}/renew-subscription`;
      let url = `subscription/subscription-details?company_id=${companyId}`;
      let response = await axios.reqApi(url).get();
      return response.data.data;
    } catch (e) {
      throw e
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}