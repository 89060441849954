import axios from '@/axios'

const state = {
  filters: {},
}

const getters = {
  filters: s => s.filters,
}

const mutations = {
  SET_FILTERS: (s, v) => s.filters = v,
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}