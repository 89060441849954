<template>
  <div class="d-card px-3 py-3">
    <div class="d-flex flex-column flex-lg-row gap-x-16-px align-items-lg-center">
      <p class="gilroy-b f-20-px mb-0 flex-grow-1 w-100">
        <span class="d-none d-md-block">
          {{ 
            type == 'inc' ? "You'll be doing more of these procedures in the future" :
            type == 'dec' ? "You'll be doing less of these procedures in the future" :
            "You'll be doing the same amount of these procedures in the future"
          }}
        </span>
        <span class="d-block d-md-none">
          {{ 
            type == 'inc' ? "Increased" :
            type == 'dec' ? "Decreased" :
            "Unchanged"
          }}
        </span>
      </p>
      <b-tooltip :target="`tooltip-futurrcast-category-type-${type}`" triggers="hover focus" placement="top" boundary="document" custom-class="promotion-tooltip tooltip-size-md">
        <p class="d-desc mb-0">Futurecast calculates your average completed procedures over the past 90 days and compares it to your upcoming schedule. You'll receive notifications of any deviations from your trend, allowing you to adjust your ordering accordingly.</p>
      </b-tooltip>
      <p class="d-desc__2 mb-0 futurecast-avg-calc-i-text cursor-pointer" :id="`tooltip-futurrcast-category-type-${type}`" v-if="categories.length > 0">
        <span>How average is calculated</span>
        <img src="@/assets/images/information.png" alt="" class="ml-2 mb-1 h-20-px w-20-px">
      </p>
    </div>
    <div class="no-data d-flex justify-content-center align-items-center p-4 mt-4 ml-30-px" v-if="categories.length == 0">
      <p class="gilroy-b f-20-px text-white text-center mb-0">No data to display during this time period</p>
    </div>
    <div class="d-flex align-items-center gap-14-px" v-else>
      <!-- <img src="@/assets/images/left_pol_arr.png" alt="" @click="scroll('left')" class="cursor-pointer w-14-px h-18-px hidden" :class="[scrollLeft > 0 && cardHolder && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"> -->
      <div class="d-flex mt-4 gap-20-px" style="overflow-x: auto;" ref="cardHolder" @scroll="onScroll">
        <div v-for="(category, i) in categories" :key="category.slug">
          <CastCategoryCard :category="category" @favorite-updated="$forceUpdate()" />
        </div>
      </div>
      <!-- <img src="@/assets/images/right_pol_arr.png" alt="" @click="scroll('right')" class="cursor-pointer w-14-px h-18-px hidden" :class="[!scrollEnded && cardHolder && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CastCategoryCard from '@/components/future-cast/CategoryCard.vue'
export default {
  props: {
    type: String,
  },
  components: { CastCategoryCard, },
  data(){
    return {
      scrollLeft: 0,
      scrollEnded: false,
      cardHolder: null,
    }
  },
  mounted(){
    this.cardHolder = this.$refs.cardHolder
  },
  computed: {
    ...mapState('PROCEDURE', ['filterBy', 'summaryCategories']),
    categories(){
      if(this.type == 'inc') return this.summaryCategories.filter(category => category.avg_count != 0 && category.count > (category.avg_count || 0)).sort((a, b) => (b.count - (b.avg_count || 0)) - (a.count - (a.avg_count || 0)))
      if(this.type == 'dec') return this.summaryCategories.filter(category => category.avg_count != 0 && category.count < (category.avg_count || 0)).sort((a, b) => ((b.avg_count || 0) - b.count) - ((a.avg_count || 0) - a.count))
      return this.summaryCategories.filter(category => category.avg_count == 0 || category.count == (category.avg_count || 0))
    },
  },
  methods: {
    onScroll(){
      let el = this.$refs.cardHolder
      if(el){
        this.scrollLeft = el.scrollLeft
        if(el.getClientRects()[0].width + this.scrollLeft > el.scrollWidth - 10){
          this.scrollEnded = true
        }else {
          this.scrollEnded = false
        }
      }
    },
    scroll(direction){
      let el = this.$refs.cardHolder
      if(el){
        if(direction == 'right'){
          el.scrollTo(el.scrollLeft + 300 , 0)
        }
        if(direction == 'left'){
          el.scrollTo(el.scrollLeft - 300 , 0)
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.futurecast-avg-calc-i-text
  @media screen and (min-width: 992px)
    min-width: max-content
    margin-right: 28px
.no-data
  background: linear-gradient(120.66deg, #C73CEA -9.54%, #50AAD5 52.67%, #5EBC3B 123.91%)
  border-radius: 10px
  width: 300px
  height: 230px
</style>