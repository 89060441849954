<template> 
  <div class="notf px-2 pt-2 pb-3">
    <p class="f-12-px gilroy-b" style="color: #274458">Current Offices</p>
    <b-card>
      <ul>
        <li v-for="companyData in companies" :key="companyData.company.id">
          <input type="checkbox" :id="`company-${companyData.company.id}`" v-model="companyData.checked"
            @change="checkAllSubItems(companyData)" />
          <label :for="`company-${companyData.company.id}`">{{ companyData.company.name }}</label>
          <ul>
            <li v-for="subcompanyData in companyData.subcompanies" :key="subcompanyData.subcompany.id">
              <input type="checkbox" :id="`subcompany-${subcompanyData.subcompany.id}`" v-model="subcompanyData.checked"
                @change="checkAllOffices(subcompanyData)" />
              <label :for="`subcompany-${subcompanyData.subcompany.id}`">{{ subcompanyData.subcompany.name
              }}</label>

              <b-icon v-if="subcompanyData.offices.length > 0" @click="toggleOfficesCollapse(subcompanyData)"
                icon="caret-down" />

              <b-collapse :visible="subcompanyData.showOffices" :id="`inner-${subcompanyData.offices[0].office.id}`"
                class="mt-2">
                <ul>
                  <li v-for="officeData in subcompanyData.offices" :key="officeData.office.id">
                    <input type="checkbox" :id="`office-${officeData.office.id}`" v-model="officeData.checked" />
                    <label :for="`office-${officeData.office.id}`">{{ officeData.office.name
                    }}</label>
                  </li>
                </ul>
              </b-collapse>
            </li>

          </ul>
        </li>
      </ul>
      <p class="card-text">Collapse contents Here</p>
      <b-button v-b-toggle.collapse-1-inner size="sm">Toggle Inner Collapse</b-button>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <b-card>Hello!</b-card>
      </b-collapse>
    </b-card>
    <div class="test-end pt-4">
      <button class="custom-btn btn o-btn ml-md-auto add-o-btn btn-secondary"
        @click="goTo({ name: 'Settings', hash: '#Ofc' })">Add another office</button>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

import GoTo from '@/mixins/GoTo'
export default {
  mixins: [OfficeMixin, GoTo],
  // data: {
  //   showLocations: false,
  //   companies: null,
  //   isChecklist: [],
  //   checkAll: false,
  // },

  data() {
        return {
          // showLocations: false,
            companies: null,
            isChecklist: [],
            checkAll: false,
        };
    },
  methods: {
    ...mapMutations('OFFICE', ['SET_SHOW_LOCATIONS', 'SET_OFFICE_X', 'SET_OFFICE_Y']),
    ...mapGetters('OFFICE', ['showLocations']),
    ...mapActions('ADMIN_VIEW', ['FETCH_COMPANY_TREE']),
    onOver(index, event) {

      const x = event.clientX;
      const y = event.clientY;
      this.SET_OFFICE_X(x);
      this.SET_OFFICE_Y(y);

      console.log('Clicked at coordinates:', x, y);
      this.showLocations = true;
      this.SET_SHOW_LOCATIONS(index);

    },
    onLeave() {
      console.log('on leave called');
      this.showLocations = false;
      this.SET_SHOW_LOCATIONS(null);

    },
    openOfc(ofc) {
      let index = this.offices.indexOf(ofc)
      let url = window.location.origin + '/' + index + '/dashboard'
      const x = event.clientX;
      const y = event.clientY;

      // Now you can use x and y as needed
      console.log('Clicked at coordinates:', x, y);
      // window.open(url, '_blank').focus()
      window.location = url
    },
    checkAllSubItems(companyData) {
      const isChecked = companyData.checked;
      companyData.subcompanies.forEach((subcompanyData) => {
        subcompanyData.checked = isChecked;
        subcompanyData.offices.forEach((officeData) => {
          officeData.checked = isChecked;
        });
      });
    },
    checkAllOffices(subcompanyData) {
      const isChecked = subcompanyData.checked;
      subcompanyData.offices.forEach((officeData) => {
        officeData.checked = isChecked;
      });
    },
    toggleOfficesCollapse(subcompanyData) {
      try {
        subcompanyData.showOffices = !subcompanyData.showOffices;
      }
      catch (e) {
        subcompanyData.showOffices = true;
      }
    },
    async fetchCompanyTree() {
      this.companies = await this.FETCH_COMPANY_TREE();
      this.companies = this.companies.data
      console.log(this.companies)
    }
  },
  created() {
    this.fetchCompanyTree();
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/ui/dropdown'
.notf
  width: 100% !important

.av
  // min-width: 20px
  min-width: 41px
  min-height: 41px
  max-width: 41px
  max-height: 41px
  border-radius: 100%
  // border: 2px solid white
  img
    min-height: inherit
    min-width: inherit
    object-fit: cover
    border-radius: inherit
  &.no-image
    background-color: #50AAD5
    display: flex
    align-items: center
    justify-content: center
    span
      font-family: GILROY-BOLD
      font-size: 18px
      color: white
      margin-top: 3px
.o-name
  color: #284458
  line-height: 1.5
  &.current
    color: #5EBC3B
</style>