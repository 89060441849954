<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 650px;">Order from all your vendors on one site</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Being able to order from your vendors on one platform saves you time and money</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Your Vendors on One Platform</p>
        <p class="subtitle-3 mb-3">Ordo allows you to order from all your vendors in one place. This eliminates the need to visit multiple websites, login to different accounts, and remember different passwords. With everything in one place, you can easily browse and compare products from different vendors and complete your order in a fraction of the time.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Multi_Vendor_Orders/multi_vendor_orders_1.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4 mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/six.svg)
</style>