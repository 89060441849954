<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Henry Schein</p>
        <!-- <p class="subtitle-1 mx-auto mb-4 text-white" style="max-width: 590px;">Easy integration, reliable delivery, great documentation: integrate with the email API that developers love and start sending in minutes.</p> -->
        <!-- <div class="d-flex flex-wrap justify-content-center align-items-center gap-16-px mb-5">
          <RouterLink to="/onboarding">
            <Button class="px-4 !min-h-45-px f-14-px" text="Start 14 Day Free Trial" />
          </RouterLink>
        </div> -->
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="subtitle-3 mb-5">Henry Schein Dental offers a broad range of supplies and equipment for dental practices, including instruments, handpieces, dental chairs, digital imaging technology, and CAD/CAM solutions. Additionally, the company provides a wide variety of consumable dental supplies such as dental adhesives, anesthetics, cements, composites, and impression materials.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import CheckCircleIcon from './CheckCircleIcon.vue'
export default {
  components: { Button, CheckCircleIcon }
}
</script>

<style lang="sass" scoped>
@import ./_vendors
</style>