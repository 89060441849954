<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <p class="mb-0">Fetching Data...</p>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-center mb-3">
        <b-btn class="custom-btn o-btn" @click="next(offices.length - 1)">Save and Continue</b-btn>
      </div>
      <div v-for="(ofc, oI) in offices" :key="oI" class="mb-3">
        <div class="conn-acc">
          <div :ref="`con-acc-${oI}`" class="con-acc-head cursor-pointer d-flex justify-content-between align-items-center mb-2" v-b-toggle="`con-acc-${oI}`">
            <div class="d-flex justify-content-between align-items-center w-100 pr-3">
              <p class="c-title mb-0">Connect Vendors: {{ofc.name}}</p>
            </div>
            <b-icon icon="chevron-down"></b-icon>
          </div>
          <b-collapse :id="`con-acc-${oI}`" accordion="my-accordion" role="tabpanel" :visible="oI == 0">
            <p class="form-label mb-3">Connect your account with</p>
            <b-row>
              <b-col cols="12" lg="4" v-for="(vendor, vI) in sorted_vendors" :key="vI">
                <div class="acc-det d-flex justify-content-start align-items-center">
                  <div :class="['img-prt', vendor.slug]">
                    <img src="@/assets/images/vendors/edge_endo_logo_icon.png" alt="" class="w-100 h-100" v-if="vendor.slug === 'edge_endo'">
                    <img :src="vendor.logo" alt="" class="w-100 h-100" v-else>
                  </div>
                  <div class="ven-info-prt">
                    <p class="mb-0 form-label text-dark gilroy-b ven-name">{{vendor.name}}</p>
                    <p class="mb-0 form-label ven-desc">{{sypplyType(vendor.name)}}</p>
                  </div>
                  <div class="c-part ml-auto">
                    <div v-if="isConnected(vendor.id, ofc.id)">
                      <b-btn class="custom-btn con-btn">Connected</b-btn>
                    </div>
                    <div v-else>
                      <b-btn class="custom-btn success con-btn" @click="selectVendor(vendor, ofc)" v-if="isVendorLinkable(vendor)">Connect</b-btn>
                      <b-btn class="custom-btn danger__2 con-btn px-5-px" v-else>Coming Soon</b-btn>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="4" v-for="(vendor, vI) in upcomingVendors" :key="`upc-${vI}`">
                <div class="acc-det d-flex justify-content-start align-items-center">
                  <div :class="['img-prt', vendor.classes]">
                    <img :src="vendor.logo" alt="" :class="['w-100 h-100']">
                  </div>
                  <div class="ven-info-prt">
                    <p class="mb-0 form-label text-dark gilroy-b ven-name">{{vendor.name}}</p>
                    <p class="mb-0 form-label ven-desc">{{sypplyType(vendor.name)}}</p>
                  </div>
                  <div class="c-part ml-auto">
                    <div v-if="isConnected(vendor.id, ofc.id)">
                      <p class="form-label d-flex mb-2">
                        <span class="con-icon d-flex align-items-center justify-content-center">
                          <b-icon icon="check" variant="light" font-scale="1.3"></b-icon>
                        </span>
                        <span class="ml-2">Connected</span>
                      </p>
                    </div>
                    <div v-else>
                      <b-btn class="custom-btn danger__2 con-btn px-5-px">
                        Coming Soon
                      </b-btn>
                    </div>
                  </div>
                </div>   
              </b-col>
              <!-- <b-col cols="12" lg="4">
                <div class="acc-det d-flex justify-content-start align-items-center">
                  <div class="img-prt">
                    <img src="@/assets/images/req_ven_icon.svg" alt="" class="w-100 h-100">
                  </div>
                  <div class="ven-info-prt">
                    <p class="mb-0 form-label text-dark gilroy-b ven-name">Missing Vendor</p>
                    <p class="mb-0 form-label ven-desc">Request Vendor</p>
                  </div>
                  <div class="c-part ml-auto">
                    <div>
                      <b-btn class="custom-btn outline-primary con-btn" @click="selectVendor(null, ofc)">
                        Request
                      </b-btn>
                    </div>
                  </div>
                </div>
              </b-col> -->
            </b-row>
            <div class="d-flex justify-content-center mt-4" v-if="oI < offices.length - 1">
              <Button :text="'Next'" class="o-btn" :onclick="() => next(oI)" :submitting="submitting" />
            </div>
          </b-collapse>
          <modal :modal="conModal" @change="toggleConModal" size="md" :noCloseOnBackdrop="true">
            <div v-if="selectedVendor">
              <p class="c-title text-center mb-3">Link your {{selectedVendor.name}} account</p>
              <p class="form-label mb-3">Input your {{selectedVendor.name}} account details to link your account. Once you link your account, we'll add your previously ordered items to your inventory list.</p>
            </div>
            <div v-else>
              <!-- <p class="c-title__2 text-center mb-3">Request a vendor</p> -->
              <p class="form-label mb-3">If we are missing a vendor, input the details of the vendor you'd like to request, and we'll get it on the platform ASAP.</p>
            </div>
            <div>
              <conn-acc-form :vendor="selectedVendor" :office="selectedOffice" @close="close" />
            </div>
          </modal>
        </div>
      </div>
      <div class="conn-acc req-ven mb-3">
        <div :ref="`req-vendor`" class="con-acc-head cursor-pointer">
          <div class="d-flex justify-content-center pt-2">
            <p class="form-label text-center p1">Are we missing a vendor you typically use? Click the button below to request a vendor.</p>
          </div>
          <div class="d-flex justify-content-center">
            <b-btn class="custom-btn o-btn" v-b-toggle="`req-vendor`">Request a Vendor</b-btn>
          </div>
        </div>
        <b-collapse :id="`req-vendor`" accordion="req-vendor-accordion" role="tabpanel">
          <div class="pt-4">
            <div class="dash"></div>
            <div class="d-flex flex-column align-items-center form-wrapper mx-auto mt-4">
              <validation-observer ref="venForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(reqVendor)">
                  <div v-for="(vendor, vI) in reqVendorFormData.vendors" :key="vI" class="mb-3">
                    <validation-provider name="Name" rules="required" v-slot="validationContext">
                      <b-input v-model="vendor.name" class="custom-input" placeholder="Enter the Vendor Name" :state="getValidationState(validationContext)"></b-input>
                      <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <p class="mb-0 form-label cursor-pointer gilroy-b text-center" style="color: #FF1DC3" @click="addAnotherVendor">+ Add another vendor</p>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-5">
        <b-btn class="custom-btn o-btn" @click="next(offices.length - 1)">Save and Continue</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
import Modal from '@/components/modals/Modal'
import { mapActions, mapGetters, mapState } from 'vuex'
import ConnAccForm from '@/components/onboarding/ConnAccForm'
import FormMixin from '@/mixins/FormMixin'
import available_vendors from '@/assets/json/available_vendors.json'

export default {
  props: {
    offices: {
      type: Array,
      default: []
    },
  },
  components: {
    Input,
    Button,
    Modal,
    ConnAccForm,
  },
  mixins: [GoTo, FormMixin],
  data: () => ({
    conModal: false,
    
    vendors: [],
    fetching: true,
    selectedVendor: null,
    selectedOffice: null,
    submitting: false,

    upcomingVendors: [
      { name: 'Dentsply', logo: require('@/assets/images/vendors/dentsply.png'), classes: 'dentsply' },
      { name: 'Staples', logo: require('@/assets/images/vendors/staples.png') },
    ],

    reqVendorFormData: {
      vendors: [],
    },
    reqVendorFD: { name: null, },
    vendorConns: {},
    
  }),
  created(){
    this.fetchVendors()
    this.addAnotherVendor()
  },
  computed: {
    ...mapGetters('OFFICE', ['connected_vendors']),
    sorted_vendors(){
      return this.vendors
      .sort((a, b) => a.name > b.name ? -1 : 1)
      .sort((a, b) => this.isVendorLinkable(a) ? -1 : 1)
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['FETCH_VENDORS', 'CONNECT_VENDOR']),
    ...mapActions('USER', ['GET_ACCOUNT']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS']),
    sypplyType(vendorName){
      if(
        vendorName == 'Amazon' ||
        vendorName == 'Office Depot' ||
        vendorName == 'Staples'
      ) return 'Office Supplies'
      else return 'Dental Supplies'
    },
    addAnotherVendor(){
      this.reqVendorFormData.vendors.push(Object.assign({}, this.reqVendorFD))
    },
    async reqVendor(){},
    close(data){
      this.toggleConModal(false)
      if(data){
        this.$emit('vendor-conn')
        this.fetchVendors()
      }
    },
    isConnected(vendorId, officeId){
      return this.vendorConns[officeId].find(ven => ven.vendor.id == vendorId) != null
    },
    selectVendor(vendor, office){
      this.selectedVendor = vendor
      this.selectedOffice = office
      this.toggleConModal(true)
    },
    async fetchVendors(){
      try{
        this.$store.state.OFFICE.fetchingConnectedVendors = true
        await Promise.all(
          this.offices.map(office => {
            return new Promise((resolve, reject) => this.FETCH_CONNECTED_VENDORS({ office: office.id, save: false, loading: false }).then(res => {
              this.vendorConns[office.id] = res
              resolve(true)
            }).catch(e => reject()))
          })
        )
        this.$store.state.OFFICE.fetchingConnectedVendors = false
        let res = await this.FETCH_VENDORS()
        this.vendors = res.map(vendor => ({
          ...vendor,
          connected: false,
        }))
      }catch(e){}
      this.fetching = false
    },
    async next(index){
      if(this.submitting) return
      if(index == this.offices.length - 1) {
        this.submitting = true
        try{
          // await this.GET_ACCOUNT()
          this.$emit('next')
        }catch(e){}
        this.submitting = false
        // this.$router.push({name: 'Dashboard', query: { start_pop: 'show' }})
        return
      }
      this.$refs[`con-acc-${index}`][0].click()
      this.$refs[`con-acc-${index + 1}`][0].click()
    },
    toggleConModal(v){
      if(v != this.conModal) this.conModal = v || false
    },
    isVendorLinkable(vendor){
      if(available_vendors.includes(vendor.slug)) return true
      return false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.complete-onboarding-btn
  width: 350px
  min-height: 60px !important
  font-size: 16px !important
.conn-acc
  width: 855px
  padding: 15px 32px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  @media screen and (max-width: 992px)
    width: 100%
  .acc-det
    padding-bottom: 15px
    border-bottom: solid 1px #eff0f7
    margin-bottom: 18px
    .img-prt
      height: 57px
      width: 57px
      min-width: 57px
      border-radius: 12px
      img
        border-radius: 12px
      &.implant_direct, &.dentsply, &.office_depot, &.edge_endo
        background-color: unset
        display: flex
        align-items: center
        justify-content: center
      &.implant_direct
        img
          height: unset !important
          border-radius: 0
      &.dentsply
        img
          height: 90% !important
          width: 90% !important
          border-radius: 0
      &.office_depot
        img
          height: unset !important
          border-radius: 0
      &.edge_endo
        img
          height: unset !important
          width: 45px !important
          border-radius: 0
          transform: scale(1.3)
          transform-position: 0px 0px
    .ven-info-prt
      padding: 5px 5px
      height: 57px
      .ven-name, .ven-desc
        font-size: 14px
        text-overflow: ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
      .ven-desc
        font-size: 11px
    .c-part
      .con-icon
        width: 16px
        height: 16px
        background-color: #7cd878
        border-radius: 50%
      .con-btn
        width: 80px
        min-height: 29px
        border-radius: 4px
        font-size: 10px
        font-weight: 500
        font-stretch: normal
        font-style: normal
        line-height: normal
  &.req-ven
    .con-acc-head
      background-image: url('../../assets/images/req_vendor_bg.png')
      background-size: 100% 100%
      min-height: 120px
    .form-wrapper
      max-width: 315px
      @media (min-width: 992px)
        .custom-input
          width: 320px
    .p1
      max-width: 272px
</style>