import axios from '@/axios'
import { AxiosError } from 'axios'

const state = {
  token: null,
  profile: null,
  // isSuperUser: false,
}

const getters = {
  token: s => s.token,
  profile: s => s.profile,
  fullName: s => s.profile ? s.profile.first_name + ' ' + s.profile.last_name : null,
  company: s => s.profile ? s.profile.company : null,
  offices: s => s.profile && s.profile.company && s.profile.company.offices ? s.profile.company.offices.filter(office => office.is_active) : [],
  role: s => s.profile ? s.profile.role : null,
  profileEmail: s => s.profile ? s.profile.email : null,
  // isSuperUser: s => s.isSuperUser,
}

const mutations = {
  SET_TOKEN: (s, d) => s.token = d,
  SET_PROFILE: (s, d) => s.profile = d,
  UPDATE_PROFILE: (s, d) => {
    s.profile = {
      ...s.profile,
      ...d
    }
  },
  // SET_SUPER_USER: (state, data) => state.isSuperUser = data,
  UPDATE_OFFICE: (s, d) => {
    if(s.profile && s.profile.company && s.profile.company.offices && Array.isArray(s.profile.company.offices)){
      let offices = s.profile.company.offices
      let index = offices.findIndex(o => o.id == d.id)
      if(index >= 0) s.profile.company.offices[index] = d
    }
  },
  UPDATE_OFFICE_SETTINGS: (s, d) => {
    const officeId = d.officeId
    delete d.office
    if(s.profile && s.profile.company && s.profile.company.offices){
      let officeIndex = s.profile.company.offices.findIndex(o => o.id == officeId)
      if(officeIndex > -1){
        s.profile.company.offices[officeIndex].settings = d
      }
    }
  },
}

const actions = {
  LOGIN: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("auth/login/").post(payload);
      if(data.data != null && data.data.profile != null && data.data.profile.company != null){
        if(data.data.profile.company.on_boarding_step > 1){
          commit('SET_TOKEN', data.data.token)
          commit('SET_PROFILE', data.data.profile)
        }
        return data.data;
      }else {
        throw new Error().message = { response: { data: { data: { detail: 'Company information is missing. Please contact admin.' } } } }
      }
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
  UPDATE_ACCOUNT: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("users/me").put(payload);
      commit('SET_PROFILE', data.data)
      return data.data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  DELETE_ACCOUNT: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("users/me").delete()
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  GET_ACCOUNT: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("users/me").get()
      commit('SET_PROFILE', data.data)
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FORGOT_PASSWORD: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("password_reset/").post(payload)
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
  FORGOT_PASSWORD_VALIDATE_TOKEN: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("password_reset/validate_token/").post(payload)
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  RESET_PASSWORD: async ({commit}, payload) => {
    try {
      let { data } = await axios.reqApi("password_reset/confirm/").post(payload)
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.data);
    }
  },
  VERIFY_TOKEN: async ({state, commit}, payload) => {
    try {
      let { data } = await axios.reqApi("token-verify/").post({ token: state.token })
      
      // commit('SET_SUPER_USER', )
      // commit('SET_TOKEN', data.data.profile.is_superuser)
      commit('SET_PROFILE', data.data.profile)
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  ACCEPT_INVITE: async ({state, commit}, payload) => {
    try {
      let { data } = await axios.reqApi("accept-invite/"+payload.token).get()
      // commit('SET_TOKEN', data.data.token) 
      return data;
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  FETCH_USERS: async ({ commit }, payload) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${payload.token}`
        }
      };
      let url = `users?page=${payload.currentPage}&page_size=10`
      const response = await axios.reqApi(url).get(config);

      const users = response.data.data;
      return users;
      //commit('setUsers', users);
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  },
  LOGIN_IMPERSONATE: async ({ commit }, payload) => {
    console.log('LOGIN_IMPERSONATE token userID: ', payload);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${payload.aToken}`
        }
      };
      let userId = payload.userId;
      let url = `auth/login-impersonate/${userId}/`
      
      const response = await axios.reqApi(url).post(config);
      const user = response.data.data;
      if(user.profile.company.on_boarding_step > 1){
        commit('SET_TOKEN', user.token)
        commit('SET_PROFILE', user.profile)
      }
      return user;
      //commit('setUsers', users);
    } catch (e) {
      return Promise.reject(e.response.data.message);
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}