<template>
  <div class="category-card p-3 position-relative">
    <div class="inner position-relative">
      <p class="d-title__2 mb-0 text-center cat-title">{{ category ? category.slug : '' }}</p>
      <b-dropdown class="menu-drp" menu-class="px-0 !min-w-200-px" right>
        <template #button-content>
          <p class="d-title__2 mb-0">
            <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#284458" />
              <circle cx="2" cy="8" r="2" fill="#284458" />
              <circle cx="2" cy="13.998" r="2" fill="#284458" />
            </svg>
          </p>
        </template>
        <div class="px-4">
          <p class="drp-option d-title__6 mb-0 p-2 cursor-pointer" @click="detailModal = true">See Details</p>
        </div>
      </b-dropdown>
      <div class="cat-image">
        <img src="@/assets/images/future-cast/categories/bone_grafting.png" alt="" class="w-100 h-100"
          v-if="category.slug == 'Bone Grafting'">
        <img src="@/assets/images/future-cast/categories/crowns.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Crowns, Bridges & Veneers'">
        <img src="@/assets/images/future-cast/categories/exams.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Exams'">
        <img src="@/assets/images/future-cast/categories/extractions.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Extractions'">
        <img src="@/assets/images/future-cast/categories/fillings.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Fillings'">
        <img src="@/assets/images/future-cast/categories/Nitrous & Sedation.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Nitrous & Sedation'">
        <img src="@/assets/images/future-cast/categories/root-canal.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Root Canals'">
        <img src="@/assets/images/future-cast/categories/selants.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Sealants'">
        <img src="@/assets/images/future-cast/categories/SRPs.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'SRPs'">
        <img src="@/assets/images/future-cast/categories/DenturesPartials.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Dentures & Partials'">
        <img src="@/assets/images/future-cast/categories/mouth-guard.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Mouthguards & Nightguards'">
        <img src="@/assets/images/future-cast/categories/cleanings.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Cleanings'">
        <img src="@/assets/images/future-cast/categories/Implant_Placement.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Implant Placement'">
        <img src="@/assets/images/future-cast/categories/implant_crowns.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Implant Crowns'">
        <img src="@/assets/images/future-cast/categories/inlay&onlay.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Inlays & Onlays'">
        <img src="@/assets/images/future-cast/categories/whitening.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Whitening'">
        <img src="@/assets/images/future-cast/categories/Ortho.png" alt="" class="w-100 h-100"
          v-else-if="category.slug == 'Ortho'">
        <img src="@/assets/images/cleaning.png" alt="" class="w-100 h-100" v-else>
      </div>
      <p class="f-12-px gilroy-b text-center mt-3 mb-1" style="color: #284458;">{{ category.count }} On the Schedule</p>
      <p class="f-12-px gilroy-b text-center avg-text" :class="[type == 'dec' ? 'dec' : type == 'bal' ? 'bal' : '']">{{
        avgText }}</p>
      <b-btn class="btn1 custom-btn primary w-max mb-2 min-w-220-px mx-auto w-100" @click="productsModal = true">Order
        {{ category ? category.slug : '' }} Supplies</b-btn>
    </div>

    <modal :hideCloseIcon="true" :modal="detailModal" @change="toggleDetailModal" ref="returnModal" size="lg"
      dialog-class="detail-view-modal-future-cast-table" c-classes="px-0 pt-0 round-modal ">
      <div>
        <div class="modal-h px-4 py-3 position-relative" :class="[detailModalHeader()]">
          <p class="d-title__7 f-18-px mb-0 text-white">{{ category ? category.slug : '' }}</p>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="close-icon-btn cursor-pointer text-white" @click="detailModal = false">
            <path
              d="M17.1 2.7C17.5971 2.20294 17.5971 1.39706 17.1 0.9C16.6029 0.402944 15.7971 0.402944 15.3 0.9L9 7.2L2.7 0.9C2.20294 0.402944 1.39706 0.402944 0.9 0.9C0.402944 1.39706 0.402944 2.20294 0.9 2.7L7.2 9L0.9 15.3C0.402944 15.7971 0.402944 16.6029 0.9 17.1C1.39706 17.5971 2.20294 17.5971 2.7 17.1L9 10.8L15.3 17.1C15.7971 17.5971 16.6029 17.5971 17.1 17.1C17.5971 16.6029 17.5971 15.7971 17.1 15.3L10.8 9L17.1 2.7Z"
              fill="currentColor" />
          </svg>
        </div>

        <div class="px-4 pt-4">
          <CastTable :category="category" hide-category-title :summary-category="true" />
        </div>
      </div>
    </modal>

    <modal :noCloseOnBackdrop="true" :hideCloseIcon="true" :modal="productsModal" @change="toggleProductsModal"
      ref="returnModal" size="lg" dialog-class="return-process-modal" c-classes="px-0 pt-0 round-modal">
      <div>
        <div class="modal-h px-4 py-3 position-relative">
          <p class="d-title__7 f-18-px mb-0 text-white">{{ category ? category.slug : '' }}</p>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="close-icon-btn cursor-pointer text-white" @click="closeModalAndResetSearch">
            <path
              d="M17.1 2.7C17.5971 2.20294 17.5971 1.39706 17.1 0.9C16.6029 0.402944 15.7971 0.402944 15.3 0.9L9 7.2L2.7 0.9C2.20294 0.402944 1.39706 0.402944 0.9 0.9C0.402944 1.39706 0.402944 2.20294 0.9 2.7L7.2 9L0.9 15.3C0.402944 15.7971 0.402944 16.6029 0.9 17.1C1.39706 17.5971 2.20294 17.5971 2.7 17.1L9 10.8L15.3 17.1C15.7971 17.5971 16.6029 17.5971 17.1 17.1C17.5971 16.6029 17.5971 15.7971 17.1 15.3L10.8 9L17.1 2.7Z"
              fill="currentColor" />
          </svg>
        </div>

        <div class="px-4 pt-4">
          <GrowLoader v-if="fetchingOrders" />
          <div style="max-height: 80vh; overflow-y: auto; overflow-x: hidden;" v-else>
            <b-form @submit.prevent="search" class="w-100 mb-3">
              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex align-items-center search-wrapper w-100">
                    <b-input ref="inp" v-model="text" class="custom-input no-border-inp bg-transparent w-100"
                      placeholder="Search.."></b-input>
                    <img src="@/assets/images/chear.svg" alt="" style="height: 20px; width: 20px"
                      class="ml-2 mr-2 cursor-pointer" v-if="text && text.trim().length > 0" @click="resetSearch">
                    <img src="@/assets/images/magnifying-glass-tilted.png" alt="" style="height: 20px; width: 20px"
                      class="mx-2 cursor-pointer" @click="search">
                  </div>
                </div>

                <div class="col-md-3 pr-4 d-flex flex-column h-100">
                  <b-form-select v-model="selectedSortOption"
                    style="border-radius: 12px; font-size: 12px; font-weight: 500; color: #707070; height:43px; background-color: #fafafa;"
                    class="w-100">
                    <option disabled :value="null" selected>Sort..</option>
                    <option value="alphabetical">Alphabetically</option>
                    <option value="recent">Recently Ordered</option>
                  </b-form-select>
                </div>
              </div>
            </b-form>

            <div class="" v-for="order in filteredOrders" :key="order.id">
              <product-card-horizontal :inventoryBtns="true" :inventory="true" :prod="order" :removeBtnInventory="true"
                :orderId="order.id" @removed="onProductRemoved" @product-image-update="onProductImageUpdate"  @update-nickname="updateNickName"/>
            </div>
          </div>

        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import IconSolidIncrease from '@/components/svg/icons/solid/Increase.vue'
import IconSolidDecrease from '@/components/svg/icons/solid/Decrease.vue'
import IconSolidBalance from '@/components/svg/icons/solid/Balance.vue'
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import Modal from '@/components/modals/Modal'
import ProductCardHorizontal from '@/components/shopping/ProductCardHorizontal'
import GrowLoader from '@/components/loaders/GrowLoader.vue'
import CastTable from '@/components/future-cast/CastTable.vue'

export default {
  components: { IconSolidIncrease, IconSolidDecrease, IconSolidBalance, Modal, ProductCardHorizontal, GrowLoader, CastTable },
  props: {
    category: Object,
  },
  mixins: [OfficeMixin,],
  data: () => ({
    searchQ: null,
    fetchingOrders: false,
    orders: [],
    productsModal: false,
    updatingCard: false,
    togglingFav: false,
    detailModal: false,
    text: null,
    selectedSortOption: "recent",
  }),
  watch: {
    productsModal(v) {
      if (v) {
        this.fetchOrders()
      }
      else {
        this.selectedSortOption = null
      }
    },
    productsModal(newValue) {
      this.toggleBodyClass(newValue);
    },
  },
  computed: {
    type() {
      if (this.category.count < (this.category.avg_count || 0)) return 'dec'
      if (this.category.count == (this.category.avg_count || 0)) return 'bal'
    },
    avgText() {
      if ((this.category.avg_count || 0) == 0) return `Same as your average`
      if ((this.category.avg_count || 0) < this.category.count) return `${this.category.count - (this.category.avg_count || 0)} more than your average`
      if ((this.category.avg_count || 0) > this.category.count) return `${(this.category.avg_count || 0) - this.category.count} less than your average`
      return `Same as your average`
    },
    filteredOrders() {
      let s = this.text
      if (s != null) {
        s = s.trim().toLowerCase()
        return this.orders.filter(o =>
          o.product.name.toLowerCase().includes(s) ||
          (o.product.nickname && o.product.nickname.toLowerCase().includes(s)) ||
          (o.product.sku && o.product.sku.includes(s)) ||
          (o.product.product_id && o.product.product_id.includes(s)) ||
          (o.product.vendor && o.product.vendor.name.toLowerCase().includes(s))
        )
      }
      if (this.selectedSortOption) {
        if (this.selectedSortOption === 'alphabetical') {
          this.orders.sort((a, b) => a.product.name.localeCompare(b.product.name));
        } else if (this.selectedSortOption === 'recent') {
          this.orders.sort((a, b) => new Date(b.last_order_date) - new Date(a.last_order_date));
        }
      }
      return this.orders
    },
  },
  methods: {
    ...mapActions('PRODUCT', ['FETCH_ORDERS_BY_CATEGORY', 'GET_PRICE_BY_PRODUCT_IDS']),
    ...mapActions('PROCEDURE', ['FETCH_INV_PRODUCTS_FOR_SUMMARY_CATEGORY', 'UPDATE_SUMMARY_CATEGORY']),
    detailModalHeader() {
      return `modal-${Math.floor(Math.random() * (6 - 1 + 1)) + 1}`
    },
    async search() { },
    resetSearch() {
      this.text = null
    },
    closeModalAndResetSearch() {
    this.productsModal = false; 
    this.resetSearch(); 
  },
    toggleProductsModal(v) {
      if (v != this.productsModal) this.productsModal = v || false
    },
    toggleDetailModal(v) {
      if (v != this.detailModal) this.detailModal = v || false
    },
    updateNickName(id, nickName){
      console.log(nickName)
      for(let order of this.orders){
        if(order.id == id){
          order.nickname = nickName;
          order.product.nickname = nickName;
          break;
        }
      }
      this.$forceUpdate();
    },
    async fetchOrders(fetching = true, page = undefined, filters = {}) {
      this.fetchingOrders = fetching
      try {
        let payload = {
          office: this.officeId,
          filters: { summary_category: this.category.slug },
        }
        let res = await this.FETCH_INV_PRODUCTS_FOR_SUMMARY_CATEGORY(payload)
        res.data = res.data.map(r => {
          let pr = r.product
          if (r.image_url) {
            pr.images = [{
              image: r.image_url
            }]
          }
          pr.nickname = r.nickname
          return {
            ...r,
            ...pr,
            id: r.id,
          }
        })

        this.orders = res.data
        console.log(this.orders)

        let null_price_ids = []
        res.data.forEach(pr => {
          if (pr.price == null) pr.price = pr.product_price
          // if(pr.price == null && pr.vendor != null) {
          //   pr.fetching_price = true
          //   null_price_ids.push(pr.id)
          // }
          if (pr.product.children) {
            pr.product.children.forEach(p => {
              if (p.price == null) p.price = p.product_price
              if (p.price == null && p.vendor != null) {
                p.fetching_price = true
                null_price_ids.push(p.id)
              }
            })
          }
        })

        if (null_price_ids.length > 0) {
          this.GET_PRICE_BY_PRODUCT_IDS({
            office: this.officeId,
            products: null_price_ids
          }).then(pRes => {
            let ids = Object.keys(pRes).map(i => parseInt(i))
            for (let i = 0; i < this.orders.length; i++) {
              let pr = this.orders[i].product
              this.orders[i].product.fetching_price = false
              if (ids.includes(pr.id)) {
                this.orders[i].product.price = pRes[pr.id].price
                if (pRes[pr.id].product_vendor_status) this.orders[i].product_vendor_status = pRes[pr.id].product_vendor_status
              }
              if (pr.children) {
                for (let j = 0; j < pr.children.length; j++) {
                  this.orders[i].product.children[j].fetching_price = false
                  let p = pr.children[j]
                  if (ids.includes(p.id)) {
                    this.orders[i].product.children[j].price = pRes[p.id].price
                    if (pRes[p.id].product_vendor_status) this.orders[i].product.children[j].product_vendor_status = pRes[p.id].product_vendor_status
                  }
                }
              }
            }
            this.$forceUpdate()
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.fetchingOrders = false
    },
    onProductRemoved() {
      this.fetchOrders()
    },
    onProductImageUpdate(prod) {
      if (prod) {
        let index = this.orders.findIndex(o => o.id === prod.id)
        if (index > -1) {
          this.orders[index].images = [{ image: prod.image_url }]
        }
        this.$forceUpdate()
      }
    },
    async toggleFavourite() {
      this.togglingFav = true
      try {
        let payload = {
          office: this.officeId,
          id: this.category.id,
          is_favorite: !this.category.is_favorite,
        }
        await this.UPDATE_SUMMARY_CATEGORY(payload)
        this.$emit('favorite-updated')
      } catch (e) { console.log(e) }
      this.togglingFav = false
    },
      // Method to toggle a class on the body element
      toggleBodyClass(isModalOpen) {
      const className = 'hideCartPopupWhenReturnPopupOpen'; 
      if (isModalOpen) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    },
    toggleProductsModal(show) {
      // Ensure the body class is toggled when modal visibility changes
      this.productsModal = show;
      this.toggleBodyClass(show);
    },
  },
  mounted() {
  this.fetchOrders();
},
created() {
    // Initially set the class based on the modal's visibility
    this.toggleBodyClass(this.productsModal);
  },
  beforeDestroy() {
    document.body.classList.remove('hideCartPopupWhenReturnPopupOpen');
  }
}
</script>

<style lang="sass" scoped>

$c-m: 188

.text-c1
  color: #284458
.text-green
  color: #5EBC3B
.menu-drp
  position: absolute
  right: 0
  top: 0
.avg-text
  // background-color: rgba(94, 188, 59, 0.1)
  // min-width: $c-m + px
  // border-radius: 10px
  color: #5EBC3B
  &.dec
    // background-color: rgba(234, 60, 190, 0.1)
    color: #EA3CBE
  &.bal
    // background-color: rgba(218, 218, 218, 0.2)
    color: #284458
.btn1
  // max-width: $c-m + px
  display: block
.fav-icon
  position: absolute
  top: 0
  left: 1rem
  color: #DADADA
  &.fav
    color: #FFB800

.close-icon-btn
  position: absolute
  right: 1.5rem
  top: 1rem

.cat-title
  max-width: 240px
  margin: 0 auto
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
.category-card
  min-height: 200px
  background-color: #F9FAFA
  border-radius: 12px
  @media screen and (min-width: 992px)
    min-width: 300px

.cat-image
  width: 50px
  height: 50px
  border-radius: 4px
  margin: 1rem auto
  // background-color: #EBEBEB
.search-wrapper
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 4px
  .custom-input
    height: 32px !important
.custom-select:focus 
  border-color: #ebeaeb
  outline: 0
  box-shadow: none

</style> 