<template>
  <div class="page-outer">
    <div class="page-contents mx-auto">
      <div class="vendors">
        <RouterLink class="!text-none" v-for="vendor in filteredVendors" :key="vendor.title" :to="vendor.to">
          <VenCard :vendor="vendor" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import VenCard from './VenCard.vue'
import available_vendors from '@/assets/json/available_vendors.json'
export default {
  components: { VenCard, },
  data: () => ({
    vendors: [
      // { comingSoon: false, image: require('../vendor-images/amazon.png'), title: 'Amazon', to: '/vendor-amazon' },
      { comingSoon: false, slug: 'benco', image: require('../vendor-images/benco.png'), title: 'Benco', to: '/vendor-benco' },
      { comingSoon: false, slug: 'berman_dental', image: require('../vendor-images/berman_dental.png'), title: 'Bergman Dental', to: '/vendor-bergman-dental' },
      { comingSoon: false, slug: 'bio_horizons', image: require('../vendor-images/bio_horizons.png'), title: 'BioHorizons', to: '/vendor-bio-horizons' },
      { comingSoon: false, slug: 'bluesky_bio', image: require('../vendor-images/blue_sky_bio.png'), title: 'Blue Sky Bio', to: '/vendor-blue-sky-bio' },
      { comingSoon: false, slug: 'crazy_dental', image: require('../vendor-images/crazy_dental.png'), title: 'Crazy Dental', to: '/vendor-crazy-dental' },
      { comingSoon: false, slug: 'darby', image: require('../vendor-images/Darby.svg'), title: 'Darby', to: '/vendor-darby' },
      { comingSoon: false, slug: 'dcdental', image: require('../vendor-images/dc_dental.png'), title: 'Dc Dental', to: '/vendor-dc-dental' },
      { comingSoon: false, slug: 'dental_city', image: require('../vendor-images/dental_city.png'), title: 'Dental City', to: '/vendor-dental-city' },
      { comingSoon: false, slug: 'dentsply_sirona', image: require('../vendor-images/dentsply_sirona.png'), title: 'Dentsply Sirona', to: '/vendor-dentsply-sirona' },
      { comingSoon: false, slug: 'ebay', image: require('../vendor-images/ebay.png'), title: 'Ebay', to: '/vendor-ebay' },
      { comingSoon: false, slug: 'edge_endo', image: require('../vendor-images/edge_endo.png'), title: 'Edge Endo', to: '/vendor-edge-endo' },
      { comingSoon: false, slug: 'henry_schein', image: require('../vendor-images/henry_schien.png'), title: 'Henry Schien', to: '/vendor-henry-schien' },
      { comingSoon: false, slug: 'implant_direct', image: require('../vendor-images/implant_direct.png'), title: 'Implant Direct', to: '/vendor-implant-direct' },
      { comingSoon: false, slug: 'midwest_dental', image: require('../vendor-images/midwest_dental.png'), title: 'Midwest Dental', to: '/vendor-midwest-dental' },
      { comingSoon: false, slug: 'net_32', image: require('../vendor-images/net32.png'), title: 'Net32', to: '/vendor-net32' },
      { comingSoon: false, slug: 'office_depot', image: require('../vendor-images/office_depot.png'), title: 'Office Depot', to: '/vendor-office-depot' },
      { comingSoon: false, slug: 'ortho_arch', image: require('../vendor-images/ortho_arch.png'), title: 'Ortho Arch', to: '/vendor-ortho-arch' },
      { comingSoon: false, slug: 'patterson', image: require('../vendor-images/patterson.png'), title: 'Patterson', to: '/vendor-patterson' },
      { comingSoon: false, slug: 'practicon', image: require('../vendor-images/practicon.png'), title: 'Practicon', to: '/vendor-practicon' },
      { comingSoon: false, slug: 'purelife', image: require('../vendor-images/purelife.png'), title: 'Purelife', to: '/vendor-purelife' },
      { comingSoon: false, slug: 'safco', image: require('../vendor-images/safco.png'), title: 'Safco', to: '/vendor-safco' },
      { comingSoon: false, slug: 'salvin', image: require('../vendor-images/salvin.png'), title: 'Salvin', to: '/vendor-salvin' },
      { comingSoon: false, slug: 'sky_dental', image: require('../vendor-images/sky_dental.png'), title: 'Sky Dental', to: '/vendor-sky-dental' },
      { comingSoon: false, slug: 'staples', image: require('../vendor-images/staples.png'), title: 'Staples', to: '/vendor-staples' },
      { comingSoon: false, slug: 'top_glove', image: require('../vendor-images/top_quality_gloves.png'), title: 'Top Quality Gloves', to: '/vendor-top-quality-gloves' },
      { comingSoon: false, slug: 'ultradent', image: require('../vendor-images/ultradent.png'), title: 'Ultradent', to: '/vendor-ultradent' },
    ],
  }),
  computed: {
    filteredVendors(){
      const vens = this.vendors.map(vendor => {
        return {
          ...vendor,
          comingSoon: !available_vendors.includes(vendor.slug)
        }
      }).sort((a, b) => a.title < b.title ? -1 : 0)
      return vens.filter(vendor => !vendor.comingSoon).concat(vens.filter(vendor => vendor.comingSoon))
    },
  },
}
</script>

<style lang="sass" scoped>
.page-outer
  padding: 5rem 1rem
.page-contents
  max-width: 900px
  .title-1
    font-family: GILROY-BOLD
    font-size: 24px
    color: #274458
  .subtitle-1
    font-family: GILROY-MEDIUM
    font-size: 16px
    color: #274458
  .cd-1
    font-family: GILROY-BOLD
    font-size: 16px
    color: #274458
    display: flex
    justify-content: center
    align-items: center
    background: #FFFFFF
    border-radius: 6px
    padding: 0.5rem 1rem
  .vendors
    display: grid
    grid-template-columns: repeat(3, minmax(0, 1fr))
    row-gap: 4rem
    column-gap: 1rem
    @media screen and (max-width: 992px)
      grid-template-columns: repeat(2, minmax(0, 1fr))
    @media screen and (max-width: 768px)
      grid-template-columns: repeat(1, minmax(0, 1fr))
</style>