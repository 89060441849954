<template>
  <tr>
    <td>
      <div class="order-info d-flex">
        <div>
          <!-- <b-tooltip :target="`tooltip-target-${order.id}`" triggers="hover focus" placement="top" boundary="window" custom-class="promotion-tooltip" v-if="order.product.promotion_description">
            <p class="d-desc mb-0">{{order.product.promotion_description}}</p>
          </b-tooltip> -->
          <div class="img-part" :id="`tooltip-target-${order.id}`">
            <product-image :product="order.product" :no-image-short="true" size="sm"
              :inventoryProduct="order.item_inventory" invText="My Inventory" />
          </div>
          <b-dropdown right class="menu-drp not-blur w-100" menu-class="bud-cat-sel"
            v-if="role === 1 && !orderApproval && !$store.state.OFFICE.fetchingBudget" offset="44">
            <template #button-content>
              <b-btn
                class="custom-btn danger__2__outline d-flex align-items-center justify-content-center p-0 f-8-px w-100"
                style="min-height: auto; padding: 3px 0 !important;">
                <span :class="['text-danger__2 px-1']"
                  style="max-width: 74px; display: inline-block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{
                    selectedBudgetType }}</span>
              </b-btn>
            </template>
            <div class="cm-body">
              <div v-for="(budCat, rI) in budgetCategories" :key="rI">
                <div class="dash" v-if="rI > 0"></div>
                <div class="my-3 cursor-pointer d-flex align-items-center" @click="onChangeBudgetType(budCat)">
                  <div class="ven-ck mr-2 px-2-px">
                    <img src="@/assets/images/pink_check_mark.png" alt=""
                      style="vertical-align: unset; max-width: 100%; max-height: 100%"
                      v-if="(budgetType == budCat.value)">
                  </div>
                  <p class="d-desc text-danger__2 mb-0">{{ budCat.text }}</p>
                </div>
              </div>
            </div>
          </b-dropdown>
        </div>
        <div class="ml-3 c-con">
          <p class="mb-0 d-desc__2 gilroy-b text-line-1 f-14-px" style="line-height: normal; color: #1c455a"
            :title="order.product.name">{{ order.product.name }}</p>
          <p class="mb-0">
            <span class="d-desc__2 f-14-px gilroy-m" style="color: #788288;">{{ order.product.product_id }}</span>
            <span class="d-title__10 f-12-px text-primary ml-3 cursor-pointer" @click="$forceUpdate(); cngVenModal = true"
              v-if="order.sibling_products.length > 0 && !orderApproval">
              <b-spinner style="width:0.8rem;height:0.8rem" variant="primary" class="mr-2" v-if="false" />
              Change Vendor
            </span>
            <span class="d-title__10 f-12-px text-primary ml-3 cursor-pointer" @click="saveForLater"
              v-if="!orderApproval">
              <b-spinner style="width:0.8rem;height:0.8rem" variant="primary" class="mr-2" v-if="submitting" />
              {{ order.save_for_later ? 'Move to Cart' : 'Save for Later' }}
            </span>
            <span class="d-title__10 f-12-px rmv-txt ml-3 cursor-pointer" @click="removeItem" v-if="!orderApproval">
              <b-spinner style="width:0.8rem;height:0.8rem" class="mr-2 text-danger__2"
                v-if="deleting || removingVendorItem" />
              <img src="@/assets/images/trash.png" alt="" style="width: 15px;height:15px" v-else>
            </span>
          </p>
        </div>
      </div>
      <modal v-if="cngVenModal" :modal="cngVenModal" @change="toggleCngVenModal" size="lg" dialog-class="cng-ven-dg"
        c-classes="px-4 py-4" :noCloseOnBackdrop="true" ref="comparisonRef">
        <div>
          <div class="d-flex justify-content-center mb-5">
            <logo class="logo" />
          </div>
          <div class="position-relative">
            <div class="d-flex s-1 justify-content-between">
              <div class="c-1">
                <p class="d-title__2 mb-0">Change vendor for</p>
                <p class="d-title__2 text-danger__2 mb-2 text-line-1">{{ order.product.name }}</p>
                <p class="d-desc mb-3">Select the vendor you prefer and it will be updated in your cart.</p>
              </div>
            </div>
            <img src="@/assets/images/change_vendor_character.png" alt="" class="s-1-img">
          </div>
          <div class="dash"></div>
          <div :class="['py-4', comparisons.length > 3 ? 'owl-carousel pl-3 mr-3' : 'd-flex align-items-end']">
            <div v-for="(comparison, cI) in comparisons" :key="comparison.id">
              <!-- <b-tooltip :target="`tooltip-target-${comparison.id}`" triggers="hover focus" placement="top" boundary="window" custom-class="promotion-tooltip" v-if="comparison.is_special_offer && comparison.special_price < (comparison.unit_price || comparison.product_price || comparison.price)">
                <p class="d-desc mb-0" v-if="comparison.promotion_description">{{comparison.promotion_description}}</p>
                <p class="d-desc mb-0" v-else>Promotion : Reduced price to ${{comparison.special_price || comparison.unit_price}} original price ${{comparison.unit_price || comparison.price}}</p>
              </b-tooltip> -->
              <!-- <div @click="changeVendor(comparison)" :class="['supplier-card__2 cursor-pointer', { 'selected-green-border' : selectedVendorId == comparison.id } , { 'promoted': comparison.is_special_offer && comparison.special_price < (comparison.unit_price || comparison.product_price || comparison.price) } ,{ 'ml-3': cI > 0 && comparisons.length < 4 }]" :id="`tooltip-target-${comparison.id}`"> -->
              <div @click="changeVendor(comparison)"
                :class="['supplier-card__2 cursor-pointer', { 'selected-green-border': selectedVendorId == comparison.id }, { 'ml-3': cI > 0 && comparisons.length < 4 }]"
                :id="`tooltip-target-${comparison.id}`">
                <!-- <div class="promotion-gr" v-if="comparison.is_special_offer && comparison.special_price < (comparison.unit_price || comparison.product_price || comparison.price)">
                  <p>On Promotion</p>
                </div> -->
                <div
                  :class="['d-flex flex-column align-items-center justify-content-center', comparison.is_special_offer && comparison.special_price < (comparison.unit_price || comparison.product_price || comparison.price) ? 'pt-31-px' : 'cp']">
                  <skeleton-loader type="rect" :width="'60%'" :height="'16px'" :radius="16" :rounded="true"
                    animation="wave" v-if="comparison.fetching_price" />
                  <p class="mb-0 supp-price text-line-1 mx-2" :title="comparison.product_vendor_status"
                    v-else-if="comparison.unit_price != null && parseFloat(comparison.unit_price) == 0 && comparison.product_vendor_status">
                    {{ comparison.product_vendor_status }}</p>
                  <p class="mb-0 supp-price" v-else-if="comparison.special_price != null">
                    ${{ parseFloat(comparison.special_price || 0).toFixed(2) }}</p>
                  <p class="mb-0 supp-price" v-else>${{ parseFloat(comparison.unit_price || comparison.price ||
                    0).toFixed(2) }}</p>
                  <div class="supp-info d-flex align-items-center my-1">
                    <p class="mb-0 supp-name">{{ comparison.vendor.name }}</p>
                    <div class="avatar__4 square ml-1">
                      <img :src="comparison.vendor.logo" alt="" class="w-100 h-100" v-if="comparison.vendor.logo">
                      <span v-else style="color: #000">{{ comparison.vendor.name[0] }}</span>
                    </div>
                  </div>
                  <div class="supp-info mb-2">
                    <p :title="comparison.product_id" class="mb-0 supp-name f-8-px text-line-1 mx-2"
                      style="line-height: 1;">Product # : {{ comparison.product_id }}</p>
                  </div>
                  <b-btn
                    :class="['custom-btn supp-add-card-btn', selectedVendorId == comparison.id ? 'success-light' : 'primary-light']">
                    <b-spinner style="width: 0.4rem;height:0.4rem;font-size:5px;" variant="primary" class="mr-2"
                      v-if="changingVendor && changingVendorCID == comparison.id" />
                    {{ selectedVendorId == comparison.id ? 'Selected' : 'Select' }}
                  </b-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="dash mb-4"></div>
          <div class="d-flex justify-content-end">
            <b-btn class="custom-btn danger__2" @click="backToCart">Back to Cart</b-btn>
          </div>
        </div>
      </modal>
    </td>
    <td class="d-desc__2 text-center" style="color: #29bf12;">
      <p class="mb-0" v-if="(order.promotion && order.promotion.type == 1)">${{ Number(order.unit_price -
        order.promotion.reduction_price).toFixed(2) }}</p>
      <p class="mb-0" v-else-if="(order.promotion && order.promotion.type == 2)">{{ order.promotion.description }}</p>
      <div :class="['d-inline-block position-relative', order.promotion && order.promotion.type == 1 && 'cut-off']">
        <span class="cut-off-span"></span>
        <b-tooltip :target="`tooltip-price-change-cart-tr-${order.id}`" triggers="hover focus" placement="top"
          boundary="document" custom-class="promotion-tooltip"
          v-if="!order.save_for_later && order.updated_unit_price && order.updated_unit_price != order.unit_price">
          <p class="d-desc mb-0">Price changed from ${{ Number(order.unit_price || 0) .toFixed(2)}} to ${{ order.updated_unit_price .toFixed(2)}}</p>
        </b-tooltip>
        <p class="t-text mb-0 pl-2-px" :id="`tooltip-price-change-cart-tr-${order.id}`">
          <span class="min-w-40-px">${{ Number(order.updated_unit_price || order.unit_price).toFixed(2) }}</span>
          <img src="@/assets/images/caution.png" alt="" class="w-14-px h-14-px mb-1 ml-2"
            v-if="!order.save_for_later && order.updated_unit_price && order.updated_unit_price != order.unit_price" />
        </p>
      </div>
    </td>
    <!-- <td class="d-desc__2 text-center" style="color: #29bf12;">
{{ shippingCost }}
    </td> -->

    <td>
      <div class="plus-minus mx-auto">
        <div class="minus" @click="dec">-</div>
        <div class="num">
          <b-spinner style="width:0.8rem;height:0.8rem" variant="primary" v-if="updatingQuantity" />
          <span v-else>{{ order.quantity }}</span>
        </div>
        <div class="plus" @click="inc">+</div>
      </div>
    </td>
    <td v-if="insCheckout">
      <div class="d-flex justify-content-center" v-if="!isVendorConnected(order.product)">
        <b-btn class="custom-btn danger__2 supp-add-card-btn mx-auto"
          style="min-height: 30px; font-size: 10px; min-width: 113px;"
          @click="relinkVendor(order.product.vendor)">Relink</b-btn>
      </div>
      <ApproveOrReject :item="order" v-else-if="orderApproval" @change="val => $emit('change-rejected-reason', val)" />
      <div class="d-flex align-items-center h-100 justify-content-center" v-else>
        <b-spinner style="width:0.8rem;height:0.8rem" variant="primary" class="mr-2" v-if="insSubmitting" />
        <b-form-checkbox switch class="custom-chk" :disabled="insSubmitting" :checked="order.instant_checkout"
          @change="onChangeInsChk"></b-form-checkbox>
      </div>
      <modal :modal="conModal" @change="toggleConModal" size="md" :noCloseOnBackdrop="true">
        <p class="modal-header-title text-center">Relink Vendor</p>
        <div class="d-flex justify-content-center align-items-center flex-column">
          <p class="modal-header-desc text-center mb-2">Oops! It looks like your vendor has become unlinked. Sometimes
            this happens when your vendor login credentials are incorrect or if they require a new or updated password.
          </p>
          <p class="modal-header-desc text-center mb-2">Action Required: Please visit the vendor website directly and
            check to see if your vendor specific username and password are correct. If they are correct, enter in those
            credentials below to relink the vendor. If they are incorrect, please update your username and/or password and
            then enter those credentials below to relink the vendor.</p>
          <p class="modal-header-desc text-center mb-4">If you are still having any issues, please reach out to us
            directly using the chat button on the lower right of your screen.</p>
        </div>
        <div class="dash mb-4"></div>
        <div>
          <conn-acc-form :vendor="selectedVendor" :office="{ id: officeId }" @close="onClose" :settingsPage="true"
            :relink="true" :office-vendor-id="selectedOfficeVendorId" />
        </div>
      </modal>
    </td>
  </tr>
</template>

<script>
import PlusMinusMixin from '@/mixins/PlusMinus'
import OfficeMixin from '@/mixins/OfficeMixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductImage from '../ProductImage.vue'
import BUS from '@/bus/BUS'
import Modal from '@/components/modals/Modal'
import Logo from '@/components/logo/Logo'
import SkeletonLoader from 'skeleton-loader-vue'
import ApproveOrReject from './ApproveOrReject.vue'
import ConnAccForm from '@/components/onboarding/ConnAccForm'
export default {
  components: { ProductImage, Modal, Logo, SkeletonLoader, ApproveOrReject, ConnAccForm, },
  props: {
    order: Object,
    insCheckout: { type: Boolean, default: true },
    orderApproval: Boolean,
    orderId: Number,
    shippingCost: Number,
  },
  mixins: [PlusMinusMixin, OfficeMixin],
  data: () => ({
    submitting: false,
    insSubmitting: false,
    cngVenModal: false,
    changingVendor: false,
    selectedVendorId: null,
    changingVendorCID: null,
    budgetType: 'dental',
    removingVendorItem: false,
    conModal: false,
    selectedOfficeVendorId: null,
    selectedVendor: null,
  }),
  created() {
    this.quantity = this.order.quantity
    this.selectedVendorId = this.order.product.id
    this.budgetType = this.order.budget_spend_type
  },
  mounted() {
    this.setupOwlCar()
  },
  watch: {
    order(v) {
      this.budgetType = this.order.budget_spend_type
      this.selectedVendorId = this.order.product.id
    },
    cngVenModal(v) {
      if (v) {
        this.setupOwlCar()
      }
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    ...mapState('CART', ['cartProducts']),
    ...mapGetters('OFFICE', ['connected_vendors']),
    budgetCategories() {
      if (this.$store.state.OFFICE.officeBudget[this.officeId]) {
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if (subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if (subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if (subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        })
      }
      return []
    },
    comparisons() {
      let arr = this.order.sibling_products
      let nulls = arr.filter(
        a => parseFloat(a.special_price || a.unit_price || a.price || 0) == 0
      )
      let notNull = arr.filter(
        a => parseFloat(a.special_price || a.unit_price || a.price || 0) != 0
      )
      return [{ ...this.order.product, unit_price: this.order.unit_price }].concat(notNull.sort((a, b) => (
        (a.special_price || a.unit_price || a.price || 0) - (b.special_price || b.unit_price || b.price || 0)
      )).concat(
        nulls.sort((a, b) => a.unit_price != null && parseFloat(a.unit_price) == 0 && a.product_vendor_status ? 1 : -1)
      ))
    },
    hasPromotion() {
      return this.order.product.promotion_description != null && this.order.product.promotion_description.length > 0
    },
    selectedBudgetType() {
      let type = this.budgetCategories.find(c => c.value == this.budgetType)
      if (type) return type.text
      return 'Budget Type'
    },
  },
  methods: {
    ...mapActions('CART', ['SAVE_FOR_LATER', 'INS_CHECKOUT', 'CHANGE_VENDOR', 'UPDATE_CART']),
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET', 'FETCH_CONNECTED_VENDORS']),
    ...mapActions('ORDER', ['REMOVE_ITEM_FROM_VENDOR_ORDER', 'UPDATE_QUANTITY_OF_ITEM_IN_VENDOR_ORDER']),
    async onChangeBudgetType(budCat) {
      this.budgetType = budCat.value
      try {
        let payload = {
          budget_spend_type: this.budgetType,
          cart: this.order.id,
          office: this.officeId
        }
        await this.UPDATE_CART(payload)
        let index = this.cartProducts.findIndex(i => i.id === this.order.id)
        if (index > -1) {
          this.cartProducts[index].budget_spend_type = this.budgetType
        }
        // await this.CURRENT_MONTH_BUDGET({office: this.officeId})
      } catch (e) { console.log(e) }
    },
    setupOwlCar() {
      const leftAr = require('@/assets/images/left_pol_arr.png')
      const rightAr = require('@/assets/images/right_pol_arr.png')
      $(document).ready(function () {
        $(".owl-carousel").owlCarousel({
          nav: true,
          items: 4,
          autoWidth: true,
          margin: 16,
          navText: [
            `<img class="owl-arrow" src="${leftAr}" alt="">`,
            `<img class="owl-arrow" src="${rightAr}" alt="">`
          ]
        });
      });
    },
    backToCart() {
      if (this.selectedVendorId && this.selectedVendorId != this.order.product.id) {
        // this.$BUS.$emit('fetch-cart')
        this.$emit('change-vendor')
      }
      this.toggleCngVenModal(false)
    },
    async changeVendor(comp) {
      this.changingVendor = true
      this.changingVendorCID = comp.id
      try {
        let payload = {
          office: this.officeId,
          cart_id: this.order.id,
          product: comp.id,
          quantity: this.order.quantity,
          unit_price: comp.special_price || comp.unit_price
        }
        await this.CHANGE_VENDOR(payload)
        this.selectedVendorId = comp.id
      } catch (e) { console.log(e) }
      this.changingVendorCID = null
      this.changingVendor = false
    },
    toggleCngVenModal(v) {
      if (v != this.cngVenModal) this.cngVenModal = v != null ? v : false
    },
    async onChangeInsChk(v) {
      this.insSubmitting = true
      try {
        let payload = {
          office: this.officeId,
          cart: this.order.id,
          instant_checkout: v,
        }
        let res = await this.INS_CHECKOUT(payload)
        // this.$BUS.$emit('ins-chk-upd', res)
        // this.$BUS.$emit('fetch-cart')
        let index = this.$store.state.CART.cartProducts.findIndex(c => c.id == this.order.id)
        if (index > -1) this.$store.state.CART.cartProducts[index].instant_checkout = res.instant_checkout
        let indexT = this.$store.state.CART.cartProductsByTime.findIndex(c => c.id == this.order.id)
        if (indexT > -1) this.$store.state.CART.cartProductsByTime[index].instant_checkout = res.instant_checkout
      } catch (e) {
        console.log(e)
      }
      this.insSubmitting = false
    },
    async removeItem() {
      if (this.orderApproval) {
        this.removingVendorItem = true
        let payload = {
          vendor_order_product_id: this.order.id,
          office: this.officeId,
        }
        await this.REMOVE_ITEM_FROM_VENDOR_ORDER(payload)
        this.$emit('removed-vendor-item', this.order.id)
        this.removingVendorItem = false
      }
      else {
        await this.deleteCart({
          vendor: this.order.product.vendor.id,
          office: this.officeId,
          cart: this.order.id
        })
      }
    },
    async saveForLater() {
      if (this.submitting) return
      this.submitting = true
      try {
        let payload = {
          office: this.officeId,
          cart_id: this.order.id,
          save_for_later: !this.order.save_for_later
        }
        await this.SAVE_FOR_LATER(payload)
        this.$emit('saved')
      } catch (e) {
        console.log(e)
      }
      this.submitting = false
    },
    inc() {
      if (this.orderApproval) {
        this.quantity += 1
        return this.updateOrderItem()
      }
      this.increaseQuantity(true)
    },
    dec() {
      if (this.orderApproval) {
        if (this.quantity > 1) this.quantity -= 1
        return this.updateOrderItem()
      }
      this.decreaseQuantity(true)
    },
    async updateOrderItem() {
      if (this.updatingQuantity) return
      this.updatingQuantity = true
      const payload = {
        vendor_order_product_id: this.order.id,
        office: this.officeId,
        quantity: this.quantity,
      }
      await this.UPDATE_QUANTITY_OF_ITEM_IN_VENDOR_ORDER(payload)
      this.$emit('quantity-update', {
        vendor_order_product_id: this.order.id,
        quantity: this.quantity,
      })
      this.updatingQuantity = false
    },
    isVendorConnected(product) {
      const con_ven = this.connected_vendors.find(cv => cv.vendor.slug == product.vendor.slug)
      if (con_ven) return con_ven.login_success
      return false
    },
    toggleConModal(v) {
      if (v != this.conModal) this.conModal = v || false
    },
    async fetchLinkedVendors() {
      try {
        await this.FETCH_CONNECTED_VENDORS({ office: this.officeId })
      } catch (e) { }
    },
    onClose() {
      this.toggleConModal(false)
      this.fetchLinkedVendors()
    },
    relinkVendor(vendor) {
      this.selectedVendor = vendor
      let connected_vendor = this.connected_vendors.find(v => v.vendor.id === vendor.id)
      if (connected_vendor) {
        this.selectedOfficeVendorId = connected_vendor.id
      }
      this.conModal = true
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'
@import '../../style/settings'
::v-deep .owl-carousel
  .owl-stage
    display: flex
    align-items: flex-end
  .owl-nav
    position: absolute
    width: calc( 100% + 25px )
    display: flex
    justify-content: space-between
    right: unset
    top: calc( 50% - 10px )
    right: -20px
    z-index: -1
    .owl-arrow
      width: 14px
      height: 18px
    .owl-prev , .owl-next
      &.disabled
        .owl-arrow
          opacity: 50%
  .owl-dots
    display: none
.cng-ven-dg
  .logo
    width: 115px
    height: 32px
  .s-1
    .c-1
      max-width: 380px
  .s-1-img
    position: absolute
    right: -40px
    bottom: 0
    width: 203px
    height: 148px
    @media screen and (max-width: 800px)
      display: none
  .supplier-card-holder
    overflow-x: auto
.rmv-txt
  color: #{$red2}
tr
  td
    &:nth-child(1)
      width: 480px
      .c-con
        max-width: 370px
    &:nth-child(2)
      width: 120px
    &:nth-child(3)
      width: 120px
    &:nth-child(4)
      min-width: 120px
    .img-part
      .images-sec
        height: inherit

.ven-ck
  width: 20px
  min-width: 20px
  height: 20px
  border-radius: 4px
  border: solid 1px #ededed
  display: flex
  align-items: center
  justify-content: center
</style>