<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">See Into the Future with Futurecast</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">Sync your Open Dental account with Ordo to take ordering to the next level Change text from Futurecast Capabilities to See the Future</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Automatic Budget Updates </p>
        <p class="subtitle-3 mb-3">Our Open Dental integration provides automatic budget updates at the beginning of each month. This means that you no longer have to spend time manually updating your budget. Instead, our integration does it for you, allowing you to focus on providing excellent care to your patients. </p>
        <p class="text-center mb-3">
          <img src="./features_images/Futurecast/one_future.webp" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">See the Future </p>
        <p class="subtitle-3 mb-3">Futurecast scans your Open Dental schedule into the future, allowing you to know what inventory you'll need in advance. This helps you stay on top of your inventory and ensures that you have the necessary supplies on hand when you need them.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Futurecast/two_future.webp" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/one.svg)
</style>