<template>
  <div class="onboarding-steps w-100">
    <div class="wrapper d-flex mx-auto" :class="[ isInvitation ? 'justify-content-center' : 'justify-content-between' ]">
      <div :class="['step cursor-pointer', { 'active': step.value <= currentStep }]" v-for="(step, sI) in filteredSteps" :key="sI" @click="onStepClick(step.value)">
        <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2329bf12' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e" alt="" class="w-18-px mr-2" v-if="fullCompletedSteps.includes(step.value)">
        <span>{{step.text}}</span>
        <div class="bottom-part"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    completed: Array,
    fullCompletedSteps: { type: Array, default: [] },
    dashboard: Boolean,
  },
  computed: {
    invEmail(){
      return this.$route.query.email
    },
    invRole(){
      return this.$route.query.role
    },
    invCompanyName(){
      return this.$route.query.company
    },
    invToken(){
      return this.$route.query.token
    },
    isInvitation(){
      if(this.invEmail && this.invRole && this.invCompanyName && this.invToken) return true
      return false
    },
    filteredSteps(){
      if(this.isInvitation) return [this.steps[0]]
      return this.steps
    },
    steps(){
      if(this.dashboard) return [
        { text: 'Link Vendors', value: 1, },
        { text: 'Office Budget', value: 2, },
        { text: 'Invite Team', value: 3, },
      ]
      return [
        { text: 'Step 1', value: 1, },
        { text: 'Step 2', value: 2, },
        { text: 'Step 3', value: 3, },
      ]
    },
  },
  methods: {
    onStepClick(val){
      if(this.completed.includes(val)) this.$emit('change', val)
    },
  },
}
</script>

<style lang="sass" scoped>
.onboarding-steps
  overflow-x: auto
  @media screen and (max-width: 860px)
    padding-bottom: 10px
  .wrapper
    width: 500px
    .step
      width: calc( (100% / 4) -  (5px) )
      text-align: center
      span
        font-size: 12px
        font-family: Gilroy-Bold
        font-weight: 500
        font-stretch: normal
        font-style: normal
        line-height: normal
        color: #1c455a
        opacity: 0.3
      .bottom-part
        margin-top: 10px
        width: 100%
        height: 6px
        background-color: #ffffff
        border-radius: 100px
      &.active
        .bottom-part
          background-color: #7cd878
          border-radius: 100px
        span
          opacity: 1
</style>