<template>
  <div class="dash-head-search d-flex align-items-center">
    <b-form @submit.prevent="search" class="w-100">
      <div class="d-flex align-items-center search-wrapper w-100">
        <b-input @focus="onInpFocus" ref="inp" @keyup="onKeyUp" @keyup.enter="search" v-model="text"
          class="custom-input no-border-inp bg-transparent w-100"
          placeholder="Enter Product Name, Vendor Number, SKU, Nickname"></b-input>
        <img src="@/assets/images/chear.svg" alt="" style="height: 20px; width: 20px" class="ml-2 mr-2 cursor-pointer"
          v-if="text && text.trim().length > 0" @click="resetSearch" />
        <div class="select-wrapper amazon d-flex d-lg-none align-items-center px-2 py-2 cursor-pointer"
          @click="showMobileSrot = !showMobileSrot">
          <img src="@/assets/images/svg_icon/down_caret.svg" alt=""
            :style="{ transform: `rotate(${showMobileSrot ? '180deg' : '0deg'})` }">
        </div>
        <dash-head-search-filter class="d-none d-lg-flex" :all-selected="allSelected"
          :can-set-ven-in-search="canSetVenInSearch" :vendors-in-search="vendorsInSearch" :filterByPrice="filterByPrice"
          :maxPrice="filterByPrice.values[1]" :minPrice="filterByPrice.values[0]"
          :search="search" :toggle-selection="toggleSelection" :toggle-vendor-search-filter="toggleVendorSearchFilter"
          @change-price-filter-min="onChangeFilterByPriceMinValue"
          @change-price-filter-max="onChangeFilterByPriceMaxValue" />
        <img src="@/assets/images/magnifying-glass-tilted.png" alt="" style="height: 20px; width: 20px"
          class="mx-2 cursor-pointer" @click="search" />
      </div>
      <dash-head-search-filter v-if="showMobileSrot" class="d-flex d-lg-none mt-2 justify-content-between"
        :all-selected="allSelected" :can-set-ven-in-search="canSetVenInSearch" :vendors-in-search="vendorsInSearch"
        :filterByPrice="filterByPrice" :search="search" :toggle-selection="toggleSelection"
        :toggle-vendor-search-filter="toggleVendorSearchFilter" @change-price-filter-min="onChangeFilterByPriceMinValue"
        @change-price-filter-max="onChangeFilterByPriceMaxValue" />
    </b-form>
    <div class="search-suggestions px-3 py-3" v-if="showSuggDiv" v-click-outside="osutClick">
      <div v-if="fetchingSuggestions">
        <b-spinner small variant="primary" class="mr-2"></b-spinner>
      </div>
      <!-- <div v-for="(product, pI) in suggestions" :key="pI">
        <div class="dash my-3" v-if="pI > 0"></div>
        <div class="d-flex align-items-center cursor-pointer" @click="setSearchT(product)">
          <product-image :no-image-short="true" size="sm" :product="product" />
          <p class="p-name mb-0 ml-3 text-line-1 mr-2">{{ product.name }}</p>
          <b-btn class="custom-btn danger__2__outline my-inv-btn" v-if="product.is_inventory">My Inventory</b-btn>
        </div>
      </div> -->
      <div v-for="(product, pI) in suggestions" :key="pI">
        <div class="dash my-3" v-if="pI > 0"></div>
        <div class="d-flex align-items-center cursor-pointer" @click="setSearchT(product)">
          <p class="p-name mb-0 ml-2 text-line-1 mr-2">{{ product}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductImage from '@/components/ProductImage'
import DashHeadSearchFilter from '@/components/dashboard/DashHeadSearchFilter.vue'
import OfficeMixin from '@/mixins/OfficeMixin'

export default {
  components: { ProductImage, DashHeadSearchFilter },
  mixins: [OfficeMixin,],
  props: {
    replaceWithCurrentRoute: Boolean,
    emitOnly: Boolean,
  },
  data: () => ({
    searchToOptions: ['All Products', 'My Inventory'],
    searchTo: 'All Products',
    text: null,
    fetchingSuggestions: false,
    suggestions: [],
    shSg: false,
    showAmazon: false,
    includeAmazon: true,
    vendors: [],
    callSug: false,
    canSetVenInSearch: true,
    vendorsInSearch: [],
    showMobileSrot: false,
    filterByPrice: {
      values: [0, null],
    },
    lastSearch: null
  }),
  created() {
    this.setText()
    this.fetchVendors()
    // this.$BUS.$on('vendors-in-search', v => {
    //   if (this.canSetVenInSearch) {
    //     for (let i = 0; i < this.vendorsInSearch.length; i++) {
    //       let ven = v.find(vv => vv == this.vendorsInSearch[i].vendor.slug)
    //       if (ven) this.vendorsInSearch[i].checked = true
    //       else this.vendorsInSearch[i].checked = false
    //     }
    //   }
    // })
  },
  beforeDestroy() {
    // this.$BUS.$off('vendors-in-search')
  },
  computed: {
    showSuggDiv() {
      return (
        this.text && this.text.trim().length > 0 && this.suggestions.length > 0
      ) && this.shSg
    },
    // filterByPrice(){
    //   return this.getFilterByPrice()
    // },
    selectedVendorSlugs() {
      return ((this.vendorsInSearch.filter(v => v.checked)).map(v => v.vendor.slug)).join(',')
    },
    allSelected() {
      return (this.vendorsInSearch.find(v => !v.checked)) == null
    },
  },
  watch: {
    $route() {
      this.setText()
    },
  },
  methods: {
    ...mapActions('PRODUCT', ['SEARCH_PRODUCT_SUGGESTION', 'SET_SEARCH_COMPLETED']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS']),
    ...mapGetters('PRODUCT', ['getSearchCompleted']),
    onChangeFilterByPriceMinValue(val) {
      if (this.filterByPrice.values[0] != val) this.filterByPrice.values[0] = val
    },
    onChangeFilterByPriceMaxValue(val) {
      if (this.filterByPrice.values[1] != val) this.filterByPrice.values[1] = val
    },
    setFilterByPrice(index, val) {
      this.filterByPrice.values[index] = val
    },
    clearPriceFilter() {
      this.filterByPrice.values[0] = 0
      this.filterByPrice.values[1] = null
      this.$forceUpdate()
    },
    setText() {
      if (this.$route.query.product_name) this.text = this.$route.query.product_name
      else if (this.$route.query.q) this.text = this.$route.query.q
    },
    resetSearch() {

      this.text = null
      this.vendorsInSearch = this.vendors
      for (let i = 0; i < this.vendorsInSearch.length; i++) {
        this.vendorsInSearch[i].checked = true
      }

      this.$refs.inp.focus()
    },
    resetVendors(call_search = true) {
      this.vendorsInSearch = this.vendors
      for (let i = 0; i < this.vendorsInSearch.length; i++) {
        this.vendorsInSearch[i].checked = true
      }
    },
    toggleSelection() {
      if (this.allSelected) {
        for (let i = 0; i < this.vendorsInSearch.length; i++) {
          this.vendorsInSearch[i].checked = false
        }
      } else {
        for (let i = 0; i < this.vendorsInSearch.length; i++) {
          this.vendorsInSearch[i].checked = true
        }
      }
    },
    toggleVendorSearchFilter(vI) {
      this.vendorsInSearch[vI].checked = !this.vendorsInSearch[vI].checked
    },
    async fetchVendors() {
      try {
        let res = await this.FETCH_CONNECTED_VENDORS({ office: this.officeId })
        let vendors = res.map(v => v.vendor.slug)
        if (this.$route.query.vendors) {
          vendors = this.$route.query.vendors.split(',')
        }
        this.vendorsInSearch = res.map(val => ({ ...val, checked: vendors.includes(val.vendor.slug) })).filter(ven => ven.vendor.slug != 'amazon')
        this.vendors = this.vendorsInSearch
      } catch (e) { }
    },
    setSearchT(product) {
      this.search(null, null, product)
    },
    async search(event, product_id, product_name, q, reset_vendors = true, include_price = true) {
      const startTime = performance.now();
      if (!this.text && !q) return
      this.canSetVenInSearch = reset_vendors
      this.$refs.inp.blur()
      this.onOutClick()
      let query = { ...this.$route.query, q: q || this.text }
      if(this.lastSearch != null)
     { if (this.getSearchCompleted() && this.lastSearch != this.text) {
        delete query.price_to
        delete query.price_from
        this.filterByPrice.values[1] = null
        this.filterByPrice.values[0] = 0
        this.clearPriceFilter();
        this.$forceUpdate()

      }}
      if (include_price) {
        query.price_from = this.filterByPrice.values[0]
        if (this.filterByPrice.values[1] != null && Number(this.filterByPrice.values[1]) > Number(this.filterByPrice.values[0])) {
          query.price_to = this.filterByPrice.values[1]
        } else {
          delete query.price_to
          this.filterByPrice.values[1] = null
          this.$forceUpdate()
        }
      } else {
        delete query.price_from
        delete query.price_to
        this.clearPriceFilter()
      }
      if (product_id && typeof product_id != 'object') query.product_id = product_id
      else delete query.product_id
      if (product_name) query.product_name = product_name
      else delete query.product_name
      if (reset_vendors) this.resetVendors()
      if (event instanceof KeyboardEvent && product_name == undefined) {
        query.q = this.text;
      }
       else if (product_name != undefined){
        query.q = product_name;
      }

      await this.$nextTick();
      const endTimeAsync = performance.now();
      const timeElapsedAsync = endTimeAsync - startTime;
      // console.log(`Async Loop completed in ${timeElapsedAsync} milliseconds.`);
      if (this.selectedVendorSlugs && this.selectedVendorSlugs.trim().length > 0) query.vendors = this.selectedVendorSlugs
      try {
        if (this.emitOnly) {
          this.$emit('head-search', query)
        }
        else if (this.replaceWithCurrentRoute) {
          this.$router.replace({
            name: this.$route.name,
            query,
            params: this.$route.params
          })
        }
        else {
          this.$router.replace({
            name: 'CreateAnOrder',
            query,
            params: this.$route.params
          })
        }
      } catch (routerErr) {
        this.$refs.venDrp.hide()
      }
      this.SET_SEARCH_COMPLETED(false)
      this.lastSearch = this.$route.query.q;
      const endTime = performance.now();
      const timeElapsed = endTime - startTime;
      // console.log(`Loop completed in ${timeElapsed} milliseconds.`);
    },
    onInpFocus() {
      this.shSg = true
    },
    onOutClick() {
      if (this.$refs.inp.$el != document.activeElement) this.shSg = false
      else this.shSg = true
    },
    formatProductObjForImage(p) {
      return Object.assign({}, p, { images: [{ image: p.image }] })
    },
    onKeyUp() {
      if (this.text && this.text.trim().length > 0) {
        this.shSg = true
        this.fetchSuggestion()
      }
    },
    async fetchSuggestion() {
      if (this.fetchingSuggestions) {
        this.callSug = true
        return
      }
      this.fetchingSuggestions = true
      try {
        let res = await this.SEARCH_PRODUCT_SUGGESTION({ q: this.text, office: this.officeId })
        this.suggestions = res
        if (this.callSug) {
          this.fetchingSuggestions = false
          this.fetchSuggestion()
        }
        this.callSug = false
      } catch (e) {
      }
      this.fetchingSuggestions = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/variables'

.search-suggestions
  min-height: 100px
  max-height: 293px
  min-width: 100%
  position: absolute
  top: 61px
  background-color: #ffffff
  overflow-y: auto
  border-radius: 10px
  z-index: 10
  // box-shadow: 0 0 25px 8px rgba(235, 235, 235, 0.93)
  border: solid 1px #ebeaeb
  @media screen and (max-width: 992px)
    min-width: calc( 100vw - (1rem * 2) )
    left: -1rem
    top: 64px
  .images-sec
    width: 42px
    min-width: 42px
    height: 40px
  .p-name
    font-size: 12px
    color: #000000
.search-wrapper
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 4px
.dash-head-search
  position: relative
  width: 100%
.custom-input
  width: 100%
  height: 36px !important
  border-radius: 12px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  @media screen and (max-width: 992px)
    width: auto
.custom-btn
  max-width: 124px
  width: max-content
  height: 46px !important
  border-radius: 12px !important
  font-size: 14px !important
  &.my-inv-btn
    min-height: 28px !important
    height: 28px !important
    font-size: 10px !important
    min-width: max-content
    margin-left: auto
</style>