<template>
  <div>
    <div v-if="!single">
      <div v-for="(office, oI) in offices" :key="oI" class="" style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)">
        <div :ref="`ofc-bud-${oI}`" class="ofc-bud-head cursor-pointer office-details-onboarding mb-3">
          <div class="d-flex justify-content-between mb-1">
            <span class="c-title">{{office.name}}</span>
            <span>
              <span @click="removeOffice(office)" class="form-label cursor-pointer text-danger mr-3">
                <b-spinner small variant="primary" class="mr-2" v-if="removing && removingId == office.id"></b-spinner>
                Remove
              </span>
              <span class="form-label cursor-pointer" v-b-toggle="`ofc-bud-${oI}`">{{collapses[oI] ? 'Close' : 'Edit'}}</span>
            </span>
          </div>
          <p class="form-label mb-1">
            <span class="font-weight-bold">Address: </span>
            <span>{{office.addresses[0].address}}</span>
          </p>
          <p class="form-label mb-1">
            <span class="font-weight-bold">Phone Number: </span>
            <span>{{office.phone_number}}</span>
          </p>
          <p class="form-label mb-1">
            <span class="font-weight-bold">Website: </span>
            <span>{{office.website}}</span>
          </p>
          <b-collapse :id="`ofc-bud-${oI}`" accordion="my-accordion" role="tabpanel" @hidden="v => {collapses[oI] = false; $forceUpdate()}" @shown="v => {collapses[oI] = true; $forceUpdate()}">
            <div class="pt-3">
              <div class="dash mb-4"></div>
              <office-detail-form  :office="office" :single="single" style="padding: 0; background-color: unset; border-radius: 0; width: unset" @updates="$forceUpdate()" @next="goNext" />
            </div>
          </b-collapse>
        </div>
        <!-- <slide-y-up-transition > -->
        <!-- </slide-y-up-transition> -->
        <div v-if="errors.length >= oI + 1 && Object.keys(errors[oI]).length > 0">
          <b-form-invalid-feedback class="d-block form-label text-danger" v-for="(error, eI) in formatErrors(errors[oI])" :key="eI">{{ error }}</b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <office-detail-form :class="[{'px-0' : settingsPage}]" :settingsPage="settingsPage" :single="single" @updates="$emit('updates');$forceUpdate()" @next="goNext" :prevOffices="offices" @done="$emit('done')" :initOfficeAddresss="initOfficeAddresss" :showPrevAddressOption="showPrevAddressOption" />
  </div>
</template>

<script>
import OfficeDetailForm from '@/components/onboarding/OfficeDetailForm'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    OfficeDetailForm,
  },
  props: {
    offices: Array,
    single: Boolean,
    settingsPage: Boolean,
  },
  data: () => ({
    // offices: [],
    formData: {
      name: null,
      address: null,
      website: null,
      phone_number: null,
      postal_code: null,
    },
    selectedOfficeIndex: -1,
    submitting: false,
    prevData: null,
    errors: [],
    removing: false,
    removingId: null,
    collapses: []
  }),
  created(){
    this.setCollapses()
  },
  watch: {
    offices(){ this.setCollapses() },
  },
  computed: {
    ...mapGetters('ONBOARDING', ['onboarding']),
    initOfficeAddresss(){
      if(this.offices.length > 0) return this.offices[0].addresses[0]
    },
    showPrevAddressOption(){
      return this.offices.length > 0
    },
  },
  methods: {
    ...mapActions('OFFICE', ['REMOVE_OFFICE']),
    ...mapActions('ONBOARDING', ['FETXH_COMPANY_WITH_OFFICES']),
    setCollapses(){
      this.collapses = new Array(this.offices.length).fill(false)
    },
    goNext(){
      if(!this.single && this.offices.length > 0) this.$emit('next')
    },
    async removeOffice(office){
      if(this.removing) return
      this.removing = true
      try{
        this.removingId = office.id
        await this.REMOVE_OFFICE({company: this.onboarding.company.id, office: office.id})
        await this.FETXH_COMPANY_WITH_OFFICES()
      }catch(e){}
      this.removingId = null
      this.removing = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.office-details-onboarding
  width: 507px
  padding: 32px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1)
  @media screen and (max-width: 992px)
    width: 100%
  ::v-deep .custom-input
    &:disabled
      background-color: #ffffff
</style>