import { render, staticRenderFns } from "./CrazyDental.vue?vue&type=template&id=befc1aea&scoped=true"
import script from "./CrazyDental.vue?vue&type=script&lang=js"
export * from "./CrazyDental.vue?vue&type=script&lang=js"
import style0 from "./CrazyDental.vue?vue&type=style&index=0&id=befc1aea&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "befc1aea",
  null
  
)

export default component.exports