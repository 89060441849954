import NumberMixin from '@/mixins/NumberComma'
export default {
  methods: {
    calculateBudget({ slug, officeId }){
      const index = this.$store.state.OFFICE.officeBudget[officeId].subaccounts.findIndex(a => a.slug == slug)
      if(index > -1 && this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage){
        if(this.$store.state.OFFICE.officeBudget[officeId].basis == 1 && this.$store.state.OFFICE.officeBudget[officeId].adjusted_production) return ((NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].adjusted_production) * NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage))/100).toFixed(2)
        else if(this.$store.state.OFFICE.officeBudget[officeId].basis == 2 && this.$store.state.OFFICE.officeBudget[officeId].collection) return ((NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].collection) * NumberMixin.methods.currencyToNumber(this.$store.state.OFFICE.officeBudget[officeId].subaccounts[index].percentage))/100).toFixed(2)
      }
    },
  },
}