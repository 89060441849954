<template>
  <div class="no-data d-flex flex-column justify-content-center align-items-center">
    <p class="d-title mb-1 text-center">This product is not in your inventory list</p>
    <!-- <p class="d-desc mb-0 text-center" style="max-width: 450px">We couldn’t find Cavi Wipes in your inventory list. To get results from your linked vendors search in the bar at the top of the page. </p> -->
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
  props: {
    searchQ: String,
  },
}
</script>

<style lang="sass" scoped>

.no-data
  min-height: 220px
  @media (max-width: 762px)
    background-image: unset
</style>