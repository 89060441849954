<template>
  <div class="page-wrapper">
    <Sec1 />
    <Sec2 style="margin-top: 6rem;" />
  </div>
</template>

<script>
import Sec1 from '@/components/landing/product-demo/Sec1'
import Sec2 from '@/components/landing/product-demo/Sec2'
export default {
  components: { Sec1, Sec2, },
}
</script>

<style lang="sass" scoped>
.page-wrapper
  background-color: #F8F9FC
</style>