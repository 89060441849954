<template>
  <div class="onboarding-page w-100">
    <div class="d-flex justify-content-center align-items-center" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <p class="mb-0">Fetching Data...</p>
    </div>
    <div class="d-flex flex-column align-items-center" v-else>
      <onboarding-steps :currentStep="step" :completed="completed" @change="setStep" :dashboard="true" :fullCompletedSteps="fullCompletedSteps" />
      <div class="active-step mt-4 w-100 d-flex justify-content-center">
        <connect-account @next="v=>{setStep(2, true); $emit('step-change')}" v-if="step == 1" :offices="offices" @vendor-conn="$emit('vendor-conn')" />
        <office-budget @next="v=>{setStep(3, true); $emit('step-change')}" v-else-if="step == 2" :offices="offices" @opendental-conn-update="$emit('opendental-conn-update')" />
        <invite-team v-else-if="step == 3" :offices="offices" :prevl="true" :members="[]" @next="$emit('close')" @invited="fetchMembers" @skip-step="skipStep" :skippingStep="skippingStep" />
      </div>
      <div class="mt-3" v-if="step != 3">
        <p class="text-danger__2 text-center f-12-px gilroy-b cursor-pointer" @click="skipStep">
          <b-spinner style="width: 12px; height: 12px;" class="mr-2" v-if="skippingStep"></b-spinner>
          Skip Step
        </p>
      </div>
    </div>
    <div class="character-wrapper">
      <div class="character">
        <div class="msg-box" v-if="m">
          <div class="msg">
            <p class="m-title">{{m.title}}</p>
            <p class="m-desc mb-0">
              {{msgDescription}}
            </p>
          </div>
        </div>
        <div :class="['img-part', { 'd-flex justify-content-end': step != 1 && step != 6 }, step]">
          <img v-if="step == 1" src="@/assets/images/onboarding/onboarding_one.png" alt="" class="mx-auto" style="height: 390px;">
          <img v-else-if="step == 6" src="@/assets/images/onboarding/final_onboarding.png" alt="" class="mx-auto" style="height: 390px;">
          <img v-else src="@/assets/images/onboarding/main_onboarding.png" alt="" class="mx-auto" style="height: 390px; width: 180px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingSteps from '@/components/onboarding/OnboardingSteps'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import BillingInfo from '@/components/onboarding/BillingInfo'
import AccountSetupOne from '@/components/onboarding/AccountSetupOne'
import AccountSetupTwo from '@/components/onboarding/AccountSetupTwo'
import InviteTeam from '@/components/onboarding/InviteTeam'
import ConnectAccount from '@/components/onboarding/ConnectAccount'
import OfficeBudget from '@/components/onboarding/OfficeBudget'
import { mapGetters, mapActions, mapState } from 'vuex'
import MsgArrow from '@/components/svg/onboarding/MsgArrow'
import OfficeMmixin from '@/mixins/OfficeMixin'
export default {
  components: {
    OnboardingSteps,
    OfficeDetails,
    BillingInfo,
    AccountSetupOne,
    AccountSetupTwo,
    InviteTeam,
    ConnectAccount,
    OfficeBudget,
    MsgArrow,
  },
  mixins: [ OfficeMmixin ],
  data: () => ({
    step: 1,
    fetching: false,
    completed: [1,2,3],
    members: [],
    skippingStep: false,
  }),
  created(){
    this.setInitStep()
  },
  watch: {
    fullCompletedSteps: {
      handler(v){
        this.setInitStep()
      }, deep: true
    },
  },
  computed: {
    ...mapGetters('ONBOARDING', ['onboarding']),
    ...mapState('ONBOARDING', ['status']),
    msgDescription(){
      let desc = this.m.desc// || (this.m.desc + '...')
      return desc
    },
    showViewFullDesc(){
      if(this.m && this.m.desc){
        return this.m.desc.length > 250
      }
      return false
    },
    image(){
      return 'One'
    },
    m(){
      switch(this.step){
        case 1:
          return { title: 'Connect your dental and front office vendors', desc: `To get the most out of Ordo, link the vendors you use to purchase your front office and dental supplies. To link a vendor click "Connect" next to the vendor name and input your account details. If we're missing a vendor that you use, no problem click "Request a Vendor".` }  
        case 2:
          return { title: `Next, let's set up your office budgets!`, desc: `You'll first want to click on "Set Dental Supply Budget". Setting a dental supply budget is required for all dental offices using Ordo. Once that’s complete, we encourage you to set your Front Office Supply Budget. Lastly, click "Save and Continue" to move to the next step.` }
        case 3:
          return { title: 'Ordo is better with a team!', desc: 'Please add your team members to Ordo. You can select them to be either an admin or a user. View the list to the right to determine what privileges they have on Ordo. You can always add or remove team members later.' }
      }
    },
    fullCompletedSteps(){
      const steps = []
      if(this.status){
        if(this.status.vendors_linked) steps.push(1)
        if(this.status.budget_set) steps.push(2)
        if(this.status.team_invited) steps.push(3)
      }
      return steps
    },
  },
  methods: {
    ...mapActions('ONBOARDING', ['ONBOARDING_CREATE_OFFICES']),
    ...mapActions('OFFICE', ['TEAM_MEMBERS']),
    setInitStep(){
      if(this.step == 1) {
        if(this.fullCompletedSteps.length > 0) this.step = Math.min(Math.max(...this.fullCompletedSteps) + 1, 3)
        else this.step = 1
      }
    },
    async fetchMembers(){
      let members = await this.TEAM_MEMBERS()
      // members.pop()
      this.members = members
    },
    setStep(val, completed){
      if(this.skippingStep) return
      this.step = val
      if(completed && !this.completed.includes(val)) this.completed.push(val)
    },
    async skipStep(){
      if(this.skippingStep) return
      this.skippingStep = true
      await this.ONBOARDING_CREATE_OFFICES({
        on_boarding_step: this.step + 2
      })
      if(this.step < 3) {
        this.step++
        this.$emit('step-change', this.step)
      }
      else this.$emit('close')
      this.skippingStep = false
    },
  },
}
</script>

<style lang="sass" scoped>
.onboarding-page
  position: relative
  min-height: 75vh
  .character-wrapper
    display: none
    @media screen and (min-width: 1350px)
      display: block
    position: fixed
    bottom: 40px
    // right: 40px
    left: 0
    .character
      position: relative
      .msg-box
        width: 320px
        min-height: 166px
        // border-radius: 15px
        // background-color: #fff
        position: absolute
        background-image: url(../assets/images/onboarding/Frame2.png)
        background-size: 100% 100%
        left: 10px
        bottom: 350px
        padding: 45px 30px 100px 45px
        .msg
          .m-title
            font-size: 17px
            font-family: GILROY-BOLD
            font-stretch: normal
            font-style: normal
            line-height: 1.2
            letter-spacing: normal
            text-align: left
            color: #000
          .m-desc
            font-size: 14px
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: 1.5
            letter-spacing: normal
            text-align: left
            color: #788288
        svg
          position: absolute
          left: 200px
          bottom: -34px
      .img-part
        width: 294px
        height: 390px
        position: relative
        bottom: -10px
        svg
          width: inherit
          height: inherit
</style>