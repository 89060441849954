import { render, staticRenderFns } from "./Lan8.vue?vue&type=template&id=22b58d00&scoped=true"
import script from "./Lan8.vue?vue&type=script&lang=js"
export * from "./Lan8.vue?vue&type=script&lang=js"
import style0 from "./Lan8.vue?vue&type=style&index=0&id=22b58d00&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b58d00",
  null
  
)

export default component.exports