<template>
  <div class="px-4 py-3">
    <p class="title-1">Ordo Features</p>
    <RouterLink class="title-2 d-flex gap-16-px !text-none py-2" v-for="(feature, i) in features" :key="i" :to="feature.to">
      <img :src="feature.image" alt="" class="w-20-px h-20-px">
      <span class="text-dark">{{ feature.title }}</span>
    </RouterLink>
  </div>
</template>

<script>
export default {
 data: () => ({
  features: [
    { image: require('@/components/landing/feature-images/price_comparison.png'), type: 'price_comparison', title: 'Price Comparison', to: '/feature-price-comparison', },
    { image: require('@/components/landing/feature-images/tracking_budgets.png'), type: 'tracking_budgets', title: 'Budget Tracking', to: '/feature-tracking-budget', },
    { image: require('@/components/landing/feature-images/tracking_orders_returns.png'), type: 'tracking_orders_returns', title: 'Track Orders and Returns', to: '/feature-tracking-orders-returns', },
    { image: require('@/assets/images/future_cast.png'), type: 'future_cast', title: 'Futurecast', to: '/feature-futurecast', },
    { image: require('@/components/landing/feature-images/order_approvals.png'), type: 'order_approvals', title: 'Order Approvals', to: '/feature-order-approvals', },
    { image: require('@/components/landing/feature-images/multi-vendor_orders.png'), type: 'multi-vendor_orders', title: 'Multi-vendor Ordering', to: '/feature-multivendor-ordering', },
    { image: require('@/components/landing/feature-images/imported_order_history.png'), type: 'imported_order_history', title: 'Imported Order History', to: '/feature-imported-order-history', },
    { image: require('@/components/landing/feature-images/customize_products.png'), type: 'customize_products', title: 'Customize Inventory', to: '/feature-customize-products', },
    { image: require('@/components/landing/feature-images/download_invoice.png'), type: 'download_invoice', title: 'Download Invoices', to: '/feature-download-invoices', },
    { image: require('@/components/landing/feature-images/analyze_supplies.png'), type: 'analyze_supplies', title: 'Analytics', to: '/feature-analyze-supplies', },
    { image: require('@/components/landing/feature-images/open_dental.png'), type: 'open_dental', title: 'Syncs with Open Dental', to: '/feature-opendental-interation', },
    { image: require('@/components/landing/feature-images/multi-office_function.png'), type: 'multi-office_function', title: 'Multi-Office Functionality', to: '/feature-multioffice-functionality', },
  ]
 }),
}
</script>

<style lang="sass" scoped>
.title-1
  font-family: GILROY-BOLD
  font-size: 18px
  color: #000000
.title-2
  font-family: GILROY-SEMIBOLD
  font-size: 16px
  color: #788288 !important
</style>