<template>
  <div class="page-outer px-3">
    <div class="page-contents mx-auto">
      <p class="title-1">FAQ's (Frequently Asked Questions)</p>
      <p class="subtitle-1 mb-5" style="max-width: 535px;">
        Still have some questions on Ordo? No worries, check out our Frequently Asked Questions below.
      </p>
      <div class="faq mb-4" v-for="(faq, i) in faqs" :key="i">
        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
          <path d="M4.2412 5.45676L0.584201 1.79976C0.511113 1.73321 0.452122 1.65266 0.410718 1.5629C0.369315 1.47314 0.346341 1.37598 0.343157 1.27718C0.339972 1.17838 0.356642 1.07994 0.392179 0.987701C0.427717 0.895458 0.481399 0.811283 0.550049 0.740157C0.618699 0.669031 0.700921 0.612402 0.791847 0.573621C0.882774 0.53484 0.980557 0.514694 1.07941 0.514378C1.17826 0.514061 1.27617 0.53358 1.36734 0.571778C1.45851 0.609977 1.5411 0.666079 1.6102 0.736764L1.6282 0.754765L5.8082 4.93376C5.94676 5.07235 6.0246 5.2603 6.0246 5.45626C6.0246 5.65223 5.94676 5.84018 5.8082 5.97876L1.6292 10.1578C1.56177 10.2276 1.48125 10.2834 1.39224 10.3221C1.30322 10.3608 1.20747 10.3815 1.11043 10.3832C1.01339 10.3849 0.916976 10.3674 0.826685 10.3318C0.736395 10.2962 0.653999 10.2432 0.584201 10.1758C0.514404 10.1083 0.458572 10.0278 0.419893 9.9388C0.381214 9.84979 0.360446 9.75403 0.358775 9.65699C0.357103 9.55996 0.374561 9.46354 0.410151 9.37325C0.445742 9.28296 0.498767 9.20056 0.566201 9.13076L0.584201 9.11276L4.2412 5.45676Z" fill="#50A9D5" stroke="#50A9D5" stroke-width="0.5"/>
        </svg>
        <p class="title-2 mb-0" v-b-toggle="'collapse-' + i">{{ faq.question }}</p>
        <b-collapse :id="'collapse-'+i">
          <div class="pt-3">
            <p class="subtitle-1 mb-0">{{ faq.answer }}</p>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    faqs: [
      {
        question: "What do I need Ordo for?",
        answer: "Ordo saves you time and money by being able to place and track your orders on one site, as well as manage your budget and keep your inventory list all in one place. And never miss another promotion code as Ordo finds and adds them automatically."
      },
      {
        question: "What is Ordo?",
        answer: "Ordo is an inventory and supply management platform for your dental office. When using ordo, you can order from your vendors on one site, compare pricing between vendors, and track and manage your orders and supply budgets. Say goodbye to those overly complicated spreadsheets for good!"
      },
      {
        question: "How do I get started?",
        answer: `Just click “Get Started” on the home page. Our onboarding process is very simple and only takes a few minutes to get started. You’ll just need to enter your office details, billing information, and link your current vendors to Ordo.`
      },
      {
        question: "How much can I expect to save by using Ordo?",
        answer: `Ordo helps you save on supplies using our price comparison tool to always find the best price among your linked vendors. Ordo even looks for any available promo codes and automatically puts them in when you are checking out. Ordo also saves you and your team time by streamlining the entire ordering process from placing orders to tracking orders to receiving orders.`
      },
      {
        question: "Will I get to keep my current negotiated pricing?",
        answer: `Yes. If you are part of a buying group, or have special pricing with your vendors, then you will still get that same pricing when using Ordo.`
      },
    ],
  }),
}
</script>

<style lang="sass" scoped>
.page-contents
  max-width: 842px
  padding: 6rem 0
.title-1
  font-family: GILROY-BOLD
  font-size: 24px
  color: #274458
.title-2
  font-family: GILROY-BOLD
  font-size: 18px
  color: #274458
.subtitle-1
  font-family: GILROY-MEDIUM
  font-size: 16px
  color: #788288
  line-height: 1.6
.faq
  position: relative
  background-color: #FFFFFF
  padding: 1rem
  border-radius: 8px
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
  .icon
    position: absolute
    right: 1rem
    top: 1.3rem
  &:has(.collapse.show)
    .icon
      transform: rotate(90deg)
</style>