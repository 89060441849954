<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <p class="d-title mt-2 mb-0" style="font-size: 18px;">Sub-company Settings</p>
    </div>
    <div class="d-flex flex-column flex-md-row gap-12-px mb-4">
      <p class="mb-0 d-desc__m551" style="width: 450px;">Your sub-company-related data will be here. If anything related
        to your company details needs to be changed or updated, you can do it below.</p>
      <b-btn class="custom-btn o-btn ml-md-auto add-o-btn" @click="companyModal = true">+ &nbsp; Add a sub-company</b-btn>
    </div>
    <div v-for="(office, oI) in allSubCompanies" :key="oI">
      <div class="dash mb-4" v-if="oI > 0"></div>
      <div class="d-lg-flex justify-content-lg-between align-items-lg-center my-3" :ref="`ofc-set-${oI}`"
        v-b-toggle="`ofc-set-${oI}`">
        <p class="mb-2 d-title__2" style="font-size: 16px;">{{ office.name }}</p>
        <div class="d-flex align-items-center">
          <b-icon icon="chevron-down" class="ml-3 cursor-pointer" style="outline: none"></b-icon>
        </div>
      </div>
      <b-collapse :id="`ofc-set-${oI}`" accordion="office-accordion" role="tabpanel" :visible="oI == 0">
        <div class="notf-surr" v-if="!officeBudgetSettings">
          <div class="check-switch-box mb-2 pl-3 pr-2">
            <div class="notf-p1">
              <p class="mb-0 d-title__4">Company Name</p>
            </div>
            <div>
              <input type="text" v-show="editField" v-bind:value="office.name" @blur="updateCompanyName"
                @keyup.enter="updateCompanyName" v-focus="editField" />
              <p v-show="!editField" @click="edit = true;" class="mb-0 d-title__4">
                {{ office.name }}
              </p>
            </div>
            <div class="ml-auto">
              <div class="sm-card">
                <!-- <p class="mb-0 d-desc__3 mr-4 c-text">{{ office.name }}</p> -->
                <b-btn v-if="!editField" class="custom-btn sm-btn" @click="toggleEditMode()">Edit</b-btn>
                <b-btn v-else class="custom-btn sm-btn" @click="updateCompany(office)">Save</b-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-end my-3 pr-2" style="gap: 15px">
          <b-btn class="custom-btn danger__2 o-btn" @click="openRmModal(office)">Remove Company</b-btn>
        </div>
      </b-collapse>
    </div>
    <modal :modal="companyModal" @change="toggleOfcModal" size="md">
      <p class="modal-header-title text-center">Add another sub-company</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4">Need to add another sub-company? Enter the information below to add
          another sub-company to your Ordo account</p>
      </div>
      <div class="dash mb-0"></div>
      <sub-company-form :class="[{ 'px-0': settingsPage }]" :settingsPage="settingsPage" :single="single"
        @updates="$emit('updates'); $forceUpdate()" @next="preCreateSubCompany" :prevOffices="offices"
        @done="$emit('done')" :initOfficeAddresss="initOfficeAddresss" :showPrevAddressOption="showPrevAddressOption"
        @getAllSubCompanies="getAllSubCompanies" />
    </modal>
    <modal :modal="rmCompanyModal" @change="toggleRmCompanyModal" size="sm">
      <div>
        <p class="d-title text-center">Remove this sub-company?</p>
        <div class="d-flex justify-content-between">
          <Button text="Remove" classes="danger__2 cr-btn" :onclick="removeCompany" :submitting="removing" />
          <Button text="Cancel" classes="cr-btn" :onclick="() => rmCompanyModal = false" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Office from '@/components/dashboard/settings/Office'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/modals/Modal'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import NumberComma from '@/mixins/NumberComma'
import SubCompanyForm from '@/components/onboarding/SubCompanyForm'


export default {
  components: {
    Office,
    Modal,
    OfficeDetails,
    SubCompanyForm,
    Button,
  },
  mixins: [OfficeMixin, NumberComma],
  data: () => ({
    linkOpenDentalModal: false,
    companyModal: false,
    rmCompanyModal: false,
    removing: false,
    updating: false,
    selectedSubCompany: null,
    allSubCompanies: null,
    editField: false,
    valueLocal: null
  }),
  created() {
    this.getAllSubCompanies();
  },

  computed: {
    ...mapGetters('USER', ['company', 'offices']),

    filteredOffices() {
      return [this.offices[this.officeIndex]].concat(this.offices.filter(o => o.id != this.officeId))
    },

  },
  methods: {
    // ...mapActions('OFFICE', ['REMOVE_OFFICE', 'FETCH_OPENDENTAL_CONNECTION_STATUS']),
    ...mapActions('USER', ['GET_ACCOUNT']),
    ...mapActions('SUBCOMPANY', ['GET_ALL_SUB_COMPANY', 'REMOVE_SUB_COMPANY', 'UPDATE_SUB_COMPANY']),
    async getAllSubCompanies() {
      this.allSubCompanies = await this.GET_ALL_SUB_COMPANY();
      this.companyModal = false;
    },
    focusField(name) {
      this.editField = name;
    },
    blurField() {
      this.editField = '';
    },
    showField(name) {
      return this.editField == name
    },
    toggleEditMode() {
      this.editField = !this.editField;
    },
    setEditMode(value) {
      this.editField = value;
    },
    openRmModal(company) {
      this.selectedSubCompany = company
      this.rmCompanyModal = true
    },
    toggleOfcModal(v) {
      if (v != this.companyModal) this.companyModal = v != null ? v : false
    },
    toggleRmCompanyModal(v) {
      if (v != this.rmCompanyModal) this.rmCompanyModal = v != null ? v : false
    },
    toggleLinkOpenDentalModal(v) {
      if (v != this.linkOpenDentalModal) this.linkOpenDentalModal = v != null ? v : false
    },
    async removeCompany() {
      if (this.removing) return
      this.removing = true
      try {
        await this.REMOVE_SUB_COMPANY({ sub_company: this.selectedSubCompany.id })
        this.getAllSubCompanies();
        this.toggleRmCompanyModal(false)
        this.selectedSubCompany = null
      } catch (e) { console.log(e) }
      this.removing = false
    },
    updateCompanyName(event) {
      this.valueLocal = event.target.value;
    },
    async updateCompany(company) {
      if (this.updating) return;
      this.updating = true;
      try {
        this.selectedSubCompany = company;
        await this.UPDATE_SUB_COMPANY({
          sub_company: this.selectedSubCompany.id,
          name: this.valueLocal, 
          company: company.company
        });
        this.getAllSubCompanies();
        this.toggleRmCompanyModal(false)
        this.selectedSubCompany = null
        this.toggleEditMode()
      } catch (e) {
        console.log(e);
      }
      this.setEditMode(false);
      this.updating = false;
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings' 
.cr-btn
  min-width: 100px
.add-o-btn
  min-width: 119px
</style>