<template>
  <div class="order-items">
    <div class="table-section">
      <b-table :items="filteredOrders" :fields="fields" class="tabl re-order-row">
        <template #cell(image)="row">
          <!-- <div class="" v-if="row.item.showForm"></div>
          <product-image :product="row.item.product" :no-image-short="true" size="sm" v-else /> -->
        </template>
        <template #cell(name)="row">
          <div :class="{ 'error-message': showErrors && formErrors.product_name }" v-if="row.item.showForm">
            <b-input v-model="row.item.product_name" class="custom-input table-input" 
              :class="{ 'error-message': showErrors && formErrors.product_name }"  :placeholder="namePlaceholderValue()"
              type="text" style="height: 28px !important"></b-input>
          </div>
          <div class="" v-else>
            <b-input v-model="row.item.product.name" class="custom-input table-input"
              :class="{ 'error-message': showErrors && row.item.product.name }" readonly :placeholder="namePlaceholderValue()"
              type="text" style="height: 28px !important"></b-input>
          </div>
        </template>
        <template #cell(status_display_text)="row">
          <div class="" v-if="row.item.showForm">
            <b-select v-model="row.item.status" :options="orderItemStatusList"
              class="custom-input custom-select table-input"  placeholder="Status"
              style="height: 28px !important"></b-select>
          </div>
          <div v-else>
            <b-select v-model="row.item.status" :options="orderItemStatusList"
              class="custom-input custom-select b-select-controls hide_img table-input" disabled  placeholder="Status"
              style="height: 28px !important"></b-select>
          </div>

          <!-- status select dropdown show, code -->
          <!-- <div v-else class="">
            <b-tooltip :target="`tooltip-target-rej-reason-${row.item.id}`" triggers="hover focus"  placement="top"
              boundary="document" custom-class="promotion-tooltip" v-if="row.item.status == 'rejected'" >
              <p class="d-desc mb-0">{{ getRejectedReasonText(row.item.rejected_reason) }}</p>
            </b-tooltip> -->
            <!-- <p :id="`tooltip-target-rej-reason-${row.item.id}`"
              :class="['t-text mb-0 w-max', row.item.tracking_link && 'text-primary text-underline cursor-pointer']"
              @click="openShippingUrl(row.item)">
              {{ row.item.status_display_text }}
              <img src="@/assets/images/caution.png" alt="" class="w-14-px h-14-px mb-1 ml-1"
                v-if="row.item.status == 'rejected'" />
            </p> -->
            <!-- <p>{{ getStatus(row) }}</p>
            <b-select v-model="row.item.status" :options="orderItemStatusList"
              class="custom-input custom-select table-input" placeholder="Status"
              style="height: 28px !important"></b-select>
          </div> -->
        </template>
        <template #cell(quantity)="row">
          <div v-if="row.item.showForm" class="d-flex justify-content-center">
            <b-input v-model="row.item.quantity" class="custom-input table-input text-center custom_input input-readyonly"
              :class="{ 'error-message': showErrors && formErrors.quantity }"  :placeholder="quantityPlaceholderValue()"
              type="number" min="1" style="height: 28px !important; max-width: 100px;"></b-input>
            <!-- <p class="error-message" v-if="showErrors && formErrors.quantity">{{ formErrors.quantity }}</p> -->
          </div>
          <div v-else="" class="d-flex justify-content-center">
            <b-input v-model="row.item.quantity" class="custom-input table-input text-center custom_input input-readyonly"
              :class="{ 'error-message': showErrors && formErrors.quantity }" readonly :placeholder="quantityPlaceholderValue()"
              type="number" min="1" style="height: 28px !important; max-width: 100px;"></b-input>
            <!-- <p class="error-message" v-if="showErrors && formErrors.quantity">{{ formErrors.quantity }}</p> -->
          </div>
        </template>
        <template #cell(vendor)="row">
          <div class="" v-if="row.item.showForm">
          </div>
          <div class="d-flex align-items-center" v-else>
            <div class="ven-image mr-2">
              <img :src="row.item.product.vendor.logo" alt="">
            </div>
            <p class="mb-0 t-text">{{ row.item.product.vendor.name }}</p>
          </div>
        </template>
        <template #cell(total)="row">
          <div v-if="row.item.showForm">
            <!-- <b-input v-model="row.item.unit_price" class="custom-input table-input"
              :class="{ 'error-message': showErrors && formErrors.unit_price }" :placeholder="pricePlaceholderValue()"
              type="number" min="0" style="height: 28px !important"></b-input> -->
            <!-- <p class="error-message" v-if="showErrors && formErrors.unit_price">{{ formErrors.unit_price }}</p> -->
            <p class="t-text mb-0 mt-1">${{ totalAmount(row.item) }}</p>
          </div>
          <div class="" v-else>
            <b-tooltip :target="`tooltip-price-change-${row.item.id}`" triggers="hover focus" placement="top"
              boundary="document" custom-class="promotion-tooltip"
              v-if="row.item.status == 'pendingapproval' && row.item.updated_unit_price && row.item.updated_unit_price != row.item.unit_price">
              <p class="d-desc mb-0">Price changed from ${{ totalAmount(row.item, row.item.unit_price) }} to ${{
        totalAmount(row.item, row.item.updated_unit_price) }}</p>
            </b-tooltip>
            <p class="t-text mb-0 pl-2-px " :id="`tooltip-price-change-${row.item.id}`">
              ${{ totalAmount(row.item) }}
              <img src="@/assets/images/caution.png" alt="" class="w-14-px h-14-px mb-1 ml-1"
                v-if="row.item.status == 'pendingapproval' && row.item.updated_unit_price && row.item.updated_unit_price != row.item.unit_price" />
            </p>
          </div>
        </template>
        <template #cell(order_col)="row">
          <div class="reorder-button-container" v-if="showReorderButton">

            <!-- Replace the existing reorder button with this: -->
            <!-- <b-btn @click="openReorderModal(row)" class="!min-h-28-px w-125-px re-order-btn fs-12px" style="min-width: max-content">Select Items to Reorder</b-btn> -->
          </div>
        </template>
        <template #cell(unit_total)="row">
          <div class="" v-if="row.item.showForm">
            <b-input v-model="row.item.unit_price" class="custom-input table-input"
              :class="{ 'error-message': showErrors && formErrors.unit_price }"  :placeholder="pricePlaceholderValue()"
              type="number" min="0" style="height: 28px !important"
              @input="formatUnitPrice(row.item, $event.target.value)"></b-input>
            <!-- <p class="error-message" v-if="showErrors && formErrors.unit_price">{{ formErrors.unit_price }}</p> -->
            <!-- <p class="t-text mb-0 mt-1">Total: ${{ totalAmount(row.item) }}</p> -->
          </div>
          <div class="" v-else>
            <b-input v-model="row.item.unit_price" class="custom-input table-input"
              :class="{ 'error-message': showErrors && formErrors.unit_price }" readonly :placeholder="pricePlaceholderValue()"
              type="number" min="0" style="height: 28px !important"
              @input="formatUnitPrice(row.item, $event.target.value)"></b-input>
          </div>

        </template>
        <template #cell(itemRcv)="row">
          <div class="" v-if="row.item.showForm">
            <b-form-checkbox switch class="custom-chk" @change="v => onChangeReceivedToggle(v, row.item)"
              :checked="row.item.status == 'received'" :disabled="true"></b-form-checkbox>
          </div>
          <div class="d-flex justify-content-center" v-else>
            <b-form-checkbox switch class="custom-chk" @change="v => onChangeReceivedToggle(v, row.item)"
              :checked="row.item.status == 'received'"
              :disabled="row.item.status == 'rejected' || row.item.status == 'cancelled'"></b-form-checkbox>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="" v-if="row.item.showForm || (row.item.product && row.item.product.vendor == null)">
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
              <BudgetType v-model="row.item.budget_spend_type" class="mb-1"
                v-if="!$store.state.OFFICE.fetchingBudget && showButtons"
                @input="v => updateBudgetCategory(row.item, { value: v })" />
              <b-icon icon="trash" class="cursor-pointer danger__2 mb-1"
                @click="() => deleteManualOrderProduct(row.item)" v-if="showButtons" font-scale="1.8"
                style=" color: #FF38C9; margin-left: 4px;"></b-icon>

            </div>
            <!-- <div class="d-flex gap-8-px justify-content-center">
              <Button text="Save" classes="!min-h-28-px" style="min-width: max-content"
                :onclick="() => saveManualOrderProduct(row.item)"
                :submitting="submittingManualOrderProductId == row.item.id" v-if="showButtons && isAlready(row.item)" />

            </div> -->
          </div>
          <div class="" v-else>
            <div class="d-lg-flex justify-content-center">
              <div v-if="rejectReasonBtn && role == 1" class="position-relative">
                <div class="d-flex align-items-center justify-content-between" style="gap: 15px">
                  <p
                    :class="['t-text mb-0', isRejected(row.item) || isRejToggleOpen(row.item.id) ? '' : 'text-success']">
                    Approve</p>
                  <b-dropdown right class="menu-drp" menu-class="rej-res-sel" :ref="`rej-res-${row.item.id}`"
                    @hide="checkRejRes(row.item)" offset="44">
                    <template #button-content>
                      <div class="d-flex align-items-center gap-10-px">
                        <b-form-checkbox switch class="custom-chk__alt" :ref="`chk-res-${row.item.id}`" disabled
                          @change="v => onChangeToggler(v, row.item)"
                          :checked="isRejected(row.item) || isRejecetedReasonPopOpen(row.item)"></b-form-checkbox>
                        <p class="t-text text-right mb-0">
                          <span v-if="isRejected(row.item) || isRejToggleOpen(row.item.id)">
                            <span class="text-danger__2" v-if="isRejected(row.item)">Rejected</span>
                            <span class="text-danger__2" v-else>Reject</span>
                          </span>
                          <span v-else>
                            <span>Reject</span>
                          </span>
                          <img src="@/assets/images/svg_icon/down_caret_grey.svg" alt="" class="ml-1"
                            v-show="isRejToggleOpen(row.item.id)">
                        </p>
                      </div>
                    </template>
                    <div class="cm-body">
                      <div v-for="(reason, rI) in rejectReasons" :key="rI">
                        <div class="dash" v-if="rI > 0"></div>
                        <div class="my-3 cursor-pointer d-flex align-items-center" @click="setRej(reason, row.item)">
                          <div class="ven-ck mr-2 px-2-px">
                            <img src="@/assets/images/pink_check_mark.png" alt=""
                              style="vertical-align: unset; max-width: 100%; max-height: 100%"
                              v-if="isSelected(reason, row.item)">
                          </div>
                          <p class="d-desc text-danger__2 mb-0">{{ reason.text }}</p>
                        </div>
                      </div>
                    </div>
                  </b-dropdown>
                </div>
                <p class="mb-0 d-desc__2 text-center w-max" style="line-height: 1; position: absolute;"
                  v-if="isRejected(row.item)">{{
        rejectReasonBtnText(row.item) }}</p>
              </div>
              
              <div v-else class="d-flex align-items-center justify-content-between " style="gap: 15px" ref="iteAction">
                <b-dropdown right class="menu-drp not-blur" menu-class="bud-cat-sel" :ref="`order-action-${row.item.id}`">
                  <template #button-content>
                    <b-btn class="custom-btn outline-primary d-flex align-items-center justify-content-center">
                      <span :class="['text-primary']">Re-order / Return</span>
                      <!-- <span :class="['text-danger__2']">{{itemReason(row.item, 'budget_category')}}</span> -->
                      <img src="@/assets/images/svg_icon/down_caret.svg" alt="" class="ml-2">
                    </b-btn>
                  </template>
                  <div class="cm-body">
                    <div class="my-3 cursor-pointer d-flex align-items-center justify-content-between"
                      @click="reOrder(row.item)">
                      <p class="f-12-px mb-0 text-primary">
                        <b-spinner small style="width: 0.8rem; height: 0.8rem;" variant="primary" class="mr-2"
                          v-if="selectedItem && selectedItem.id == row.item.id && reOrdering" />
                        Re-Order Item
                      </p>
                      <div
                        class="px-3 py-1 d-flex justify-content-center align-items-center bg-primary br-4-px h-20-px">
                        <p class="f-10-px text-white mb-0">Select</p>
                      </div>
                    </div>
                    <div class="dash"></div>
                    <div class="my-3 cursor-pointer d-flex align-items-center justify-content-between"
                      >
                      <p class="f-12-px mb-0 text-primary">Return Item</p>
                      <div
                        class="px-3 py-1 d-flex justify-content-center align-items-center bg-primary br-4-px h-20-px">
                        <p class="f-10-px text-white mb-0">Select</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown>
                <b-dropdown right class="menu-drp not-blur" menu-class="bud-cat-sel" :ref="`bud-cat-${row.item.id}`"
                  v-if="role === 1 && !$store.state.OFFICE.fetchingBudget" offset="44">
                  <template #button-content>
                    <b-btn class="custom-btn danger__2__outline d-flex align-items-center justify-content-between"
                      style="width: 147px;">
                      <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                        v-if="isSelected({ value: 'dental' }, row.item, 'budget_category')">Dental Supply Budget</span>
                      <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                        v-else-if="isSelected({ value: 'office' }, row.item, 'budget_category')">Front Office Supply
                        Budget</span>
                      <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                        v-else-if="isSelected({ value: 'miscellaneous' }, row.item, 'budget_category')">Uncategorized</span>
                      <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;" v-else>{{
        getBudgetType(row.item) }}</span>
                      <!-- <span :class="['text-danger__2']">{{itemReason(row.item, 'budget_category')}}</span> -->
                      <img src="@/assets/images/svg_icon/down_caret_pink.svg" alt="" class="ml-2" v-if="role == 1">
                    </b-btn>
                  </template>
                  <div class="cm-body">
                    <div v-for="(budCat, rI) in budgetCategories" :key="rI">
                      <div class="dash" v-if="rI > 0"></div>
                      <div class="my-3 cursor-pointer d-flex align-items-center"
                        @click="setRej(budCat, row.item, 'budget_category', $event)">
                        <div class="ven-ck mr-2 px-2-px">
                          <img src="@/assets/images/pink_check_mark.png" alt=""
                            style="vertical-align: unset; max-width: 100%; max-height: 100%"
                            v-if="isSelected(budCat, row.item, 'budget_category')">
                        </div>
                        <p class="d-desc text-danger__2 mb-0">{{ budCat.text }}</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown>
              </div>
            </div>
            <p class="d-desc mb-0 f-10-px text-success position-absolute"
              :style="{ right: '1rem', bottom: '-2px', visibility: showUpdated ? 'visible' : 'hidden' }">
              Updated
              <img src="@/assets/images/check.svg" alt="" class="ml-1 mt-1-px">
            </p>
          </div>
        </template>
      </b-table>
    </div>
    <div class="w-100 pr-2">
      <p class="text-right d-desc text-danger__2 my-2" v-for="(msg, mI) in msgs" :key="mI">{{ msg }}</p>
    </div>
    <modal :modal="returnProcessModal" @change="handleModalChange" ref="returnModal" size="lg"
      dialog-class="return-process-modal">
      <p class="d-title__7 f-18-px text-center">Process a Return</p>
      <button class="close-button" @click="closeModal">&times;</button>
      <p class="d-desc text-center">To process a return, first select the item(s) in your order that you'd like to
        return
      </p>
      <div class="d-flex align-items-center mb-3">
        <div class="sl-num">
          <p class="d-desc mb-0 text-white">1</p>
        </div>
        <p class="mb-0 d-title__2 text-danger__2 ml-2">Select Products to Return</p>
      </div>
      <div class="table-section order-items" style="overflow-x: clip">
        <b-table :items="filteredOrders" :fields="returnProcessFields" class="tabl mb-0" ref="reTable">
          <template #cell(image)="row">
            <product-image :product="row.item.product" :no-image-short="true" size="sm" />
          </template>
          <template #cell(name)="row">
            <p class="t-text mb-0">{{ row.item.product.name }}</p>
          </template>
          <template #cell(quantity)="row">
            <div class="d-flex align-items-center justify-content-center">
              <span class="t-text mb-0 cursor-pointer" @click="decreaseQ(row.item.id)">-</span>
              <p class="t-text mb-0 mx-2">{{ orderItems[row.item.id].quantity }}</p>
              <span class="t-text mb-0 cursor-pointer" @click="increaseQ(row.item.id)">+</span>
            </div>
          </template>
          <template #cell(total)="row">
            <p class="t-text mb-0 pl-2-px">${{ totalAmount(row.item) }}</p>
          </template>
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox v-model="orderItems[row.item.id].return" switch class="custom-chk"></b-form-checkbox>
            </div>
          </template>
        </b-table>
      </div>
      <div class="dash"></div>
      <div class="d-lg-flex mt-4">
        <div class="col-lg-8 col-12 px-0">
          <div class="d-flex align-items-center mb-3">
            <div class="sl-num">
              <p class="d-desc mb-0 text-white">2</p>
            </div>
            <p class="mb-0 d-title__2 text-danger__2 ml-2">Where to email shipping labels and invoices?</p>
          </div>
          <p class="d-desc" style="max-width: 313px; line-height: 2">
            Shipping Labels and Invoices will be emailed to <span class="text-danger__2">{{ profileEmail }}</span>
            within
            24-48 hours. If you'd like to add an email to receive the info above, add it below.
          </p>
          <div class="d-md-flex align-items-center">
            <!-- <b-select class="custom-input custom-select ad-em-sl" value="Add additional emails" :options="['Add additional emails']"></b-select> -->
            <!-- <p class="d-desc__8 gilroy-b text-danger__2 ml-md-3 mb-0">+ Add another email</p> -->
            <b-dropdown right class="menu-drp" menu-class="em-return-fil" ref="venDrp">
              <template #button-content>
                <div
                  class="select-wrapper__2 em-sel d-flex align-items-center justify-content-between px-2 cursor-pointer">
                  <p class="my-0 d-desc__8 mx-2">Add additional emails</p>
                  <img src="@/assets/images/svg_icon/down_caret_silver_grey.svg" alt="">
                </div>
              </template>
              <div class="cm-body">
                <div v-for="(em, eI) in otherEmails" :key="eI">
                  <div class="d-flex py-3 cursor-pointer" @click.capture.stop="toggleOtherEmail(eI)">
                    <div class="em-ck mr-2 px-2-px">
                      <img src="@/assets/images/pink_check_mark.png" alt=""
                        style="vertical-align: unset; max-width: 100%; max-height: 100%; margin-bottom: 1.5px"
                        v-if="em.checked">
                    </div>
                    <p class="my-0 d-desc__8 gilroy-b em-return-em-txt" style="color: #000; line-height: 2;">{{ em.email
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </b-dropdown>
          </div>
          <p class="d-desc text-success mt-3">{{ this.otherEmails.filter(e => e.checked).map(e => e.email).join(' , ')
            }}
          </p>
        </div>
        <div class="col-12 col-lg-4 px-0">
          <div class="d-flex align-items-center mb-3">
            <div class="sl-num">
              <p class="d-desc mb-0 text-white">3</p>
            </div>
            <p class="mb-0 d-title__2 text-danger__2 ml-2">Number of Shipping Labels</p>
          </div>
          <div class="d-flex">
            <p class="d-desc__8 mr-3 mb-0" style="max-width: 140px; line-height: 2">How many boxes will need shipping
              labels?</p>
            <b-select class="custom-input custom-select box-ship-lb-sl" v-model="shippingNumberValue"
              :options="[1, 2, 3, 4, 5]"></b-select>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mt-4">
        <Button text="Process Return" style="min-width: 160px" :submitting="returning" :onclick="processReturn" />
        <p class="d-desc mb-0 mt-3 f-10-px text-danger__2" v-if="returnErrorMsg">{{ returnErrorMsg }}</p>
        <p class="d-desc mb-0 mt-3 f-10-px text-success" v-else-if="returnSuccessMsg">{{ returnSuccessMsg }}</p>
      </div>
    </modal>
    <template>
      <!-- Existing component template... -->

      <!-- Add the modal component -->
      <b-modal v-model="isReorderModalVisible" title="Select Items to Reorder" size="lg" centered>
        <b-form>
          <b-form-group>
            <div v-for="order in filteredOrders" :key="order.id" class="d-flex align-items-center">
              <b-form-checkbox :id="'checkbox-' + order.id" v-model="selectedOrderItems[order.id]" :value="true"
                :unchecked-value="false" @change="isChecked => handleCheckboxChange(order.id, isChecked)">

                <span class="orderName f-13px"> {{ getName(order) }} </span>
                
                <span class="d-none"> Quantity: {{
        order.quantity }} </span>
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-btn variant="primary" @click="reOrder(selectedRow.item)">Reorder Selected Items</b-btn>
        </template>
      </b-modal>
    </template>

  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage'
import Button from '@/components/ui/Button'
import { mapGetters, mapActions, mapState } from 'vuex'
import BUS from '@/bus/BUS'
import OfficeMixin from '@/mixins/OfficeMixin'
import PlusMinus from '@/mixins/PlusMinus'
import Modal from '@/components/modals/Modal'
import BudgetType from './BudgetType.vue'
import FilterMixin from '@/mixins/Filter'
import { RouterView } from 'vue-router'
export default {
  components: { ProductImage, Button, Modal, BudgetType, },
  mixins: [OfficeMixin, PlusMinus, FilterMixin],
  props: {
    allItems: [],
    returnBtnClick: Boolean,
    order: Object,
    orders: Array,
    checkoutPage: Boolean,
    rejectReasonBtn: Boolean,
    rejectReasons: Array,
    prevReasons: Array,
    cartData: Array,
    invoiceBtn: Boolean,
    lastFilters: Object,
    showButtons: {
      type: Boolean,
      default: true
    },

    formErrors: {
      type: Object,
      default: {
        product_name: null,
        status: null,
        quantity: null,
        unit_price: null,
      }
    },
  },

  data: () => ({
    selectedItem: null,
    selectedRow: null,
    shippingNumberValue: 1,
    checkedItem: [],
    isReorderModalVisible: false,
    selectedOrderItems: {},
    reOrdering: false,
    msgs: [],
    returnProcessModal: false,
    otherEmails: [],
    orderItems: {},
    returning: false,
    returnSuccessMsg: null,
    returnErrorMsg: null,
    showUpdated: false,
    orderItemStatusList: [
      { value: 'pendingapproval', text: 'Pending Approval' },
      { value: 'Rejected', text: 'Rejected' },
      { value: 'processing', text: 'Processing' },
      { value: '', text: 'Processing' },
      { value: 'backordered', text: 'Back Ordered' },
      { value: 'returned', text: 'Returned' },
      { value: 'Return in process', text: 'Return in process' },
      { value: 'cancelled', text: 'Cancelled' },
      { value: 'received', text: 'Received' },
      { value: 'shipped', text: 'Shipped' },
      { value: 'delivered', text: 'Delivered' },
      { value: 'credited', text: 'Credited' },
      { value: 'maintenance', text: 'Repair/Maintenance' },
    ],
    submittingManualOrderProductId: null,
    deletingManualOrderProductId: null,
    // formErrors: {
    //   product_name: null,
    //   status: null,
    //   quantity: null,
    //   unit_price: null,
    // },
    showErrors: false,
  }),
  created() {
    this.setFormData()
    this.setPrevReasons()
    this.setOtherEmails()
    this.setOrderItems()
    this.clearValidation()
  },
  mounted() {
    this.clearValidation()
  },

  watch: {
    orders() { this.setFormData() },
    'orderFormData.items'(v) {
      this.$emit('order-item-change', v)
    },
    prevReasons(v) { this.setPrevReasons() },
    returnBtnClick(newValue) {
      if (newValue === true) {
        this.returnProcessModal = true; // Set the data property to true when the prop value changes to true
      } else {
        this.returnProcessModal = false;
      }
    }
  },

  computed: {
    ...mapGetters('USER', ['role', 'profileEmail']),
    ...mapGetters('FILTER', ['filters']),
    ...mapGetters('OFFICE', ['teamMembers']),
    ...mapState('OFFICE', ['orderFormData']),
    ...mapGetters('CART', ['cartProducts']),
    budgetCategories() {
      if (this.$store.state.OFFICE.officeBudget[this.officeId]) {
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if (subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if (subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if (subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        })
      }
      return []
    },
    reorderProcessFields() {
      return [
        // Define fields similar to 'returnProcessFields', adjusted for reordering context
        { key: 'name', label: 'Product Name', sortable: false },
        { key: 'actions', label: 'Select', sortable: false, 'class': 'text-center' },
      ];
    },

    showReorderButton() {
      return this.filteredOrders.length > 1;
    },
    submitForm() {
      // Implement your form submission logic here
    },
    vendorOrders() {
      return (this.order.vendor_orders.map(o => o.products)).flat()
    },
    filteredOrders() {
      if (this.orders) {
        return this.orders;
        // if (this.showButtons) {
        //   return orders;
        // } else {
        //   let selectedOrders = [];
        //   for (let order of this.orders) {
        //     if (order.quantity != null) {
        //       selectedOrders.push(order);
        //     }
        //   }
        //   return selectedOrders
        // }
      }
      else return this.vendorOrders
    },
    fields() {
      if (this.checkoutPage) return [
        { key: 'image', label: 'Product Image', sortable: false, tdClass: 'pr-img-td' },
        { key: 'name', label: 'Product Title', sortable: false, tdClass: 'name-desc-td__chk' },
        { key: 'quantity', label: 'Quantity Ordered', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total', label: 'Item Total', sortable: false, thClass: '', tdClass: '' },
      ]
      let fields = [
        // { key: 'image', label: 'Product Image', sortable: false, tdClass: 'pr-img-td' },
        { key: 'name', label: 'Product Name', sortable: false, tdClass: 'name-desc-td' },
        { key: 'quantity', label: 'Quantity Ordered', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'unit_total', label: 'Unit Price', sortable: false, thClass: '', tdClass: 'unit-total' },
        { key: 'total', label: 'Item Total', sortable: false, thClass: '', tdClass: 'item-total' },
        { key: 'status_display_text', label: 'Status', sortable: false, thClass: 'text-left', tdClass: 'text-left status-td' },
        { key: 'itemRcv', label: 'Received', sortable: false, thClass: 'text-center' },
        // { key: 'order_col', label: 'Order', sortable: false, thClass: 'text-center' },
      ]
      let actions = { key: 'actions', label: 'Budget Category', sortable: false, thClass: 'text-center', tdClass: 'text-center' }
      if (this.order && this.order.status == 'pendingapproval') fields.splice(5, 1)
      if (this.invoiceBtn) {
        // fields.push({ key: 'budget_category', label: 'Budget Category', sortable: false, thClass: 'text-center', tdClass: 'text-center position-relative' })
      } else {
        if (this.showButtons)
          fields.push(actions)
      }
      return fields
    },
    returnProcessFields() {
      let fields = [
        { key: 'image', label: 'Product Image', sortable: false, tdClass: 'pr-img-td' },
        { key: 'name', label: 'Product Title', sortable: false, tdClass: 'name-desc-td' },
        { key: 'quantity', label: 'Quantity', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total', label: 'Total', sortable: false, thClass: '', tdClass: 'item-total' },
        { key: 'actions', label: 'Return', sortable: false, thClass: 'text-center', tdClass: 'text-center' },
      ]
      return fields
    },
  },
  methods: {
    ...mapActions('CART', ['ADD_MULTIPLE_PRODUCTS_TO_CART', 'SAVE_FOR_LATER']),
    ...mapActions('PRODUCT', ['ORDER_STATUS_UPDATE', 'UPDATE_BUDGET_CATEGORY']),
    ...mapActions('ORDER', ['RETURN_ORDER', 'CREATE_MANUAL_ORDER_PRODUCT', 'DELETE_MANUAL_ORDER_PRODUCT']),
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET']),



    nex() {
      console.log('in bensnsh')
    },
    getStatus(row) {
      return row.value;
    },
    getName(order){
      return order.product_name;
    },
    async reOrder(row) {
      console.log(row)
      if (this.reOrdering) return
      this.selectedItem = row.item
      this.reOrdering = true
      try {
        let payload = {
          data: [],
          office: this.officeId
        }
        for (let i = 0; i < row.item.products.length; i++) {
          if (!this.checkedItems.includes(row.item.products[i].id)) {
            continue
          }
          let prod = row.item.products[i]

          let orderObj = this.cartItem(prod.product.id, true)
          let quantity = 1
          if (orderObj) {
            quantity += orderObj.quantity
            await this.update({
              office: this.officeId,
              cart: orderObj.id,
              quantity,
            })
          } else {
            payload.data.push({
              product: prod.product.id,
              office: this.officeId,
              unit_price: prod.unit_price,
              quantity
            })
          }

        }
        if (payload.data.length > 0) {
          let res = await this.ADD_MULTIPLE_PRODUCTS_TO_CART(payload)
        }
        this.$BUS.$emit('fetch-cart')
        BUS.$emit('open-cart-tab')
        this.selectedItem = null
      } catch (e) {
        console.log(e)
        if (Array.isArray(e)) this.msgs = e
        else this.msgs = [e]
        setTimeout(() => {
          this.selectedItem = null
        }, 2000)
      }
      this.reOrdering = false
    },

    handleModalChange(newValue) {
      if (!newValue) {
        this.closeModal();
      }
    },
    closeModal() {
      // Method to close the modal and set returnProcessModal to false
      this.$emit('modalClosed', false);
      this.returnProcessModal = false;
      this.returnBtnClick = false;
    },

    openShippingUrl(item) {
      if (item.tracking_link) {
        window.open(item.tracking_link, '_blank')
      }
    },
    isAlready(product) {
      for (let item of this.allItems) {
        let products = item.products;
        for (let p of products) {
          if (p.id == product.id) {
            return p.showForm;
          }
        }
      }
      return true;
    },

    // Method to format the unit_price input to not exceed two decimal places
    formatUnitPrice(item, value) {
      // Use a regular expression to match the input against the allowed format
      const regex = /^\d*(\.\d{0,2})?$/;
      if (regex.test(value)) {
        item.unit_price = value;
      } else {
        // If the input does not match the allowed format, revert to the last valid value
        // This trims the input to two decimal places if more are entered
        item.unit_price = parseFloat(value).toFixed(2);
      }
    },

    clearValidation() {
      this.formErrors.product_name = null;

      this.formErrors.status = null;
      this.formErrors.quantity = null;
      this.formErrors.unit_price = null;
    },

    // validateForm(item) {
    //   this.showErrors = true;
    //   let hasErrors = false;
    //   if (!this.validateProductName(item)) hasErrors = true;
    //   if (!this.validateStatus(item)) hasErrors = true;
    //   if (!this.validateQuantity(item)) hasErrors = true;
    //   if (!this.validateUnitPrice(item)) hasErrors = true;

    //   return hasErrors


    // },
    // validateProductName(item) {

    //   if (!item.product_name) {
    //     this.formErrors.product_name = "Field required";
    //     return false;
    //   }
    //   this.formErrors.product_name = null;
    //   return true;
    // },

    // validateStatus(item) {
    //   if (!item.status) {
    //     this.formErrors.status = "Field required";
    //     return false;
    //   }
    //   this.formErrors.status = null;
    //   return true;
    // },

    // validateQuantity(item) {
    //   if (!item.quantity || item.quantity < 1) {
    //     this.formErrors.quantity = "Greater than 0";
    //     return false;
    //   }
    //   this.formErrors.quantity = null;
    //   return true;
    // },
    quantityPlaceholderValue() {
      if (this.formErrors.quantity == null) {
        return "Quantity"
      } else {
        return this.formErrors.quantity;
      }
    },

    // validateUnitPrice(item) {
    //   if (!item.unit_price || item.unit_price < 0) {
    //     this.formErrors.unit_price = "Field required";
    //     return false;
    //   }
    //   this.formErrors.unit_price = null;
    //   return true;
    // },
    pricePlaceholderValue() {
      if (this.formErrors.unit_price == null) {
        return "Price"
      } else {
        return this.formErrors.unit_price;
      }
    },
    namePlaceholderValue() {
      if (this.formErrors.product_name == null) {
        return "Product Name"
      } else {
        return this.formErrors.product_name;
      }

    },

    setOrderItems() {
      this.filteredOrders.forEach(item => {
        this.orderItems[item.id] = {
          max_quantity: item.quantity,
          quantity: item.quantity,
          return: false,
        }
      })
    },
    increaseQ(id) {
      if (this.orderItems[id].quantity < this.orderItems[id].max_quantity) {
        this.orderItems[id].quantity = this.orderItems[id].quantity + 1
        this.$refs.reTable.$forceUpdate()
      }
    },
    decreaseQ(id) {
      if (this.orderItems[id].quantity > 0) {
        this.orderItems[id].quantity = this.orderItems[id].quantity - 1
        this.$refs.reTable.$forceUpdate()
      }
    },
    toggleOtherEmail(eI) {
      this.otherEmails[eI].checked = !this.otherEmails[eI].checked
    },
    setOtherEmails() {
      this.otherEmails = this.teamMembers.map(member => ({ email: member.email, checked: false, }))
    },
    toggleReturnProcessModal(v) {
      if (v != this.returnProcessModal) this.returnProcessModal = v || false
    },
    cartItem(productId, full) {
      if (!this.cartProducts) return null
      let cartProd = this.cartProducts.find(c => c.product.id == productId)
      if (cartProd) return full ? cartProd : cartProd.quantity
      return null
    },
    async onChangeReceivedToggle(v, item) {
      try {
        let payload = { office: this.officeId, product_id: item.id }
        if (v) payload.status = 'received'
        else {
          if (this.order.status == 'open') payload.status = 'processing'
          else payload.status = 'processing'
        }
        let res = await this.ORDER_STATUS_UPDATE(payload)
        this.$emit('status-updated', res, this.order)
      } catch (e) {
        console.log(e)
      }
    },
    isRejToggleOpen(id) {
      let tog = this.$refs[`rej-res-${id}`]
      if (tog) {
        return tog.visible
      }
      return false
    },
    async reOrder(item) {
      if (this.reOrdering) return
      this.selectedItem = item
      this.reOrdering = true
      try {
        let prod = item
        let orderObj = this.cartItem(prod.product.id, true)
        let quantity = 1
        if (orderObj) {
          quantity += orderObj.quantity
          if (orderObj.save_for_later) {
            let payload = {
              office: this.officeId,
              cart_id: orderObj.id,
              save_for_later: !orderObj.save_for_later
            }
            await this.SAVE_FOR_LATER(payload)
          }
          await this.update({
            office: this.officeId,
            cart: orderObj.id,
            quantity,
          })
          BUS.$emit('open-cart-tab')
        } else {
          let payload = {
            office: this.officeId,
            data: [{
              product: prod.product.id,
              office: this.officeId,
              quantity,
              unit_price: prod.unit_price
            }]
          }
          let res = await this.ADD_MULTIPLE_PRODUCTS_TO_CART(payload)
          this.$BUS.$emit('fetch-cart')
          BUS.$emit('open-cart-tab')
        }
      } catch (e) {
        if (Array.isArray(e)) this.msgs = e
        else this.msgs = [e]
      }
      this.selectedItem = null
      this.reOrdering = false
    },
    isRejecetedReasonPopOpen(item) {
      let el = this.$refs[`rej-res-${item.id}`]
      if (el != null) return el.visible
      else return false
    },
    async onChangeToggler(val, item) {
      if (!val) this.setRej({ value: null }, item)
      else {
        await this.$nextTick()
        this.$refs[`rej-res-${item.id}`].show(true)
      }
    },
    async checkRejRes(item) {
      await this.$nextTick()
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index < 0 || this.orderFormData.items[index].rejected_reason == null) {
        this.$refs[`chk-res-${item.id}`].$el.children[0].checked = false
        this.$forceUpdate()
      }
    },
    getRejectedReasonText(value) {
      let item = this.rejectReasons.find(r => r.value == value)
      if (item) return item.text
      return null
    },
    rejectReasonBtnText(item) {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1 && this.orderFormData.items[index].rejected_reason) {
        let reason = this.rejectReasons.find(r => r.value == this.orderFormData.items[index].rejected_reason)
        if (reason) return `Item Rejected: ${reason.text}`
      }
      return null
    },
    isSelected(reason, item, key = 'rejected_reason') {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      return index > -1 && this.orderFormData.items[index][key] && this.orderFormData.items[index][key] == reason.value
    },
    getBudgetType(item) {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1 && this.orderFormData.items[index]['budget_category'] != null) {
        const subaccount = this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.find(s => s.slug == this.orderFormData.items[index]['budget_category'])
        if (subaccount != null) return `${subaccount.name} Budget`
      }
      return `Budget Type`
    },
    isRejected(item, key = 'rejected_reason') {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      return index > -1 && this.orderFormData.items[index][key] != null
    },
    itemReason(item, key = 'rejected_reason') {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1 && this.orderFormData.items[index][key]) {
        let ress = this.rejectReasons
        if (key == 'budget_category') ress = this.budgetCategories
        let reason = ress.find(r => r.value == this.orderFormData.items[index][key])
        if (reason) return `${reason.text}`
      }
      return null
    },
    async setRej(reason, item, key = 'rejected_reason', ev) {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1) {
        if (this.orderFormData.items[index][key] != reason.value) this.orderFormData.items[index][key] = reason.value
        else this.orderFormData.items[index][key] = null
      }
      await this.$nextTick()
      if (key == 'rejected_reason') this.$refs[`rej-res-${item.id}`].hide(true)
      if (key == 'budget_category') this.$refs[`bud-cat-${item.id}`].hide(true)

      if (key == 'budget_category') this.updateBudgetCategory(item, reason, ev)
    },
    async updateBudgetCategory(item, reason, ev) {
      try {
        let payload = {
          office: this.officeId,
          product_id: item.id,
          budget_spend_type: reason.value,
          // filters: this.filters,
        }
        await this.UPDATE_BUDGET_CATEGORY(payload)
        this.onFilterChange(this.filters, flt => {
          this.CURRENT_MONTH_BUDGET({ office: this.officeId, filters: flt })
        })
        this.showUpdated = true
        setTimeout(() => {
          this.showUpdated = false
        }, 1000)
      } catch (e) { console.log(e) }
    },
    totalAmount(order, price) {
      const q = Number(order.quantity || 0)
      const p = Number(price || order.updated_unit_price || order.unit_price || 0)
      return (q * p).toFixed(2)
    },
    setFormData() {
      this.orderFormData.items = this.orders.map(item => {
        let it = this.orderFormData.items.find(i => i.order_product_id == item.id)
        let rejected_reason = null
        if (it) rejected_reason = it.rejected_reason
        return {
          order_product_id: item.id,
          rejected_reason,
          budget_category: item.budget_spend_type,
        }
      })
    },
    setPrevReasons() {
      if (this.prevReasons) this.orderFormData.items = this.prevReasons
    },
    async processReturn() {
      this.returning = true
      try {
        let return_items = [];
        let selectedProducts = [];

        this.order.products.forEach(productData => {
          let itemId = productData.id.toString();
          if (this.orderItems[itemId] && this.orderItems[itemId].return) {
              return_items.push(itemId); // Push the ID to return_items array

              let product = productData.product;

              // Create an object with selected product data and push it to selectedProducts array
              selectedProducts.push({
                  id: productData.id,
                  name: product.name,
                  product_id: product.product_id,
                  quantity: productData.quantity,
                  price: productData.unit_price
              });
          }
        });
        // Object.keys(this.orderItems).forEach(key => {
        //   if (this.orderItems[key].return) return_items.push(parseInt(key))
        // })
        if (return_items.length == 0) {
          this.returnErrorMsg = 'No items selected'
          this.returning = false
          return
        }
        let user_emails = this.otherEmails.filter(em => em.checked).map(em => em.email);
        user_emails.push(this.profileEmail);
        let shipping_number = this.shippingNumberValue;
        let vendor_order_id = this.order.vendor_order_id;
        let vendor_slug = this.order.vendor.slug;
        let order_id = this.order.order;
        
        // let products = this.order.products.map(productData => {
        //   return {
        //       name: productData.product.name,
        //       product_id: productData.product.product_id,
        //       quantity: productData.quantity,
        //       price: productData.unit_price
        //   };
        // });

        let payload = {
          return_items,
          user_emails,
          vendor_order_id,
          shipping_number,
          vendor_slug,
          products: selectedProducts, // Pass only selected product data
          order_id,
          orderId: this.order.id,
          office: this.officeId
        }
        await this.RETURN_ORDER(payload)
        this.returnSuccessMsg = 'Returned Successfully'
        return_items.forEach(id => {
          let item = this.order.products.find(p => p.id == id)
          if (item) this.$emit('status-updated', { ...item, status: 'Return in process', status_display_text: 'Return in process' }, this.order)
        })
        setTimeout(() => {
          //this.returnProcessModal = false
          this.closeModal();
        }, 1000)
      } catch (e) { console.log(e) }
      this.returning = false
    },
    async saveManualOrderProduct(item) {
      // if (item.quantity == null || item.unit_price == null || item.product_name == null) return
      let result = this.validateForm(item)
      this.$emit('set-validation-errors', result)
      if (!result) {
        this.submittingManualOrderProductId = item.id
        const payload = {
          office: this.officeId,
          order: item.order_id,
          quantity: item.quantity,
          unit_price: item.unit_price,
          status: item.status,
          product_name: item.product_name,
          budget_spend_type: item.budget_spend_type,
        }
        const { data } = await this.CREATE_MANUAL_ORDER_PRODUCT(payload)
        this.submittingManualOrderProductId = null
        this.$emit('added')
      }

    },
    async deleteManualOrderProduct(item) {

      this.clearValidation();

      this.deletingManualOrderProductId = item.id
      if (typeof item.id == 'string') {
        return this.$emit('delete', this.order.id, item.id)
      }
      const payload = {
        office: this.officeId,
        order_product_id: item.id,
        start_date: this.lastFilters.start_date,
        end_date: this.lastFilters.end_date,
      }
      const { data } = await this.DELETE_MANUAL_ORDER_PRODUCT(payload)
      this.$emit('delete', this.order.id, item.id)
      this.deletingManualOrderProductId = null
    },

    openReorderModal(row) {
      this.isReorderModalVisible = true;
      this.selectedRow = row;
      // Initialize or reset selections when opening the modal
      // this.filteredOrders.forEach(order => {
      //   this.$set(this.selectedOrderItems, order.id, false);
      // });
    },

    handleCheckboxChange(orderId, isChecked) {
      if (isChecked) {
        if (!this.selectedOrderItems.includes(orderId)) {
          this.selectedOrderItems.push(orderId);
        }
      } else {
        const index = this.selectedOrderItems.indexOf(orderId);
        if (index > -1) {
          this.selectedOrderItems.splice(index, 1);
        }
      }
    },


    async reOrderSelectedItems() {
      for (const orderId of this.selectedOrderItems) {
        const orderToReorder = this.filteredOrders.find(order => order.id === orderId);
        if (orderToReorder) {
          // Perform reordering logic here for each selected order
          // For example:
          await this.reOrder(orderToReorder); // Assuming reOrder can handle a single order object
        }
      }

      // After reordering, you might want to clear the selections or close the modal
      this.selectedOrderItems = []; // Clear selections
      this.isReorderModalVisible = false; // Close modal if needed
    },

  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/sl-num'
::v-deep .em-return-fil
  min-width: unset !important
  width: 200px
  .em-return-em-txt
    max-width: 150px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.em-ck
  min-width: 20px
  width: 20px
  height: 20px 
  border-radius: 4px
  border: solid 1px #ededed
.error-message::placeholder 
  color: red !important
  font-size: 10px
  margin-top: 2px
  
.em-sel
  height: 31px
  min-width: 200px
.box-ship-lb-sl
  max-width: 50px
  height: 35px !important
.order-items
  ::v-deep .images-sec
    width: 65px
    height: 60px
    border-radius: 4px
    border: solid 1px rgba(28, 69, 90, 0.1)
    background-color: #ebf4f8
  .ven-ck
    width: 20px
    min-width: 20px
    height: 20px
    border-radius: 4px
    border: solid 1px #ededed
    display: flex
    align-items: center
    justify-content: center
  .t-text
    font-size: 12px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    letter-spacing: normal
    text-align: left
    color: #707070
  .ven-image
    width: 24px
    height: 24px
    display: flex
    img
      width: inherit
      height: inherit
  ::v-deep table.tabl
    @media screen and (max-width: 992px)
      width: max-content
      min-width: 100%
    thead
      tr
        th
          background-color: #ebf4f8
          border-top: none
          border-bottom: none
          font-family: GILROY-SEMIBOLD
          font-size: 12px
          font-weight: 600
          font-stretch: normal
          font-style: normal
          line-height: 2
          text-align: left
          color: #1c455a
          padding-top: 7.75px
          padding-bottom: 7.75px
          &:first-child
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
          &:last-child 
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
    tbody
      tr
        td
          border-top: none
          border-bottom: none
          padding: 12px 0 0 0
          font-size: 12px
          font-weight: 500
          font-stretch: normal
          font-style: normal
          line-height: 2
          color: #000000
          &.pr-img-td
            @media screen and (min-width: 992px)
              width: 125px
          &.name-desc-td
            @media screen and (min-width: 992px)
              width: 246px
            p
              // max-width: 192px
              text-overflow: ellipsis
              overflow: hidden
              display: -webkit-box
              -webkit-line-clamp: 1
              -webkit-box-orient: vertical
            &__chk
              p
                max-width: 350px
          &.qty-td
            p
              min-width: 27px
              height: 25px
              width: min-content
              flex-grow: 0
              border-radius: 4px
              border: solid 1px #e6eaeb
              display: flex
              justify-content: center
              margin: 0 auto
          // &.item-total
          //   width: 150px
          // &.status-td
          //   width: 190px
          .custom-btn
            min-height: 28px
            min-width: 77px
            &:nth-child(2)
              margin-left: 8px
            @media screen and (max-width: 1350px)
              &:nth-child(2)
                margin-top: 5px
                margin-left: 0
    thead
      tr
        th
          background-color: transparent !important
          border-top: solid 1px #E5EAEC !important
          white-space: nowrap
    tbody
      tr
        td
          vertical-align: middle
          padding: 7.75px 19.8px 7.75px 12px !important
          border-top: solid 1px #E5EAEC !important
.re-order-btn
  background-color: #FF1DC3
  border-color: #FF1DC3
  width: auto
  padding: 4px 6px
  min-height: auto !important
  font-family: 'GILROY-MEDIUM'
  &:hover
    background-color: #13add9
    border-color: #13add9
.reorder-button-container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
.fs-12px
  font-size: 12px
.f-13px
  font-size: 13px
//.re-order-row ::v-deep tbody tr:not(:first-child) td:last-child 
//opacity: 0
//pointer-events: none

</style>

<!-- <style>
.input-readyonly .custom_input[type="number"]::-webkit-inner-spin-button,
.input-readyonly .custom_input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>  -->
<style>
select.hide_img {
    background-image: none; /* Remove default arrow in Firefox */
}
.modal-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  font-weight:700;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #000;
}
</style>