<template>
  <order-sync v-if="showSpin" title="Syncing Recent Order Data" desc="Now that you’ve linked your vendors we are pulling all your products ordered and creating your inventory list." />
  <recent-order-no-data class="h-100" v-else-if="!fetching && orders.length == 0" />
  <div class="recent-orders" v-else>
    <all-orders :hide-search-field="true" :onlyTable="true" :showTitle="true" :statusFilter="false" title="Recent Orders" :hover-color-change="false" :hide-header="true" class="px-3 py-3" :recent="true" @items="onItemFetched" />
  </div>
</template>

<script>
import GoTo from '@/mixins/GoTo'
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import RecentOrderNoData from './RecentOrderNoData.vue'
import AllOrders from './orders/AllOrders.vue'
import OrderSync from '@/components/dashboard/vendor-fetching/OrderSync'
export default {
  components: { RecentOrderNoData, AllOrders, OrderSync },
  mixins: [GoTo, OfficeMixin],
  data: () => ({
    orders: [],
    fields: [
      { key: 'name', label: 'Supplier Name', sortable: false },
      { key: 'order_date', label: 'Order Date', sortable: false },
      { key: 'total_items', label: '# Items', sortable: false },
      { key: 'total_amount', label: 'Order Total', sortable: false },
      { key: 'status', label: 'Status', sortable: false },
    ],
    fetching: true,
    timer: null,
    showSpin: false,
  }),
  created(){
    if(this.$route.query.start_pop == 'show') this.fetchOrders()
  },
  beforeDestroy(){
    if(this.timer) clearTimeout(this.timer)
  },
  methods: {
    ...mapActions('ORDER', ['FETCH_VENDOR_ORDERS']),
    onItemFetched(orders){
      this.orders = orders
      this.fetching = false
    },
    async fetchOrders(){
      let payload = {
        office: this.officeId,
      }
      let res = await this.FETCH_VENDOR_ORDERS(payload)
      if(res.total == 0) {
        this.showSpin = true
        this.timer = setTimeout(() => {
          this.fetchOrders()
        }, 30 * 1000)
      }else {
        if(this.timer) clearTimeout(this.timer)
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.recent-orders
  ::v-deep .custom-table
    thead
      tr
        th
          padding-top: 5px
          padding-bottom: 5px
  .order-btn
    font-size: 8px
    min-height: 29px
    height: 29px
    width: 87px
    padding: 0

</style>