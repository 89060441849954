<template>
  <div>
    <div class="bg">
      <div class="contents px-3">
        <p class="title-1 mx-auto text-white" style="max-width: 756px;">Ordo automatically pulls your order history from your vendors</p>
        <p class="subtitle-1 mx-auto mb-0 text-white" style="max-width: 590px;">By having all of your previous orders saved in Ordo, you can easily access items you regularly purchase</p>
      </div>
    </div>
    <div class="px-3">
      <div class="page-contents mx-auto">
        <p class="title-2">Ordo Automatically Pulls your Order History</p>
        <p class="subtitle-3 mb-3">When you sign up for Ordo, we pull your order history from all of the vendors you’ve linked. From day one, you will be able to access all the items you normally order in the “My Inventory” tab. We also save every item that you place moving forward so you can say goodbye to the days of having to spend hours looking through a vendor catalog for your favorite bur.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Imported_Order_History/imported_order_history_1.png" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">Upload custom photos</p>
        <p class="subtitle-3 mb-3">Any of us that have ordered before know that many items have generic, or even incorrect, images on the vendors website. Ordo took care of this problem by allowing you to upload custom photos of product images from your device straight into Ordo.</p>
        <p class="mb-3 text-center">
          <img src="./features_images/Imported_Order_History/imported_order_history_2.png" alt="" class="" style="max-width: 100%">
        </p>
        <p class="title-2">Create nicknames for items</p>
        <p class="subtitle-3 mb-3">How many times have we told our assistant to order our favorite bur and then we can’t remember the name of it, or it has some slang term like mosquito bur? With Ordo’s “add a nickname” feature, you can easily rename your 557 bur to “doc’s favorite bur” and now your assistants will easily be able to find it and order it for you.</p>
        <p class="text-center mb-5">
          <img src="./features_images/Imported_Order_History/imported_ordered_history_3.png" alt="" class="" style="max-width: 100%">
        </p>
        <Lan9 class="pb-0 pt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Lan9 from '@/components/landing/Lan9.vue'
export default {
  components: { Button, Lan9, }
}
</script>

<style lang="sass" scoped>
@import ./_features
.bg
  background-image: url(./cover_images/four.svg)
</style>