<template>
  <div class="card">
    <div class="card-header">
      <h2>{{ title }}</h2>
    </div>
    <div class="card-body">
      <div :class="{ 'circle-background': isCircular, 'rounded-background': !isCircular }">
        <p class="number">{{ number }}</p>
      </div>
      <div class="center-text" v-if="!isCircular">
        <p class="subTitle">{{ subTitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    title: String,
    subTitle: String,
    isCircular: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.card {
  width: 340px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #13ADD9;
  color: white;
  padding: 15px;
  font-size: 13px;
  text-align: center;
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-background {
  background-color: #13ADD9;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.rounded-background {
  background-color: #13ADD9;
  border-radius: 16px; 
  width: 130px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.number {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.center-text {
  text-align: center;
}

.title,
.subTitle {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
