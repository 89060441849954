import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('USER', ['offices']),
    office(){
      return this.offices[this.officeIndex]
    },
    officeId(){
      let office = this.offices[this.officeIndex]
      if(office) return office.id
      return null
    },
    officeIndex(){
      return this.$route.params.office
    },
    filteredOffices(){
      return this.offices.filter((o, i) => i != this.officeIndex)
    },
    officeAddress(){
      if(this.office.addresses && this.office.addresses.length > 0){
        let ad = this.office.addresses[0]
        return `${ad.address}, ${ad.city}, ${ad.state} - ${ad.zip_code}`
      }
      return null
    },
  },
}