<template>
  <div class="previous-orders-invoices">
    <div class="d-lg-flex justify-content-between mb-3">
      <div class="d-lg-flex align-items-center">
        <div class="title-part mb-3 mb-lg-0" v-if="showTitle">
          <b-tooltip :target="`finance-tooltip`" triggers="hover focus" placement="topleft" boundary="document"
            custom-class="promotion-tooltip tooltip-size-md">
            <p class="d-desc mb-0">Please note that unavailable invoices on Ordo mean that the vendor has not yet posted
              them on their site. Once they become available from the vendor, they will be displayed on Ordo. Thank you
              for your patience and understanding.</p>
          </b-tooltip>
          <p class="t-text mb-0 pl-2-px">
            <span class="mb-0 d-title">Previous Invoices and Credit Memos</span>
            <img src="@/assets/images/information.png" alt="" class="ml-2 mb-1 h-20-px w-20-px" :id="`finance-tooltip`" />
          </p>
        </div>
      </div>
      <b-form @submit.prevent="search" v-if="!hideSearchField">
        <div class="d-flex align-items-center search-wrapper w-100">
          <b-input v-model="q" class="custom-input no-border-inp bg-transparent w-100 pr-1"
            placeholder="Enter order nickname, order date, product name"></b-input>
          <span class="f-10-px text-danger__2 cursor-pointer" style="width: 40px" @click="resetFilter"
            v-if="q && q.length > 0">Clear</span>
          <img src="@/assets/images/magnifying-glass-tilted.png" alt="" style="height: 20px; width: 20px"
            class="ml-1 mr-2 cursor-pointer" @click="search">
        </div>
      </b-form>
    </div>
    <div class="table-section">
      <b-table :items="items" :fields="fields" :thead-class="thClass" table-class="c-table" @row-clicked="onRowClick"
        show-empty :busy="fetching">
        <template #cell(name)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">
            <div class="supplier-name d-flex align-items-center">
              <div class="avatar__super-mini mr-2">
                <img :src="row.item.vendor.logo" alt="" class="w-100 h-100" v-if="row.item.vendor.logo">
                <span v-else>{{ row.item.vendor.name[0] }}</span>
              </div>
              <span class="d-title__3 ml-0">{{ row.item.vendor.name }}</span>
            </div>
          </div>
        </template>
        <template #cell(id)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">{{ row.item.id }}</div>
        </template>
        <template #cell(nickname)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">
            <div v-if="row.item.nickname" class="bx__1 d-flex align-items-center justify-content-between w-100 px-2">
              <p class="mb-0 nickname">{{ row.item.nickname }}</p>
              <p class="ml-2 mb-0 text-primary cursor-pointer" @click.stop="editNickName($event, row.item)">Edit</p>
            </div>
            <div class="w-100" v-else>
              <p class="mb-0 text-primary cursor-pointer" @click.stop="onAddNicknameClick">+Add</p>
              <div class="bx__1 pr-2" style="display: none;">
                <b-input class="custom-input no-border-inp table-input"></b-input>
                <b-spinner style="width: 0.5rem; height: 0.38rem; border-width: 1px;" class="mr-1 text-primary"
                  v-if="submittingNickName && submittingNickNameFor && submittingNickNameFor.id == row.item.id"></b-spinner>
                <p class="mb-0 l-h-1 text-primary cursor-pointer f-10-px" @click.stop="submitNickName($event, row.item)">
                  Save</p>
              </div>
            </div>
          </div>
        </template>
        <template #cell(order_date)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">{{ formatDateTime(row.item.order_date)
          }}
          </div>
        </template>
        <template #cell(vendor_order_id)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">{{ row.item.vendor_order_id }}</div>
        </template>
        <template #cell(total_items)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">
            <p class="mb-0">{{ row.item.total_items }}</p>
          </div>
        </template>
        <template #cell(total_amount)="row">
          <div :class="['inner', { 'hover-color-changeable': hoverColorChange }]">
            <span class="pl-2-px">${{ row.item.total_amount }}</span>
          </div>
        </template>
        <template #cell(status_display_text)="row">
          <div :class="['inner d-flex align-items-center', { 'hover-color-changeable': hoverColorChange }]">
            <!-- <div :class="['status-indicator mr-2', {'one': row.item.status == 'Shipped' || row.item.status == 'COMPLETE'},{'two': row.item.status == 'Processing'},{'three': row.item.status == 'Refund'},]">
              <div class="indc"></div>
            </div> -->
            <span :class="['st-text']" style="width: 100px">{{ row.item.status_display_text }}</span>
            <!-- <img src="@/assets/images/svg_icon/down_caret.svg" alt="" class="ml-2" v-if="!row.detailsShowing">
            <img src="@/assets/images/svg_icon/down_caret.svg" alt="" class="ml-2" style="transform: rotateZ(180deg)" v-else> -->
          </div>
        </template>
        <template #cell(action)="row">
          <div :class="['inner justify-content-end align-items-center', { 'hover-color-changeable': hoverColorChange }]">
            <p class="text-right d-desc text-danger__2 mt-1 mb-0" v-if="invoiceErrorMsg">{{ invoiceErrorMsg }}</p>
            <!-- <p class="text-right d-desc text-danger__2 mt-1 mb-0" v-if="vendorNotConnectedMessage  && errorOrderId == row.item.id">{{ vendorNotConnectedMessage }}</p> -->
            <!-- <b-btn class="custom-btn success supp-add-card-btn"
              v-if="vendorNotConnectedMessage && errorOrderId == row.item.id"
              @click="goTo({ name: 'Settings', hash: '#Lnk' })">Link a Vendor</b-btn> -->
            <Button v-if="vendorNotConnectedMessage && errorOrderId == row.item.id" text='Not available'
              classes="re-order-btn" :class="[row.item.is_invoice_available === false && 'disabled']"
              :onclick="() => navigateToSettings()"
              />
            <Button
              :text="`${downloadingInvoice && selectedOrder.id == row.item.id ? 'Downloading' : row.item.is_invoice_available === false ? 'Not available' : 'Download'}`"
              classes="re-order-btn" :class="[row.item.is_invoice_available === false && 'disabled']"
              :onclick="() => downloadInvoice(row.item)"
              :submitting="downloadingInvoice && selectedOrder.id == row.item.id" v-else />
          </div>
        </template>
        <template #row-details="{ item }">
          <div class="order-details" style="padding-top: 0 !important;">
            <div class="w-100 pr-2 pt-2" v-if="selectedItem && selectedItem.id == item.id && msgs.length > 0">
              <p class="text-right d-desc text-danger__2 my-2" v-for="(msg, mI) in msgs" :key="mI">{{ msg }}</p>
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center mb-3" @click="onRowClick(item)">
              <p class="mb-0 d-title__6 cursor-pointer">Order : #{{item.id}} Items</p>
              <p class="mb-0 d-title__6 cursor-pointer" style="color: #1c455a;">Close Drop Down</p>
            </div> -->
            <order-items :invoice-btn="invoiceBtn" :order="item" :orders="item.products"
              :reject-reason-btn="item.status == 'pendingapproval'" :rejectReasons="rejectReasons"
              @order-item-change="v => onOrderItemChange(v, item)" :prevReasons="orderItems[item.id]"
              @status-updated="onStatusUpdated" :cartData="cartData" />
            <div v-if="item.status == 'pendingapproval' && role == 1">
              <div class="dash"></div>
              <div class="d-flex justify-content-end py-3 px-3">
                <Button style="min-width: 74px" :text="`Submit`" classes="re-order-btn"
                  :onclick="() => approveOrder(item)" :submitting="approvingOrder && selectedOrder.id == item.id" />
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="no-orders">
            <p class="d-desc text-center mx-auto my-4" style="max-width: 300px" v-if="q && q.trim().length > 0">No orders
              found for "{{ q }}".</p>
            <div v-else-if="status">
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-if="status == 'open'">There are no
                open orders during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else-if="status == 'closed'">There
                are no closed orders during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px"
                v-else-if="status == 'pendingapproval'">No orders are pending approval during this period</p>
              <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else>There are no orders during this
                period</p>
              <!-- <p class="d-desc text-center mx-auto mb-4" style="max-width: 300px">Currently no orders have been {{status.toLowerCase()}}. Once the status of an order updates to {{status.toLowerCase()}}.</p> -->
            </div>
            <p class="d-title text-center mx-auto my-4" style="max-width: 300px" v-else>There are no orders during this
              period</p>
          </div>
        </template>
        <template #table-busy>
          <div style="height: 250px">
            <grow-loader />
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination class="custom-pagination" v-if="!onlyTable && items.length > 0 && !fetching"
      v-model="tableSettings.currentPage" :total-rows="tableSettings.rows" :per-page="tableSettings.perPage"
      align="center" hide-ellipsis hide-goto-end-buttons />
    <div v-if="approvalModalDiv">
      <modal :modal="approvalModal" @change="toggleApprovalModal" size="lg" c-classes="px-0 py-4 order-approval-md"
        dialog-class="order-approval-modal">
        <div class="px-3">
          <p class="d-title mb-0" v-if="items.length > 0">Order Approval Required</p>
          <order-approval th-class="d-none" :hide-header="true" :hover-color-change="false"
            :ids="$route.query.order_approval_reject" />
        </div>
      </modal>
    </div>
    <modal :modal="orderApprovedModal" @change="toggleOrderApprovedModal" size="md" c-classes="order-app-or-rej-modal">
      <div class="d-flex justify-content-center mb-40-px">
        <logo class="logo" />
      </div>
      <p class="text-center d-title text-danger__2">Order approval processed</p>
      <p class="text-center d-desc mb-4">We've processed your order approval, users of your office will receive an email
        with the updated status of this order</p>
      <div class="d-flex justify-content-center">
        <p class="text-center cursor-pointer text-primary d-title"
          @click="v => { orderApprovedModal = false; approvalModal = false; }">View Order Status</p>
      </div>
    </modal>
    <modal :modal="orderRejectionModal" @change="toggleOrderRejectionModal" size="md" c-classes="order-app-or-rej-modal">
      <div v-if="selectedOrder">
        <validation-observer ref="formObs" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(rejectOrder)">
            <div class="d-flex justify-content-center mb-40-px">
              <logo class="logo" />
            </div>
            <p class="text-center d-title text-danger__2">Reject the {{ selectedOrder.vendor.name }} order placed on
              {{ formatDateTime(selectedOrder.order_date) }}</p>
            <p class="text-center d-desc mb-4">If you have a specific reason as to why you are rejecting the order you can
              leave your comments below, or just cliick “Reject Order” to process the rejection without comments</p>
            <validation-provider name="Reason" rules="required" v-slot="validationContext">
              <b-textarea class="custom-input-textarea" placeholder="Input order rejection notes..." :rows="5" no-resize
                v-model="rejected_reason" :state="getValidationState(validationContext)"></b-textarea>
              <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
              }}</b-form-invalid-feedback>
            </validation-provider>
            <div class="d-flex flex-column align-items-center justify-content-center mt-4">
              <Button text="Reject Order" style="min-width: 150px" classes="custom-btn danger__2" :onclick="rejectOrder"
                :submitting="rejectingOrder" />
              <p class="text-center cursor-pointer text-primary d-title f-12-px mt-3"
                @click="v => { orderRejectionModal = false; }">Close</p>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </modal>
    <modal :modal="conInfoModal" @change="toggleConInfoModal" size="md" dialog-class="vendor-contact-info-modal">
      <vendor-contact-info-form @close="v => toggleConInfoModal(false)" :venInfo="contactInfoForSelectedVendor" />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import PlusMinus from '@/mixins/PlusMinus'
import OrderItems from '@/components/dashboard/orders/OrderItems'
import Button from '@/components/ui/Button'
import moment from 'moment'
import Modal from '@/components/modals/Modal'
import OrderApproval from './OrderApproval'
import Logo from '@/components/logo/Logo'
import FormMixin from '@/mixins/FormMixin'
import GrowLoader from '@/components/loaders/GrowLoader'
import BUS from '@/bus/BUS'
import VendorContactInfoForm from '@/components/dashboard/settings/VendorContactInfoForm'
export default {
  name: 'AllOrders',
  mixins: [OfficeMixin, FormMixin, PlusMinus],
  components: {
    OrderApproval,
    OrderItems,
    Button,
    Modal,
    Logo,
    GrowLoader,
    VendorContactInfoForm,
  },
  props: {
    hideHeader: {
      type: Boolean,
    },
    statusFilter: {
      type: Boolean,
      default: true,
    },
    dateFilters: {
      type: Boolean,
      default: true,
    },
    onlyTable: {
      type: Boolean,
      default: false,
    },
    'invoice-btn': Boolean,
    'hover-color-change': { type: Boolean, default: true },
    approvalModalDiv: { type: Boolean },
    thClass: { type: String, default: '' },
    ids: { type: Array, },
    recent: { type: Boolean, },
    showTitle: { type: Boolean, },
    hideSearchField: { type: Boolean, },
  },
  data: () => ({
    orders: [],
    items: [],
    fetching: true,
    tableSettings: {
      rows: 100,
      perPage: 5,
      currentPage: 1
    },
    downloadingInvoice: false,
    vendorNotConnectedMessage: null,
    errorOrderId: -1,
    approvingOrder: false,
    rejectingOrder: false,
    status: null,
    approvalModal: false,
    rejectReasons: [
      { value: 'noneed', text: 'Not Needed' },
      { value: 'wrong', text: 'Wrong Item' },
      { value: 'expensive', text: 'Too Expensive' },
      { value: 'other', text: 'Other' },
    ],
    orderApprovedModal: false,
    orderRejectionModal: false,
    selectedOrder: null,
    orderItems: {},
    rejected_reason: null,
    selectedItem: null,
    reOrdering: false,
    msgs: [],
    cartData: [],
    q: null,
    lastFilters: {},
    submittingNickNameFor: null,
    submittingNickName: false,
    contactInfoForSelectedVendor: null,
    conInfoModal: false,
    invoiceErrorMsg: null,


  }),
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change')
    this.$BUS.$off('cart-data')
  },

  created() {
    this.fetchMembers()
    if (this.recent) this.fetchOrders(true, undefined, {
      per_page: 5
    })
    else {
      this.lastFilters = {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
      }
      this.fetchOrders(true, undefined, this.lastFilters)
    }
    if (!this.recent) this.$BUS.$on('filter:filter-change', this.onFilterChange)
    if (this.$route.query.order_approval_reject) {
      this.approvalModal = true
    }
    this.$BUS.$on('cart-data', v => this.cartData = v)
  },
  watch: {
    'tableSettings.currentPage'(v) {
      this.fetchOrders(true, v, this.lastFilters)
    },
    status(v) {
      if (this.tableSettings.currentPage != 1) this.tableSettings.currentPage = 1
      else this.fetchOrders(true, undefined, this.lastFilters)
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    ...mapGetters('OFFICE', ['connected_vendors']),
    fields() {
      // if(this.onlyTable) return [
      //   { key: 'name', label: 'Vendor Name', sortable: false },
      //   { key: 'order_date', label: 'Order Date', sortable: false },
      //   { key: 'total_items', label: '# Items', sortable: false },
      //   { key: 'total_amount', label: 'Order Total', sortable: false },
      //   { key: 'status', label: 'Status', sortable: false },
      // ]
      if (this.invoiceBtn) {
        return [
          { key: 'order_date', label: 'Date', sortable: false, tdClass: 'w-25', },
          { key: 'name', label: 'Vendor', sortable: false, tdClass: 'w-25', },
          { key: 'total_amount', label: 'Total', sortable: false, tdClass: 'w-25' },
          { key: 'action', label: '', sortable: false, tdClass: 'w-25', },
        ]
      }
      return [
        // { key: 'id', label: 'Order Number', sortable: false },
        { key: 'order_date', label: 'Order Date', sortable: false, tdClass: 'order-date' },
        // { key: 'vendor_order_id', label: 'Order Number', sortable: false, tdClass: 'order-number' },
        { key: 'nickname', label: 'Order Nickname', sortable: false, tdClass: 'order-nickname', },
        { key: 'name', label: 'Vendor', sortable: false, tdClass: 'vendor-name' },
        { key: 'total_items', label: 'Quantity', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total_amount', label: 'Order Total', sortable: false, tdClass: 'order-total-td' },
        { key: 'status_display_text', label: 'Status', sortable: false },
        { key: 'action', label: 'Order Action', sortable: false, thClass: 'text-center' },
      ]
    },
  },
  methods: {
    ...mapActions('ORDER', ['GET_INVOICE', 'FETCH_VENDOR_ORDERS', 'APPROVE_ORDER', 'UPDATE_ORDER']),
    ...mapActions('CART', ['ADD_MULTIPLE_PRODUCTS_TO_CART']),
    ...mapActions('OFFICE', ['TEAM_MEMBERS', 'DELETE_MEMBER']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS']),
    async fetchMembers() {
      try {
        this.members = await this.TEAM_MEMBERS({ office: this.officeId })
      } catch (e) {
        console.log(e)
      }
    },
    resetFilter() {
      this.q = null
      this.search()
    },
    onAddNicknameClick(ev) {
      let parent = ev.target.parentElement
      parent.children[0].style.display = 'none'
      parent.children[1].style.display = 'flex'
    },
    openContactInfoModal(vendor) {
      if (vendor.slug == 'amazon') return
      let ven = this.connected_vendors.find(v => v.vendor.slug === vendor.slug)
      if (ven) {
        this.contactInfoForSelectedVendor = {
          ...ven
        }
        this.conInfoModal = true
      }
    },
    toggleConInfoModal(v) {
      if (v != this.conInfoModal) this.conInfoModal = v || false
    },
    getContactInfo(slug) {
      if (slug == 'net_32') return { email: 'support@net32.com', phone_number: '(919) 205-0448' }
      else if (slug == 'henry_schein') return { email: 'custserv@henryschein.com', phone_number: '1-800-372-4346, Extension 2' }
      else if (slug == 'darby') return { email: 'dawn.sharma@darby.com', phone_number: '(516) 688-6800 Ext. (4213)' }
      else if (slug == 'ultradent') return { email: 'onlineordersupport@ultradent.com', phone_number: '888.230.1420' }
      else if (slug == 'patterson') return { email: 'ptc.support@pattersoncompanies.com', phone_number: '1.866.449.7282' }
      else if (slug == 'implant_direct') return { email: 'css@implantdirect.com', phone_number: '1-888-649-6425' }
      else if (slug == 'edge_endo') return { email: 'edgehr@edgeendo.com', phone_number: '855-985-3636' }
      else if (slug == 'benco') return { email: 'N/A', phone_number: '1-800-462-3626' }
      else if (slug == 'dental_city') return { email: 'help@DentalCity.com', phone_number: '1-800-353-9595' }

      return null
    },
    getContactPhone(slug) {
      let info = this.getContactInfo(slug)
      if (info) return info.phone_number
    },
    getContactEmail(slug) {
      let info = this.getContactInfo(slug)
      if (info) return info.email
    },
    async editNickName(ev, item) {
      let index = this.items.findIndex(i => i.id == item.id)
      if (index > -1) {
        let nickname = this.items[index].nickname
        this.items[index].nickname = null
        let inner = ev.target.parentElement.parentElement
        await this.$nextTick()
        inner.children[0].children[0].style.display = 'none'
        inner.children[0].children[1].style.display = 'flex'
        setTimeout(() => {
          inner.children[0].children[1].children[0].value = nickname
        }, 100)
      }
    },
    async submitNickName(ev, item) {
      this.submittingNickNameFor = item
      this.submittingNickName = true
      try {
        let parent = ev.target.parentElement.parentElement
        let nickname = ev.target.parentElement.children[0].value
        if (nickname.trim().length == 0) nickname = null
        let payload = {
          office: this.officeId,
          orderId: item.id,
          nickname,
        }
        let res = await this.UPDATE_ORDER(payload)
        let index = this.items.findIndex(i => i.id == item.id)
        if (index > -1) {
          this.items[index].nickname = nickname
          // this.orders[index].nickname = nickname
          await this.$nextTick()
          setTimeout(() => {
            parent.children[0].style.display = 'block'
            if (res.data.data.nickname == null) parent.children[1].style.display = 'none'
          }, 100)
        }
      } catch (e) {
        console.log(e)
      }
      this.submittingNickName = false
      this.submittingNickNameFor = null
    },
    cartItem(productId, full) {
      if (!this.cartData) return null
      let cartProd = this.cartData.find(c => c.product.id == productId)
      if (cartProd) return full ? cartProd : cartProd.quantity
      return null
    },
    onStatusUpdated(item, order) {
      let orderIndex = this.items.findIndex(o => o.id == order.id)
      if (orderIndex > -1) {
        let itemIndex = this.items[orderIndex].products.findIndex(p => p.id == item.id)
        if (itemIndex > -1) {
          this.items[orderIndex].products[itemIndex].status = item.status
          this.items[orderIndex].products[itemIndex].status_display_text = item.status_display_text
          if ((this.items[orderIndex].products.filter(p => p.status == 'received')).length == this.items[orderIndex].products.length) {
            this.items[orderIndex].status = 'complete'
            this.items[orderIndex].status_display_text = 'Complete'
          }
          // else {
          //   this.items[orderIndex].status = this.orders[orderIndex].status
          //   this.items[orderIndex].status_display_text = this.orders[orderIndex].status_display_text
          // }
        }
      }
    },
    async reOrder(row) {
      if (this.reOrdering) return
      this.selectedItem = row.item
      this.reOrdering = true
      try {
        let payload = {
          data: [],
          office: this.officeId
        }
        for (let i = 0; i < row.item.products.length; i++) {
          let prod = row.item.products[i]

          let orderObj = this.cartItem(prod.product.id, true)
          let quantity = 1
          if (orderObj) {
            quantity += orderObj.quantity
            await this.update({
              office: this.officeId,
              cart: orderObj.id,
              quantity,
            })
          } else {
            payload.data.push({
              product: prod.product.id,
              office: this.officeId,
              quantity
            })
          }

        }
        if (payload.data.length > 0) {
          let res = await this.ADD_MULTIPLE_PRODUCTS_TO_CART(payload)
        }
        this.$BUS.$emit('fetch-cart')
        BUS.$emit('open-cart-tab')
        this.selectedItem = null
      } catch (e) {
        console.log(e)
        if (Array.isArray(e)) this.msgs = e
        else this.msgs = [e]
        setTimeout(() => {
          this.selectedItem = null
        }, 2000)
      }
      this.reOrdering = false
    },
    async search() {
      this.fetchOrders(false, undefined, this.lastFilters)
    },
    onOrderItemChange(v, item) {
      this.orderItems[item.id] = v
    },
    toggleApprovalModal(v) {
      if (v != this.approvalModal) this.approvalModal = v != null ? v : false
    },
    toggleOrderApprovedModal(v) {
      if (v != this.orderApprovedModal) this.orderApprovedModal = v != null ? v : false
    },
    toggleOrderRejectionModal(v) {
      if (v != this.orderRejectionModal) this.orderRejectionModal = v != null ? v : false
      this.rejected_reason = null
    },
    setStatus(v) {
      if (this.status != v) {
        this.status = v
      }
      else {
        this.status = null
      }
      if (this.lastFilters.status != v && v != null) this.lastFilters.status = v
      else delete this.lastFilters.status
    },
    vendorsPerOrder(item) {
      let vendors = ((item.vendor_orders.map(vo => vo.products.map(p => p.product.vendor))).flat()).filter(f => f)
      let ids = (vendors.map(v => v.id)).filter((value, index, self) => self.indexOf(value) === index)
      return ids.map(id => vendors.find(v => v.id == id))
    },
    onRowClick(row) {
      if (this.invoiceBtn == true) return
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.items.forEach(item => {
          this.$set(item, '_showDetails', false)
        })
        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    async onFilterChange(filters) {
      let obj = {}
      if (filters.budget_type) obj.budget_type = filters.budget_type
      // if(filters.date_range) obj.date_range = filters.date_range
      if (filters.selectedDate.startDate && filters.selectedDate.endDate) {
        let sta = moment(filters.selectedDate.startDate).format('YYYY-MM-DD')
        let end = moment(filters.selectedDate.endDate).format('YYYY-MM-DD')
        obj.start_date = sta
        obj.end_date = end
      }
      if (
        (filters.selectedDate.startDate && filters.selectedDate.endDate)
        || (!filters.selectedDate.startDate && !filters.selectedDate.endDate)
      ) {
        this.lastFilters = obj
        this.fetchOrders(true, undefined, obj)
      }
    },
    async fetchOrders(fetching = true, page = undefined, filters = {}) {
      this.fetching = fetching
      try {
        let payload = {
          office: this.officeId,
          filters,
        }
        payload.filters.disable_approval = true
        payload.filters.per_page = 10
        if (page) payload.filters.page = page
        if (this.status) payload.filters.status = this.status
        if (this.ids) payload.filters.ids = this.ids
        if (this.q && this.q.trim().length > 0) payload.filters.q = this.q
        else delete payload.filters.q
        let res = await this.FETCH_VENDOR_ORDERS(payload)
        this.tableSettings.rows = res.total
        this.tableSettings.perPage = res.per_page
        this.tableSettings.currentPage = res.current_page
        this.items = this.onlyTable ? res.data.splice(0, 15) : res.data
        this.orders = this.onlyTable ? res.data.splice(0, 15) : res.data
        this.$emit('items', this.items)
        if (this.status == null && res.data.length == 0 && Object.keys(payload.filters).length == 0) this.$emit('show-empty')
      } catch (e) { }
      this.fetching = false
    },
    str2bytes(str) {
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },
    navigateToSettings() {
      console.log('in navigate settings -----------')
    this.$router.push({ name: 'Settings', hash: '#Lnk' });
  },

    async downloadInvoice(order) {
      if (order.is_invoice_available) {
        this.selectedOrder = order
        this.downloadingInvoice = true
        try {
          let res = await this.GET_INVOICE({ office: this.officeId, orderId: order.id })


          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a')
          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
        } catch (e) {
          this.vendorNotConnectedMessage = "Vendor Not Connected"
          this.errorOrderId = order.id

          console.log(e)
        }
        this.downloadingInvoice = false
        this.selectedOrder = null
      }
      // }
    },
    async showRejectOrderModal(order) {
      this.selectedOrder = order
      this.orderRejectionModal = true
    },
    async approveOrder(order) {
      this.selectedOrder = order
      this.approvingOrder = true
      try {
        let payload = {
          office: this.officeId,
          orderId: order.id,
          is_approved: true,
        }
        if (this.orderItems[order.id]) {
          payload.rejected_items = this.orderItems[order.id].filter(i => i.rejected_reason != null)
        } else {
          payload.rejected_items = []
        }
        let res = await this.APPROVE_ORDER(payload)
        this.fetchOrders()
        this.orderApprovedModal = true
      } catch (e) {
        console.log(e)
      }
      this.approvingOrder = false
      this.selectedOrder = null
    },
    async rejectOrder(order) {
      this.rejectingOrder = true
      try {
        let payload = {
          office: this.officeId,
          orderId: this.selectedOrder.id,
          is_approved: false,
          rejected_reason: this.rejected_reason
        }
        let res = await this.APPROVE_ORDER(payload)
        this.fetchOrders()
        this.toggleOrderRejectionModal(false)
      } catch (e) {
        console.log(e)
      }
      this.rejectingOrder = false
      this.selectedOrder = null
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d, 'YYYY-MM-DD').format("MM/DD/YY");
    },
  },
}
</script>

<style lang="sass" scoped>

.no-orders
  background-image: url(../../../assets/images/no_order_img.png)
  background-size: 80% 100%
  background-position-x: 40%
  background-repeat: no-repeat
  min-height: 250px
  @media (min-width: 992px)
    padding-top: 80px
  @media (max-width: 992px)
    min-height: unset
    background-image: unset
    background-size: unset
.bx-wrapper
  height: 40px
  border-radius: 4px
  border: solid 1px #e9e9e9
  .custom-input
    height: 100% !important
.bx
  height: 28px
  display: flex
  justify-content: space-between
  align-items: center
  border-radius: 4px
  border: solid 1px #eaeaeb
  // background-color: #fafafb
  .custom-input
    height: 100% !important
  &__1
    @extend .bx
    min-width: 120px
    width: 100%
.search-wrapper
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 4px
  height: 38px
  @media (min-width: 992px)
    max-width: 280px
  @media (min-width: 1200px)
    max-width: unset
    min-width: 280px
  .custom-input
    &::placeholder
      font-size: 10px
.order-app-or-rej-modal
  .logo
    width: 115px
    height: 32px
::v-deep .rej-res-sel
  min-width: 150px !important
::v-deep .bud-cat-sel
  min-width: 200px !important
// ::v-deep .custom-modal
//   &.order-approval-md
.view-btn
  min-height: 29px
  min-width: 54px
  border-radius: 4px
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  font-size: 12px
  font-family: GILROY-BOLD
  font-stretch: normal
  font-style: normal
  line-height: normal
.previous-orders-invoices
  ::v-deep .b-table-has-details
    box-shadow: 0 0 50px 0 rgba(235, 235, 235, 0.93)
  .order-details
    border: solid 1px #E5EAEC
    box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  .f-text
    opacity: 0.2
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.71
    color: #000000
    &.ac
      opacity: 1
      font-family: GILROY-BOLD
      color: #1c455a
  .title-part
    .below-title
      width: 95px
      height: 3px
      border-radius: 8px
      background-color: #49a2cb
  ::v-deep .c-table
    @media screen and (max-width: 992px)
      width: max-content
      min-width: 100%
    thead
      tr
        th
          background-color: #ebf4f8
          border-top: none
          border-bottom: none
          font-family: GILROY-BOLD
          font-size: 12px
          font-weight: 600
          font-stretch: normal
          font-style: normal
          color: #1c455a
          padding-top: 10px
          padding-bottom: 10px
          &:first-child
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
          &:last-child 
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
    tbody
      tr
        td
          border-top: none
          border-bottom: none
          padding: 12px 0 0 0
          font-size: 12px
          font-weight: 500
          font-stretch: normal
          font-style: normal
          line-height: 2
          color: #000000
          .inner
            cursor: pointer
            padding: 12px
            border-top: solid 1px #E7ECEE
            border-bottom: solid 1px #E7ECEE
            height: 55px
            display: flex
            align-items: center
          &:first-child
            .inner
              border-left: solid 1px #E7ECEE
              border-top-left-radius: 4px
              border-bottom-left-radius: 4px
          &:last-child
            @media screen and (min-width: 992px)
              width: 220px
            .inner
              border-right: solid 1px #E7ECEE
              border-top-right-radius: 4px
              border-bottom-right-radius: 4px
              justify-content: center
          .re-order-btn
            min-height: 27px
            min-width: 140px
</style>