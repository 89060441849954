<template>
  <div class="settings-page">
    <div class="d-flex flex-column flex-lg-row page-contents">
      <div class="menu-sec mb-3 mb-lg-0">
        <div class="menus-wrp">
          <div class="menus mb-4">
            <div :class="[
              'menu cursor-pointer pl-2',
              {
                active: menu.value == selectedMenu,
                'appended-settings': menu.appendedSettings,
              },
            ]" v-for="(menu, mI) in menus" :key="mI" @click="handleMenuClick(menu.value)">
              <p class="mb-0 menu-text">{{ menu.text }}</p>
            </div>
          </div>
          <div class="menus">
            <div :class="[
              'menu cursor-pointer pl-2',
              { active: menu.value == selectedMenu },
            ]" v-for="(menu, mI) in menus2" :key="mI" @click="handleMenuClick(menu.value)">
              <p class="mb-0 menu-text">{{ menu.text }}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="oth-con">
        <!-- Render components based on selectedMenu -->
        <notification-center v-if="selectedMenu === 'Ntfc'" />
        <office-settings v-if="selectedMenu === 'Ofc'" @accordion-toggle="handleAccordionToggle" />
        <sub-company-settings v-if="selectedMenu == 'Comp'" />
        <user-settings v-if="selectedMenu === 'Usr'" />
        <billing-settings v-if="selectedMenu === 'Bill'" @accordion-toggle="handleAccordionToggle" />
        <manage-team v-if="selectedMenu === 'MTm'" />
        <link-vendor-setting v-if="selectedMenu === 'Lnk'" />
        <order-approval-settings v-if="selectedMenu === 'OrderApproval'" />
        <OfficeBudgetSettings v-if="selectedMenu === 'office_budget'" @accordion-toggle="handleAccordionToggle" />
        <terms v-if="selectedMenu === 'terms'" />
        <privacy v-if="selectedMenu === 'privacy'" />
      </div>
    </div>
    <DashboardFooter class="mt-3" />
  </div>
</template>
<script>
import DHead from "@/components/dashboard/DHead";
import DSearch from "@/components/dashboard/DSearch";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import NotificationSettings from "@/components/dashboard/settings/NotificationSettings";
import NotificationCenter from "@/components/dashboard/settings/NotificationCenter";
import OfficeSettings from "@/components/dashboard/settings/OfficeSettings";
import SubCompanySettings from "@/components/dashboard/settings/SubCompanySettings";
import OfficeBudgetSettings from "@/components/dashboard/settings/OfficeBudgetSettings";
import UserSettings from "@/components/dashboard/settings/UserSettings";
import BillingSettings from "@/components/dashboard/settings/BillingSettings";
import ManageTeam from "@/components/dashboard/settings/ManageTeam";
import LinkVendorSetting from "@/components/dashboard/settings/LinkVendorSetting";
import OrderApprovalSettings from "@/components/dashboard/settings/OrderApprovalSettings";
import Terms from "@/components/dashboard/settings/Terms";
import Privacy from "@/components/dashboard/settings/Privacy";
import DashHead from "@/components/dashboard/DashHead";
import { mapGetters } from "vuex";
import DashboardFooter from "@/components/dashboard/footer/DashboardFooter.vue";
import OfficeMixin from '@/mixins/OfficeMixin'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    DHead,
    DSearch,
    NotificationSettings,
    ManageTeam,
    OfficeSettings,
    SubCompanySettings,
    UserSettings,
    BillingSettings,
    DashHead,
    LinkVendorSetting,
    OrderApprovalSettings,
    Terms,
    Privacy,
    NotificationCenter,
    DashboardFooter,
    OfficeBudgetSettings,
  },
  data: () => ({
    shouldShowDropdown: false,
    officeSettingMenu: false,
    officeSettingMenus: [
      {
        text: "Office Budget Settings",
        value: "office_budget",
      },
      {
        text: "Manage Team",
        value: "MTm",
      },
      {
        text: "Order Approval Settings",
        value: "OrderApproval",
      },
      {
        text: "Vendors",
        value: "Lnk",
      },
      {
        text: "Billing Details",
        value: "Bill",
      },
    ],
    menus2: [
      {
        text: "Terms of Use",
        value: "terms",
      },
      {
        text: "Privacy Policy",
        value: "privacy",
      },
    ],
    mns: [
      {
        text: "User Settings",
        value: "Usr",
      },
      { text: "Company Settings", value: "Ofc" },
      // { text: "Sub-company Settings", value: "Comp" },
      {
        text: "Vendors",
        value: "Lnk",
      },
      {
        text: "Notification Center",
        value: "Ntfc",
      },
    ],
    selectedMenu: "Ofc",
    initialRenderComponents: ["Ntfc", "Ofc", "Usr", "Lnk"], // Add the menu values to be initially rendered
    shouldShowAllComponents: false, // Flag to determine whether to show all components
    usrs: ["Usr", "Ntfc", "Lnk", "UpdateCreditCard"],
    additionalMenus: [
      {
        text: "User Profile",
        value: "Usr",
      },
      {
        text: "Vendors",
        value: "Lnk",
      },
    ],
  }),
  created() {
    this.checkHash();
    this.handleAccordionToggle(this.officeId, this.office.name)
  },
  watch: {
    $route() {
      this.checkHash();
    },
  },
  mixins: [OfficeMixin],
  computed: {
    ...mapGetters("USER", ["role"]),
    // menus() {
    //   let mns = [
    //     { text: 'User Settings', value: 'Usr' },
    //     { text: 'Office Settings', value: 'Ofc' },
    //     { text: 'Office Budget Settings', value: 'office_budget' },
    //     { text: 'Manage Team', value: 'MTm' },
    //     { text: 'Notification Center', value: 'Ntfc' },
    //     { text: 'Order Approval Settings', value: 'OrderApproval' },
    //     { text: 'Vendors', value: 'Lnk' },
    //     { text: 'Billing Details', value: 'Bill' },
    //   ];
    //   return this.role == 1 ? mns : mns.filter((m) => this.usrs.includes(m.value));
    // },
    menus() {
      return this.role == 1 && 3
        ? this.mns
        : this.mns.filter((m) => this.usrs.includes(m.value));
    },
  },
  methods: {
    checkHash() {
      if (this.$route.hash) {
        if (
          this.role == 1 || this.role == 3 ||
            (this.role == 2 && this.usrs.includes(this.$route.hash.substring(1)))
        )
          this.selectedMenu = this.$route.hash.substr(1);
      } else this.selectedMenu = "Usr";
    },
    handleAccordionToggle({ index, officeName, name }) {
      // Now, you have the officeName of the clicked accordion
      // Check if officeName already exists in mns
      const existingOfficeNames = this.mns.map((menu) => menu.text);
      if (!existingOfficeNames.includes(officeName)) {
        // Check if each item in officeSettingMenus already exists in mns
        const officeSettingMenuValues = this.officeSettingMenus.map(
          (menu) => menu.value
        );
        const existingValues = this.mns.map((menu) => menu.value);
        const newMenus = this.officeSettingMenus.filter(
          (menu) => !existingValues.includes(menu.value)
        );
        // Using splice to insert the newMenus at index 2 in mns
        this.mns.splice(2, 0, ...newMenus);
        const officeSettingsIndex = this.mns.findIndex(
          (menu) => menu.value === "Ofc"
        );
        if (officeSettingsIndex !== -1) {
          // Replace the existing object with a new one
          this.mns.splice(officeSettingsIndex, 1, {
            text: "Company Settings",
            value: "Ofc",
            appendedSettings: true,
          });
        }
        if (
          this.mns.length > 0 &&
          this.mns[this.mns.length - 1].value !== "Ntfc"
        ) {
          this.mns.pop();
        }
        // this.mns.push({ text: officeName, value: 'Ofcsss' });
        this.officeSettingMenu = true;
      }
    },
    handleMenuClick(menu) {
      this.selectedMenu = menu;
      if (this.officeSettingMenus.some((item) => item.value === menu)) {
        this.officeSettingMenu = true;
      } else {
        this.officeSettingMenu = true;
      }
      // if (menu == "Usr" || menu == "Ntfc") {
      //   this.mns = [
      //     {
      //       text: "User Settings",
      //       value: "Usr",
      //     },
      //     {
      //       text: "Company Settings",
      //       value: "Ofc",
      //     },
      //     {
      //       text: "Sub Company",
      //       value: "Comp",
      //     },
      //     {
      //       text: "Notification Center",
      //       value: "Ntfc",
      //     },
      //   ];
      // }
    },
    shouldRenderComponent(component) {
      if (this.shouldShowAllComponents) {
        return true; // Show all components when the flag is true
      } else {
        return this.initialRenderComponents.includes(component);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.settings-page
  .page-contents
    min-height: calc(100vh - 62px - 66px - 4rem)

  .menu-sec
    position: relative

    @media screen and (min-width: 992px)
      width: 276px

    @media screen and (max-width: 992px)
      max-width: 100%

    .menus-wrp
      @media screen and (min-width: 992px)
        position: sticky
        top: 80px

      .menus
        border-radius: 12px
        box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
        background-color: #ffffff

        .menu
          height: 55px
          display: flex
          align-items: center
          border-top: solid 1px #E7ECEE
          border-left: solid 9px transparent

          &:first-child
            border-top: none

            &.active
              border-top-left-radius: 12px

          &:last-child
            &.active
              border-bottom-left-radius: 12px

          .menu-text
            font-size: 12px
            font-family: Gilroy-SEMIBOLD
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: 2
            color: #1c455a
            @media screen and (max-width: 479px)
              padding-left: 10px

          &.active
            border-left: solid 9px #13add9

            .menu-text
              color: #13add9

  .menu.cursor-pointer.appended-settings
    ~ .menu.cursor-pointer
      background: #f9f9f9
      padding-left: 2rem !important

      &:last-child
        background: #fff
        padding-left: 0.5rem !important

  .oth-con
    width: calc(100% - 276px - 20px)
    margin-left: auto

    @media screen and (max-width: 992px)
      width: 100%
</style>
