<template>
  <div class="d-flex flex-column flex-lg-row">
    <div class="products-search-results">
      <div class="products">
        <div v-if="searchResult.length > 0">
          <div>
            <!-- Check for products with non-null price -->
            <div v-for="(product) in searchResult" :key="product._source.id"
                 v-if="product._source.children && product._source.children.some(child => child.price != null)"
                 class="w-100 d-card mb-4 pr-card-wrp">
              <product-card-horizontal :ref="`prCard-${product._source.id}`" :inventory-in-search="product._source.is_inventory"
                :inventoryProduct="product._source.is_inventory" :prod="product._source" :cart-data="cart" :supplierAsCard="true"
                :orderApproval="orderApproval" :itemsToCheckIn="itemsToCheckIn" :orderId="orderId" @show="openDetail"
                @added-item-in-order="$emit('added-item-in-order')" />
            </div>

            <div class="w-100 d-card px-3 mb-4 pr-card-wrp" v-if="searchingMore">
              <product-card-horizontal :skeleton="true" :search-skeleton="true" :supplierAsCard="true" />
            </div>
          </div>
        </div>
        <div v-else-if="!searching && !searchingMore && !inc" class="py-5 h-100">
          <no-search-result class="m-auto" />
        </div>
      </div>
      <div class="" v-if="searching && !searchingMore">
        <!-- Other elements here -->
      </div>
    </div>
    <modal :modal="infoModal" @change="toggleInfoModal" size="lg">
      <product-details-in-modal :product="selectedProduct" />
    </modal>
  </div>
</template>

<script>
import CaretDownFill from '@/components/svg/CaretDownFill'
import ProductCard from '@/components/shopping/ProductCard'
import DHead from '@/components/dashboard/DHead'
import FilterSidebar from '@/components/shopping/FilterSidebar'
import ProductCardHorizontal from '@/components/shopping/ProductCardHorizontal'
import ProductSearchMixin from '@/mixins/ProductSearch'
import Modal from '@/components/modals/Modal'
import ProductDetailsInModal from '@/components/shopping/ProductDetailsInModal'
import RecentlyOrderedItems from '@/components/shopping/RecentlyOrderedItems'
import SearchedProductHeader from '@/components/shopping/SearchedProductHeader'
import NoSearchResult from '@/components/shopping/NoSearchResult'
import CartMixin from '@/mixins/CartMixin'
import { mapActions } from 'vuex'
export default {
  components: {
    CaretDownFill,
    ProductCard,
    DHead,
    FilterSidebar,
    ProductCardHorizontal,
    Modal,
    ProductDetailsInModal,
    RecentlyOrderedItems,
    SearchedProductHeader,
    NoSearchResult
  },
  props: {
    text: String,
    filters: Object,
    orderApproval: Boolean,
    itemsToCheckIn: Array,
    orderId: Number,
  },
  mixins: [ProductSearchMixin, CartMixin],
  data: () => ({
    filterBy: {
      sortBy: 'cheapset'
    },
    sortOptions: ['cheapset'],
    categories: ['Discounted Products', 'My Favorites'],
    infoModal: false,
    selectedProduct: null,
  }),
  created() {
    if (!this.searching) this.emitter({ q: this.text })
    this.CLEAR_FILTER_BY_PRICE()
  },
  computed: {
    productConHeight() {
      return this.filteredProducts.length > 0 ? '100vh' : 'auto'
    },
  },
  watch: {
    searchResult: {
      handler(newV, oldV) {
        if (oldV == null || oldV.length === 0) {
          let rt = document.querySelector(`.rt-view-wrapper`)
          if (rt) rt.scrollTop = 0
        }
        newV.forEach(p => {
          let el = this.$refs[`prCard-${p.id}`]
          if (Array.isArray(el)) {
            el.forEach(e => {
              e.$forceUpdate()
            })
          }
          else if (el) el.$forceUpdate()
        })
      }, deep: true
    },
    filters: {
      handler(val) {
        this.emitter({ q: val.q, filters: val })
      }, deep: true,
    },
  },
  methods: {
    ...mapActions('PRODUCT', ['SEARCH_PRODUCT_SUGGESTION', 'CLEAR_FILTER_BY_PRICE']),
    toggleInfoModal(v) {
      if (v != this.infoModal) this.infoModal = v != null ? v : false
    },
    openDetail(product) {
      this.selectedProduct = product
      this.infoModal = true
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../style/shopping'
.products-search-results
  .products
    .pr-card-wrp
      &:first-child
        .manufacturer-promo-product
          margin-top: 1rem !important
.filter-sidebar
  @media screen and (min-width: 992px)
    border-right: solid 1px #EBF4F7
    min-width: 282px
.products-search-results
  width: -webkit-fill-available
  @media screen and (min-width: 992px)
    .products
      overflow-y: auto
</style>