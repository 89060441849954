<template>
  <div class="order-items">
    <div class="d-flex flex-column flex-lg-row justify-content-between mb-3 gap-12-px">
      <div class="d-lg-flex align-items-center gap-24-px">
        <div class="title-part mb-3 mb-lg-0">
          <p class="mb-0 d-title">Products by Budget Category</p>
        </div>
        <div class="d-flex align-items-center gap-16-px" style="overflow-x: auto;"
          v-if="!this.$store.state.OFFICE.fetchingBudget">
          <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == budgetCategory }]"
            @click="setStatus(null)">All</p>
          <p :class="['f-text mb-0 cursor-pointer min-w-max', { 'ac': status == budgetCategory.value }]"
            @click="setStatus(budgetCategory.value)" v-for="budgetCategory in budgetCategories"
            :key="budgetCategory.value">
            <span v-if="budgetCategory.value == 'dental'">Dental Supply</span>
            <span v-else-if="budgetCategory.value == 'office'">Front Office Supply</span>
            <span v-else-if="budgetCategory.value == 'miscellaneous'">Uncategorized</span>
            <span v-else>{{ budgetCategory.text }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="orders.length > 0">
      <div class="table-section" style="overflow-x: clip">
        <b-table :items="orders" :fields="fields" class="tabl" show-empty :busy="fetching">
          <template #cell(image)="row">
            <div class="inner">
              <product-image :product="row.item.product" :no-image-short="true" size="sm" />
            </div>
          </template>
          <template #cell(created_at)="row">
            <div class="inner">
              <p class="t-text mb-0">{{ getProcessedDt(row.item.order_date) }}</p>
            </div>
          </template>
          <template #cell(name)="row">
            <div class="inner">
              <b-tooltip :target="`tooltip-target-order-item-${row.item.id}`" triggers="hover focus" placement="top"
                boundary="document" custom-class="promotion-tooltip">
                <p class="d-desc mb-0">{{ row.item.product.name }}</p>
              </b-tooltip>
              <p class="t-text mb-0" :id="`tooltip-target-order-item-${row.item.id}`">{{ row.item.product.name }}</p>
            </div>
          </template>
          <template #cell(quantity)="row">
            <div class="inner">
              <p class="t-text mb-0">{{ row.item.quantity }}</p>
            </div>
          </template>
          <template #cell(total)="row">
            <div class="inner">
              <p class="t-text mb-0 pl-2-px ">${{ totalAmount(row.item) }}</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="inner justify-content-center">
              <b-dropdown right class="menu-drp not-blur" menu-class="bud-cat-sel" :ref="`bud-cat-${row.item.id}`"
                v-if="role === 1 && !$store.state.OFFICE.fetchingBudget" offset="44">

                <template #button-content>
                  <b-btn class="custom-btn danger__2__outline d-flex align-items-center justify-content-between" :style="!isSelected({ value: 'dental' }, row.item, 'budget_category') &&
                    !isSelected({ value: 'office' }, row.item, 'budget_category') &&
                    !isSelected({ value: 'miscellaneous' }, row.item, 'budget_category') ?
                    { backgroundColor: '#FF1DC3', color: 'white' } : {}" style="width: 147px;">
                    <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                      v-if="isSelected({ value: 'dental' }, row.item, 'budget_category')">Dental Supply Budget</span>
                    <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                      v-else-if="isSelected({ value: 'office' }, row.item, 'budget_category')">Front Office Supply
                      Budget</span>
                    <span :class="['text-danger__2']" style="overflow: hidden; text-overflow: ellipsis;"
                      v-else-if="isSelected({ value: 'miscellaneous' }, row.item, 'budget_category')">Uncategorized</span>
                    <span :class="['text-danger__2']"
                      style="color: white !important; overflow: hidden; text-overflow: ellipsis;" v-else>Select
                      Budget</span>

                    <img src="@/assets/images/svg_icon/down_caret_white.svg" alt="" class="ml-2" v-if="!isSelected({ value: 'dental' }, row.item, 'budget_category') &&
                      !isSelected({ value: 'office' }, row.item, 'budget_category') &&
                      !isSelected({ value: 'miscellaneous' }, row.item, 'budget_category') && role == 1">
                    <img src="@/assets/images/svg_icon/down_caret_pink.svg" alt="" class="ml-2" v-else-if="role == 1">
                  </b-btn>
                </template>



                <div class="cm-body">
                  <div v-for="(budCat, rI) in budgetCategories" :key="rI">
                    <div class="dash" v-if="rI > 0"></div>
                    <div class="my-3 cursor-pointer d-flex align-items-center"
                      @click="setRej(budCat, row.item, 'budget_category', $event)">
                      <div class="ven-ck mr-2 px-2-px">
                        <img src="@/assets/images/pink_check_mark.png" alt=""
                          style="vertical-align: unset; max-width: 100%; max-height: 100%"
                          v-if="isSelected(budCat, row.item, 'budget_category')">
                      </div>
                      <p class="d-desc text-danger__2 mb-0">{{ budCat.text }}</p>
                    </div>
                  </div>
                </div>
              </b-dropdown>
            </div>
            <p class="d-desc mb-0 f-10-px text-success position-absolute"
              :style="{ right: '2rem', bottom: '-3px', visibility: showUpdated ? 'visible' : 'hidden' }">
              Updated
              <img src="@/assets/images/check.svg" alt="" class="ml-1 mt-1-px">
            </p>
          </template>
          <template #table-busy>
            <div style="height: 250px">
              <GrowLoader />
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination class="custom-pagination mt-4" v-if="!fetching" v-model="tableSettings.currentPage"
        :total-rows="tableSettings.rows" :per-page="tableSettings.perPage" align="center" hide-ellipsis
        hide-goto-end-buttons />
    </div>
    <OrderByBudgetTypeNoData v-else>
      <template #desc-text>
        <div v-if="status">
          <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-if="status == 'dental'">There are no
            dental supply budget products during this period</p>
          <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else-if="status == 'office'">There are
            no
            front office supply budget products during this period</p>
          <p class="d-title text-center mx-auto mb-2" style="max-width: 300px" v-else-if="status == 'miscellaneous'">No
            products during this period</p>
        </div>
        <p class="d-title text-center mx-auto my-4" style="max-width: 400px" v-else>There are no products during the
          period you have selected. To see products, try changing the period range in the filters above.</p>
      </template>
    </OrderByBudgetTypeNoData>
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage'
import Button from '@/components/ui/Button'
import { mapGetters, mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import FilterMixin from '@/mixins/Filter'
import PlusMinus from '@/mixins/PlusMinus'
import Modal from '@/components/modals/Modal'
import moment from 'moment'
import GrowLoader from '@/components/loaders/GrowLoader'
import OrderByBudgetTypeNoData from './OrderByBudgetTypeNoData.vue'
export default {
  components: { ProductImage, Button, Modal, GrowLoader, OrderByBudgetTypeNoData, },
  mixins: [OfficeMixin, PlusMinus, FilterMixin],
  data: () => ({
    orders: [],
    showUpdated: false,
    fetching: true,
    status: null,
    lastFilters: {},
    tableSettings: {
      rows: 100,
      perPage: 5,
      currentPage: 1
    },
    orderFormData: {
      items: []
    },
  }),
  created() {
    this.setFormData()

    this.lastFilters = {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    }
    // this.lastFilters = {}
    this.fetchOrders(true, undefined, this.lastFilters)
    this.$BUS.$on('filter:filter-change', flt => {
      this.onFilterChange(flt, filter => {
        this.lastFilters = filter
        this.fetchOrders(true, undefined, filter)
      })
    })
  },
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change')
  },
  watch: {
    orders: {
      handler() { this.setFormData() },
      deep: true,
    },
    status(v) {
      if (this.tableSettings.currentPage != 1) this.tableSettings.currentPage = 1
      else this.fetchOrders(true, undefined, this.lastFilters)
    },
    'tableSettings.currentPage'(v) {
      this.fetchOrders(true, v, this.lastFilters)
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    ...mapGetters('FILTER', ['filters']),
    budgetCategories() {
      if (this.$store.state.OFFICE.officeBudget[this.officeId] != null) {
        return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => {
          if (subaccount.slug == 'dental') return { value: 'dental', text: 'Dental Supply Budget' }
          else if (subaccount.slug == 'office') return { value: 'office', text: 'Front Office Supply Budget' }
          else if (subaccount.slug == 'miscellaneous') return { value: 'miscellaneous', text: 'Uncategorized' }
          else {
            return { value: subaccount.slug, text: subaccount.name + ' Budget' }
          }
        })
      }
      return []
    },
    fields() {
      let fields = [
        { key: 'created_at', label: 'Order Date', sortable: false, tdClass: '' },
        { key: 'name', label: 'Product Name', sortable: false, tdClass: 'name-desc-td' },
        { key: 'image', label: 'Product Image', sortable: false, tdClass: 'pr-img-td' },
        { key: 'quantity', label: 'Quantity Ordered', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total', label: 'Item Total', sortable: false, thClass: '', tdClass: 'item-total' },
      ]
      if (this.role == 1) fields.push({ key: 'actions', label: 'Budget Category', sortable: false, thClass: 'text-center', tdClass: 'text-center' })
      return fields
    },
  },
  methods: {
    ...mapActions('ORDER', ['FETCH_ORDER_PRODUCTS']),
    ...mapActions('PRODUCT', ['UPDATE_BUDGET_CATEGORY']),
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET']),
    getProcessedDt(dt) {
      let d = moment(dt)
      if (d.isValid()) return d.format("M/D/YYYY")
      return null
    },
    setStatus(v) {
      if (v == null) {
        if ('status' in this.lastFilters) {
          delete this.lastFilters['status'];
        }
        if ('budget_type' in this.lastFilters) { delete this.lastFilters['budget_type']; }
      }
      if (this.status != v) {
        this.status = v
      }
      else {
        this.status = this.budgetCategory
      }
      if (this.lastFilters.status != v) this.lastFilters.status = v
      // if(this.lastFilters.status != v && v != null) this.lastFilters.status = v
      else delete this.lastFilters.status


    },
    async fetchOrders(fetching = true, page = undefined, filters = {}) {
      this.fetching = fetching
      try {
        let payload = {
          office: this.officeId,
          filters
        }
        payload.filters.per_page = 10
        if (page) payload.filters.page = page
        if (this.status != null) payload.filters.budget_type = this.status
        let res = await this.FETCH_ORDER_PRODUCTS(payload)
        this.tableSettings.rows = res.total
        this.tableSettings.perPage = res.per_page
        this.tableSettings.currentPage = res.current_page
        this.orders = res.data
      } catch (e) { }
      this.fetching = false
    },
    setFormData() {
      this.orderFormData.items = this.orders.map(item => {
        let it = this.orderFormData.items.find(i => i.order_product_id == item.id)
        return {
          order_product_id: item.id,
          budget_category: item.budget_spend_type,
        }
      })
    },
    totalAmount(order, price) {
      return (order.quantity * parseFloat(price || order.updated_unit_price || order.unit_price)).toFixed(2)
    },
    isSelected(reason, item, key = 'rejected_reason') {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      return index > -1 && this.orderFormData.items[index][key] && this.orderFormData.items[index][key] == reason.value
    },
    getBudgetType(item) {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1 && this.orderFormData.items[index]['budget_category'] != null) {
        const subaccount = this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.find(s => s.slug == this.orderFormData.items[index]['budget_category'])
        if (subaccount != null) return `${subaccount.name} Budget`
      }
      return `Budget Type`
    },
    async setRej(reason, item, key = 'rejected_reason', ev) {
      let index = this.orderFormData.items.findIndex(i => i.order_product_id == item.id)
      if (index > -1) {
        if (this.orderFormData.items[index][key] != reason.value) this.orderFormData.items[index][key] = reason.value
        else this.orderFormData.items[index][key] = null
      }
      await this.$nextTick()
      if (key == 'budget_category') {
        this.$refs[`bud-cat-${item.id}`].hide(true)
        this.updateBudgetCategory(item, { value: this.orderFormData.items[index][key] }, ev)
      }
    },
    async updateBudgetCategory(item, reason, ev) {
      try {
        let payload = {
          office: this.officeId,
          product_id: item.id,
          budget_spend_type: reason.value,
          // filters: this.filters,
        }
        await this.UPDATE_BUDGET_CATEGORY(payload)
        this.$emit('updateRecord')
        this.onFilterChange(this.filters, flt => {
          this.CURRENT_MONTH_BUDGET({ office: this.officeId, filters: flt })
        })
        this.showUpdated = true
        setTimeout(() => {
          this.showUpdated = false
        }, 1000)

      } catch (e) { console.log(e) }
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/sl-num'

.f-text
  opacity: 0.2
  font-size: 14px
  font-weight: normal
  font-stretch: normal
  font-style: normal
  line-height: 1.71
  color: #000000
  &.ac
    opacity: 1
    font-family: GILROY-BOLD
    color: #1c455a
::v-deep .em-return-fil
  min-width: unset !important
  width: 200px
  .em-return-em-txt
    max-width: 150px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.em-ck
  min-width: 20px
  width: 20px
  height: 20px
  border-radius: 4px
  border: solid 1px #ededed
.em-sel
  height: 31px
  min-width: 200px
.box-ship-lb-sl
  max-width: 50px
  height: 35px !important
.order-items
  ::v-deep .images-sec
    width: 45px
    height: 40px
    border-radius: 4px
    border: solid 1px rgba(28, 69, 90, 0.1)
    background-color: #ebf4f8
  .ven-ck
    width: 20px
    min-width: 20px
    height: 20px
    border-radius: 4px
    border: solid 1px #ededed
    display: flex
    align-items: center
    justify-content: center
  .t-text
    font-size: 12px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    letter-spacing: normal
    text-align: left
    color: #707070
  .ven-image
    width: 24px
    height: 24px
    display: flex
    img
      width: inherit
      height: inherit
  ::v-deep table.tabl
    @media screen and (max-width: 992px)
      width: max-content
      min-width: 100%
    thead
      tr
        th
          background-color: #ebf4f8
          border-top: none
          border-bottom: none
          font-family: GILROY-BOLD
          font-size: 12px
          font-weight: 600
          font-stretch: normal
          font-style: normal
          color: #1c455a
          padding-top: 10px
          padding-bottom: 10px
          &:first-child
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
          &:last-child 
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
    tbody
      tr
        td
          border-top: none
          border-bottom: none
          padding: 12px 0 0 0
          font-size: 12px
          font-weight: 500
          font-stretch: normal
          font-style: normal
          line-height: 2
          color: #000000
          .inner
            cursor: pointer
            padding: 12px
            border-top: solid 1px #E7ECEE
            border-bottom: solid 1px #E7ECEE
            height: 55px
            display: flex
            align-items: center
          &:first-child
            .inner
              border-left: solid 1px #E7ECEE
              border-top-left-radius: 4px
              border-bottom-left-radius: 4px
          &:last-child
            @media screen and (min-width: 992px)
              width: 220px
            .inner
              border-right: solid 1px #E7ECEE
              border-top-right-radius: 4px
              border-bottom-right-radius: 4px
          &.pr-img-td
            @media screen and (min-width: 992px)
              width: 125px
          &.name-desc-td
            @media screen and (min-width: 992px)
              width: 246px
            p
              // max-width: 192px
              text-overflow: ellipsis
              overflow: hidden
              display: -webkit-box
              -webkit-line-clamp: 1
              -webkit-box-orient: vertical
            &__chk
              p
                max-width: 350px
          &.qty-td
            p
              min-width: 27px
              height: 25px
              width: min-content
              flex-grow: 0
              border-radius: 4px
              border: solid 1px #e6eaeb
              display: flex
              justify-content: center
              margin: 0 auto
          // &.item-total
          //   width: 150px
          // &.status-td
          //   width: 190px
          .custom-btn
            min-height: 28px
            min-width: 77px
            &:nth-child(2)
              margin-left: 8px
            @media screen and (max-width: 1350px)
              &:nth-child(2)
                margin-top: 5px
                margin-left: 0
    // thead
    //   tr
    //     th
    //       background-color: transparent !important
    //       border-top: solid 1px #E5EAEC !important
    tbody
      tr
        td
          vertical-align: middle
          // padding: 7.75px 12px 7.75px 12px !important
          // border-top: solid 1px #E5EAEC !important
</style>