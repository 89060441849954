<template>
<svg width="843" height="239" viewBox="0 0 843 239" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M408.251 146.489C408.251 192.499 374.428 226.017 329.637 226.017C284.847 226.017 251.027 192.499 251.027 146.489C251.027 100.177 284.847 66.9619 329.637 66.9619C374.428 66.9619 408.251 100.177 408.251 146.489ZM367.707 146.489C367.707 118.153 349.425 103.829 329.62 103.829C309.816 103.829 291.533 118.153 291.533 146.489C291.533 174.523 309.816 189.149 329.62 189.149C349.425 189.149 367.724 174.825 367.724 146.489H367.707Z" fill="#FF1DC3"/>
<path d="M513.371 111.754C509.359 110.941 505.275 110.533 501.181 110.538C480.459 110.538 462.482 120.595 462.482 152.889V221.445H421.939V71.5303H461.245V93.7709C470.388 73.9664 491.107 70.3105 503.906 70.3105C507.062 70.3521 510.215 70.5562 513.351 70.9222V111.754H513.371Z" fill="#FF1DC3"/>
<path d="M671.203 194.326C671.203 204.383 671.815 215.351 672.419 221.446H633.725C632.729 215.71 632.219 209.9 632.202 204.078C625.499 215.959 610.264 225.102 590.154 225.102C547.494 225.102 517.024 191.585 517.024 146.186C517.024 102.306 546.573 67.8752 588.931 67.8752C614.834 67.8752 627.018 78.5265 631.285 86.4634V0.844238H671.2V194.326H671.203ZM595.029 188.829C615.751 188.829 631.9 172.986 631.9 145.88C631.9 119.08 615.751 104.138 595.029 104.138C574.307 104.138 557.86 119.372 557.86 146.186C557.86 173 573.703 188.843 595.029 188.843V188.829Z" fill="#FF1DC3"/>
<path d="M842.751 146.489C842.751 192.499 808.928 226.017 764.137 226.017C719.347 226.017 685.524 192.499 685.524 146.489C685.524 100.177 719.347 66.9619 764.137 66.9619C808.928 66.9619 842.751 100.177 842.751 146.489ZM802.224 146.489C802.224 118.153 783.942 103.829 764.137 103.829C744.333 103.829 726.05 118.153 726.05 146.489C726.05 174.523 744.333 189.149 764.137 189.149C783.942 189.149 802.224 174.825 802.224 146.489Z" fill="#FF1DC3"/>
<path d="M101.487 57.5435C79.3638 57.5445 58.0022 65.622 41.4134 80.259C24.8246 94.896 14.15 115.086 11.3943 137.036H57.7789C59.7698 129.332 63.7623 122.291 69.3516 116.627C74.9409 110.963 81.9283 106.877 89.6056 104.784C97.2829 102.691 105.377 102.665 113.068 104.708C120.758 106.752 127.772 110.792 133.398 116.42C139.024 122.048 143.061 129.063 145.102 136.755C147.143 144.446 147.113 152.54 145.018 160.217C142.922 167.893 138.833 174.879 133.167 180.466C127.501 186.054 120.458 190.043 112.753 192.031V238.416C135.675 235.544 156.641 224.043 171.38 206.254C186.118 188.465 193.522 165.727 192.083 142.671C190.644 119.614 180.47 97.9734 163.634 82.1555C146.797 66.3377 124.564 57.5325 101.463 57.5332L101.487 57.5435Z" fill="#FF1DC3"/>
<path d="M399.661 146.489C399.661 192.499 365.838 226.017 321.048 226.017C276.258 226.017 242.438 192.499 242.438 146.489C242.438 100.177 276.258 66.9619 321.048 66.9619C365.838 66.9619 399.661 100.177 399.661 146.489ZM359.118 146.489C359.118 118.153 340.835 103.829 321.031 103.829C301.226 103.829 282.944 118.153 282.944 146.489C282.944 174.523 301.226 189.149 321.031 189.149C340.835 189.149 359.135 174.825 359.135 146.489H359.118Z" fill="#13ADD9"/>
<path d="M504.782 111.754C500.77 110.941 496.686 110.533 492.592 110.538C471.87 110.538 453.893 120.595 453.893 152.889V221.445H413.35V71.5303H452.656V93.7709C461.799 73.9664 482.518 70.3105 495.316 70.3105C498.473 70.3521 501.626 70.5562 504.762 70.9222V111.754H504.782Z" fill="#13ADD9"/>
<path d="M662.614 194.326C662.614 204.383 663.225 215.351 663.83 221.446H625.135C624.139 215.71 623.63 209.9 623.613 204.078C616.91 215.959 601.675 225.102 581.564 225.102C538.904 225.102 508.435 191.585 508.435 146.186C508.435 102.306 537.984 67.8752 580.341 67.8752C606.245 67.8752 618.428 78.5265 622.696 86.4634V0.844238H662.61V194.326H662.614ZM586.44 188.829C607.162 188.829 623.311 172.986 623.311 145.88C623.311 119.08 607.162 104.138 586.44 104.138C565.718 104.138 549.27 119.372 549.27 146.186C549.27 173 565.113 188.843 586.44 188.843V188.829Z" fill="#13ADD9"/>
<path d="M834.162 146.489C834.162 192.499 800.339 226.017 755.548 226.017C710.758 226.017 676.935 192.499 676.935 146.489C676.935 100.177 710.758 66.9619 755.548 66.9619C800.339 66.9619 834.162 100.177 834.162 146.489ZM793.635 146.489C793.635 118.153 775.353 103.829 755.548 103.829C735.744 103.829 717.461 118.153 717.461 146.489C717.461 174.523 735.744 189.149 755.548 189.149C775.353 189.149 793.635 174.825 793.635 146.489Z" fill="#13ADD9"/>
<path d="M92.898 57.5435C70.7749 57.5445 49.4133 65.622 32.8245 80.259C16.2358 94.896 5.56118 115.086 2.80542 137.036H49.19C51.181 129.332 55.1734 122.291 60.7627 116.627C66.352 110.963 73.3395 106.877 81.0168 104.784C88.6941 102.691 96.7883 102.665 104.479 104.708C112.17 106.752 119.183 110.792 124.809 116.42C130.435 122.048 134.473 129.063 136.513 136.755C138.554 144.446 138.525 152.54 136.429 160.217C134.333 167.893 130.244 174.879 124.578 180.466C118.912 186.054 111.87 190.043 104.164 192.031V238.416C127.087 235.544 148.052 224.043 162.791 206.254C177.53 188.465 184.933 165.727 183.494 142.671C182.055 119.614 171.881 97.9734 155.045 82.1555C138.208 66.3377 115.975 57.5325 92.874 57.5332L92.898 57.5435Z" fill="#13ADD9"/>
<path d="M96 148.494L95.4958 238L3 148L96 148.494Z" fill="#1E9A0B"/>
<path d="M24.0279 156.137L2.77692 147.472L0.60097 150.827L4.13 154.062L22.6543 158.998L24.0279 156.137Z" fill="#1E9A0B"/>
<path d="M95.5 238L92 215.5L82 215L78.5 219.5L87.5 238H95.5Z" fill="#1E9A0B"/>
<path d="M88 152.472L87.5337 238L2 152L88 152.472Z" fill="#29BF12"/>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>