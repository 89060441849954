<template>
  <div class="px-3">
    <div class="outer d-flex flex-column-reverse flex-lg-row gap-x-70-px gap-y-60-px mx-auto align-items-center justify-content-between">
      <div class="contents">
        <p class="title-1">All your vendors, one convenient platform</p>
        <p class="subtitle-1 mb-0">Ready, set, order! Ordo links your vendors and imports your previous order history, so you can start shopping right away and never miss a beat.</p>
        <RouterLink to="/feature-multivendor-ordering" class="mb-0 link text-none d-block mt-3 text-center text-lg-left">
          Learn more
          <RightArrow class="mb-2-px w-16-px h-16-px" />
        </RouterLink>
      </div>
      <img src="./landing_images/image_1.svg" class="svg" />
    </div>
  </div>
</template>

<script>
import RightArrow from '@/components/svg/icons/solid/RightArrow.vue'
export default {
  components: { RightArrow, },
}
</script>

<style lang="sass" scoped>
.outer
  max-width: 1056px
  margin-top: 8rem
  .contents
    max-width: 550px
    .title-1
      font-family: GILROY-BOLD
      font-size: 34px
      color: #274458
      line-height: 1.2
    .subtitle-1
      font-size: 16px
      color: #788288
      line-height: 1.6
    .link
      font-family: GILROY-SEMIBOLD
      color: #50A9D5
      font-size: 16px
      &:hover
        text-decoration: none
  .svg
    width: 500px
    height: 350px
    max-width: 100%
@media screen and (max-width: 992px)
  .outer
    margin-top: 6rem
    .title-1
      font-size: 24px
      text-align: center
    .subtitle-1
      font-size: 14px
      text-align: center
    .svg
      width: auto
      margin: 0 auto
      height: auto
      transform: translateX(-30px)
</style>