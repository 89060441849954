<template>
  <div class="position-relative">
    <div class="bg">
      <img src="./HeaderOrdo.svg" alt="">
    </div>
    <div class="position-relative contents px-3">
      <p class="title-1 mx-auto">The smarter way to order and manage your dental supplies</p>
      <p class="subtitle-1 mx-auto mb-4">Experience the convenience of Ordo: the all-in-one tool that manages your dental supplies, vendors, and budget in real-time</p>
      <div class="d-flex justify-content-center align-items-center gap-16-px mb-5">
        <!-- <RouterLink to="/wait-list">
          <Button class="px-4 min-w-142-px !min-h-45-px f-14-px shadow-btn" text="Join our Waitlist" />
        </RouterLink> -->
        <a href="/wait-list" >
       <button class="btn nv-btn primary px-4 btn-secondary custom-btn !min-h-45-px"> Join our Waitlist </button></a>
        <Button class="white__outline min-w-142-px px-4 !min-h-45-px f-14-px" style="border-width: 2px;" text="Watch the Video" ref="playPauseBtn" :onclick="toggleVideoPlay" />
      </div>
      <p class="text-center d">
        <img src="./LanD1.svg" alt="">
      </p>
    </div>
    <VendorsSlider class="my-3 my-lg-5" />
    <div class="cov-video" v-show="playVideo">
      <video ref="video" :src="`${publicPath}videos/ORDO.mp4`" v-click-outside="onOutsideVideoClick" controls></video>
    </div>
  </div>
</template>

<script>
import Button from '../ui/Button.vue'
import VendorsSlider from './VendorsSlider.vue'
export default {
  components: { Button, VendorsSlider, },
  data: () => ({
    playVideo: false,
    publicPath: process.env.BASE_URL
  }),
  mounted(){
    window.onresize = () => {
      if(window.innerWidth < 1000){
        if(this.playVideo) this.toggleVideoPlay()
      }
    }
  },
  methods: {
    toggleVideoPlay(){
      this.playVideo = !this.playVideo
      if(this.playVideo) this.$refs.video.play()
      else {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 0
      }
    },
    onOutsideVideoClick(e){
      if(e.target !== this.$refs.playPauseBtn.$el && window.innerWidth > 1000 && this.playVideo) this.toggleVideoPlay()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/landing'
.title-1
  font-family: GILROY-BOLD
  font-size: 55px
  text-align: center
  max-width: 800px
  color: white
  line-height: 1.1
.subtitle-1
  font-family: GILROY-MEDIUM
  font-size: 20px
  text-align: center
  max-width: 650px
  color: white
  line-height: 1.8

.contents
  z-index: 1
  padding-top: 82px
.bg
  width: 100%
  height: 875px
  position: absolute
  img
    display: block
    width: 100%
    height: 100%
    object-fit: cover
    // &:first-child
    //   height: 100%
    //   object-fit: cover
    // &:last-child
    //   position: relative
    //   top: -255px
.shadow-btn
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)

.cov-video
  position: fixed
  z-index: 5
  left: 0
  top: 2rem
  backdrop-filter: blur(4px)
  background-color: rgba(0,0,0,0.3)
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  video
    max-width: 800px
    max-height: 500px
    box-shadow: 0px 33.1868px 82.5983px rgba(0, 0, 0, 0.06), 0px 16.8008px 36.0077px rgba(0, 0, 0, 0.0405), 0px 6.63736px 13.4222px rgba(0, 0, 0, 0.03), 0px 1.45192px 4.77521px rgba(0, 0, 0, 0.0195)
    border-radius: 5px
.d
  img
    max-width: 1000px
    height: max-content
    max-height: 714px
@media screen and (max-width: 992px)
  .contents
    padding-top: 2.5rem
  .title-1
    font-size: 32px
  .subtitle-1
    font-size: 16px
  .bg
    height: 500px
    // img
    //   &:last-child
    //     display: none
  .d
    img
      max-width: 100%
      height: unset
</style>