<template>
  <div class="previous-orders-invoices">
    <div class="">
      <div class="d-lg-flex justify-content-between mb-3" v-if="!hideHeader">
        <div class="d-lg-flex align-items-center">
          <div class="title-part mb-3 mb-lg-0">
            <p class="mb-0 d-title">{{title}}</p>
            <!-- <div class="below-title"></div> -->
          </div>
          <!-- <p class="f-text ml-lg-4 mb-lg-0" v-if="statusFilter">All Orders</p> -->
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'pendingapproval' }]" v-if="statusFilter" @click="setStatus('pendingapproval')" >Pending Approval</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'processing' }]" v-if="statusFilter" @click="setStatus('processing')" >Processing</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'shipped' }]" v-if="statusFilter" @click="setStatus('shipped')" >Shipped</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'delivered' }]" v-if="statusFilter" @click="setStatus('delivered')" >Delivered</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'backordered' }]" v-if="statusFilter" @click="setStatus('backordered')" >Backordered</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'returned' }]" v-if="statusFilter" @click="setStatus('returned')" >Returned</p>
          <p :class="['f-text ml-lg-4 mb-lg-0 cursor-pointer', { 'ac': status == 'complete' }]" v-if="statusFilter" @click="setStatus('complete')" >Complete</p>
        </div>
        <!-- <div class="d-none d-lg-flex flex-column flex-lg-row justify-content-center align-items-center" v-if="dateFilters">
          <b-form-datepicker class="custom-dt-picker" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }" locale="en-US" label-no-date-selected="-"></b-form-datepicker>
          <p class="mb-0 d-title__3 mx-1">To</p>
          <b-form-datepicker class="custom-dt-picker" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }" locale="en-US" label-no-date-selected="-"></b-form-datepicker>
        </div> -->
      </div>
      <div class="table-section">
        <b-table :items="items" :fields="fields" :thead-class="thClass" table-class="c-table" @row-clicked="onRowClick" ref="table">
          <template #cell(name)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">
              <div class="supplier-name d-flex align-items-center">
                <div class="avatar__super-mini mr-2">
                  <img :src="row.item.vendor.logo" alt="" class="w-100 h-100" v-if="row.item.vendor.logo">
                  <span v-else>{{row.item.vendor.name[0]}}</span>
                </div>
                <span class="d-title__3 ml-0">{{row.item.vendor.name}}</span>
              </div>
            </div>
          </template>
          <template #cell(id)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">{{row.item.id}}</div>
          </template>
          <template #cell(order_date)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">{{formatDateTime(row.item.order_date)}}</div>
          </template>
          <template #cell(total_items)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">
              <p class="mb-0">{{row.item.total_items}}</p>
            </div>
          </template>
          <template #cell(total_amount)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">
              <span class="pl-2-px">${{row.item.total_amount}}</span>
            </div>
          </template>
          <template #cell(status_display_text)="row">
            <div :class="['inner d-flex align-items-center', {'hover-color-changeable': hoverColorChange}]">
              <!-- <div :class="['status-indicator mr-2', {'one': row.item.status == 'Shipped' || row.item.status == 'COMPLETE'},{'two': row.item.status == 'Processing'},{'three': row.item.status == 'Refund'},]">
                <div class="indc"></div>
              </div> -->
              <span :class="['st-text']">{{row.item.status_display_text}}</span>
            </div>
          </template>
          <template #cell(action)="row">
            <div :class="['inner', {'hover-color-changeable': hoverColorChange}]">
              <div class="view-btn bg-primary ml-lg-2" style="min-width: 140px" v-if="!invoiceBtn && row.item.status == 'pendingapproval' && role == 1">
                Approve / Reject
              </div>
              <!-- <div v-if="!invoiceBtn && row.item.status == 'pendingapproval' && role == 1">
                <Button style="min-width: 74px" :text="`Approve Order`" classes="re-order-btn" :onclick="() => approveOrder(row.item)" :submitting="approvingOrder && selectedOrder.id == row.item.id" />
                <Button style="min-width: 74px" text="Reject Order" classes="danger__2 re-order-btn ml-2" :onclick="() => showRejectOrderModal(row.item)" />
              </div> -->
              <Button :text="`${downloadingInvoice && selectedOrder.id == row.item.id ? 'Downloading':'Download'} Invoice`" classes="re-order-btn" :onclick="() => downloadInvoice(row.item)" :submitting="downloadingInvoice && selectedOrder.id == row.item.id" v-else-if="invoiceBtn" />
              <div class="view-btn bg-primary ml-lg-2" v-else>View</div>
            </div>
          </template>
          <template #row-details="{ item }">
            <div class="order-details py-3">
              <!-- <div class="d-flex justify-content-between align-items-center mb-3" @click="onRowClick(item)">
                <p class="mb-0 d-title__6 cursor-pointer">Order : #{{item.id}} Items</p>
                <p class="mb-0 d-title__6 cursor-pointer" style="color: #1c455a;">Close Drop Down</p>
              </div> -->
              <order-items :order="item" :orders="item.products" :reject-reason-btn="item.status == 'pendingapproval'" :rejectReasons="rejectReasons" @order-item-change="v => onOrderItemChange(v, item)" :prevReasons="orderItems[item.id]" @status-updated="onStatusUpdated" :cartData="cartData" />
              <div>
                <div class="dash"></div>
                <div class="d-flex justify-content-end py-3 px-3">
                  <Button style="min-width: 74px" :text="`Submit`" classes="re-order-btn" :onclick="() => approveOrder(item)" :submitting="approvingOrder && selectedOrder.id == item.id" />
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="approvalModalDiv">
      <modal :modal="approvalModal" @change="toggleApprovalModal" size="lg" c-classes="px-0 py-4 order-approval-md" dialog-class="order-approval-modal">
        <div class="px-3">
          <p class="d-title mb-0" v-if="items.length>0">Order Placed : {{formatDateTime(items[0].order_date)}}</p>
          <all-orders th-class="d-none" :hide-header="true" :hover-color-change="false" :ids="$route.query.order_approval_reject" />
        </div>
      </modal>
    </div>
    <modal :modal="orderApprovedModal" @change="toggleOrderApprovedModal" size="md" c-classes="order-app-or-rej-modal">
      <div class="d-flex justify-content-center mb-40-px">
        <logo class="logo" />
      </div>
      <p class="text-center d-title text-danger__2">Order approval processed</p>
      <p class="text-center d-desc mb-4">We've processed your order approval, users of your office will receive an email with the updated status of this order</p>
      <div class="d-flex justify-content-center">
        <p class="text-center cursor-pointer text-primary d-title" @click="v => {orderApprovedModal = false; approvalModal = false;}">View Order Status</p>
      </div>
    </modal>
    <modal :modal="orderRejectionModal" @change="toggleOrderRejectionModal" size="md" c-classes="order-app-or-rej-modal">
      <div v-if="selectedOrder">
        <validation-observer ref="formObs" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(rejectOrder)">
            <div class="d-flex justify-content-center mb-40-px">
              <logo class="logo" />
            </div>
            <p class="text-center d-title text-danger__2">Reject the {{selectedOrder.vendor.name}} order placed on {{formatDateTime(selectedOrder.order_date)}}</p>
            <p class="text-center d-desc mb-4">If you have a specific reason as to why you are rejecting the order you can leave your comments below, or just cliick “Reject Order” to process the rejection without comments</p>
            <validation-provider name="Reason" rules="required" v-slot="validationContext">
              <b-textarea class="custom-input-textarea" placeholder="Input order rejection notes..." :rows="5" no-resize v-model="rejected_reason" :state="getValidationState(validationContext)"></b-textarea>
              <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
            </validation-provider>
            <div class="d-flex flex-column align-items-center justify-content-center mt-4">
              <Button text="Reject Order" style="min-width: 150px" classes="custom-btn danger__2" :onclick="rejectOrder" :submitting="rejectingOrder" />
              <p class="text-center cursor-pointer text-primary d-title f-12-px mt-3" @click="v => {orderRejectionModal = false;}">Close</p>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import OrderItems from '@/components/dashboard/orders/OrderItems'
import Button from '@/components/ui/Button'
import moment from 'moment'
import Modal from '@/components/modals/Modal'
import Logo from '@/components/logo/Logo'
import FormMixin from '@/mixins/FormMixin'
export default {
  name: 'AllOrders',
  mixins: [OfficeMixin, FormMixin],
  components: {
    OrderItems,
    Button,
    Modal,
    Logo,
  },
  props: {
    hideHeader: {
      type: Boolean,
    },
    statusFilter: {
      type: Boolean,
      default: true,
    },
    dateFilters: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "All Orders",
    },
    onlyTable: {
      type: Boolean,
      default: false,
    },
    'invoice-btn': Boolean,
    'hover-color-change': {type: Boolean, default: true},
    approvalModalDiv: {type: Boolean},
    thClass: {type: String, default: ''},
    ids: {type: String,},
  },
  data: () => ({
    items: [],
    fetching: true,
    tableSettings: {
      rows: 100,
      perPage: 10,
      currentPage: 1
    },
    downloadingInvoice: false,
    selectedOrder: null,
    approvingOrder: false,
    rejectingOrder: false,
    status: null,
    approvalModal: false,
    rejectReasons: [
      { value: 'noneed', text: 'Not Needed' },
      { value: 'wrong', text: 'Wrong Item' },
      { value: 'expensive', text: 'Too Expensive' },
      { value: 'other' , text: 'Other' },
    ],
    orderApprovedModal: false,
    orderRejectionModal: false,
    selectedOrder: null,
    orderItems: {},
    rejected_reason: null,
  }),
  beforeDestroy(){
    this.$BUS.$off('filter:filter-change')
    this.$BUS.$off('cart-data')
  },
  created(){
    this.fetchOrders()
    this.$BUS.$on('filter:filter-change', this.onFilterChange)
    if(this.$route.query.order_approval_reject){
      this.approvalModal = true
    }
    this.$BUS.$on('cart-data', v => this.cartData = v)
  },
  watch: {
    'tableSettings.currentPage'(v){
      this.fetchOrders(true, v)
    },
    status(v){
      this.fetchOrders(true)
    },
  },
  computed: {
    ...mapGetters('USER', ['role']),
    fields(){
      // if(this.onlyTable) return [
      //   { key: 'name', label: 'Vendor Name', sortable: false },
      //   { key: 'order_date', label: 'Order Date', sortable: false },
      //   { key: 'total_items', label: '# Items', sortable: false },
      //   { key: 'total_amount', label: 'Order Total', sortable: false },
      //   { key: 'status', label: 'Status', sortable: false },
      // ]
      return [
        // { key: 'id', label: 'Order Number', sortable: false },
        { key: 'order_date', label: 'Order Date', sortable: false, tdClass: 'order-date' },
        { key: 'name', label: 'Vendor Name', sortable: false, tdClass: 'vendor-name' },
        { key: 'total_items', label: 'Quantity of Items', sortable: false, tdClass: 'qty-td', thClass: 'text-center' },
        { key: 'total_amount', label: 'Order Total', sortable: false, tdClass: 'order-total-td' },
        { key: 'status_display_text', label: 'Order Status', sortable: false },
        { key: 'action', label: 'Order Details', sortable: false, thClass: 'text-center' },
      ]
    },
  },
  methods: {
    ...mapActions('ORDER', ['GET_INVOICE', 'FETCH_VENDOR_ORDERS', 'APPROVE_ORDER']),
    onStatusUpdated(item, order){
      let orderIndex = this.items.findIndex(o => o.id == order.id)
      if(orderIndex > -1){
        let itemIndex = this.items[orderIndex].products.findIndex(p => p.id == item.id)
        if(itemIndex > -1){
          this.items[orderIndex].products[itemIndex].status = item.status
          this.items[orderIndex].products[itemIndex].status_display_text = item.status_display_text
          if((this.items[orderIndex].products.filter(p => p.status == 'received')).length == this.items[orderIndex].products.length){
            this.items[orderIndex].status = 'complete'
            this.items[orderIndex].status_display_text = 'Complete'
          }
          // else {
          //   this.items[orderIndex].status = 'processing'
          //   this.items[orderIndex].status_display_text = 'Processing'
          // }
        }
      }
    },
    onOrderItemChange(v, item){
      this.orderItems[item.id] = v
    },
    toggleApprovalModal(v){
      if(v != this.approvalModal) this.approvalModal = v != null ? v : false
    },
    toggleOrderApprovedModal(v){
      if(v != this.orderApprovedModal) this.orderApprovedModal = v != null ? v : false
    },
    toggleOrderRejectionModal(v){
      if(v != this.orderRejectionModal) this.orderRejectionModal = v != null ? v : false
      this.rejected_reason = null
    },
    setStatus(v){
      if(this.status != v) this.status = v
      else this.status = null
    },
    vendorsPerOrder(item){
      let vendors = ((item.vendor_orders.map(vo => vo.products.map(p => p.product.vendor))).flat()).filter(f => f)
      let ids = (vendors.map(v => v.id)).filter((value, index, self) => self.indexOf(value) === index)
      return ids.map(id => vendors.find(v => v.id == id))
    },
    onRowClick(row, index, event){
      console.log(event)
      if(row._showDetails){
        this.$set(row, '_showDetails', false)
      }else{
        this.items.forEach(item => {
          this.$set(item, '_showDetails', false)
        })
        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    async onFilterChange(filters){
      let obj = {}
      if(filters.budget_type) obj.budget_type = filters.budget_type
      if(filters.date_range) obj.date_range = filters.date_range
      if(filters.selectedDate.startDate && filters.selectedDate.endDate){
        let sta = moment(filters.selectedDate.startDate).format('YYYY-MM-DD')
        let end = moment(filters.selectedDate.endDate).format('YYYY-MM-DD')
        obj.start_date = sta
        obj.end_date = end
      }
      if(
        (filters.selectedDate.startDate && filters.selectedDate.endDate)
        || (!filters.selectedDate.startDate && !filters.selectedDate.endDate)
      ) this.fetchOrders(true, undefined, obj)
    },
    async fetchOrders(fetching = true, page = undefined, filters = {}){
      this.fetching = fetching
      try{
        let payload = {
          office: this.officeId,
          filters
        }
        if(page) payload.filters.page = page
        if(this.status) payload.filters.status = this.status
        if(this.ids) payload.filters.ids = this.ids
        let res = await this.FETCH_VENDOR_ORDERS(payload)
        this.tableSettings.rows = res.total
        this.tableSettings.perPage = res.per_page
        this.tableSettings.currentPage = res.current_page
        this.items = this.onlyTable ? res.data.splice(0,10):res.data
        setTimeout(()=>{
          if(this.items.length == 1){
            this.onRowClick(this.items[0])
          }
        },500)
      }catch(e){}
      this.fetching = false
    },
    str2bytes (str) {
      var bytes = new Uint8Array(str.length);
      for (var i=0; i<str.length; i++) {
          bytes[i] = str.charCodeAt(i);
        }
        return bytes;
    },
    async downloadInvoice(order){
      this.selectedOrder = order
      this.downloadingInvoice = true
      try{
        let res = await this.GET_INVOICE({ office: this.officeId, orderId: order.id })
        console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data],{type:'application/zip'}))
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', 'invoice.zip')
        document.body.appendChild(link);
        link.click();
      }catch(e){
        console.log(e)
      }
      this.downloadingInvoice = false
      this.selectedOrder = null
    },
    async showRejectOrderModal(order){
      this.selectedOrder = order
      this.orderRejectionModal = true
    },
    async approveOrder(order){
      this.selectedOrder = order
      this.approvingOrder = true
      try{
        let payload = { 
          office: this.officeId,
          orderId: order.id,
          is_approved: true,
        }
        if(this.orderItems[order.id]){
          payload.rejected_items = this.orderItems[order.id].filter(i => i.rejected_reason != null)
        }else{
          payload.rejected_items = []
        }
        let res = await this.APPROVE_ORDER(payload)
        this.fetchOrders()
        this.orderApprovedModal = true
      }catch(e){
        console.log(e)
      }
      this.approvingOrder = false
      this.selectedOrder = null
    },
    async rejectOrder(order){
      this.rejectingOrder = true
      try{
        let payload = { 
          office: this.officeId,
          orderId: this.selectedOrder.id,
          is_approved: false,
          rejected_reason: this.rejected_reason
        }
        let res = await this.APPROVE_ORDER(payload)
        this.fetchOrders()
        this.toggleOrderRejectionModal(false)
      }catch(e){
        console.log(e)
      }
      this.rejectingOrder = false
      this.selectedOrder = null
    },
    formatDateTime(d) {
      if (!d || !moment(d).isValid) return "";
      return moment(d, 'YYYY-MM-DD').format("MM/DD/YY");
    },
  },
}
</script>

<style lang="sass" scoped>

.order-app-or-rej-modal
  .logo
    width: 115px
    height: 32px
::v-deep .rej-res-sel
  min-width: 150px !important
// ::v-deep .custom-modal
//   &.order-approval-md
.view-btn
  min-height: 29px
  min-width: 54px
  border-radius: 4px
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  font-size: 12px
  font-family: GILROY-BOLD
  font-stretch: normal
  font-style: normal
  line-height: normal
.previous-orders-invoices
  .order-details
    border: solid 1px #E5EAEC
  .f-text
    opacity: 0.2
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.71
    color: #000000
    &.ac
      opacity: 1
  .title-part
    .below-title
      width: 95px
      height: 3px
      border-radius: 8px
      background-color: #49a2cb
  ::v-deep .c-table
    @media screen and (max-width: 992px)
      width: max-content
      min-width: 100%
    thead
      tr
        th
          background-color: #ebf4f8
          border-top: none
          border-bottom: none
          font-family: GILROY-BOLD
          font-size: 12px
          font-weight: 600
          font-stretch: normal
          font-style: normal
          color: #1c455a
          padding-top: 10px
          padding-bottom: 10px
          &:first-child
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
          &:last-child 
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
    tbody
      tr
        td
          border-top: none
          border-bottom: none
          padding: 12px 0 0 0
          font-size: 12px
          font-weight: 500
          font-stretch: normal
          font-style: normal
          line-height: 2
          color: #000000
          .inner
            cursor: pointer
            padding: 12px
            border-top: solid 1px #E7ECEE
            border-bottom: solid 1px #E7ECEE
            height: 55px
            display: flex
            align-items: center
            .st-text
              &.one
                color: #7cd878
              &.two
                color: #ff9f1d
              &.three
                color: #ff1dc3
            .status-indicator
              width: 17px
              height: 17px
              border-radius: 50%
              display: flex
              justify-content: center
              align-items: center
              .indc
                width: 11px
                height: 11px
                border-radius: 50%
              &.one
                background-color: #F2FBF1
                .indc
                  background-color: #7cd878
              &.two
                background-color: #F0EDE4
                .indc
                  background-color: #ff9f1d
              &.three
                background-color: #EFE1F4
                .indc
                  background-color: #ff1dc3
          &:first-child
            .inner
              border-left: solid 1px #E7ECEE
              border-top-left-radius: 4px
              border-bottom-left-radius: 4px
          &:last-child
            @media screen and (min-width: 992px)
              width: 255px
            .inner
              border-right: solid 1px #E7ECEE
              border-top-right-radius: 4px
              border-bottom-right-radius: 4px
              justify-content: center
          .re-order-btn
            min-height: 27px
          &.qty-td
            @media screen and (min-width: 992px)
              width: 193px
            p
              min-width: 27px
              height: 25px
              width: min-content
              flex-grow: 0
              // border-radius: 4px
              // border: solid 1px #e6eaeb
              display: flex
              justify-content: center
              margin: 0 auto
          &.order-date
            @media screen and (min-width: 992px)
              width: 125px
          &.vendor-name
            @media screen and (min-width: 992px)
              width: 247px
          &.order-total-td
            @media screen and (min-width: 992px)
              width: 150px
        &:hover
          .hover-color-changeable
            background-image: linear-gradient(to bottom, #49a2cb -84%, #ffffff 111%)
            opacity: 0.75
            border-top: solid 1px transparent
            border-bottom: solid 1px #ffffff
        &.b-table-has-details
          td
            .inner
              border-bottom: none
            &:first-child
              .inner
                border-bottom-left-radius: 0px
            &:last-child
              .inner
                border-bottom-right-radius: 0px
        &.b-table-details
          td
            padding-top: 0
            .order-details
              border-top: none
              padding-top: 5px !important
</style>