<template>
  <div class="d-card px-3 py-3">
    <div class="d-flex justify-content-between">
      <img src="@/assets/images/promo_anly.png" alt="" style="width: 50px; height: 50px;" class="">
      <div class="ml-3">
        <p class="d-title mb-1">Order Summary</p>
        <p class="d-desc mb-0">Click the Ordo icon to search and apply promo codes to your order</p>
      </div>
    </div>
    <div class="d-flex mt-3">
      <b-input class="custom-input" placeholder="or manually input promo code"></b-input>
      <Button text="Apply" style="min-width: 66px;" class="ml-3" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
export default {
  components: {Button, },
}
</script>