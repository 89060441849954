<template>
  <div class="bg">
    <div class="position-relative cover-contents px-3">
      <p class="title-1 mx-auto text-white">Our Features</p>
      <p class="subtitle-1 mx-auto mb-0 text-white">Easy integration, reliable delivery, great documentation: integrate with the email API that developers love and start sending in minutes.</p>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
export default {
  components: { Button, }
}
</script>

<style lang="sass" scoped>
@import '../_landing_common'
.bg
  background-image: url(../HeaderOrdo.svg)
  background-size: cover
  background-repeat: no-repeat

.cover-contents
  .subtitle-1
    max-width: 590px
  .subtitle-2
    font-family: GILROY-BOLD
    font-size: 17px
</style>