<template>
  <div class="accept-invite d-flex flex-column align-items-center">
    <!-- <auth-logo class="mb-5" /> -->
    <accept-invite-card />
  </div>
</template>

<script>
import AcceptInviteCard from '@/components/auth/AcceptInviteCard'
import AuthLogo from '@/components/auth/AuthLogo'
export default {
  components: {
    AcceptInviteCard,
    AuthLogo,
  },
}
</script>

<style lang="sass" scoped>
.logo
  width: 50px
</style>