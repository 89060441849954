<template>
  <div class="orders-page">
    <admin-header class="mb-3" title="Track It, Receive It, Complete It" redirect="CreateAnOrder" :searchField="true"
      desc="Here you can manage all of your orders. Click on an order below to track it from start to finish. You can even process a return, and easily re-order your items.">
      <template #btns>
        <div class="d-none d-lg-flex" style="max-width: 375px">
          <b-btn class="custom-btn danger__2 w-50">Tutorial</b-btn>
          <b-btn class="custom-btn danger__2 ml-3 w-50">Check promotions</b-btn>
        </div>
      </template>
    </admin-header>
    <filter-com />
    <div class="mt-3 mb-4">
      <order-activity />
    </div>

    <order-sync class="mt-4" v-if="showSpin" title="Syncing Order Data"
      desc="Now that you’ve linked your vendors we are pulling all your products ordered and creating your inventory list." />
    <order-no-data v-else-if="showEmpty" class="mt-4 d-card py-5" />
    <div v-else class="admin-orders">
      <order-component :hover-color-change="false" class="oders-table-card d-card px-3 py-3" :approvalModalDiv="true"
        @show-empty="onShowEmpty" @items="onItemFetched" />
    </div>

    <OrdersByBudgetTypes :recent="false" :hide-search-field="true" :show-title="true" :statusFilter="false"
      :dateFilters="false" :invoice-btn="true" :hover-color-change="false" style="font-size: 16px;"
      class="mt-4 d-card px-3 py-3" @items="onItemFetched" />
    <div class="bar-chart" style="border-radius: 12px;">
      <div class="d-md-flex px-3 justify-content-between">
        <p class="d-title mb-0">Top Vendors by Spent</p>
      </div>
      <div v-if="chartXAxis.length > 0">
        <apexchart ref="myChart" width="1000" type="bar" :options="chartOptions()" :series="series()"></apexchart>
      </div>
      <div v-else>
        <order-no-data />
      </div>
    </div>
    <DashboardFooter class="mt-3" />
  </div>
</template>


<script>
import AllOrders from '@/components/dashboard/orders/AllOrders'
import OrderComponent from '@/views/admin_dashboard/components/order_component'
import DHead from '@/components/dashboard/DHead'
import DashHead from '@/components/dashboard/DashHead'
import AdminHeader from '@/views/admin_dashboard/components/admin_header.vue'
import OrderActivity from '@/components/dashboard/OrderActivity'
import TopSuppliersBySpendOrders from '@/components/dashboard/TopSuppliersBySpendOrders'
import OfficeMixin from '@/mixins/OfficeMixin'
import moment from 'moment'
import FilterCom from '@/components/dashboard/FilterCom'
import OrderNoData from '@/components/dashboard/OrderNoData'
import OrderSync from '@/components/dashboard/vendor-fetching/OrderSync'
import { mapActions } from 'vuex'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
import OrdersByBudgetTypes from '@/components/dashboard/orders/OrdersByBudgetTypes.vue'
import Filter from '@/mixins/Filter'
import BarChart from '@/components/dashboard/BarChart'
import VueApexCharts from 'vue-apexcharts'


export default {
  components: {
    AllOrders,
    DHead,
    DashHead,
    AdminHeader,
    OrderActivity,
    TopSuppliersBySpendOrders,
    FilterCom,
    OrderNoData,
    OrderSync,
    DashboardFooter,
    OrderComponent,
    OrdersByBudgetTypes,
    apexchart: VueApexCharts,
    BarChart
  },
  mixins: [OfficeMixin, Filter],
  data: () => ({
    showEmpty: false,
    timer: null,
    showSpin: false,
    orders: null,
    seriesData: [],
    chartXAxis: []
  }),
  created() {
    this.$BUS.$on('filter:filter-change', filters => this.onFilterChange(filters, async (filt) => {
      this.getData(filt)
    }))
    this.getData({
      selectedDate: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
      }
    });
    this.fetchTopFive();
    this.fetchOrders();
  },
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change')
    if (this.timer) clearTimeout(this.timer)
  },

  computed: {
    topVendorSpent() {
      return this.$store.getters['ADMIN_VIEW/getTopVendorSpent'];
    },

    currentMonth() {
      return moment().format('MMMM')
    },
  },

  methods: {
    ...mapActions('ADMIN_VIEW', ['FETCH_VENDOR_ORDERS', 'FETCH_TOP_VENDORS']),
    series() {
      return [{
        name: 'Top Vendors',
        data: this.seriesData
      }];
    },

    chartOptions() {
      return {
        chart: {
          id: 'top-example'
        },
        xaxis: {
          categories: this.topVendorSpent.map(s => s.name)
        },
        yaxis: {
          labels: {
            formatter: (value) => ``
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 5
          },
        },
        fill: {
          colors: [function ({ value, dataPointIndex, seriesIndex, w }) {
            if (seriesIndex == 0) return '#13ADD9'
            return '#13ADD9'
          }]
        },
      };
    },

    async fetchOrders() {
      let payload = {
        office: this.officeId,
      }
      let res = await this.FETCH_VENDOR_ORDERS(payload)
      if (res.total == 0) {
        this.showSpin = true
        this.timer = setTimeout(() => {
          this.fetchOrders()
        }, 30 * 1000)
      } else {
        if (this.timer) clearTimeout(this.timer)
      }
    },
    async fetchTopFive() {
      let payload = {
        office: this.officeId,
      }
      let res = await this.FETCH_TOP_VENDORS(payload);
      this.seriesData = res.map((_, index) => (index + 1) * 5).reverse();
      this.chartXAxis = res.map(e => e.name);
    },

    async fetchTopFiveWithFilters(filters) {
      let payload = {
        office: this.officeId,
      }
      this.setPeriod(filters)
      if (filters) payload = { ...payload, ...filters }
      let res = await this.FETCH_TOP_VENDORS(payload);
      this.seriesData = res.map((_, index) => (index + 1) * 5).reverse();
      this.chartXAxis = res.map(e => e.name);
    },

    async getData(filters) {
      let payload = { office: this.officeId }
      if (filters) payload = { ...payload, ...filters }
      const topFive = await this.FETCH_TOP_VENDORS(payload)
      this.seriesData = topFive.map((_, index) => (index + 1) * 5).reverse();
      this.chartXAxis = topFive.map(e => e.name);

    },
    onItemFetched(orders) {
      this.orders = orders
    },
    onShowEmpty() {
      this.showEmpty = true
    },
  },
}
</script>

<style lang="sass" scoped>

.bar-chart
  background-color: white
  border-radius: 15px0
  margin-top: 15px
  padding: 20px
  
.all-orders
  .oders-table-card
    @media (min-width: 992px)
      min-height: 522px
</style>