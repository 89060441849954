<template>
  <div class="welcome-to-ordo py-4 py-lg-5 px-4">
    <div class="wrapper">
      <p class="d-title__8 text-white" style="margin-bottom: 10px;">{{title}}</p>
      <p class="d-desc__2 text-white f-14-px" style="max-width: 400px; line-height: 1.7; font-family: Gilroy-Semibold">{{desc}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
  data: () => ({
    squeez: false,
  }),
  methods: {
    toggleSqueez(){
      this.squeez = !this.squeez
    },
  },
}
</script>

<style lang="sass" scoped>
.welcome-to-ordo
  border-radius: 12px
  box-shadow: 0 3px 25px 0 rgba(235, 235, 235, 0.16)
  // background-image: linear-gradient(to bottom, #8bde88, #60c95c)
  background-image: url('../../assets/images/head_cover/cart_checkout.svg')
  background-repeat: no-repeat
  background-size: 100% 100%
  // min-height: 200px
  position: relative
  min-height: 235px
  &.centered-contents
    display: flex
    flex-flow: column
    justify-content: center
  .character
    position: absolute
    right: 10%
    top: 0
    height: 100%
    img
      height: 90%
      width: 90%
</style>