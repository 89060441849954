<template>
  <validation-observer ref="updateConnVenInfoForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(updateConnVenInfo)">
      <div v-if="contactInfoForSelectedVendor">
        <div class="notf-p1 d-flex align-items-center justify-content-center mb-4">
          <p class="mb-0 d-title__2 text-center" style="line-height: 1">{{contactInfoForSelectedVendor.vendor.name}}</p>
          <img :src="contactInfoForSelectedVendor.vendor.logo" alt="" style="width: 20px" class="ml-2">
        </div>
        <div class="bx-wrapper px-2 py-2 d-flex align-items-center justify-content-center mb-3">
          <p class="mb-0"><span class="d-desc__8 f-12-px">Vendor Phone Number:</span> <span class="d-title__12 f-12-px gilroy-b">{{getContactPhone(contactInfoForSelectedVendor.vendor.slug)}}</span></p>
        </div>
        <div class="bx-wrapper px-2 py-2 d-flex align-items-center justify-content-center">
          <p class="mb-0"><span class="d-desc__8 f-12-px">Vendor Email Address:</span> <span class="d-title__12 f-12-px gilroy-b">{{getContactEmail(contactInfoForSelectedVendor.vendor.slug)}}</span></p>
        </div>
        <div class="dash my-4"></div>
        <p class="d-title__2 text-center">Local Rep Information</p>
        <validation-provider name="representative_full_name" rules="" v-slot="validationContext">
          <div class="bx-wrapper p-2 d-flex align-items-center">
            <p class="d-desc__8 f-12-px mb-0 ml-auto">Full Name : </p>
            <b-input class="custom-input info-inp no-border-inp back-no-img" v-model="contactInfoForSelectedVendor.representative_full_name" :state="getValidationState(validationContext)"></b-input>
          </div>
          <p class="text-danger__2 d-desc" v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</p>
        </validation-provider>
        <validation-provider name="representative_email" rules="" v-slot="validationContext">
          <div class="bx-wrapper p-2 d-flex align-items-center mt-3">
            <p class="d-desc__8 f-12-px mb-0 ml-auto" style="min-width: 73px;">Email Address : </p>
            <b-input class="custom-input info-inp no-border-inp back-no-img" v-model="contactInfoForSelectedVendor.representative_email" :state="getValidationState(validationContext)"></b-input>
          </div>
          <p class="text-danger__2 d-desc" v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</p>
        </validation-provider>
        <validation-provider name="representative_phone_number" rules="" v-slot="validationContext">
          <div class="bx-wrapper p-2 d-flex align-items-center mt-3">
            <p class="d-desc__8 f-12-px mb-0 ml-auto" style="min-width: 73px;">Phone Number : </p>
            <b-input class="custom-input info-inp no-border-inp back-no-img" v-model="contactInfoForSelectedVendor.representative_phone_number" :state="getValidationState(validationContext)"></b-input>
          </div>
          <p class="text-danger__2 d-desc" v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</p>
        </validation-provider>
        <validation-provider name="detail" rules="" v-slot="validationContext">
          <b-input class="d-none" :state="getValidationState(validationContext)"></b-input>
          <p class="text-danger__2 d-desc" v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</p>
        </validation-provider>
        <div class="d-flex justify-content-center mt-3">
          <Button :text="connVenInfoBtnText" :class="connVenInfoBtnClass" style="min-width: 170px" :submitting="updatingConVenInfo" :onclick="updateConnVenInfo" />
        </div>
        <div class="dash my-3"></div>
        <div class="d-flex justify-content-center">
          <p class="d-title text-danger__2 cursor-pointer mb-0" @click="$emit('close')">Close</p>
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import FormMixin from '@/mixins/FormMixin'
export default {
  components: { Button },
  mixins: [ OfficeMixin, FormMixin ],
  props: {
    venInfo: Object,
  },
  data: () => ({
    contactInfoForSelectedVendor: null,
    updatingConVenInfo: false,
    connVenInfoBtnText: 'Save',
    connVenInfoBtnClass: 'primary',
  }),
  created(){
    this.setVenInfo()
  },
  watch: {
    venInfo: {
      handler(v){ this.setVenInfo() }, deep: true
    },
  },
  methods: {
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS', 'UPDATE_CONNECTED_VENDOR_INFO']),
    setVenInfo(){
      this.contactInfoForSelectedVendor = Object.assign({}, this.venInfo)
    },
    async updateConnVenInfo(){
      if(this.connVenInfoBtnText == 'Updated' || this.updatingConVenInfo) return
      this.updatingConVenInfo = true
      try{
        let payload = {
          office: this.officeId,
          ...this.contactInfoForSelectedVendor
        }
        delete payload.vendor
        await this.UPDATE_CONNECTED_VENDOR_INFO(payload)
        this.connVenInfoBtnText = 'Updated'
        this.connVenInfoBtnClass = 'success'
        setTimeout(() => {
          this.connVenInfoBtnText = 'Save'
          this.connVenInfoBtnClass = 'primary'
          this.$emit('close')
        }, 1500)
      }catch(e){
        console.log(e.data, this.$refs.updateConnVenInfoForm)
        let errors = e.data
        this.$refs.updateConnVenInfoForm.setErrors(errors)
      }
      this.updatingConVenInfo = false
    },
    getContactInfo(slug){
      if(slug == 'net_32') return { email : 'support@net32.com', phone_number : '(919) 205-0448' }
      else if(slug == 'henry_schein') return { email : 'custserv@henryschein.com', phone_number : '1-800-372-4346, Extension 2' }
      else if(slug == 'darby') return { email : 'dawn.sharma@darby.com', phone_number : '(516) 688-6800 Ext. (4213)' }
      else if(slug == 'ultradent') return { email : 'onlineordersupport@ultradent.com', phone_number : '888.230.1420' }
      else if(slug == 'patterson') return { email : 'ptc.support@pattersoncompanies.com', phone_number : '1.866.449.7282' }
      else if(slug == 'implant_direct') return { email : 'css@implantdirect.com', phone_number : '1-888-649-6425' }
      else if(slug == 'edge_endo') return { email : 'edgehr@edgeendo.com', phone_number : '855-985-3636' }
      else if(slug == 'benco') return { email : 'N/A', phone_number : '1-800-462-3626' }
      else if(slug == 'dental_city') return { email : 'help@DentalCity.com', phone_number : '1-800-353-9595' }

      return null
    },
    getContactPhone(slug){
      let info = this.getContactInfo(slug)
      if(info) return info.phone_number
    },
    getContactEmail(slug){
      let info = this.getContactInfo(slug)
      if(info) return info.email
    },
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings'
.bx-wrapper
  height: 48px
  border-radius: 4px
  border: solid 1px #e9e9e9
  .info-inp
    width: 70%
    @media (min-width: 768px)
      width: 60%
</style>