<template>
  <div>
    <!-- <dash-head class="mb-4" /> -->
    <grow-loader v-if="fetchingData" />
    <div v-else class="cart-page">
      <SearchHeadCover class="d-lg-none" title="Your Cart Details"
        :desc="`View your cart below. Update your quantity, change the vendor, or save an item for later. If you don't want to purchase certain items right now, deselect the ''Send to Checkout'' button and the item will stay in your cart until next time.`" />
      <div class="d-flex flex-column flex-lg-row mt-3">
        <div class="l-part order-2 order-lg-1">
          <SearchHeadCover class="d-none d-lg-block" title="Your Cart Details"
            :desc="`View your cart below. Update your quantity, change the vendor, or save an item for later. If you don't want to purchase certain items right now, deselect the ''Send to Checkout'' button and the item will stay in your cart until next time.`" />
          <div class="d-card px-3 py-3 mt-3" v-for="(vendorOrder, index) in vendorsHasProducts" :key="index">
            <div class="d-lg-flex justify-content-between align-items-center gap-x-47-px gap-y-16-px">
              <div class="d-lg-flex justify-content-between align-items-center gap-12-px">
                <p class="mb-0">
                  <span class="d-title__11">{{ vendorOrder.vendor.name }}</span>
                  &nbsp;
                  <span class="d-desc__2 f-12-px">({{ vendorOrder.products.length }} item<span
                      v-if="vendorOrder.products.length > 1">s</span>)</span>
                </p>
                <p class="mb-0 d-title" v-if="
                  getDefaultShippingOption(
                    vendorOrder.vendor.id,
                    vendorOrder.total_amount
                  )
                ">
                  {{
                    getDefaultShippingOption(
                      vendorOrder.vendor.id,
                      vendorOrder.total_amount
                    ).name
                  }}
                  <b-tooltip :target="`tooltip-target-${vendorOrder.vendor.id}`" triggers="hover focus" placement="top"
                    boundary="window" custom-class="promotion-tooltip tooltip-size-md">
                    <p class="d-desc mb-0">
                      {{ getShippingNote(vendorOrder.vendor.id) }}
                    </p>
                  </b-tooltip>
                  <img src="@/assets/images/information.png" alt="" class="ml-2 mb-1 h-20-px w-20-px"
                    :id="`tooltip-target-${vendorOrder.vendor.id}`" v-if="getShippingNote(vendorOrder.vendor.id)" />
                </p>
                <p v-else class="mb-0 d-title">Standard Delivery</p>
              </div>
              <p class="mb-0 d-title ml-auto">
                Estimated Shipping :
                <span class="text-success" v-if="
                  shippingCost(
                    vendorOrder.vendor.slug,
                    vendorOrder.total_amount
                  ) == 0 || getPrice(vendorOrder) === 0
                ">Free</span>
                <span class="text-success" v-else>
                  ${{
                  Number(
                    shippingCost(
                      vendorOrder.vendor.slug,
                      vendorOrder.total_amount
                    )
                  ).toFixed(2)
                }} </span>

              </p>

              <p class="mb-0 d-title">
                Subtotal :
                <!-- <span class="text-success"
                  >${{ Number(vendorOrder.total_amount) +  Number(getShippingCost(
                        vendorOrder.vendor.slug,
                        vendorOrder.total_amount,0
                      )).toFixed(2) }}
                </span> -->
                
                <span class="text-success" v-if="reRender && getPrice(vendorOrder) !== 0"> 
                  ${{
                    (
                      Number(getPrice(vendorOrder)) +
                      Number(
                        shippingCost(
                          vendorOrder.vendor.slug,
                          vendorOrder.total_amount
                        )
                      )
                    ).toFixed(2)
                  }}
                </span>
                <span class="text-success" v-else>
                  $ 0
                </span>
              </p>
            </div>
            <div class="dash my-3"></div>
            <div class="table-section">
              <table class="w-100 cart-show-table">
                <thead>
                  <th>Description</th>
                  <th class="text-center">Unit Price</th>
                  <th class="text-center">Quantity</th>
                  <th class="text-center">Add to Checkout</th>
                </thead>
                <tbody>
                  <cart-product-tr :ref="`cart-product-tr-${order.id}`" :id="`cart-product-tr-${order.id}`"
                    v-for="(order, vOId) in vendorOrder.products" :key="vOId"
                    :order="{ ...order, sibling_products: [] }" :orderApproval="true" :orderId="order.id"
                    @change-rejected-reason="onChangeRejectedReason" @removed-vendor-item="onRemovedVendorItem"
                    @quantity-update="onQuantityUpdate" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="pl-lg-3 r-part order-1 order-lg-2">
          <order-summary-card :showPromo="true" :orderApproval="true" :orderApprovalTotal="total"
            :orderApprovalDentalProductTotal="getTotalOf('dental')"
            :orderApprovalFrontProductTotal="getTotalOf('office')" :orderApprovalRejectedItems="rejected_items"
            :itemsToCheckIn="allProducts" :orderId="order.id" @added-item-in-order="onAddedItemInOrder"
            @add-product-modal-close="onAddProductModalClose" />
        </div>
      </div>
      <DashboardFooter class="mt-3" />
    </div>
  </div>
</template>

<script>
import DashHead from "@/components/dashboard/DashHead";
import GoTo from "@/mixins/GoTo";
import CartMixin from "@/mixins/CartMixin";
import GrowLoader from "@/components/loaders/GrowLoader";
import CartProductTr from "@/components/cart/CartProductTr";
import CartCover from "@/components/cart/CartCover";
import OrderSummaryCard from "@/components/cart/OrderSummaryCard";
import RemainingBudgetCard from "@/components/cart/RemainingBudgetCard";
import PromoAnalyzer from "@/components/cart/PromoAnalyzer";
import CartPromoApply from "@/components/cart/CartPromoApply";
import SearchHeadCover from "@/components/SearchHeadCover";
import { mapActions, mapGetters, mapState } from "vuex";
import DashboardFooter from "@/components/dashboard/footer/DashboardFooter.vue";
import Modal from "@/components/modals/Modal";
import OfficeMixin from "@/mixins/OfficeMixin";
export default {
  components: {
    DashHead,
    GrowLoader,
    CartProductTr,
    CartCover,
    OrderSummaryCard,
    RemainingBudgetCard,
    SearchHeadCover,
    PromoAnalyzer,
    CartPromoApply,
    DashboardFooter,
    Modal,
  },
  mixins: [GoTo, OfficeMixin, CartMixin],
  data: () => ({
    fetchingData: true,
    order: null,
    rejected_items: [],
    isItemAdded: false,
    reRender: true
  }),
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters("OFFICE", ["connected_vendors"]),
    allProducts() {
      let products = [];
      if (this.order != null) {
        this.vendorsHasProducts.forEach((vendor_order) => {
          products = products.concat(vendor_order.products);
        });
      }
      console.log("Almost sorted")
      products.sort((x, y) => Number(y.item_inventory) - Number(x.item_inventory));
      console.log(products)
      return products;
    },
    ...mapGetters("CART", ["getEstimatedShippingPrice"]), // Import the getter if not already imported
    shippingPrice() {
      return this.numberWithCommas(this.getEstimatedShippingPrice.toFixed(2)); // Assuming getEstimatedShippingPrice returns the shipping price
    },
    // total() {
    //   let sum = 0;
    //   if (this.order != null) {
    //     this.vendorsHasProducts.map((vendor_order) => {
    //       for (let product of vendor_order.products) {
    //         sum +=
    //           Number(product.updated_unit_price || 0) *
    //             Number(product.quantity || 1) ;
    //       }
    //     });
    //   }
    //   return sum;
    // },

    total() {
      let sum = 0;
      let currentProduct = null;
      if (this.order != null) {
        this.vendorsHasProducts.map((vendor_order) => {

          let productSum = 0;
          for (let product of vendor_order.products) {
            if(product.status !== 'rejected') {
              productSum +=
                Number(product.updated_unit_price || 0) *
                Number(product.quantity || 1);
            }
           
            currentProduct = product;
          }
          if (currentProduct != null && currentProduct.status !== 'rejected') {
            if
              (currentProduct.product.vendor.slug == 'dental_city') {
            }
            productSum += this.shippingCost(
              currentProduct.product.vendor.slug,
              productSum,
            );
          }
          sum += productSum;
        });
      }
      return sum;
    },
    

    vendorsHasProducts() {
      let value = this.order.vendor_orders.filter(
        (order) => order.products.length > 0
      )

      value.forEach(items => {
        items.products.sort((x, y) => Number(y.item_inventory) - Number(x.item_inventory))
      })

      return value;
    },
  },
  methods: {
    ...mapActions("ORDER", ["FETCH_PENDING_ORDER"]),

    getPrice(vendorOrder) {
      let price = 0;
      for (let product of vendorOrder.products) {
        if(product.status !== "rejected") {
          price += Number(product.updated_unit_price * product.quantity);
        }
      }
      return price;
    },

    getShippingNote(vendorId) {
      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.id === vendorId
        );
        if (vendorInfo) {
          let slug = vendorInfo.vendor.slug;
          if (slug === "safco")
            return `All orders placed before 4:30pm central time Monday-Thursday (3:30pm on Friday) ship the same day. For customers within our next day delivery area, there's no faster service available in the industry.`;
          if (slug === "edge_endo")
            return `All orders placed by 5PM MST Monday–Thursday, and by 4PM MST on Friday, will be shipped SAME DAY.`;
          if (slug === "henry_schein")
            return `Future order prices may vary on date of processing. Final order total may differ than that displayed due to variations in product availability, discounts, taxes, shipping, hazardous materials, small order charges, and/or handling charges. This sale is subject to Henry Schien Terms and Conditions. Shipping Price: $12.95`;
          if (slug === "darby")
            return `Products sometimes ship from an alternate warehouse. This may result in longer shipping times. Shipping Price: $9.95`;
          if (slug === "patterson")
            return `Prices are subject to change. Actual invoice cost may differ from displayed amount due to taxes, shipping, and other adjustments. By selecting place order, you acknowledge you have read and agree to the Terms And Conditions and the Privacy Statement, which terms shall govern your purchase.`;
          if (slug === "ultradent")
            return `Orders placed after 5:00 PM EST will be processed the next business day.`;
        }
      }
      return null;
    },
    async fetchData() {
      this.fetchingData = true;
      const { data } = await this.FETCH_PENDING_ORDER({
        orderId: this.$route.params.order_id,
        office: this.officeId,
      });
      this.order = data.data;
      this.fetchingData = false;
    },

    getDefaultShippingOption(vendorId, total_amount) {

      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.id === vendorId
        );
        if (vendorInfo && vendorInfo.default_shipping_option) {
          let price = vendorInfo.default_shipping_option.price;
          if (vendorInfo.vendor.slug == "dental_city") {
            price = 12.99;
            if (total_amount > 249.99) price = 0;
          } else if (vendorInfo.vendor.slug == "safco") {
            price = 14.95;
            if (total_amount > 250) price = 0;
          } else if (vendorInfo.vendor.slug == "ultradent") {
            price = 14.95;
            if (total_amount > 285) price = 0;
          } else if (vendorInfo.vendor.slug == "darby") {
            price = 12.95;
            if (total_amount > 150) price = 9.95;
          }
        }
      }
    },
    getTotalOf(type) {
      let sum = 0;
      if (this.order != null) {
        this.vendorsHasProducts.map((vendor_order) => {
          vendor_order.products
            .filter((order_product) => order_product.budget_spend_type == type)
            .map((order_product) => {
              let unit_price = Number(
                order_product.updated_unit_price || order_product.unit_price
              );
              sum += unit_price * order_product.quantity;
            });
        });
      }
      return sum;
    },
    async onChangeRejectedReason(val) {
      let rejectedIndex = this.rejected_items.findIndex(
        (item) => item.order_product_id == val.order_product_id
      );
      if (rejectedIndex > -1) {
        if (val.rejected_reason != null)
          this.rejected_items[rejectedIndex].rejected_reason =
            val.rejected_reason;
        this.rejected_items.splice(rejectedIndex, 1);
      } else {
        this.rejected_items.push(val);
      }

      this.order.vendor_orders.forEach((vendorOrder) => {
          const productIndex = vendorOrder.products.findIndex((product) => product.id === val.order_product_id);
          // If a matching product is found, update its rejected_reason along with status
          if (productIndex !== -1) {
              vendorOrder.products[productIndex].rejected_reason = val.rejected_reason;
              vendorOrder.products[productIndex].status = 'rejected';
              vendorOrder.products[productIndex].status_display_text= "Rejected"
              return;
          }
      });
      this.reRender = false;
      this.reRender = true;
    },
    onRemovedVendorItem(id) {
      this.order.vendor_orders.forEach((vendorOrder, vendorOrderIndex) => {
        const index = vendorOrder.products.findIndex((p) => p.id == id);
        if (index > -1)
          this.order.vendor_orders[vendorOrderIndex].products.splice(index, 1);
      });
    },
    onAddedItemInOrder() {
      this.isItemAdded = true;
    },
    onAddProductModalClose(val) {
      if (val == false) {
        this.fetchData();
      }
    },
    onQuantityUpdate(data) {
      this.order.vendor_orders.forEach((vendorOrder, vendorOrderIndex) => {
        const index = vendorOrder.products.findIndex(
          (p) => p.id == data.vendor_order_product_id
        );
        if (index > -1) {
          const diff =
            data.quantity -
            this.order.vendor_orders[vendorOrderIndex].products[index].quantity;
          let unit_price = Number(
            this.order.vendor_orders[vendorOrderIndex].products[index]
              .updated_unit_price || 0
          );
          if (unit_price == 0) {
            unit_price = Number(
              this.order.vendor_orders[vendorOrderIndex].products[index]
                .unit_price || 0
            );
          }
          const total_amount = Number(
            this.order.vendor_orders[vendorOrderIndex].total_amount
          );
          this.order.vendor_orders[vendorOrderIndex].total_amount = Number(
            total_amount + unit_price * diff
          ).toFixed(2);
          this.order.vendor_orders[vendorOrderIndex].products[index].quantity =
            data.quantity;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>

.cart-page
  .l-part
    width: -webkit-fill-available
    @media screen and (min-width: 992px)
      // height: 90vh
      // overflow-y: auto
    @media screen and (max-width: 992px)
      width: 100%
  .r-part
    width: 100%
    max-width: calc( 267px + 1rem)
    position: relative
    .d-card
      @media screen and (min-width: 992px)
        position: sticky
        top: 80px
    @media screen and (max-width: 992px)
      max-width: 100%
  .checkout-card
    ::v-deep .c-chk
      label
        font-family: GILROY-Bold
        font-size: 14px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: 1.71
        text-align: left
        color: #000
    .c-l-part
      border-right: solid 1px #EBF4F7
      width: 100%
    .c-r-part
      width: 100%
      .checkout-pricing-table
        tr
          td
            padding-top: 8px
            padding-bottom: 8px
        .total-row
          td
            border-top: solid 1px #EBF4F7
      .checkout-pay-to-btn-wrapper
        border: solid 1px #EBF4F7
        border-radius: 4px
</style>
