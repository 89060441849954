import { render, staticRenderFns } from "./admin_header.vue?vue&type=template&id=24c089f7&scoped=true"
import script from "./admin_header.vue?vue&type=script&lang=js"
export * from "./admin_header.vue?vue&type=script&lang=js"
import style0 from "./admin_header.vue?vue&type=style&index=0&id=24c089f7&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c089f7",
  null
  
)

export default component.exports