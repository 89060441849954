<template>
  <div>
    <order-sync class="mt-4" v-if="showSpin" title="Syncing Order Data"
      desc="Now that you’ve linked your vendors we are pulling all your products ordered and creating your inventory list." />
    <inventory-no-data v-else-if="showEmpty && searchQ == null" class="d-card py-5" />
    <div class="d-flex flex-column flex-lg-row" v-else>
      <Button class="custom-btn primary w-100 mb-3 d-lg-none" :onclick="() => showFilter = !showFilter"
        :submitting="downloadingList" text="Filters" />
      <div class="position-relative" v-if="showFilter">
        <div class="mb-3 filters">
          <div class="d-card mb-3 py-2 px-3">
            <b-form @submit.prevent="search" class="w-100">
              <div class="d-flex align-items-center search-wrapper w-100">
                <b-input ref="inp" v-model="searchQ" class="custom-input no-border-inp bg-transparent w-100"
                  placeholder="Search Products in your Inventory List"></b-input>
                <img src="@/assets/images/chear.svg" alt="" style="height: 20px; width: 20px" class="cursor-pointer"
                  v-if="searchQ && searchQ.trim().length > 0" @click="resetSearch" />
                <img src="@/assets/images/magnifying-glass-tilted.png" alt="" style="height: 20px; width: 20px"
                  class="mx-2 cursor-pointer" @click="search">
              </div>
            </b-form>
          </div>
          <Button class="custom-btn primary w-100" :onclick="printInventoryList" :submitting="downloadingList"
            text="Print Inventory List" />
          <p class="mt-1 ml-1 f-10-px text-danger__2" v-if="downloadingList">It might take a few minutes to generate the
            list.</p>
          <category-sidebar class="mt-3" :selectedCategory="selectedCategory" :selectedVendor="selectedVendor"
            :setSelectedCategory="setSelectedCategory" :setSelectedVendor="setSelectedVendor" :categories="categories"
            :inventoryVendors="inventoryVendors" @cat-change="onChangeCat" @vendor-change="onChangeVendor"
            @filter-by-price="onFilterByPrice" @reload="() => $forceUpdate()" :fetchingCategories="fetchingCategories"
            :fetchingInventoryVendors="fetchingInventoryVendors" @fetch-cat="fetchProductCategories"
            :onClickVendor="onClickVendor" />
        </div>
      </div>
      <div class="category-wise-products-wrapper w-fill mt-lg-0">
        <div class="d-flex flex-column">
          <div class="px-lg-3 w-fill prods">
            <SearchedProductHeader :skeleton="true" v-if="fetchingCategories || fetchingOrders" style="height: 50px" />
            <div class="d-card px-3 pt-3 pb-3" v-if="fetchingCategories || fetchingOrders"
              :style="{ 'border-top-left-radius': '0', 'border-top-right-radius': '0', }">
              <product-card-horizontal :skeleton="true" />
            </div>
            <inventory-search-no-data v-else-if="$store.state.PRODUCT.inventoryItems.length == 0 && searchQ != null"
              class="d-card py-5" :searchQ="searchQ" />
            <no-category-product v-else-if="selectedCategory && $store.state.PRODUCT.inventoryItems.length == 0" />
            <div v-else class="h-100 d-flex flex-column">
              <div v-if="filteredCategories.length > 0">
                <div v-for="(category, cI) in filteredCategories" :key="cI" class="mb-4">
                  <SearchedProductHeader id="top-container" :title="`${category.name}`" style="height: 50px" />
                  <div ref="paginatedContainer" class="products-wrapper d-card px-3 pt-3"
                    :style="{ 'border-top-left-radius': '0', 'border-top-right-radius': '0', }">
                    <div v-for="(order, oI) in ordersByCat(category)" :key="oI">
                      <product-card-horizontal :no-border="oI == 0" :inventoryBtns="true" :inventory="true" :prod="order"
                        :removeBtnInventory="true" :orderId="order.id" @removed="onProductRemoved" :cart-data="cart"
                        @product-image-update="onProductImageUpdate" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <no-search-result class="m-auto" />
              </div>
              <b-pagination v-if="$store.state.PRODUCT.inventoryItems.length > 0" class="custom-pagination mt-auto mb-0"
                v-model="pagination.currentPage" :total-rows="pagination.rows" :per-page="pagination.perPage"
                align="center" @change="handlePaginationChange" hide-ellipsis hide-goto-end-buttons />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import CartMixin from '@/mixins/CartMixin'
import GrowLoader from '@/components/loaders/GrowLoader'
import CategorySidebar from '@/components/dashboard/CategorySidebar'
import InventoryProductCardCategory from '@/components/shopping/InventoryProductCardCategory'
import ProductCardHorizontal from '@/components/shopping/ProductCardHorizontal'
import SearchedProductHeader from '@/components/shopping/SearchedProductHeader'
import InventoryNoData from '@/components/dashboard/InventoryNoData'
import InventorySearchNoData from '@/components/dashboard/InventorySearchNoData'
import OrderSync from '@/components/dashboard/vendor-fetching/OrderSync'
import NoCategoryProduct from '@/components/dashboard/NoCategoryProduct'
import NoSearchResult from '@/components/shopping/NoSearchResult'
import exportCsvFromJSON from 'export-from-json'
import Button from '@/components/ui/Button.vue'
import Vue from 'vue'
import axios from "@/axios";
import { data } from 'vue-range-component'
export default {
  components: { GrowLoader, CategorySidebar, InventoryProductCardCategory, ProductCardHorizontal, SearchedProductHeader, InventoryNoData, OrderSync, InventorySearchNoData, NoCategoryProduct, NoSearchResult, Button },
  mixins: [OfficeMixin, CartMixin],
  props: {
    padx: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    categories: [],
    inventoryVendors: [],
    fetchingCategories: true,
    fetchingInventoryVendors: true,
    fetchingOrders: false,
    pagination: {
      currentPage: 1,
      rows: 0,
      perPage: 0
    },
    selectedCategory: null,
    selectedVendor: null,
    searchQ: null,
    showEmpty: false,
    timer: null,
    showSpin: false,
    filterByPrice: {
      max: null,
      min: 0
    },
    downloadingList: false,
    showFilter: true
  }),
  created() {
    this.fetchOrdersChkSync()
    this.fetchData()
    this.onResize()
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer)
  },
  computed: {
    filteredCategories() {
      let cats = this.$store.state.PRODUCT.inventoryCategories
      if (this.selectedCategory) cats = [this.selectedCategory]
      return cats.filter(c => this.ordersByCat(c).length > 0)
    },
  },
  watch: {
    'pagination.currentPage'(v) {
      this.fetchOrders(true, v)
    },
  },
 
  methods: {
    ...mapActions('PRODUCT', ['GET_PRODUCT_CATEGORIES_INVENTORY', 'FETCH_ORDERS_BY_CATEGORY', 'GET_PRICE_BY_PRODUCT_IDS', 'GET_PRODUCT_CATEGORIES_INVENTORY_VENDOR']),
    ...mapActions('ORDER', ['FETCH_VENDOR_ORDERS']),
    handlePaginationChange() {
      const topContainer = document.getElementById('top-container');

      if (topContainer) {
        topContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
    ,

    onResize() {
      if (window.innerWidth > 992) this.showFilter = true
      else this.showFilter = false
    },
    resetSearch() {
      this.searchQ = null
      this.fetchData(true)
    },
    async printInventoryList() {
      let res = await axios.reqApi("reports/inventory-list/").getBuffer({ params: { office_id: this.officeId } })
      await axios.downloadFile(res);
    },
    setSelectedCategory(v) {
      this.selectedCategory = v
      this.onChangeCat(this.selectedCategory)
    },
    setSelectedVendor(v) {
      this.selectedVendor = v
      this.onChangeVendor(this.selectedVendor)
    },
    onClickVendor(vendor) {
      if (this.selectedVendor && this.selectedVendor.id == vendor.id) this.setSelectedVendor(null)
      else this.setSelectedVendor(vendor)
    },
    onProductImageUpdate(prod) {
      if (prod) {
        let index = this.$store.state.PRODUCT.inventoryItems.findIndex(o => o.id === prod.id)
        if (index > -1) {
          Vue.set(this.$store.state.PRODUCT.inventoryItems, index, {
            ...this.$store.state.PRODUCT.inventoryItems[index],
            images: [{ image: prod.image_url }]
          })
        }
        this.$forceUpdate()
      }
    },
    async fetchOrdersChkSync() {
      let payload = {
        office: this.officeId,
      }
      let res = await this.FETCH_VENDOR_ORDERS(payload)
      if (res.total == 0) {
        this.showSpin = true
        this.timer = setTimeout(() => {
          this.fetchOrdersChkSync()
        }, 30 * 1000)
      } else {
        if (this.timer) clearTimeout(this.timer)
      }
    },
    async search() {
      this.selectedCategory = null
      this.selectedVendor = null
      this.fetchData(true)
    },
    onChangeCat(category) {
      this.searchQ = null
      this.selectedCategory = category
      this.fetchOrders(true)
    },
    onChangeVendor(vendor) {
      this.searchQ = null
      this.selectedVendor = vendor
      this.fetchOrders(true)
    },
    onFilterByPrice(filter) {
      this.filterByPrice.min = filter.values[0]
      if (filter.values[1] && filter.values[1].trim().length > 0) this.filterByPrice.max = filter.values[1]
      else this.filterByPrice.max = null
      this.fetchOrders(true, undefined)
    },
    async fetchData(loading = true) {
      this.fetchInventoryVendors()
      this.fetchingCategories = loading
      await this.fetchProductCategories()
      await this.fetchOrders()
      this.fetchingCategories = false
    },
    ordersByCat(category) {
      let result = this.$store.state.PRODUCT.inventoryItems.filter(o => o.office_product_category && o.office_product_category.id == category.id)
      return result;
    },
    async fetchProductCategories() {
      try {
        this.categories = await this.GET_PRODUCT_CATEGORIES_INVENTORY({
          office_id: this.officeId
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchInventoryVendors() {
      this.fetchingInventoryVendors = true
      try {
        this.inventoryVendors = await this.GET_PRODUCT_CATEGORIES_INVENTORY_VENDOR({
          office_id: this.officeId
        })
      } catch (e) {
        console.log(e)
      }
      this.fetchingInventoryVendors = false
    },
    async fetchOrders(fetching = true, page = undefined, filters = {}) {
      
      this.fetchingOrders = fetching
      try {
        let payload = {
          office_id: this.officeId,
          filters: this.filterByPrice,
        }
        if (page) payload.page = page
        if (this.selectedCategory) payload.category = this.selectedCategory.slug
        if (this.selectedVendor) payload.vendor = this.selectedVendor.slug
        if (this.searchQ && this.searchQ.trim().length > 0) payload.q = this.searchQ
        let res = await this.FETCH_ORDERS_BY_CATEGORY(payload)
        res.data = res.data.map(r => {
          let pr = r.product
          if (r.image_url) {
            pr.images = [{
              image: r.image_url
            }]
          }
          pr.nickname = r.nickname
          return {
            ...r,
            ...pr,
            id: r.id,
          }
        })
        console.log('res.data',res.data)

        let null_price_ids = []
        res.data.forEach(pr => {
          if (pr.price == null) pr.price = pr.product_price
          if (pr.product.children) {
            pr.product.children.forEach(p => {
              if (p.price == null) p.price = p.product_price
              if (p.price == null && p.vendor != null) {
                p.fetching_price = true
                null_price_ids.push(p.id)
              }
            })
          }
        })

        this.$store.state.PRODUCT.inventoryItems = res.data

        if (null_price_ids.length > 0) {
          this.GET_PRICE_BY_PRODUCT_IDS({
            office: this.officeId,
            products: null_price_ids
          }).then(pRes => {
            let ids = Object.keys(pRes).map(i => parseInt(i))
            for (let i = 0; i < this.$store.state.PRODUCT.inventoryItems.length; i++) {
              let pr = this.$store.state.PRODUCT.inventoryItems[i].product
              this.$store.state.PRODUCT.inventoryItems[i].product.fetching_price = false
              if (ids.includes(pr.id)) {
                this.$store.state.PRODUCT.inventoryItems[i].product.price = pRes[pr.id].price
                if (pRes[pr.id].product_vendor_status) this.$store.state.PRODUCT.inventoryItems[i].product_vendor_status = pRes[pr.id].product_vendor_status
              }
              if (pr.children) {
                for (let j = 0; j < pr.children.length; j++) {
                  this.$store.state.PRODUCT.inventoryItems[i].product.children[j].fetching_price = false
                  let p = pr.children[j]
                  if (ids.includes(p.id)) {
                    this.$store.state.PRODUCT.inventoryItems[i].product.children[j].price = pRes[p.id].price
                    if (pRes[p.id].product_vendor_status) this.$store.state.PRODUCT.inventoryItems[i].product.children[j].product_vendor_status = pRes[p.id].product_vendor_status
                  }
                }
              }
            }
            this.$forceUpdate()
          })
        }

        this.pagination.currentPage = res.current_page
        this.pagination.perPage = res.per_page
        this.pagination.rows = res.total
        if (this.selectedCategory == null && this.selectedVendor == null && res.data.length == 0 && this.searchQ == null && this.filterByPrice.max == null && this.filterByPrice.min == 0) this.showEmpty = true
        else this.showEmpty = false
      } catch (e) {
        console.log(e)
      }
      this.fetchingOrders = false
      
    },
    onProductRemoved() {
      this.fetchOrders()
      this.fetchProductCategories()
      this.fetchInventoryVendors()
    },
  },

}
</script>

<style lang="sass" scoped>
.search-wrapper
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
::v-deep .menu-drp
  .dropdown-menu
    &.show
      max-height: 50vh
      overflow-y: auto
      width: 340px
      @media screen and (min-width: 992px)
        top: 182px !important
        transform: translate3d(-210px, -127px, 0px) !important
.filters
  @media screen and (min-width: 992px)
    position: sticky
    top: 80px
.prods
  // max-height: 100vh
  // overflow-y: auto
  @media screen and (min-width: 992px)
    border-left: solid 1px #EBF4F7
// .products-wrapper
//   .product-card
//     width: 32%
//     margin-right: calc(4% / 3)
//     margin-bottom: 15px
//     @media screen and (max-width: 768px)
//       margin-right: 0
//     @media screen and (max-width: 992px)
//       margin-right: calc(4% / 2)
//     @media screen and (max-width: 1350px)
//       margin-right: calc(4% / 3)
</style>