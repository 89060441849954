<template>
  <div class="login-form">
    <validation-observer ref="loginFormObs" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(signin)">
        <validation-provider name="Email Address" rules="required" v-slot="validationContext">
          <b-input v-model="formData.username" class="custom-input" placeholder="Email Address" type="text"
            :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
          }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="Password" rules="required" v-slot="validationContext">
          <b-input v-model="formData.password" class="custom-input mt-3" placeholder="Password" type="password"
            :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
          }}</b-form-invalid-feedback>
        </validation-provider>
        <!-- <b-checkbox class="auth-chk custom-chk mt-2">Stay signed in for a week</b-checkbox> -->
        <validation-provider name="Messages" v-slot="validationContext">
          <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error
          }}</b-form-invalid-feedback>
        </validation-provider>
        <Button text="Log In" class="w-100 mt-4" :onclick="signin" :submitting="submitting" />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
import FormMixin from '@/mixins/FormMixin'
import localStorageService from '@/store/localStorageService';

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [GoTo, FormMixin],
  components: {
    Input,
    Button,
  },
  data: () => ({
    formData: {
      username: null,
      password: null,
    },
    submitting: false,
  }),
  computed: {
    ...mapGetters('USER', ['profileEmail']),
  },
  methods: {
    ...mapActions('USER', ['LOGIN', 'UPDATE_PROFILE']),
    ...mapActions('OFFICE', ['TEAM_MEMBERS']),
    ...mapMutations('USER', ['UPDATE_PROFILE']),
    async signin() {

      if (this.submitting) return
      this.submitting = true
      let isValid = await this.$refs.loginFormObs.validate();
      try {
        if (isValid) {
          let payload = { ...this.formData }
          let res = await this.LOGIN(payload)
          if (res.profile && res.profile.company && res.profile.company.on_boarding_step > 1) {
            let members = await this.TEAM_MEMBERS()
            let mem = members.find(m => m.email == this.profileEmail)
            if (mem) {
              this.UPDATE_PROFILE({
                role: mem.role
              })
            }
            const lastVisitedRoute = localStorageService.getItem('lastVisitedRoute');
            if (lastVisitedRoute) {
              if (mem) {
                if (mem.role == 2) { this.$router.push(lastVisitedRoute); }
              }
              else {
                this.$router.push({ name: 'Dashboard', query: { fetchGreetings: true } }).catch(() => { });
              }
            }
            else {
              this.$router.push({ name: 'Dashboard', query: { fetchGreetings: true } }).catch(() => { });
            }
            // this.goTo({
            //   name: 'Dashboard', params: {
            //     fetchGreetings: true,
            //   }
            // })
          } else {
            // TODO: If onboarding billing is not passed, redirect to onboard page
            // this.$store.state.ONBOARDING.data = { company: { id: res.profile.company.id } }
            
            this.goTo({name: 'OnBoarding'})
            this.$refs.loginFormObs.setErrors({
              'Messages': 'Onboarding process is not completed, please contact Admin.'
            });
          }
        }
      } catch (e) {
        let errors = {
          'Messages': e
        };
        if (e.detail) errors.Messages = e.detail
        this.$refs.loginFormObs.setErrors(errors);
      }
      this.submitting = false
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .auth-chk
  .custom-control-label
    &::before
      top: 0
</style>