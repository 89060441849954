<template>
  <div :class="['d-flex align-items-center mb-lg-0', {'active': notfDrp}]">
    <b-dropdown right class="menu-drp notification-menu-drp w-100" ref="notfDrp" @toggle="v => notfDrp = v">
      <template #button-content>
        <div class="d-flex align-items-center">
          <div class="d-icon">
            <img src="@/assets/images/notification_center_alt.png" style="width: 20px; height: 20px;" alt="">
            <span class="num-wrapper d-flex justify-content-center align-items-center" v-if="unreadNotifications.length > 0">
              <span class="num-notf d-desc text-white" style="font-size: 10px;line-height: 1;margin-top:2px">{{unreadNotifications.length}}</span>
            </span>
          </div>
          <!-- <p class="d-title__3 mb-0">Notification Center</p> -->
        </div>
      </template>
      <notification-menu />
    </b-dropdown>
  </div>
</template>

<script>
import NotificationMenu from '@/components/dashboard/NotificationMenu'
import GoTo from '@/mixins/GoTo'
import UserCard from '@/components/dashboard/UserCard'
import { mapGetters } from 'vuex'
export default {
  props: {
    mobile: Boolean,
  },
  components: {
    NotificationMenu,
    UserCard,
  },
  mixins: [GoTo],
  data: () => ({
    notfDrp: false,
  }),
  computed: {
    ...mapGetters('NOTIFICATION', ['unreadNotifications']),
  },
}
</script>

<style lang="sass" scoped>

@import '../../style/variables'

::v-deep .menu-drp 
  .dropdown-menu 
    width: 96vw
    @media screen and (min-width: 992px)
      width: 360px
    @media screen and (max-width: 992px)
      max-height: calc( 100vh - 74px - 72px - 8px )
    &.dropdown-menu-right 
      &.show
        @media screen and (min-width: 992px)
          left: 140px !important
          top: 16px !important
        @media screen and (min-width: 479px)
          top: 16px !important
        @media screen and (max-width: 479px)
          top: 10px !important
        
.d-icon
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
  padding: 8px
  position: relative
  .num-wrapper
    position: absolute
    top: -5.3px
    right: -4.65px
    background-color: #{$red2}
    height: 15px
    width: 15px
    border-radius: 100%

.d-title__3
  @media screen and (max-width: 768px)
    font-size: 10px
  @media screen and (max-width: 350px)
    font-size: 8px
  @media screen and (max-width: 300px)
    display: none
</style>