<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-center align-items-center px-3 my-2" v-if="fetching">
      <b-spinner small variant="primary" class="mr-2"></b-spinner>
      <p class="mb-0">Fetching Data...</p>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between mb-1">
        <p class="d-title mt-2 mb-0" style="font-size: 18px;">Manage Team</p>
      </div>
      <p class="mb-4 d-desc__m551" style="width: 450px;">Track and manage your active team members and invitations. If you
        need to add another team member you can do this below.</p>
      <div class="d-lg-flex justify-content-lg-between align-items-lg-center mb-3">
        <p class="mb-2 d-title__2" style="font-size: 16px;">Team Members</p>
        <b-btn class="custom-btn o-btn" @click="toggleInvModal(true, 'invite')">Invite Team Member</b-btn>
        
      </div>
      <div class="notf-surr" v-for="(member, mI) in teamMembers" :key="`mem-${mI}`">
        <div class="check-switch-box mb-2 pl-3 pr-2">
          <div class="notf-p1">
            <p class="mb-0 d-title__4">{{ member.email }}</p>
          </div>
          <div class="ml-auto d-flex gap-8-px align-items-center">
            <p class="mb-0 d-desc__3">Office : {{ member.office.name }}</p>
            <div class="sm-card">
              <p class="mb-0 d-desc__3">User Role : {{ member.role_name }}</p>
            </div>
          </div>
          <Button text="Edit" classes="sm-btn ml-lg-2" :submitting="submitting" :onclick="() => updateInv(member)" />
          <Button text="Remove" classes="danger__2 sm-btn ml-lg-2" :submitting="submitting"
            :onclick="() => cancelInv(member)" />
        </div>
      </div>
      <p class="mt-4 mb-4 d-title__2" v-if="pendingMembers.length > 0" style="font-size: 16px;">Pending Team Member</p>
      <div class="notf-surr" v-for="(member, mI) in pendingMembers" :key="`pen-${mI}`">
        <div class="check-switch-box mb-2 pl-3 pr-2">
          <div class="notf-p1">
            <p class="mb-0 d-title__4">{{ member.email }}</p>
          </div>
          <div class="ml-auto d-flex gap-8-px align-items-center">
            <p class="mb-0 d-desc__3">Office : {{ member.office.name }}</p>
            <div class="sm-card">
              <p class="mb-0 d-desc__3">User Role : {{ member.role_name }}</p>
            </div>
          </div>
          <Button text="Resend Invite" classes="sm-btn ml-lg-2" :submitting="resendingInviteTo == member.id" 
            :onclick="() => resendInvite(member)" v-if="role == 1" />
          <Button text="Remove" classes="danger__2 sm-btn ml-lg-2" :submitting="submitting"
            :onclick="() => cancelInv(member)" />
        </div>
      </div>
    </div>
    <modal :modal="invModal" @change="toggleInvModal" @updated="refetch" size="md">
      <p class="modal-header-title text-center">{{ invitationType === 'invite' ? 'Invite a team member' : 'Edit Team Member' }}</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4"> {{ invitationType === 'invite' ? 'To invite a team member, add there email address, select the users role and assign them an office.' : 'You can edit your team member email, role and assigned office' }}  </p>
      </div>
      <div class="dash mb-4"></div>
      <div>
        <invite-member-form :invitation="invitation" @updated="fetchMembers(); invModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/modals/Modal';
import InviteMemberForm from '@/components/dashboard/settings/InviteMemberForm';
import Button from '@/components/ui/Button';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Modal,
    InviteMemberForm,
    Button,
  },
  data: () => ({
    invModal: false,
    fetching: true,
    members: [],
    submitting: false,
    invitation: null,
    resendingInviteTo: null,
    invitationType: 'invite',
  }),
  created() {
    this.fetchMembers();
  },
  computed: {
    ...mapGetters('USER', ['role']),
    teamMembers() {
      return this.members.filter(member => member.invite_status === 1);
    },
    pendingMembers() {
      return this.members.filter(member => member.invite_status === 0);
    },
  },
  methods: {
    ...mapActions('OFFICE', ['TEAM_MEMBERS', 'DELETE_MEMBER', 'RESEND_INVITE']),
    toggleInvModal(visible, type = 'invite') {
      if (visible !== this.invModal) {
        this.invModal = visible;
        this.invitation = null;
        this.invitationType = type;
      }
    },
    async fetchMembers() {
  this.fetching = true;
  try {
    const response = await this.fetchTeamMembers(); // Make sure this function is correctly implemented in Vuex
    this.members = response;
    console.log('Members fetched:', this.members); // Check the fetched data
  } catch (e) {
    console.error('Failed to fetch members:', e);
  } finally {
    this.fetching = false;
  }
},
    async updateInv(member) {
      this.invitation = member;
      this.toggleInvModal(true, 'edit');
    },
    async cancelInv(member) {
      this.submitting = true;
      try {
        await this.DELETE_MEMBER(member.id);
        this.fetchMembers();
      } catch (error) {
        console.error(error);
      }
      this.submitting = false;
    },
    refetch() {
      this.fetchMembers();
      this.toggleInvModal(false);
    },
    async resendInvite(member) {
      if (this.resendingInviteTo === null) {
        this.resendingInviteTo = member.id;
        try {
          const payload = { invite_status: 3, member_id: member.id };
          await this.RESEND_INVITE(payload);
        } catch (error) {
          console.error(error);
        }
        this.resendingInviteTo = null;
      }
    },
  },
}
</script>


<style lang="sass" scoped>

@import '../../../style/settings'
.manage-team
  .check-switch-box
    // min-height: 57px
    // border-radius: 4px
    // border: solid 1px #E7ECEE
    // display: flex
    // justify-content: space-between
    // align-items: center
    .sm-card
      // background-color:
      min-width: 144px
      width: min-content
      min-height: 28px
      display: flex
      align-items: center
      justify-content: center
    .txt-rm-inv
      color: #ff1dc3
      width: max-content
      min-width: 90px
</style>
