<template>
  <div :class="['welcome-to-ordo py-4 py-lg-5 px-4 position-relative', imageClass, squeez && 'squeezed']">
    <div class="wrapper d-flex flex-column justify-content-center">
      <slot>
        <p class="d-title__8 text-white t-tx" style="margin-bottom: 10px;">{{title}}</p>
        <p class="d-desc__2 text-white sub-t d-none d-lg-block">{{desc}}</p>
      </slot>
    </div>
    <div :class="['character d-none d-lg-block']" v-if="image"></div>
    <div class="arrow-btn cursor-pointer" @click="toggleSqueez">
      <span class="text-primary"> <PolyGonIcon /> </span>
    </div>
  </div>
</template>

<script>
import DSearch from '@/components/dashboard/DSearch'
import CartCheckoutCharacter from '@/components/svg/CartCheckoutCharacter.webp'
import CreateAnOrderCharacter from '@/components/svg/CreateAnOrderCharacter.webp'
import FinanceCharacter from '@/components/svg/FinanceCharacter.webp'
import OrderCharacter from '@/components/svg/OrderCharacter.webp'
import WelcomeIllustration from '@/components/svg/WelcomeIllustration.webp'
import CartIllustration from '@/components/svg/CartIllustration.webp'
import PolyGonIcon from '@/components/svg/icons/PolyGonIcon'
export default {
  components: { DSearch, CartCheckoutCharacter, CreateAnOrderCharacter, FinanceCharacter, OrderCharacter, WelcomeIllustration, CartIllustration, PolyGonIcon },
  props: ['redirect', 'initText', 'title', 'searchField', 'desc'],
  data: () => ({
    squeez: false,
  }),
  mounted(){
    // setTimeout(() => {
    //   if(!this.squeez && window.innerWidth > 992) this.toggleSqueez()
    // }, 15000)
  },
  computed: {
    image(){
      let route = this.$route.name
      if(route == 'Orders') return 'OrderCharacter'
      else if(route == 'Finance') return 'FinanceCharacter'
      else if(route == 'CreateAnOrder') return 'CreateAnOrderCharacter'
      else if(route == 'Cart' || route == 'Checkout' || route == 'OrderApproval') return 'CartIllustration'
      else if(route == 'Dashboard') return 'WelcomeIllustration'
    },
    imageClass(){
      let route = this.$route.name
      if(route == 'Orders') return 'Orders'
      else if(route == 'Finance') return 'Finance'
      else if(route == 'CreateAnOrder') return 'CreateAnOrder'
      else if(route == 'Inventory') return 'Inventory'
      else if(route == 'Cart' || route == 'Checkout' || route == 'OrderApproval') return 'CartIllustration'
      else if(route == 'Dashboard') return 'Dashboard'
      else if(route == 'FutureCast') return 'FutureCast'
    },
  },
  methods: {
    toggleSqueez(){
      this.squeez = !this.squeez
    },
  },
}
</script>

<style lang="sass" scoped>
.welcome-to-ordo
  border-radius: 12px
  box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
  position: relative
  background-repeat: no-repeat
  // background-size: 100% 100%
  background-size: cover
  background-position-y: bottom
  .wrapper
    @media screen and (min-width: 800px)
      max-width: 527px
      min-height: 166px
  .t-tx
    font-size: 36px
  .sub-t
    font-size: 14px
    margin-top: 0
    margin-bottom: 20px
    max-width: 500px
    line-height: 1.7
    font-family: Gilroy-Semibold
  .character
    position: absolute
    right: 10%
    bottom: 0
    width: 229px
    height: 90%
    svg
      height: 100%
      width: 100%
  @media screen and (max-width: 992px)
    background-image: url('../assets/images/header_background_alt.webp')
    border-radius: 12px
    box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
    background-color: #ffffff
    .t-tx
      font-size: 20px
      margin-bottom: 0 !important
  &.Finance
    background-image: url('../assets/images/head_cover/finance.svg')
    @media screen and (min-width: 800px)
      height: 262px
      .character
        width: 349px
        height: 262px
    @media screen and (max-width: 992px)
      background-image: url('../assets/images/head_cover/finance_mobile.svg')
  &.CreateAnOrder
    @media screen and (min-width: 800px)
      height: 249px
      .character
        right: 0
        width: 443px
        height: 249px
  &.Inventory
    background-image: url('../assets/images/head_cover/inventory_list.svg')
    @media screen and (max-width: 992px)
      background-image: url('../assets/images/head_cover/inventory_list_mobile.svg')
  &.Orders
    background-image: url('../assets/images/head_cover/orders.svg')
    @media screen and (min-width: 800px)
      .character
        height: 86%
    @media screen and (max-width: 992px)
      background-image: url('../assets/images/head_cover/orders_mobile.svg')
  &.Dashboard
    background-image: url('../assets/images/head_cover/dashboard.svg')
    @media screen and (min-width: 800px)
      .character
        width: 320px
        height: 88%
    @media screen and (max-width: 992px)
      background-image: url('../assets/images/head_cover/dashboard_mobile.svg')
  &.CartIllustration
    background-image: url('../assets/images/head_cover/cart_checkout.svg')
    background-position-x: right
    .wrapper
      .t-tx
        font-size: 26px
      .sub-t
        max-width: 400px
    
    @media screen and (max-width: 992px)
      background-image: url('../assets/images/head_cover/cart_checkout_mobile.svg')
  &.FutureCast
    background-image: url('../assets/images/head_cover/future_cast.svg')
    background-size: cover
    .wrapper
      max-width: unset
  .arrow-btn
    width: 40px
    height: 40px
    background: #D6EEF6
    border: 1.5px solid #50AAD5
    border-radius: 10px
    position: absolute
    right: 1rem
    top: 1rem
    justify-content: center
    align-items: center
    display: none
    span
      transform: rotateX(180deg)
  @media screen and (min-width: 992px)
    .arrow-btn
      display: flex
    &.squeezed
      padding-top: 1rem !important
      padding-bottom: 1rem !important
      // background-size: 100% 262px
      height: unset
      &.Finance
        background-image: url('../assets/images/head_cover/finance_mobile.svg')
      &.CreateAnOrder
        background-image: url('../assets/images/head_cover/inventory_list_mobile.svg')
      &.Orders
        background-image: url('../assets/images/head_cover/orders_mobile.svg')
      &.Inventory
        background-image: url('../assets/images/head_cover/orders_mobile.svg')
      &.Dashboard
        background-image: url('../assets/images/head_cover/dashboard_mobile.svg')
      &.FutureCast
        background-image: url('../assets/images/head_cover/future_cast_mobile.svg')
      &.CartIllustration
        background-image: url('../assets/images/head_cover/cart_checkout_mobile.svg')
      .arrow-btn
        span
          transform: rotateX(0deg)
      .wrapper
        min-height: unset
        max-width: unset
        min-height: 40px
        .t-tx
          margin-bottom: 0 !important
          line-height: 1
          font-size: 26px
        .sub-t
          display: none !important
</style>