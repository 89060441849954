<template>
  <validation-observer ref="nameForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onClickEdButton)" class="d-flex gap-16-px">
      <validation-provider name="Name" rules="required" v-slot="validationContext" v-if="editMode" class="flex-grow-1">
        <b-input @click.stop @keydown.stop v-model="title" class="custom-input w-100" style="height: 28px !important;" placeholder="Type Budget Name" type="text" :state="getValidationState(validationContext)"></b-input>
        <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
      </validation-provider>
      <p class="mb-0" v-else>
        <span class="c-title__2" v-if="subaccount.slug == 'dental'">Set Dental Supply Budget</span>
        <span class="c-title__2" v-else-if="subaccount.slug == 'office'">Set Front Office Supply Budget</span>
        <span class="c-title__2" v-else-if="subaccount.slug == 'miscellaneous'">Set Uncategorized Budget</span>
        <span class="c-title__2" v-else>Set <span>{{ subaccount.name }}</span> Budget</span>
        <span class="form-label__2 ml-2 f-10-px" v-if="subaccount.slug == 'dental'">Required</span>
      </p>
      <b-btn class="custom-btn ml-auto" :class="[ isUpdated && 'success' ]" style="min-height: 28px; height: 28px;" @click.stop="onClickEdButton" v-if="showEdBtn">
        <b-spinner class="mr-2 w-14-px h-14-px" v-if="submitting"></b-spinner>
        {{ btnText }}
      </b-btn>
      <b-btn class="custom-btn danger__2" style="min-height: 28px; height: 28px;" @click.stop="onClickCancelEdButton" v-if="showEdBtn && editMode">Cancel</b-btn>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/mixins/FormMixin.vue'
export default {
  props: {
    subaccount: Object,
    officeId: Number,
  },
  mixins: [ FormMixin ],
  data: () => ({
    editMode: false,
    title: null,
    isUpdated: false,
    submitting: false,
  }),
  computed: {
    btnText(){
      if(this.isUpdated) return 'Saved'
      if(this.editMode) return 'Save'
      return 'Edit Name'
    },
    showEdBtn(){
      return !['dental', 'office'].includes(this.subaccount.slug)
    },
  },
  methods: {
    ...mapActions('OFFICE', ['CREATE_CUSTOM_BUDGET']),
    onClickEdButton(){
      if(this.editMode) this.submit()
      else {
        this.title = this.subaccount.name
        this.editMode = true
      }
    },
    onClickCancelEdButton(){
      this.editMode = false
    },
    async submit(){
      if(this.submitting || this.isUpdated) return
      this.submitting = true
      let isValid = await this.$refs.nameForm.validate()
      if(isValid){
        try{
          const payload = {
            office: this.officeId,
            ...this.$store.state.OFFICE.officeBudget[this.officeId],
            subaccounts: this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.map(subaccount => ({
              ...subaccount,
              name: subaccount.slug == this.subaccount.slug ? this.title : subaccount.name
            }))
          }
          await this.CREATE_CUSTOM_BUDGET(payload)
          this.$emit('updated', this.officeId, this.subaccount.slug, this.title)
          this.isUpdated = true
          setTimeout(() => {
            this.isUpdated = false
            this.editMode = false
          }, 1000)
        }catch(e){}
      }
      this.submitting = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
</style>