import axios from '@/axios'


const state = {

}
const getters = {

}
const mutations = {

}
const actions = {
    CREATE_SUB_COMPANY: async ({ rootGetters }, payload) => {
        try {
            const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id;
            payload['company'] = companyId;
            let { data } = await axios.reqApi(`companies/${companyId}/subcompany/add_new_sub_company`).post(payload);
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    },

    GET_ALL_SUB_COMPANY: async ({ rootGetters }, payload) => {
        const companyId = rootGetters['USER/company'] ? rootGetters['USER/company'].id : rootGetters['ONBOARDING/onboarding'].company.id;
        let { data } = await axios.reqApi(`companies/${companyId}/subcompany/get_all_sub_companies`).get();
        return data.data;
    },
    REMOVE_SUB_COMPANY: async ({ rootGetters }, payload) => {
        try {
            const companyId = payload.company || rootGetters['USER/company'].id
            let { data } = await axios.reqApi(`companies/${companyId}/subcompany/remove_sub_company`).delete(payload)
            return data.data;
        } catch (e) {
            return Promise.reject(e.response.data.message);
        }
    },
    UPDATE_SUB_COMPANY: async ({ rootGetters }, payload) => {
        try{
            const companyId = payload.company || rootGetters['USER/company'].id
            let { data } = await axios.reqApi(`companies/${companyId}/subcompany/update_sub_company`).put(payload)
            return data.data;

        }catch (e){
            return Promise.reject(e.response.data.message);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}