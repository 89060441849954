<template>
  <div class="create-an-order">
    <div v-if="showSearch">
      <shopping-search :text="searchText" />
      <DashboardFooter class="" />
    </div>
  </div>
</template>

<script>
import ShoppingSearch from '@/components/shopping/ShoppingSearch'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'

export default {
  components: {
    ShoppingSearch,
    DashboardFooter,
  },
  data: () => ({
    searchText: '',
    showSearch: false,
  }),
  beforeDestroy(){
    this.$BUS.$off('search')
  },
  mounted(){
    this.$BUS.$on('search', (payload) => {
      this.showSearch = true
      this.searchText = payload.q
    })
    if(this.$route.query.q){
      this.searchText = this.$route.query.q
      this.$BUS.$emit('search', {
        q: this.$route.query.q
      })
    }
  },
  methods: {
    setSearch(v){
      this.searchText = v
    },
  },
}
</script>

<style lang="sass" scoped>

::v-deep .products-carousel
  .owl-item
    width: max-content !important
    min-width: 276px
    .product-card
      width: 256px
</style>