<template>
  <div class="filter-sidebar">
    <p class="mt-4 mb-4 f-title">Filter & Refine</p>
    <div class="filter-sm-card px-2 mt-2 d-flex align-items-center">
      <b-checkbox class="custom-chk f-chk" @click.native.prevent>Products on Promotion</b-checkbox>
    </div>
    <div class="filter-sm-card px-2 mt-2 d-flex align-items-center">
      <b-checkbox class="custom-chk f-chk" @click.native.prevent>In your Inventory List</b-checkbox>
    </div>
    <div class="filter-sm-card px-2 mt-2 d-flex align-items-center">
      <b-checkbox class="custom-chk f-chk" @click.native.prevent>From Linked Vendors</b-checkbox>
    </div>
    <div class="dash my-4"></div>
    <grow-loader justify="justify-content-start" padding="pb-4" text="Loading Vendors" v-if="fetchingVendors" />
    <div class="suppliers-div" v-else>
      <p class="mb-2 f-title__2">Vendors</p>
      <div class="filter-sm-card px-2 mt-2 d-flex align-items-center" v-for="(vendor, vI) in vendors" :key="vI" @click="vendor.selected = !vendor.selected">
        <b-checkbox class="custom-chk f-chk" @click.native.prevent :checked="vendor.selected">{{vendor.name}}</b-checkbox>
      </div>
      <div class="dash my-4"></div>
    </div>
    <div class="by-price">
      <p class="mb-2 f-title__2">Filter by Price</p>
      <div class="d-flex">
        <b-input class="custom-input p-inp" placeholder="$Min" v-model="min"></b-input>
        <b-input class="custom-input p-inp" placeholder="$Max" v-model="max"></b-input>
        <b-btn class="custom-btn p-btn" @click="emitSearch">Go</b-btn>
      </div>
    </div>
    <!-- <p class="mb-2 f-title__2">Avg. Customer Review</p>
    <div class="d-flex align-items-center">
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <p class="mb-0 f-desc">& Up</p>
    </div>
    <div class="d-flex align-items-center">
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <p class="mb-0 f-desc">& Up</p>
    </div>
    <div class="d-flex align-items-center">
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <p class="mb-0 f-desc">& Up</p>
    </div>
    <div class="d-flex align-items-center">
      <b-icon icon="star-fill" variant="warning"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <b-icon icon="star" variant="light"></b-icon>
      <p class="mb-0 f-desc">& Up</p>
    </div>
    <div class="dash my-2"></div>
    <p class="mb-2 f-title__2">Price</p>
    <p class="mb-0 f-desc">Under $25</p>
    <p class="mb-0 f-desc">$25 to $50</p>
    <p class="mb-0 f-desc">$50 to $100</p>
    <p class="mb-0 f-desc">$100 to $200</p>
    <p class="mb-0 f-desc">$200 & Above</p>
    <div class="d-flex my-3">
      <b-input class="min-field" placeholder="$Min"></b-input>
      <b-input class="max-field mx-2" placeholder="$Max"></b-input>
      <b-btn class="custom-btn min-max-btn">Go</b-btn>
    </div>
    <div class="dash my-2"></div>
    <p class="mb-2 f-title__2">With checklist example</p>
    <p class="mb-0 f-desc">Under $25</p>
    <b-checkbox class="f-desc">Lorem Ipsum</b-checkbox>
    <b-checkbox class="f-desc">Lorem Ipsum</b-checkbox>
    <b-checkbox class="f-desc">Lorem Ipsum</b-checkbox>
    <b-checkbox class="f-desc">Lorem Ipsum</b-checkbox>
    <b-checkbox class="f-desc">Lorem Ipsum</b-checkbox> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OfficeMixin from '@/mixins/OfficeMixin'
import GrowLoader from '@/components/loaders/GrowLoader'
import ProductSearchBus from '@/bus/ProductSearchBus'
export default {
  components: { GrowLoader },
  mixins : [OfficeMixin],
  data: () => ({
    vendors: [],
    fetchingVendors: true,
    max: null,
    min: null,
  }),
  created(){
    this.fetchVendors()
  },
  methods: {
    ...mapActions('ONBOARDING', ['FETCH_VENDORS']),
    async fetchVendors(){
      try{
        this.vendors = (await this.FETCH_VENDORS()).map(v => ({...v, selected: false}))
      }catch(e){}
      this.fetchingVendors = false
    },
    emitSearch(){
      this.$BUS.$emit('search-filter',{
        vendors: this.vendors.filter(v => v.selected)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-sidebar
  .filter-sm-card
    height: 45px
    border-radius: 4px
    border: solid 1px #efefef
    cursor: pointer
  .f-title
    font-family: GILROY-BOLD
    font-size: 14px
    font-weight: 600
    font-stretch: normal
    font-style: normal
    line-height: 1.2
    color: #1c455a
    &__2
      @extend .f-title
      font-size: 14px
      font-family: GILROY-BOLD
      line-height: 2
  .f-desc
    font-size: 12px
    font-family: Gilroy-BOLD
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 2
    color: #1c455a
  .min-field , .max-field
    width: 73px
    height: 29px
    border-radius: 4px
    border: solid 1px #1c455a
    line-height: 2
    &:focus
      outline: none
      box-shadow: none
      border: solid 0.5px #1c455a
    &::placeholder
      font-family: GILROY-MEDIUM
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: normal
      color: #000000

  .min-max-btn
    min-height: 29px
    height: 29px
    padding-top: 0
    padding-bottom: 0
    display: flex
    align-items: center
    justify-content: center
  ::v-deep .f-chk
    label
      font-family: GILROY-SEMIBOLD
      font-size: 12px
      font-weight: 500
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #1c455a
      opacity: 0.8
      // margin-top: 5px
      cursor: pointer
      &:hover
        opacity: 1

  .p-inp
    max-width: 73px
    height: 29px !important
    margin-right: 10px
  .p-btn
    min-height: 29px
    max-height: 29px
</style>