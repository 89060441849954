<template>
  <div class="no-result d-card px-4 px-md-5 py-5 py-md-5 d-flex align-items-center">
    <div class="pl-md-5 pr-md-5">
      <p class="d-title">Current Category is Empty</p>
      <p class="d-desc">The category you selected is empty. To add products to this category, drag and drop the image of a product into this category.</p>
    </div>
    <img src="@/assets/images/cart_package.png" alt="" class="ch ml-5 d-none d-md-inline">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

.no-result
  .ch
    width: 165px
    height: 165px
  .d-desc
    max-width: 350px
</style>