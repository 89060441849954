<template>
  <div class="no-data d-flex flex-column justify-content-center align-items-center" v-if="connected_vendors.length == 0">
    <p class="d-title mb-1 text-center">Previous Order Invoices</p>
    <p class="d-desc mb-3 text-center" style="max-width: 320px">Once you place orders or link other vendors we’ll display all your previous invoices. We provide a link to download the specific invoices. </p>
    <Button style="min-width: 200px" text="Connect a Vendor" :onclick="() => goTo({name: 'Settings', hash: '#Lnk'})" />
  </div>
  <div class="pt-3" v-else>
    <p class="mb-0 d-title mx-3">Previous Invoices and Credit Memos</p>
    <div class="no-data d-flex flex-column justify-content-center align-items-center">
      <p class="d-title mb-1 text-center" style="max-width: 400px;">There are no invoices during the period you have selected. To see invoices, try changing the period range in the filters above.</p>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
import { mapGetters } from 'vuex'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
  computed: {
    ...mapGetters('OFFICE', ['connected_vendors']),
  },
}
</script>

<style lang="sass" scoped>

.no-data
  background-image: url('../../assets/images/order-invoice-box.png')
  background-position: 70px 0px
  background-size: 90% 100%
  background-repeat: no-repeat
  min-height: 220px
  @media (max-width: 762px)
    background-image: unset
</style>