<template>
  <div class="acc-setup-two">
    <p class="c-title">Account Setup : Part Two</p>
    <p class="form-label mb-1">Full Name</p>
    <Input placeholder="Enter your full name" />
    <p class="form-label mb-1 mt-3">Email</p>
    <Input placeholder="Enter your Email" />
    <p class="form-label mb-1 mt-3">Your Phone</p>
    <Input placeholder="Enter your Phone" />
    <p class="form-label mb-1 mt-3">Approval Setting</p>
    <Input placeholder="Lorem Ipsum" />
    <div class="d-flex justify-content-center mt-4">
      <Button text="Next" class="o-btn" :onclick="next" />
    </div>
  </div>
</template>

<script>
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
export default {
  components: {
    Input,
    Button,
  },
  methods: {
    next(){
      this.$emit('next')
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.acc-setup-two
  width: 507px
  padding: 32px
  border-radius: 8px
  background-color: #ffffff
  @media screen and (max-width: 992px)
    width: 100%
</style>