<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <p class="d-title__2 l-h-1 mb-0" v-if="n.notification.event == 'NewOrderNotification'">
        <img src="@/assets/images/order_box.png" alt="" style="width: 15px; height: 15px;" class="mr-2">
        <span>Order Confirmation</span>
      </p>
      <p class="d-title__2 l-h-1 mb-0" v-else-if="n.notification.event == 'OrderApprovalNotification'">
        <img src="@/assets/images/order_box.png" alt="" style="width: 15px; height: 15px;" class="mr-2">
        <span>Order Approval Required</span>
      </p>
      <Button class="danger__2__outline mark-btn" spinner-class="text-danger__2" spinner-width="0.7rem" spinner-height="0.7rem" text="Mark as Read" v-if="!n.is_read" :onclick="markNotification" :submitting="marking" />
    </div>
    <p class="d-desc mb-0" v-if="n.notification.event == 'NewOrderNotification'">
      An order has been placed on {{orderDate}}. 
      <!-- The products in this order are from 
      <span v-for="(ven, vI) in n.notification.metadata.vendors" :key="ven.id">
        {{ven}}
        <span v-if="vI > 0 && vI < n.notification.metadata.vendors.length - 1">&nbsp; and &nbsp;</span>
      </span>.  -->
      Click <span class="text-primary cursor-pointer" @click="notfDetails(n)">here</span> to view more details
    </p>
    <p class="d-desc mb-0" v-else-if="n.notification.event == 'OrderApprovalNotification'">
      An order on {{orderDate}}. 
      requires your approval. 
      Click <span class="text-primary cursor-pointer" @click="notfDetails(n)">here</span> to view more details.
    </p>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import { mapActions, mapMutations } from 'vuex'
import GoTo from '@/mixins/GoTo'
import moment from 'moment'

export default {
  props: {
    n: Object,
  },
  components: { Button, },
  mixins: [ GoTo ],
  data: () => ({
    marking: false,
  }),
  computed: {
    orderDate(){
      if(this.n && this.n.notification && this.n.notification.metadata && this.n.notification.metadata.order_date){
        let d = moment(this.n.notification.metadata.order_date, 'YYYY-MM-DD')
        if(d.isValid()) return d.format('MM-DD-YYYY')
      }
      return null
    },
  },
  methods: {
    ...mapActions('NOTIFICATION', ['MARK_NOTIFICATION']),
    ...mapMutations('NOTIFICATION', ['TOGGLE_NOTIFICATION_READ']),
    notfDetails(){
      this.goTo({ name: 'Orders' })
    },
    async markNotification(e){
      e.stopPropagation()
      // this.TOGGLE_NOTIFICATION_READ(this.n.id)
      if(this.marking) return
      this.marking = true
      try{
        let payload = {
          notification_id: this.n.id,
          is_read: true,
        }
        await this.MARK_NOTIFICATION(payload)
      }catch(e){
        console.log(e)
      }
      this.marking = false
    },
  },
}
</script>

<style lang="sass" scoped>

.mark-btn
  font-size: 8px !important
  min-height: 30px !important
  border: none !important
  padding: 0 !important
  font-size: 10px !important
</style>