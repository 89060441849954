<template>
  <!-- <b-dropdown right class="menu-drp w-100" ref="drp" :menu-class="offices.length == 1 ? 'hide-menu':'ofc-menu'">
    <template #button-content>
      <div class="d-flex align-items-center justify-content-between b-con">
        <div class="d-flex av-name">
          <div class="av" :class="[ !office.logo && 'no-image' ]">
            <img v-if="office.logo" :src="office.logo" alt="" class="w-100 h-100">
            <img src="./office_default.png" alt="" v-else>
          </div>
          <p class="d-title__3 my-auto o-name text-line-2 text-white" style="color: #1C455A;line-height: 1.5;font-size: 14px">{{office.name}}</p>
        </div>
        <b-icon icon="caret-down-fill" variant="white" font-scale="0.9" class="ml-1" v-if="offices.length > 1"></b-icon>
      </div>
    </template>
    <company-menu v-if="offices.length > 1" />
  </b-dropdown> -->
  <div class="ofc-menu d-card py-3 px-3">
  <company-menu v-if="offices.length > 0" />
  </div>
</template>

<script>
import CompanyMenu from '@/components/dashboard/CompanyMenu'
import OfficeMixin from '@/mixins/OfficeMixin'
import GoTo from '@/mixins/GoTo'
export default {
  mixins: [OfficeMixin, GoTo],
  components: {
    CompanyMenu,
  },
  computed: {
    
  },
}
</script>

<style lang="sass" scoped>


::v-deep .ofc-menu
  width: 95% !important
::v-deep .dropdown-menu 
  width: 200px
  min-width: 215px !important
  &.dropdown-menu-right 
    &.show
      left: 1px !important
      top: 5px !important
    &.hide-menu
      display: none
.us-card
  .us-sec
    width: calc( 100% - 0.5rem )
.b-con
  background-image: url(./Office_grad.svg)
  background-size: cover
  padding: 1.5rem 1rem
  border-radius: 8px 0px 0px 8px
  @media screen and (max-width: 992px)
    border-radius: 0
  .av-name
    max-width: 95%
.av
  min-width: 41px
  min-height: 41px
  max-width: 41px
  max-height: 41px
  border-radius: 100%
  border: 2px solid white
  img
    // min-height: calc( 100% - 4px )
    // min-width: calc( 100% - 4px )
    object-fit: cover
    border-radius: inherit
    height: 100%
    width: 100%
  &.no-image
    // background-color: #FFFFFF
    display: flex
    align-items: center
    justify-content: center
    border-color: transparent
    border-radius: 0
    span
      font-family: GILROY-BOLD
      font-size: 26px
      color: white
      margin-top: 3px

::v-deep .menu-drp
  button
    max-width: 150px

.o-name
  max-width: 125px
  white-space: normal
  text-align: left
  // text-overflow: ellipsis
  // overflow: hidden

</style>