<template>
  <div class="d-card px-3 py-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <p class="d-title mb-0" style="font-size: 16px; font-family: Gilroy-Bold;">{{period}} Order Activity</p>
    </div>
    <grow-loader v-if="$store.state.OFFICE.fetchingBudgetStats || fetchingFilterData" />
    <div class="wrapper d-lg-flex justify-content-lg-around align-items-center px-4 py-3" v-else>
      <div class="activity py-2 py-lg-0">
        <p class="amount mb-0 text-center text-success">{{orderCountData.order_counts}}</p>
        <p class="d-desc mb-0 text-center" style="font-size: 12px; font-family: Gilroy-Semibold;">Orders</p>
      </div>
      <div class="h-rect my-2 my-lg-0"></div>
      <div class="activity py-2 py-lg-0">
        <p class="amount mb-0 text-center text-success">{{orderCountData.total_items}}</p>
        <p class="d-desc mb-0 text-center" style="font-size: 12px; font-family: Gilroy-Semibold;">Total Items Ordered</p>
      </div>
      <div class="h-rect my-2 my-lg-0"></div>
      <div class="activity py-2 py-lg-0">
        <p class="amount mb-0 text-center text-success">${{numberWithCommas(orderCountData.average_amount)}}</p>
        <p class="d-desc mb-0 text-center" style="font-size: 12px; font-family: Gilroy-Semibold;">Average Cost per Order</p>
      </div>
      <div class="h-rect my-2 my-lg-0"></div>
      <div class="activity py-2 py-lg-0">
        <p class="amount mb-0 text-center text-success">{{numberWithCommas(orderCountData.pending_order_counts)}}</p>
        <p class="d-desc mb-0 text-center" style="font-size: 12px; font-family: Gilroy-Semibold;">Orders Pending Approval</p>
      </div>
      <div class="h-rect my-2 my-lg-0"></div>
      <div class="activity py-2 py-lg-0">
        <p class="amount mb-0 text-center text-success">{{numberWithCommas(orderCountData.backordered_count)}}</p>
        <p class="d-desc mb-0 text-center" style="font-size: 12px; font-family: Gilroy-Semibold;">Backordered</p>
      </div>
    </div>
  </div>
</template>

<script>
import GrowLoader from '@/components/loaders/GrowLoader.vue'
import moment from 'moment'
import MonthMixin from '@/mixins/Month'
import Filter from '@/mixins/Filter'
import StatisticsMixin from '@/mixins/Statistics'
import NumberComma from '@/mixins/NumberComma'
import {mapActions} from 'vuex'
export default {
  components: { GrowLoader },
  mixins: [MonthMixin, StatisticsMixin, NumberComma, Filter],
  data: () => ({
    monthBudgetData: null,
    fetchingFilterData: false,
    filterApplied: false,
  }),
  created(){
    this.period = this.currentMonth
    // this.fetchOrderCount()
    this.$BUS.$on('filter:fetch-stats', this.getBudData)
    this.$BUS.$on('filter:filter-change', this.getBudData)
    this.getBudData({
      selectedDate: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
      }
    })
  },
  beforeDestroy(){
    this.$BUS.$off('filter:filter-change')
    this.$BUS.$off('filter:fetch-stats')
  },
  methods: {
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET']),
    getBudData(filters){
      this.onFilterChange(filters, async (filt) => {
        // this.fetchCurrentBudget(filt)
        this.setPeriod(filters)
        let payload = {office: this.officeId}
        if(filt) payload.filters = filt
        payload.saveData = false
        this.fetchingFilterData = true
        this.monthBudgetData = await this.CURRENT_MONTH_BUDGET(payload)
        this.filterApplied = true
        this.fetchingFilterData = false
      })
    },
  },
  computed: {
    orderCountData(){
      if(this.filterApplied && this.monthBudgetData) return this.monthBudgetData.order
      return this.count
    },
  },
}
</script>

<style lang="sass" scoped>

.wrapper
  border-radius: 8px
  background-color: #f9fafa
  .h-rect
    width: 0.5px
    opacity: 0.2
    height: 120px
    border-radius: 8px
    background-color: #687C8A
    @media screen and (max-width: 992px)
      width: 100%
      height: 2px
  .activity
    position: relative
    .amount
      font-size: 45px
      font-family: 'GILROY-BOLD'
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      text-align: left
      color: #1c455a
      @media screen and (max-width: 992px)
        font-size: 22px
    .coming-soon-div
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: rgba(249, 250, 250, 0.9)
</style>