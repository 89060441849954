<template>
  <b-dropdown right class="cart-btn ml-auto mr-3 mb-2 mb-lg-0" ref="drp">
    <template #button-content>
      <div class="d-flex align-content-center justify-content-between btn-content">
        <b-icon variant="dark" icon="cart"></b-icon>
        <b-icon variant="dark" icon="caret-down-fill" font-scale="0.9" style="height:inherit"></b-icon>
      </div>
    </template>
    <cart-contents />
  </b-dropdown>
</template>

<script>
import CartContents from '@/components/cart/CartContents'
export default {
  components: {
    CartContents
  },
}
</script>

<style lang="sass" scoped>
.cart-btn
  width: 70px
  height: 43px
  ::v-deep button
    border-radius: 12px
    // box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
    border: solid 1px #EBF4F7 !important
    background-color: #ffffff !important
    &:focus , &:hover , &:active
      background-color: #ffffff !important
      box-shadow: none !important
    .btn-content
      color: rgba(0, 0, 0, 0)
    &::after
      display: none
  ::v-deep .dropdown-menu
    width: 300px
    border-radius: 8px
    box-shadow: 0 0 25px 0 rgba(235, 235, 235, 0.93)
    background-color: #ffffff
    border: none

</style>