<template>
  <div class="product-info">
    <p class="d-title">{{product.name}}</p>
    <div class="d-flex">
      <div class="l-wrapper">
        <product-image :product="product" />
        <!-- <p class="d-title__3 mb-0 mt-3">Specifications</p>
        <p class="d-desc d-flex justify-content-between mb-0">
          <span>Packaging :	</span>
          <span>CAN/160</span>
        </p>
        <p class="d-desc d-flex justify-content-between mb-0">
          <span>Packaging :	</span>
          <span>CAN/160</span>
        </p>
        <p class="d-desc d-flex justify-content-between mb-0">
          <span>Packaging :	</span>
          <span>CAN/160</span>
        </p>
        <p class="d-desc d-flex justify-content-between mb-0">
          <span>Packaging :	</span>
          <span>CAN/160</span>
        </p> -->
      </div>
      <div class="r-wrapper">
        <div class="d-flex justify-content-between align-items-center">
          <div class="img-sec"></div>
          <p class="d-title__4 mb-0">{{price}} / per unit</p>
          <div class="plus-minus">
            <div class="minus" @click="decreaseQuantity">-</div>
            <div class="num">{{quantity}}</div>
            <div class="plus" @click="increaseQuantity">+</div>
          </div>
          <Button text="Add to Cart" classes="cart-btn" :submitting="submitting" :onclick="addToCart" />
        </div>
        <div class="dash mb-4 mt-2"></div>
        <grow-loader v-if="fetchingDescription" />
        <p class="d-desc" v-else>{{ hasDesc ? product.description : prodDetail.description}}</p>
        <!-- <p class="d-desc">CaviWipes, presaturated with CaviCide®, are a cleaner and disinfectant in one. They are durable, nonwoven, nonabrasive towelettes that offer quick, easy-to-use, time-saving convenience. They're recommended for use on non-porous surfaces and fixtures.</p>
        <p class="d-desc">Kills TB in 3 minutes and MRSA, HIV-1 and HBV and HCV in 2 minutes
        <p class="d-desc">Low alcohol (17.20% isopropanol) surface disinfectants</p>
        <p class="d-desc">Stays fully saturated; better fluid capacity means the wipes won't dry out</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import PlusMinusMixin from '@/mixins/PlusMinus'
import { mapActions } from 'vuex'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import ProductMixin from '@/mixins/Product'
import GrowLoader from '../loaders/GrowLoader.vue'
import ProductImage from '../ProductImage.vue'
export default {
  components: {
    Button,
    GrowLoader,
    ProductImage,
  },
  mixins: [PlusMinusMixin, OfficeMixin, ProductMixin],
  props: {
    product: Object
  },
  data: () => ({
    desc: null,
  }),
  created(){
    this.checkDesc()
  },
  computed: {
    hasDesc(){
      if(!this.product.description || this.product.description.trim().length == 0) return false
      return true
    },
  },
  methods: {
    async checkDesc(){
      if(!this.hasDesc){
        await this.getProductDetails({
          vendor: this.product.vendor.id,
          product_id: this.product.product_id,
          product_url: this.product.url
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.product-info
  .l-wrapper
    .img-sec
      width: 154px
      height: 144px
      border-radius: 4px
      border: solid 1px rgba(28, 69, 90, 0.1)
      background-color: #ebf4f8
  .r-wrapper
    width: -webkit-fill-available
    padding-left: 30px 
    .img-sec
      width: 101px
      height: 18px
      background-color: #ebf4f8
  .cart-btn
    min-height: 29px
    font-size: 8px
</style>