<template>
  <div class="landing-layout">
    <landing-header />
    <router-view></router-view>
    <landing-footer />
  </div>
</template>

<script>
import LandingFooter from '@/components/landing/footer/LandingFooter'
import LandingHeader from '@/components/landing/header/LandingHeader'
import META from '@/assets/json/meta.json'
export default {
  components: { LandingFooter, LandingHeader, },
  metaInfo() {
    let meta = META.find(m => m.path == this.$route.path)
    if(meta)
      return { 
        title: meta.title,
        meta: [
          { name: 'description', content:  meta.desc },
          { property: 'og:title', content: meta.title },
          { property: 'og:site_name', content: 'Ordo' },
          { property: 'og:type', content: 'website' },
        ]
      }
    return { 
      title: 'Ordo: Dental Inventory Management System',
      meta: [
        { name: 'description', content:  'Effortlessly manage your dental supply ordering and tracking. Ordo allows you to track inventory, compare prices from different suppliers, and much much more.' },
        { property: 'og:title', content: 'Ordo: Dental Inventory Management System' },
        { property: 'og:site_name', content: 'Ordo' },
        { property: 'og:type', content: 'website' },
      ]
    }
  },
}
</script>

<style lang="sass" scoped>
.landing-layout
  background-color: #F8F9FC
</style>