<template>
  <div class="login-form">
    <validation-observer ref="formObs" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(submit)">
        <validation-provider name="Email" rules="required|email" v-slot="validationContext">
          <b-input v-model="email" class="custom-input" placeholder="Email Address" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider name="Messages" v-slot="validationContext">
          <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <Button text="Reset Password" classes="w-100 mt-4" :onclick="submit" :submitting="submitting" />
      </b-form>
    </validation-observer>
    <modal :modal="infoModal" @change="toggleInfoModal" size="sm">
      <div class="auth-card__no-card p-0">
        <p class="auth-title text-center mb-3">Request Placed Successfully</p>
        <p class="auth-link text-center mb-3">Please check your email for further instructions</p>
        <Button text="Back to Login" classes="w-100 mt-4" :onclick="() => goTo({name: 'Login'})" />
      </div>
    </modal>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import FormMixin from '@/mixins/FormMixin'
import { mapActions } from 'vuex'
import GoTo from '@/mixins/GoTo'
import Modal from '@/components/modals/Modal'
export default {
  components: {
    Button,
    Modal,
  },
  mixins: [FormMixin, GoTo],
  data: () => ({
    email: null,
    submitting: false,
    infoModal: false,
  }),
  methods: {
    ...mapActions('USER', ['FORGOT_PASSWORD']),
    toggleInfoModal(v){
      if(v != this.infoModal) this.infoModal = v != null ? v : false
    },
    async submit(){
      if(this.submitting) return
      this.submitting = true
      let isValid = await this.$refs.formObs.validate()
      if(isValid){
        try{
          let payload = {
            email: this.email
          }
          await this.FORGOT_PASSWORD(payload)
          this.infoModal = true
        }catch(e){
          let errors = {}
          for(let key in e){
            errors[key[0].toUpperCase() + key.substr(1)] = e[key]
          }
          this.$refs.formObs.setErrors(errors);
        }
      }
      this.submitting = false
    },
  },
}
</script>

<style>

</style>