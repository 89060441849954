<template>
  <div class="">
    <div class="px-4">
      <DashHeadSearch :emitOnly="true" @head-search="onHeadSearch" class="mb-3 mt-3" />
    </div>
    
    <ShoppingSearch :text="searchText" :filters="filters" :orderApproval="true" :itemsToCheckIn="itemsToCheckIn"
      :orderId="orderId" />
  </div>
</template>

<script>
import DashHeadSearch from '@/components/dashboard/DashHeadSearch.vue'
import ShoppingSearch from '@/components/shopping/ShoppingSearch'
export default {
  components: { DashHeadSearch, ShoppingSearch, },
  props: {
    itemsToCheckIn: Array,
    orderId: Number,
  },
  data: () => ({
    searchText: '',
    filters: {}
  }),
  methods: {
    onHeadSearch(filters) {
      this.searchText = filters.q
      this.filters = filters
    },
  },
}
</script>