<template>
  <div class="manage-team d-card py-3 px-3">
    <div class="d-flex justify-content-between mb-1">
      <p class="d-title mt-2 mb-0" style="font-size: 18px;">Office Settings</p>
    </div>
    <div class="d-flex flex-column flex-md-row gap-12-px mb-4">
      <p class="mb-0 d-desc__m551" style="width: 450px;">Your office-related data will be here. If anything related to your office details needs to be changed or updated, you can do it below.</p>
      <b-btn class="custom-btn o-btn ml-md-auto add-o-btn" @click="ofcModal = true">+ &nbsp; Add an office</b-btn>
    </div>
    <div v-for="(office, oI) in filteredOffices" :key="oI">
      <div class="dash mb-4" v-if="oI > 0"></div>
      <div class="d-lg-flex justify-content-lg-between align-items-lg-center my-3" :ref="`ofc-set-${oI}`"
        v-b-toggle="`ofc-set-${oI}`" @click="handleAccordionToggle(oI, office.name)"> 
        <p class="mb-2 d-title__2" style="font-size: 16px;">{{ office.name }}</p>
        <div class="d-flex align-items-center">
          <b-icon icon="chevron-down" class="ml-3 cursor-pointer" style="outline: none"></b-icon>
        </div>
      </div>

      <b-collapse :id="`ofc-set-${oI}`" accordion="office-accordion" role="tabpanel" :visible="oI == 0">
        <office :office="office" :showAdditionalEditButton="true" />
        <div class="d-flex flex-wrap justify-content-end my-3 pr-2" style="gap: 15px">
        </div>
      </b-collapse>
    </div>
    <modal :modal="ofcModal" @change="toggleOfcModal" size="md">
      <p class="modal-header-title text-center">Add another office</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4">Need to add another office? Enter the information below to add another office to your Ordo account</p>
      </div>
      <div class="dash mb-0"></div>
      <div>
        <office-details  :single="true" @done="handleDone" :settingsPage="true" :offices="offices" />
      </div>
    </modal>
    <modal :modal="rmOfcModal" @change="toggleRmOfcModal" size="sm">
      <div>
        <p class="d-title text-center">Remove this office?</p>
        <div class="d-flex justify-content-between">
          <Button text="Remove" classes="danger__2 cr-btn" :onclick="removeOffice" :submitting="removing" />
          <Button text="Cancel" classes="cr-btn" :onclick="() => rmOfcModal = false" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Office from '@/components/dashboard/settings/Office'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/modals/Modal'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import NumberComma from '@/mixins/NumberComma'
import BillingInfo from '@/components/onboarding/BillingInfo'

export default {
  components: {
    Office,
    Modal,
    OfficeDetails,
    Button,
    BillingInfo
  },
  mixins: [OfficeMixin, NumberComma],
  data: () => ({
    linkOpenDentalModal: false,
    ofcModal: false,
    rmOfcModal: false,
    removing: false,
    selectedOfc: null,
  }),
  computed: {
    ...mapGetters('USER', ['company', 'offices']),
    filteredOffices() {
      return [this.offices[this.officeIndex]].concat(this.offices.filter(o => o.id != this.officeId))
    },
  },
  methods: {
    ...mapActions('OFFICE', ['REMOVE_OFFICE', 'FETCH_OPENDENTAL_CONNECTION_STATUS']),
    ...mapActions('USER', ['GET_ACCOUNT']),
    openRmModal(ofc) {
      this.selectedOfc = ofc
      this.rmOfcModal = true
    },
    toggleOfcModal(v) {
      if (v != this.ofcModal) this.ofcModal = v != null ? v : false
    },
    toggleRmOfcModal(v) {
      if (v != this.rmOfcModal) this.rmOfcModal = v != null ? v : false
    },
    toggleLinkOpenDentalModal(v) {
      if (v != this.linkOpenDentalModal) this.linkOpenDentalModal = v != null ? v : false
    },
    async removeOffice() {
      console.log('remove')
      if (this.removing) return
      this.removing = true
      try {
        
        await this.REMOVE_OFFICE({ office: this.selectedOfc.id })
        await this.GET_ACCOUNT()
        this.toggleRmOfcModal(false)
        this.selectedOfc = null
      } catch (e) { console.log(e) }
      this.removing = false
    },
    handleAccordionToggle(index, officeName, name) {
      this.$emit('accordion-toggle', { index, officeName });
    },
    handleDone() {
      this.ofcModal = false;
    }
  },
}
</script>

<style lang="sass" scoped>

@import '../../../style/settings' 
.cr-btn
  min-width: 100px
.add-o-btn
  min-width: 119px

.custom-modal
  padding:20px
</style>

<style lang="sass">
.custom-modal
  padding:30px 15px !important
</style>