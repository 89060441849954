<template>
  <div class="auth-card">
    <p class="auth-title mb-3">Log In</p>
    <login-form class="mb-3" />
    <p class="auth-link mb-0">
      <span>Forgot your password?</span>
      <span class="text-primary cursor-pointer ml-3" @click="goTo({name: 'ForgotPassword'})">Reset HERE</span>
    </p>
  </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    LoginForm,
  },
  mixins: [GoTo]
}
</script>

<style>

</style>