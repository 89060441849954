<template>
  <div class="px-3">
    <div class="outer d-flex flex-column flex-lg-row gap-x-70-px gap-y-60-px mx-auto align-items-center justify-content-between">
      <img src="./landing_images/image_4.webp" class="svg" />
      <div class="contents">
        <p class="title-1">Track and manage everything from orders to spending</p>
        <p class="subtitle-1 mb-0">With Ordo, you can track your dental supply orders from start to finish with ease! Mark items as received, process returns, and keep your budget on track with just a few clicks. Plus, our approval process prevents any budget surprises and you can even track your front office supplies separately.</p>
        <RouterLink to="/feature-tracking-orders-returns" class="mb-0 link text-none d-block mt-3 text-center text-lg-left">
          Learn more
          <RightArrow class="mb-2-px w-16-px h-16-px" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import RightArrow from '@/components/svg/icons/solid/RightArrow.vue'
export default {
  components: { RightArrow, },
}
</script>

<style lang="sass" scoped>
.outer
  max-width: 1056px
  margin-top: 8rem
  .contents
    max-width: 550px
    .title-1
      font-family: GILROY-BOLD
      font-size: 34px
      color: #274458
      line-height: 1.2
    .subtitle-1
      font-size: 16px
      color: #788288
      line-height: 1.6
    .link
      font-family: GILROY-SEMIBOLD
      color: #50A9D5
      font-size: 16px
      &:hover
        text-decoration: none
  .svg
    width: 500px
    height: 350px
    // margin-left: -37px
    max-width: 100%
    @media screen and (min-width: 992px)
      margin-left: -50px
@media screen and (max-width: 992px)
  .outer
    margin-top: 6rem
    .title-1
      font-size: 24px
      text-align: center
    .subtitle-1
      font-size: 14px
      text-align: center
    .svg
      width: auto
      margin: 0 auto
      height: auto
</style>