<template>
  <div>
    <Button text="Add Billing Information" style="min-height: 30px;" :onclick="onClickAddBilling" />
    <modal :modal="billModal" @change="toggleBillModal" size="md">
      <p class="modal-header-title text-center">Add Billing Information for {{ name }}</p>
      <div class="dash mb-4"></div>
      <validation-observer ref="billObs" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(submit)" class="mt-3">
          <p class="form-label mb-1">Credit Card Number</p>
          <validation-provider name="Card Number" rules="required" v-slot="validationContext">
            <b-input v-model="formData.cc_number" v-cardformat:formatCardNumber class="custom-input" placeholder="Enter Card Number" :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
          </validation-provider>
          <b-row>
            <b-col cols="6">
              <validation-provider name="Expiry" rules="required" v-slot="validationContext">
                <p class="form-label mb-1 mt-3">Expiration Date</p>
                <b-input class="custom-input" placeholder="MM/YY" maxlength="7" v-cardformat:formatCardExpiry @keydown="e => e.target.setAttribute('maxlength', 7)" v-model="formData.cc_expiry" :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider name="CVV" rules="required" v-slot="validationContext">
                <p class="form-label mb-1 mt-3">CVV</p>
                <b-input v-model="formData.cc_code" v-cardformat:formatCardCVC class="custom-input" placeholder="Enter CVV" :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <div class="dash mt-4"></div>
          <validation-provider name="address" rules="" v-slot="validationContext">
            <p class="form-label mb-1 mt-3">Billing Address</p>
            <b-input v-model="formData.address" class="custom-input" placeholder="Enter your card address" :state="getValidationState(validationContext)"></b-input>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
          </validation-provider>
          <b-row>
            <b-col cols="4">
              <validation-provider name="city" rules="" v-slot="validationContext">
                <p class="form-label mb-1 mt-3">City</p>
                <b-input v-model="formData.city" class="custom-input" placeholder="Enter City" :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider name="state" rules="" v-slot="validationContext">
                <p class="form-label mb-1 mt-3">State</p>
                <b-form-select v-model="formData.state" :options="states" class="custom-input" placeholder="State" :state="getValidationState(validationContext)"></b-form-select>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider name="zip" rules="min:5" v-slot="validationContext" :custom-messages="{ 'required': 'Zip Code field must be at least 5 characters', 'min': 'Zip Code field must be at least 5 characters' }">
                <p class="form-label mb-1 mt-3">Zip Code</p>
                <b-input v-model="formData.zip" class="custom-input" placeholder="Enter Zip" v-cardformat:restrictNumeric :state="getValidationState(validationContext)"></b-input>
                <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <div class="dash mt-4"></div>
          <validation-provider name="Messages" v-slot="validationContext">
            <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
            <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
          </validation-provider>
          <div class="d-flex justify-content-center mt-4">
            <b-btn class="custom-btn o-btn" :class="[ success && 'success' ]" type="submit">
              <b-spinner small variant="light" class="mr-2" v-if="submitting"></b-spinner>
              {{ btnText }} 
            </b-btn>
          </div>
        </b-form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue'
import Modal from '@/components/modals/Modal.vue'
import FormMixin from '@/mixins/FormMixin.vue'
import US_STATES from '@/assets/json/usa_states.json'
import moment from 'moment'
export default {
  components: { Button, Modal },
  mixins: [ FormMixin ],
  props: {
    slug: String,
    name: String,
  },
  data: () => ({
    billModal: false,
    formData: {
      card: null,
      expiration: null,
      security: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    },
    submitting: false,
    success: false,
  }),
  created(){
    this.$BUS.$on('checkout:show-billing-' + this.slug, (key) => {
      this.billModal = true
    })
  },
  beforeDestroy(){
    this.$BUS.$off('checkout:show-billing-' + this.slug)
  },
  computed: {
    states(){ return US_STATES },
    btnText(){
      if(this.success) return 'Saved'
      return 'Submit'
    },
  },
  methods: {
    toggleBillModal(v){
      if(v != this.billModal) this.billModal = v || false
    },
    onClickAddBilling(){
      this.billModal = true
    },
    async submit(){
      if(this.submitting) return
      let isValid = await this.$refs.billObs.validate()
      if(isValid){
        this.submitting = true
        try{
          const payload = {
            cc_number : this.formData.cc_number ? this.formData.cc_number.replaceAll(' ', '') : this.formData.cc_number,
            cc_expiry : this.formData.cc_expiry ? this.formData.cc_expiry.replaceAll(' ', '') : this.formData.cc_expiry,
            cc_code : this.formData.cc_code ? this.formData.cc_code.replaceAll(' ', '') : this.formData.cc_code,
            address : this.formData.address ? this.formData.address : this.formData.address,
          }
          this.success = true
          setTimeout(() => {
            this.$store.state.CART.checkoutData.order_details[this.slug]['credit_info'] = JSON.parse(JSON.stringify(payload))
            this.$BUS.$emit('order-summary:checkout')
            this.$emit('close')
            this.success = false
            this.billModal = false
          }, 500)
        }catch(e){
          this.$refs.billObs.setErrors({ Messages: e.message })
        }
        this.submitting = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../style/onboarding/onboarding'
.modal-header-title
  font-family: GILROY-BOLD
  font-size: 22px
  font-stretch: normal
  font-style: normal
  line-height: 1.2
  letter-spacing: normal
  color: #2e4356
</style>