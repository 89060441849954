<template>
  <div class="">
    <validation-observer ref="formObs" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(submit)">
        <validation-provider name="Budget Name" rules="required" v-slot="validationContext">
          <b-input v-model="formData.name" class="custom-input w-100" style="height: 40px !important;" placeholder="Budget Name" type="text" :state="getValidationState(validationContext)"></b-input>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <!-- <validation-provider name="vendors" rules="" v-slot="validationContext">
          <multiselect v-model="formData.vendors" :options="vendorOptions" :multiple="true" class="custom-input w-100 mt-3" style="height: 40px !important;" placeholder="Vendors"></multiselect>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" :style="{ display: validationContext.invalid ? 'block': 'none' }">{{ error }}</b-form-invalid-feedback>
        </validation-provider> -->
        <validation-provider name="offices" rules="" v-slot="validationContext">
          <multiselect v-model="formData.offices" :options="officeOptions" :multiple="true" class="custom-input w-100 mt-3" style="height: 40px !important;" placeholder="Offices"></multiselect>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" :style="{ display: validationContext.invalid ? 'block': 'none' }">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <b-row class="mx-0 mt-3">
          <b-col cols="12" class="px-0">
            <p class="form-label mb-1">Target Spend Percentage</p>
            <validation-provider name="Percentage" rules="required" v-slot="validationContext">
              <div class="position-relative">
                <b-input @keyup="onKeyUpPercentageDental($event)" v-model="formData.percentage" class="custom-input w-100" style="height: 40px !important; background-image: unset;" step="0.25" placeholder="Enter Target Spend Percentage" type="text" :state="getValidationState(validationContext)"></b-input>
                <div class="arrows d-flex flex-column">
                  <b-icon class="cursor-pointer" icon="caret-up-fill" font-scale="0.8" @click="onKeyUpPercentageDental(undefined, '0.1')"></b-icon>
                  <b-icon class="cursor-pointer" icon="caret-down-fill" font-scale="0.8" @click="onKeyUpPercentageDental(undefined, '0.1', true)"></b-icon>
                </div>
              </div>
              <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI" :style="{ display: validationContext.invalid ? 'block': 'none' }">{{ error }}</b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <validation-provider name="Messages" v-slot="validationContext">
          <b-form-select class="d-none" :state="getValidationState(validationContext)"></b-form-select>
          <b-form-invalid-feedback v-for="(error, eI) in validationContext.errors" :key="eI">{{ error }}</b-form-invalid-feedback>
        </validation-provider>
        <Button text="Submit" class="w-100 mt-4" :onclick="submit" :submitting="submitting" />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Button from '@/components/ui/Button'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NumberComma from '@/mixins/NumberComma'
export default {
  mixins: [ FormMixin, NumberComma ],
  components: { Button, Multiselect: Treeselect, },
  data: () => ({
    formData: {
      name: null,
      vendors: [],
      offices: [],
      percentage: '0%'
    },
    submitting: false,
  }),
  computed:{
    ...mapGetters('USER', ['offices']),
    officeOptions(){
      return this.offices.map(o => ({ id: o.id, label: o.name }))
    },
    vendorOptions(){
      return [
        { id: "henry_schein", label: "henry_schein" },
        { id: "darby", label: "darby" },
        { id: "patterson", label: "patterson" },
        { id: "amazon", label: "amazon" },
        { id: "benco", label: "benco" },
        { id: "ultradent", label: "ultradent" },
        { id: "implant_direct", label: "implant_direct" },
        { id: "edge_endo", label: "edge_endo" },
        { id: "dental_city", label: "dental_city" },
        { id: "dcdental", label: "dcdental" },
        { id: "crazy_dental", label: "crazy_dental" },
        { id: "purelife", label: "purelife" },
        { id: "skydental", label: "skydental" },
        { id: "top_glove", label: "top_glove" },
        { id: "bluesky_bio", label: "bluesky_bio" },
        { id: "practicon", label: "practicon" },
        { id: "midwest_dental", label: "midwest_dental" },
        { id: "pearson", label: "pearson" },
        { id: "salvin", label: "salvin" },
        { id: "bergmand", label: "bergmand" },
        { id: "biohorizons", label: "biohorizons" },
        { id: "atomo", label: "atomo" },
        { id: "orthoarch", label: "orthoarch" },
        { id: "office_depot", label: "office_depot" },
        { id: "safco", label: "safco" },
        { id: "net_32", label: "net_32" },
      ]
    },
  },
  methods: {
    ...mapActions('OFFICE', ['CREATE_CUSTOM_BUDGET', 'FETCH_OFFICE_BUDGETS']),
    async submit (){
      if(this.submitting) return
      this.submitting = true
      let isValid = await this.$refs.formObs.validate();
      try{
        if(isValid){
          // if(this.formData.vendors.length == 0){
          //   this.$refs.formObs.setErrors({ vendors: ['Select Vendor'] })
          //   this.submitting = false
          //   return
          // }
          if(this.formData.offices.length == 0){
            this.$refs.formObs.setErrors({ offices: ['Select Office'] })
            this.submitting = false
            return
          }
          const payload = []
          this.formData.offices.forEach(officeId => {
            payload.push({
              office: officeId,
              id: this.$store.state.OFFICE.officeBudget[officeId].id,
              subaccounts: [ 
                ...this.$store.state.OFFICE.officeBudget[officeId].subaccounts.filter(subaccount => subaccount.percentage != null)
                .map(subaccount => {
                  const obj = { ...subaccount }
                  delete obj.vendors
                  return obj
                }),
                { name: this.formData.name, percentage: this.currencyToNumber(this.formData.percentage) , vendors: this.formData.vendors }
              ]
            })
          })
          await Promise.all(
            payload.map(pl => new Promise((resolve, reject) => {
              this.CREATE_CUSTOM_BUDGET(pl).then(res => resolve(res)).catch(e => reject(e))
            })),
          )
          this.$emit('done')
        }
      }catch(e){
        let errors = {
          'Messages': e
        };
        if(e.detail) errors.Messages = e.detail
        this.$refs.formObs.setErrors(errors);
      }
      this.submitting = false
    },
    onKeyUpPercentageDental(event, incBy, dec){
      if(incBy != null || ['0','1','2','3','4','5','6','7','8','9'].includes(event.key)){
        let percentage = this.formData.percentage || '0'
        if(percentage && percentage.trim().length == 0) {
          this.formData.percentage = null
        }
        else if(percentage) {
          if(percentage.includes('%')) percentage = percentage.replaceAll('%', '')
          if(incBy) {
            if(dec && parseFloat(percentage) > 0) percentage = (this.currencyToNumber(percentage) - parseFloat(incBy)).toFixed(2)
            else percentage = (this.currencyToNumber(percentage) + parseFloat(incBy)).toFixed(2)
          }
          this.formData.percentage = this.numberWithCommas(percentage)
          if(this.formData.percentage && !this.formData.percentage.includes('%')) this.formData.percentage += '%'
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../style/settings'
@import '../../../style/onboarding/onboarding' 
::v-deep .vue-treeselect
  .vue-treeselect__control
    border: none
  .vue-treeselect__placeholder, .vue-treeselect__single-value
    font-family: GILROY-MEDIUM
    font-size: 12px
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: normal
    color: #707070
    display: flex
    align-items: center
  .vue-treeselect-helper-hide
    display: none !important
.arrows
  position: absolute
  right: 10px
  bottom: 7px
</style>