<template>
  <div class="d-flex align-items-center us-card mb-lg-0">
    <b-dropdown right class="menu-drp acc" ref="drp">
      <template #button-content>
        <div :class="[{ 'd-flex align-content-center': !mobile }]">
          <div :class="['avatar', { 'mr-3': !mobile }]" v-if="profile.avatar">
            <img :src="profile.avatar" :alt="fullName" class="w-100 h-100">
          </div>
          <div :class="['d-icon', { 'mr-3': !mobile }]" v-else>
            <img src="@/assets/images/user_profile.png" :alt="fullName" style="width: 20px; height: 20px;">
          </div>
        </div>
      </template>
      <div class="user-menu">
        <div class="d-flex align-items-center cursor-pointer p-3 user-menu__item"
          @click="goTo({ name: 'Settings', hash: '#Usr' })">
          <img src="@/assets/images/settings.png" :alt="settings" style="width: 25px; height: 25px;" class="mr-3">
          <p class="d-title__6 mb-0" style="font-size: 14px; color: #1c455a">Account Settings</p>
        </div>
        <div v-if="offices.length > 1" class="d-flex align-items-center cursor-pointer p-3 user-menu__item"
          @click="switchOffice">
          <img src="@/assets/images/switch_office.png" :alt="switchOffices" style="width: 25px; height: 25px;"
            class="mr-3">
          <p class="d-title__6 mb-0" style="font-size: 14px; color: #1c455a">Switch Office</p>
        </div>
        <!-- <div v-if="!isAdminView()" class="d-flex align-items-center cursor-pointer p-3 user-menu__item"
          @click="goTo({ name: 'AdminDashboard'  })">
          <img src="@/assets/images/switch_admin.png" :alt="swithch_admin" style="width: 25px; height: 25px;" class="mr-3">
          <p class="d-title__6 mb-0" style="font-size: 14px; color: #1c455a">Switch To Admin View</p>
        </div>
        <div v-else class="d-flex align-items-center cursor-pointer  p-3 user-menu__item" @click="goTo({ name: 'Dashboard' })">
          <img src="@/assets/images/home.png" :alt="switchUser" style="width: 25px; height: 25px;" class="mr-3">
          <p class="d-title__6 mb-0" style="font-size: 14px; color: #1c455a">Switch To User View</p>
        </div> -->
        <div class="d-flex align-items-center cursor-pointer p-3 user-menu__item" @click="logout">
          <img src="@/assets/images/logout.png" :alt="logout" style="width: 25px; height: 25px;" class="mr-3">
          <p class="d-title__6 mb-0" style="font-size: 14px; color: #1c455a">Log Out</p>
        </div>
      </div>
    </b-dropdown>

    <!-- Popup for Switch Office -->
    <modal :modal="switchOfficePopup" v-if="showSwitchOfficePopup" size="lg" :key="showSwitchOfficePopup"
      :hideCloseIcon="shouldHideCloseIcon" id="switchOfficeModal">
      <div class="backdrop" @click="toggleSwitchOfficePopup(false)"></div>
      <div class="auth-card__no-card p-0">
        <p class="auth-title text-center mb-4">Switch your office:</p>
        <div v-for="(office, oI) in filteredOffices" :office="{ id: officeId }" :key="oI" class="offices-list">
          <div class="dash mb-4" v-if="oI > 0"></div>
          <div @click="openOfc(office, $event)"  :class="{'selected-office': oI == officeIndex}" class="d-lg-flex justify-content-lg-between align-items-lg-center my-3"
            :ref="`ofc-set-${oI}`" v-b-toggle="`ofc-set-${oI}`">
            <div class="d-flex av-name">
              <div class="av" :class="[!office.logo && 'no-image']" style="width: 50px; height: 40px;">
                <!-- <img v-if="office.logo" :src="office.logo" alt="" class="w-100 h-100"> -->
                <img src="@/assets/images/Dental-Store-Icon.png" alt=""  style="max-width: 100%; height: 100%; border-radius: 5px">
              </div>
              <div class="d-flex justify-content-center flex-column pl-3 off-det">
                <p class="mb-0 d-title__2" style="font-size: 16px;">{{ office.name }}</p>
                <p class="mb-0 fs-12px" style="font-size: 16px;">
                  {{ office.addresses && office.addresses.length > 0 ? office.addresses[0]['address'] : '' }}
                </p>
              </div>
            </div>
            <div class="checkMark" v-if="oI == 0">
              <!-- <img src="../../assets/images/pink_check_mark.png" alt=""> -->
              ✓
            </div>
          </div>
        </div>

      </div>
      <!-- <p @click="closeOffice" class="gilroy-b text-danger__2 mt-3 mb-0 cursor-pointer text-right">CloseX</p> -->
      <p @click="toggleSwitchOfficePopup(false)" class="gilroy-b text-danger__2 mt-3 mb-0 cursor-pointer text-right">Close
      </p>
    </modal>
    <modal :modal="ofcModal" @change="toggleOfcModal" size="md">
      <p class="modal-header-title text-center">Add another company</p>
      <div class="d-flex justify-content-center">
        <p class="modal-header-desc text-center mb-4">Need to add another company? Enter the information below to add
          another company to your Ordo account</p>
      </div>
      <div class="dash mb-0"></div>
      <div>
        <office-details :single="true" @done="ofcModal = false" :settingsPage="true" :offices="offices" />
      </div>
    </modal>
  </div>
</template>
 
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import GoTo from '@/mixins/GoTo'
import localStorageService from '@/store/localStorageService';
import Modal from '@/components/modals/Modal'
import Office from '@/components/dashboard/settings/Office'
import OfficeDetails from '@/components/onboarding/OfficeDetails'
import Button from '@/components/ui/Button'
import OfficeMixin from '@/mixins/OfficeMixin'
import NumberComma from '@/mixins/NumberComma'
import Check from '@/components/svg/icons/Check'

export default {
  mixins: [GoTo, OfficeMixin, NumberComma],
  components: {
    Office,
    Modal,
    OfficeDetails,
    Button,
    Check
  },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      ofcModal: false,
      showSwitchOfficePopup: false,
      switchOfficePopup: false,
      shouldHideCloseIcon: true,
    };
  },
  computed: {
    ...mapGetters('USER', ['profile', 'fullName'], ['company', 'offices']),
    filteredOffices() {
      let res = this.offices.length > 1 ? [this.offices[this.officeIndex]].concat(this.offices.filter(o => o.id != this.officeId)) : [];
      console.log(res)
      // console.log(res[0]['company'])
      return res
    },
  },
  methods: {
    ...mapActions(['CLEAR'], 'OFFICE', ['REMOVE_OFFICE', 'FETCH_OPENDENTAL_CONNECTION_STATUS']),
    ...mapGetters('USER', ['role']),
    ...mapActions('ADMIN_VIEW', ['FETCH_COMPANY_WITH_TREE']),
    ...mapMutations('OFFICE', ['SET_SHOW_LOCATIONS', 'SET_OFFICE_X', 'SET_OFFICE_Y']),
    ...mapGetters('OFFICE', ['showLocations']),
    openOfc(ofc) {
      let index = this.offices.indexOf(ofc)
      let url = window.location.origin + '/' + index + '/dashboard'
      window.location = url
    },
    isAdminView() {
      const path = location.pathname;
      return path.includes('admin-dashboard') ||
        path.includes('procedure-analytics') ||
        path.includes('orders-analytics') ||
        path.includes('financial-analytics') ||
        path.includes('admin-orders');
    },
    async fetchAdminViewData() {
      let payload = { 'profile': this.profile }
      let data = await this.FETCH_COMPANY_TREE(payload)
      console.log(data)
    },
    toggleOfcModal(v) {
      if (v != this.ofcModal) this.ofcModal = v != null ? v : false
    },
    logout() {
      this.CLEAR();
      if (this.role() == 2) {
        localStorageService.setItem('lastVisitedRoute', this.$route.fullPath);
      }
      window.location = window.location.origin + '/login';
    },
    switchOffice() {
      this.showSwitchOfficePopup = true;
      this.switchOfficePopup = true;
    },
    toggleSwitchOfficePopup(v) {
      if (v !== this.switchOfficePopup) {
        this.switchOfficePopup = v || false;
        this.showSwitchOfficePopup = false;
      }
    },

    closeOffice() {
      this.showSwitchOfficePopup = false;
      this.switchOfficePopup = false;
    },
  },
  watch: {
    showSwitchOfficePopup(newVal) {
      console.log('showSwitchOfficePopup changed:', newVal);
    },
    switchOfficePopup(newVal) {
      console.log('switchOfficePopup changed:', newVal);
    },
    beforeDestroy() {
      console.log('Modal component is being destroyed');
    },
  },
};
</script>
 
<style lang="sass" scoped>
    .auth-card__no-card
      z-index: 10
      position: relative
   .backdrop 
     position: fixed
     top: 0
     left: 0
     width: 100%
     height: 100%
     background-color: rgba(0, 0, 0, 0.0)
     z-index: 9

   ::v-deep .menu-drp 
     &.acc
       .dropdown-menu 
         width: 267px
         padding: 0
         @media screen and (max-width: 992px)
           width: 100%
         &.show
           left: 0 !important
           top: 16px !important
           @media screen and (max-width: 992px)
            left: 10px !important
            top: 10px !important
            width: calc(100vw - 32px)
   .popup 
     /* Add styling for the popup, position it as needed */
     position: fixed
     top: 50%
     left: 50%
     transform: translate(-50%, -50%)
     background-color: white
     padding: 20px
     border: 1px solid #ccc
     z-index: 9999
   .user-menu
     &__item
       &:hover
         background-image: url('./Office_grad.svg')
         background-size: cover
         background-position: center
         p
           color: #fff !important 
  .offices-list
    &:hover
      .collapsed
        padding: 0 1.5rem
        transition: 0.3s linear
        transform: scale(1.1)
        p
          color: #000
    .collapsed
      transition: .3s linear
.off-det
  p
    line-height: normal
.fs-12px
  font-size: 12px !important
.auth-title
  font-size: 1.5rem !important
.d-icon 
  border-radius: 12px
  border: solid 1px #ebeaeb
  background-color: #fafafa
.checkMark
  background: #13bc05
  border-radius: 50%
  height: 26px
  width: 26px
  display: flex
  justify-content: center
  align-items: center
  color: #fff

 </style>
 