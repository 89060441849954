export default {
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatToCurrencyNumber(n) {
      // return n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)+\.)/g, ",")
      return n.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    },
    currencyToNumber(c){
      if(c == null) return 0
      let n = parseFloat(c.toString().replace(/[^0-9.-]+/g, ""))
      return !isNaN(n) ? n : 0
    },
  },
}