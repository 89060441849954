<template>
  <div>
    <!-- <dash-head class="mb-4" /> -->
    <!-- <grow-loader v-if="fetchingData " /> -->
    <div class="cart-page">
      <!-- <b-alert v-if="this.$store.getters['CART/getCCStatus']" show variant="success">Card Added Successfully</b-alert> -->
      <modal :modal="!getCCStatus()" @change="showCreditCardPopup = $event" :hideCloseIcon="true" size="md"
        c-classes="p-0 creditCard-update-modal" dialog-class="product-desc-dialog" v-if="showCreditCardPopup">
        <p class="d-title__8 text-capitalize p-3 mt-3 mb-0  t-showCreditCardPopup">
          credit card detail
        </p>
        <p class="px-3 pb-4 text-center">Looks like this is the first time you've ordered from Crazy Dental. Please
          enter your credit card details below to complete the order. (Don't worry, you only need to do this once.)
        </p>
        <div class="checkout">
          <div class="credit-card-box" :class="{ hover: isCcvFocused }">
            <div class="flip">
              <div class="front">
                <div class="d-flex">
                  <div class="chip"></div>
                  <div class="card-logo">
                    <img src="../../assets/images/master-card.png" alt="card logo" />
                  </div>
                </div>

                <div class="logo"><!-- SVG logo here --></div>
                <div class="number">
                  <label>Card Number</label>

                  {{ formattedCardNumber }}
                </div>
                <div class="card-holder">
                  <label>Card holder Name</label>
                  <div>{{ cardHolder }}</div>
                </div>
                <div class="card-expiration-date">
                  <label>Expires</label>
                  <div>{{ formattedExpirationDate() }}</div>
                </div>
              </div>
              <div class="back">
                <div class="strip"></div>
                <div class="logo"><!-- SVG logo here --></div>
                <div class="ccv">
                  <label>CCV</label>
                  <div>{{ ccv }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--  @submit.prevent="submitForm" -->
          <form class="form" autocomplete="off" @submit.prevent="submitForm" novalidate>
            <div class="d-flex card-fieldset">
              <fieldset>
                <label for="card-number">Card Number</label>
                <input type="text" v-model="cardNumber" class="input-cart-number w-100" maxlength="19"
                  @input="formatCardNumber" placeholder="" />
              </fieldset>
              <fieldset>
                <label for="card-holder">Card holder Name</label>
                <input type="text" id="card-holder" placeholder="" v-model="cardHolder" />
              </fieldset>
            </div>

            <fieldset class="fieldset-expiration">
              <label for="card-expiration-month">Expiration date</label>
              <select v-model="expirationMonth" id="card-expiration-month">
                <option disabled value="">Month</option>
                <option v-for="month in 12" :key="month" :value="month">
                  {{ month.toString().padStart(2, "0") }}
                </option>
              </select>
              <select v-model="expirationYear" class="ml-1" id="card-expiration-year">
                <option disabled value="">Year</option>
                <option v-for="year in generateYears(2015, 2035)" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </fieldset>
            <fieldset class="fieldset-ccv">
              <label for="card-ccv">CCV</label>
              <input type="text" id="card-ccv" v-model="ccv" maxlength="3" placeholder=""
                @focus="isCcvFocused = true" @blur="isCcvFocused = false" />
            </fieldset>

            <div class="address-card">
              <!-- <div class="d-flex justify-content-end">
                <span> <i class="fa fa-pen"></i> Update</span>
              </div> -->
              <div class="d-flex card-fieldsetc flex-wrap">
                <fieldset>
                  <label for="address-no">Address</label>
                  <input type="text" id="address-no" class="input-cart-number w-100" placeholder="" maxlength="19"
                    v-model="addressee" />
                </fieldset>
                <fieldset>
                  <label for="city">City</label>
                  <input type="text" id="city" class="input-cart-number w-100" placeholder="" maxlength="19"
                    v-model="city" />
                </fieldset>
                <fieldset>
                  <label for="State">State</label>
                  <input type="text" id="State" class="input-cart-number w-100" placeholder="" maxlength="19"
                    v-model="state" />
                </fieldset>
                <fieldset>
                  <label for="Zip">Zip</label>
                  <input type="text" id="Zip" class="input-cart-number w-100" placeholder="" maxlength="19"
                    v-model="zip" />
                </fieldset>
                <fieldset>
                  <label for="Country">Country</label>
                  <input type="text" id="Country" class="input-cart-number w-100" placeholder="" maxlength="19"
                    v-model="country" />
                </fieldset>
              </div>

              <!-- <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                class="w-100"
                placeholder="Address"
                v-model="address"
              ></textarea> -->
            </div>
            <p v-if="creditCardPostErrors" class="mb-0 d-title text-danger">
              {{ creditCardPostErrors }}
            </p>
            <div class="d-flex w-100">
              <!-- <button class="btn btn-secondary custom-btn w-100" type="submit" @click="submitCreditCardDetails">
                <i class="fa fa-lock"></i> Submit
              </button> -->
              <button class="btn btn-secondary custom-btn w-100" type="submit" @click="submitCreditCardDetails"
                :disabled="loading">
                <span v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...
                </span>

                <span v-else><i class="fa fa-lock"></i> Submit</span>
              </button>
              <button @click="hidePopup()" class="btn btn-danger custom-btn w-100" v-if="!loading">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </modal>
      
      <SearchHeadCover class="centered-contents d-lg-none" title="Checkout"
        desc="You're almost there! Select your shipping, confirm details of your order for each vendor and click Complete Order. Keep track of your orders on the Dashboard and make sure to mark it received once you get it!" />
      <div class="d-flex flex-column flex-lg-row mt-3">
        <div class="l-part order-2 order-lg-1">
          <SearchHeadCover class="centered-contents d-none d-lg-block" title="Checkout"
            desc="You're almost there! Select your shipping, confirm details of your order for each vendor and click Complete Order. Keep track of your orders on the Dashboard and make sure to mark it received once you get it!" />
          <div class="d-card px-3 py-3 checkout-card mt-3" v-for="vendorId in keys" :key="vendorId">
            <div class="d-flex align-items-center justify-content-between">
              <skeleton-loader type="rect" width="300px" height="23px" :rounded="true" :radius="23" animation="wave"
                v-if="fetchingData" />
              <p class="mb-0 d-title__11" v-else>
                {{ checkoutData.order_details[vendorId].name }}
              </p>
              <p class="mb-0 d-title__2 f-12-px cursor-pointer" @click="viewProducts = !viewProducts">
                {{ viewProducts ? "Close" : "View Products" }}
              </p>
            </div>
            <div class="mt-3" v-if="viewProducts && !fetchingData">
              <order-items :orders="orderedProductsForCheckoutByVendor(
          checkoutData.order_details[vendorId].id
        )
          " :checkout-page="true" />
            </div>
            <div :class="['dash mb-3', { 'mt-3': !viewProducts }]"></div>
            <div class="d-lg-flex">
              <div class="c-l-part">
                <div class="d-flex align-items-center mb-3">
                  <div class="sl-num">
                    <p class="d-desc mb-0 text-white">1</p>
                  </div>
                  <p class="mb-0 d-title__2 text-danger__2 ml-2">
                    Select Shipping Option
                  </p>
                </div>
                
               
                <div v-if="getShippingMethodsOf(checkoutData.order_details[vendorId].slug).length > 0" class="pr-lg-3 pb-3">
    <div v-for="(option, oI) in getShippingMethodsOf(checkoutData.order_details[vendorId].slug)" :key="oI">
        <div class="dash my-2"></div>
        <div class="ship-method d-flex align-items-center px-2 cursor-pointer" @click="toggleShipOption(vendorId, option)">
            <div class="ck-wrp">
                <img src="@/assets/images/pink_check_mark.png" alt="" class="ck" 
                     v-if="(shipping_options[vendorId] && shipping_options[vendorId].id === shippingOptionId[oI]) || 
                          (['benco', 'henry_schein'].includes(checkoutData.order_details[vendorId].slug) && oI === 0)" />
            </div>
            <p class="mb-0 d-title__13 ml-2">{{ option.name }}</p>
        </div>
    </div>
    <div class="dash my-2"></div>
</div>
<div v-else class="pr-lg-3 pb-3">
    <div class="dash my-2"></div>
    <div class="ship-method d-flex align-items-center px-2 cursor-pointer" @click="toggleShipOption(vendorId, option)">
        <div class="ck-wrp">
            <img src="@/assets/images/pink_check_mark.png" alt="" class="ck" v-if="!fetchingData" />
        </div>
        <p class="mb-0 d-title__13 ml-2">
            <skeleton-loader type="rect" width="80px" height="23px" :rounded="true" :radius="23" animation="wave" v-if="fetchingData" />
            <span v-else>Standard Shipping</span>
        </p>
    </div>
    <div class="dash my-2"></div>
</div>






              </div>
              <div class="c-r-part px-3">
                <table class="w-100 checkout-pricing-table">
                  <!-- <tr>
                    <td class="d-title__2">Savings :</td> 
                    <td class="d-title__2 text-right text-success">
                      <skeleton-loader class="ml-auto" type="rect" width="137px" height="20px" :rounded="true"
                        :radius="20" animation="wave" v-if="fetchingData" />
                     <span v-else>${{(checkoutData.order_details[vendorId].subtotal_amount || 0).toFixed(2)}}</span> -->
                  <!-- <span v-else>${{ getSubSavings(vendorId) }}</span>
                    </td>
                  </tr> -->
                  <tr>
                    <td class="d-title__2">Subtotal :</td>
                    <td class="d-title__2 text-right text-success">
                      <skeleton-loader class="ml-auto" type="rect" width="137px" height="20px" :rounded="true"
                        :radius="20" animation="wave" v-if="fetchingData" />
                      <!-- <span v-else>${{(checkoutData.order_details[vendorId].subtotal_amount || 0).toFixed(2)}}</span> -->
                      <span v-else>${{ getSubTotal(vendorId).toFixed(2) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="d-title__2">Tax :</td>
                    <td class="d-title__2 text-right text-success">
                      <skeleton-loader class="ml-auto" type="rect" width="95px" height="17px" :rounded="true"
                        :radius="17" animation="wave" v-if="fetchingData" />
                      <span v-b-tooltip.hover title="Calculated by Vendor" v-else-if="(checkoutData.order_details[vendorId].tax_amount ||
          0) == 0
          "><b-icon class="h5 mb-2" icon="info-circle"></b-icon>
                      </span>
                      <span v-b-tooltip.hover title="Calculated by Vendor" v-else>${{
          (
            checkoutData.order_details[vendorId].tax_amount || 0
          ).toFixed(2)
        }}</span>
                    </td>
                  </tr>
                  <tr class="last-row"></tr>
                  <tr class="last-row">
  <td class="d-title__2">Shipping :</td>
  <td class="d-title__2 text-right text-success">
    <skeleton-loader class="ml-auto" type="rect" width="95px" height="17px" :rounded="true"
      :radius="17" animation="wave" v-if="fetchingData" />

    <!-- Check if vendorId is 'Patterson' -->
    <span v-if="vendorId === 'patterson' && !fetchingData">
      <skeleton-loader class="ml-auto" type="rect" width="95px" height="17px" :rounded="true"
                        :radius="17" animation="wave" v-if="fetchingData" />
                      <span v-b-tooltip.hover title="Calculated by Vendor" v-else-if="(checkoutData.order_details[vendorId].tax_amount ||
          0) == 0
          "><b-icon class="h5 mb-2" icon="info-circle"></b-icon>
                      </span>
                      <span v-b-tooltip.hover title="Calculated by Vendor" v-else>${{
          (
            checkoutData.order_details[vendorId].tax_amount || 0
          ).toFixed(2)
        }}</span>
    </span>

    <!-- Display shipping cost -->
    <span v-else-if="getShippingCost(
          vendorId,
          getTotalAmount(vendorId),
          0
        ) == 0
          ">Free</span>
                      <span v-else>${{
          getShippingCost(vendorId, getTotalAmount(vendorId), 0)
        }}</span>
  </td>
</tr>
                  <tr class="total-row">
                    <td class="d-title__2">Total :</td>
                    <td class="d-title__2 text-right text-success">
                      <skeleton-loader class="ml-auto" type="rect" width="95px" height="17px" :rounded="true"
                        :radius="17" animation="wave" v-if="fetchingData" />
                      <span v-else>${{
          Number(getSubTotal(vendorId) +
            getShippingCost(vendorId, getTotalAmount(vendorId), 0)).toFixed(2)
        }}</span>
                    </td>
                  </tr>
                </table>

                <div class="d-flex align-items-center mt-4">
                  <div class="sl-num">
                    <p class="d-desc mb-0 text-white">2</p>
                  </div>
                  <p class="mb-0 d-title__2 text-danger__2 ml-2">
                    Confirm Payment Method
                  </p>
                </div>

                <div
                  class="checkout-pay-to-btn-wrapper d-flex justify-content-between align-items-center py-2 px-2 mt-3">
                  <p class="mb-0 d-title__6" style="color: #1c455a; min-width: 56px">
                    Payment :
                  </p>
                  <skeleton-loader type="rect" width="95px" height="17px" :rounded="true" :radius="17" animation="wave"
                    v-if="fetchingData" />
                  <p class="mb-0 d-title__6 text-line-1" style="color: #1c455a" v-else-if="checkoutData.order_details[vendorId].payment_method
          ">
                    {{ checkoutData.order_details[vendorId].payment_method }}
                  </p>
                  <p class="mb-0 d-title__6 text-line-1" style="color: #1c455a" v-else>
                    Payment Method on File
                    </p>
                    <AddBillingInCheckout :slug="checkoutData.order_details[vendorId].slug"
                    :name="checkoutData.order_details[vendorId].name" v-if="!fetchingData &&
          ['dcdental', 'top_glove'].includes(
            checkoutData.order_details[vendorId].slug
            ) &&
            checkoutData.order_details[vendorId].credit_info == null
            " @close="$forceUpdate()" />
                </div>
                <div style="display: flex; justify-content: flex-end;">
                  <span @click="toggleCreditCardPopup" v-if="!CD_CCvalue" style="cursor: pointer; font-size: 12px; font-family: GILROY-Bold; color: #FF1DC3">Add Payment Details</span>
                </div>
                <div class="d-flex align-items-center mt-4">
                  <div class="sl-num">
                    <p class="d-desc mb-0 text-white">3</p>
                  </div>
                  <p class="mb-0 d-title__2 text-danger__2 ml-2">
                    Confirm Shipping Address
                  </p>
                </div>
                <div
                  class="checkout-pay-to-btn-wrapper d-flex justify-content-between align-items-center py-2 px-2 mt-3">
                  <p class="mb-0 d-title__6 mr-3" style="color: #1c455a; min-width: 47px">
                    Ship To :
                  </p>
                  <skeleton-loader type="rect" width="95px" height="17px" :rounded="true" :radius="17" animation="wave"
                    v-if="fetchingData" />
                  <!-- <p class="mb-0 d-title__6 text-right ml-auto" style="color: #1c455a;" v-else-if="checkoutData.order_details[vendorId].shipping_address">{{checkoutData.order_details[vendorId].shipping_address}}</p> -->
                  <p class="mb-0 d-title__6 text-right ml-auto" style="color: #1c455a" v-else-if="checkoutData.order_details[vendorId].shipping_address
          ">
                    {{ officeAddress }}
                  </p>
                  <p class="mb-0 d-title__6 text-right ml-auto" style="color: #1c455a" v-else>
                    {{ officeAddress }}
                  </p>
                  <!-- <p class="mb-0 d-title__6 text-right ml-auto" style="color: #1c455a;" v-else>Shipped Address we have on file for the office</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pl-lg-3 r-part order-1 order-lg-2">
          <order-summary-card :disabled="isCompleteOrderDisabled" ref="orderSummaryCard" :loading="fetchingData"
            :d="checkoutData" :completeBtn="true" :ckOut="checkingOut" :init-total="totalValue()" />
        </div>
      </div>
      <DashboardFooter class="mt-3" />
    </div>
    <modal :modal="infoModal" @change="toggleInfoModal" size="md" :noCloseOnBackdrop="true">
      <div class="d-flex justify-content-center">
        <img src="@/assets/images/x.png" alt="" />
      </div>
      <p class="d-title text-center my-3">
        Oops.. we encounterd an error on your order
      </p>
      <p class="d-desc__2 text-center mb-3">
        While processing your order we ran into an issue. Please confirm your
        billing details and shipping info is correct
      </p>
      <div class="d-flex justify-content-center">
        <Button text="Back to Checkout" classes="mt-4" :onclick="() => goTo({ name: 'Cart' })" />
      </div>
    </modal>
    <!-- for price update  -->
    <modal :modal="priceUpdateModal" @change="togglePriceUpdateModal" :hideCloseIcon="true" size="md"
      c-classes="p-0 price-update-alert-modal" dialog-class="product-desc-dialog">
      <div class="d-flex flex-column gap-16-px p-4">
        <p class="d-title f-18-px d-flex gap-8-px align-items-center mb-2">
          <img src="@/assets/images/caution.png" class="h-18-px w-18-px mb-1" />
          Important messages about items in your cart:
        </p>
        <div class="" v-for="(vendorId, vId) in vendorIdsHasUpdatedPrices" :key="vId">
          <p class="d-title f-14-px mb-2">
            {{ getUpdatedPriceProducts(vendorId).length }} item<span
              v-if="getUpdatedPriceProducts(vendorId).length > 1">s</span>
            from {{ vendorInfo(vendorId).name }} price has changed
          </p>
          <p class="d-desc f-12-px mb-0">
            Items on your Shopping Cart will always reflect the most recent
            price displayed on their product detail pages
          </p>
          <ul class="pl-3">
            <li class="d-desc f-11-px" v-for="(order, orderIndex) in getUpdatedPriceProducts(vendorId)"
              :key="orderIndex">
              <span>{{ order.product.name }}</span>
              <span> has changed from </span>
              <span class="text-success gilroy-b">${{ Number(order.unit_price).toFixed(2) }}
              </span>
              <span> to </span>
              <span class="text-success gilroy-b">${{ Number(order.updated_unit_price).toFixed(2) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PlusMinusMixin from "@/mixins/PlusMinus";
import DashHead from "@/components/dashboard/DashHead";
import CartMixin from "@/mixins/CartMixin";
import OfficeMixin from "@/mixins/OfficeMixin";
import GrowLoader from "@/components/loaders/GrowLoader";
import Button from "@/components/ui/Button";
import CartCover from "@/components/cart/CartCover";
import OrderSummaryCard from "@/components/cart/OrderSummaryCard";
import RemainingBudgetCard from "@/components/cart/RemainingBudgetCard";
import OrderItems from "@/components/dashboard/orders/OrderItems";
import SkeletonLoader from "skeleton-loader-vue";
import PromoAnalyzer from "@/components/cart/PromoAnalyzer";
import SearchHeadCover from "@/components/SearchHeadCover";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Modal from "@/components/modals/Modal";
import DashboardFooter from "@/components/dashboard/footer/DashboardFooter.vue";
import AddBillingInCheckout from "@/components/cart/AddBillingInCheckout.vue";
import Swal from "sweetalert2";
import axios from "@/axios";
export default {
  components: {
    DashHead,
    GrowLoader,
    Button,
    CartCover,
    OrderSummaryCard,
    RemainingBudgetCard,
    OrderItems,
    SkeletonLoader,
    PromoAnalyzer,
    SearchHeadCover,
    Modal,
    DashboardFooter,
    AddBillingInCheckout,
  },
  mixins: [PlusMinusMixin, CartMixin, OfficeMixin],
  data: () => ({
    viewProducts: false,
    fetchingData: true,
    infoModal: false,
    checkoutData: null,
    priceUpdateModal: false,
    isPriceUpdateModalShown: false,
    creditCardModal: false,
    cardNumber: "",
    cardHolder: "",
    expirationMonth: "",
    expirationYear: "",
    address: null,
    ccv: "",
    isCcvFocused: false,
    loading: false,
    expirationDate: new Date("2024-12-31"),
    showCreditCardPopup: true,
    showCardSuccess: false,
    creditCardSubmitted: false,
    // editCreditCardDetails: false,
    creditCardPostErrors: null,
    cc_status: false,
    streetAddress: "",
    city: null,
    state: null,
    zip: null,
    country: null,
    CD_CCvalue: false,
    addressee: null,
  }),
  created() {
    this.fetchData();
    this.fetchCheckoutData();
    // this.getTotalSavings()
    this.$BUS.$on("checkout", this.checkout);
    this.$BUS.$on("checkout-error", () => (this.infoModal = true));
    if (this.vendorIdsHasUpdatedPrices.length > 0) this.priceUpdateModal = true;
    for (let vId of this.vendorIdsHasProducts) {
    }
    this.$BUS.$on("creditCardSuccess", (success) => {
      this.CD_CCvalue = success;
    });
  }, 
  beforeDestroy() {
    this.$BUS.$off("checkout");
    this.$BUS.$off("checkout-error");
  },
  computed: {
    formattedCardNumber() {
      return this.cardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
    },
    formattedDate() {
      const month = this.expirationDate.getMonth() + 1; // getMonth() is zero-indexed
      const year = this.expirationDate.getFullYear();
      return `${month}/${year}`; // Formats date as MM/YYYY
    },
    keys() {
      if (this.fetchingData) return 1;
      return Object.keys(this.checkoutData.order_details);
    },
    hasVendorId12Product() {
      return this.checkoutData.products.some(
        (product) => product.product.vendor.id == 12
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.orderSummaryCard.markCheckoutAsReaady();
    next();
  },
  watch: {
    fetchingCart(v) {
      if (!v) {
        this.updatePrices();
      }
      if (!v && !this.isPriceUpdateModalShown) {
        this.isPriceUpdateModalShown = false;
        if (this.vendorIdsHasUpdatedPrices.length > 0)
          this.priceUpdateModal = true;
      }
    },
  },
  methods: {
    ...mapActions("CART", [
      "FETCH_CART",
      "CHECKOUT_CART",
      "FETCH_CHECKOUT",
      "CLEAR_SAVED_FOR_LATER",
      "CLEAR_CART",
      "VENDOR_SHIPPING_METHODS"
    ]),
    ...mapMutations("CART", ["SetTotalSaving", "SET_CC_STATUS"]),
    ...mapActions("PRODUCT", [
      "GET_PRODUCT_CATEGORIES_INVENTORY",
      "FETCH_ORDERS_BY_CATEGORY",
      "UPDATE_PRICE_BY_PRODUCT_IDS",
      "GET_PRICE_BY_PRODUCT_IDS",
      "GET_PRODUCT_CATEGORIES_INVENTORY_VENDOR",
    ]),
    ...mapGetters('CART', ['getCCStatus']),
    
    toggleCreditCardPopup() {
    this.showCreditCardPopup = !this.showCreditCardPopup;
    },

    generateYears(start, end) {
      let years = [];
      for (let year = start; year <= end; year++) {
        years.push(year);
      }
      return years;
    },
    hidePopup() {
      this.showCreditCardPopup = false;
    },
    async fetchCreditCardDetails() {
      try {
        const response = await axios
          .reqApi(`crazy-dental/user-credit-card?office_id=${this.officeId}`)
          .get();
        let cardHolderName = response.data["ccholdername"];
        let cardNumber = response.data["ccnumber"];
        let expiryDate = response.data["ccexpdate"];
        let internalId = response.data["ccinternalid"];
        // let address = response.data['statefrom'];
        // let ccv = response.data['cctype'];
        // let validfrom = response.data['ccvalidfrom'];

        console.log(response);
      } catch (error) {
        console.error(error);
      }
      console.log("Fetching credit card details", response);

    },
    async fetchUserDetails() {
      try {
        const userDetails = await getUserDetails();
        this.creditCardSubmitted = !!userDetails.creditCardInfo;
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    },

    onCreditCardSubmitSuccess() {
      this.creditCardSubmitted = true;
    },

    async submitCreditCardDetails() {
      // Start loading
      this.loading = true;
      try {
        let payload = {
          ccname: this.cardHolder,
          ccnumber: this.cardNumber.replace(/ /g, ""),
          customercode: this.ccv,
          ccexpiredate: `0${this.expirationMonth}/${this.expirationYear}`,
        };
        const response = await axios
          .reqApi(`crazy-dental/user-credit-card?office_id=${this.officeId}`)
          .post(payload);
        console.log("Failed to post credit card details.");
        // Check response status or success indicator from API
        if (!this.addressee) {
          this.addressee = 'ordo11'
        }
        if (response.status === 200) {
          this.$BUS.$emit("creditCardSuccess", true);
          let addressPayload = {
            defaultbilling: true,
            defaultshipping: false,
            addressee: this.addressee,
            // attention: "Rashid",
            state: this.state,
            country: this.country,
            zip: this.zip,
            addr1: this.addressee

          };
          let url = `crazy-dental/user-address?office_id=${this.officeId}`;
          let result = await axios.reqApi(url).post(addressPayload);
          // Success
          this.hidePopup();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your credit card details have been submitted successfully.",
            confirmButtonText: "OK",
          });
        } else {
          this.$BUS.$emit("creditCardSuccess", false);
          this.creditCardPostErrors = response.data;
          this.showCreditCardPopup = false;
          this.showCardSuccess = true;
          // Handle API response indicating failure
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            confirmButtonText: "Try Again",
            confirmButtonColor: "#e83e8c"
          });
        }
      } catch (error) {
        this.$BUS.$emit("creditCardSuccess", false);
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid Card Details!",
          confirmButtonText: "Try Again",
          confirmButtonColor: "#e83e8c"
        });
      }

      // Stop loading
      this.loading = false;
    },

    formattedExpirationDate() {
      return (
        this.expirationYear.toString() + " / " + this.expirationMonth.toString()
      );
    },

    formatCardNumber() {
      this.cardNumber = this.cardNumber
        .replace(/[^\d]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    },

    async fetchCheckoutData() {
      this.fetchingCheckout = true;
      try {
        this.checkoutData = await this.FETCH_CHECKOUT({
          office: this.officeId,
        });
        if (Object.hasOwn(this.checkoutData, 'is_cc_details')) {
          this.SET_CC_STATUS(this.checkoutData.is_cc_details);
        }
        else {
          this.SET_CC_STATUS(true);
        }
        this.cc_details = this.getCCStatus();
        for (let product of this.checkoutData.products) {
          if (product.product.vendor.id == 12) {
            this.showCreditCardPopup = true;
            // this.editCreditCardDetails = true;
          }
        }
        console.log(this.checkoutData);
        this.$BUS.$emit('cd_cc_value', this.checkoutData.is_cc_details)
        // await this.$nextTick()
        // this.$BUS.$emit('total-order-value-in-cart', this.totalOrderValueCheckout)
        // this.$BUS.$emit('checkout-data', this.checkoutData)
      } catch (e) {
        this.$BUS.$emit("checkout-error");
        console.log(e);
      }
      this.fetchingCheckout = false;
    },
    // async updatePrices() {
    //   let productIds = this.productIds();
    //   debugger
    //   let updatedRecord = await this.UPDATE_PRICE_BY_PRODUCT_IDS({
    //     office: this.officeId,
    //     products: productIds
    //   })
    //   debugger
    //   console.log(updatedRecord)
    // },
    // productIds() {
    //   let ids = []
    //   debugger
    //   for (let product of this.checkoutData.products) {
    //     ids.push(product.product.id)
    //   }
    //   return ids;
    // },
    togglePriceUpdateModal(v) {
      if (v != this.priceUpdateModal) this.priceUpdateModal = v;
    },
    totalValue() {
      if (Array.isArray(this.keys))
        return this.keys
          .map((k) => {
            return Number(this.getTotalAmount(k));
          })
          .reduce((a, b) => a + b);
      return null;
    },
    getSubSavings(vendorId) {
      return this.checkoutData.order_details[vendorId].savings_amount;
    },
    getTotalSavings() {
      let totalSavings = 0;
      for (let order in this.checkoutData.order_details) {
        totalSavings =
          totalSavings + this.checkoutData.order_details[order].savings_amount;
      }
      this.SetTotalSaving(totalSavings);
      return totalSavings;
    },
    getSubTotal(vendorId) {
      let result =
        this.checkoutData.order_details[vendorId].subtotal_amount || 0;
      return result;
    },
    toggleInfoModal(v) {
      if (v != this.infoModal) this.infoModal = v != null ? v : false;
    },
    async fetchData() {
      await this.fetchCheckoutData();
      this.getTotalSavings();
      // .then(res => {
      //   this.updatePrices()
      // })
      await this.$nextTick();

      this.fetchingData = false;
    },

    getShippingMethodsOf(vendorId) {
      // if(this.vendorShippingMethods[key] != null) return this.vendorShippingMethods[key]
      // return []
      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.slug === vendorId
        );
        if (vendorInfo != null) {
          return vendorInfo.shipping_options;
        }
      }
      return [];
    },
    getDefaultShippingOption(vendorId) {
      if (this.connected_vendors) {
        let vendorInfo = this.connected_vendors.find(
          (info) => info.vendor.slug === vendorId
        );
        return vendorInfo.default_shipping_option;
      }
    },
    async setShipOption(vendorId, option) {
      this.shipping_options[vendorId] = option;
      // Save to localStorage
      localStorage.setItem(
        "selectedShippingOptions",
        JSON.stringify(this.shipping_options)
      );
      if (this.checkoutData.order_details[vendorId] != null)
        this.checkoutData.order_details[vendorId].shipping_option_id =
          option.id;
      else {
        this.checkoutData.order_details[vendorId] = {
          shipping_option_id: option.id,
        };
      }
      await this.$nextTick();
      this.$forceUpdate();
    },
    getTotalAmount(vendorId) {
      let sub_total = Number(
        this.checkoutData.order_details[vendorId].total_amount
      );
      let shippingCost = Number(this.getShippingCost(vendorId, sub_total));
      let total = (sub_total + shippingCost).toFixed(2);
      return total;
    },
  },
  mounted() {
    const savedOptions = JSON.parse(
      localStorage.getItem("selectedShippingOptions")
    );
    if (savedOptions) {
      this.shipping_options = savedOptions;
    }
  },
};
</script>

<style lang="sass" scoped>

@import '../../style/variables'
@import '../../style/sl-num'
.cart-page
  .l-part
    width: -webkit-fill-available
    @media screen and (min-width: 992px)
      // height: 90vh
      // overflow-y: auto
      // padding-bottom: 50px
    @media screen and (max-width: 992px)
      width: 100%
  .r-part
    min-width: calc( 267px + 1rem)
    position: relative
    .d-card
      @media screen and (min-width: 992px)
        position: sticky
        top: 80px
    @media screen and (max-width: 992px)
      width: 100%
  .checkout-card
    ::v-deep .c-chk
      label
        font-family: GILROY-BOLD
        font-size: 16px
        font-weight: 600
        font-stretch: normal
        font-style: normal
        line-height: 1.8
        text-align: left
        color: #1c455a
    .c-l-part
      border-right: solid 1px #EBF4F7
      width: 100%
      .ship-method
        width: 100%
        min-height: 45px
        // border-radius: 4px
        // border: solid 1.5px #eff6f8
        .ck-wrp
          width: 30px
          height: 30px
          border-radius: 4px
          border: solid 1px #eff6f8
          // min-width: 100%
          display: flex
          align-items: center
          justify-content: center
          .ck
            width: 20px
            height: 20px
            flex-grow: 0
            border-radius: 2px
            // background-color: #5ebc3a
    .c-r-part
      width: 100%
      .checkout-pricing-table
        tr
          td
            padding-top: 8px
            padding-bottom: 8px
        .last-row
          td
            padding-bottom: 12px
        .total-row
          td
            padding-top: 12px
            border-top: solid 1px #EBF4F7
      .checkout-pay-to-btn-wrapper
        border: solid 1px #EBF4F7
        border-radius: 4px
</style>
