<template>
  <div class="login-page d-flex flex-column align-items-center w-100">
    <!-- <auth-logo class="mb-5" /> -->
    <login-card />
  </div>
</template>

<script>
import LoginCard from '@/components/auth/LoginCard'
export default {
  components: {
    LoginCard,
  },
}
</script>

<style lang="sass" scoped>
.logo
  width: 50px
</style>