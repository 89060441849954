<template>
  <div id="app">
    <VueSlickCarousel
      v-if="this.$store.getters['GREETINGS/getShowGreetings'] && !this.$store.getters['GREETINGS/getHardClosed']"
      :arrows="true" :dots="false" :autoplay="true" :pauseOnFocus="true" :adaptiveHeight="true" :pauseOnHover="true"
      :vertical="true" @afterChange="handleCarouselChange"
      :style="{ backgroundColor: currentBackgroundColor, color: currentForegroundColor }" class="fade-transition"
      ref="carousel">
      <div v-for="greeting in this.$store.getters['GREETINGS/getAllGreetings']" :key="greeting.id">
        <div class="greeting-container">
          <span @click="prevItem" class="control-button">&lt;</span>
          <div>
            <transition name="text-fade">
              <span class="title" :key="greeting.title">{{ greeting.title }}</span>
            </transition>
            <transition name="text-fade">
              <span class="sub-title" :key="greeting.sub_title">{{ greeting.sub_title }}</span>
            </transition>
          </div>
          <span @click="nextItem" class="control-button">&gt;</span>
          <p @click="closeCarousel" class="close-button">X</p>
        </div>
      </div>
    </VueSlickCarousel>
    <router-view />
  </div>
</template>
 
<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';
import { mapGetters, mapMutations, mapActions } from 'vuex';


export default {
  data: () => ({
    currentBackgroundColor: '#ff1dc3',
    currentForegroundColor: '#FFFFFF',
    currentIndex: 0,
  }),
  mounted() {
    this.setIntercom()
  },
  components: {
    VueSlickCarousel
  },
  computed: {
    ...mapGetters('USER', ['profile', 'role']),
  },
  watch: {
    profile: {
      handler() {
        this.setIntercom()
      }, deep: true
    },
  },
  created() {
    this.fetchGreetings()
  },
  methods: {
    ...mapActions('GREETINGS', ['FETCH_GREETINGS_BY_ROLE', 'CLOSE_CAROUSEL']),
    ...mapMutations('OFFICE', ['SET_SHOW_LOCATIONS']),
    ...mapGetters('OFFICE', ['showLocations']),
    async fetchGreetings() {
      if (this.role != null) {
        let payload = {
          role: this.role
        }
        await this.FETCH_GREETINGS_BY_ROLE(payload)
      }
    },
    handleCarouselChange(index) {
      this.currentIndex = index;
      if (this.$store.getters['GREETINGS/getAllGreetings'][index]) {
        this.currentBackgroundColor = this.$store.getters['GREETINGS/getAllGreetings'][index].background_color;
        this.currentForegroundColor = this.$store.getters['GREETINGS/getAllGreetings'][index].foreground_color
      }
    },
    async closeCarousel() {
      await this.CLOSE_CAROUSEL(true)
    },
    nextItem() {
      this.$refs.carousel.next();
    },
    prevItem() {
      this.$refs.carousel.prev();
    },
    setIntercom() {
      if (this.profile == null) {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "sjlb899z"
        };
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/sjlb899z'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
      } else {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "sjlb899z",
          name: this.profile.first_name + ' ' + this.profile.last_name, // Full name
          email: this.profile.email,
          created_at: this.profile.date_joined
        };
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/sjlb899z'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active 
  transition: opacity 1.5s 

.fade-enter, .fade-leave-to 
  opacity: 0

.text-fade-enter-active, .text-fade-leave-active 
  transition: transform 0.5s ease, opacity 0.5s ease

.text-fade-enter, .text-fade-leave-to 
  transform: translateY(20px)
  opacity: 0

.greeting-container 
  display: flex
  align-items: center
  justify-content: center
  transition: background-color 3s ease


.control-button 
  cursor: pointer
  font-size: 16px
  font-weight: bold
  margin-left: 20px 
  margin-right: 20px 


.title 
  font-size: 16px
  font-weight: bold

.sub-title 
  font-size: 14px
  margin-left: 10px

.close-button 
  position: absolute
  right: 10px
  transform: translateY(50%)
  cursor: pointer
  font-size: 16px
  line-height: 1
  .floating-div 
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 300px
    height: 300px
    background-color: #fff 
    border: 1px solid #ccc 
    z-index: 9999 
  

</style>