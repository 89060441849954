<template>
  <div class="no-data">
    <div class="wrappeer mx-auto d-flex flex-column flex-lg-row align-items-center justify-content-lg-between">
      <div class="img-wrapper mb-3 mb-lg-0">
        <img src="@/assets/images/Group 16174.svg" alt="" class="w-100 h-100">
      </div>
      <div class="contents">
        <p class="d-title mb-1">Budget vs Spend Data</p>
        <p class="d-desc mb-3">Hey! Currently you have no upcoming orders. Once you create an order the upcoming order will display here. To create an order, click here</p>
        <Button text="Create Order" :onclick="() => goTo({name: 'CreateAnOrder'})" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button'
import GoTo from '@/mixins/GoTo'
export default {
  components: {
    Button,
  },
  mixins: [GoTo],
}
</script>

<style lang="sass" scoped>
.no-data
  .wrappeer
    max-width: 535px
    width: 100%
    .img-wrapper
      width: 100%
      height: 100%
      max-width: 160px
      max-height: 160px
    .contents
      @media screen and (min-width: 992px)
        max-width: 325px
</style>