<template>
  <div class="upcoming-orders d-card py-3">
    <div class="d-flex px-3 justify-content-between">
      <p class="d-title mb-0">Monthly Reports</p>
    </div>
    <div class="owl-carousel mt-3 pl-3 orders-carousel" id="rep">
      <div :class="['upc-order pb-2 pt-1 position-relative two',]" v-for="i in 4" :key="i">
        <div :class="['indicator', i%2 == 0? 'one': i%3 == 0? 'two':'three']"></div>
        <div class="head-sec mx-2">
          <div class="t-head d-flex justify-content-between mb-2">
            <p class="mb-0 h-title">June 2021</p>
          </div>
          <div class="pdf-head d-flex mb-2">
            <div class="pdf-logo mr-2">
              <span>PDF</span>
            </div>
            <div class="pdf-info h-100 d-flex flex-column justify-content-between">
              <p class="mb-0 h-title__2" style="line-height: normal">Monthly report of Jun 2021</p>
              <p class="mb-0 h-desc"  style="line-height: normal">250kb</p>
            </div>
          </div>
        </div>
        <p class="h-desc mb-3 mx-2">Lorem Ipsum is simply dummy text of the print text dummy</p>
        <div class="d-flex px-2">
          <b-btn class="custom-btn downl-btn w-100">Download</b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
  components: {
    Carousel
  },
  data: () => ({}),
  mounted(){
    $(document).ready(function(){
      $("#rep").owlCarousel({
        autoWidth: true,
        nav: true,
        navText: ["<i class='fa fa-angle-left mr-3'></i>", "<i class='fa fa-angle-right'></i>"]
      });
    });
  },
}
</script>

<style lang="sass" scoped>

.upcoming-orders
  ::v-deep .orders-carousel
    .owl-item
      width: max-content !important
      min-width: 265px
  .upc-order
    width: 245px
    border-radius: 12px
    &.one
      border: solid 1px #F5FAFD
      background-color: #F5FAFD
    &.two
      border: solid 1px #E5E5E5
      background-color: #ffffff
    .indicator
      width: 4px
      height: 38px
      border-radius: 12px
      position: absolute
      &.one
        background-color: #49a2cb
      &.two
        background-color: #7cd878
      &.three
        background-color: #1c455a
    .h-title
      font-family: GILROY-SEMIBOLD
      font-size: 14px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 2
      color: #1c455a
      &__2
        @extend .h-title
        font-size: 10px
        line-height: 2.4
        color: #1c455a
    .h-desc
      font-size: 10px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.8
      color: #000000
    .head-sec
      .t-head
        padding-left: 10px
        margin-top: 5px
      .pdf-logo
        width: 34px
        height: 30px
        background-color: #49a2cb
        color: #ffffff
        display: flex
        justify-content: center
        align-items: center
        font-weight: bold
        font-size: 12px
    .downl-btn
      border: solid 0.5px #F0FEF0
      background-color: #F0FEF0
      color: #1c455a
      &:hover, &:active , &:focus
        border-color: #F0FEF0 !important
        background-color: #F0FEF0 !important
        color: #1c455a
</style>