<template>
  <div class="finance-page">
    <admin-header class="mb-3" title="Money is Here!" :btn1="true"
      desc="You can finally see how much you're spending in real time! Plus, download your invoices.">
      <template #btns>
        <div class="d-none d-lg-flex" style="max-width: 375px">
          <b-btn class="custom-btn danger__2 w-50">Tutorial</b-btn>
          <b-btn class="custom-btn danger__2 ml-3 w-50">Update Budgets</b-btn>
        </div>
      </template>
    </admin-header>
    <!-- <filter-com /> -->

    <div class="d-card mt-4" v-if="$store.state.OFFICE.fetchingBudget">
      <grow-loader />
    </div>
    <div class="d-flex gap-16-px mt-3" v-else style="overflow-x: auto;">
      <finance-activity :monthBudgetData="monthBudgetData" :subaccount="subaccount"
        v-for="subaccount in monthBudgetData.budget" :key="subaccount.slug" :fetchingFilterData="fetchingFilterData" />
    </div>
    <budget-vs-spend-no-data class="d-card py-5 mt-4" v-if="noBudData && role == 1" />
    <bar-chart class="d-card py-3 mt-4" @no-data="noBudData = true && role == 1" v-else />
    <DashboardFooter class="mt-3" />
  </div>
</template>
  
<script>
import DHead from '@/components/dashboard/DHead'
import BudgetSpend from '@/components/dashboard/finance/BudgetSpend'
import Report from '@/components/dashboard/finance/Report'
import PieChart from '@/components/dashboard/PieChart'
import FinanceWelcome from '@/components/FinanceWelcome'
import DashHead from '@/components/dashboard/DashHead'
import Invoices from '@/components/dashboard/orders/Invoices'
import OrdersByBudgetTypes from '@/components/dashboard/orders/OrdersByBudgetTypes.vue'
import SearchHeadCover from '@/components/SearchHeadCover'
import AdminHeader from '@/views/admin_dashboard/components/admin_header'
import FinanceActivity from '@/components/dashboard/FinanceActivity'
import TopSuppliersBySpend from '@/components/dashboard/TopSuppliersBySpend'
import { mapActions } from 'vuex'
import StatisticsMixin from '@/mixins/Statistics'
import MonthMixin from '@/mixins/Month'
import moment from 'moment'
import BarChart from '@/components/dashboard/BarChart'
import BudgetVsSpendNoData from '@/components/dashboard/BudgetVsSpendNoData'
import FilterCom from '@/components/dashboard/FilterCom'
import TopSuppliersBySpendOrders from '@/components/dashboard/TopSuppliersBySpendOrders'
import OrderInvoicesNoData from '@/components/dashboard/OrderInvoicesNoData'
import Filter from '@/mixins/Filter'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
import GrowLoader from '@/components/loaders/GrowLoader'
export default {
  components: {
    DHead,
    BudgetSpend,
    Report,
    PieChart,
    FinanceWelcome,
    DashHead,
    Invoices,
    // SearchHeadCover,
    FinanceActivity,
    TopSuppliersBySpend,
    BarChart,
    BudgetVsSpendNoData,
    FilterCom,
    TopSuppliersBySpendOrders,
    OrderInvoicesNoData,
    DashboardFooter,
    OrdersByBudgetTypes,
    GrowLoader,
    AdminHeader
  },
  mixins: [StatisticsMixin, MonthMixin, Filter],
  data: () => ({
    fetching: true,
    noBudData: false,
    orders: null,

    monthBudgetData: null,
    fetchingFilterData: false,
    filterApplied: false,
    fullStats: [],
    scrollLeft: 0,
    scrollEnded: false,
    cardHolder: null,
  }),
  created() {
    this.$BUS.$on('filter:filter-change', this.getData)
    this.getData({
      selectedDate: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
      }
    })
  },
  mounted() {
    this.cardHolder = this.$refs.cardHolder
  },
  computed: {
    currentMonth() {
      return moment().format('MMMM')
    },
    hasOrders() {
      if (this.orders == null || (Array.isArray(this.orders) && this.orders.length > 0)) return true
      return false
    },
    subaccounts() {
      return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.sort((a, b) => Number(b.percentage) - Number(a.percentage))
    },
  },
  methods: {
    ...mapActions('ORDER', ['MONTH_ORDER_COUNT_STATUS']),
    ...mapActions('OFFICE', ['CURRENT_MONTH_BUDGET', 'FETCH_FULL_STATS']),
    getData(filters) {
      this.onFilterChange(filters, async (filt) => {
        this.fetchingFilterData = true
        let payload = { office: this.officeId }
        if (filt) payload = { ...payload, ...filt }
        const res = await this.FETCH_FULL_STATS(payload)
        this.fullStats = res.data.data
        this.filterApplied = true
        this.fetchingFilterData = false
      })
    },
    async fetchOrderCount() {
      try {
        this.count = await this.MONTH_ORDER_COUNT_STATUS({ office: this.officeId })
      } catch (e) { }
      this.fetching = false
    },
    onItemFetched(orders) {
      this.orders = orders
    },
    onScroll() {
      let el = this.$refs.cardHolder
      if (el) {
        this.scrollLeft = el.scrollLeft
        if (el.getClientRects()[0].width + this.scrollLeft > el.scrollWidth - 10) {
          this.scrollEnded = true
        } else {
          this.scrollEnded = false
        }
      }
    },
    scroll(direction) {
      let el = this.$refs.cardHolder
      if (el) {
        if (direction == 'right') {
          el.scrollTo(el.scrollLeft + 300, 0)
        }
        if (direction == 'left') {
          el.scrollTo(el.scrollLeft - 300, 0)
        }
      }
    },
  },
  beforeDestroy() {
    this.$BUS.$off('filter:filter-change')
  },
}
</script>
  
<style lang="sass" scoped>
  .pie-chart
    min-width: 80%
    max-width: 80%
    @media (min-width: 992px)
      min-width: 29%
      max-width: 29%
  .activities
    display: flex
    justify-content: space-between
    .act
      &:nth-child(1)
        width: 40%
      &:nth-child(2)
        width: 40%
      &:nth-child(3)
        width: 18%
    @media screen and (max-width: 1400px)
      .act
        &:nth-child(1)
          width: 37%
        &:nth-child(2)
          width: 37%
        &:nth-child(3)
          width: 20%
    @media screen and (max-width: 1200px)
      .act
        &:nth-child(1)
          width: 35%
        &:nth-child(2)
          width: 35%
        &:nth-child(3)
          width: 24%
    @media screen and (max-width: 1100px)
      .act
        &:nth-child(1)
          width: 33%
        &:nth-child(2)
          width: 33%
        &:nth-child(3)
          width: 28%
    @media screen and (max-width: 900px)
      flex-flow: column
      .act
        margin-bottom: 30px
        &:nth-child(1)
          width: 100%
        &:nth-child(2)
          width: 100%
        &:nth-child(3)
          width: 100%
  
  .finance-page
    .p1
      max-width: 659px
      width: 100%
    .p2
      max-width: 353px
      width: 100%
  </style>