<template>
  <div class="dashboard-page">
    <div class="">
      <div class="d-p-1 order-2 order-lg-1">
        <admin-header class="mb-3" :title="`Welcome to Ordo, ${profile.first_name}!`" redirect="CreateAnOrder"
          :searchField="true" :initText="$route.query.q"
          :desc="`Hey there! My name is Gordo and I'm here to take care of all your ordering needs. If you ever need any help click on the ''Help Center'' button to the left for more information about how things work around these parts`"></admin-header>
        <div>
          <b-navbar toggleable="sm" type="light" variant="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <b-navbar-brand>Your Monthly Overview</b-navbar-brand>
            <b-collapse id="nav-text-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-text>{{ this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length == 0 ?
                  this.$store.getters['ADMIN_VIEW/getTotalOffices'] :
                  this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length
                }} offices {{ this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length == 0 ? 'Connected' :
  'Selected' }}</b-nav-text>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
        <div class="d-card" v-if="loadingBudget">
          <grow-loader />
        </div>
        <div class="d-flex gap-16-px align-items-center w-100" v-else>
          <img src="@/assets/images/left_pol_arr.png" alt="" @click="scroll('left')"
            class="cursor-pointer w-14-px h-18-px hidden"
            :class="[scrollLeft > 0 && cardHolder && cardHolder.getClientRects()[0] && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"
            v-if="cardHolder">
          <img src="@/assets/images/right_pol_arr.png" alt="" @click="scroll('right')"
            class="cursor-pointer w-14-px h-18-px hidden"
            :class="[!scrollEnded && cardHolder && cardHolder.getClientRects()[0] && cardHolder.scrollWidth > cardHolder.getClientRects()[0].width && 'visible']"
            v-if="cardHolder">
        </div>



        <div class="d-flex justify-content-center align-items-center" style="margin-top: 10 !important;">
          <info-tile :number="bestPerformanceOffice()" :title="'Best Performing Office'" iconColor="green"
            icon="bookmark-check"></info-tile>
          <info-tile :number="worstPerformanceOffice()" :title="'Worst Performing Office'" iconColor="red"
            icon="exclamation-diamond"></info-tile>
          <info-tile :number="totalSavings()" :title="'Total Savings'" iconColor="green"
            icon="card-checklist"></info-tile>

        </div>
        <div class="d-flex justify-content-center align-items-center" style="margin-top: 10 !important;">
          <info-tile :number="totalOverBudget()" :title="'Over Budget Sub Account'" iconColor="red"
            icon="graph-down"></info-tile>
          <info-tile :number="underBudgetOffices()" :title="'Under Budget Sub Account'" iconColor="green"
            icon="graph-up"></info-tile>
          <info-tile :number="this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length == 0 ?
            this.$store.getters['ADMIN_VIEW/getTotalOffices'] :
            this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length
            "
            :title="this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length == 0 ? 'Offices Connected' : 'Offices Selected'"
            iconColor="blue" icon="gear-wide-connected"></info-tile>
        </div>
        <div class="d-flex justify-content-center align-items-center"
          style="margin-top: 10 !important; align-items: start; ">
          <info-tile :number="`${percentageOverBudget()}`" :title="'Over  Budget Percentage'" iconColor="red"
            icon="graph-down"></info-tile>
          <info-tile :number="`${percentageUnderBudgetOffices()}`" :title="'Under Budget Percentage'" iconColor="green"
            icon="graph-up">
          </info-tile>
          <info-tile :number="`${getToTalSubAccounts()}`" :title="'Total Sub Accounts'" iconColor="blue"
            icon="check2-all">
          </info-tile>


        </div>
        <div class="d-flex justify-content-center align-items-center"
          style="margin-top: 10 !important; align-items: start; ">
          <info-tile :number="`${pendingCount}`" :title="'Pending Approval'" iconColor="blue"
            icon="arrow-clockwise"></info-tile>
        </div>
      </div>
      <div v-for="id in getSelectedOfficesIds()" :key="id" style="margin-bottom: 10px;">
        <div
          style="background-color: white; padding: 6px; border-radius: 10px; margin-bottom: 10px; padding-left: 20px; display: flex; align-items: center; height: 100%; font-weight: bold;">
          <p style="margin: 0; height: 100%;">{{ getOfficeName(id) }}</p>
        </div>
        <div class="d-flex gap-16-px align-items-stretch w-100" style="overflow-x: auto; overflow-y: hidden;"
          :ref="'cardHolder_' + id" @scroll="onScroll">
          <template v-if="getOfficeBudget(id)">
            <template v-for="subaccount in getOfficeBudget(id).subaccounts">
              <pie-chart class="d-card py-3 pie-chart" :subaccount="subaccount" :key="subaccount.key" />
            </template>
          </template>
        </div>
      </div>
    </div>
    <modal :modal="startPop" @change="toggleStartPop" size="md" dialog-class="start-pop-modal">
      <div class="d-flex">
        <div>
          <p class="d-title">Welcome aboard</p>
          <p class="d-desc" style="max-width: 340px">Now that you've successfully completed onboarding, you are ready to
            start using Ordo. We are currently pulling your order history from your linked vendors. This typically takes
            5-10 minutes. We'll send you an email when the process is complete. Happy ordering!</p>
          <Button text="Start using Ordo!" class="px-md-4 mt-lg-4" :onclick="() => toggleStartPop(false)" />
        </div>
        <img src="@/assets/images/onboarding_popup.png" alt="" class="start-pop-ch">
      </div>
    </modal>
    <DashboardFooter class="mt-3" />
    <modal :modal="completeOnboardModal" @change="toggleCompleteOnboardModal" size="lg"
      dialog-class="dashboard-onboarding">
      <p class="d-title text-center">Complete Onboarding</p>
      <Onboarding @close="onCloseOnboard" @step-change="onStepChange" />
    </modal>
  </div>
</template>
  
<script>
import RecentOrders from '@/components/dashboard/RecentOrders'
import PieChart from '@/components/dashboard/PieChart'
import Pie from '@/views/admin_dashboard/components/pie'
import AdminHeader from '@/views/admin_dashboard/components/admin_header.vue'
import Modal from '@/components/modals/Modal'
import Button from '@/components/ui/Button'
import DashboardFooter from '@/components/dashboard/footer/DashboardFooter.vue'
import Onboarding from '@/views/OnboardingDashboard.vue'
import GrowLoader from '@/components/loaders/GrowLoader'
import OfficeMmixin from '@/mixins/OfficeMixin'
import OfficesPicker from '@/views/admin_dashboard/components/offices_picker'
import InfoCard from '@/views/admin_dashboard/components/info_card'
import InfoTile from '@/views/admin_dashboard/components/info_tile'

import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  head: {
    script: [
      { type: 'text/javascript', src: '/js/fbq_dashboard.js', async: true, body: true },
      { type: 'text/javascript', src: 'https://www.googletagmanager.com/gtag/js?id=G-4PXG8JVSJH', async: true, head: true },
      { type: 'text/javascript', src: '/js/gm_dashboard.js', async: true, body: true },
    ],
  },
  components: {
    RecentOrders,
    PieChart,
    Pie,
    AdminHeader,
    Modal,
    Button,
    DashboardFooter,
    Onboarding,
    GrowLoader,
    OfficesPicker,
    InfoCard,
    InfoTile
  },
  mixins: [OfficeMmixin],
  data: () => ({
    noUpcOrder: true,
    startPop: false,
    completeOnboardModal: false,
    on_boarding_step: 5,
    scrollLeft: 0,
    scrollEnded: false,
    cardHolder: null,
    showOnboardingBanner: false,
    fetchingOnboardingStatus: true,
    pendingCount: 0,
    scrollEnded: false,

  }),
  created() {
    this.fetchDashboardAnalytics()
    this.totalPendingOrder()
  },
  mounted() {
    this.cardHolder = this.$refs.cardHolder
    this.completeOnboardModal = this.$route.query.enableOnBoarding == null ? false : true;
    if (this.$route.query.fetchGreetings == true) {
      this.fetchGreetings()
    }
  },
  computed: {
    ...mapGetters('USER', ['role', 'profile']),
    ...mapGetters('OFFICE', ['connected_vendors']),
    ...mapState('OFFICE', ['fetchingConnectedVendors', 'fetchingBudget', 'fetchingTeamMebers']),
    ...mapState('ONBOARDING', ['status']),
    loadingBudget() {
      return this.$store.state.OFFICE.fetchingBudget || this.$store.state.OFFICE.updatingBudget
    },
    subaccounts() {
      return JSON.parse(JSON.stringify(this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts))
    },
    showBanner() {
      return !this.fetchingOnboardingStatus && this.status && (!this.status.vendors_linked || !this.status.budget_set || !this.status.team_invited)
    },


  },

  methods: {
    ...mapActions('ONBOARDING', ['FETCH_ONBOARDING_STATUS']),
    ...mapActions('ADMIN_VIEW', ['FETCH_DASHBOARD_ANALYTICS', 'FETCH_VENDOR_ORDERS']),
    ...mapActions('OFFICE', ['FETCH_CONNECTED_VENDORS', 'FETCH_OFFICE_BUDGETS']),
    ...mapActions('GREETINGS', ['FETCH_GREETINGS_BY_ROLE']),
    ...mapActions('ADMIN_ORDER', ['GET_INVOICE', 'FETCH_PENDING_STATS', 'APPROVE_ORDER', 'UPDATE_ORDER', 'UPDATE_PENDING_ORDER', 'APPROVE_PENDING_ORDER', 'CREATE_MANUAL_ORDER', 'DELETE_MANUAL_ORDER']),

    getSelectedOfficesIds() {
      if (this.$store.getters['ADMIN_VIEW/getSelectedOffices'].length > 0) {
        return this.$store.getters['ADMIN_VIEW/getSelectedOffices'];
      }
      else {
        return [this.officeId];
      }
    },

    getToTalSubAccounts() {
      let selectedOfficesIds = this.getSelectedOfficesIds();
      let total = 0;
      for (const officeId of selectedOfficesIds) {
        total = total + this.$store.state.OFFICE.officeBudget[officeId].subaccounts.length;
      }
      return total;
    },

    getOfficeBudget(id) {
      return this.$store.state.OFFICE.officeBudget[id];
    },

    isValidIndex(i) {
      return i < this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.length;
    },
    getSubaccount(i) {
      return this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts[i];
    },
    totalOverBudget() {
      let totalOverBudgetCount = 0;
      let officeIds = this.getSelectedOfficesIds()
      for (const officeId of officeIds) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId]?.subaccounts;

        if (subaccounts) {
          totalOverBudgetCount += subaccounts.reduce((count, office) => {
            if (office.ytd.amount > office.ytd.budget_amount || office.ytd.spend > office.ytd.budget_amount) {
              return count + 1;
            }
            return count;
          }, 0);
        }
      }

      return totalOverBudgetCount;
    },

    percentageOverBudget() {
      const subaccounts = this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts;

      const totalOffices = subaccounts.length;

      if (totalOffices === 0) {
        return 0;
      }

      const officesOverBudgetCount = subaccounts.reduce((count, office) => {
        if (office.ytd.amount > office.ytd.budget_amount || office.ytd.spend > office.ytd.budget_amount) {
          return count + 1;
        }
        return count;
      }, 0);

      const percentage = (officesOverBudgetCount / totalOffices) * 100;
      return percentage + '%';
    },

    loopLength() {
      let length = Math.floor((this.$store.state.OFFICE.officeBudget[this.officeId].subaccounts.length - 1) / 3);
      let remainder = length % 3;
      if (remainder > 0) {
        length = length + 1;
      }
      return length;
    },

    getOfficeName(id) {
      return this.offices.filter(ofc => { return ofc.id == id })[0].name;
    },

    underBudgetOffices() {
      let total = 0;
      let selectedOffices = this.getSelectedOfficesIds();

      for (const officeId of selectedOffices) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId].subaccounts;
        console.log(subaccounts);

        const underBudgetOffices = subaccounts.filter(office => {
          return office.ytd.amount <= office.ytd.budget_amount && office.ytd.spend <= office.ytd.budget_amount;
        });

        total = total + underBudgetOffices.length;
      }
      return total;
    },
    percentageUnderBudgetOffices() {
      let totalUnderBudget = 0;
      let totalSubaccounts = 0;
      let selectedOffices = this.getSelectedOfficesIds();

      for (const officeId of selectedOffices) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId]?.subaccounts;

        if (subaccounts) {
          totalSubaccounts += subaccounts.length;

          const underBudgetOffices = subaccounts.filter(office => {
            return office.ytd.amount <= office.ytd.budget_amount && office.ytd.spend <= office.ytd.budget_amount;
          });

          totalUnderBudget += underBudgetOffices.length;
        }
      }

      const percentage = totalSubaccounts > 0 ? (totalUnderBudget / totalSubaccounts) * 100 : 0;
      return percentage + '%';
    },

    bestPerformanceOffice() {
      let bestPerformance = null;
      let officeIds = this.getSelectedOfficesIds();
      let bestOfficeId = null;
      for (const officeId of officeIds) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId]?.subaccounts;

        if (subaccounts) {
          for (const office of subaccounts) {
            if (!bestPerformance || (office.ytd.budget_amount > bestPerformance.ytd.budget_amount)) {
              bestPerformance = office;
              bestOfficeId = officeId;
            }
          }
        }
      }

      if (bestPerformance) {
        console.log('Best performance office:', bestPerformance.name);
        return this.getOfficeName(bestOfficeId);
      } else {
        console.log('No offices found');
        return null;
      }
    },

    worstPerformanceOffice() {
      let worstPerformance = null;
      let officeIds = this.getSelectedOfficesIds();
      let worstOfficeId = null;
      for (const officeId of officeIds) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId]?.subaccounts;
        if (subaccounts) {
          for (const office of subaccounts) {
            if (!worstPerformance || (office.ytd.budget_amount < worstPerformance.ytd.budget_amount)) {
              worstPerformance = office;
              worstOfficeId = officeId;
            }
          }
        }
      }

      if (worstPerformance) {
        console.log('Worst performance office:', worstPerformance.name);
        return this.getOfficeName(worstOfficeId);
      } else {
        console.log('No offices found');
        return null;
      }
    },

    totalSavings() {
      let totalSavingsAmount = 0;
      let officeIds = this.getSelectedOfficesIds();
      for (const officeId of officeIds) {
        const subaccounts = this.$store.state.OFFICE.officeBudget[officeId]?.subaccounts;
        if (subaccounts) {
          totalSavingsAmount += subaccounts.reduce((sum, office) => {
            const savings = office.ytd.amount - office.ytd.spend;
            return sum + savings;
          }, 0);
        }
      }
      return totalSavingsAmount;
    },


    async fetchGreetings() {
      if (this.role != null) {
        let payload = {
          role: this.role
        }
        await this.FETCH_GREETINGS_BY_ROLE(payload)

      }
    },
    async totalPendingOrder() {
      let total = 0;
      const officeIds = this.getSelectedOfficesIds();
      for (const officeId of officeIds) { total = total + await this.FETCH_PENDING_STATS({ office: officeId }); }
      return total;
    },
    async fetchDashboardAnalytics() {
      await this.FETCH_DASHBOARD_ANALYTICS()
    },

    onShowStartPop() {
      this.startPop = true
    },
    toggleStartPop(v) {
      if (v != this.startPop) this.startPop = v != null ? v : false
    },
    toggleCompleteOnboardModal(v) {
      if (v != this.completeOnboardModal) this.completeOnboardModal = v != null ? v : false
      if (!v) {
        this.FETCH_CONNECTED_VENDORS({ office: this.officeId })
      }
    },
    async fetchOnboardingStatus() {
      await this.FETCH_ONBOARDING_STATUS()
      this.fetchingOnboardingStatus = false
      setTimeout(() => {
        if (this.$route.query.start_pop != null && this.showBanner) {
          this.completeOnboardModal = true
        }
      }, 500)
    },
    onCloseOnboard() {
      this.completeOnboardModal = false
      this.on_boarding_step = 6
    },
    onScroll() {
      let el = this.$refs.cardHolder
      if (el) {
        this.scrollLeft = el.scrollLeft
        if (el.getClientRects()[0].width + this.scrollLeft > el.scrollWidth - 10) {
          this.scrollEnded = true
        } else {
          this.scrollEnded = false
        }
      }
    },
    scroll(direction) {
      let el = this.$refs.cardHolder
      if (el) {
        if (direction == 'right') {
          el.scrollTo(el.scrollLeft + 300, 0)
        }
        if (direction == 'left') {
          el.scrollTo(el.scrollLeft - 300, 0)
        }
      }
    },
    onStepChange() {
      this.on_boarding_step++
    },
  },
}
</script>
  
<style lang="sass" scoped>
  .pie-chart
    min-width: 80%
    max-width: 80%
    @media (min-width: 992px)
      min-width: 29%
      max-width: 29%
  
  .charts
    @media (max-width: 992px)
      overflow-x: auto
      flex-wrap: nowrap
  .start-pop-ch
    width: 184px
    height: 249px
    @media (min-width: 992px)
      margin-right: -50px
      margin-left: 50px
    @media (max-width: 992px)
      display: none
  </style>