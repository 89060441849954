<!-- <template>
  <div class="searched-product-header px-3">
    <skeleton-loader v-if="skeleton" type="rect" :width="'40%'" :height="'18px'" :radius="18" :rounded="true" animation="wave" class="mt-0" />
    <p class="title-txt mb-0 text-line-1" v-else>{{title}}</p>
    <b-btn class="custom-btn" v-if="inventoryBtn" @click="goTo({name: 'InventoryList'})">My Inventory List</b-btn>
    <b-btn class="custom-btn" v-if="linkVendorBtn" @click="goTo({ name: 'Settings', hash: '#Lnk' })">+ Link more Vendors</b-btn>
  </div>
</template> -->

<script>
import GoTo from '@/mixins/GoTo'
import SkeletonLoader from 'skeleton-loader-vue'
export default {
  props: {
    title: String,
    inventoryBtn: Boolean,
    linkVendorBtn: Boolean,
    skeleton: Boolean,
  },
  mixins: [ GoTo, ],
  components: { SkeletonLoader },
}
</script>

<style lang="sass" scoped>

.searched-product-header
  height: 75px
  display: flex
  align-items: center
  justify-content: space-between
  background-image: url('../../assets/images/head_cover/inventory_list_category_header.webp')
  background-size: 100% 100%
  border-top-left-radius: 12px
  border-top-right-radius: 12px
  background-size: cover
  .title-txt
    font-family: GILROY-BOLD
    font-size: 14px
    font-stretch: normal
    font-style: normal
    line-height: 1.2
    letter-spacing: normal
    text-align: left
    color: #fff
  .custom-btn
    background-color: #ff1dc3
    border-color: #ff1dc3
    min-width: 150px
    &:focus, &:hover, &:active
      background-color: #ff1dc3 !important
      border-color: #ff1dc3 !important
      
</style>