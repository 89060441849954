<template>
  <div class="page-wrapper">
    <Sec1 />
    <Sec2 />
  </div>
</template>

<script>
import Sec1 from '@/components/landing/features/Sec1'
import Sec2 from '@/components/landing/features/Sec2'
export default {
  components: { Sec1, Sec2, },
  // metaInfo() {
  //       return { 
  //           title: "Epiloge - Build your network in your field of interest",
  //           meta: [
  //               { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
  //               { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
  //               { property: 'og:site_name', content: 'Epiloge'},
  //               {property: 'og:type', content: 'website'},    
  //               {name: 'robots', content: 'index,follow'} 
  //           ]
  //       }
  //   }
}
</script>

<style lang="sass" scoped>
.page-wrapper
  background-color: #F8F9FC
</style>