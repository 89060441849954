import { render, staticRenderFns } from "./Lan6.vue?vue&type=template&id=a6dd1012&scoped=true"
import script from "./Lan6.vue?vue&type=script&lang=js"
export * from "./Lan6.vue?vue&type=script&lang=js"
import style0 from "./Lan6.vue?vue&type=style&index=0&id=a6dd1012&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6dd1012",
  null
  
)

export default component.exports